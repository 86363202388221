<template>
  <div class="empty-screen">
    <div class="empty-screen__inner">
      <div class="empty-screen__image">
        <img src="@/assets/images/empty-screen.svg" alt="" />
      </div>
      <div class="empty-screen__header">
        <h2>You currently don’t have any {{ props.headingText }} projects</h2>
        <p>Start your new project at lightning speed.</p>
      </div>
      <div class="empty-screen__content">
        <div class="empty-screen__content-item">
          <div class="mdi mdi-check-circle"></div>
          <p><strong>Dispute protection</strong> as standard</p>
        </div>
        <div class="empty-screen__content-item">
          <div class="mdi mdi-check-circle"></div>
          <p>Deposits secured <strong>in Escrow</strong></p>
        </div>
        <div class="empty-screen__content-item">
          <div class="mdi mdi-check-circle"></div>
          <p>Verified trade contractors <strong>in your local area</strong></p>
        </div>
      </div>
      <div class="empty-screen__footer">
        <v-btn
          class="button button-purple"
          v-if="props.allowButton"
          @click="onClickNewProject"
          block
        >
          Start a new project
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const emits = defineEmits(["openNewProject"]);
const onClickNewProject = () => {
  emits("openNewProject");
};
const props = defineProps({
  allowButton: Boolean,
  headingText: String,
});
console.log("propssss", props);
</script>
<style lang="scss" scoped>
.empty-screen {
  padding: 50px 0;
  color: $blueDark;

  &__inner {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  &__image {
    max-width: 372px;
    width: 100%;
    margin: 0 auto 46px;

    @media (max-width: 767px) {
      max-width: 278px;
      margin: 0 0 24px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 36px;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.15px;
    font-weight: 500;
    color: rgba($blueDark, 0.5);

    @media (max-width: 767px) {
      text-align: left;
    }

    h2 {
      @include fluidFont(32, 28, 30px);
      color: $blueDark;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  &__content {
    padding: 0 28px;
    text-align: left;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.4px;
    display: grid;
    grid-gap: 8px;

    @media (max-width: 767px) {
      padding: 0;
    }

    &-item {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 8px;
      align-items: center;

      .mdi {
        @include fluidFont(24, 24, 1);
        color: rgba($skyBlue, 1);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__footer {
    margin-top: 36px;
  }
}
</style>
