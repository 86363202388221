<template>
  <main class="tw-flex tw-flex-col tw-gap-4">
    <div
      class="dashboard-welcome remove-padding md:!tw-gap-4"
      v-if="!filterListOfEnrollCourse?.length"
    >
      <div class="dashboard-welcome__text">
        <h3>
          {{ filterListOfEnrollCourse?.length ? title : titleEmptyState }}
        </h3>
        <p class="body-text">
          {{ filterListOfEnrollCourse?.length ? text : textEmptyState }}
        </p>
        <Text
          variant="p"
          textColor="rgba(12, 15, 74, 0.5)"
          class="md:!tw-text-center"
          >Don't miss out on the opportunity to expand your knowledge and skills
          with our diverse selection of courses.</Text
        >
        <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
          >Happy learning!</Text
        >
      </div>
      <div class="dashboard-welcome__img md:!tw-w-full">
        <div class="img-wrapper">
          <img
            class="md:!tw-w-full"
            src="../../../assets/images/completed-course-image.png"
            width="364"
            height="144"
          />
        </div>
      </div>
    </div>

    <Button
      label="VIEW ALL COURSEs"
      class="!tw-w-[342px]"
      v-if="!filterListOfEnrollCourse?.length"
      @click="routeToAllCourses"
    />
    <div
      class="courses__list all__courses"
      v-if="filterListOfEnrollCourse?.length"
    >
      <div class="courses__title">
        <h3>Completed Courses</h3>
      </div>
      <div
        v-if="!loading && filterListOfEnrollCourse?.length"
        class="courses__card__list"
      >
        <v-card
          class="courses__card completed_card"
          v-for="(items, index) in filterListOfEnrollCourse"
          :key="index"
        >
          <section
            class="tw-flex tw-flex-col tw-justify-between tw-w-full tw-gap-3 tw-h-full"
          >
            <div class="tw-flex tw-flex-col tw-w-full tw-gap-2 tw-h-full">
              <div class="courses__card__img">
                <img
                  class=""
                  cover
                  :src="
                    require('@/assets/images/course/' +
                      items?.course?.thumbnail)
                  "
                />
              </div>
              <h5 class="courses__card__title">{{ items?.course?.title }}</h5>
              <div
                class="courses__card__prefix !tw-w-full !tw-flex-col !tw-justify-between !tw-flex !tw-items-start"
              >
                <Text
                  class="course-ellipsis"
                  textColor="rgba(12,15,74,0.5)"
                  variant="span"
                  lineHeight="20px"
                  >{{ items?.course?.about }}</Text
                >
                <div class="tw-flex tw-items-center tw-gap-2 tw-justify-start">
                  <CalendarIcon class="tw-min-w-[11px] tw-min-h-[13px]" />
                  <Text variant="span" textColor="rgba(12, 15, 74, 0.5)">{{
                    formatDate(
                      items?.userCourseSchedules[0]?.courseSchedule?.startDate
                    )
                  }}</Text>
                </div>
                <div class="tw-flex tw-items-center tw-gap-2 tw-justify-start">
                  <MapMarkerBlueIcon class="tw-min-w-[11px] tw-min-h-[13px]" />
                  <Text variant="span" textColor="rgba(12, 15, 74, 0.5)">{{
                    items?.courseMode
                  }}</Text>
                </div>
                <div class="tw-flex tw-items-center tw-gap-2 tw-justify-start">
                  <MapMarkerYellowIcon
                    class="tw-min-w-[11px] tw-min-h-[13px]"
                  />
                  <Text
                    variant="span"
                    textColor="rgba(12, 15, 74, 0.5)"
                    lineHeight="20px"
                    >{{
                      items?.userCourseSchedules[0]?.courseSchedule?.address
                    }}</Text
                  >
                </div>
              </div>
            </div>

            <Button
              @click="viewCourseDetails(items?.course?.id, items?.id)"
              variant="secondary"
              label="view full course details"
            />
          </section>
        </v-card>
      </div>
      <CommonLoader v-else :loading="loading" />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { COURSES_STORE } from "@/store/modules/courses";
import { USER_STORE } from "@/store/modules/user";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { useRouter } from "vue-router";
import { airSourceHeatPumpData, greenSkillsData } from "../data/courseDetails";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import MapMarkerBlueIcon from "@/core/components/icons/MapMarkerBlueIcon.vue";
import MapMarkerYellowIcon from "@/core/components/icons/MapMarkerYellowIcon.vue";
import CalendarIcon from "@/core/components/icons/CalendarIcon.vue";

export default defineComponent({
  components: {
    CommonLoader,
    Button,
    Text,
    MapMarkerBlueIcon,
    MapMarkerYellowIcon,
    CalendarIcon,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const title = "Welcome to your Courses";
    const text =
      "Behold, your wall of excellence! These completed courses represent your mastery of newly acquired green building skills. Display them with pride, for they are your badges of honour. Now it's time to secure new projects and dream clients.";
    const titleEmptyState = "You have not yet completed in this courses.";
    const textEmptyState = `Explore our course catalog to find the perfect fit for your learning goals. Start by browsing our available courses. Once you find a course that piques your curiosity, simply "Enroll" to get started on your learning journey at the near by course available location.`;
    const loading = ref(false);
    const listOfCourse = ref([]) as any;
    const listOfEnrollCourse = ref([]) as any;
    const coursesWithExtraInfo = ref([]) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const assignCourseList = ref([]);
    const routeToAllCourses = () => {
      ctx.emit("onBack");
    };

    const getEnrollCourseData = async () => {
      try {
        loading.value = true;
        const response: any = await store.dispatch(
          `${COURSES_STORE}/getAssignedCourses`,
          {
            userId: user.value.id,
          }
        );
        if (response?.data?.length) {
          assignCourseList.value = response.data.map((items: any) => {
            if (items?.userCourse?.userCourseSchedules[0]?.id) {
              if (items?.userCourse?.course?.id === 1) {
                return {
                  ...items,
                  course: {
                    ...items?.userCourse?.course,
                    ...airSourceHeatPumpData,
                  },
                  userCourseSchedules: items?.userCourse?.userCourseSchedules,
                  courseMode: "In-Person & E-Learning",
                };
              }
              if (items?.userCourse?.course?.id === 2) {
                return {
                  ...items,
                  course: {
                    ...items?.userCourse?.course,
                    ...greenSkillsData,
                  },
                  userCourseSchedules: items?.userCourse?.userCourseSchedules,
                  courseMode: "In-Person & E-Learning",
                };
              }
            }
          });
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };
    const filterListOfEnrollCourse: any = computed(() =>
      assignCourseList.value.filter((course: any) => course?.result)
    );

    const viewCourseDetails = async (courseID: number, recordId: number) => {
      const course = filterListOfEnrollCourse.value.find(
        (item: any) => item.id === recordId
      );
      if (course) {
        await store.dispatch(`${COURSES_STORE}/saveCourseDetails`, course);
        router.push({
          name: "courseDetails",
          params: { courseId: courseID, variableName: "completed" },
        });
      } else {
        console.error(`Course with ID ${courseID} not found.`);
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      // Extracting date components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      return `${day}-${month}-${year}`;
    };

    onMounted(async () => {
      getEnrollCourseData();
    });

    return {
      title,
      text,
      loading,
      listOfCourse,
      coursesWithExtraInfo,
      user,
      viewCourseDetails,
      listOfEnrollCourse,
      formatDate,
      textEmptyState,
      titleEmptyState,
      routeToAllCourses,
      filterListOfEnrollCourse,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";

.course-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(1.2em * 3);
  line-height: 1.2em;
  max-height: calc(1.2em * 3);
}
</style>
