<template>
  <div class="profile_content md:!tw-pb-3 md:!tw-box-border">
    <v-tabs class="tab-list" v-model="tab" align-tabs="center" stacked>
      <v-tab value="tab-1"> Personal Details </v-tab>

      <v-tab value="tab-2"> Password Details </v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item v-for="i in 2" :key="i" :value="'tab-' + i">
        <PersonalDetailsComponent v-if="i === 1" />
        <PasswordDetailsComponent v-if="i === 2" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import PersonalDetailsComponent from "@/modules/profile/components/PersonalDetailsComponent.vue";
import PasswordDetailsComponent from "@/modules/profile/components/PasswordDetailsComponent.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ProfilePage",
  components: {
    PersonalDetailsComponent,
    PasswordDetailsComponent,
  },
  setup() {
    const tab = ref(null);
    return { tab };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/profile/styles/profile.scss";
</style>
