<template>
  <div class="chatcomment__section">
    <div class="chatcomment__area">
      <div class="chatcomment__title">
        <div
          class="comment__wrapper"
          :class="{ active: isShowDashboard.type === 'comment' }"
          @click="onClickShow('comment')"
        >
          <h6>Comments</h6>
          <span>{{ projectComments.length }}</span>
        </div>
        <span>|</span>
        <div
          class="chat__wrapper"
          :class="{ active: isShowDashboard.type === 'chat' }"
          @click="onClickShow('chat')"
        >
          <h6>Chats</h6>
          <span>{{ selectedUserMessages.length }}</span>
        </div>
      </div>
    </div>

    <CommentsComponent
      v-if="isShowDashboard.type === 'comment' && isShowDashboard.isActive"
      :projectComments="projectComments"
      :activeComment="activeComment"
      :projectId="projectId"
      :userImagesMap="userImagesMap"
      @toggleActiveComment="toggleActiveComment"
      @updateCommentRoomStatus="updateCommentRoomStatus"
    />

    <!-- <div class="chatcomment__overlay overlay"></div> -->
  </div>
</template>

<script lang="ts">
import CommentsComponent from "@/core/components/CommentsComponent.vue";
import { inject, onBeforeUnmount, onMounted, ref, computed } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
export default {
  name: "ChatCommentsComponent",
  components: {
    CommentsComponent,
  },
  props: {
    isShowDashboard: {
      type: Object,
      required: true,
    },
    projectComments: {
      type: Array,
      required: true,
    },
    activeComment: {
      type: Object,
    },
    projectId: {
      type: Number,
    },
    userImagesMap: {
      type: Object,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const customBorderLayout: any = inject("customBorderLayout");
    const selectedUserMessages: any = ref([]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const onClickShow = (type: any) => {
      ctx.emit("openMessageDashboard", type);
    };
    const toggleActiveComment = (elementId: any) => {
      ctx.emit("toggleActiveComment", elementId);
    };
    const updateCommentRoomStatus = (commentRoomId: number, status: string) => {
      ctx.emit("updateCommentRoomStatus", commentRoomId, status);
    };

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    onMounted(() => {
      if (customBorderLayout.value) {
        customBorderLayout.value.classList.add("custom__border");
      }
    });
    onBeforeUnmount(() => {
      if (customBorderLayout.value) {
        customBorderLayout.value.classList.remove("custom__border");
      }
    });

    return {
      onClickShow,
      selectedUserMessages,
      toggleActiveComment,
      updateCommentRoomStatus,
      user,
      activeUserWorkstation,
    };
  },
};
</script>

<style lang="scss" scoped>
.chatcomment__section {
  background-color: rgba(255, 165, 0, 1);
  width: 217px;
  margin: 0 auto;
  position: fixed;
  top: 5px;
  left: 0;
  right: 0;
  border-radius: 0 0 8px 8px;
  .chatcomment__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    .comment__wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      h6 {
        color: #fff;
        @include fluidFont(14, 14, 1.2);
        letter-spacing: 0.25px;
      }
      span {
        color: #fff;
        @include fluidFont(8, 8, 1);
        letter-spacing: 0.25px;
        border: 1px solid #fff;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .chat__wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      h6 {
        color: #fff;
        @include fluidFont(14, 14, 1.2);
        letter-spacing: 0.25px;
      }
      span {
        color: #fff;
        @include fluidFont(8, 8, 1);
        letter-spacing: 0.25px;
        border: 1px solid #fff;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .active {
      background-color: rgba(255, 255, 255, 0.24);
      padding: 4px;
      border-radius: 4px;
    }
    span {
      color: #fff;
      font-weight: 700;
    }
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(197, 210, 242, 0.5);
  left: 0;
  top: 0;
  z-index: -1;
}
</style>
