import $axios from "../utils/axios-api-config";

export default {
  createPersonalEvent(
    userId: number,
    name: string,
    description: string,
    location: string,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    invitePeople: string
  ) {
    return $axios.post(`/trader/calendars?userId=${userId}`, {
      name,
      description,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      invitePeople,
    });
  },
  getPersonalCalendarEvent(params = {}) {
    return $axios.get(`/trader/calendars`, { params });
  },
  deleteCalendarEvent(TraderCalenderId: number) {
    return $axios.delete(
      `/trader/calendars?TraderCalenderId=${TraderCalenderId}`
    );
  },
  updateCalendarEvent(TraderCalenderId: number, updateData: any) {
    return $axios.patch(
      `/trader/calendars/update?TraderCalenderId=${TraderCalenderId}`,
      updateData
    );
  },
  getProjectTradeSideCalendarData() {
    return $axios.get(`/projects/trade-calendar`);
  },
  getProjectPropertySideCalendarData() {
    return $axios.get(`/projects/property-calendar`);
  },
  getTeamCalendarData(TraderCalenderId: number, updateData: any) {
    return $axios.get(`/projects/team-calendar`);
  },
  getCalenderMemberEvent() {
    return $axios.get(`/trader/calendars/events`);
  },
};
