<template>
  <CollapsibleWidgetSlot title="Asset Register">
    <template #content>
      <section
        class="tw-flex tw-flex-col tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <div
          class="tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-between"
        >
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[412px]"
            placeholder="Search"
            v-model="filters.keywords"
          />
          <Button
            class="!tw-w-auto"
            label="New"
            v-if="isUserHasEditRight"
            @click="onOpenAssetRegisterModal"
          >
            <template #prefix>
              <v-icon icon="mdi-plus-circle"></v-icon>
            </template>
          </Button>
        </div>
        <div
          class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-p-4 tw-box-border tw-rounded-lg"
        >
          <PropertyAssetRegisterTable
            @on-view-register="onOpenViewAssetRegisterModal"
            @on-edit-register="onEditAsset"
            :isUserHasEditRight="isUserHasEditRight"
          />
        </div>

        <v-alert
          v-if="state.alert"
          class="tw-absolute tw-bottom-8 tw-right-0"
          transition="slide-y-transition"
          :title="state.alertConfig.title"
          :text="state.alertConfig.text"
          :type="state.alertType"
        >
        </v-alert>
      </section>
    </template>
  </CollapsibleWidgetSlot>

  <!-- MODALS -->
  <AddAssetRegisterModal
    v-if="assetRegisterModal"
    :isEditMode="isEditMode"
    :selectedAsset="selectedAsset"
    @on-close="onCloseAssetRegisterModal"
    @on-success="onSuccess"
    @on-error="onError"
  />
  <ViewAssetRegisterModal
    v-if="viewAssetRegisterModal"
    :selectedAsset="selectedAsset"
    @on-close="onCloseViewAssetRegisterModal"
  />
</template>
<script setup>
import { computed, reactive, ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import PropertyAssetRegisterTable from "@/modules/properties/components/Tables/PropertyAssetRegisterTable.vue";
import AddAssetRegisterModal from "@/modules/properties/components/Modals/AddAssetRegisterModal.vue";
import ViewAssetRegisterModal from "@/modules/properties/components/Modals/ViewAssetRegisterModal.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { CONNECTIONS } from "@/store/modules/connections";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";

const store = useStore();
const route = useRoute();

const filters = reactive({
  keywords: "",
});

const state = reactive({
  alert: false,
  alertType: "",
  actionType: "",
  alertConfig: {
    text: "",
    title: "",
  },
});
const assetRegisterModal = ref(false);
const viewAssetRegisterModal = ref(false);
const loading = ref(false);

const selectedAsset = ref(null);
const isEditMode = ref(false);

const propertyId = computed(() => route?.params?.propertyId);

const onEditAsset = (e) => {
  selectedAsset.value = e;
  isEditMode.value = true;
  onOpenAssetRegisterModal();
};

const onOpenAssetRegisterModal = () => {
  assetRegisterModal.value = true;
};
const onCloseAssetRegisterModal = () => {
  selectedAsset.value = null;
  isEditMode.value = false;
  assetRegisterModal.value = false;
};

const onOpenViewAssetRegisterModal = (e) => {
  selectedAsset.value = e;
  viewAssetRegisterModal.value = true;
};
const onCloseViewAssetRegisterModal = () => {
  selectedAsset.value = null;
  viewAssetRegisterModal.value = false;
};

const initialize = async () => {
  const payload = {
    propertyId: propertyId.value,
    params: {},
  };
  loading.value = true;
  try {
    await store.dispatch(`${PROPERTY_STORE}/fetchAssetRegisters`, payload);
  } catch (error) {
    loading.value = false;
  } finally {
    loading.value = false;
  }
};

const hideAlert = (event) => {
  setTimeout(() => {
    state.alert = false;
  }, 3000);
};

const setAlert = (e) => {
  // e.type = error or success
  // e.message = alert message
  // e.title = alert title
  state.alertType = e?.type;
  state.alert = true;
  state.alertConfig = {
    text: e?.message,
    title: e?.title,
  };
};

const onSuccess = (isAdd) => {
  const alert = {
    type: "success",
    message: isAdd ? "Asset added successfully" : "Asset edited successfully",
    title: "Success",
  };
  setAlert(alert);
  initialize();
};

const onError = () => {
  const alert = {
    type: "error",
    message: "Something went wrong. Please try again!",
    title: "Error",
  };
  setAlert(alert);
};
const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);

watch(
  () => state.alert,
  (newValue, oldValue) => {
    if (newValue) {
      hideAlert();
    }
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  await initialize();
});
</script>
<style lang="scss" scoped></style>
