import $axios from "../utils/axios-api-config";
export default {
  createIssue(payload: any) {
    return $axios.post("/property-issues", payload);
  },
  getAllIssue(issueType: string, issueStatus: string) {
    return $axios.get(
      "/property-issues?issueType=" + issueType + "&issueStatus=" + issueStatus
    );
  },
  getParticularIssue(id: number) {
    return $axios.get(`/property-issues/${id}`);
  },
  updateParticularIssue(id: number, payload: any) {
    return $axios.put(`/property-issues/${id}`, payload);
  },
  deleteParticularIssue(id: number) {
    return $axios.delete(`/property-issues/${id}`);
  },
};
