<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['edit_certificate_modal']"
    width="835px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header !tw-ml-0">
          <h4>Edit Certification</h4>
          <v-btn
            icon="mdi-close"
            @click="onClickCloseButton"
            variant="text"
          ></v-btn>
        </div>
        <div class="modal_form">
          <h4 v-if="certificateData">{{ certificateData.name }}</h4>
          <div class="cerificate__content" v-if="!loader">
            <div class="left_block">
              <h5>Attachment</h5>
              <div class="left_block__content">
                <div class="left_block__content__item" v-if="attachmentData">
                  <img src="../../../assets/icons/pdficon.svg" alt="" />
                  <p v-if="attachmentData[0]">
                    {{ attachmentData[0].originalName || "Upload" }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Members -->
            <div class="right_block">
              <h5>Members</h5>
              <div class="right_block__content">
                <v-text-field
                  placeholder="Search"
                  class="search_field"
                  bg-color="#fff"
                  prepend-inner-icon="mdi-magnify"
                  variant="outlined"
                  v-model="searchMembers"
                />
                <div class="members__list">
                  <ul class="members__list__status">
                    <li v-for="data in filteredMembers" :key="data.toUser.id">
                      <v-checkbox
                        color="indigo"
                        v-model="checkedMembers"
                        :value="data?.toUser?.id"
                      />
                      <div class="member_img">
                        <img
                          v-if="data.toUser?.profileURL"
                          :src="data.toUser?.profileURL"
                        />
                        <UserProfileLogo
                          v-else
                          :userName="`${data?.toUser?.firstName} ${data?.toUser?.lastName}`"
                        />
                      </div>
                      <p>
                        {{ data.toUser.firstName }} {{ data.toUser.lastName }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <v-btn
              class="button button-purple-border"
              width="50%"
              max-width="100%"
              @click="onClickCloseButton"
              >CANCEL</v-btn
            >
            <v-btn
              class="button button-orange"
              width="50%"
              max-width="100%"
              :disabled="loader"
              @click="updateMembers"
              >SAVE</v-btn
            >
          </div>
        </div>
        <div class="loader">
          <CommonLoader v-if="loader" :loading="loader" />
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { USER_STORE } from "@/store/modules/user";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { onMounted } from "vue";
import { getImageStringToImageURL, parseJSON } from "@/core/utils/common";
import CommonLoader from "@/core/components/CommonLoader.vue";

export default {
  props: {
    userCertificateData: { type: Object, default: null },
  },

  components: {
    CommonDialog,
    UserProfileLogo,
    CommonLoader,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userCertificateModalData = ref(null) as any;
    const certificateData = ref(null) as any;
    const attachmentData = ref(null) as any;
    const workstationMemberList = ref([]) as any;
    const checkedMembers = ref([]) as any;
    const searchMembers = ref("");
    const loader = ref(false);

    onMounted(async () => {
      userCertificateModalData.value = props.userCertificateData;
      certificateData.value = userCertificateModalData.value.certificate;
      attachmentData.value = userCertificateModalData.value.attachment;
      checkedMembers.value =
        parseJSON(props.userCertificateData.assignedUserIds) || [];

      await setWorkstationMembers();
    });

    const setWorkstationMembers = async () => {
      try {
        loader.value = true;
        const projectDataResponse = await store.dispatch(
          `${MEMBER_STORE}/getTeamMember`,
          {
            userId: user.value?.id,
          }
        );
        workstationMemberList.value = projectDataResponse.filter(
          (member: any) => member.invitationStatus === "accepted"
        );

        for (const member of workstationMemberList.value) {
          if (member?.toUser?.userPublicProfile?.profileImage) {
            getImageStringToImageURL(
              member?.toUser?.userPublicProfile?.profileImage
            ).then((value) => {
              member.toUser.profileURL = value;
            });
          }
        }
        loader.value = false;
      } catch (error) {
        console.log("error: ", error);
      }
    };

    const updateMembers = async () => {
      const newAssignedUserIds = checkedMembers.value.filter(
        (id: any) =>
          !(
            parseJSON(userCertificateModalData.value.assignedUserIds) || []
          ).includes(id)
      );
      const deletedAssignedUserId = (
        parseJSON(userCertificateModalData.value.assignedUserIds) || []
      ).filter((id: any) => !checkedMembers.value.includes(id));

      if (newAssignedUserIds.length || deletedAssignedUserId.length) {
        if (newAssignedUserIds.length) {
          await store.dispatch(`${USER_STORE}/addCertificateRelatedMembers`, {
            userId: user?.value?.id,
            certificateRecordId: props.userCertificateData.id,
            teamMemberIds: {
              teamMemberIds: newAssignedUserIds,
            },
          });
        }
        if (deletedAssignedUserId.length) {
          await store.dispatch(
            `${USER_STORE}/removeCertificateRelatedMembers`,
            {
              userId: user?.value?.id,
              certificateRecordId: props.userCertificateData.id,
              teamMemberIds: {
                teamMemberIds: deletedAssignedUserId,
              },
            }
          );
        }

        await store.dispatch(
          `${USER_STORE}/setUserCertificates`,
          user.value?.id
        );
        ctx.emit("onSaveData");
      } else {
        ctx.emit("onClickCloseTab");
      }
    };

    // Serach members
    const filteredMembers = computed(() => {
      if (!searchMembers.value) {
        return workstationMemberList.value;
      }
      return workstationMemberList.value.filter((member: any) =>
        `${member.toUser.firstName} ${member.toUser.lastName}`
          .toLowerCase()
          .includes(searchMembers.value.toLowerCase())
      );
    });

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    return {
      user,
      onClickCloseButton,
      certificateData,
      attachmentData,
      checkedMembers,
      searchMembers,
      filteredMembers,
      updateMembers,
      loader,
    };
  },
};
</script>
