<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M3.47296 5.34289C4.5065 5.34289 5.34435 4.50504 5.34435 3.47149C5.34435 2.43795 4.5065 1.6001 3.47296 1.6001C2.43941 1.6001 1.60156 2.43795 1.60156 3.47149C1.60156 4.50504 2.43941 5.34289 3.47296 5.34289Z"
        fill="#0C0F4A"
      />
      <path
        d="M8.0003 9.8717C9.03384 9.8717 9.8717 9.03384 9.8717 8.0003C9.8717 6.96676 9.03384 6.12891 8.0003 6.12891C6.96676 6.12891 6.12891 6.96676 6.12891 8.0003C6.12891 9.03384 6.96676 9.8717 8.0003 9.8717Z"
        fill="#0C0F4A"
      />
      <path
        d="M3.47296 9.8717C4.5065 9.8717 5.34435 9.03384 5.34435 8.0003C5.34435 6.96676 4.5065 6.12891 3.47296 6.12891C2.43941 6.12891 1.60156 6.96676 1.60156 8.0003C1.60156 9.03384 2.43941 9.8717 3.47296 9.8717Z"
        fill="#0C0F4A"
      />
      <path
        d="M8.0003 14.4C9.03384 14.4 9.8717 13.5622 9.8717 12.5286C9.8717 11.4951 9.03384 10.6572 8.0003 10.6572C6.96676 10.6572 6.12891 11.4951 6.12891 12.5286C6.12891 13.5622 6.96676 14.4 8.0003 14.4Z"
        fill="#0C0F4A"
      />
      <path
        d="M3.47296 14.4C4.5065 14.4 5.34435 13.5622 5.34435 12.5286C5.34435 11.4951 4.5065 10.6572 3.47296 10.6572C2.43941 10.6572 1.60156 11.4951 1.60156 12.5286C1.60156 13.5622 2.43941 14.4 3.47296 14.4Z"
        fill="#0C0F4A"
      />
      <path
        d="M8.0003 5.34289C9.03384 5.34289 9.8717 4.50504 9.8717 3.47149C9.8717 2.43795 9.03384 1.6001 8.0003 1.6001C6.96676 1.6001 6.12891 2.43795 6.12891 3.47149C6.12891 4.50504 6.96676 5.34289 8.0003 5.34289Z"
        fill="#0C0F4A"
      />
      <path
        d="M12.5316 9.8717C13.5651 9.8717 14.4029 9.03384 14.4029 8.0003C14.4029 6.96676 13.5651 6.12891 12.5316 6.12891C11.498 6.12891 10.6602 6.96676 10.6602 8.0003C10.6602 9.03384 11.498 9.8717 12.5316 9.8717Z"
        fill="#0C0F4A"
      />
      <path
        d="M12.5316 5.34289C13.5651 5.34289 14.4029 4.50504 14.4029 3.47149C14.4029 2.43795 13.5651 1.6001 12.5316 1.6001C11.498 1.6001 10.6602 2.43795 10.6602 3.47149C10.6602 4.50504 11.498 5.34289 12.5316 5.34289Z"
        fill="#0C0F4A"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AvailableSpotIcon",
};
</script>
