export const greenSkillsData = {
  thumbnail: "green-skills.jpg",
  title: "Solar PV, Battery Storage & EV Charging Level 3",
  templateComponentName: "GreenSkillsTemplate",
  about: [
    "This Bootcamp Package is designed for existing, qualified electricians looking to up-skill to design, install and maintain new and existing green technologies including Solar PV, Electrical Energy (Battery) Storage and Electric Vehicle Charging Installations.",
  ],
  summary: [
    {
      title: "Solar PV",
      description:
        "Aimed at existing electricians, supporting the development of knowledge/understanding of Solar PV installations. Learners will understand the specific requirements for the design and installation of domestic/small commercial solar PV installations.",
    },
    {
      title: "EV Car Charging",
      description:
        "Aimed at existing electricians, supporting the development of knowledge/understanding of electric vehicle charging design and installations. Learners will understand the specific requirements for the design and installation of domestic and small commercial electric vehicle charging installations.",
    },
    {
      title: "Electrical Energy Storage Systems",
      description:
        "This qualification is aimed at existing electricians, supporting the development of knowledge/understanding of energy storage. Learners will understand the specific requirements for design and installation of domestic electrical energy storage systems.",
    },
    {
      title: "Introduction to Domestic Retrofit",
      description:
        "Covers how to assess dwellings for retrofit, identify improvement options, design and specify energy efficiency measures. This course goes hand in hand with green skills and will give learners knowledge of the subject area in order to deal with customer questions about improving energy performance of property.",
    },
  ],
  aims: [
    "Obtain Consumer Code certificate",
    "Join MCS as a certified installer",
    "Offer solar package installation services",
  ],
  courseDetails: [
    {
      dayCount: "5",
      locationType: "Workshop",
      name: "Solar PV",
      description: "Level 3 - (BPEC 0018)",
    },
    {
      dayCount: "1",
      locationType: "Classroom",
      name: "Introduction to Domestic Retrofit",
      description: "Digital Credential – Introduction to Domestic Retrofit",
    },
    {
      dayCount: "2",
      locationType: "Workshop",
      name: "EV Car Charging",
      description: "Level 3 - (City and Guilds 2921-31)",
    },
    {
      dayCount: "2",
      locationType: "Workshop",
      name: "Electrical Energy Storage Systems",
      description: "Level 3 - (BPEC 0059)",
    },
  ],
};

export const airSourceHeatPumpData = {
  thumbnail: "air-source.jpg",
  title: "Air Source Heat Pump (Level 3)",
  templateComponentName: "AirSourceTemplate",
  about: [
    "This Level 3 Air Source Heat Pump boot camp is developed in partnership with City & Guilds Training and is designed to help you upskill as a certified Air Source Heat Pump Installer.",

    "Successful completion will enable you to register with the Microgeneration Certification Scheme (MCS) and allow you to apply to a relevant Consumer Code.",

    "This course is structured over two separate weeks, with a 10-day break in between, allowing you to continue with your regular work schedule before diving back into the second week.",
  ],

  summary: [
    {
      title: "Minor Electrical Works (Part 1)",
      description:
        "Allows heat pump installers to conduct basic minor electrical works, e.g connecting a feed to the equipment. Includes different circuits and scenario based activities. Learners also test their own installations to ensure they are safe.",
    },
    {
      title: "Minor Electrical Works (Part 2)",
      description:
        "eLearning introduces fundamental requirements for making additions or alterations to existing electrical circuits. Explores specific requirements for IET Wiring Regulations and Building Regulations.",
    },
    {
      title: "Introduction to domestic retrofit",
      description:
        "Covers how to assess dwellings for retrofit (heat pumps), identify improvement options, design and specify energy efficiency measures. This knowledge goes hand-in-hand with heat pump installations to ensure they have capacity to work efficiently.",
    },
    {
      title: "Water Regulations",
      description:
        "Gives an understanding of water regulations/water bylaws - a pre-requisite for anyone undertaking a heat pump qualification.",
    },
    {
      title: "Heat Pumps",
      description:
        "Aimed at experienced heating installers who wish to expand their knowledge/skills to include fitting heat pumps in domestic and small commercial properties. Successful learners will also meet entry requirements for the Micro-generation Certification Scheme (MCS).",
    },
  ],
  aims: [
    "Obtain Consumer Code certificate",
    "Join MCS as a certified installer",
    "Offer heat pump installation services",
  ],
  courseDetails: [
    {
      dayCount: "5",
      locationType: "Domestic Workshop",
      name: "BPEC",
      description: "Low-temperature heating and hot water design",
    },
    {
      dayCount: "1",
      locationType: "Classroom",
      name: "BPEC",
      description: "Domestic air source heat pumps",
    },
    {
      dayCount: "1",
      locationType: "Classroom",
      name: "BPEC",
      description: "Water regulations",
    },
    {
      dayCount: "3",
      locationType: "Heat Pump Workshop",
      name: "BPEC",
      description: "G3 stored hot water",
    },
  ],
  breakDown: [
    {
      id: 1,
      label: "Week 1",
      days: [
        "BPEC Water Regulations",
        "BPEC G3 Stored Hot Water",
        "BPEC Low-Temperature Heating & Hot Water Design",
        "Low-Temperature Heating Systems Design",
        "Heat Loss Scenarios and Assessment",
      ],
    },
    {
      id: 2,
      label: "Week 2",
      days: [
        "Electrical for Heat Pumps",
        "BPEC Domestic Air Source Heat Pump",
        "BPEC Domestic Air Source Heat Pump",
        "BPEC Domestic Air Source Heat Pump",
        "Putting theory to practice (Exams & Onsite Experience).",
      ],
    },
  ],
};
