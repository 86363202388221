<template>
  <CollapsibleWidgetSlot title="Property Insurance">
    <template #content>
      <section
        class="tw-flex tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <v-radio-group v-model="selectedInsurance" @change="onSelect">
          <div
            class="tw-flex tw-flex-col tw-gap-4"
            v-if="props.insurances && props.insurances.length"
          >
            <Card
              bgColor="#F1F6FB"
              v-for="(insurance, index) in props.insurances"
              :key="index"
            >
              <template #content>
                <section
                  class="tw-p-3 tw-box-sizing tw-w-full tw-h-full tw-flex-col-between"
                >
                  <div
                    class="tw-w-full tw-flex tw-items-center tw-justify-start tw-pb-3 tw-box-border tw-border-b-dashed-custom"
                  >
                    <v-radio color="#FFA500" :value="insurance">
                      <template v-slot:label>
                        <div>
                          <Text variant="p">{{
                            getInsuranceName(insurance.propertyInsuranceType)
                          }}</Text>
                        </div>
                      </template></v-radio
                    >
                  </div>
                  <div class="tw-flex tw-gap-3 tw-flex-col tw-w-full tw-h-full">
                    <div class="tw-flex-row-between">
                      <Text>Premium Cover</Text>
                      <div class="tw-flex tw-items-center tw-gap-1">
                        <Text
                          variant="xsmall"
                          textColor="rgba(12, 15, 74, 0.5)"
                          >{{
                            CurrencyFilter.formatToCurrency(
                              insurance?.coverAmount
                            )
                          }}</Text
                        >
                      </div>
                    </div>
                    <div class="tw-flex-row-between">
                      <Text>Expire Date</Text>
                      <div class="tw-flex tw-items-center tw-gap-1">
                        <Text variant="xsmall">{{
                          appFilters.formatToDate(insurance.expiryDate)
                        }}</Text>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-flex-row-between tw-pt-3 tw-box-border tw-border-t-dashed-custom"
                  >
                    <ChipState
                      :bgColor="insurance.isExpired ? '#F45454' : '#40903E'"
                      :label="insurance.isExpired ? 'Expired' : 'Active'"
                      textColor="#FFF"
                    ></ChipState>
                    <!-- <el-divider direction="vertical"></el-divider>
                    <div
                      class="tw-flex tw-items-center tw-gap-1 tw-cursor-pointer"
                    >
                      <Text variant="span" textColor="#FFA500">View</Text>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </div> -->
                  </div>
                </section>
              </template>
            </Card>
          </div>
        </v-radio-group>
      </section>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { ref } from "vue";

import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import appFilters from "@/filters";
import CurrencyFilter from "@/filters/currency.filter";

const props = defineProps(["insurances"]);
const emits = defineEmits(["on-select-insurance"]);

const selectedInsurance = ref(null);

const onSelect = () => {
  if (!selectedInsurance.value) return;
  emits("on-select-insurance", selectedInsurance.value);
};

const getInsuranceName = (type) => {
  if (type === "1") {
    return "Building Instance";
  }
  if (type === "2") {
    return "Contents Insurance";
  }
};
</script>
<style lang="scss" scoped></style>
