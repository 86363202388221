import EWalletPage from "../pages/EWalletPage.vue";

export const E_WALLET = "e-wallet";

export default {
  path: "/e-wallet",
  name: E_WALLET,
  component: EWalletPage,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "E-Wallet",
  },
};
