<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_250_138522)">
      <path
        d="M12 1C5.92412 1 1 5.92412 1 12C1 18.0759 5.92412 23 12 23C18.0759 23 23 18.0759 23 12C23 5.92412 18.0759 1 12 1ZM12 21.8676C6.55824 21.8676 2.13235 17.4418 2.13235 12C2.13235 11.0424 2.26824 10.0976 2.54 9.18529C2.54647 9.16588 2.55294 9.14 2.55941 9.12059C2.57235 9.08177 2.59824 9.05588 2.61118 9.02353C2.63706 8.97176 2.65647 8.92 2.69529 8.87471C2.72765 8.82941 2.77294 8.79059 2.81176 8.75824C2.84412 8.73235 2.86353 8.7 2.89588 8.68059C2.90235 8.67412 2.91529 8.67412 2.92176 8.66765C2.97353 8.63529 3.03176 8.62235 3.08353 8.60294C3.12882 8.59 3.17412 8.56412 3.21941 8.55765C3.27118 8.55118 3.31647 8.56412 3.36176 8.56412C3.42 8.56412 3.47824 8.56412 3.53647 8.57706C3.54941 8.57706 3.55588 8.57706 3.56882 8.57706C3.60765 8.59 3.63353 8.61588 3.66588 8.62882C3.71765 8.65471 3.76941 8.67412 3.81471 8.71294C3.86 8.74529 3.89882 8.78412 3.93118 8.82941C3.95706 8.85529 3.98941 8.87471 4.01529 8.91353L6.43529 12.66C6.68118 13.0353 6.57118 13.54 6.19588 13.7794C6.06 13.87 5.91118 13.9088 5.75588 13.9088C5.49059 13.9088 5.23176 13.7794 5.07647 13.54L3.76941 11.5147C3.75647 11.67 3.75 11.8318 3.75 11.9935C3.75 16.5424 7.45118 20.2435 12 20.2435C16.5488 20.2435 20.25 16.5424 20.25 11.9935C20.25 7.44471 16.5488 3.74353 12 3.74353C9.28882 3.74353 6.75235 5.07647 5.21235 7.30882C4.95353 7.67118 4.44882 7.76824 4.08647 7.50941C3.71765 7.25706 3.62706 6.75235 3.87941 6.38353C5.72353 3.71765 8.75824 2.12588 12 2.12588C17.4418 2.12588 21.8676 6.55176 21.8676 11.9935C21.8676 17.4353 17.4418 21.8612 12 21.8612V21.8676ZM14.8341 13.6953C15.1512 14.0059 15.1512 14.5235 14.8341 14.8341C14.6788 14.9959 14.4718 15.0735 14.2647 15.0735C14.0576 15.0735 13.8506 14.9959 13.6953 14.8341L11.4306 12.5694C11.2753 12.4206 11.1912 12.2135 11.1912 12V7.47059C11.1912 7.02412 11.5535 6.66177 12 6.66177C12.4465 6.66177 12.8088 7.02412 12.8088 7.47059V11.6635L14.8341 13.6953Z"
        fill="#3B67E9"
      />
    </g>
    <defs>
      <clipPath id="clip0_250_138522">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "InProgressBlueIcon",
};
</script>
