<template>
  <div class="verify_jobs_wrapper">
    <!-- Tabs -->
    <v-tabs
      class="tab-list"
      align-tabs="center"
      stacked
      v-model="activeTab"
      v-if="isListJobs"
    >
      <v-tab v-for="item in verifyJobItems" :key="item.name" :value="item.name">
        <v-icon :icon="item.icon" />
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs
      class="tab-list"
      align-tabs="center"
      stacked
      v-model="activeTab"
      v-else
    >
      <v-tab
        v-for="item in filterVerifyJobItems"
        :key="item.name"
        :value="item.name"
      >
        <v-icon :icon="item.icon" />
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <!-- Tabs Data -->
    <RequestsComponent
      v-if="activeTab === 'Requests'"
      @redirectTab="redirectTab"
    />
    <VerifiedJobDetails
      v-if="activeTab === 'VerifyJobs'"
      @redirectTab="redirectTab"
    />
    <VerifiedJobComponent
      v-if="activeTab === 'Verified'"
      @redirectTab="redirectTab"
    />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, watch, computed, ref } from "vue";
import RequestsComponent from "@/modules/verify-historical-projects/components/RequestsComponent.vue";
import VerifiedJobComponent from "@/modules/verify-historical-projects/components/VerifiedJobComponent.vue";
import VerifiedJobDetails from "../components/VerifiedJobDetails.vue";

const router = useRouter();
const activeTab = ref("Requests");
const isListJobs = ref(true);
const verifyJobItems = ref([
  { name: "Requests", icon: "mdi-home" },
  { name: "Verified", icon: "mdi-tools" },
]);
const filterVerifyJobItems = ref([{ name: "VerifyJobs", icon: "mdi-tools" }]);

const redirectTab = (tabName) => {
  if (tabName === "VerifyJobs") {
    activeTab.value = "VerifyJobs";
    isListJobs.value = false;
  } else if (tabName === "Requests") {
    isListJobs.value = true;
    activeTab.value = "Requests";
  } else {
    isListJobs.value = true;
    activeTab.value = "Verified";
  }
};
</script>
