<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.83328 8.44482C7.83328 9.42659 7.08713 10.2225 6.16672 10.2225C5.24615 10.2225 4.5 9.42659 4.5 8.44482C4.5 7.46289 5.24615 6.66699 6.16672 6.66699C7.08713 6.66699 7.83328 7.46289 7.83328 8.44482Z"
      fill="#0C0F4A"
    />
    <path
      d="M19.5 4H4.5C3.12167 4 2 5.19645 2 6.66667V17.3333C2 17.3831 2.0116 17.4302 2.01419 17.48C2.00244 17.5866 2.00748 17.6942 2.0441 17.7956C2.24994 19.0454 3.26999 20 4.5 20H19.5C20.8783 20 22 18.8035 22 17.3333V6.66667C22 5.19645 20.8783 4 19.5 4ZM4.5 5.77783H19.5C19.9591 5.77783 20.3333 6.17692 20.3333 6.66667V13.7236L15.95 9.04801C15.38 8.43994 14.4525 8.43994 13.8834 9.04801L9.91672 13.2791L8.86661 12.1592C8.29669 11.5511 7.36911 11.5511 6.79996 12.1592L3.66672 15.5013V6.66667C3.66672 6.17692 4.04086 5.77783 4.5 5.77783V5.77783Z"
      fill="#0C0F4A"
    />
  </svg>
</template>

<script>
export default {
  name: "ImageIcon",
};
</script>
