export default {
  estimatesSent: {
    iknowaCount: 0,
    iknowaPercentage: 0,
    directCount: 0,
    directPercentage: 0,
    previousEstimateCount: 0,
    previousDirectEstimateCount: 0,
  },
  jobsCompleted: {
    iknowaCount: 0,
    iknowaPercentage: 0,
    directCount: 0,
    directPercentage: 0,
    previousJobCompleteCount: 0,
    previousDirectJobcompleteCount: 0,
  },
  customerRating: 4,
  revenueOverview: {
    iknowaResidential: 10,
    iknowaCommercial: 40,
    directResidential: 35,
    directCommercial: 15,
    total: 100,
    totalRevenuePercentage: 20.22,
  },
  revenueData: {
    iknowaRevenue: 0,
    directRevenue: 0,
    totalRevenue: 0,
    iknowaPercentage: 0,
    directPercentage: 0,
    previousPeriodIknowaRevenue: 0,
    previousPeriodDirectRevenue: 0,
    iknowaRevenueByPropertyCategory: {
      residential: 0,
      commercial: 0,
    },
    directRevenueByPropertyCategory: {
      residential: 0,
      commercial: 0,
    },
  },
  revenuePerformance: {
    iknowaRevenueData: [
      151654.58, 281654.58, 81654.58, 201654.58, 201654.58, 201654.58,
    ],
    directRevenue: [
      151654.58, 281654.58, 81654.58, 201654.58, 201654.58, 201654.58,
    ],
  },
  // revenuePerformanceData: null,
  revenuePerformanceData: [
    {
      month: "Aug",
      iknowa: 0,
      direct: 0,
    },
    {
      month: "Sep",
      iknowa: 0,
      direct: 0,
    },
    {
      month: "Oct",
      iknowa: 0,
      direct: 0,
    },
  ],
  revenueByService: {
    gasHeating: 155,
    eletrical: 80,
    insulation: 71,
    windows: 40,
    total: 4565.67,
  },
  jobDurationByService: {
    gasHeating: 155,
    eletrical: 80,
    insulation: 71,
    windows: 40,
    total: 527,
  },
  revenueAndJobService: {
    serviceRevenue: {},
    totalRevenue: 0,
    totalDaysWorked: 0,
  },
  // revenueAndJobService: {
  //   serviceRevenue: {
  //     Architectural: {
  //       revenue: 100,
  //       days: 5,
  //     },
  //     Electrical: {
  //       revenue: 40,
  //       days: 2,
  //     },
  //   },
  //   totalRevenue: 140,
  //   totalDaysWorked: 7,
  // },

  teamPerformance: [],
};
