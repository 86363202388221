<template>
  <WizardModal
    :hideFooter="false"
    :hideStepper="true"
    :hasCustomActionFooter="true"
    :hideBackButton="false"
    :currentStep="2"
    :hideClose="true"
    :singleData="modelDetails"
    :showHeaderInMobile="true"
    :hideTitleAndDescription="true"
    :contentOverflowHidden="true"
    width="704px"
    height="543px"
    @on-close="onCloseModal"
    @on-back="onBack"
  >
    <template #content>
      <main
        class="tw-overflow-y-auto tw-flex tw-flex-col tw-h-full tw-min-h-[unset] tw-justify-between !tw-mt-0 rmd:!tw-mt-4 tw-gap-4 tw-pt-0 tw-box-border"
      >
        <section class="tw-flex tw-flex-col tw-h-full tw-gap-4">
          <div class="tw-flex-row-between !tw-items-start md:!tw-flex-col">
            <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
              <Text variant="h4" class="md:!tw-text-[21px]" textWeight="400"
                >Do you have a Workstation?</Text
              >
              <Text variant="span" textWeight="400"
                >Select a workstation you would like to assign the course
                to.</Text
              >
            </div>
            <Text
              variant="h6"
              textColor="#4F55F0"
              class="tw-cursor-pointer"
              @click="onCreateWorkstation"
              >Create a New Workstation
              <v-icon icon="mdi-chevron-right" color="#4F55F0"></v-icon
            ></Text>
          </div>

          <div class="tw-flex tw-flex-col tw-gap-3 tw-items-start">
            <Text variant="h5">Select Workstation</Text>
            <InputSelect
              class="!tw-w-full md:!tw-w-full"
              placeholder="Select workstation"
              :items="filteredUserAllWorkStation"
              variant="outlined"
              v-model="selectedWorkstation"
              item-title="name"
              item-value="id"
            />
          </div>
        </section>
      </main>
    </template>
    <template #footer-action>
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-4 tw-pt-4 tw-box-border tw-border-t-solid-custom md:!tw-justify-center md:!tw-flex-col-reverse md:!tw-gap-[8px] md:!tw-pb-16"
      >
        <div
          class="tw-flex tw-items-center tw-gap-4 md:!tw-gap-4px md:!tw-flex-col md:!tw-w-full"
        >
          <Button
            class="!tw-w-auto md:!tw-w-full"
            label="back"
            @click="onBack"
            variant="secondary"
          />
          <Button
            class="!tw-w-auto md:!tw-w-full"
            label="launch workstation"
            :disabled="!selectedWorkstation"
            @click="onLaunchWorkstation"
          />
        </div>
      </section>
    </template>
  </WizardModal>
</template>
<script setup>
import { computed, ref, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// import CommonDialog from "@/core/components/CommonDialog.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import InputSelect from "@/core/components/common/InputSelect.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_LAUNCH_WS,
  DASHBOARD_WITH_COURSE_ROUTE,
} from "@/modules/dashboard/routes";

const store = useStore();
const route = useRoute();
const router = useRouter();
const courseId = ref(null);
const courseEligibility = ref([]);

const isLoading = ref(false);
const isShowCertificateModal = ref(false);
const tcOptIn = ref(false);

const selectedWorkstation = ref(null);

const modelDetails = {
  id: 2,
  tab: "preEnrollTerms",
  name: "preEnrollTerms",
  header: "",
};

const emits = defineEmits(["on-close", "on-back"]);

const userAllWorkStation = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkstations`]
);

const filteredUserAllWorkStation = computed(() =>
  userAllWorkStation.value.filter(
    (workstation) => workstation.name !== "Default"
  )
);

const reservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
);

const filteredReserveSpots = computed(() => {
  const courseId = String(route?.params?.courseId); // Convert courseId to a string

  if (reservedSpotsData.value && reservedSpotsData.value.length) {
    return reservedSpotsData.value.filter((spot) => {
      return String(spot.course?.id) === courseId && !spot.subscription;
    });
  }

  return [];
});

const onCloseModal = () => {
  return emits("on-close");
};

// const onCreateWorkstation = () => {
//   onClickBackButton();
//   store.dispatch(`${WORKSTATION}/setWsModal`, {
//     isShowWorkStationModal: true,
//   });
//   router.push({ name: DASHBOARD_ROUTE });
// };

const onBack = () => {
  emits("on-back");
};

const onCreateWorkstation = () => {
  store.dispatch(`${WORKSTATION}/setWsModal`, {
    isShowWorkStationModal: true,
    isFromCourse: true,
  });
  router.push({
    name: DASHBOARD_WITH_COURSE_ROUTE,
    params: route?.params.courseId,
  });
  emits("on-close");
};

const onLaunchWorkstation = () => {
  console.log(selectedWorkstation.value, "selectedWorkstation");
  // if launch ws first //
  // set isFromCourse
  store.dispatch(`${WORKSTATION}/setWsModal`, {
    isFromCourse: true,
  });
  // set reservationSpot data to local store
  store.dispatch(
    `${COURSES_STORE}/setStoredReservedSpotsData`,
    filteredReserveSpots.value
  );
  router.push({
    name: DASHBOARD_WITH_COURSE_LAUNCH_WS,
    params: {
      courseId: route?.params.courseId,
      workstationId: selectedWorkstation.value,
    },
  });
  onCloseModal();
};

onBeforeMount(() => {
  //
});

onBeforeUnmount(() => {
  //   eligibility.value = null;
});

onMounted(() => {
  console.log(filteredUserAllWorkStation.value, "filteredUserAllWorkStation");
});
</script>
<style lang="scss" scoped></style>
