import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SoleTraderDashboard = _resolveComponent("SoleTraderDashboard")!
  const _component_PropertyOwnerDashboard = _resolveComponent("PropertyOwnerDashboard")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.user?.role?.name == $setup.UserRolesEnum.TRADESPERSON)
      ? (_openBlock(), _createBlock(_component_SoleTraderDashboard, { key: 0 }))
      : _createCommentVNode("", true),
    ($setup.user?.role?.name == $setup.UserRolesEnum.PROPERTY_OWNER)
      ? (_openBlock(), _createBlock(_component_PropertyOwnerDashboard, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}