<template>
  <ChipState
    label="Not Started"
    bgColor="#FFF"
    :hasShadow="true"
    textColor="rgba(12, 15, 74, 0.8)"
  >
    <template #prefix>
      <InfoIconWhite bgFill="rgba(12, 15, 74, 0.8)" fill="#fff" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import InfoIconWhite from "@/core/components/icons/InfoIconWhite.vue";
</script>
<script>
export default {
  name: "NotStartedChip",
};
</script>
