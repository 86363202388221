<template>
  <Card class="!tw-max-h-[unset] !tw-min-w-[85px] !tw-p-2 tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col !tw-gap-3 tw-w-full">
        <section
          v-for="(item, index) in getMenuItems"
          :key="index"
          @click="onHandleAction(item.name)"
          class="tw-flex tw-items-center tw-gap-2 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <component
            class="tw-min-w-[20px] tw-min-h-[12px]"
            :is="item.icon"
          ></component>
          <Text
            class="hover:!tw-text-[#FFA500] !tw-capitalize"
            variant="p"
            textWeight="400"
            >{{ item.name }}</Text
          >
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { reactive, computed } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import EyeIcon from "@/core/components/icons/EyeIcon";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { DELETE, VIEW, EDIT } from "../constants";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import ChangePasswordFormVue from "@/modules/change-password/components/ChangePasswordForm.vue";

const props = defineProps({
  connectionRole: String,
  isShowEditBtn: { type: Boolean, default: false },
  isShowViewBtn: { type: Boolean, default: true },
});
const emits = defineEmits(["on-handle-action"]);

const menu = reactive([
  {
    icon: EyeIcon,
    name: VIEW,
  },
  {
    icon: TrashIcon,
    name: EDIT,
  },
  {
    icon: TrashIcon,
    name: DELETE,
  },
]);
const getMenuItems = computed(() =>
  menu.filter(
    (item) =>
      (props.connectionRole &&
        props.connectionRole === PropertyConnectionRoleEnum.OWNER &&
        item.name === DELETE) ||
      (item.name === VIEW && props.isShowViewBtn) ||
      (item.name === EDIT && props.isShowEditBtn)
  )
);
const onHandleAction = (name) => {
  emits("on-handle-action", name);
};
</script>
<style lang="scss" scoped></style>
