<template>
  <Card
    class="tw-min-h-[160px] tw-p-5 tw-box-border md:!tw-min-h-[unset] md:!tw-max-h-[unset] md:!tw-h-auto"
  >
    <template #content>
      <section class="tw-flex tw-flex-col tw-gap-4">
        <div
          class="tw-pb-4 tw-box-border tw-border-b-dashed-custom tw-flex-row-between md:!tw-flex-col md:!tw-items-start"
        >
          <Text
            variant="h4"
            textWeight="500"
            class="md:!tw-text-[20px] md:!tw-font-bold md:!tw-text-start"
            >{{ data?.title }}</Text
          >
          <div class="tw-flex tw-gap-4 tw-items-center md:!tw-gap-[.5rem]">
            <Button class="!tw-w-auto" label="manage" @click="onManage(data)" />
            <Button
              class="!tw-w-auto"
              variant="secondary"
              label="download invoice"
              @click="onDownloadInvoice(data)"
              :loading="
                isLoading &&
                props.selectedSubscription.subscriptionType ===
                  data.subscriptionType
              "
            />
          </div>
        </div>
        <div class="subscription-details-wrapper tw-w-full tw-grid tw-gap-4">
          <div class="tw-flex tw-flex-col tw-items-start tw-justify-start">
            <Text
              variant="p"
              textWeight="500"
              textColor="rgba(12, 15, 74, 0.5)"
              textAlign="left"
              >BILLING ITEM</Text
            >
            <Text variant="h6" textWeight="600" textAlign="left">{{
              data?.billingItem
            }}</Text>
          </div>
          <div class="tw-flex tw-flex-col tw-items-start tw-justify-start">
            <Text
              variant="p"
              textWeight="500"
              textColor="rgba(12, 15, 74, 0.5)"
              textAlign="left"
              >BILLING CYCLE</Text
            >
            <Text variant="h6" textWeight="600" textAlign="left"
              >{{ appFilters.formatToHumanDate(data?.currentCycleStart) }}
              -
              {{ appFilters.formatToHumanDate(data?.currentCycleEnd) }}</Text
            >
          </div>
          <div class="tw-flex tw-flex-col tw-items-start tw-justify-start">
            <Text variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.5)"
              >NEXT BILLING DUE</Text
            >
            <Text variant="h6" textWeight="600" textAlign="left"
              >{{ appFilters.formatToHumanDate(data?.nextBillDueDate) }}
              <Text
                textAlign="left"
                variant="h6"
                textWeight="400"
                textColor="rgba(12, 15, 74, 0.5)"
                >(Renews Automatically)</Text
              ></Text
            >
          </div>
          <div class="tw-flex tw-flex-col tw-items-start tw-justify-start">
            <Text
              variant="p"
              textWeight="500"
              textColor="rgba(12, 15, 74, 0.5)"
              textAlign="left"
              >Total Sum (Inc. VAT)</Text
            >
            <Text variant="h6" textWeight="600" textAlign="left">{{
              formattedCurrency(data?.upcomingTotal)
            }}</Text>
          </div>
        </div>
      </section>
    </template>
  </Card>
</template>
<script setup>
import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import appFilters from "@/filters";
import CurrencyFilter from "@/filters/currency.filter";

const props = defineProps(["data", "isLoading", "selectedSubscription"]);
const emits = defineEmits(["on-manage", "on-download-invoice"]);

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const onManage = (data) => {
  emits("on-manage", data);
};

const onDownloadInvoice = (data) => {
  emits("on-download-invoice", data);
};
</script>
<style lang="scss" scoped>
.subscription-details-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
</style>
