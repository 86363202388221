export const CHAT_ROUTE = "chat";

export default {
  path: "/chat",
  name: CHAT_ROUTE,
  component: () => import("@/modules/chat/pages/ChatPage.vue"),
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Chat",
  },
};
