<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2998 0.5H2.0998C1.75502 0.5 1.42436 0.636964 1.18057 0.880761C0.936769 1.12456 0.799805 1.45522 0.799805 1.8V12.2C0.799805 12.5448 0.936769 12.8754 1.18057 13.1192C1.42436 13.363 1.75502 13.5 2.0998 13.5H9.8998C10.2446 13.5 10.5752 13.363 10.819 13.1192C11.0628 12.8754 11.1998 12.5448 11.1998 12.2V4.4L7.2998 0.5ZM4.3735 9.7235C4.17265 9.912 3.87625 9.9965 3.5311 9.9965C3.46418 9.99723 3.39729 9.99332 3.3309 9.9848V10.9117H2.7498V8.3533C3.01205 8.31418 3.27703 8.29635 3.54215 8.3C3.9042 8.3 4.1616 8.3689 4.33515 8.50735C4.50025 8.63865 4.61205 8.8538 4.61205 9.1073C4.6114 9.3621 4.5269 9.57725 4.3735 9.7235ZM6.84805 10.6042C6.57505 10.8311 6.1597 10.939 5.65205 10.939C5.34785 10.939 5.1327 10.9195 4.98645 10.9V8.35395C5.2488 8.31566 5.51369 8.29762 5.7788 8.3C6.27085 8.3 6.59065 8.3884 6.84025 8.5769C7.11 8.7771 7.279 9.09625 7.279 9.5545C7.279 10.0505 7.09765 10.393 6.84805 10.6042ZM9.2498 8.8005H8.254V9.39265H9.1848V9.86975H8.254V10.9123H7.6651V8.3195H9.2498V8.8005ZM7.2998 5.05H6.6498V1.8L9.8998 5.05H7.2998Z"
      fill="#0C0F4A"
      fill-opacity="0.54"
    />
  </svg>
</template>
<script>
export default {
  name: "PdfIconGray",
};
</script>
