<template>
  <main class="project-log-sidenav">
    <section
      class="project-log-sidenav__side tw-right-[-535px]"
      :class="{ 'tw-right-[0]': isOpen }"
    >
      <div class="project-log-sidenav__header">
        <div class="project-log-sidenav__title">
          <h4>Project <strong> Logs</strong></h4>

          <v-icon
            icon="mdi-close"
            @click="onClose"
            class="tw-cursor-pointer"
          ></v-icon>
        </div>
        <div class="audit__logs">
          <div class="confirm_date">
            <DatePicker v-model.range="dataRange" :popover="true">
              <template #default="{ togglePopover, inputValue, inputEvents }">
                <div class="calendar__select" @click="() => togglePopover()">
                  <v-text-field
                    :value="
                      inputValue.start
                        ? `${inputValue.start}
            ${inputValue.end}`
                        : ''
                    "
                    variant="outlined"
                    v-on="inputEvents"
                    placeholder="Choose Date"
                    class="date__start c-input"
                    prepend-icon="mdi-calendar-text"
                    color="#0C0F4A"
                  />
                </div>
              </template>
            </DatePicker>
            <v-btn
              prepend-icon="mdi-tray-arrow-down"
              class="button button-lightGray"
              size="large"
              @click="downloadAuditLogs"
            >
              Download XLS
            </v-btn>
          </div>
          <v-text-field
            v-model="searchLogs"
            placeholder="Search"
            class="search_field"
            bg-color="#fff"
            variant="outlined"
          >
            <template v-slot:prepend-inner>
              <img src="../../../assets/icons/trade-network-search.svg" />
            </template>
          </v-text-field>
          <div class="overview__detail__wrapper">
            <div
              class="overview__detail"
              v-for="(group, date) in auditLogsList"
              :key="date"
            >
              <div class="overview__detail__date">
                <h6>{{ date }}</h6>
              </div>
              <div class="overview__detail__item__wrapper">
                <div
                  class="overview__detail__item"
                  v-for="(log, i) in group"
                  :key="i"
                >
                  <div
                    :class="`overview__detail__icon overview__detail__icon__${log?.category}`"
                  >
                    <v-icon v-if="log?.category == 'project'">
                      <img src="../../../assets/icons/pad-pen.svg" alt="" />
                    </v-icon>
                    <v-icon v-else-if="log?.category == 'trade-passport'">
                      <img src="../../../assets/icons/calu-pen.svg" alt="" />
                    </v-icon>
                    <v-icon v-else>
                      <img src="../../../assets/icons/note-pen.svg" alt="" />
                    </v-icon>
                  </div>
                  <div class="overview__detail__content">
                    <div class="overview__detail__text">
                      <p v-html="log?.content"></p>
                    </div>
                    <div class="overview__detail__time">
                      <p>{{ log?.time }} IST</p>
                      <v-icon
                        color="#FAA500"
                        @click="
                          onRenderToLogModule(log?.category, log?.meta?.id)
                        "
                        icon="mdi-chevron-right-circle"
                      ></v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <v-btn
            v-if="isPaginationAvailable"
            @click="onLoadMore"
            variant="text"
            aria-label="Refresh"
            class="back_btn yellow-text"
            append-icon="mdi-chevron-right"
            >load more Data</v-btn
          > -->
        </div>
      </div>
    </section>
    <section
      class="project-log-sidenav__overlay tw-hidden"
      :class="{ '!tw-flex': isOpen }"
    ></section>
  </main>
</template>
<script lang="ts">
import { DatePicker } from "v-calendar";
import { ref, onBeforeMount, computed, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { AUDIT_LOG_STORE } from "@/store/modules/audit-logs";
import { useRoute, useRouter } from "vue-router";
import * as XLSX from "xlsx";
import { PROJECT_DETAILS_ROUTE } from "@/modules/project/routes";

export default {
  components: {
    DatePicker,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const dataRange = ref();
    const showDatePicker = ref(false);
    const searchLogs = ref("");
    const categoryName = ref("") as any;
    const paginationData = ref({
      currentPage: 1,
      pageSize: 0,
      totalRecords: 0,
    });
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const propertyId = computed(() => route?.params?.propertyId);
    const getAuditLogsList = computed(
      () => store.getters[`${AUDIT_LOG_STORE}/getAuditLogsList`]
    );
    interface Log {
      [x: string]: any;
      content: string;
      time: string;
    }

    interface AuditLogs {
      [date: string]: Log[];
    }
    const masks = {
      weekdays: "YYYY-MM-DD",
    };
    const logsData = ref();
    const auditLogsList = ref<AuditLogs | undefined>();
    const originalAuditLogsList = ref();

    function formatTime(dateString: any) {
      const date = new Date(dateString);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    function organizeDataByDate(data: any) {
      const organizedData: any = {};
      data?.forEach((item: any) => {
        const date = new Date(item.dateCreated).toISOString().split("T")[0];
        const time = formatTime(item.dateCreated);
        if (!organizedData[date]) {
          organizedData[date] = [];
        }
        item.time = time;
        organizedData[date].push(item);
      });

      auditLogsList.value = organizedData;
      originalAuditLogsList.value = organizedData;
      return organizedData;
    }

    const onRenderToLogModule = (category: any, id: any) => {
      switch (category) {
        case "property":
          router.push("/properties");
          break;
        case "project":
          router.push("/project");
          break;
        default:
          // router.push("/dashboard");
          router.push({
            name: PROJECT_DETAILS_ROUTE,
            params: { refId: id },
          });
      }
    };

    const downloadAuditLogs = () => {
      const wb = XLSX.utils.book_new();
      const ws_name = "Audit Logs";
      const ws_data = [["Date", "Content", "Time"]];

      if (auditLogsList.value) {
        for (const [date, group] of Object.entries(auditLogsList.value)) {
          group.forEach((log: Log) => {
            ws_data.push([date, log.content, log.time]);
          });
        }
      }

      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, "Activity-log.xlsx");
    };

    watch(route, (newValue, oldValue) => {
      categoryName.value = newValue.name;
      getAuditLogsData(1);
    });

    watch(dataRange, (newValue, oldValue) => {
      if (newValue && newValue.start && newValue.end) {
        filterAuditLogsByDate(newValue.start, newValue.end);
      }
    });

    const filterAuditLogsByDate = (startDate: any, endDate: any) => {
      startDate.setHours(0, 0, 0, 0);
      const filteredLogs: any = {};
      if (startDate && endDate && originalAuditLogsList.value) {
        for (const [date, logs] of Object.entries(
          originalAuditLogsList.value
        )) {
          const currentDate = new Date(date);
          currentDate.setHours(0, 0, 0, 0);
          if (startDate.getTime() === currentDate.getTime()) {
            filteredLogs[date] = logs;
          } else {
            if (currentDate >= startDate && currentDate <= endDate) {
              filteredLogs[date] = logs;
            }
          }
        }
        auditLogsList.value = filteredLogs;
      }
    };

    watch(searchLogs, (newValue, oldValue) => {
      filterAuditLogsBySearch(newValue);
    });

    const filterAuditLogsBySearch = (searchTerm: any) => {
      if (!searchTerm) {
        auditLogsList.value = originalAuditLogsList.value;
        return;
      }

      const filteredLogs: AuditLogs = {};

      for (const [date, logs] of Object.entries(originalAuditLogsList.value)) {
        if (Array.isArray(logs)) {
          const filtered = logs.filter((log: Log) =>
            log.content.toLowerCase().includes(searchTerm.toLowerCase())
          );
          if (filtered.length > 0) {
            filteredLogs[date] = filtered;
          }
        }
      }
      auditLogsList.value = filteredLogs;
    };

    const isPaginationAvailable = computed(() => {
      const { totalRecords } = paginationData.value;
      return totalRecords !== getAuditLogsList.value.length;
    });

    const onLoadMore = async () => {
      const { currentPage } = paginationData.value;
      if (isPaginationAvailable.value) {
        const nextPage = +currentPage + 1;
        const data = await getAuditLogsData(nextPage);
      }
    };

    const onClose = () => {
      ctx.emit("on-close");
    };

    const getAuditLogsData = async (page: number) => {
      let params: any = {
        category: "project",
        // page: page,
        // pageSize: 30,
      };

      params = Object.fromEntries(
        Object.entries(params).filter((value: any) =>
          value[1] === 0 ? true : value[1]
        )
      );
      const response = await store.dispatch(`${AUDIT_LOG_STORE}/getAuditLogs`, {
        userId: user.value?.id,
        params: params,
      });
      if (response) {
        const timeLineData = response?.data?.filter((audit: any) =>
          audit?.meta?.propertiesData?.find(
            (property: any) => property?.propertyId == propertyId.value
          )
        );
        organizeDataByDate(timeLineData);
        paginationData.value.currentPage = response?.page;
        paginationData.value.pageSize = response?.pageSize;
        paginationData.value.totalRecords = response?.totalRecords;
      }
      if (response?.data?.length) {
        return response.data;
      } else {
        return [];
      }
    };

    onBeforeMount(async () => {
      await getAuditLogsData(1);
      console.log(auditLogsList.value, "auditLogsList");
      window.addEventListener("keypress", handleKeyPress);
    });

    const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
        filterAuditLogsBySearch(searchLogs.value);
      }
    };

    onBeforeUnmount(() => {
      window.removeEventListener("keypress", handleKeyPress);
    });

    return {
      masks,
      dataRange,
      showDatePicker,
      searchLogs,
      user,
      logsData,
      auditLogsList,
      formatTime,
      getAuditLogsList,
      categoryName,
      onRenderToLogModule,
      downloadAuditLogs,
      isPaginationAvailable,
      paginationData,
      onLoadMore,
      onClose,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-log-sidenav {
  .project-log-sidenav__side {
    .project-log-sidenav__header {
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .project-log-sidenav__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba($cardBorder, 1);
        padding: 40px 40px 28px;
        h4 {
          @include fluidFont(24, 18, 1.3);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
        .v-icon {
          @include fluidFont(14, 14, 1);
          color: rgba($blueDark, 1);
        }
      }
      .audit__logs {
        padding: 22px 40px;
        @include respond(s720) {
          padding: 22px 24px;
        }
        .confirm_date {
          display: flex;
          gap: 12px;
          align-items: center;
          margin-bottom: 16px;
          .calendar__select {
            min-width: 300px;
            @include respond(sm) {
              min-width: 190px;
            }
            .date__start {
              padding: 7px 16px;
              margin: 0;
              border: 1px solid rgba($buttonText, 0.4);
              border-radius: 8px;
              :deep(.v-input__prepend) {
                .v-icon {
                  cursor: pointer;
                }
              }
              :deep(.v-input__control) {
                .v-field {
                  .v-field__field {
                    align-items: center;
                    .v-field__input {
                      background-color: transparent;
                      padding: 0;
                      min-height: auto;
                      @include fluidFont(12, 12, 1.3);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                    }
                    ::placeholder {
                      @include fluidFont(12, 12, 1.3);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                    }
                  }
                  .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
          .button-lightGray {
            display: flex;
            align-items: center;
            width: 144px;
            max-width: 100%;
            @include respond(xs) {
              width: 125px;
            }
            :deep(.v-btn__content) {
              text-transform: capitalize;
              @include fluidFont(12, 12, 1.2);
              font-weight: 600;
              color: rgba($buttonText, 1);
            }
            :deep(.v-btn__prepend) {
              .v-icon {
                @include fluidFont(16, 16, 1.2);
              }
            }
          }
        }
        .search_field {
          :deep(.v-input__control) {
            .v-field {
              border-radius: 8px;
              padding: 0 16px;
              gap: 8px;
              .v-field__field {
                .v-field__input {
                  padding: 0;
                  min-height: 40px;
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 400;
                  letter-spacing: 0.4px;
                  color: rgba($blueDark, 1);
                }
                ::placeholder {
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 400;
                  letter-spacing: 0.4px;
                  color: rgba($blueDark, 1);
                }
              }
            }
          }
        }
        .overview__detail__wrapper {
          margin-top: 16px;
          height: 100%;
          max-height: 670px;
          overflow: auto;
          @include respond(xlg) {
            max-height: 460px;
          }
          @include respond(xs) {
            max-height: 340px;
          }
          .overview__detail {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            @include respond(s720) {
              flex-direction: column;
            }
            .overview__detail__date {
              width: 100px;
              max-width: 100%;
              margin-top: 5px;
              @include respond(s720) {
                margin: 0 0 0 45px;
              }
              h6 {
                @include fluidFont(14, 14, 1.2);
                font-weight: 500;
                color: rgba($blueDark, 1);
                white-space: nowrap;
              }
            }
            .overview__detail__item__wrapper {
              width: calc(100% - 115px);
              max-width: 100%;
              @include respond(s720) {
                width: 100%;
              }
              .overview__detail__item {
                text-align: left;
                display: flex;
                align-items: flex-start;
                gap: 20px;
                .overview__detail__icon {
                  width: 32px;
                  height: 32px;
                  background-color: rgba($PrimaryPurple, 0.1);
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  .v-icon {
                  }
                  ::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    right: 0;
                    top: 100%;
                    border-left: 2px dashed #dde2e7;
                    height: 90px;
                    z-index: 0;
                  }
                }
                .overview__detail__icon__trade-passport {
                  width: 32px;
                  height: 32px;
                  background-color: rgba(240, 243, 232, 1);
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  .v-icon {
                  }
                  ::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    right: 0;
                    top: 100%;
                    border-left: 2px dashed #dde2e7;
                    height: 90px;
                    z-index: 0;
                  }
                }
                .overview__detail__content {
                  padding: 16px;
                  border-radius: 8px;
                  background-color: #f3f3f6;
                  width: calc(100% - 50px);
                  max-width: 100%;
                  margin-bottom: 16px;
                  .overview__detail__text {
                    p {
                      @include fluidFont(14, 14, 1.4);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                    }
                  }
                  .overview__detail__time {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                    p {
                      @include fluidFont(11, 11, 1.4);
                      font-weight: 400;
                      color: rgba($blueDark, 0.6);
                    }
                    .v-icon {
                      @include fluidFont(16, 16, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.project-log-sidenav {
  display: flex;
  max-width: 100%;
  z-index: 999;
  &__side {
    position: fixed;
    max-width: 535px;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100vh;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease-in-out;
    z-index: 1;
  }
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(197, 210, 242, 0.5);
    left: 0;
    top: 0;
    z-index: -1;
  }
}
</style>
