import { TradeNetworkState } from "@/core/models/trade-network";

export default {
  getTradeNetworkContractorList: (state: TradeNetworkState) =>
    state.tradeNetworkContractorList,
  getUserNetworkContractorList: (state: TradeNetworkState) =>
    state.userNetworkContractorList,
  getUserNetworkConnectedList: (state: TradeNetworkState) =>
    state.userNetworkConnectedList,
  getFilterState: (state: any) => state.isClearFilter,
  getInviteContractorFromTradeNetworkState: (state: any) =>
    state.isInviteContractorFromTradeNetwork,
  getExternalInvitedContractors: (state: TradeNetworkState) =>
    state.externalInvitedContractorList,
  getProjectData: (state: any) => state.projectData,
  getTotalPages: (state: any) => state.totalPages,
  getTotalConnectionTabPages: (state: any) => state.totalConnectionTabPages,
  getTradeNetworkContractorCount: (state: any) =>
    state.tradeNetworkContractorCount,

  getAllUserConectionContractorCount: (state: any) =>
    state.allUserConectionContractorCount,
};
