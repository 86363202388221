<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10.7246" r="10" fill="#80829F" />
    <path
      d="M13.6272 8.3279C11.9064 7.18174 9.66154 7.0776 7.77059 8.05396C5.87106 9.03653 5.06819 10.5042 5.03413 10.5665C5.01215 10.6083 5 10.6536 5 10.7012V10.7431C5 10.798 5.0162 10.8517 5.0457 10.8993C5.64262 11.8134 6.36971 12.5599 7.20614 13.1163C8.14319 13.7406 9.23465 14.0553 10.3412 14.0553C11.2667 14.0553 12.2009 13.8352 13.0627 13.3903C14.9623 12.4077 15.7651 10.9401 15.7992 10.8778C15.8212 10.8359 15.8333 10.7906 15.8333 10.7431V10.7012C15.8333 10.6463 15.8171 10.5925 15.7876 10.545C15.1919 9.63032 14.4648 8.8843 13.6272 8.3279ZM10.4181 12.453C9.44052 12.453 8.64869 11.6775 8.64869 10.7216C8.64869 9.76504 9.44056 8.99022 10.4181 8.99022C11.3956 8.99022 12.1874 9.76565 12.1874 10.7216C12.1874 11.6781 11.3956 12.453 10.4181 12.453Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "EyeCircularGrayIcon",
};
</script>
