<template>
  <CollapsibleWidgetSlot title="Reports">
    <template #content>
      <section
        class="tw-flex tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <v-radio-group v-model="selectedReport" @change="onSelect">
          <div
            class="tw-flex tw-flex-col tw-gap-4"
            v-if="props.reports && props.reports.length"
          >
            <Card
              bgColor="#F1F6FB"
              v-for="(report, index) in props.reports"
              :key="index"
            >
              <template #content>
                <section
                  class="tw-p-3 tw-box-sizing tw-w-full tw-h-full tw-flex-col-between"
                >
                  <div
                    class="tw-w-full tw-flex tw-items-center tw-justify-start tw-pb-3 tw-box-border tw-border-b-dashed-custom"
                  >
                    <v-radio color="#FFA500" :value="report">
                      <template v-slot:label>
                        <div>
                          <Text variant="p">{{ report.name }}</Text>
                        </div>
                      </template></v-radio
                    >
                  </div>
                  <div class="tw-flex tw-gap-3 tw-flex-col tw-w-full tw-h-full">
                    <div class="tw-flex-row-between">
                      <Text>Created by</Text>
                      <div class="tw-flex tw-items-center tw-gap-1">
                        <Text
                          variant="xsmall"
                          textColor="rgba(12, 15, 74, 0.5)"
                          >{{ report?.userWorkstation?.name }}</Text
                        >
                      </div>
                    </div>
                    <div class="tw-flex-row-between">
                      <Text>Date created</Text>
                      <div class="tw-flex tw-items-center tw-gap-1">
                        <Text variant="xsmall">{{
                          appFilters.formatToDate(report.dateCreated)
                        }}</Text>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-flex-row-between tw-pt-3 tw-box-border tw-border-t-dashed-custom"
                  >
                    <ChipState
                      :bgColor="report.isActive ? '#40903E' : '#F45454'"
                      :label="report.isActive ? 'Active' : 'In-active'"
                      textColor="#FFF"
                    ></ChipState>
                    <!-- <el-divider direction="vertical"></el-divider>
                    <div
                      class="tw-flex tw-items-center tw-gap-1 tw-cursor-pointer"
                    >
                      <Text variant="span" textColor="#FFA500">View</Text>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </div> -->
                  </div>
                </section>
              </template>
            </Card>
          </div>
        </v-radio-group>
      </section>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { ref } from "vue";

import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import appFilters from "@/filters";

const props = defineProps(["reports"]);
const emits = defineEmits(["on-select-report"]);

const selectedReport = ref(null);

const onSelect = () => {
  if (!selectedReport.value) return;
  emits("on-select-report", selectedReport.value);
};
</script>
<style lang="scss" scoped></style>
