<template>
  <div>
    <CommonDialog
      :isShowHeader="false"
      :isShowBackButton="false"
      width="585px"
      max-width="100%"
      height="auto"
      :className="['recommend_project_modal']"
    >
      <template v-slot:body>
        <div class="modal__header">
          <div class="header-title">Add Recommended Project</div>

          <v-btn
            variant="plain"
            icon="mdi-close"
            :disabled="loading"
            @click="onCloseModal()"
          ></v-btn>
        </div>
        <div class="modal__content">
          <div class="modal__content__field">
            <label>Project Name</label>
            <v-text-field
              density="compact"
              placeholder="Enter Project Name"
              variant="outlined"
              class="input_field"
              :rules="[(v) => !!v || 'Project name is required']"
              v-model="form.improvement_summary_text"
            >
            </v-text-field>
          </div>

          <div class="modal__content__field">
            <label>Project Description</label>
            <v-textarea
              class="input_field"
              placeholder="Lorem Ips"
              variant="outlined"
              :rules="[(v) => !!v || 'Project description is required']"
              v-model="form.improvement_descr_text"
            ></v-textarea>
          </div>
          <div class="modal__content__field">
            <label>Priority</label>
            <v-radio-group v-model="form.priority">
              <v-radio
                :rule="[(v) => !!v || 'Select project priority is required']"
                :label="priority?.label"
                :value="priority?.value"
                color="#4B4BFF"
                v-for="priority in projectPriority"
                :key="priority?.value"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="image__upload__wrapper">
            <div class="modal__content__field">
              <label>Upload Document</label>
              <div class="">
                <UploadForm
                  class=""
                  listType="picture"
                  :record="form"
                  :allowMultiple="false"
                  :attachmentSources="[]"
                  :useCustomUploadIcon="false"
                  :useDragTrigger="true"
                  @on-progress-upload="onProgressUpload"
                  @on-upload-success="updateFileOnSuccess"
                  @on-handle-error="onHandleError"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="modal__footer">
          <v-btn
            class="button button-purple-border"
            label="Close"
            @click="onCloseModal"
            >Close</v-btn
          >
          <v-btn
            class="button button-orange"
            :disabled="isDisabled"
            @click="onSaveRecommendation"
            >Save</v-btn
          >
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script setup>
import CommonDialog from "../CommonDialog.vue";
import UploadForm from "@/core/components/forms/Upload.vue";
import { reactive, ref, getCurrentInstance, computed } from "vue";
import propertyService from "@/core/services/property.service";
import { useStore } from "vuex";
import { PROPERTY_STORE } from "@/store/modules/property";
import { JOBS_STORE } from "@/store/modules/jobs";

const internalInstance = getCurrentInstance();
const store = useStore();
const emits = defineEmits(["on-close"]);
const props = defineProps({
  propertyId: { type: Number, required: true },
});
const projectPriority = ref([
  { label: "High Priority", value: 3 },
  { label: "Medium Priority", value: 2 },
  { label: "Low Priority", value: 1 },
]);
const form = reactive({
  improvement_summary_text: "",
  improvement_descr_text: "",
  priority: "",
  attachments: [],
  disableButton: false,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});

const isDisabled = computed(() => {
  if (
    form.improvement_summary_text != "" &&
    form.improvement_descr_text != "" &&
    form.priority != "" &&
    form.attachments.length &&
    !form.isLoading
  ) {
    return false;
  } else {
    return true;
  }
});
const siteVisitProjectRefId = computed(
  () => store.getters[`${PROPERTY_STORE}/getSiteVisitProjectRefId`]
);
const onProgressUpload = () => {
  form.isUploading = true;
  form.isLoading = true;
};

const updateFileOnSuccess = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onHandleError = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onCloseModal = () => {
  emits("on-close");
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};
const onSaveRecommendation = async () => {
  try {
    form.isLoading = true;
    if (!form.attachments[0]) {
      displayToast("Image Not Found", "error");
      return;
    }
    const recommendationData = {
      improvement_summary_text: form.improvement_summary_text,
      improvement_descr_text: form.improvement_descr_text,
      priority: form.priority,
      projectImage: form.attachments[0]?.attachment,
      propertyId: props.propertyId,
      projectRefId: siteVisitProjectRefId.value,
    };

    const propertyDetails = store.getters[`${PROPERTY_STORE}/propertyDetails`];
    propertyDetails?.localData?.epcDomestic?.recommendations == null
      ? (propertyDetails.localData.epcDomestic.recommendations = [
          recommendationData,
        ])
      : propertyDetails.localData.epcDomestic?.recommendations.push(
          recommendationData
        );

    store.commit(`${PROPERTY_STORE}/setPropertyDetails`, propertyDetails);
    await propertyService.createRecommendation(recommendationData);
    displayToast("recommendation added successfully.", "success");
  } catch (error) {
    console.log(error);
    displayToast("Something went wrong.", "error");
  } finally {
    form.isLoading = false;
    onCloseModal();
  }
};
</script>

<style scoped></style>
