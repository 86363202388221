<template>
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4 7.00021H3.99V12.0002C3.99 12.5502 4.44 13.0002 4.99 13.0002H8.99C9.54 13.0002 9.99 12.5502 9.99 12.0002V7.00021H11.58C12.47 7.00021 12.92 5.92021 12.29 5.29021L7.7 0.700215C7.31 0.310215 6.68 0.310215 6.29 0.700215L1.7 5.29021C1.07 5.92021 1.51 7.00021 2.4 7.00021ZM0 16.0002C0 16.5502 0.45 17.0002 1 17.0002H13C13.55 17.0002 14 16.5502 14 16.0002C14 15.4502 13.55 15.0002 13 15.0002H1C0.45 15.0002 0 15.4502 0 16.0002Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "UploadIcon",
};
</script>
