<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['edit_workstation_dialog']"
    width="600px"
    height="auto"
    min-height="400px"
    max-height="unset"
    ><template v-slot:body
      ><div class="header">
        <h4>Edit workstation</h4>
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <div
        class="modal__content !tw-max-h-[600px] !tw-overflow-y-scroll !tw-pr-2 !tw-box-border !tw-border-0"
      >
        <div
          class="uploade-wrapper md:!tw-h-[200px] md:!tw-w-[200px] rmd:!tw-h-[209px] rmd:!tw-w-[300px] rmd:!tw-rounded-[100%] rmd:!tw-overflow-hidden"
        >
          <v-file-input
            class="upload_file md:!tw-w-[200px] md:!tw-h-[200px] md:!tw-overflow-hidden md:!tw-top-[4px]"
            append-inner-icon="mdi-image-plus"
            variant="solo-filled"
            :class="{ 'added-img': imageUrl }"
            @change="onFileChange"
            @click:clear="clearImageUrl"
          >
          </v-file-input>
          <v-img
            class="uploaded-img rmd:!tw-top-0"
            :width="200"
            aspect-ratio="16/9"
            cover
            :src="imageUrl"
          ></v-img>
        </div>

        <div class="right__wrapper">
          <v-form
            ref="workStationDetailsForm"
            @submit.prevent
            :rules="workStationDetailsFormRules"
          >
            <div class="input__field__wrapper">
              <label>Workstation Name</label>
              <v-text-field
                class="c-input"
                variant="outlined"
                v-model="updatedUserInfo.workstationName"
                :rules="workStationDetailsFormRules.workstationName"
              ></v-text-field>
            </div>
            <div
              class="input__field__wrapper"
              v-if="
                activeUserWorkstation?.user?.userBusinessDetails?.type ===
                BusinessDetailTypeEnum.LIMITED_COMPANY
              "
            >
              <label>Company Register No</label>
              <v-text-field
                class="c-input"
                variant="outlined"
                v-model="updatedUserInfo.companyRegistrationNo"
                :rules="workStationDetailsFormRules.companyRegistrationNo"
              ></v-text-field>
            </div>
            <div class="input__field__wrapper">
              <label>Postcode</label>
              <v-text-field
                class="c-input c-select"
                variant="outlined"
                :model-value="addressForm.postCode"
                @update:modelValue="onPostCodeChange"
                :rules="workStationDetailsFormRules.postCode"
              ></v-text-field>
              <ul
                v-if="addressListFromPostcode?.length"
                class="address__postcodes !tw-max-h-[280px]"
              >
                <li
                  class="address__postcodes_list"
                  :class="{ active: DPA?.UPRN === selectedPostCode }"
                  v-for="{ DPA } in addressListFromPostcode"
                  :key="DPA?.UPRN"
                  @click="onSelectedAddress(DPA)"
                >
                  <div class="address__postcodes_list__item">
                    {{ DPA?.ADDRESS }}
                  </div>
                </li>
              </ul>
              <span class="error_msg" v-if="errorMessagePostcode">{{
                errorMessagePostcode
              }}</span>
            </div>

            <v-btn
              class="button button-orange"
              @click="onClickSaveButton"
              :disabled="isProFileImageUploading"
              :loading="isWorkStationDetailUpdating"
              >SAVE</v-btn
            >
          </v-form>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import { VUE_APP_OS_POSTCODE_URL, VUE_APP_OS_API } from "@/config";
import axios from "axios";
import $axios from "@/core/utils/axios-api-config";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { onMounted, ref } from "vue";
import { computed } from "vue";
import workstation, { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";
import UserService from "@/core/services/user.service";
import { USER_STORE } from "@/store/modules/user";
import { getImageApiUrl } from "@/core/utils/common";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
export default {
  components: {
    CommonDialog,
  },
  props: {
    postCode: String,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const imageUrl = ref("");
    const imageData = ref("");
    const workStationDetailsForm = ref(null) as any;

    const updatedUserInfo = ref({
      profileImage: "",
      workstationName: "",
      companyRegistrationNo: "",
    });

    const selectedPostCode = ref("");

    const isWorkStationDetailUpdating = ref(false);

    const addressForm = ref({
      postCode: "",
      address: "",
      companyRegisterNo: "",
      place: "",
      latitude: "",
      longitude: "",
    }) as any;

    const selectedDPA = ref(null) as any;

    const isProFileImageUploading = ref(false);

    const errorMessagePostcode = ref("");

    const addressListFromPostcode = ref([]) as any;

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onPostCodeChange = async (value: any) => {
      // addressForm.value.postCode = value;
      try {
        if (value?.length > 3) {
          isProFileImageUploading.value = true;
          addressListFromPostcode.value = [];
          const response = await UserService.fetchAddressFromPostCode(value);
          if (response.data?.results) {
            addressListFromPostcode.value = response.data?.results;
          } else {
            errorMessagePostcode.value = "No address found";
          }
          if (response.status === 200) {
            errorMessagePostcode.value = "";
            addressForm.value.address =
              response.data?.result?.primary_care_trust;
            addressForm.value.place = response.data?.result?.region;
          } else if (response.status === 400) {
            errorMessagePostcode.value = "Invalid postcode";
            console.error("Failed to fetch address data");
          }
        }
      } catch (error) {
        errorMessagePostcode.value = "Invalid postcode";
        console.log("Error fetching address data:", error);
      } finally {
        isProFileImageUploading.value = false;
      }
    };

    const onSelectedAddress = (DPA: any) => {
      selectedPostCode.value = DPA?.UPRN;
      selectedDPA.value = DPA;
      addressForm.value.address = removePostCodeAndPostTownInFullAddress(
        DPA.POSTCODE,
        DPA.POST_TOWN,
        DPA.ADDRESS
      );
      addressForm.value.place = DPA.POST_TOWN;
      addressForm.value.postCode = DPA.POSTCODE;
      addressForm.value.latitude = DPA.LAT;
      addressForm.value.longitude = DPA.LNG;
    };

    const removePostCodeAndPostTownInFullAddress = (
      postcode: string,
      postTown: string,
      address: string
    ) => {
      if (!postcode && !address && !postTown) {
        console.log("Please enter a word to remove and the address.");
        return;
      }

      const concatRemovedWord = `, ${postTown}, ${postcode}`;

      // Escape special characters in the word to be removed
      const escapedWord = concatRemovedWord.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );

      // Create a regular expression to match the word to be removed
      const regex = new RegExp(`\\b${escapedWord}\\b`, "gi");

      // Remove the word from the input string
      return address.replace(regex, "").trim();
    };
    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        try {
          isProFileImageUploading.value = true;
          const file = fileInput.files[0];
          imageUrl.value = URL.createObjectURL(file);
          imageData.value = file;
          const formData = new FormData();
          formData.append("file", imageData.value);
          const uploadImg = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
          updatedUserInfo.value.profileImage = uploadImg.attachment;
        } catch (error) {
          console.log("error", error);
        } finally {
          isProFileImageUploading.value = false;
        }
      } else {
        console.error("Error: No file selected.");
      }
    };

    const clearImageUrl = () => {
      imageUrl.value = "";
    };
    const onClickCloseButton = () => {
      ctx.emit("closeEditWorkStationModal");
    };

    const onClickSaveButton = async () => {
      const isFormValid = await workStationDetailsForm.value.validate();
      if (isFormValid.valid) {
        const userBusinessDetails = user.value?.userBusinessDetails;
        delete userBusinessDetails.dateUpdated;
        delete userBusinessDetails.dateCreated;

        try {
          isWorkStationDetailUpdating.value = true;
          await UserService.updateUserWorkstation(
            user.value?.id,
            activeUserWorkstation.value?.id,
            {
              profileImage: updatedUserInfo.value.profileImage,
              name: updatedUserInfo.value.workstationName,
              companyRegistrationNo:
                updatedUserInfo.value.companyRegistrationNo,
            }
          );

          let formData;
          if (
            activeUserWorkstation.value?.workstationAccountType?.code ===
            BusinessDetailTypeEnum.LIMITED_COMPANY
          ) {
            delete userBusinessDetails.businessAddress;
            delete userBusinessDetails.businessPostcode;
            delete userBusinessDetails.latitude;
            delete userBusinessDetails.longitude;
            const defaultPayload = {
              businessAddress: selectedDPA.value.ADDRESS,
              businessPostcode: addressForm.value.postCode,
              latitude: addressForm.value.latitude.toString(),
              longitude: addressForm.value.longitude.toString(),
            };
            formData = {
              ...defaultPayload,
              ...userBusinessDetails,
            };
          } else {
            delete userBusinessDetails.residencyAddressLookup;
            delete userBusinessDetails.residencyAddressLine;
            delete userBusinessDetails.residencyTown;
            delete userBusinessDetails.residencyPostcode;
            delete userBusinessDetails.latitude;
            delete userBusinessDetails.longitude;
            const defaultPayload = {
              residencyAddressLookup: selectedDPA.value?.ADDRESS,
              residencyAddressLine: addressForm.value?.address,
              residencyTown: addressForm.value?.place,
              residencyPostcode: addressForm.value?.postCode,
              latitude: addressForm.value.latitude.toString(),
              longitude: addressForm.value.longitude.toString(),
            };

            formData = {
              ...defaultPayload,
              ...userBusinessDetails,
            };
          }

          await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
            userId: user.value.id,
            formData,
          });

          await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        } catch (error) {
          // ctx.emit("closeEditWorkStationModal");
          console.log(error);
        } finally {
          isWorkStationDetailUpdating.value = false;
          ctx.emit("closeEditWorkStationModal");
        }
      }
    };

    onBeforeMount(async () => {
      updatedUserInfo.value.workstationName = activeUserWorkstation.value?.name;
      updatedUserInfo.value.companyRegistrationNo =
        activeUserWorkstation.value?.companyRegistrationNo;
      addressForm.value.postCode = props.postCode;
      try {
        isProFileImageUploading.value = true;
        const imageApiUrl = getImageApiUrl(
          activeUserWorkstation.value?.profileImage,
          true
        );
        const encoded: any = await $axios.get(imageApiUrl);
        imageUrl.value = encoded.publicUrl;
      } catch (error) {
        return null;
      } finally {
        isProFileImageUploading.value = false;
      }
    });

    const workStationDetailsFormRules = ref({
      workstationName: [
        (v: string) => !!v?.trim() || "Workstation Name is required",
        (v: string) =>
          !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(v) ||
          "Workstation Name cannot include an email address",
        (v: string) =>
          /^[a-zA-Z0-9-_' ]+$/.test(v.trim()) ||
          "Workstation Name should not contain special characters",
        (v: string) =>
          v.trim().length <= 100 ||
          "Workstation Name must be less than 100 characters",
      ],
      companyRegistrationNo: [
        (v: string) => !!v?.trim() || "Company Registration Number is required",
      ],
      postCode: [(v: string) => !!v?.trim() || "Post Code is required"],
    });
    return {
      addressForm,
      onPostCodeChange,
      errorMessagePostcode,
      addressListFromPostcode,
      onClickCloseButton,
      onSelectedAddress,
      imageUrl,
      onFileChange,
      clearImageUrl,
      updatedUserInfo,
      onClickSaveButton,
      isProFileImageUploading,
      selectedPostCode,
      isWorkStationDetailUpdating,
      activeUserWorkstation,
      workStationDetailsForm,
      workStationDetailsFormRules,
      BusinessDetailTypeEnum,
    };
  },
};
</script>
