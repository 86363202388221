<template>
  <Card
    class="tw-cursor-pointer"
    v-if="workflowType === HistoricalWorkFlowTypeEnum.PROPERTY"
  >
    <template #content>
      <div class="tw-w-full tw-h-full tw-py-4 tw-box-border">
        <div class="tw-w-full tw-flex tw-gap-2">
          <div class="tw-relative">
            <div
              class="tw-h-[10px] tw-w-[10px] tw-bg-[#3BC740] tw-rounded-full tw-border tw-border-solid tw-border-[#FFF] tw-absolute tw-top-0 tw-right-[.2rem] tw-z-10"
            ></div>
            <div
              class="tw-rounded-full tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative tw-overflow-hidden"
              v-if="getTradeNetworkImg"
            >
              <img
                :src="getTradeNetworkImg"
                alt="Contractor"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              v-else
              class="tw-rounded-full tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
            >
              <UserProfileLogo :userName="contractor?.userWorkstation?.name" />
            </div>
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-items-start">
            <Text variant="h6" textWeight="500"
              >{{ contractor?.userWorkstation?.name }}
            </Text>
            <Postcode
              v-if="
                contractor?.toUser?.userBusinessDetails &&
                getPostcode(contractor?.toUser?.userBusinessDetails)
              "
              :value="getPostcode(contractor?.toUser?.userBusinessDetails)"
              class="tw-ml-[-.5rem]"
            />
            <div class="tw-flex tw-gap-1 tw-items-center">
              <img src="@/assets/icons/star-icon.svg" />
              <Text variant="span" textWeight="400"
                >{{ contractor?.ratings }}/5.0({{
                  contractor?.jobcompleted
                }}
                Jobs)</Text
              >
            </div>
          </div>
        </div>
        <v-divider class="tw-my-3"></v-divider>

        <!-- <p
          v-if="descriptionWithoutTags"
          class="tw-text-[#0c0f4a] tw-text-sm tw-font-normal tw-leading-4 tw-tracking-[0.15px] tw-text-left tw-text-ellipsis tw-overflow-hidden tw-max-w-full tw-whitespace-nowrap"
        >
          {{ descriptionWithoutTags }}
        </p> -->
      </div>
    </template>
  </Card>
  <v-card
    v-else
    class="client-card tw-p-4 tw-w-full !tw-rounded-md !tw-shadow-[0px_0px_8px_0px_rgba(12,15,74,0.08)]"
  >
    <div class="card__header">
      <div class="header__left tw-flex tw-gap-4 tw-items-center">
        <div
          class="card__img tw-w-[56px] tw-h-[56px] tw-rounded-full tw-overflow-hidden"
        >
          <img
            v-if="getTradeNetworkImg"
            :src="getTradeNetworkImg"
            alt=""
            class="tw-w-full tw-h-full tw-object-cover"
          />
          <UserProfileLogo
            v-else
            :userName="contractor?.userWorkstation?.name"
          />
        </div>
        <div class="title tw-w-[calc(100%-70px)] tw-max-w-full tw-text-left">
          <h5
            class="tw-text-[20px] tw-font-semibold tw-leading-6 tw-text-[#0C0F4A]"
          >
            {{ contractor?.userWorkstation?.name }}
          </h5>
          <div
            class="client_info tw-text-xs tw-font-medium tw-text-[#0c0f4a80] tw-leading-[16px] tw-tracking-[0.15px] tw-flex tw-gap-1 tw-items-center tw-mt-1"
          >
            <span> Property Owner workstation </span>
            |
            <span>
              <p class="">
                <v-icon icon="mdi-map-marker" size="x-small" />{{
                  contractor?.toUser?.postcode || "N/A"
                }}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { ref } from "vue";
import { onMounted } from "vue";
import { HistoricalWorkFlowTypeEnum } from "@/core/enums/ProjectsEnum";
import { computed } from "vue";
import { getImageStringToImageURL } from "@/core/utils/common";

const props = defineProps({
  contractor: {
    type: Object,
  },
  workflowType: {
    type: String,
    default: HistoricalWorkFlowTypeEnum.PROPERTY,
  },
});
const getTradeNetworkImg = ref(null);

const getPostcode = (businessDetails) => {
  if (
    businessDetails?.type === "soletrader" &&
    businessDetails?.residencyPostcode
  ) {
    return businessDetails?.residencyPostcode;
  }
  if (
    businessDetails?.type === "limited_company" &&
    businessDetails?.businessPostcode
  ) {
    return businessDetails?.businessPostcode;
  }
  return "";
};

const descriptionWithoutTags = computed(
  () =>
    props.contractor.userWorkstation?.description &&
    props.contractor.userWorkstation?.description
      .trim()
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/\s\s+/g, " ")
);
onMounted(async () => {
  getTradeNetworkImg.value = await getImageStringToImageURL(
    props.contractor?.userWorkstation?.profileImage
  );
});
</script>
<style lang="scss" scoped>
.client-card {
  width: auto !important;
}
</style>
