import { JobDetails, JobOverview, JobsState } from "@/core/models/jobs";

export default {
  setJobs(state: JobsState, payload: JobOverview[]) {
    state.jobs = payload;
  },
  appendJobs(state: JobsState, payload: JobOverview[]) {
    state.jobs.push(...payload);
  },
  setTotalPages(state: any, payload: number) {
    state.totalPages = payload;
  },
  setMyJobs(state: JobsState, payload: JobOverview[]) {
    state.myJobs = payload;
  },
  appendMyJobs(state: JobsState, payload: JobOverview[]) {
    state.myJobs.push(...payload);
  },
  setDraftJobs(state: JobsState, payload: JobOverview[]) {
    state.draftJobs = payload;
  },
  appendDraftJobs(state: JobsState, payload: JobOverview[]) {
    state.draftJobs.push(...payload);
  },
  setCompletedJobs(state: JobsState, payload: JobOverview[]) {
    state.completedJobs = payload;
  },
  setDraftJobsByUser(state: JobsState, payload: JobOverview[]) {
    state.draftJobsByUser = payload;
  },
  setRejectedJobs(state: JobsState, payload: JobOverview[]) {
    state.rejectedJobs = payload;
  },
  setJobDetails(state: JobsState, payload: JobDetails) {
    state.jobDetails = payload;
  },
  setHistoricalJobs(state: JobsState, payload: any) {
    state.historicalJobs = payload;
  },
  setActiveHistoricalJob(state: JobsState, payload: any) {
    state.activeHistoricalJob = payload;
  },
  setVideoScheduleTime(state: JobsState, payload: any) {
    state.videoScheduleTime = payload;
  },
  setWorkFlowType(state: any, payload: any) {
    state.workFlowType = payload;
  },
  setJobStatusCountsList(state: any, payload: any) {
    state.jobStatusCountsList = payload;
  },
  setHistoricalDraftProject(state: JobsState, payload: any) {
    state.historicalDraftProjects = payload;
  },
  saveHistoricalDraftProject(state: JobsState, payload: any) {
    state.currentDraftProject = payload;
  },
  setJobRedirectTabName(state: JobsState, payload: any) {
    state.jobRedirectTabName = payload;
  },
  setJobEmergencyCallOutDetails(state: JobsState, payload: any) {
    state.jobEmergencyCallOutDetails = payload;
  },
  setIsSiteVisitResponseNotification(state: any, payload: any) {
    state.isSiteVisitResponseNotification = payload;
  },
  setTotalJobs(state: any, payload: any) {
    state.totalJobs = payload;
  },
  setTotalDraftJobs(state: any, payload: any) {
    state.totalDraftJobs = payload;
  },
  setTotalDraftPages(state: any, payload: any) {
    state.totalDraftPages = payload;
  },
  setTotalMyJobs(state: any, payload: any) {
    state.totalMyJobs = payload;
  },
  setTotalMyJobPages(state: any, payload: any) {
    state.totalMyJobPages = payload;
  },
};
