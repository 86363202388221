import EOHPage from "../pages/EOHPage.vue";

export const EMERGENCY_OUT_OF_HOURS = "emergency-out-of-hours";

export default {
  path: "/emergency-out-of-hours",
  name: EMERGENCY_OUT_OF_HOURS,
  component: EOHPage,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Out of Hours",
  },
};
