<template>
  <svg
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27" cy="27.5" r="27" fill="#F45454" />
    <path
      d="M17.4196 18.4196C17.5523 18.2866 17.7099 18.1811 17.8834 18.1091C18.0569 18.0371 18.2429 18 18.4308 18C18.6187 18 18.8047 18.0371 18.9782 18.1091C19.1517 18.1811 19.3093 18.2866 19.442 18.4196L27.0004 25.9808L34.5587 18.4196C34.6915 18.2868 34.8492 18.1815 35.0227 18.1096C35.1962 18.0377 35.3821 18.0007 35.5699 18.0007C35.7577 18.0007 35.9437 18.0377 36.1172 18.1096C36.2907 18.1815 36.4484 18.2868 36.5811 18.4196C36.7139 18.5524 36.8193 18.71 36.8911 18.8836C36.963 19.0571 37 19.243 37 19.4308C37 19.6186 36.963 19.8046 36.8911 19.9781C36.8193 20.1516 36.7139 20.3092 36.5811 20.442L29.0199 28.0004L36.5811 35.5587C36.7139 35.6915 36.8193 35.8492 36.8911 36.0227C36.963 36.1962 37 36.3821 37 36.5699C37 36.7577 36.963 36.9437 36.8911 37.1172C36.8193 37.2907 36.7139 37.4484 36.5811 37.5811C36.4484 37.7139 36.2907 37.8193 36.1172 37.8911C35.9437 37.963 35.7577 38 35.5699 38C35.3821 38 35.1962 37.963 35.0227 37.8911C34.8492 37.8193 34.6915 37.7139 34.5587 37.5811L27.0004 30.0199L19.442 37.5811C19.3092 37.7139 19.1516 37.8193 18.9781 37.8911C18.8046 37.963 18.6186 38 18.4308 38C18.243 38 18.0571 37.963 17.8836 37.8911C17.71 37.8193 17.5524 37.7139 17.4196 37.5811C17.2868 37.4484 17.1815 37.2907 17.1096 37.1172C17.0377 36.9437 17.0007 36.7577 17.0007 36.5699C17.0007 36.3821 17.0377 36.1962 17.1096 36.0227C17.1815 35.8492 17.2868 35.6915 17.4196 35.5587L24.9808 28.0004L17.4196 20.442C17.2866 20.3093 17.1811 20.1517 17.1091 19.9782C17.0371 19.8047 17 19.6187 17 19.4308C17 19.2429 17.0371 19.0569 17.1091 18.8834C17.1811 18.7099 17.2866 18.5523 17.4196 18.4196Z"
      fill="#FFF"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
