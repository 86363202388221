<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill="white" />
    <g clip-path="url(#clip0_1482_35006)">
      <path
        d="M7.3125 6.56245C7.3125 7.31245 6.375 7.87495 6.375 8.43745H5.625C5.625 7.87495 4.6875 7.31245 4.6875 6.56245C4.6875 5.8387 5.27625 5.24995 6 5.24995C6.72375 5.24995 7.3125 5.8387 7.3125 6.56245ZM6.375 8.81245H5.625V8.99995C5.625 9.2062 5.79375 9.37495 6 9.37495C6.20625 9.37495 6.375 9.2062 6.375 8.99995V8.81245ZM8.625 6.37495C8.625 6.8962 8.47125 7.3837 8.205 7.79245C8.11125 7.9387 8.13375 8.1337 8.2575 8.25745C8.4225 8.42245 8.7075 8.39995 8.835 8.2012C9.17625 7.6762 9.375 7.04995 9.375 6.37495C9.375 5.48995 9.03375 4.6837 8.475 4.07995C8.32875 3.92245 8.08125 3.9187 7.93125 4.0687C7.78875 4.2112 7.78875 4.43995 7.92375 4.58995C8.35875 5.0587 8.625 5.68495 8.625 6.37495ZM7.36875 3.2437L6.3225 2.19745C6.2025 2.07745 6 2.15995 6 2.3287V2.99995C4.13625 2.99995 2.625 4.5112 2.625 6.37495C2.625 7.04995 2.82375 7.6762 3.165 8.20495C3.2925 8.4037 3.5775 8.4262 3.7425 8.2612C3.86625 8.13745 3.88875 7.9462 3.795 7.79995C3.27375 6.9937 3.18 5.88745 3.84375 4.79995C4.29375 4.0687 5.14125 3.6787 6 3.74995V4.4212C6 4.58995 6.2025 4.67245 6.31875 4.55245L7.365 3.5062C7.44 3.43495 7.44 3.31495 7.36875 3.2437Z"
        fill="#FAA500"
      />
    </g>
    <defs>
      <clipPath id="clip0_1482_35006">
        <rect
          width="9"
          height="9"
          fill="white"
          transform="translate(1.5 1.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "StandardCircleWhiteIcon",
};
</script>
