import { ConnectionState } from "@/core/models/connections";

export default {
  getUserConnections: (state: ConnectionState) =>
    state.userConnections.allConnection,
  getUserFilteredConnection: (state: ConnectionState) =>
    state.userConnections.filterConnection,
  getPropertyConnectionRole: (state: ConnectionState) =>
    state.propertyConnectionRole,
  propertyConnections: (state: ConnectionState) => state.propertyConnections,
  propertyOccupiers: (state: ConnectionState) => state.propertyOccupiers,
};
