/**
 * Forgot Password route page
 */
import AliaseEmailPage from "@/modules/aliase-email/pages/AliaseEmail.vue";

export const ALIASE_EMAIL_ROUTE = "aliase-email";

export default {
  path: "/alias-email",
  name: ALIASE_EMAIL_ROUTE,
  component: AliaseEmailPage,
  meta: {
    layout: "AuthLayout",
  },
};
