<template>
  <el-date-picker
    class="tw-min-h-[2.625rem] tw-max-h-[2.625rem]"
    type="date"
    :placeholder="placeholder"
    value-format="YYYY-MM-DD"
    :format="DATE_FORMAT"
    :disabled-date="disabledDate"
  />
</template>

<script setup>
import { DATE_FORMAT } from "@/core/constants";
import moment from "moment";

const props = defineProps({
  placeholder: {
    type: String,
    default: "e.g. 15/11/2022",
  },
  startDate: {
    type: String,
  },
  isHistoric: {
    type: Boolean,
    default: false,
  },
  isHistoricPhase: {
    type: Boolean,
    default: false,
  },
});

const disabledDate = (time) => {
  if (props.startDate === "00/00/0000" && props.isHistoric) {
    return time.getTime() > Date.now();
  } else if (!props.startDate && props.isHistoric) {
    return false;
  } else if (props.isHistoricPhase) {
    const startDate = moment(props.startDate);
    const today = moment().startOf("day");
    return (
      time.getTime() < startDate.valueOf() || time.getTime() > today.valueOf()
    );
  } else if (props.startDate) {
    if (props.isHistoric) {
      return time.getTime() > moment(props.startDate);
    } else return time.getTime() < moment(props.startDate);
  }

  return time.getTime() < Date.now();
};
</script>

<script>
export default {
  name: "NoBgDatePicker",
};
</script>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  background: transparent;
  // border-bottom: 1px solid rgba(12, 15, 74, 0.2);
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
</style>
