<template>
  <svg
    width="75"
    height="38"
    viewBox="0 0 75 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5132 23.9261H26.3934L30.2182 0.42002H36.3387L32.5132 23.9261ZM21.245 0.42002L15.4106 16.5877L14.7202 13.1061L14.7208 13.1074L12.6616 2.53647C12.6616 2.53647 12.4126 0.42002 9.75855 0.42002H0.113179L0 0.818033C0 0.818033 2.94957 1.43171 6.40153 3.50478L11.7184 23.9267H18.0948L27.8314 0.42002H21.245ZM69.3807 23.9261H75L70.1006 0.419391H65.1811C62.9093 0.419391 62.356 2.17115 62.356 2.17115L53.2287 23.9261H59.6083L60.8841 20.4345H68.6639L69.3807 23.9261ZM62.6465 15.6112L65.862 6.81464L67.671 15.6112H62.6465ZM53.7072 6.07269L54.5806 1.0249C54.5806 1.0249 51.8857 0 49.0763 0C46.0394 0 38.8273 1.32734 38.8273 7.78169C38.8273 13.8544 47.2919 13.9298 47.2919 17.1196C47.2919 20.3094 39.6994 19.7378 37.1938 17.7264L36.284 23.0043C36.284 23.0043 39.0166 24.3316 43.1917 24.3316C47.368 24.3316 53.6683 22.1693 53.6683 16.284C53.6683 10.1723 45.1276 9.60325 45.1276 6.94605C45.1283 4.28823 51.0884 4.62965 53.7072 6.07269Z"
      fill="#2566AF"
    />
    <path
      d="M14.7208 20.3075L12.6616 3.92869C12.6616 3.92869 12.4126 0.649414 9.75855 0.649414H0.113179L0 1.2661C0 1.2661 4.63594 2.75473 9.08262 8.33223C13.3344 13.6633 14.7208 20.3075 14.7208 20.3075Z"
      fill="#E6A540"
    />
  </svg>
</template>
<script>
export default {
  name: "VisaIcon",
};
</script>
