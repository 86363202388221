<template>
  <div
    class="main_project_preview md:!tw-pb-16 md:!tw-box-border"
    v-if="!loading"
  >
    <div class="left_block">
      <div class="proj_components">
        <div class="list_block">
          <h3>Project Overview</h3>
          <div class="tw-mb-8"></div>
        </div>

        <!-- Project Details -->
        <div class="list_block">
          <h3>Project Details</h3>
          <div class="tw-mb-8"></div>

          <div class="list_block__item">
            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Project Type</div>
              <div class="list_block__item__wrapper__right">
                {{ projectData?.type }}
              </div>
            </div>
          </div>
          <div class="tw-mb-8"></div>
        </div>

        <!-- Estimate -->
        <div class="list_block">
          <h3>Estimate</h3>
          <div class="tw-mb-8"></div>

          <div class="list_block__item">
            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Start Date</div>
              <div class="list_block__item__wrapper__right">
                {{
                  moment(
                    projectMetaData?.projectEstimation?.suggestedStartDate
                  ).format("DD MMMM YYYY")
                }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Total Phase</div>
              <div class="list_block__item__wrapper__right">
                {{ projectMetaData?.projectEstimation?.totalPhases }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Total Tasks</div>
              <div class="list_block__item__wrapper__right">
                {{ projectMetaData?.projectEstimation?.totalTasks }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Duration</div>
              <div class="list_block__item__wrapper__right">
                {{ duration }}
              </div>
            </div>

            <div class="list_block__item__wrapper">
              <div class="list_block__item__wrapper__left">Total Cost</div>
              <div class="list_block__item__wrapper__right">
                {{
                  formattedCurrency(
                    projectMetaData?.projectEstimation?.totalEstimateCost
                  )
                }}
              </div>
            </div>

            <div class="tw-mb-4"></div>
            <v-btn
              class="button button-orange"
              @click="redirectToRequestProjectEstimate"
            >
              verify the estimate
            </v-btn>
          </div>
          <div class="tw-mb-8"></div>
        </div>
      </div>
    </div>
    <div class="right_block">
      <div class="top_links_main desktop__wrapper">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click="onClickBackToSearchResults"
            >Back
          </v-btn>
        </div>

        <!-- <EmergencyChip /> -->
        <StandardChip />
        <!-- <RoutineChip /> -->
      </div>

      <!-- Project Review -->
      <div class="project_info__wrapper">
        <template v-if="!isEditProjectNoteSection">
          <v-card class="project_info">
            <div class="card_content">
              <div class="card_content__header">
                <div class="card_content__title">
                  <h5 id="project_name">
                    {{ projectData?.name }}
                  </h5>
                  <div class="card_content__title_info">
                    <div
                      class="raised"
                      id="project_review_item_icon_commerical"
                    >
                      <v-icon class="pointer_events_none">
                        <img
                          class="pointer_events_none"
                          :src="require('@/assets/icons/user.svg')"
                          alt=""
                        />
                      </v-icon>
                      <p class="pointer_events_none">Commercial</p>
                    </div>
                    <div class="project_id" id="project_id_container">
                      <p class="pointer_events_none">Project ID:</p>
                      <span class="pointer_events_none">{{
                        projectData?.refId
                      }}</span>
                    </div>
                  </div>
                </div>

                <v-icon
                  class="custom_edit"
                  icon="mdi-pencil"
                  @click.stop="updateProjectNameNote(projectData)"
                ></v-icon>
              </div>
              <div class="card_content__footer">
                <h6>Project Notes</h6>
                <p v-html="projectData?.description"></p>
              </div>
            </div>
          </v-card>
        </template>
        <template v-else>
          <v-card class="edit_project_info">
            <div class="title">
              <h6>Project Title</h6>
              <v-text-field
                density="compact"
                placeholder="enter title"
                variant="outlined"
                v-model="projectTitle"
              ></v-text-field>
            </div>
            <div class="description">
              <h6>Project Notes</h6>
              <div class="custom_editor">
                <quill-editor
                  v-model:value="state.content"
                  :options="state.editorOption"
                  :disabled="state.disabled"
                  class="editor_ql"
                />
              </div>
            </div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="onCancelProjectNotes"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveProjectNotes"
                :loading="isLoadingProjectNotes"
                prepend-icon="mdi-content-save"
              >
                save
              </v-btn>
            </div>
          </v-card>
        </template>
      </div>

      <!-- Specialism -->
      <div class="specialism__wrapper">
        <div class="header">
          <div class="title">
            <h4>Specialism</h4>
          </div>
          <v-icon
            class="custom_edit"
            v-if="!isEditSpecialismSection"
            icon="mdi-pencil"
            @click.stop="updateSpecialismSection()"
          ></v-icon>
        </div>
        <div class="specialism__wrapper__content">
          <div v-if="!projectData?.subCategories?.length">No Specialism</div>
          <div class="specialism-card-list">
            <div
              class="specialism-card-list__item"
              v-for="subCategory in projectData?.subCategories"
              :key="subCategory.id"
            >
              <v-btn
                variant="text"
                aria-label="Delete"
                class="specialism-card-list__item-delete"
                prepend-icon="mdi-delete"
                v-if="isEditSpecialismSection"
                @click="onClickDeleteCategoryButton(subCategory?.id)"
              ></v-btn>

              <div class="specialism-card-list__item-author">
                <div
                  class="specialism-card-list__item-author-header"
                  :class="specialismCategoryClass(subCategory?.generatedBy)"
                >
                  <div class="speicalism__icon">
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'krystal'
                      "
                      :src="require('@/assets/icons/krystal.svg')"
                      alt="krystalImg"
                    />
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'property'
                      "
                      :src="require('@/assets/icons/house.svg')"
                      alt="houseimage"
                    />
                  </div>
                  <h6>{{ subCategory?.generatedBy }}</h6>
                  <v-icon icon="mdi-information-variant-circle-outline" />
                </div>
                <div class="specialism-card-list__item-author-content">
                  <div class="category_icon">
                    <img
                      :src="
                        require('@/assets/category-icons/' +
                          subCategory?.category?.iconName +
                          '.svg')
                      "
                      alt="Image"
                    />
                  </div>
                  <div class="category_text">
                    <h5>{{ subCategory?.label }}</h5>
                    <p>{{ subCategory?.category?.poLabel }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEditSpecialismSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            variant="text"
            @click="onClickAddMoreSpecialism"
          >
            Add more Specialism
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              @click="onClickCancelSpecialismButton"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveSpecialismButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Client -->
      <div class="client_info__wrapper">
        <h3>Client</h3>
        <div class="client__cardlist">
          <v-card class="client_card">
            <div class="card_header">
              <div class="client_img">
                <img
                  alt="Profile Image"
                  v-if="projectMetaData.userWorkStation?.profileURL"
                  :src="projectMetaData.userWorkStation?.profileURL"
                />
                <UserProfileLogo
                  v-else
                  :userName="`${projectMetaData.userWorkStation?.user?.firstName} 
                          ${projectMetaData.userWorkStation?.user?.lastName}`"
                />
              </div>
              <div class="client_info">
                <h4>
                  {{ projectMetaData.userWorkStation?.user?.firstName }}
                  {{ projectMetaData.userWorkStation?.user?.lastName }}
                </h4>
                <div class="header_bottom">
                  <span>
                    {{
                      BusinessDetailTypeEnum.SOLETRADER ===
                      projectMetaData.userWorkStation?.user?.userBusinessDetails
                        ?.type
                        ? "Solo Trader"
                        : "Limited Company"
                    }}
                  </span>
                  |
                  <p>
                    {{
                      projectMetaData.userWorkStation?.user?.postcode || "N/A"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="card__info__tags__custom">
              <div
                class="custom_tags"
                v-for="skill in getUserSkillList(
                  projectMetaData?.userWorkStation?.user?.userSkills
                ).slice(0, 1)"
                :key="skill.id"
              >
                <v-icon>
                  <img
                    v-if="skill?.iconName"
                    :src="
                      require('@/assets/category-icons/' +
                        skill?.iconName +
                        '.svg')
                    "
                /></v-icon>
                <span> {{ skill?.label }}</span>
              </div>

              <div
                class="custom_tags__more"
                v-if="
                  getUserSkillList(
                    projectMetaData?.userWorkStation?.user?.userSkills
                  ).length > 1
                "
              >
                <span>
                  +{{
                    getUserSkillList(
                      projectMetaData?.userWorkStation?.user?.userSkills
                    ).length - 1
                  }}
                  More
                </span>
              </div>
              <div
                v-if="projectMetaData.userWorkStation?.description"
                class="description"
              >
                <p class="client_card_description">
                  {{ projectMetaData.userWorkStation?.description }}
                </p>
              </div>
            </div>
            <!-- <div
              v-if="projectMetaData.userWorkStation?.description"
              class="description"
            >
              <p class="client_card_description">
                sdadadas
                {{ projectMetaData.userWorkStation?.description }}
              </p>
            </div> -->
          </v-card>
        </div>
      </div>

      <!-- Property -->
      <div class="properties_wrapper">
        <div class="header">
          <div class="title">
            <h4 id="project_properties_title">
              Properties
              <span class="count__property pointer_events_none">{{
                projectData?.projectProperties?.length
              }}</span>
            </h4>
          </div>
        </div>
        <div class="properties_wrapper__content">
          <div class="properties_wrapper__left">
            <div
              class="properties_wrapper__info"
              v-for="(property, index) in projectData?.projectProperties"
              :key="property?.id"
            >
              <v-icon
                class="delete__icon"
                v-if="isEditPropertiesSection"
                icon="mdi-delete"
                @click.stop="onClickRemoveProperty(property)"
              ></v-icon>

              <div class="properties_wrapper__info__left">
                <div class="location">
                  <v-icon
                    icon="mdi mdi-map-marker"
                    class="pointer_events_none"
                  ></v-icon>
                  {{ property?.property?.postcode }}
                </div>

                <div class="prop_name">
                  <v-icon class="pointer_events_none">
                    <img
                      alt="house-icon"
                      :src="require('@/assets/icons/house.svg')"
                    />
                  </v-icon>
                  <h6 class="pointer_events_none">
                    {{ property?.property?.address }}
                  </h6>
                </div>

                <div class="property__info">
                  <div class="property__info__item">
                    {{ property?.property?.propertyType || "NA" }}
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">{{
                      property?.property?.beds || "NA"
                    }}</span>
                    bedroom
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">{{
                      property?.property?.propertySize || "NA"
                    }}</span>
                    sqm
                  </div>
                </div>

                <div class="property_type">
                  <v-icon
                    icon="mdi mdi-account-circle"
                    class="pointer_events_none"
                  ></v-icon
                  >Commercial
                </div>
              </div>
              <div class="properties_wrapper__info__right">
                <div class="property_image">
                  <img
                    src="../../../assets/images/property-sample-image.jpg"
                    alt=""
                  />
                </div>
                <div v-if="!isEditPropertiesSection" class="prop_count">
                  {{ index + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div class="properties_wrapper__right">
            <div
              class="map__wrapper"
              id="map_properties"
              v-if="addresses && addresses.length"
            >
              <AddressMapComponent
                class="pointer_events_none"
                :latlng="[53.5809811, -0.6393335]"
                :addresses="addresses"
              />
            </div>
          </div>
        </div>
        <div v-if="isEditPropertiesSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            @click="onClickTogglePropertyListModal"
            variant="text"
          >
            Add more Property
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              :loading="isLoadingOnCancel"
              @click="onClickCancelButton"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Documents / Attachments -->
      <div v-if="!loadingSelectProperty" class="documents_attachment">
        <div class="title">
          <h3 id="project_documents_attachment_name">
            Documents / Attachments
          </h3>
          <v-select
            v-model="propertyId"
            label="Select property"
            :items="projectAttachments"
            item-title="address"
            item-value="propertyId"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            @update:modelValue="changePropertyId"
            :menu-props="{ contentClass: 'custom__text__selector' }"
          >
          </v-select>
        </div>

        <v-expansion-panels v-model="panel" multiple class="accordion__wrapper">
          <v-expansion-panel
            v-for="(property, index) in projectAttachments"
            :key="index"
          >
            <v-expansion-panel-title>
              <v-icon class="house__icon">
                <img :src="require('@/assets/icons/house.svg')" alt="" />
              </v-icon>
              <h6>
                {{ property?.address }}
              </h6>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  class="custom_edit"
                  v-if="expanded && !isEditAttachmentsSection.flag"
                  icon="mdi-pencil"
                  @click="updateAttachmentsSection(index)"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-chevron-down"
                  class="bottom__chevron"
                ></v-icon>
              </template>
            </v-expansion-panel-title>

            <div class="group__cards" v-if="isShowGroupCard(index)">
              <div class="group__card__text">
                <v-expansion-panel-text>
                  <div
                    class="accordion_inner_title"
                    v-for="category in getFilterCategory(property.categories)"
                    :key="category.id"
                  >
                    <h3>
                      {{ category?.name }}
                    </h3>
                    <div class="doc_main">
                      <div
                        class="doc_main__list"
                        v-for="attachment in category.attachments"
                        :key="attachment.id"
                      >
                        <div
                          class="property__img__name"
                          @click.stop="
                            onClickOpenNewTab(attachment.attachmentUrl)
                          "
                        >
                          <v-icon icon="mdi mdi-image-outline"></v-icon>
                          <span class="tw-cursor-pointer">{{
                            attachment?.originalName
                          }}</span>
                        </div>
                        <div
                          v-if="
                            isEditAttachmentsSection.flag &&
                            isEditAttachmentsSection.index === index
                          "
                          @click="
                            onClickRemoveAttachment(
                              attachment,
                              property,
                              category?.name,
                              index,
                              category?.id
                            )
                          "
                        >
                          <v-icon
                            icon="mdi-close"
                            class="pointer_events_none"
                          ></v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!isEditAttachmentsSection.flag"
                    class="property__images"
                  >
                    <div
                      v-for="(attachment, idx) in getFilterImages(
                        property.categories
                      )"
                      :key="attachment.id"
                      class="property__item__images"
                    >
                      <img
                        :src="attachment?.attachmentUrl"
                        @click="openCarousel(idx, property.categories)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      isEditAttachmentsSection.flag &&
                      isEditAttachmentsSection.index === index
                    "
                    class="edit-mode-button"
                  >
                    <v-btn
                      class="button button-purple-border"
                      @click="onClickToggleAttachmentModal"
                    >
                      Upload More
                    </v-btn>
                    <div class="edit__buttons">
                      <v-btn
                        class="button button-white"
                        variant="outlined"
                        :loading="isLoadingOnAttachmentCancel"
                        @click="onCancelAttachments"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="button button-orange"
                        size="large"
                        block
                        :loading="isLoadingRemoveAttachments"
                        @click="onClickSaveButtonAttachments"
                      >
                        save
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <CommonLoader :loading="loadingSelectProperty" />
    </div>

    <!-- AttachmentModal -->
    <CommonDialog
      v-model="isShowAttachmentModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      width="832px"
      max-width="100%"
      persistent
      :className="['property__upload__modal ']"
    >
      <template v-slot:body>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onClickToggleAttachmentModal"
        ></v-icon>
        <div class="modal__content">
          <div class="left_upload_img">
            <h4>Upload images and files.</h4>

            <div class="upload__area">
              <div class="uploade-wrapper">
                <v-file-input
                  class="upload_file"
                  label="Upload"
                  append-inner-icon="mdi-image-plus"
                  accept="image/png, image/jpeg, image/jpg"
                  variant="solo-filled"
                  v-model="selectedFile"
                  :class="{ 'added-img': imageUrl }"
                  @change="onFileChange"
                  @click:clear="clearImageUrl"
                  multiple
                >
                  <!-- <template v-slot:append-inner>
                    <v-icon>mdi-image-plus</v-icon>
                  </template> -->
                  <!-- append-icon="mdi-upload" -->
                </v-file-input>
                <v-img
                  class="uploaded-img"
                  :width="200"
                  aspect-ratio="16/9"
                  cover
                  :src="imageUrl"
                ></v-img>
                <v-icon icon="mdi mdi-file-image-box"></v-icon>
                <p class="error_msg" v-if="errorMessage">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="upload__text">
                <p>
                  <strong>Drag and drop documents, or</strong>
                  <span>Browse</span>
                </p>
                <p class="">
                  Attachments such as images, design ideas and documents help
                  better understand the project and provide increasingly
                  accurate estimates.
                </p>
              </div>
            </div>
            <div class="selected-info">
              <v-btn
                aria-label="Refresh"
                class="button button-orange"
                @click="addPropertyImage"
                :disabled="!uploadedImages"
                >{{ attachmentDataList.length ? "ADD ANOTHER" : "add" }}</v-btn
              >
            </div>
          </div>
          <div class="image__upload__wrapper">
            <div v-if="attachmentDataList.length">
              <h5>Uploaded Files</h5>
              <div class="uploaded_details_wrapper">
                <div
                  class="show_uploaded_details"
                  v-for="(attachment, index) in attachmentDataList"
                  :key="index"
                >
                  <v-img
                    class="uploaded-img"
                    :width="156"
                    :height="88"
                    aspect-ratio="16/9"
                    cover
                    :src="attachment?.uploadedImages?.url"
                  ></v-img>
                  <div class="prop_img_details">
                    <h5>{{ attachment?.uploadedImages?.name }}</h5>
                    <v-btn
                      @click="
                        removeImageFromAttachments(
                          index,
                          attachment?.uploadedImages?.attachmentId
                        )
                      "
                      variant="text"
                      class="button button-lightGray"
                    >
                      <img src="../../../assets/icons/delete.svg" alt="" />
                    </v-btn>
                  </div>

                  <div class="upload__list">
                    <v-select
                      v-model="attachment.propertyId"
                      label="Select property"
                      :items="userSelectedProperties"
                      item-title="address"
                      item-value="id"
                      multiple
                      variant="outlined"
                      density="compact"
                      class="selectprop_dropdown"
                      :menu-props="{ contentClass: 'text__selector' }"
                    >
                    </v-select>
                    <v-text-field
                      density="compact"
                      placeholder="E.g. Public liability"
                      variant="outlined"
                      v-model="attachment.userCategoryName"
                      class="v-text-custom selectprop_dropdown"
                      :menu-props="{ contentClass: 'text__selector' }"
                    >
                      <v-icon
                        icon="mdi-chevron-down"
                        :id="`menu-activator-${index}`"
                        class="cursor-pointer"
                      ></v-icon>
                    </v-text-field>
                  </div>
                  <v-menu
                    transition="slide-y-reverse-transition"
                    class="slider-menu-active pro_review_drop"
                    :activator="`#menu-activator-${index}`"
                    :active="true"
                    :menu-props="{ contentClass: 'pro_review_drop' }"
                  >
                    <v-list
                      class="dropdown_selector"
                      :menu-props="{ contentClass: 'text__selector' }"
                    >
                      <v-list-item
                        v-for="(item, index) in userCategory"
                        :key="index"
                      >
                        <v-list-item-title
                          class="cursor-pointer"
                          @click="attachment.userCategoryName = item.name"
                          >{{ item.name }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </div>
            <div v-else class="blank_project">
              <p>Your added project attachments will show up here</p>
            </div>
          </div>
        </div>

        <div class="footer-buttons">
          <v-btn
            class="button button-purple-border"
            @click="onClickToggleAttachmentModal"
            variant="outlined"
          >
            cancel
          </v-btn>
          <v-btn
            class="button button-orange"
            :loading="isLoadingUploadAttachments"
            @click="onClickSaveButtonAttachmentsModal"
          >
            save
          </v-btn>
        </div>
      </template>
    </CommonDialog>

    <!-- CarouselDialog -->
    <v-dialog
      v-model="isCarouselDialog"
      height="auto"
      max-width="600"
      class="project__carousal__modal"
      :show-arrows="false"
    >
      <v-card>
        <template v-slot:actions>
          <v-btn
            icon="mdi-close"
            @click="isCarouselDialog = false"
            class="d-flex"
          ></v-btn>
          <v-carousel
            hide-delimiter-background
            show-arrows="hover"
            v-model="currentIndex"
          >
            <v-carousel-item
              v-for="attachment in carouselImageList"
              :key="attachment.id"
            >
              <v-img
                cover
                :src="attachment?.attachmentUrl"
                height="100%"
                width="100%"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </template>
      </v-card>
    </v-dialog>
    <SpecialismMultiSelectModel
      v-if="isShowSpecialismModal"
      :isHistorical="false"
      :projectId="projectData.id"
      :selectedSpecialism="projectData?.subCategories"
      :isLoading="isLoadingForSaveSpecialism"
      @onClickCloseTab="onClickCloseTab"
      @onClickSaveButtonClick="onClickSaveButtonClick"
    />
  </div>
  <CommonLoader :loading="loading" />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  reactive,
  onBeforeMount,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import BackButton from "@/core/components/common/BackButton.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import { quillEditor } from "vue3-quill";
import { PROJECT_ROUTE } from "../routes";
import { REQUEST_PROJECT_ESTIMATE_ROUTE } from "../routes";
import { JOBS_STORE } from "@/store/modules/jobs";
import { PROJECT, PROJECT_STORE } from "@/store/modules/project";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import SpecialismMultiSelectModel from "../modal/SpecialismMultiSelectModel.vue";
import { USER_STORE } from "@/store/modules/user";
import CommonLoader from "@/core/components/CommonLoader.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import moment from "moment";
import currencyFilter from "@/filters/currency.filter";
import {
  getImageStringToImageURL,
  getUserSubSkillList,
} from "@/core/utils/common";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import { WORKSTATION } from "@/store/modules/workstation";
const store = useStore();
const route = useRoute();
const router = useRouter();
const projectData = ref(null) as any;
const internalInstance = getCurrentInstance();
const projectComments: any = ref([]);
const isEditProjectNoteSection = ref(false);
const isEditPropertiesSection = ref(false);

const isEditAttachmentsSection = ref({
  flag: false,
  id: -1,
}) as any;
const isLoadingRemoveAttachments = ref(false);
const isShowAttachmentModal = ref(false);
const isLoadingProjectNotes = ref(false);
const isShowSpecialismModal = ref(false);
const isLoadingOnCancel = ref(false);
const isCarouselDialog = ref(false);
const currentIndex = ref(0);
const isLoadingOnAttachmentCancel = ref(false);
const isLoadingUpdatePropriety = ref(false);
const projectMetaData = ref(null) as any;
const loading = ref(false);
const projectRefId = ref("") as any;
const addresses: any = ref([]);
const projectAttachments = ref([]) as any;
const loadingSelectProperty = ref(false);
const isLoadingProjectData = ref(false);
const isShowPropertyListModal = ref(false);

const selectedFile = ref([]);
const imageUrl = ref("");
const errorMessage = ref("");
const uploadedImages = ref(null) as any;
const userCategory = [
  {
    name: "Floorplan",
  },
  {
    name: "Garden",
  },
  {
    name: "Materials",
  },
  {
    name: "Maps",
  },
];
const panel = ref([]) as any;
const propertyId = ref();
const attachmentsUpdateList = ref([]) as any;
const carouselImageList = ref();
const availableCategoryList = ref();
const attachmentDataList = ref([]) as any;
const cloneAttachmentDataList = ref([]) as any;
const isLoadingUploadAttachments = ref(false);
const isNewPropertyAdded = ref(false);
const removeAttachmentsIds = ref([]) as any;
const isLoadingForSaveSpecialism = ref(false);
const selectedIndices = ref([]) as any;
const userSelectedProperties = ref([]) as any;
const deletedProject = ref([]) as any;

const projectTitle = ref("");

const isEditSpecialismSection = ref(false);
const state = reactive({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "Description here",
    modules: {},
  },
  disabled: false,
});

const onClickSaveProjectNotes = async () => {
  try {
    isLoadingProjectNotes.value = true;
    await store.dispatch(`${PROJECT_STORE}/updateProject`, {
      projectId: projectData.value?.id,
      data: {
        description: state.content,
        name: projectTitle.value,
      },
    });
    displayToast("Update successfully.", "success");
  } catch {
    console.log();
  } finally {
    await setProjectData();
    isEditProjectNoteSection.value = false;
    isLoadingProjectNotes.value = false;
  }
};
const onCancelProjectNotes = () => {
  isEditProjectNoteSection.value = false;
};
const updateProjectNameNote = (project: any) => {
  projectTitle.value = project?.name;
  state.content = project?.description;
  isEditProjectNoteSection.value = true;
};
const onClickBackToSearchResults = async () => {
  await store.dispatch(
    `${PROJECT_STORE}/setProjectRedirectTabName`,
    "requested-projects"
  );
  router.push({ name: PROJECT_ROUTE });
};
const updateSpecialismSection = () => {
  isEditSpecialismSection.value = true;
};

const onClickCloseTab = () => {
  isShowSpecialismModal.value = false;
};
const onClickAddMoreSpecialism = () => {
  isShowSpecialismModal.value = true;
};
const onClickCancelSpecialismButton = async () => {
  isEditSpecialismSection.value = false;
  await setProjectData();
};
const setProjectData = async () => {
  try {
    isLoadingProjectData.value = true;
    const projectDataResponse = await store.dispatch(
      `${PROJECT_STORE}/getProjectData`,
      {
        projectRefId: projectRefId.value,
      }
    );
    projectData.value = projectDataResponse;
    if (!projectMetaData.value) {
      if (projectData.value.projectMetaData) {
        projectMetaData.value = projectData.value.projectMetaData;

        const profileImg = projectMetaData.value?.userWorkStation.profileImage;

        projectMetaData.value = {
          ...projectMetaData.value,
          userWorkStation: {
            ...projectMetaData.value?.userWorkStation,
            profileURL: await getImageStringToImageURL(profileImg),
          },
        };
      }
    }

    availableCategoryList.value = await store.dispatch(
      `${PROJECT_STORE}/getProjectCategoryList`,
      projectDataResponse?.id
    );

    store.dispatch(`${PROJECT}/projectDetails`, {
      inviteMembers: projectData.value?.invitedMembersAndRoles,
    });

    projectAttachments.value = projectDataResponse?.projectAttachments;

    selectedIndices.value = projectDataResponse?.projectProperties.map(
      (property: any) => property?.property?.id
    );
    projectDataResponse?.projectProperties.map((item: any) => {
      const existingPropertyIndex = userSelectedProperties.value.findIndex(
        (property: any) => property.id === item.property.id
      );
      if (existingPropertyIndex === -1)
        userSelectedProperties.value.push(item.property);
    });
    attachmentDataList.value =
      projectDataResponse?.projectAttachmentProperties.map(
        (propertyItems: any) => ({
          uploadedImages: {
            url: propertyItems[0]?.attachmentUrl,
            name: propertyItems[0]?.projectAttechment?.originalName,
            attachment: propertyItems[0]?.projectAttechment?.attachment,
            attachmentId: propertyItems[0]?.projectAttechment?.id,
            id: propertyItems[0]?.id,
          },
          propertyId: propertyItems.map(
            (property: any) => property.property.id
          ),
          userCategoryName: propertyItems[0]?.projectAttachmentsCategory?.value,
        })
      );
    cloneAttachmentDataList.value = JSON.parse(
      JSON.stringify(attachmentDataList.value)
    );
  } catch (error) {
    console.log();
    if (!projectData.value) router.push({ name: PROJECT_ROUTE });
  } finally {
    isLoadingProjectData.value = false;
  }
};

const specialismCategoryClass = (category: string) => {
  if (category.toLowerCase() === "krystal") {
    return "krystal";
  }
  if (category.toLowerCase() === "property") {
    return "property";
  }
  if (category.toLowerCase() === "trade") {
    return "trade";
  }
};
const redirectToRequestProjectEstimate = () => {
  router.push({
    name: REQUEST_PROJECT_ESTIMATE_ROUTE,
    params: {
      refId: projectData.value?.refId,
      estimateId: projectMetaData.value.projectEstimation?.id,
    },
  });
};
const displayToast = (message: string, type: string) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};
const getProjectData = async () => {
  try {
    loading.value = true;
    await setProjectData();

    if (projectRefId.value) {
      const addressList: any = [];
      projectData.value.projectProperties.length &&
        projectData.value.projectProperties.map((item: any) => {
          let latlang = null;
          const property = item.property;
          if (property && property.addressData) {
            const address =
              typeof property.addressData === "string"
                ? JSON.parse(property.addressData)
                : property.addressData;
            latlang = address?.latlng;
            addressList.push({
              address: property.address,
              uprn: property.uprn,
              latlang,
              id: property.id,
              postcode: property.postcode,
            });
          }
        });

      addresses.value = addressList;
    }
  } catch (error) {
    console.log("error:", error);
  } finally {
    loading.value = false;
  }
};
const onClickDeleteCategoryButton = (subCategoryId: number) => {
  projectData.value.subCategories = projectData.value.subCategories.filter(
    (subCategory: any) => {
      return subCategory.id !== subCategoryId;
    }
  );
};
const onClickSaveButtonClick = async (selectedCategory: any) => {
  try {
    isLoadingForSaveSpecialism.value = true;
    const projectSubCategories = projectData.value?.subCategories.map(
      (subCategory: any) => {
        return subCategory.id;
      }
    );

    const removedSubCategories = projectSubCategories.filter(
      (subCategoryId: number) => {
        return !selectedCategory.includes(subCategoryId);
      }
    );

    if (removedSubCategories.length) {
      projectData.value.subCategories = projectData.value.subCategories.filter(
        (subCategory: any) => {
          return !removedSubCategories.includes(subCategory.id);
        }
      );
    }

    const newAddedSubCategoriesIds = selectedCategory.filter(
      (subCategoryId: number) => {
        return !projectSubCategories.includes(subCategoryId);
      }
    );

    if (newAddedSubCategoriesIds.length) {
      const newAddedSubCategoriesData = await store.dispatch(
        `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
        {
          subCategoriesIds: newAddedSubCategoriesIds,
        }
      );

      newAddedSubCategoriesData.forEach((subCategory: any) => {
        projectData.value?.subCategories.push(subCategory);
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoadingForSaveSpecialism.value = false;
    isShowSpecialismModal.value = false;
  }
};
const onClickSaveSpecialismButton = async () => {
  let selectedCategoryFinal = projectData.value?.subCategories.map(
    (subCategory: any) => {
      return subCategory.id;
    }
  );

  await store.dispatch(`${PROJECT_STORE}/updateSubSpecialismCategory`, {
    projectId: projectData.value?.id,
    data: {
      categoryData: selectedCategoryFinal,
    },
  });

  onClickCancelSpecialismButton();
};
const onClickRemoveProperty = async (ProjectDetails: any) => {
  deletedProject.value.push({
    id: ProjectDetails?.id,
    propertyId: ProjectDetails?.property?.id,
    isDeleted: true,
  });
  projectData.value.projectProperties =
    projectData.value.projectProperties.filter((property: any) => {
      return property?.id !== ProjectDetails?.id;
    });
};
const onClickTogglePropertyListModal = () => {
  isShowPropertyListModal.value = !isShowPropertyListModal.value;
};

const onClickCancelButton = async () => {
  try {
    isLoadingOnCancel.value = true;
    await setProjectData();
    isEditPropertiesSection.value = false;
  } catch (e) {
    console.log("Caught exception", e);
  } finally {
    isLoadingOnCancel.value = false;
    deletedProject.value = [];
  }
};
const updateProperties = async (propertiesData: any) => {
  try {
    isLoadingUpdatePropriety.value = true;
    if (propertiesData.length && projectData.value?.id)
      await store.dispatch(`${PROJECT_STORE}/updateProject`, {
        projectId: projectData.value?.id,
        data: { propertiesData },
      });
  } catch (error) {
    console.log("error", error);
  } finally {
    isLoadingUpdatePropriety.value = false;
  }
};
const onClickSaveButton = async () => {
  await updateProperties(deletedProject.value);
  onClickCancelButton();
  deletedProject.value = [];
};
const changePropertyId = async (value: any) => {
  if (value.length > 0) {
    try {
      loadingSelectProperty.value = true;
      let selectedProperties = await store.dispatch(
        `${PROJECT_STORE}/getProjectDataSelectedProperties`,
        {
          projectRefId: projectRefId.value,
          propertyIds: value,
        }
      );
      projectAttachments.value = selectedProperties;
    } catch (error) {
      console.log("error", error);
    } finally {
      loadingSelectProperty.value = false;
    }
  } else {
    projectAttachments.value = projectData.value?.projectAttachments;
  }
};
const updateAttachmentsSection = (index: number) => {
  panel.value.push(index);

  isEditAttachmentsSection.value.flag = true;
  isEditAttachmentsSection.value.index = index;
};
const isShowGroupCard = (index: number) => {
  return panel.value.includes(index);
};
const getFilterCategory: any = (category: any) => {
  const categoryData = Object.entries(category).map((data: any) => ({
    name: data[0],
    id: data[1][0]?.categoryId,
    attachments: data[1],
  }));
  return categoryData;
};
const getFilterImages: any = (category: any) => {
  const filterImages: any = [];
  return Object.values(category)
    .flat()
    .filter((category: any) => {
      if (!filterImages.includes(category.attachmentUrl)) {
        filterImages.push(category.attachmentUrl);
        return true;
      }
      return false;
    });
};
const onClickRemoveAttachment = (
  attachment: any,
  category: any,
  categoryName: any,
  attachmentIndex: any,
  categoryId: number
) => {
  projectAttachments.value[attachmentIndex].categories[categoryName] =
    projectAttachments.value[attachmentIndex].categories[categoryName].filter(
      (category: any) => {
        return category?.id !== attachment?.id;
      }
    );

  attachmentsUpdateList.value.push({
    id: attachment?.id,
    propertyIds: [
      {
        id: category?.id,
        propertyId: category?.propertyId,
        isDeleted: true,
      },
    ],
    attechmentCategoryId: attachment?.categoryId,
    isDeleted: false,
  });
};
const onClickOpenNewTab = (attachment: any) => {
  try {
    window.open(attachment);
  } catch (e) {
    console.log(e);
  }
};
const openCarousel = (idx: any = 0, categoryData: any) => {
  isCarouselDialog.value = true;
  carouselImageList.value = getFilterImages(categoryData);
  currentIndex.value = idx;
};
const onClickToggleAttachmentModal = () => {
  isShowAttachmentModal.value = !isShowAttachmentModal.value;
  isNewPropertyAdded.value = false;
};
const onCancelAttachments = async () => {
  try {
    isLoadingOnAttachmentCancel.value = true;
    await setProjectData();
    isEditAttachmentsSection.value.flag = false;
  } catch (e) {
    console.log("Error :", e);
  } finally {
    isLoadingOnAttachmentCancel.value = false;
  }
};
const onClickSaveButtonAttachmentsModal = async () => {
  try {
    isLoadingUploadAttachments.value = true;

    //new attachment add functionality
    const newAttachmentDataList = attachmentDataList.value.filter(
      (attachment: any) => attachment?.uploadedImages.imageData
    );
    const filterAttachmentDataList = attachmentDataList.value.filter(
      (attachment: any) => !attachment?.uploadedImages.imageData
    );
    // check if new category add then upload that

    for (const attachment of attachmentDataList.value) {
      const existCategory = availableCategoryList.value.find(
        (category: any) => category.label === attachment.userCategoryName
      );
      if (!existCategory) {
        const response = await store.dispatch(
          `${PROJECT_STORE}/attachmentCategory`,
          {
            label: attachment.userCategoryName,
            value: attachment.userCategoryName,
          }
        );
        availableCategoryList.value.push(response);
      }
    }

    let newCreatedAttachment = [];
    if (newAttachmentDataList.length) {
      const formData = new FormData();
      newAttachmentDataList.forEach((attachment: any) => {
        if (attachment.uploadedImages.imageData) {
          formData.append(`files`, attachment.uploadedImages.imageData);
        }
      });

      const uploadMultipleImage = await store.dispatch(
        `${USER_STORE}/uploadMultipleImage`,
        formData
      );

      newCreatedAttachment = uploadMultipleImage.map(
        (item: any, index: number) => ({
          publicUrl: item.publicUrl,
          attachment: item.attachment,
          originalName: item.originalName,
          propertyIds: newAttachmentDataList[index].propertyId.map(
            (id: any) => ({ propertyId: id, isDeleted: false })
          ),
          attechmentCategoryId: availableCategoryList.value.find(
            (category: any) =>
              category.label === newAttachmentDataList[index].userCategoryName
          ).id,
          isDeleted: false,
        })
      );
    }

    const finalDataAttachment: any = [];
    const projectDefaultPropertyIds = projectData.value?.projectProperties.map(
      (property: any) => property?.property?.id
    );
    cloneAttachmentDataList.value.forEach((attachment: any, index: any) => {
      let attachmentData;
      if (attachment.isDeleted) {
        attachmentData = {
          id: attachment?.uploadedImages?.attachmentId,
          attachment: attachment?.uploadedImages?.attachment,
          originalName: attachment?.uploadedImages?.name,
          attechmentCategoryId: attachment?.uploadedImages?.attachmentId,
          propertyIds: attachment.propertyId.map((id: any) => ({
            id: attachment?.uploadedImages?.id,
            propertyId: id,
            isDeleted: true,
          })),
          isDeleted: attachment.isDeleted,
        };
      } else {
        const propertyIds: any = [];
        projectDefaultPropertyIds.forEach((propertyId: number) => {
          if (
            attachment.propertyId.includes(propertyId) &&
            filterAttachmentDataList[index].propertyId.includes(propertyId)
          ) {
            propertyIds.push({
              id: attachment?.uploadedImages?.id,
              propertyId: propertyId,
              isDeleted: false,
            });
          } else if (
            !attachment.propertyId.includes(propertyId) &&
            filterAttachmentDataList[index].propertyId.includes(propertyId)
          ) {
            propertyIds.push({
              propertyId: propertyId,
              isDeleted: false,
            });
          } else if (
            attachment.propertyId.includes(propertyId) &&
            !filterAttachmentDataList[index].propertyId.includes(propertyId)
          ) {
            propertyIds.push({
              id: attachment?.uploadedImages?.id,
              propertyId: propertyId,
              isDeleted: true,
            });
          }
        });

        attachmentData = {
          id: attachment?.uploadedImages?.attachmentId,
          attachment: attachment?.uploadedImages?.attachment,
          originalName: attachment?.uploadedImages?.name,
          attechmentCategoryId: availableCategoryList.value.find(
            (category: any) =>
              category.label ===
              filterAttachmentDataList[index].userCategoryName
          ).id,
          propertyIds: propertyIds,
        };
      }
      finalDataAttachment.push(attachmentData);
    });
    await store.dispatch(`${PROJECT_STORE}/updateProject`, {
      projectId: projectData.value?.id,
      data: {
        attachments: [...newCreatedAttachment, ...finalDataAttachment],
      },
    });

    await setProjectData();

    isEditAttachmentsSection.value.flag = false;
  } catch (error) {
    console.log();
  } finally {
    isLoadingUploadAttachments.value = false;
    onClickToggleAttachmentModal();
  }
};
const onClickSaveButtonAttachments = async () => {
  try {
    isLoadingRemoveAttachments.value = true;
    await store.dispatch(`${PROJECT_STORE}/updateProject`, {
      projectId: projectData.value?.id,
      data: {
        attachments: attachmentsUpdateList.value,
      },
    });
    attachmentsUpdateList.value = null;
    await setProjectData();
    isEditAttachmentsSection.value.flag = false;
  } catch {
    console.log();
  } finally {
    isLoadingRemoveAttachments.value = false;
  }
};
const clearImageUrl = () => {
  imageUrl.value = "";
};
const onFileChange = async (event: any) => {
  const fileInput = event.target;
  errorMessage.value = "";
  if (fileInput.files && fileInput.files.length > 0) {
    Array.from(fileInput.files).forEach((file: any) => {
      if (file.type.includes("image")) {
        // Check image size limit (200MB)
        if (file.size <= 200 * 1024 * 1024) {
          imageUrl.value = URL.createObjectURL(file);
          uploadedImages.value = {
            url: imageUrl.value,
            name: file.name,
            imageData: file,
          };
        } else {
          errorMessage.value = "Image size exceeds the limit (200MB).";
          // Handle error message or display notification
        }
      } else if (file.type.includes("video")) {
        // Check video size limit (20MB)
        if (file.size <= 10 * 1024 * 1024) {
          uploadedImages.value = {
            type: "video",
            name: file.name,
            imageData: file,
          };
          // Process video file
        } else {
          errorMessage.value = "Video size exceeds the limit (20MB).";
          // Handle error message or display notification
        }
      } else {
        // Allow other file types without size validation
        imageUrl.value = URL.createObjectURL(file);
        uploadedImages.value = {
          url: imageUrl.value,
          name: file.name,
          imageData: file,
        };
      }
    });
  } else {
    console.error("Error: No file selected.");
  }
};
const addPropertyImage = () => {
  attachmentDataList.value.push({
    uploadedImages: { ...uploadedImages.value },
    propertyId: null,
    userCategoryName: null,
  });
  uploadedImages.value = null;
  isNewPropertyAdded.value = true;
  selectedFile.value = [];
  imageUrl.value = "";
};
const removeImageFromAttachments = (index: number, attachmentId: any) => {
  removeAttachmentsIds.value.push(attachmentId);
  attachmentDataList.value.splice(index, 1);
  cloneAttachmentDataList.value.forEach((attachment: any) => {
    if (attachment.uploadedImages.attachmentId === attachmentId) {
      attachment["isDeleted"] = true;
    }
  });
};
const duration = computed(() => {
  const startDate = moment(
    projectMetaData.value?.projectEstimation?.suggestedStartDate
  );
  const endDate = moment(
    projectMetaData.value?.projectEstimation?.suggestedEndDate
  );

  const totalDays = endDate.diff(startDate, "days");
  const totalWeeks = endDate.diff(startDate, "weeks");

  let result;

  if (totalDays % 7 === 0) {
    result = `${totalWeeks} week${totalWeeks !== 1 ? "s" : ""}`;
  } else {
    result = `${totalDays} day${totalDays !== 1 ? "s" : ""}`;
  }

  return result;
});
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);
const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};
const formattedCurrency = (amount: any) => {
  return currencyFilter.formatToCurrency(amount);
};
onBeforeMount(async () => {
  if (route.params && route.params.refId) {
    projectRefId.value = route.params.refId;

    await getProjectData();

    try {
      if (!getAllSpecialism.value.length) {
        await store.dispatch(
          `${USER_STORE}/setAllSpecialismsCategory`,
          activeUserWorkstation.value.id
        );
      }
    } catch (error) {
      console.log();
    }
  } else {
    router.push({ name: PROJECT_ROUTE });
  }
});
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectReview.scss";

// .properties_wrapper {
//   .line {
//     border: none;
//     border-top: 1px dashed #808080;
//     margin: 20px 0;
//     width: 100%;
//   }
// }

.client_info__wrapper {
  padding: 24px;
  background-color: #fff;
  border-radius: 13px;
  margin-top: 32px;
  h3 {
    @include fluidFont(25, 25, 1.3);
    font-weight: 600;
    color: rgba($blueDark, 1);
    letter-spacing: 0.64px;
    padding-bottom: 32px;
    border-bottom: 1px dashed rgba(134, 135, 165, 1);
  }
  .client__cardlist {
    margin-top: 20px;
    .client_card {
      padding: 16px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border-radius: 8px;
      width: calc(50% - 12px);
      max-width: 100%;
      @include respond(s720) {
        width: 100%;
      }
      .card_header {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        padding-bottom: 14px;
        border-bottom: 1px dashed rgba(134, 135, 165, 1);
        .client_img {
          width: 56px;
          height: 56px;
          overflow: hidden;
          border-radius: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .client_info {
          h4 {
            @include fluidFont(20, 20, 1.3);
            font-weight: 700;
            color: rgba($blueDark, 1);
          }
          .header_bottom {
            @include fluidFont(12, 12, 1.3);
            font-weight: 700;
            color: rgba($blueDark, 0.5);
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
      }
      .card__info__tags__custom {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 14px 0;
        border-bottom: 1px dashed rgba(134, 135, 165, 1);
        // @include respond(s1800) {
        //   width: 100%;
        //   flex-wrap: wrap;
        // }
        // @include respond(s1024) {
        //   width: calc(100% - 100px);
        //   flex-wrap: nowrap;
        // }
        // @include respond(md) {
        //   width: 100%;
        //   flex-wrap: wrap;
        // }
        // @include respond(s720) {
        //   width: calc(100% - 100px);
        // }
        // @include respond(sm) {
        //   width: 100%;
        //   flex-wrap: nowrap;
        // }
        .custom_tags {
          display: flex;
          gap: 4px;
          align-items: center;
          background-color: rgba($blueDark, 0.04);
          border-radius: 8px;
          padding: 4px 8px;
          // width: 33.33%;
          .v-icon {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            overflow: hidden;
            background-color: rgba($blueDark, 1);
            img {
              filter: invert(99%) sepia(0%) saturate(3735%) hue-rotate(87deg)
                brightness(119%) contrast(100%);
              width: 10px;
            }
          }
          span {
            @include fluidFont(12, 12, 2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.15px;
            display: inline-block;
            width: calc(100% - 25px);
            max-width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include respond(sm) {
              max-width: 70px;
            }
          }
        }
        .custom_tags__more {
          display: inline-flex;
          gap: 4px;
          align-items: center;
          background-color: rgba($blueDark, 0.04);
          border-radius: 8px;
          padding: 4px 8px;
          span {
            @include fluidFont(12, 12, 2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.15px;
            display: inline-block;
            width: 100%;
            max-width: 100%;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
