// import {
//   CreateProjectQuoteResponseDto,
//   ProjectQuote,
//   UpdateProjectQuoteResponseDto,
//   UpdateProjectQuoteStatusRequestDto,
// } from "@/core/packages/shared-library";

import $axios from "../utils/axios-api-config";

export default {
  /**
   * Get project quote details by projectId, projectQuoteId
   * @param projectId
   * @param projectQuoteId
   * @returns ProjectQuote
   */
  getProjectQuoteDetails(
    projectId: number,
    projectQuoteId: number
  ): Promise<any> {
    return $axios.get(`/projects/${projectId}/quotes/${projectQuoteId}`);
  },

  /**
   * Get project quotes by projectId
   * @param projectId
   * @returns ProjectQuoteListResponseDto
   */
  getProjectQuotesByProject(projectId: number) {
    return $axios.get(`/projects/${projectId}/quotes`);
  },

  /**
   * Get the calculated service fee
   * @param projectId
   * @param payload
   * @returns
   */
  // eslint-disable-next-line camelcase
  calculateServiceFee(
    projectId: number,
    payload: { total: number; threshold_percentage: number }
  ) {
    return $axios.post(`/projects/${projectId}/quotes/service-fee`, payload);
  },

  /**
   * Create new project Quote
   * @param projectId
   * @param createProjectQuoteRequestDto
   * @returns CreateProjectQuoteResponseDto
   */
  createProjectQuote(
    projectId: number,
    createProjectQuoteRequestDto: any
  ): Promise<any> {
    return $axios.post(
      `/projects/${projectId}/quotes`,
      createProjectQuoteRequestDto
    );
  },

  /**
   * Update existing project quote
   * @param projectId
   * @param projectQuoteId
   * @param updateProjectQuoteRequestDto UpdateProjectQuoteRequestDto
   * @returns UpdateProjectQuoteResponseDto
   */
  updateProjectQuote(
    projectId: number,
    projectQuoteId: number,
    updateProjectQuoteRequestDto: any
  ): Promise<any> {
    return $axios.put(
      `/projects/${projectId}/quotes/${projectQuoteId}`,
      updateProjectQuoteRequestDto
    );
  },

  /**
   * Delete existing project quote
   * @param projectId
   * @param projectQuoteId
   * @returns
   */
  deleteProjectQuote(projectId: number, projectQuoteId: number) {
    return $axios.delete(`/projects/${projectId}/quotes/${projectQuoteId}`);
  },

  /**
   * Update project quote status
   * @param projectId
   * @param projectQuoteId
   * @param updateProjectQuoteStatusRequestDto UpdateProjectQuoteStatusRequestDto
   * @returns UpdateProjectQuoteResponseDto
   */
  updateProjectQuoteStatus(
    projectId: number,
    projectQuoteId: number,
    updateProjectQuoteStatusRequestDto: any
  ): Promise<any> {
    return $axios.put(
      `/projects/${projectId}/quotes/${projectQuoteId}/status`,
      updateProjectQuoteStatusRequestDto
    );
  },

  // debug
  getProjectQuotes(payload: any) {
    const test = payload;

    return { test };
  },
};
