<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#FFA500" />
    <path
      d="M17.5125 7.74993C17.4776 7.40232 17.516 7.05125 17.6251 6.71936C17.7342 6.38748 17.9117 6.08216 18.1461 5.82308C18.3804 5.564 18.6665 5.35692 18.9858 5.21519C19.3052 5.07347 19.6506 5.00024 20 5.00024C20.3494 5.00024 20.6948 5.07347 21.0142 5.21519C21.3335 5.35692 21.6196 5.564 21.8539 5.82308C22.0883 6.08216 22.2658 6.38748 22.3749 6.71936C22.484 7.05125 22.5224 7.40232 22.4875 7.74993L21.5175 23.6249C21.48 24.0014 21.304 24.3505 21.0235 24.6044C20.7431 24.8583 20.3783 24.9989 20 24.9989C19.6217 24.9989 19.2569 24.8583 18.9765 24.6044C18.696 24.3505 18.52 24.0014 18.4825 23.6249L17.5125 7.74993ZM17.5 29.9999C17.5 29.3369 17.7634 28.701 18.2322 28.2322C18.7011 27.7633 19.337 27.4999 20 27.4999C20.663 27.4999 21.2989 27.7633 21.7678 28.2322C22.2366 28.701 22.5 29.3369 22.5 29.9999C22.5 30.663 22.2366 31.2989 21.7678 31.7677C21.2989 32.2365 20.663 32.4999 20 32.4999C19.337 32.4999 18.7011 32.2365 18.2322 31.7677C17.7634 31.2989 17.5 30.663 17.5 29.9999Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ExlamationFilledIcon",
};
</script>
