<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_231_65788)">
      <path
        d="M49.0001 9.33333H41.7668C41.2252 6.69996 39.7923 4.33381 37.7097 2.63368C35.627 0.933543 33.0219 0.00339389 30.3334 0L25.6667 0C22.9783 0.00339389 20.3732 0.933543 18.2905 2.63368C16.2078 4.33381 14.775 6.69996 14.2334 9.33333H7.00008C6.38124 9.33333 5.78775 9.57917 5.35017 10.0168C4.91258 10.4543 4.66675 11.0478 4.66675 11.6667C4.66675 12.2855 4.91258 12.879 5.35017 13.3166C5.78775 13.7542 6.38124 14 7.00008 14H9.33342V44.3333C9.33712 47.4264 10.5675 50.3917 12.7546 52.5788C14.9417 54.7659 17.907 55.9963 21.0001 56H35.0001C38.0931 55.9963 41.0585 54.7659 43.2456 52.5788C45.4327 50.3917 46.663 47.4264 46.6668 44.3333V14H49.0001C49.6189 14 50.2124 13.7542 50.65 13.3166C51.0876 12.879 51.3334 12.2855 51.3334 11.6667C51.3334 11.0478 51.0876 10.4543 50.65 10.0168C50.2124 9.57917 49.6189 9.33333 49.0001 9.33333ZM25.6667 4.66667H30.3334C31.7807 4.66843 33.192 5.11788 34.3738 5.95337C35.5556 6.78886 36.4501 7.96948 36.9344 9.33333H19.0657C19.5501 7.96948 20.4445 6.78886 21.6263 5.95337C22.8081 5.11788 24.2194 4.66843 25.6667 4.66667ZM42.0001 44.3333C42.0001 46.1898 41.2626 47.9703 39.9498 49.2831C38.6371 50.5958 36.8566 51.3333 35.0001 51.3333H21.0001C19.1436 51.3333 17.3631 50.5958 16.0503 49.2831C14.7376 47.9703 14.0001 46.1898 14.0001 44.3333V14H42.0001V44.3333Z"
        fill="#FA4B00"
      />
      <path
        d="M23.3333 41.9987C23.9522 41.9987 24.5457 41.7529 24.9832 41.3153C25.4208 40.8777 25.6667 40.2842 25.6667 39.6654V25.6654C25.6667 25.0465 25.4208 24.453 24.9832 24.0154C24.5457 23.5779 23.9522 23.332 23.3333 23.332C22.7145 23.332 22.121 23.5779 21.6834 24.0154C21.2458 24.453 21 25.0465 21 25.6654V39.6654C21 40.2842 21.2458 40.8777 21.6834 41.3153C22.121 41.7529 22.7145 41.9987 23.3333 41.9987Z"
        fill="#FA4B00"
      />
      <path
        d="M32.6668 41.9987C33.2857 41.9987 33.8792 41.7529 34.3167 41.3153C34.7543 40.8777 35.0002 40.2842 35.0002 39.6654V25.6654C35.0002 25.0465 34.7543 24.453 34.3167 24.0154C33.8792 23.5779 33.2857 23.332 32.6668 23.332C32.048 23.332 31.4545 23.5779 31.0169 24.0154C30.5793 24.453 30.3335 25.0465 30.3335 25.6654V39.6654C30.3335 40.2842 30.5793 40.8777 31.0169 41.3153C31.4545 41.7529 32.048 41.9987 32.6668 41.9987Z"
        fill="#FA4B00"
      />
    </g>
    <defs>
      <clipPath id="clip0_231_65788">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "TrashRedIcon",
};
</script>
