<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.10582 15.0886C2.19268 14.9202 2.36638 14.8359 2.54009 14.8359C2.73551 14.8359 2.88751 14.9412 2.97436 15.0886C2.97436 15.0886 3.95147 16.9626 4.19032 17.4048C4.45088 17.9312 4.45088 18.4997 4.19032 19.0051C3.86462 19.6157 3.23492 19.9947 2.54009 19.9947C2.25782 19.9947 1.97554 19.9316 1.71498 19.8052C1.28071 19.5947 0.955005 19.2157 0.803011 18.7524C0.651016 18.2892 0.694443 17.8049 0.911578 17.3838L2.10582 15.0886Z"
      :fill="fill"
    />
    <path
      d="M16.6755 2.25781H17.7612C17.8914 2.25781 18 2.36309 18 2.48943V11.2489C18 11.3752 17.8914 11.4805 17.7612 11.4805H16.6755C16.0675 11.4805 15.5681 10.9962 15.5681 10.4066V3.35274C15.5464 2.74211 16.0458 2.25781 16.6755 2.25781Z"
      :fill="fill"
    />
    <path
      d="M0.82526 11.6603V10.439C0.82526 7.57533 3.23546 5.23808 6.1885 5.23808H6.97018V4.88012C6.97018 4.35371 7.40445 3.91153 7.96901 3.91153H8.14271V2.60603C8.14271 2.22702 7.83872 1.86906 7.42617 1.86906C7.40445 1.86906 7.40445 1.86906 7.38274 1.86906C7.03532 1.86906 6.73133 2.03751 6.47077 2.24807C6.25364 2.41652 5.97136 2.52181 5.66737 2.52181C4.88568 2.52181 4.25599 1.82695 4.38627 1.04786C4.51655 0.52145 4.92911 0.100323 5.45024 0.0160976C5.88451 -0.0470715 6.27535 0.0792667 6.53591 0.331943C6.77476 0.542507 7.10046 0.647789 7.40445 0.647789C7.68673 0.647789 7.96901 0.584619 8.20785 0.437225C8.38156 0.310887 8.5987 0.247718 8.83755 0.247718C9.0764 0.247718 9.29353 0.310887 9.46724 0.437225C9.68438 0.605676 9.98837 0.647789 10.2706 0.647789C10.5963 0.647789 10.9003 0.542507 11.1392 0.331943C11.4215 0.0792667 11.8123 -0.0470715 12.2249 0.0160976C12.746 0.100323 13.1803 0.52145 13.2671 1.0268C13.4191 1.82695 12.7894 2.50075 11.986 2.50075C11.6603 2.50075 11.378 2.39547 11.1392 2.20596C10.8786 1.9954 10.5746 1.86906 10.2272 1.86906C9.81466 1.86906 9.51067 2.20596 9.51067 2.60603V3.91153H9.68438C10.2272 3.91153 10.6832 4.33265 10.6832 4.88012V5.23808H15.6337L15.6338 8.56498H10.9438C10.5312 9.19667 9.81466 9.6178 8.98954 9.6178H8.70726C7.88215 9.6178 7.1656 9.19667 6.75305 8.56498H6.21021C5.14625 8.56498 4.25599 9.40724 4.25599 10.4601V11.6813C4.73369 11.8708 5.05939 12.313 5.05939 12.8394V13.3237C5.05939 13.4922 4.9074 13.6396 4.73369 13.6396H0.325849C0.152141 13.6396 0.000145912 13.4922 0.000145912 13.3237V12.8394C0.0218592 12.292 0.369276 11.8498 0.82526 11.6603Z"
      :fill="fill"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#2C9F29",
  },
});
</script>

<script>
export default {
  name: "WaterTapIcon",
};
</script>
