/**
 * Analytics route page
 */
import AnalyticsPage from "../pages/AnalyticsPage.vue";
export const ANALYTICS_ROUTE = "analytics";

export default [
  {
    path: "/analytics",
    name: ANALYTICS_ROUTE,
    component: AnalyticsPage,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Analytics",
    },
  },
];
