<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#4A92E5" />
    <path
      d="M5.44 10.3136C5.44 10.8338 6.82847 11.0841 7.99483 11.0841C7.99658 11.0841 7.99833 11.084 7.99993 11.084C8.00168 11.084 8.00343 11.0841 8.0051 11.0841C9.17168 11.0841 10.56 10.8338 10.56 10.3136C10.56 8.41514 9.86476 9.34891 8.82692 8.54701C8.82245 8.71761 8.45414 8.85543 7.99993 8.85543C7.54573 8.85543 7.17749 8.71761 7.17302 8.54688C6.13525 9.34891 5.44 8.41514 5.44 10.3136Z"
      fill="#FFF"
    />
    <path
      d="M6.68 7.10207C6.71446 7.29776 6.80161 7.44858 6.88184 7.45997C7.00316 7.79054 7.20863 8.10923 7.46226 8.30911C7.62636 8.43854 7.81023 8.51877 8.00508 8.51877C8.1986 8.51877 8.3815 8.43966 8.54475 8.31162C8.8002 8.11147 9.00727 7.79054 9.12909 7.45794C9.20624 7.43502 9.28711 7.28888 9.32002 7.10207C9.33763 7.00213 9.33819 6.90876 9.32505 6.83594H6.67483C6.6619 6.90876 6.66239 7.00213 6.68 7.10207Z"
      fill="#FFF"
    />
    <path
      d="M9.34985 6.58007C9.4551 6.58007 9.54036 6.49481 9.54036 6.38962C9.54036 6.28444 9.4551 6.19918 9.34985 6.19918H9.29799C9.29799 5.69326 9.0083 5.25527 8.58597 5.04099L8.47841 5.65098C8.46989 5.69927 8.42795 5.73324 8.3805 5.73324C8.37484 5.73324 8.36897 5.73275 8.36317 5.73177C8.309 5.72212 8.27287 5.67062 8.28252 5.61645L8.40531 4.91974C8.35925 4.84601 8.27755 4.79688 8.18411 4.79688H7.81573C7.72243 4.79688 7.64073 4.84601 7.59461 4.91974L7.71747 5.61645C7.72698 5.67062 7.69091 5.72212 7.63675 5.73177C7.63095 5.73275 7.62508 5.73324 7.61935 5.73324C7.57196 5.73324 7.52996 5.69934 7.52143 5.65098L7.41395 5.04113C6.99154 5.2552 6.70193 5.69326 6.70193 6.19918H6.65C6.54482 6.19918 6.45963 6.28444 6.45963 6.38962C6.45963 6.49481 6.54482 6.58007 6.65 6.58007H9.34985Z"
      fill="#FFF"
    />
  </svg>
</template>
<script>
export default {
  name: "TradeAvatarCircleBlue",
};
</script>
