import { UserState } from "@/core/models/user";
import state from "./state";

export default {
  user: (state: UserState) => state.user,
  userInsurances: (state: UserState) => state.userInsurances,
  userImage: (state: { userImage: any }) => state.userImage,
  userWorkStationImage: (state: UserState) => state.userWorkStationImage,
  userRole: (state: UserState) => state.userRole,
  userCertificates: (state: UserState) => state.userCertificates,
  isUserProfileVerifiable: (state: UserState) => state.isProfileVerifiable,
  userBusinessDetails: (state: UserState) => state.user?.userBusinessDetails,
  userSpecialisms: (state: UserState) => state.userSpecialisms,
  specialismsCategory: (state: UserState) => state.specialismCategory,
  userNotifications: (state: UserState) => state.userNotifications,
  accountStatuses: (state: UserState) => state.accountStatuses,
  getAllSpecialism: (state: UserState) => state.allSpecialismList,
};
