<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['compliance-record-modal']"
    width="520px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>View Compliance Record</h4>
          <v-btn variant="plain" icon="mdi-close" @click="closeModal"></v-btn>
        </div>
        <div class="compliance-record-modal__sub-title">
          <p>{{ safetyDetail.name }}</p>
        </div>
        <div class="compliance-record-modal__content">
          <p><strong>Heating System Type: </strong> {{ safetyDetail.name }}</p>
          <p>
            <strong>Servicing Frequency: </strong> {{ getServiceFrequency }}
          </p>
          <p>
            <strong>Last Service: </strong>
            {{ formatDate(safetyDetail.serviceDate) }}
          </p>
          <p>
            <strong>Expiry: </strong>{{ formatDate(safetyDetail.expiryDate) }}
          </p>
          <p><strong>Provider: </strong> {{ safetyDetail.provider }}</p>
          <p><strong>Result: </strong>{{ safetyDetail.result }}</p>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { parseJSON } from "@/core/utils/common";
import moment from "moment";
import { computed } from "vue";
const props = defineProps(["safetyDetail"]);
const emits = defineEmits(["on-close"]);
const getServiceFrequency = computed(() => {
  const serviceFrequency = parseJSON(props.safetyDetail?.serviceFrequency);
  return serviceFrequency.frequency && serviceFrequency.interval
    ? `${serviceFrequency.frequency} ${serviceFrequency.interval}`
    : "";
});
const formatDate = (date) => moment(date).format("DD-MM-YYYY");
const closeModal = () => {
  emits("on-close");
};
</script>
