import $axios from "../utils/axios-api-config";

// DEPRACATED SERVICE

export function getProperties(params = {}): Promise<any> {
  return $axios.get("/properties", { params });
}

export function createProperty(formData: any): Promise<any> {
  return $axios.post("/properties", formData);
}

export function updateProperty(
  propertyId: number,
  payload: any = {}
): Promise<any> {
  return $axios.put(`/properties/${propertyId}`, payload);
}

export function getPostCodeSuggestion(postCode: string) {
  return $axios.get(`/properties/postcodes-suggetions?postcode=${postCode}`);
}

export function getAddressFromPostCode(postCode: any) {
  return $axios.get(
    `properties/get-address-from-postcode?postcode=${postCode}`
  );
}

export function getAllDataViewSet(uprn: string) {
  return $axios.get(`/properties/tenurs?uprn=${uprn}`);
}
export function getRaiseAnIssuesPropertyList() {
  return $axios.get(`/property-issues/raise-issue/property-occupier`);
}
