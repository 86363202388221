<template>
  <div
    class="image-dynamic-layout tw-min-h-[60px] tw-w-full tw-flex tw-items-center tw-justify-center tw-p-2"
  >
    <template v-if="!uploadDone">
      <el-progress :percentage="currentNumber" />
    </template>
    <template v-else-if="isDeleting"
      ><Text variant="p" textWeight="400">Deleting...</Text>
    </template>
    <template v-else-if="uploadDone">
      <slot></slot>
    </template>
  </div>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";
import { watch, ref } from "vue";

const props = defineProps({
  isLoading: Boolean,
  isDeleting: Boolean,
  source: String,
  file: Object,
  uploadDone: Boolean,
  currentNumber: Number,
});

const intervalId = ref(null);
</script>
<style lang="scss" scoped>
.attachments-form {
  .el-upload-list__item div.el-progress {
    display: flex !important;
    top: 0 !important;
  }

  .el-progress {
    padding-top: 26px;
  }
}

:deep(.el-progress__text) {
  justify-content: flex-end;
  top: 8px;
  font-weight: 500;
  font-size: 12px !important;
  height: auto !important;
  text-align: right;
}

:deep(.el-progress-bar__inner) {
  background-color: $orange;
}
</style>
