import $axios from "../utils/axios-api-config";

export default {
  getWorkStationLink(token: string) {
    return $axios.get(`/workstation-link-tree/${token}`);
  },
  createWorkStationLink(payload: any) {
    return $axios.post(`/workstation-link-tree/`, payload);
  },
  updateWorkStationLink(linkId: number, payload: any) {
    return $axios.put(`/workstation-link-tree/${linkId}`, payload);
  },
};
