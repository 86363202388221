<template>
  <div>
    <!-- replay comments -->
    <div
      v-if="
        commentElement?.elementId === activeComment?.elementId &&
        comments.length > 0
      "
      class="reply__commentbox"
      :style="{
        top: commentElement?.style?.y + 'px',
        left: commentElement?.style?.x + 'px',
      }"
    >
      <div class="reply__commentbox__card">
        <div class="reply__commentbox__card__icons">
          <div class="left__icon">
            <v-icon
              icon="mdi-chevron-left"
              :disabled="index == 0"
              @click="nextComment(index, 'left')"
            ></v-icon>
            <v-icon
              icon="mdi-chevron-right"
              :disabled="commentsLength - 1 == index"
              @click="nextComment(index, 'right')"
            ></v-icon>
          </div>
          <div class="right__icon">
            <v-icon
              v-if="isShowResolve(commentElement?.userWorkstation?.id)"
              icon="mdi-check-circle-outline"
              @click="resolveUserComments(commentElement?.id)"
            ></v-icon>
            <v-icon icon="mdi-close" @click="closeCommentBox()"></v-icon>
          </div>
        </div>

        <div class="reply__commentbox__section" ref="commentContainer">
          <div
            class="reply__commentbox__card__text__top"
            v-for="comment in comments"
            :key="comment.id"
          >
            <div class="user__info">
              <div class="user__img">
                <img
                  v-if="comment?.userWorkstation?.profileImage"
                  :src="getWorkStationImagePublicUrl(comment?.userWorkstation)"
                  alt=""
                />
                <UserProfileLogo
                  v-else
                  :userName="comment?.userWorkstation?.name"
                />
              </div>
              <div class="user__text">
                <div class="user__text__title">
                  <h6>{{ comment?.userWorkstation?.name }}</h6>
                  <p>{{ timeCommentAgo(comment.dateCreated) }}</p>
                </div>
                <div class="user__comment">
                  <p>{{ comment.comment }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="reply__commentbox__edit__comment">
        <v-text-field
          class="messagechat__message"
          variant="solo-filled"
          hide-details
          single-line
          flat
          v-model="commentText"
          @keydown.shift.enter.stop=""
          @keydown.enter="addNewComment"
          placeholder="Reply"
        >
          <template v-slot:append-inner>
            <v-icon icon="mdi-chevron-right-circle" @click="addNewComment" />
          </template>
        </v-text-field>
      </div>
    </div>
    <!-- hover commentbox -->
    <div
      class="commentbox"
      :ref="`user_comment_${commentElement?.elementId}`"
      :id="`user_comment_${commentElement?.elementId}`"
      :class="{
        commentbox__hover: isHoverComment,
      }"
      :style="{
        top: commentElement?.style?.y + 'px',
        left: commentElement?.style?.x + 'px',
      }"
      @mouseover="isHoverComment = true"
      @mouseout="isHoverComment = false"
    >
      <div
        class="commentbox__card"
        v-if="commentElement?.userComments.length > 0"
      >
        <div class="commentbox__card__text__top" @click="openCommentBox()">
          <div class="user__img">
            <img
              v-if="commentElement?.userWorkstation?.profileImage"
              :src="
                getWorkStationImagePublicUrl(commentElement.userWorkstation)
              "
              alt=""
            />
            <!-- <ProfileThumbnail
              v-if="commentElement?.userWorkstation?.profileImage"
              :src="
                getWorkStationImagePublicUrl(commentElement.userWorkstation)
              "
            /> -->
            <UserProfileLogo
              v-else
              :userName="commentElement?.userWorkstation?.name"
            />
          </div>
          <div
            class="user__text"
            :class="{
              commentbox__user__text: isHoverComment,
            }"
          >
            <div class="title">
              <h6>{{ commentElement?.userWorkstation?.name }}</h6>
              <p>
                {{
                  timeCommentAgo(commentElement?.userComments[0]?.dateCreated)
                }}
              </p>
            </div>
            <div class="user__comment">
              <p>{{ commentElement?.userComments[0]?.comment }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject, onMounted, ref } from "vue";
import commentsService from "../services/comments.service";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { onBeforeUnmount } from "vue";
import { UserCommentRoomStatus } from "../enums/CommentsEnum";
import { watch } from "vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserRolesEnum } from "@/core/enums/RolesEnum";

export default {
  name: "ToolTipComment",
  props: {
    commentElement: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    activeComment: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    commentsLength: {
      type: Number,
      required: true,
    },
    projectWorkStation: {
      type: Object,
    },
    userImagesMap: {
      type: Object,
    },
  },
  components: {
    UserProfileLogo,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const isHoverComment = ref(false);
    const commentText = ref("");
    const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
    const commentContainer = ref(null) as any;
    const openCommentBox = () => {
      const scrollRef = document.getElementById(
        `user_comment_${props.commentElement.elementId}`
      );

      if (scrollRef) {
        // scrollRef.scrollIntoView({ behavior: "smooth" });
        ctx.emit("toggleActiveComment", props.commentElement.elementId);
        isHoverComment.value = false;
      }
    };
    const closeCommentBox = () => {
      ctx.emit("toggleActiveComment", null);
      isHoverComment.value = false;
    };
    const pusher = inject("pusher") as any;
    const comments = ref([]) as any;

    const timeCommentAgo = (timestamp: any) => {
      const previous = new Date(timestamp) as any;
      const current = new Date() as any;

      const elapsed = current - previous;

      const seconds = Math.floor(elapsed / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      if (weeks > 0) {
        return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
      } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
      } else {
        return "Just now";
      }
    };

    const addNewComment = async (e: any) => {
      const newComment = commentText.value.trim();
      if (newComment !== "") {
        try {
          const payload = {
            userId: user.value.id,
            comment: newComment,
            commentRoomId: props.commentElement?.id,
          };
          await commentsService.createUserComments(payload);
        } catch (error) {
          console.log(error);
        }
        commentText.value = "";
      }
      e.stopPropagation();
    };
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const subscribeChannelList = ref([]) as any;

    const subscribeProjectComments = () => {
      const roomId = props.commentElement.id;
      const roomChannel = pusher?.subscribe(
        `project-comments-${roomId}-${props.projectId}`
      );
      roomChannel
        ?.unbind("new.comment")
        ?.bind("new.comment", (commentMessage: any) => {
          if (commentMessage) {
            if (
              [
                props.commentElement.userWorkstation?.id,
                activeUserWorkstation.value.id,
              ].includes(commentMessage.userWorkstation.id) ||
              user.value.role.name === UserRolesEnum.PROPERTY_OWNER
            )
              comments.value.push(commentMessage);
          }
          setTimeout(() => {
            scrollToBottom();
          });
        });

      subscribeChannelList.value.push(roomChannel);
    };
    const unbindSubscribedCommentsChannels = () => {
      if (subscribeChannelList.value.length) {
        subscribeChannelList.value.forEach((channel: any) => {
          // Unbind all events from channel
          channel?.unbind();
          pusher?.unsubscribe(channel.name);
        });
        subscribeChannelList.value.length = [];
      }
    };
    const resolveUserComments = (commentRoomId: number) => {
      ctx.emit(
        "updateCommentRoomStatus",
        commentRoomId,
        UserCommentRoomStatus.RESOLVED
      );
    };

    const nextComment = (idx: number, dir: string) => {
      const scrollRef = document.getElementById(
        `user_comment_${props.commentElement.elementId}`
      );
      if (scrollRef) {
        if (dir == "left") ctx.emit("nextComment", idx - 1, dir);
        else ctx.emit("nextComment", idx + 1, dir);
      }
    };
    const scrollToBottom = () => {
      if (commentContainer.value) {
        commentContainer.value.scrollTop = commentContainer.value?.scrollHeight;
      }
    };

    const getWorkStationImagePublicUrl = (message: any) => {
      if (!message) return null;
      if (message && message?.id && props.userImagesMap[message?.id])
        return props.userImagesMap[message.id];
    };

    onMounted(() => {
      comments.value = props.commentElement.userComments;
      subscribeProjectComments();
    });
    onBeforeUnmount(() => {
      unbindSubscribedCommentsChannels();
    });

    watch(
      () => props.activeComment.elementId,
      () => {
        if (
          props.commentElement?.elementId === props.activeComment?.elementId
        ) {
          setTimeout(() => {
            scrollToBottom();
          });
        }
      }
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const isShowResolve = (workstationId: number) => {
      if (user.value.role.name === UserRolesEnum.PROPERTY_OWNER) return true;
      return activeUserWorkstation.value.id === workstationId;
    };
    return {
      comments,
      openCommentBox,
      isHoverComment,
      closeCommentBox,
      timeCommentAgo,
      commentText,
      addNewComment,
      resolveUserComments,
      nextComment,
      userImage,
      scrollToBottom,
      commentContainer,
      getWorkStationImagePublicUrl,
      activeUserWorkstation,
      isShowResolve,
    };
  },
};
</script>

<style lang="scss" scoped>
.reply__commentbox {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  position: absolute;
  z-index: 1000000;
  background-color: rgba($white, 1);
  border-radius: 8px 8px 8px 0;
  width: 250px;
  overflow: hidden;
  .user__img {
    width: 24px;
    height: 24px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .reply__commentbox__card {
    padding: 12px 16px 0;
    border-radius: 8px 8px 8px 0;
    .reply__commentbox__card__icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba($black, 0.18);
      padding-bottom: 10px;
      .left__icon {
        display: flex;
        gap: 4px;
        align-items: center;
        .v-icon {
          @include fluidFont(16, 16, 1);
          color: rgba($black, 1);
        }
      }
      .right__icon {
        display: flex;
        gap: 4px;
        align-items: center;
        .v-icon {
          @include fluidFont(16, 16, 1);
          color: rgba($black, 1);
        }
      }
    }
    .reply__commentbox__section {
      max-height: 200px;
      //overflow: auto;
      overflow: scroll;
      .reply__commentbox__card__text__top {
        display: flex;
        gap: 8px;
        align-items: center;
        padding-top: 10px;
        .user__info {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          width: 100%;
          max-width: 100%;
          h6 {
            @include fluidFont(12, 12, 1);
            font-weight: 700;
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.94);
          }
          p {
            @include fluidFont(12, 12, 1);
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 400;
          }
        }
        .user__text {
          width: calc(100% - 32px);
          max-width: 100%;
          .user__text__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .user__comment {
          padding-top: 6px;
          p {
            @include fluidFont(12, 12, 1);
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.94);
            font-weight: 400;
          }
        }
      }
    }
  }
  .reply__commentbox__edit__comment {
    .v-input {
      padding: 12px 16px;
      :deep .v-field--appended {
        padding: 0;
        border-radius: 8px;
        .v-field__field {
          .v-field__input {
            min-height: 28px;
            padding: 2px 12px;
            @include fluidFont(12, 12, 1.2);
          }
        }
        .v-field__append-inner {
          padding-right: 8px;
          .v-icon {
            @include fluidFont(16, 16, 1.2);
            color: rgba($blueDark, 1);
            opacity: 1;
          }
        }
      }
    }
  }
}

/* comment box */

.commentbox {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  position: absolute;
  // top: -30px;
  // left: 0;
  z-index: 1;
  background-color: rgba($white, 1);
  border-radius: 100% 100% 100% 10%;
  //width: 250px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  padding: 2px;
  cursor: pointer;
  .commentbox__card {
    border-radius: 8px 8px 8px 0;
    width: 100%;
    height: 100%;
    .commentbox__card__text__top {
      width: 100%;
      height: 100%;
      .user__img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .user__text {
        width: calc(100% - 30px);
        max-width: 100%;
        margin-top: 2px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2px;
          h6 {
            @include fluidFont(12, 12, 1);
            font-weight: 700;
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.94);
          }
          p {
            @include fluidFont(12, 12, 1);
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 400;
          }
        }
      }
    }
    .user__comment {
      margin-top: 10px;
      p {
        @include fluidFont(12, 12, 1);
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.94);
        font-weight: 400;
      }
    }
  }
}

/* hover commentbox */

.commentbox__hover {
  position: absolute;
  width: 250px;
  height: 60px;
  padding: 8px;
  border-radius: 8px 8px 8px 0;
  z-index: 2;
}

.custom__input {
  display: flex;
  gap: 10px;
}
.commentbox__hover:hover {
  .commentbox__card {
    .commentbox__card__text__top {
      display: flex;
      gap: 16px;
      align-items: flex-start;
      .user__img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          // margin: 0;
          // border-radius: 100%;
        }
      }
    }
  }
}
</style>
