import $axios from "../utils/axios-api-config";

export default {
  getStageTaskMembers(taskId: number) {
    return $axios.get(`/phase-task/${taskId}/assign`);
  },

  createStageTaskMembers(taskId: number, data: any) {
    return $axios.put(`/phase-task/${taskId}/assign`, data);
  },
};
