<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00002 0.335938C3.20002 0.335938 0.666687 2.4826 0.666687 5.8026C0.666687 7.9226 2.30002 10.4159 5.56002 13.2893C5.81335 13.5093 6.19335 13.5093 6.44669 13.2893C9.70002 10.4159 11.3334 7.9226 11.3334 5.8026C11.3334 2.4826 8.80002 0.335938 6.00002 0.335938ZM6.00002 7.0026C5.26669 7.0026 4.66669 6.4026 4.66669 5.66927C4.66669 4.93594 5.26669 4.33594 6.00002 4.33594C6.73335 4.33594 7.33335 4.93594 7.33335 5.66927C7.33335 6.4026 6.73335 7.0026 6.00002 7.0026Z"
      fill="#0C0F4A"
    />
  </svg>
</template>
<script>
export default {
  name: "LoadIcon",
};
</script>
