<template>
  <commonDialog
    v-if="isShowDialog"
    :onClickBackButton="onClickCloseTab"
    width="478px"
    max-width="100%"
    height="auto"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['delete_invite_modal']"
  >
    <template v-slot:body>
      <v-card class="delete_invitees">
        <div class="">
          <img :src="require('@/assets/images/email-success.png')" alt="" />
        </div>
        <div class="content">
          <h4>Email Successfully Confirmed!</h4>
          <p>Your alias email has been successfully added to the list.</p>
          <v-btn class="button button-orange" @click="onClickCloseTab">
            CONTINUE
          </v-btn>
        </div>
      </v-card>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import manageEmailService from "@/core/services/manage-email.service";
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { ref } from "vue";

export default {
  components: {
    CommonDialog,
  },

  setup() {
    const router = useRouter();
    const route: any = useRoute();
    const isShowDialog = ref(false);
    const onClickCloseTab = () => {
      isShowDialog.value = false;
      router.push({ name: LOGIN_ROUTE });
    };
    onMounted(async () => {
      if (!route.query.aemt) router.push({ name: LOGIN_ROUTE });
      try {
        await manageEmailService.confirmEmailRequest({
          key: route.query.aemt,
        });
        isShowDialog.value = true;
      } catch (error) {
        router.push({ name: LOGIN_ROUTE });
      }
    });

    return {
      onClickCloseTab,
      isShowDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>
