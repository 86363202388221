<template>
  <svg
    width="66"
    height="67"
    viewBox="0 0 66 67"
    fill="none"
    :class="className"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.485 29.2821C46.485 28.7543 46.0546 28.332 45.5349 28.332H30.2687C29.7409 28.332 29.3105 28.7624 29.3105 29.2821C29.3105 29.8018 29.7409 30.2322 30.2687 30.2322H45.5349C46.0627 30.2322 46.485 29.8018 46.485 29.2821Z"
      fill="#FAA500"
    />
    <path
      d="M20.7266 30.2325C23.3576 30.2325 25.4932 28.0887 25.4932 25.4658C25.4932 22.843 23.3576 20.6992 20.7266 20.6992C18.0956 20.6992 15.96 22.843 15.96 25.4658C15.96 28.0887 18.0956 30.2325 20.7266 30.2325ZM20.7266 22.5994C22.3019 22.5994 23.5849 23.8824 23.5849 25.4577C23.5849 27.0331 22.3019 28.3161 20.7266 28.3161C19.1512 28.3161 17.8682 27.0331 17.8682 25.4577C17.8682 23.8824 19.1512 22.5994 20.7266 22.5994Z"
      fill="#FAA500"
    />
    <path
      d="M46.4845 35.0087C46.4845 34.4808 46.0542 34.0586 45.5345 34.0586H34.0848C33.557 34.0586 33.1348 34.489 33.1348 35.0087C33.1348 35.5284 33.5651 35.9587 34.0848 35.9587H45.5345C46.0623 35.9587 46.4845 35.5284 46.4845 35.0087Z"
      fill="#FAA500"
    />
    <path
      d="M27.2476 33.1565C25.721 32.2552 23.358 31.1914 20.727 31.1914C18.096 31.1914 15.7249 32.2633 14.1983 33.1565C12.9234 33.9036 12.1357 35.3652 12.1357 36.9568V37.5658C12.1357 38.7839 13.0533 39.7746 14.1821 39.7746H27.272C28.4007 39.7746 29.3183 38.7839 29.3183 37.5658V36.9568C29.3183 35.3652 28.5306 33.9036 27.2557 33.1565H27.2476ZM27.4019 37.5658C27.4019 37.7607 27.2882 37.8663 27.2639 37.8663H14.1821C14.1821 37.8663 14.044 37.7689 14.044 37.5658V36.9568C14.044 36.0473 14.4825 35.2028 15.1646 34.7968C16.4801 34.0173 18.5183 33.0916 20.7189 33.0916C22.9195 33.0916 24.9577 34.0173 26.2732 34.7968C26.9553 35.1947 27.3938 36.0473 27.3938 36.9568V37.5658H27.4019Z"
      fill="#FAA500"
    />
    <path
      d="M56.9841 39.4414C56.635 39.1085 56.2614 38.7999 55.8635 38.5238C54.8972 37.858 53.7929 37.3708 52.6154 37.1028V16.8995C52.6154 16.664 52.5829 16.4366 52.5504 16.2174C52.4124 15.4135 52.0389 14.6664 51.4461 14.0736C50.6909 13.3184 49.6921 12.9043 48.6202 12.9043H10.0082C8.93631 12.9043 7.92939 13.3184 7.18233 14.0736C6.42714 14.8288 6.00488 15.8357 6.00488 16.8995V44.5329C6.00488 45.5966 6.42714 46.6035 7.18233 47.3587C7.77511 47.9515 8.52218 48.3251 9.32609 48.4631C9.38293 48.4712 9.43977 48.4712 9.49661 48.4793C9.66714 48.5037 9.83766 48.5281 10.0082 48.5281H41.247C41.5231 49.7136 42.0184 50.8099 42.6924 51.7762C43.6262 53.1241 44.9092 54.2041 46.4034 54.9187C47.6458 55.5196 49.0262 55.8688 50.496 55.8688C55.7417 55.8688 60.0049 51.6057 60.0049 46.3599C60.0049 43.6315 58.8356 41.1792 56.9923 39.4414H56.9841ZM40.9871 46.3599C40.9871 46.3599 40.9871 46.3924 40.9871 46.4087H10.0082C9.74834 46.4087 9.48849 46.3518 9.253 46.2463C9.04188 46.1569 8.84699 46.027 8.67646 45.8565C8.31917 45.4992 8.12428 45.0282 8.12428 44.5329V16.8995C8.12428 16.396 8.31917 15.925 8.67646 15.5759C9.02563 15.2267 9.49661 15.0237 10.0001 15.0237H48.6202C49.1237 15.0237 49.5947 15.2186 49.9438 15.5759C50.1144 15.7464 50.2362 15.9413 50.3336 16.1524C50.4392 16.3879 50.496 16.6396 50.496 16.8995V36.8511C45.2503 36.8511 40.9871 41.1142 40.9871 46.3599ZM50.496 53.8387C48.5065 53.8387 46.6957 53.0511 45.3559 51.7762C44.5113 50.9723 43.8617 49.9735 43.4638 48.861C43.4232 48.7473 43.3745 48.6417 43.342 48.5281C43.139 47.8541 43.0253 47.1395 43.0172 46.4005C43.0172 46.3843 43.0172 46.3681 43.0172 46.3518C43.0172 42.2835 46.2897 38.9705 50.3336 38.8811C50.3904 38.8811 50.4392 38.873 50.496 38.873C51.235 38.873 51.9495 38.9867 52.6235 39.1816C53.8741 39.5551 54.9865 40.2453 55.8717 41.1629C57.1709 42.5109 57.9748 44.338 57.9748 46.3518C57.9748 50.4769 54.6211 53.8306 50.496 53.8306V53.8387Z"
      fill="#FAA500"
    />
    <path
      d="M51.2752 46.7093V43.0795C51.2752 42.5192 50.8205 42.0645 50.2602 42.0645C49.6999 42.0645 49.2451 42.5192 49.2451 43.0795V47.1315C49.2451 47.3995 49.3507 47.6593 49.5456 47.8461L51.738 50.0386C51.9329 50.2335 52.1928 50.339 52.4526 50.339C52.7125 50.339 52.9723 50.2416 53.1672 50.0386C53.5651 49.6407 53.5651 48.9992 53.1672 48.6013L51.2671 46.7011L51.2752 46.7093Z"
      fill="#FAA500"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
