import userDocumentService from "@/core/services/user-document.service";
import { PROPERTY_STORE } from "../property";

export default {
  setOpenShareModal({ commit }: any, value: boolean) {
    commit("setOpenShareModal", value);
  },

  setOpenRenameModal({ commit }: any, value: boolean) {
    commit("setOpenRenameModal", value);
  },

  setOpenMoveModal({ commit }: any, value: boolean) {
    commit("setOpenMoveModal", value);
  },

  setOpenDetailsModal({ commit }: any, value: boolean) {
    commit("setOpenDetailsModal", value);
  },

  setOpenDeleteModal({ commit }: any, value: boolean) {
    commit("setOpenDeleteModal", value);
  },

  setOpenNewFolderModal({ commit }: any, value: boolean) {
    commit("setOpenNewFolderModal", value);
  },

  setOpenNewFileUploadModal({ commit }: any, value: boolean) {
    commit("setOpenNewFileUploadModal", value);
  },

  setFolderList({ commit }: any, value: boolean) {
    commit("setFolderList", value);
  },

  setOpenedFolder({ commit }: any, value: boolean) {
    commit("setOpenedFolder", value);
  },

  setSelectedDocument({ commit }: any, value: boolean) {
    commit("setSelectedDocument", value);
  },

  setFileList({ commit }: any, value: boolean) {
    commit("setFileList", value);
  },

  setIsPropertyDocument({ commit }: any, value: boolean) {
    commit("setIsPropertyDocument", value);
  },

  async getUserDocuments({ getters }: any, params: any) {
    const { userId, options } = params;

    if (getters.isPropertyDocument) {
      // eslint-disable-next-line no-param-reassign
      params.options = {
        ...options,
        entityId: "1",
      };
    }

    return await userDocumentService.getUserDocuments(userId, params.options);
  },
  async getContractorDocuments({ getters }: any, payload: any) {
    const { userId, workStationId, params } = payload;
    const { options } = params;

    if (getters.isPropertyDocument) {
      // eslint-disable-next-line no-param-reassign
      params.options = {
        ...options,
        entityId: "1",
      };
    }
    return await userDocumentService.getContractorDocuments(
      userId,
      workStationId,
      params.options
    );
  },
  getTradePassportGallery(context: any, userId: number) {
    return userDocumentService.getTradePassportGallery(userId);
  },
  getContractorTradePassportGallery(context: any, payload: any) {
    const { userId, workStationId } = payload;
    return userDocumentService.getContractorTradePassportGallery(
      userId,
      workStationId
    );
  },

  getUserDocumentCalculatedSize(context: any, params: any) {
    const { userId, userDocumentId, workStationId = "" } = params;

    return userDocumentService.getUserDocumentCalculatedSize(
      userId,
      userDocumentId,
      workStationId
    );
  },

  getUserDocumentTotalSize(context: any, params: any) {
    const { userId } = params;

    return userDocumentService.getUserDocumentTotalSize(userId);
  },

  createUserDocument(context: any, params: any) {
    const { userId, formData } = params;

    return userDocumentService.createUserDocument(userId, formData);
  },

  updateUserDocument(context: any, params: any) {
    const { userId, id, formData } = params;

    return userDocumentService.updateUserDocument(userId, id, formData);
  },

  moveUserDocuments(context: any, params: any) {
    const { userId, formData } = params;

    return userDocumentService.moveUserDocuments(userId, formData);
  },

  deleteUserDocuments(context: any, params: any) {
    const { userId, formData } = params;

    return userDocumentService.deleteUserDocuments(userId, formData);
  },

  setOpenDocumentBillingModal({ commit }: any, value: boolean) {
    commit("setOpenDocumentBillingModal", value);
  },

  setDocumentList({ commit }: any, value: boolean) {
    commit("setDocumentList", value);
  },
  getWorkStationList(context: any, params: any) {
    const { userId, name } = params;

    return userDocumentService.getWorkStationList(userId, name);
  },
  shareDocument(context: any, payload: any) {
    return userDocumentService.shareDocument(payload);
  },
};
