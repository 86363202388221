import actions from "./actions";
import getters from "./getters";

export const PROJECT_STAGE_TASK_MEMBER_STORE = "projectStageTaskMember";

export default {
  namespaced: true,

  state: {},

  actions,

  getters,

  mutations: {},
};
