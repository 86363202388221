<template>
  <CollapsibleWidgetSlot title="Title Deeds">
    <template #content>
      <section
        class="tw-flex tw-flex-col tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <div
          class="tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-between"
        >
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[412px]"
            placeholder="Search"
            v-model="searchValue"
          />
        </div>
        <div
          class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-p-4 tw-box-border tw-rounded-lg tw-flex"
        >
          <TitleDeedsTable
            @on-show-coordinates="onOpenModal"
            class="tw-hidden"
          />

          <div class="title-deeds">
            <div class="title-deeds__header">
              <!-- <div class="title-deeds__header-col"></div> -->
              <div class="title-deeds__header-col">Title Type</div>
              <div class="title-deeds__header-col">Title Number</div>
              <!-- <div class="title-deeds__header-col">Duration</div> -->
              <!-- <div class="title-deeds__header-col"></div> -->
              <div class="title-deeds__header-col">Action</div>
            </div>

            <v-radio-group>
              <div
                class="title-deeds__list"
                v-if="getPropertyClassTitleList?.length"
              >
                <div
                  class="title-deeds__item"
                  v-for="title of getPropertyClassTitleList"
                  :key="title.id"
                >
                  <div class="title-deeds__item-inner">
                    <!-- <div class="title-deeds__item-col single-radio"> -->
                    <!-- <v-radio
                        :value="title.className"
                        color="#4B4BFF"
                        ripple="false"
                      ></v-radio> -->
                    <!-- </div> -->
                    <div class="title-deeds__item-col">
                      <span class="title-deeds__item-mobile-title"
                        >Title Type</span
                      >
                      <span>{{ title.className }}</span>
                    </div>
                    <div class="title-deeds__item-col">
                      <span class="title-deeds__item-mobile-title"
                        >Title Number</span
                      >

                      <span>{{ title.titleNo }}</span>
                    </div>
                    <!-- <div class="title-deeds__item-col">
                      <span class="title-deeds__item-mobile-title"
                        >Duration</span
                      >
                      <span>MS 2391332</span>
                    </div> -->
                    <!-- <div class="title-deeds__item-col default-checkbox"> -->
                    <!-- <v-radio
                        color="#4B4BFF"
                        ripple="false"
                        value="freehold-default"
                      >
                        <template v-slot:label>
                          Set as Default

                          <div>
                            <v-tooltip
                              max-width="245px"
                              activator="parent"
                              location="bottom"
                            >
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua.
                            </v-tooltip>

                            <img src="@/assets/icons/tooltip.svg" alt="" />
                          </div>
                        </template>
                      </v-radio> -->
                    <!-- </div> -->
                    <div class="title-deeds__item-col">
                      <span class="title-deeds__item-mobile-title">Action</span>

                      <button
                        type="button"
                        class="title-deeds__item-trigger"
                        @click="toggleContent(title)"
                      >
                        <span>{{
                          currentSelectItem?.id === title.id
                            ? "Hide coordinates"
                            : "Show coordinates"
                        }}</span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="title-deeds__item-content"
                    v-if="currentSelectItem?.id === title.id"
                  >
                    <CommonLoader
                      v-if="loading"
                      class="loader-container"
                      :loading="loading"
                    />
                    <PropertyTitleDeedMap
                      v-else
                      :polygonList="deedMapData.polygon"
                      :location="deedMapData.location"
                    />
                  </div>
                </div>
              </div>
            </v-radio-group>
          </div>
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>

  <!-- MODALS -->
  <ShowPolygonCoordinatesModal
    v-if="polygonCoordinatesModal"
    @on-close="onCloseModal"
  />
</template>
<script setup>
import { ref } from "vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import TitleDeedsTable from "@/modules/properties/components/Tables/TitleDeedsTable.vue";
import InputField from "@/core/components/common/InputField.vue";
import PropertyTitleDeedMap from "@/modules/properties/components/Collapsibles/sub-modules/PropertyTitleDeedMap.vue";
import ShowPolygonCoordinatesModal from "@/modules/properties/components/Modals/ShowPolygonCoordinatesModal.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { computed } from "vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { useStore } from "vuex";
import propertyService from "@/core/services/property.service";

const searchValue = ref();
const currentSelectItem = ref();
const loading = ref(false);
const deedMapData = ref({ polygon: [], location: {} });
const toggleContent = (value) => {
  if (value.id === currentSelectItem.value?.id) {
    currentSelectItem.value = null;
  } else {
    currentSelectItem.value = value;
    setPolygonInMap();
  }
};

const setPolygonInMap = async () => {
  try {
    loading.value = true;
    const response = await propertyService.fetchPropertyPolygonFromTitleNo(
      currentSelectItem.value.titleNo
    );
    deedMapData.value = {
      polygon: response.data.results,
      location: response.data.results[0].location,
    };
  } catch (error) {
    deedMapData.value = { polygon: [], location: {} };
    console.log("fetch polygon error", error);
  } finally {
    loading.value = false;
  }
};
const polygonCoordinatesModal = ref(false);

const onOpenModal = () => {
  polygonCoordinatesModal.value = true;
};
const onCloseModal = () => {
  polygonCoordinatesModal.value = false;
};
const store = useStore();

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);
const getPropertyClassTitleList = computed(() => {
  return !searchValue.value?.length
    ? propertyDetails.value?.propertyTitleClasses || []
    : propertyDetails.value?.propertyTitleClasses?.filter(
        (title) =>
          title?.className
            .toLowerCase()
            .includes(searchValue.value.toLowerCase().trim()) ||
          title?.titleNo
            .toLowerCase()
            .includes(searchValue.value.toLowerCase().trim())
      ) || [];
});
</script>
<style lang="scss" scoped>
.title-deeds {
  background-color: #fff;
  border-radius: inherit;
  width: 100%;

  @media (max-width: 992px) {
    background-color: transparent;
  }

  &__item-inner,
  &__header {
    display: grid;
    grid-template-columns: 24% 1fr 12%;

    @media (max-width: 1578px) {
      grid-template-columns: 24% 1fr 18%;
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }

    &-col {
      min-width: 45px;
      padding: 12px 16px;
      @include fluidFont(10, 10, 16px);
      color: rgba(12, 15, 74, 0.5);
      font-weight: 500;
      text-align: left;
    }
  }

  .empty-block,
  .single-radio,
  &__header {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__list {
    @media (max-width: 992px) {
      display: grid;
      grid-gap: 16px;
    }
  }

  &__item {
    border-left: 1px solid rgba(12, 15, 74, 0.1);
    border-right: 1px solid rgba(12, 15, 74, 0.1);
    border-bottom: 1px solid rgba(12, 15, 74, 0.1);

    @media (max-width: 992px) {
      border: 0;
      padding: 16px;
      background-color: #fff;
      border-radius: 8px;
    }

    &:first-child {
      border-top: 1px solid rgba(12, 15, 74, 0.1);

      @media (max-width: 992px) {
        border: 0;
      }
    }

    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;

      @media (max-width: 992px) {
        border-radius: 0;
      }
    }

    &-col {
      min-width: 45px;
      padding: 12px 16px;
      @include fluidFont(10, 10, 16px);
      font-weight: 500;
      text-align: left;
      @include fluidFont(12, 12, 16px);
      color: #0c0f4a;

      @media (max-width: 992px) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 10px;
        align-items: center;
        padding: 0;
      }
    }

    .v-selection-control--density-default {
      --v-selection-control-size: 16px;
      justify-content: flex-end;

      @media (max-width: 992px) {
        justify-content: flex-start;
        grid-column: span 2;
        --v-selection-control-size: 12px;
      }
    }

    :deep(.v-label) {
      @include fluidFont(10, 10, 16px);
      padding-left: 6px;
      color: $blueDark;
      display: flex;
      align-items: center;
      gap: 8px;
      opacity: 1;
    }

    &-trigger {
      @include fluidFont(12, 12, 18px);
      font-weight: 500;
      color: #4b4bff;
      text-decoration: underline;
    }

    &-content {
      width: 100%;
      height: 315px;
      border-top: 1px solid rgba(12, 15, 74, 0.1);

      @media (max-width: 992px) {
        height: 184px;
        margin-top: 10px;
        border-radius: 8px;
        overflow: hidden;
        border: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .default-checkbox {
      @media (max-width: 992px) {
        order: -1;
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }

    &-mobile-title {
      @include fluidFont(10, 10, 16px);
      color: rgba(12, 15, 74, 0.5);
      font-weight: 500;
      text-align: left;

      @media (min-width: 992px) {
        display: none;
      }
    }
  }
}

.loader-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
