export enum PropertyIssueStatus {
  DRAFT = "draft",
  RAISED = "raised",
  CLOSED = "closed",
  IN_PROGRESS = "in-progress",
}

export enum PropertyIssueType {
  STANDARD = "standard",
  EMERGENCY = "emergency",
  EMERGENCY_OUT_OF_HOURS = "emergency_out_of_hours",
}
