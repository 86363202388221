<template>
  <GeneralDialog
    headerTitle="File Upload"
    @on-close="onCloseModal"
    borderRadius="30px"
    :paddingLarge="true"
    width="400px"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 md:!tw-min-w-[unset] md:!tw-max-w-[unset]"
      >
        <v-form :rules="validation" ref="folderNameFormRef" class="tw-w-full">
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <UploadForm
              class="!tw-w-full"
              listType="picture"
              :record="form"
              :allowMultiple="true"
              :attachmentSources="[]"
              :useCustomUploadIcon="true"
              @on-progress-upload="onProgressUpload"
              @on-upload-success="updateFileOnSuccess"
              :isGallery="props.isGallery"
              @on-handle-error="onHandleError"
              :forDocuments="$props.forDocuments"
            />
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          label="Cancel"
          variant="tertiary"
          :disabled="form.isUploading"
          @click="onCloseModal"
        />
        <Button
          :disabled="form.isUploading || !form.attachments.length"
          :isLoading="form.isLoading"
          label="Save"
          :isActive="hasAttachments"
          @click="onSave"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, onMounted, ref, reactive, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import UploadForm from "@/core/components/forms/Upload.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import documentConstants from "@/modules/documents/contants";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import axios from "axios";
import {
  getImageApiUrl,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import { PROPERTY_STORE } from "@/store/modules/property";

const internalInstance = getCurrentInstance();

const emits = defineEmits(["on-close", "on-success", "on-success-gallery"]);
const props = defineProps({
  forDocuments: {
    type: Boolean,
    default: false,
  },
  isGallery: Boolean,
  isPropertyDocument: Boolean,
});

const store = useStore();
const route = useRoute();

const form = reactive({
  attachments: [],
  disableButton: false,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const openedFolder = computed(
  () => store.getters[`${DOCUMENTS_STORE}/openedFolder`]
);
const files = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);
const folderList = computed(
  () => store.getters[`${DOCUMENTS_STORE}/folderList`]
);

const hasAttachments = computed(() => {
  return form.attachments?.length > 0;
});

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};
const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};
const setDocumentList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setDocumentList`, params);
};

const onCloseModal = () => {
  form.attachments = [];
  if (!form.isUploading) {
    emits("on-close");
  }
};
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const getFolderList = async (options = {}) => {
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;
  let fileList = null;

  if (Object.keys(options).length) {
    params.options = options;
  }
  if (props.isPropertyDocument) {
    params.options.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );
    fileList = userDocuments.filter(
      (userDocument) =>
        !userDocument.isDir && userDocument.parentId === hasParentParam
    );
    fileList = fileList.map((file) => {
      return { ...file, name: file.originalName, attachment: file.name };
    });
  } catch (error) {
    const formError = "Error getting folder list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    const attachmentPromises = fileList.map(async (currentFile) => {
      const { attachment } = currentFile;
      const imageUrl = setDirectStoragePathToImage(attachment);
      return { ...currentFile, attachmentSource: imageUrl };
    });
    fileList = await Promise.all(attachmentPromises);
    setFileList(fileList);
    setDocumentList([...folderList.value, ...fileList]);
  }
};

const onProgressUpload = () => {
  form.isUploading = true;
  form.isLoading = true;
};

const updateFileOnSuccess = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onHandleError = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const onSave = async () => {
  const { params: routeParams, name } = route;
  const { attachments } = form;
  const { id } = openedFolder.value;
  const parentId = id || null;
  const { id: userId } = user.value;
  const propertyEntity =
    name.includes("property-details") && routeParams.id
      ? { propertyId: routeParams.id }
      : "";
  const params = {
    userId,
    formData: {
      name: attachments[0]?.originalName,
      parentId,
      attachments,
      entity: propertyEntity ? JSON.stringify(propertyEntity) : "",
      docType: props.isGallery ? "gallery" : "document",
    },
  };
  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  let docParams = {};
  if ("id" in openedFolder.value) {
    docParams = { parentId: id };
  }

  form.isLoading = true;

  try {
    // if (form.attachments.some((file) => file.url)) {
    //   await Promise.allSettled(
    //     form.attachments
    //       .filter((file) => file.url)
    //       .map((file) =>
    //         axios.put(file.url, file.raw, {
    //           headers: {
    //             "Content-Type": file.mime,
    //           },
    //           maxContentLength: Infinity,
    //           maxBodyLength: Infinity,
    //         })
    //       )
    //   );
    // }

    const fileList = await store.dispatch(
      `${DOCUMENTS_STORE}/createUserDocument`,
      params
    );
    if (props.isGallery) {
      // let currentFileList = fileList.map((file) => {
      //   return { ...file, name: file.originalName, attachment: file.name };
      // });

      // const attachmentPromises = currentFileList.map(async (currentFile) => {
      //   const { attachment } = currentFile;
      //   const imageUrl = getImageApiUrl(attachment, true);
      //   const encoded = await $axios.get(imageUrl);
      //   const publicURL = encoded?.publicUrl;
      //   return { ...currentFile, attachmentSource: publicURL };
      // });

      // // Wait for all attachment promises to resolve
      // currentFileList = await Promise.all(attachmentPromises);
      // // Flatten the combined array to avoid nesting
      // const updatedFileList = [...files.value, ...currentFileList].flat();

      // await setFileList(updatedFileList);
      // console.log(files.value, "files after update");

      // if (currentFileList) {
      //   emits("on-success", documentConstants.FILE);
      // }
      emits("on-success-gallery", docParams);
      console.log("is gallry", docParams);
    } else {
      console.log(fileList);
      await setFileList(fileList);
      if (fileList) {
        emits("on-success", documentConstants.FILE);
      }
    }
    await getFolderList(docParams);
  } catch (error) {
    const formError = "Error uploading file(s). Please try again.";
    // Note: Add notification logic
    if (error?.response?.status === 400)
      displayToast("Don't have enough storage to store document.", "error");
  } finally {
    form.isLoading = false;
    onCloseModal();
  }
};

onMounted(() => {
  form.attachments = [];
});
</script>
