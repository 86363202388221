<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#0C0F4A" />
    <path
      d="M27.7999 14.2105C27.3899 13.8005 26.6799 14.0805 26.6799 14.6505V18.1405C26.6799 19.6005 27.9199 20.8105 29.4299 20.8105C30.3799 20.8205 31.6999 20.8205 32.8299 20.8205C33.3999 20.8205 33.6999 20.1505 33.2999 19.7505C31.8599 18.3005 29.2799 15.6905 27.7999 14.2105Z"
      fill="white"
    />
    <path
      d="M32.5 22.19H29.61C27.24 22.19 25.31 20.26 25.31 17.89V15C25.31 14.45 24.86 14 24.31 14H20.07C16.99 14 14.5 16 14.5 19.57V28.43C14.5 32 16.99 34 20.07 34H27.93C31.01 34 33.5 32 33.5 28.43V23.19C33.5 22.64 33.05 22.19 32.5 22.19ZM23.5 29.75H19.5C19.09 29.75 18.75 29.41 18.75 29C18.75 28.59 19.09 28.25 19.5 28.25H23.5C23.91 28.25 24.25 28.59 24.25 29C24.25 29.41 23.91 29.75 23.5 29.75ZM25.5 25.75H19.5C19.09 25.75 18.75 25.41 18.75 25C18.75 24.59 19.09 24.25 19.5 24.25H25.5C25.91 24.25 26.25 24.59 26.25 25C26.25 25.41 25.91 25.75 25.5 25.75Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "FileCircleIcon",
};
</script>
