import $axios from "../utils/axios-api-config";
import { InviteMember, UpdateMemberDetails } from "../models/manage-member";

export default {
  inviteMember(members: InviteMember, userId: number) {
    return $axios.post(`/user-workstations/${userId}/members`, members);
  },
  getTeamMember(userId: number) {
    return $axios.get(`/user-workstations/${userId}/members`);
  },
  updateMemberDetails(
    members: UpdateMemberDetails,
    userWorkstationMemberId: number,
    userId: number
  ) {
    return $axios.put(
      `/user-workstations/${userId}/members/${userWorkstationMemberId}`,
      members
    );
  },
  deleteMemberDetails(userMemberId: number, userId: number) {
    return $axios.delete(
      `/user-workstations/${userId}/members/${userMemberId}`
    );
  },
  getAllTeamMemberList(userId: number) {
    return $axios.get(`/user-workstations/${userId}/members/all`);
  },
};
