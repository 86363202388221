<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill="white" />
    <path
      d="M9.67358 4.33011L5.17358 8.83011C5.13439 8.86944 5.08782 8.90064 5.03654 8.92194C4.98526 8.94323 4.93028 8.95419 4.87476 8.95419C4.81923 8.95419 4.76425 8.94323 4.71297 8.92194C4.66169 8.90064 4.61512 8.86944 4.57593 8.83011L2.60718 6.86136C2.56793 6.82211 2.53681 6.77553 2.51557 6.72425C2.49433 6.67298 2.4834 6.61803 2.4834 6.56253C2.4834 6.50703 2.49433 6.45208 2.51557 6.4008C2.53681 6.34953 2.56793 6.30294 2.60718 6.2637C2.64642 6.22446 2.69301 6.19333 2.74428 6.17209C2.79555 6.15085 2.85051 6.13992 2.90601 6.13992C2.9615 6.13992 3.01646 6.15085 3.06773 6.17209C3.119 6.19333 3.16559 6.22446 3.20483 6.2637L4.87511 7.93397L9.07663 3.73315C9.15588 3.6539 9.26338 3.60938 9.37546 3.60938C9.48754 3.60938 9.59503 3.6539 9.67429 3.73315C9.75354 3.81241 9.79807 3.9199 9.79807 4.03198C9.79807 4.14406 9.75354 4.25156 9.67429 4.33081L9.67358 4.33011Z"
      fill="#1FB27A"
    />
  </svg>
</template>
<script>
export default {
  name: "CheckIconWhite",
};
</script>
