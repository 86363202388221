<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="912px"
    max-width="100%"
    height="auto"
    :className="[
      'trade_passport_preview_modal',
      'md:!tw-h-auto md:!tw-overflow-[unset]',
    ]"
  >
    <template v-slot:body>
      <div class="header md:!tw-right-[-13px]">
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <v-carousel
        height="auto"
        class="md:!tw-h-auto"
        style="overflow: initial"
        hide-delimiter-background
        :show-arrows="false"
        :continuous="false"
        v-model="model"
      >
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-sheet>
            <div class="md:!tw-flex md:!tw-flex-col md:!tw-gap-[.5rem]">
              <h4 class="md:!tw-mb-0 md:!tw-text-[24px]">Why iknowa?</h4>
              <div
                class="carousel__img !tw-mb-0 tw-relative !tw-w-full tw-overflow-hidden rmd:!tw-h-[400px] rmd:!tw-m-0 rmd:!tw-pt-8 rmd:! rmd:!tw-mb-4"
              >
                <img
                  width="90"
                  class="tw-relative tw-z-10 !tw-object-contain"
                  :src="item.src"
                />
                <img
                  class="tw-w-full !tw-h-auto tw-absolute tw-z-0 tw-top-[-16px] tw-right-0 tw-object-contain rmd:!tw-top-[-27px]"
                  :src="require('@/assets/images/benefit-blured-bg-1.png')"
                />
              </div>
              <div
                class="trade_passport_preview_modal_title md:!tw-mb-0 md:!tw-text-[18px]"
              >
                {{ item?.title }}
              </div>
              <div
                class="trade_passport_preview_modal_text md:!tw-mb-0 md:!tw-text-[12px] md:!tw-leading-[18px] md:!tw-min-h-[unset]"
              >
                {{ item?.description }}
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="carousel-controls">
        <v-btn
          variant="elevated"
          class="button button-orange"
          @click="prevSlide"
          :disabled="model === 0"
        >
          Previous
        </v-btn>
        <v-btn
          variant="elevated"
          class="button button-orange"
          @click="handleNextClick"
        >
          {{ model === items.length - 1 ? "Go to Dashboard" : "Next" }}
        </v-btn>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );
    const model = ref(0);

    const items = ref([
      {
        src: require("@/assets/images/trade-preview-3.png"),
        title: "Win jobs & Manage your business",
        description:
          "Select from the range of jobs that fit your criteria & schedule, manage everything under one roof and get paid securely directly to your trade e-wallet.",
      },
      {
        src: require("@/assets/images/trade-preview-4.png"),
        title: "Get paid for site visits",
        description:
          "Update property records that apply to your area of expertise and get paid immediately when the record has been added during your site visits.",
      },
      {
        src: require("@/assets/images/trade-preview-2.png"),
        title: "Up-skill and earn more",
        description:
          "Fast-track your ability to earn more via subsidised government backed short - bootcamp courses, in the; low-carbon, renewable and retrofit sector today.",
      },
      {
        src: require("@/assets/images/trade-preview-1.png"),
        title: "Stay Part-L Compliant",
        description:
          "With iknowa you can stay Part L compliant using our integrated tools which enable you to break down your estimate into stages, capture proof of work as you progress with your job and keep records of every step of your job.",
      },
    ]);

    const handleNextClick = () => {
      if (model.value === items.value.length - 1) {
        ctx.emit("onClickClosePreviewModalButton");
      } else {
        model.value++;
      }
    };
    const prevSlide = () => {
      if (model.value > 0) {
        model.value--;
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickClosePreviewModalButton");
    };

    return {
      userName,
      onClickCloseButton,
      items,
      model,
      handleNextClick,
      prevSlide,
    };
  },
};
</script>
