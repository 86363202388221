const ImageFileTypes = [
  "png",
  "jpeg",
  "jpg",
  "tiff",
  "bmp",
  "jbig",
  "gif",
  "rle",
  "dib",
  "jpe",
  "exif",
  "tiff",
  "tif",
  "icon",
  "ico",
  "mwf",
  "emf",
  "svg",
];

const DocumentFileTypes = [
  "pdf",
  "doc",
  "docx",
  "xlsx",
  "xlsm",
  "xlsb",
  "pptx",
  "text",
  "txt",
  "mov",
  "mp4",
  "mpg",
  "mpeg",
  "heic",
];

const GalleryImageFileTypes = ["png", "jpeg", "jpg"];
const GalleryDocumentFileTypes = ["mp4"];

// Define new file type constants
const ArchiveFileTypes = ["zip", "rar", "tar", "gzip", "gz"];

const AudioFileTypes = ["mp3", "mpeg", "wav", "ogg", "opus"];

const TextFileTypes = ["txt"];

const VideoFileTypes = [
  "webm",
  "mpeg4",
  "3gpp",
  "3gp",
  "mov",
  "avi",
  "mpegps",
  "wmv",
  "flv",
  "ogg",
];

const HEICFileTypes = ["heic"];

const HEVCFileTypes = ["hevc"];

const mbToGbSize = 0.0009765625; // 1GB
const bytesToGBSize = 1073741824; // GB

// Update getFileType function to handle new file types
const getFileType = (filename: string) => {
  let fileType = "";

  if (filename && typeof filename === "string") {
    fileType = filename.split(".").pop()?.toLowerCase() || "";
  }

  return fileType;
};

// Update isPdfFile function to handle MIME type and filename extension
const isPdfFile = (type: string, filename: string) => {
  let bool = type === "application/pdf";

  if (filename && filename.toLowerCase().endsWith(".pdf")) {
    bool = true;
  }

  return bool;
};

// Extend isImageAttachment function to include new image file types
const isImageAttachment = (filename: string) => {
  const extension = getFileType(filename);

  return (
    ImageFileTypes.includes(extension) || HEICFileTypes.includes(extension)
  );
};

const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const bytesToMb = (bytes: any) => {
  return bytes ? parseFloat(formatBytes(bytesToGBSize - bytes)) : 0;
};

const formatBytesToGB = (bytes: any, capacity: any) => {
  const getInMb = bytesToMb(bytes);
  const finalTotalInGb = Number(getInMb * capacity * mbToGbSize).toPrecision(
    10
  );
  return finalTotalInGb.slice(0, 4);
};

const getMBPercentage = (bytes: any, capacity: any) => {
  return (
    100 - Math.floor((100 * (bytesToMb(bytes) * capacity)) / (1024 * capacity))
  );
};

// Update export list to include new functions and constants
export {
  getFileType,
  ImageFileTypes,
  DocumentFileTypes,
  isImageAttachment,
  isPdfFile,
  ArchiveFileTypes,
  AudioFileTypes,
  TextFileTypes,
  VideoFileTypes,
  HEICFileTypes,
  HEVCFileTypes,
  formatBytes,
  formatBytesToGB,
  getMBPercentage,
  bytesToMb,
  GalleryDocumentFileTypes,
  GalleryImageFileTypes,
};
