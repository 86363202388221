<template>
  <Card
    class="tw-p-4 tw-min-h-[150px] tw-max-h-[150px] tw-cursor-pointer"
    @click="onHandleClickRow($event, props.folder)"
    :class="{
      'tw-border-solid tw-border-[1px] tw-border-[#FFA500]':
        props.folder.id === props.selectedId,
    }"
  >
    <template #content>
      <section
        class="tw-h-full tw-w-full tw-flex-col-between tw-items-start tw-relative"
      >
        <DocumentFolderIcon />
        <div class="tw-flex tw-flex-col tw-items-start">
          <Text class="!tw-text-[18px]">{{ props.folder?.name }}</Text>
          <!-- <Text
            variant="span"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            >4 Folders</Text
          > -->
        </div>

        <v-menu location="bottom">
          <template v-slot:activator="{ props }">
            <v-icon
              v-if="getUserWorkStationFieldAccess"
              v-bind="props"
              icon="mdi-dots-horizontal"
              color="rgba(12, 15, 74, 0.5)"
              class="!tw-absolute !tw-top-0 !tw-right-0"
            ></v-icon>
          </template>
          <DocumentActionMenu
            @on-handle-action="onHandleAction($event, props.folder)"
            :isGallery="true"
          />
        </v-menu>
      </section>
    </template>
  </Card>

  <!-- MODALS -->
  <RenameDocumentModal
    v-if="modals.renameDocument"
    :selectedDocument="state.selectedDocument"
    renameType="folders"
    @on-close="onCloseRenameDocumentModal"
    @on-success="onSuccess('edit')"
  />
  <DocumentDeleteConfirmationModal
    v-if="modals.deleteConfirmation"
    @on-close="onCloseDeleteConfirmationModal"
    :selectedDocument="state.selectedDocument"
    deleteType="folders"
    @on-success="onSuccess('delete')"
  />
</template>
<script setup>
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import DocumentFolderIcon from "@/core/components/icons/DocumentFolderIcon.vue";
import DocumentActionMenu from "@/modules/documents/components/DocumentActionMenu.vue";
import RenameDocumentModal from "@/modules/documents/components/modal/RenameDocumentModal.vue";
import DocumentDeleteConfirmationModal from "@/modules/documents/components/modal/DocumentDeleteConfirmationModal.vue";
import { getImageApiUrl } from "@/core/utils/common";
import documentConstants from "@/modules/documents/contants";

const emits = defineEmits(["on-success", "on-view-folder"]);
const props = defineProps({
  folder: Object,
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
  selectedId: Number,
});

const modals = reactive({
  shareModal: false,
  renameDocument: false,
  documentDetails: false,
  deleteConfirmation: false,
  moveModal: false,
});
const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedDocument: null,
});

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      //   state.selectedRow = state.selectedRow === item.id ? null : item.id;
      state.selectedRow = item.id;
      state.result.push(event.type);
      state.clicks = 0;
      emits("set-selected-id", state.selectedRow);
    }, state.delay);
  } else {
    clearTimeout(state.timer);
    state.selectedDocument = item;
    state.selectedRow = item.id;
    state.clicks = 0;
    emits("on-view-folder", item);
  }
};

const onOpenRenameDocumentModal = (document) => {
  state.selectedDocument = document;
  modals.renameDocument = true;
};

const onCloseRenameDocumentModal = () => {
  state.selectedDocument = null;
  modals.renameDocument = false;
};

const onOpenDeleteConfirmationModal = (document) => {
  state.selectedDocument = document;
  modals.deleteConfirmation = true;
};

const onCloseDeleteConfirmationModal = () => {
  state.selectedDocument = null;
  modals.deleteConfirmation = false;
};

const closeAllModal = () => {
  onCloseRenameDocumentModal();
  onCloseDeleteConfirmationModal();
};

const onHandleAction = (name, document) => {
  closeAllModal();
  if (name === documentConstants.RENAME) {
    onOpenRenameDocumentModal(document);
  }
  if (name === documentConstants.DELETE) {
    onOpenDeleteConfirmationModal(document);
  }
};
const onSuccess = (type) => {
  emits("on-success", type);
};

onMounted(() => {
  state.selectedRow = props.selectedId;
});
</script>
<style lang="scss" scoped></style>
