import $axios from "@/core/utils/axios-api-config";

export default {
  getMyJobTemplates(params = "") {
    return $axios.get(`/job-templates${params}`);
  },

  getJobTemplate(id: number) {
    return $axios.get(`/job-templates/${id}`);
  },

  createMyJobTemplate(formData: any) {
    return $axios.post("/job-templates", formData);
  },

  updateMyJobTemplate(id: number, formData?: any) {
    return $axios.put(`/job-templates/${id}`, formData);
  },

  deleteMyJobTemplate(id: number): Promise<number> {
    return $axios.delete(`/job-templates/${id}`);
  },
};
