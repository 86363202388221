import actions from "./actions";
import getters from "./getters";

export const PROJECT_STAGES_TASKS_STORE = "projectStagesTasks";

export default {
  namespaced: true,

  state: {},

  actions,

  getters,

  mutations: {},
};
