<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_539_59459)">
      <path
        d="M1.37588 7.5H0.000375003V7C-0.00719416 6.29894 0.178472 5.60933 0.537006 5.00684C0.895539 4.40434 1.41309 3.91224 2.03288 3.5845C2.64344 3.28345 3.3047 3.09864 3.98288 3.0395C3.68654 3.40617 3.38938 3.79583 3.09138 4.2085C2.42018 5.25095 1.84595 6.35273 1.37588 7.5ZM7.79188 8.9065C6.74952 9.57856 5.64774 10.1536 4.50038 10.6245V12H5.00038C5.70144 12.0076 6.39104 11.8219 6.99354 11.4634C7.59603 11.1048 8.08814 10.5873 8.41588 9.9675C8.71692 9.35693 8.90174 8.69568 8.96088 8.0175C8.59588 8.3125 8.20621 8.60883 7.79188 8.9065ZM12.0004 1.4955C11.9339 3.672 10.3669 5.8305 7.20938 8.0955C6.35013 8.65251 5.44365 9.13303 4.50038 9.5315V9.25C4.49814 8.78656 4.31305 8.34274 3.98534 8.01503C3.65764 7.68733 3.21382 7.50224 2.75038 7.5H2.46888C2.86782 6.55673 3.34901 5.6504 3.90688 4.7915C6.16638 1.639 8.32138 0.072 10.4944 0C11.5774 0 12.0004 0.4425 12.0004 1.4955ZM9.00038 4.25C9.00038 3.91848 8.86868 3.60054 8.63426 3.36612C8.39984 3.1317 8.0819 3 7.75038 3C7.41886 3 7.10091 3.1317 6.86649 3.36612C6.63207 3.60054 6.50038 3.91848 6.50038 4.25C6.50038 4.58152 6.63207 4.89946 6.86649 5.13388C7.10091 5.3683 7.41886 5.5 7.75038 5.5C8.0819 5.5 8.39984 5.3683 8.63426 5.13388C8.86868 4.89946 9.00038 4.58152 9.00038 4.25ZM0.687375 11.8925C1.25038 11.7925 2.60788 11.5135 3.06138 11.0605C3.20068 10.9212 3.31117 10.7558 3.38656 10.5738C3.46195 10.3918 3.50075 10.1968 3.50075 9.99975C3.50075 9.80275 3.46195 9.60768 3.38656 9.42568C3.31117 9.24367 3.20068 9.0783 3.06138 8.939C2.92208 8.7997 2.7567 8.6892 2.5747 8.61381C2.3927 8.53843 2.19762 8.49962 2.00063 8.49962C1.60277 8.49962 1.2212 8.65767 0.939875 8.939C0.486875 9.3925 0.207375 10.75 0.107875 11.313L-0.015625 12.016L0.687375 11.8925Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_539_59459">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "LunchIcon",
};
</script>
