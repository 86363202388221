<template>
  <div v-if="!isLoading" class="billing_history__wrapper">
    <div v-if="billingHistoryList?.length" class="billing_history__header">
      <div class="title">
        <h3 class="billing_history__title">Billing History</h3>
        <!-- <v-btn class="button"
        ><img src="@/assets/icons/download-icon.svg" />Download PDf</v-btn
      > -->
      </div>
      <div class="download__btn">
        <v-btn class="button button-orange" width="100%">
          <v-icon>
            <img src="../../../../assets/icons/download-icon.svg" alt=""
          /></v-icon>
          Download PDF
        </v-btn>
      </div>
    </div>
    <v-table
      v-if="billingHistoryList?.length"
      class="tw-pt-8 tw-pb-4 tw-box-border tw-bg-transparent no-scrollbar"
    >
      <thead>
        <tr>
          <th class="text-left">
            <Text variant="p" class="tw-uppercase">Date</Text>
          </th>
          <th class="text-left">
            <Text variant="p" whiteSpace="nowrap" class="tw-uppercase"
              >Description</Text
            >
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap" class="tw-uppercase"
              >Amount</Text
            >
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap" class="tw-uppercase"
              >Status</Text
            >
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap" class="tw-uppercase"
              >invoice</Text
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in billingHistoryList"
          :key="index"
          class="tw-cursor-pointer"
        >
          <td class="text-left">
            <div class="tw-flex tw-items-center tw-gap-2">
              <Text class="tw-whitespace-nowrap" variant="p">
                {{
                  item?.dateCreated ? formatDate(item?.dateCreated) : "--"
                }}</Text
              >
            </div>
          </td>
          <td class="text-left">
            <Text class="tw-whitespace-nowrap" variant="p">
              {{ item?.description }}</Text
            >
          </td>
          <td class="text-center">
            <Text class="tw-whitespace-nowrap" variant="p"
              >£{{ item?.amount ? item?.amount : "0" }}</Text
            >
          </td>
          <td class="text-center">
            <Text
              class="tw-whitespace-nowrap tw-inline-block tw-text-white tw-bg-[#1fb27a] tw-py-[2px] tw-px-[8px] tw-rounded-md"
              variant="p"
              >{{ item?.status || "--" }}</Text
            >
          </td>
          <td class="text-center">
            <v-icon
              @click="onClickDownloadInvoice(item?.id)"
              color="#80829F"
              class="card__delete"
              ><img src="@/assets/icons/download-icon.svg"
            /></v-icon>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="empty-screen" v-if="!billingHistoryList?.length">
      <div class="empty-screen__inner">
        <div class="empty-screen__image">
          <img src="@/assets/images/billingHistoryempty.png" alt="" />
        </div>
        <div class="empty-screen__header">
          <h2>Your billing history is currently empty.</h2>
          <p>
            Start making transactions to see your billing records here and keep
            track of your payments!
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- LOADER -->
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
  >
    <Loader :show="isLoading" />
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import Loader from "@/core/components/common/Loader.vue";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";

export default {
  components: {
    Loader,
  },
  setup() {
    const store = useStore();
    const internalInstance = getCurrentInstance();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isLoading = ref(false);
    const billingHistoryList = ref();
    const modifiedMembers = ref([]) as any;
    const openedModel = ref("");
    const modelName = {
      ADDTEAMMEMBER: "Add Member",
      EDITTEAMMEMBER: "Edit Member",
    };
    const memberData = ref();
    const memberName = ref("");
    const userMemberId = ref();
    const isShowRemoveTeamMemberModal = ref(false);
    const downloadLoading = ref(false);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear().toString().slice(-2);

      return `${dayOfWeek}, ${day} ${month} ${year}`;
    };

    const getBillingHistory = async () => {
      try {
        const response = await store.dispatch(
          `${WORKSTATION_SETTING}/getBillingHistoryDetails`,
          {
            userId: user.value.id,
          }
        );

        billingHistoryList.value = response;
      } catch (err) {
        console.log();
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickDownloadInvoice = async (id: number) => {
      try {
        downloadLoading.value = true;
        const response = await store.dispatch(
          `${WORKSTATION_SETTING}/getInvoiceBillingDetails`,
          {
            userId: user.value.id,
            id: id,
          }
        );
        if (response?.receiptUrl) {
          const link = document.createElement("a");
          link.href = response?.receiptUrl;
          link.click();
          displayToast("Download Details Successfully.", "success");
        }
      } catch (error) {
        console.error("Error fetching invoice details:", error);
      } finally {
        downloadLoading.value = false;
      }
    };

    const onClickBackButton = () => {
      isShowRemoveTeamMemberModal.value = false;
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      getBillingHistory();
    };

    onBeforeMount(async () => {
      await getBillingHistory();
    });
    return {
      user,
      activeUserWorkstation,
      formatDate,
      isLoading,
      modifiedMembers,
      billingHistoryList,
      onClickCloseModel,
      openedModel,
      modelName,
      memberData,
      userMemberId,
      memberName,
      isShowRemoveTeamMemberModal,
      onClickBackButton,
      downloadLoading,
      onClickDownloadInvoice,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/workstation-setting/styles/BillingHistorysetting.scss";
</style>
