<template>
  <ChipState
    :label="value"
    textColor="rgba(12, 15, 74, 0.5)"
    bgColor="transparent"
    :uppercase="true"
  >
    <template #prefix>
      <LocationIcon />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import LocationIcon from "@/core/components/icons/LocationIcon";

const props = defineProps({
  value: String,
});
</script>
<script>
export default {
  name: "Postcode",
};
</script>
