<template>
  <div class="title__banner">
    <div class="left__block">
      <h3>{{ title }}</h3>
      <p class="body-text !tw-mb-4">
        {{ description }}
      </p>
    </div>
    <v-btn
      @click="onClickAction"
      v-if="getUserWorkStationFieldAccess"
      class="button button-orange"
      >{{ actionTitle }}</v-btn
    >
  </div>
</template>

<script setup>
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  actionTitle: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["onClickAction"]);

const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);

const onClickAction = () => {
  emit("onClickAction");
};
</script>

<style lang="scss" scoped>
.title__banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 20px;
  background-color: rgba($white, 1);
  border-radius: 8px;
  @include respond(s720) {
    flex-direction: column;
  }
  .left__block {
    h3 {
      @include fluidFont(28, 28, 1.3);
      font-weight: 400;
      color: rgba($blueDark, 1);
    }
    p {
      @include fluidFont(14, 14, 1.3);
      font-weight: 500;
      color: rgba($blueDark, 0.5);
      margin-top: 8px;
      letter-spacing: 0.15px;
    }
  }
  .button {
    @include respond(s720) {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
