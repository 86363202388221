import $axios from "../utils/axios-api-config";

export default {
  getAuditLogs(userId: number, params = {}) {
    return $axios.get(`/activity-logs/${userId}`, { params });
  },
  getAuditLogs_all(
    search: string,
    startDate: string,
    endDate: string,
    projectId: string
  ) {
    console.log("here me");
    return $axios.get("/project-audit-logs", {
      params: {
        search: search || "",
        startDate: startDate || "",
        endDate: endDate || "",
        projectId: projectId || "",
      },
    });
  },
};
