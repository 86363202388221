import { Commit } from "vuex";
import EWalletService from "@/core/services/e-wallet.service";

export default {
  getWalletBalance({ commit }: { commit: Commit }, userId: number) {
    if (!userId) return;
    return EWalletService.getWalletBalance(userId);
  },
  getTransactionList({ commit }: { commit: Commit }, userId: number) {
    if (!userId) return;
    return EWalletService.getTransactionList(userId);
  },
  getInvoiceTransactionDetails({ commit }: any, payload: any) {
    const { userId, startTransactionDate, endTransactionDate } = payload;
    const response = EWalletService.getInvoiceTransactionDetails(
      userId,
      startTransactionDate,
      endTransactionDate
    );
    return response;
  },
  async moneyOutInformation({ commit }: { commit: Commit }, payload: any) {
    return EWalletService.moneyOutInformation(payload).then((response) => {
      return response;
    });
  },
};
