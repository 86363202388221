<template>
  <Card class="!tw-max-h-[unset] !tw-min-w-[179px] !tw-p-4 tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
        <section
          v-for="(item, index) in menuList"
          :key="index"
          @click="onHandleAction(item)"
          class="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <component :is="item.icon"></component>
          <Text
            class="hover:!tw-text-[#FFA500] !tw-capitalize"
            variant="p"
            textWeight="400"
            >{{ item.name }}</Text
          >
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { reactive } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import documentConstants from "@/modules/documents/contants";
import DocumentIcon from "@/core/components/icons/DocumentIcon.vue";

const emits = defineEmits(["on-handle-action"]);
const props = defineProps(["menuList"]);

const menu = reactive([]);

const onHandleAction = (item) => {
  const { id, name } = item;
  emits("on-handle-action", id !== undefined ? id : name);
};
</script>
<style lang="scss" scoped></style>
