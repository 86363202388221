<template>
  <div class="main_block">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main_block {
  width: 100%;
  background: linear-gradient(111.5deg, #f5faff 0%, #d3e9ff 100%);
  text-align: center;
  height: 100dvh;
  @include respond(md) {
    border-bottom-right-radius: 30px;
  }

  /* height: 100dvh; */
}
.chat-logo {
  position: fixed;
  right: 60px;
  bottom: 60px;
}
/* border-bottom-right-radius: 30px; */
@media (max-width: 1024px) {
  .main_block {
    border-bottom-right-radius: 30px;
  }
}

@media (max-width: 720px) {
  .chat-logo {
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
  }
}
</style>
