<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-[70vh] tw-w-full"
  >
    <Loader :show="isLoading" />
  </div>

  <div v-if="!isSubscriedWorkstation && !isLoading">
    <div class="empty-screen">
      <div class="empty-screen__inner">
        <div class="empty-screen__image">
          <img src="@/assets/images/manage_subscribe.png" alt="" />
        </div>
        <div class="empty-screen__header">
          <h3>You currently don’t have any Subscription</h3>
          <p>Subscribe now to unlock premium features!</p>
        </div>

        <div class="empty-screen__footer">
          <v-btn class="button button-purple" block @click="onClickUpgradePlan">
            Upgrade Plan
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="
      isSubscriedWorkstation &&
      activeUserWorkstation?.subStatus !==
        UserWorkstationSubStatusEnum.SUCCEEDED &&
      !isLoading
    "
  >
    <Card
      class="tw-min-h-[160px] tw-p-5 tw-box-border md:!tw-min-h-[unset] md:!tw-max-h-[unset] md:!tw-h-auto"
    >
      <template #content>
        <div class="property_widget_premium_main">
          <div class="property_widget_premium_icon">
            <img :src="require(`@/assets/icons/premium-plan.svg`)" />
          </div>
          <h3 class="property_widget_premium_title">Upgrade to Premium Plan</h3>
          <p class="property_widget_premium_des">
            to manage additional properties, increase document storage capacity,
            and boost file size limits.
          </p>
          <v-btn
            class="button button-purple w-auto"
            @click="onClickUpgradePlan"
          >
            Upgrade Plan
          </v-btn>
        </div>
      </template>
    </Card>
  </div>

  <section
    class="tw-w-full tw-h-full tw-flex tw-flex-col tw-mt-4 tw-gap-4 tw-relative"
  >
    <template v-if="!isLoading">
      <SubscriptionCardDetails
        v-for="(subscription, index) in userSubscriptionDetails"
        :key="index"
        :data="subscription"
        @on-manage="onManage"
        @on-download-invoice="onDownloadInvoice"
        :isLoading="downloadingInvoice"
        :selectedSubscription="selectedSubscription"
      />
    </template>

    <ManageSubscriptionModal
      v-if="isShowManageSubscription"
      :currentPlan="selectedSubscription?.activePlan"
      @toggleActiveManageSubscription="toggleActiveManageSubscription"
    />

    <ManageMemoryModal
      v-if="manageMemoryModal"
      @on-close="onCloseManageMemoryModal"
    />
  </section>
  <PropertyOwnerSubscriptionModal
    v-if="isShowUpgradePremiumModal"
    @closeSubscriptionModal="closeUpgradePremiumModal"
    @closeSelectWorkstationTypeModal="closeUpgradePremiumModal"
  />
</template>

<script setup>
import { ref, computed, onBeforeMount, defineEmits } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import SubscriptionCardDetails from "@/modules/workstation-setting/components/cards/SubscriptionCardDetails.vue";
import ManageSubscriptionSettingTab from "@/modules/workstation-setting/components/screens/ManageMemberEmptyScreen.vue";
import ManageSubscriptionModal from "@/modules/workstation-setting/components/ManageSubscriptionModal.vue";
import ManageMemoryModal from "@/modules/documents/components/modal/ManageMemoryModal.vue";
import Loader from "@/core/components/common/Loader.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import { WORKSTATION } from "@/store/modules/workstation";
import PropertyOwnerSubscriptionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerSubscriptionModal.vue";
import { InvoiceItem, UserWorkstationSubStatusEnum } from "@/core/enums";
import Card from "@/core/components/ui/general/Card.vue";

const store = useStore();
const router = useRouter();

const emits = defineEmits(["on-manage-member"]);

const isLoading = ref(false);
const isSubscriedWorkstation = ref(true);
const userSubscriptionDetails = ref([]);
const isShowManageSubscription = ref(false);

const downloadingInvoice = ref(false);

const manageMemoryModal = ref(false);

const selectedSubscription = ref(null);

const isShowUpgradePremiumModal = ref(false);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onClickUpgradePlan = () => {
  isShowUpgradePremiumModal.value = true;
};

const closeUpgradePremiumModal = () => {
  isShowUpgradePremiumModal.value = false;
};

const onOpenManageMemoryModal = () => {
  manageMemoryModal.value = true;
};

const onCloseManageMemoryModal = async (event) => {
  if (event) {
    manageMemoryModal.value = false;
    getWorkstationsSettingDetails();
  } else {
    manageMemoryModal.value = false;
  }
};

const onClickSubscriptionUpgrade = () => {
  isShowManageSubscription.value = true;
};

const toggleActiveManageSubscription = (updatedPlan) => {
  isShowManageSubscription.value = false;
  if (updatedPlan) {
    getWorkstationsSettingDetails();
  }
  selectedSubscription.value = null;
};

const onManage = (event) => {
  selectedSubscription.value = event;
  if (event?.subscriptionType === "workstation") {
    onClickSubscriptionUpgrade();
  }
  if (event?.subscriptionType === "storage") {
    onOpenManageMemoryModal();
  }
  if (event?.subscriptionType === "members") {
    emits("on-manage-member");
  }
};

const onDownloadInvoice = async (event) => {
  try {
    selectedSubscription.value = event;
    downloadingInvoice.value = true;
    const payload = {
      userId: user.value.id,
      workstationId: activeUserWorkstation.value?.id,
      invoiceItem: event.subscriptionType,
    };
    const response = await store.dispatch(
      `${WORKSTATION_SETTING}/downloadSubscriptionInvoice`,
      payload
    );
    if (response?.invoiceUrl) {
      const link = document.createElement("a");
      link.href = response?.invoiceUrl;
      link.click();
    }
  } catch (error) {
    console.log(error);
  } finally {
    selectedSubscription.value = null;
    downloadingInvoice.value = false;
  }
};

const modifySubscriptionDetails = () => {
  userSubscriptionDetails.value = userSubscriptionDetails.value.map(
    (detail, index) => {
      let title = "";
      if (detail?.subscriptionType === InvoiceItem.WORKSTATION) {
        title = "Workstation Subscription";
      }
      if (detail?.subscriptionType === InvoiceItem.STORAGE) {
        title = "Storage Subscription";
      }
      if (detail?.subscriptionType === InvoiceItem.MEMBERS) {
        title = "Members Subscription";
      }
      return {
        ...detail,
        title,
      };
    }
  );
};

const getWorkstationsSettingDetails = async () => {
  try {
    isLoading.value = true;
    const response = await store.dispatch(
      `${WORKSTATION_SETTING}/getWorkstationsSettingDetails`,
      {
        userId: user.value.id,
        workstationId: activeUserWorkstation.value?.id,
      }
    );
    userSubscriptionDetails.value = response;
    if (userSubscriptionDetails.value.length) {
      modifySubscriptionDetails();
    } else {
      isSubscriedWorkstation.value = false;
    }
    console.log(userSubscriptionDetails.value, "userSubscriptionDetails");
  } catch (err) {
    console.log();
  } finally {
    isLoading.value = false;
  }
};

onBeforeMount(async () => {
  if (activeUserWorkstation.value) {
    getWorkstationsSettingDetails();
  } else {
    await router.push({ name: "dashboard" });
  }
});
</script>
<style lang="scss" scoped>
.empty-screen {
  padding: 50px 0;
  color: $blueDark;

  &__inner {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  &__image {
    max-width: 372px;
    width: 100%;
    margin: 0 auto 46px;

    @media (max-width: 767px) {
      max-width: 278px;
      margin: 0 0 24px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 36px;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.15px;
    font-weight: 500;
    color: rgba($blueDark, 0.5);

    @media (max-width: 767px) {
      text-align: left;
    }

    h3 {
      @include fluidFont(32, 28, 30px);
      color: $blueDark;
      font-weight: 400;
      margin-bottom: 16px;
    }
  }

  &__content {
    padding: 0 28px;
    text-align: left;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.4px;
    display: grid;
    grid-gap: 8px;

    @media (max-width: 767px) {
      padding: 0;
    }

    &-item {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 8px;
      align-items: center;

      .mdi {
        @include fluidFont(24, 24, 1);
        color: rgba($skyBlue, 1);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__footer {
    margin-top: 36px;
  }
}

.property_widget_premium_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 30px 0 10px;
  max-width: 380px;
  margin: 0 auto;
  .property_widget_premium_icon {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .property_widget_premium_title {
    @include fluidFont(14, 14, 1.3);
    font-weight: 700;
    color: rgba($blueDark, 1);
    text-transform: capitalize;
  }
  .property_widget_premium_des {
    @include fluidFont(14, 14, 1.3);
    font-weight: 600;
    color: rgba($blueDark, 0.5);
    letter-spacing: 0.4px;
    text-align: center;
  }
  .button {
    :deep(.v-btn__content) {
      font-weight: 700;
      letter-spacing: 1.25px;
    }
  }
}
</style>
