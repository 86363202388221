import PropertyDetailsPage from "@/modules/properties/pages/PropertyDetailsPage.vue";

export const PROPERTIES_ROUTE = "properties";
export const ADD_PROPERTY_ROUTE = "addProperty";
export const PROPERTY_DETAILS_ROUTE = "propertyDetails";

export default [
  {
    path: "/properties",
    name: PROPERTIES_ROUTE,
    component: () => import(`@/modules/properties/pages/PropertiesPage.vue`),
    meta: {
      requiresAuth: true,
      title: "Properties",
    },
  },
  {
    path: "/add-property/:type",
    name: ADD_PROPERTY_ROUTE,
    component: () => import(`@/modules/properties/pages/AddPropertyPage.vue`),
    meta: {
      requiresAuth: true,
      title: "Properties",
    },
  },
  {
    path: "/property-details/:propertyId",
    name: PROPERTY_DETAILS_ROUTE,
    component: PropertyDetailsPage,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Property Details",
    },
  },
  // {
  //   path: "/property-details/:postCode",
  //   name: PROPERTY_DETAILS_ROUTE,
  //   component: PropertyDetailsPage,
  //   meta: {
  //     validateAuth: true,
  //     requiresAuth: true,
  //     title: "Property Details",
  //   },
  // },
];
