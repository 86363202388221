<template>
  <div
    class="tw-text-[#0C0F4A] tw-text-left tw-max-w-full rsm:tw-max-w-[500px] tw-w-full tw-mx-auto"
  >
    <div class="tw-mb-6">
      <div class="tw-text-[24px] tw-font-semibold tw-mb-4">
        <p>Select Your Plan</p>
      </div>
      <p>
        Please read through and agree to the terms & conditions to access your
        Iknowa platform.
      </p>
    </div>

    <div class="tw-mb-6">
      <div class="tw-grid tw-gap-2">
        <div class="tw-grid tw-grid-cols-[24px_1fr] tw-gap-2 tw-items-center">
          <v-icon color="#4887FF" class="tw-text-[24px]">
            mdi-check-circle
          </v-icon>
          <p>Access Subsidised <strong>Upskilling</strong> Courses</p>
        </div>
        <div class="tw-grid tw-grid-cols-[24px_1fr] tw-gap-2 tw-items-center">
          <v-icon color="#4887FF" class="tw-text-[24px]">
            mdi-check-circle
          </v-icon>
          <p>Find & Estimate on <strong>New Jobs</strong></p>
        </div>
        <div class="tw-grid tw-grid-cols-[24px_1fr] tw-gap-2 tw-items-center">
          <v-icon color="#4887FF" class="tw-text-[24px]">
            mdi-check-circle
          </v-icon>
          <p>
            Collaborate With <strong>Your Team</strong> or
            <strong>Other Trades</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- yearly -->
    <div
      @click="selectSubscriptionType(PaymentPlanType.YEARLY)"
      :class="{
        '!tw-border-[#FAA500]':
          selectedSubscriptionType === PaymentPlanType.YEARLY,
      }"
      class="tw-rounded-[16px] tw-bg-white tw-border-solid tw-border-[rgba(12,15,74,0.2)] tw-border-[2px] tw-p-[10px] tw-cursor-pointer tw-mb-5"
    >
      <div
        class="tw-flex tw-items-center tw-flex-row-between tw-flex-wrap tw-mb-4"
      >
        <div
          class="tw-rounded-[6px] tw-border-solid tw-border-[#FAA500] tw-border-[1px] tw-font-bold tw-leading-none tw-flex tw-items-center tw-justify-center tw-py-[2px] tw-px-[8px] tw-min-h-[24px]"
        >
          <p>YEARLY</p>
        </div>
        <div
          class="tw-rounded-[8px] tw-bg-[#FAA500] tw-flex tw-text-white tw-font-semibold tw-items-center tw-leading-none tw-justify-center tw-py-[2px] tw-px-[6px] tw-min-h-[24px]"
        >
          <p>Most Cost Effective</p>
        </div>
      </div>

      <div>
        <p class="tw-text-[16px]">
          <strong class="tw-text-[28px]">£7.99</strong> per month
        </p>
        <p>
          <strong>/ £95.88</strong>
          <span class="tw-text-[#0C0F4A80] tw-mx-2">(+ VAT)</span>
          <strong>billed today</strong>
        </p>
      </div>
    </div>

    <!-- 3 months -->
    <div
      @click="selectSubscriptionType(PaymentPlanType.MONTHLY)"
      :class="{
        '!tw-border-[#FAA500]':
          selectedSubscriptionType === PaymentPlanType.MONTHLY,
      }"
      class="tw-rounded-[16px] tw-bg-white tw-border-solid tw-border-[rgba(12,15,74,0.2)] tw-border-[2px] tw-p-[10px] tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-items-center tw-flex-row-between tw-flex-wrap tw-mb-4"
      >
        <div
          class="tw-rounded-[6px] tw-border-solid tw-border-[#FAA500] tw-border-[1px] tw-font-bold tw-leading-none tw-flex tw-items-center tw-justify-center tw-py-[2px] tw-px-[8px] tw-min-h-[24px]"
        >
          <p>3-MONTHS</p>
        </div>
      </div>

      <div>
        <p class="tw-text-[16px]">
          <strong class="tw-text-[28px]">£9.99</strong> per month
        </p>
        <p>
          <strong>/ £29.97</strong>
          <span class="tw-text-[#0C0F4A80] tw-mx-2">(+ VAT)</span>
          <strong>billed today</strong>
        </p>
      </div>
    </div>

    <div class="tw-mt-6">
      <Button
        :disabled="!selectedSubscriptionType"
        :isLoading="loading"
        label="take me to payment"
        @click="onClickPaymentPlan"
      ></Button>
      <div class="tw-mt-4 tw-text-center tw-text-[#0C0F4A80]">
        <p>
          Cancel Anytime After 3 Months |
          <a
            href="#"
            class="tw-text-[#FFA500] tw-no-underline hover:tw-underline"
          >
            Terms & Conditions</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import Button from "@/core/components/ui/general/Button.vue";
import $axios from "@/core/utils/axios-api-config";

import {
  BusinessDetailTypeEnum,
  PaymentPlanType,
} from "@/core/enums/RolesEnum";
import { cloneObject } from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";

const emits = defineEmits(["onNext"]);
const props = defineProps(["userDetails"]);
const store = useStore();
const selectedSubscriptionType = ref();
const loading = ref(false);
const selectSubscriptionType = (type) => {
  if (loading.value) return;
  selectedSubscriptionType.value = type;
};
const createdWorkstation = ref();
const stripe = ref();
const sessionId = ref();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const workstationType = ref();
const onClickPaymentPlan = async () => {
  try {
    if (loading.value) return;
    loading.value = true;
    const companyName =
      props?.userDetails?.companyName ||
      props?.userDetails?.userName + "workstation";
    workstationType.value =
      props?.userDetails?.workstationType ===
      BusinessDetailTypeEnum.LIMITED_COMPANY
        ? BusinessDetailTypeEnum.LIMITED_COMPANY
        : BusinessDetailTypeEnum.SOLETRADER;

    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: { hasBusinessType: true },
    });

    createdWorkstation.value = await store.dispatch(
      `${WORKSTATION}/createUserWorkstation`,
      {
        userId: user.value?.id,
        userWorkstation: {
          name: companyName,
          companyName,
          businessType: workstationType.value,
          workstationAccountType: workstationType.value,
        },
      }
    );

    await store.dispatch(`${WORKSTATION}/getUserWorkstations`, user.value?.id);

    await store.dispatch(`${USER_STORE}/initializeUserState`);

    await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    if (selectedSubscriptionType.value === PaymentPlanType.YEARLY) {
      await initializeStripePayment(false, false, 95.9);
    } else if (selectedSubscriptionType.value === PaymentPlanType.MONTHLY) {
      await initializeStripePayment(false, false, 9.99);
    }
    store.dispatch(`${WORKSTATION}/setWsModal`, {
      createdWorkstationId: createdWorkstation.value.id,
      activeLimitedCompanyWorkStationModal: 1,
      activeSoleTraderWorkStationModal: 1,
      isShowWorkStationModal: false,
      isShowLimitedCompanyWorkStation:
        workstationType.value === BusinessDetailTypeEnum.LIMITED_COMPANY,
      isShowSoleTraderWorkStation:
        workstationType.value === BusinessDetailTypeEnum.SOLETRADER,
    });
  } catch (error) {
    console.log("error while adding workstation", error);
  } finally {
    loading.value = false;
    localStorage.setItem("isShowWelcomePopup", JSON.stringify(false));
    localStorage.removeItem("newAuthenticationFlowDetails");
  }
};

const initializeStripe = () => {
  let stripe = null;
  if ("Stripe" in window) {
    const { Stripe } = window;
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripe;
};

const initializeStripePayment = async (isTrial, oneTimePayment, price) => {
  stripe.value = initializeStripe();

  if (stripe.value) {
    await onCheckout(isTrial, oneTimePayment, price);
  }
};

const onCheckout = async (isTrial, oneTimePayment, price) => {
  await createSession(isTrial, oneTimePayment, price);
  if (sessionId.value) {
    stripe.value
      .redirectToCheckout({
        sessionId: sessionId.value,
      })
      .then((result) => {
        if (result.error) {
          console.log();
        }
      });
  }
};

const createSession = async (
  isTrial = false,
  oneTimePayment = false,
  price
) => {
  const productName = "tradesperson-profile";

  const host = `${window.location.protocol}//${window.location.host}`;
  const redirectUrl = `${host}/dashboard`;

  const totalPrice = price;

  await $axios
    .post(`/payment/${user.value.id}/session`, {
      price: totalPrice,
      redirectUrl: redirectUrl,
      productName,
      metadata: JSON.stringify({
        userWorkstationId: createdWorkstation.value?.id,
        name: createdWorkstation.value?.name,
        companyName: createdWorkstation.value?.name,
        productName,
        userId: user.value.id,
        businessType: workstationType.value,
        planType: selectedSubscriptionType.value,
        workstationAccountType: workstationType.value,
        capacity: "100MB",
        totalMember: 1,
      }),
      isTrial,
      oneTimePayment,
    })
    .then(async (sessionResponse) => {
      if (sessionResponse) {
        sessionId.value = sessionResponse?.id;
      }
    })
    .catch((error) => {
      console.log();
    });
};

const nextStep = () => {
  emits("onNext");
};
</script>

<style></style>
