export enum PropertyTypeEnum {
  PROPERTY_OWNER = "property owner",
  PROPERTY_OCCUPIER = "property occupier",

  FREEHOLD = "Freehold",
  LEASEHOLD = "Leasehold",

  OCCUPIER = "Occupier",
  OWNER = "Owner",
  COMPANY = "Company",
  MANAGER = "Manager",

  COMMERCIAL = "Commercial",
  RESIDENTIAL = "Residential",
}

export enum PropertyConnectionRoleEnum {
  OWNER = "owner",
  ADMIN = "admin",
  EDITOR = "editor",
  VIEWER = "viewer",
}
export enum PropertyWorkflowTypeEnum {
  PROPERTY = "property",
  CLIENT = "client",
  RAISE_CLIENT = "raise_client",
  RAISE_AN_ISSUE = "raise-an-issue",
}

export enum PropertyMapLayerType {
  SHAPES = "shapes",
  MEASURE = "measure",
}

export enum PropertyCategoryEnum {
  COMMERCIAL = "Commercial",
  RESIDENTIAL = "Residential",
}

export enum HeatingSystemTypeEnum {
  AIR_SOURCE_HEAT_PUMP = "Air Source Heat Pump",
  GROUND_SOURCE_HEAT_PUMP = "Ground Source Heat Pump",
  GAS_BOILERS = "Gas Boilers",
  COMBI_BOILERS = "Combi Boilers",
  LPG = "LPG",
}

export enum SafetyCheckResultEnum {
  PASSED = "Pass",
  FAILED = "Fail",
}

export enum AddSafetyModalTitleEnum {
  GAS_TITLE = "Gas / Heating Check (LGSR)",
  ELECTRICAL_TITLE = "Electrical (EICR) ",
  WATER_TITLE = "Water Test (L8)",
  CREATE_NEW_SAFETY = "Add New Safety Check",
}
export enum SafetyStandardStatusEnum {
  ATTENTION = "Attention Required",
  EXPIRED_SOON = "Expiring Soon",
  EXPIRED = "Expired",
  VALID = "Valid",
}
