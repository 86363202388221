<template>
  <div
    :class="{ shadow: hasShadow }"
    class="chip-state tw-px-[.7rem] tw-py-[.2rem] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-start"
  >
    <slot name="prefix"></slot>
    <Text
      variant="span"
      textWeight="600"
      :textColor="textColor"
      whiteSpace="nowrap"
      v-if="!hasCustomLabel"
    >
      {{ label }}
    </Text>
    <slot name="custom-label" v-else></slot>
  </div>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  label: String,
  textColor: {
    type: String,
    default: "#0C0F4A",
  },
  bgColor: String,
  hasShadow: Boolean,
  hasCustomLabel: Boolean,
});
</script>
<script>
export default {
  name: "ChipState",
};
</script>
<style lang="scss" scoped>
.chip-state {
  background: v-bind("props.bgColor");
}

.chip-state.shadow {
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
}
</style>
