<template>
  <div>
    <CommonDialog
      :isShowHeader="false"
      :isShowBackButton="false"
      width="420px"
      max-width="100%"
      height="auto"
      :className="['restricted__modal_box']"
    >
      <template v-slot:body>
        <div
          class="dashboard-welcome__close !tw-top-8 !tw-right-8"
          @click="onClickCloseButton($event)"
        >
          <v-icon icon="mdi-close"></v-icon>
        </div>
        <div class="header-title">Your Invitation have been sent</div>

        <div class="header-sub-title">We've sent your invitation to:</div>
        <ul class="header-sub-title tw-mt-1">
          <li v-for="(member, index) in members" :key="index">
            <b>{{ member?.email }}</b>
          </li>
        </ul>
        <div class="welcome__image">
          <img src="../../assets/images/invite-member-sent.png" alt="" />
        </div>
        <div class="modal__footer">
          <v-btn
            class="button button-orange"
            @click="onClickCloseButton($event)"
            >Close</v-btn
          >
        </div>
      </template>
    </CommonDialog>
  </div>
</template>

<script setup lang="ts">
import CommonDialog from "./CommonDialog.vue";

const props = defineProps({
  members: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const onClickCloseButton = (event: any) => {
  emit("onClose", true, event);
};
</script>

<style lang="scss" scoped></style>
