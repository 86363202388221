/**
 * Workstation Setting route page
 */
import WorkstationSettingRoute from "@/modules/workstation-setting/pages/WorkstationSettingView.vue";

export const WORKSTATION_SETTING_ROUTE = "workstation-setting";

export default {
  path: "/workstation-setting",
  name: WORKSTATION_SETTING_ROUTE,
  component: WorkstationSettingRoute,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Profile",
  },
};
