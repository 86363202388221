<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="600px"
    max-width="100%"
    :className="['setting_up_preview_modal']"
  >
    <template v-slot:body>
      <div class="modal_content">
        <v-icon
          icon="mdi-close"
          class="close_icon"
          @click="onClickCloseButton"
        />
        <h4 class="r2xl:!tw-max-w-[61%]">
          Wow, 🤩<br />
          that was quick! 🥳
        </h4>
        <img src="../../../../assets/images/complete-tp.png" alt="" />
        <p>
          Next so we can send you quality jobs & give you access to subsidised
          up-skilling courses, complete your trade passport
        </p>

        <div
          class="tw-grid tw-gap-4 tw-grid-cols-2 tw-w-full s540:!tw-flex s540:!tw-flex-col-reverse"
        >
          <Button
            @click="onClickCloseButton"
            variant="secondary"
            label="Cancel"
          ></Button>
          <Button
            @click="onClickManageTradePassport"
            label="Complete Trade Passport"
          ></Button>
        </div>

        <!-- <v-btn
          variant="elevated"
          class="button button-orange w-full"
          @click="onClickManageTradePassport"
        >
          Complete Trade Passport
        </v-btn>
        <v-btn
          @click="onClickCloseButton"
          variant="plain"
          class="blue-text"
          color="blue"
          >cancel</v-btn
        > -->
        <!-- <div class="header">
          <v-btn
            variant="text"
            icon="mdi-close"
            @click="onClickCloseButton"
          ></v-btn>
        </div>
        <div
          class="modal_content_main md:!tw-p-0 md:!tw-flex md:!tw-flex-col md:!tw-gap-4"
        >
          <div class="title">Complete Your Trade Passport</div>
          <div class="sub-title md:!tw-m-0">
            Trade Passport is a representation of your skillset/ superpowers,
            and all other business related details, which clients and other
            trades who may invite you to the jobs may like to see. The more
            details provided the better.
          </div>
          <div class="title_img md:!tw-m-0">
            <img src="@/assets/images/trade-passport-preview.png" />
          </div>
        </div>
        <div class="modal_footer_button">
          <v-btn
            variant="elevated"
            class="button button-orange w-full"
            @click="onClickManageTradePassport"
          >
            Complete your trade passport
          </v-btn>
        </div> -->
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useRouter } from "vue-router";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import Button from "@/core/components/ui/general/Button.vue";

export default {
  components: {
    CommonDialog,
    Button,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();

    const onClickManageTradePassport = () => {
      router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    return {
      onClickCloseButton,
      onClickManageTradePassport,
    };
  },
};
</script>
