<template>
  <GeneralDialog
    :headerTitle="modalHeader.title"
    :headerDescription="modalHeader.description"
    @on-close="onCloseModal"
    :isShowCloseButton="false"
    width="430px"
    max-width="100%"
  >
    <template #body>
      <div class="tw-w-full tw-max-w-full">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-relative tw-ml-5">
            <ImageHolder
              v-for="imgUrl of subContractorsImages.slice(0, 3)"
              :key="imgUrl"
              :imageUrl="imgUrl"
              class="tw-ml-[-20px] !tw-h-[40px] !tw-w-[40px]"
            />
            <!-- Numbered ImageHolder -->
            <div
              class="tw-w-[40px] tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-absolute tw-top-0 tw-left-[75px] tw-bg-cyan-50 tw-rounded-full tw-border-solid tw-border-white"
              v-if="subContractorsImages?.length > 3"
            >
              <Text
                variant="p"
                textWeight="700"
                textColor="#4B4BFF"
                textSize="24px"
              >
                +{{ subContractorsImages?.length - 3 }}
              </Text>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-gap-[16px]">
            <EstimateBadge :badge="getEstimateDepositType" bgColor="#6B5F4B" />
          </div>
        </div>

        <div class="tw-flex tw-items-center tw-gap-1 tw-mt-4">
          <span class="tw-text-xl tw-font-bold tw-leading-6 tw-text-[#0C0F4A]">
            {{ estimateData?.workStation?.name }}
          </span>
          <span
            v-if="subContractorsImages?.length > 1"
            class="tw-text-[10px] tw-font-[600] tw-text-[#0C0F4A80]"
          >
            +{{ subContractorsImages.length - 1 }} more
          </span>
        </div>

        <div class="tw-flex tw-items-center tw-gap-3 tw-mt-1">
          <div
            class="tw-flex tw-items-center tw-font-font-semibold tw-text-xs tw-text-[#0C0F4A80]"
          >
            <span>{{
              estimateData?.workStation?.user?.userBusinessDetails?.type
            }}</span>
            <span
              v-if="estimateData?.workStation?.user?.postcode"
              class="tw-flex tw-items-center"
            >
              <v-icon icon="mdi-map-marker" size="12px" />
              {{ estimateData?.workStation?.user?.postcode }}
            </span>
          </div>
          <!-- <div class="tw-flex tw-items-center tw-gap">
            <v-icon icon="mdi-star" color="#faa500" size="12px" />
            <span class="tw-font-normal tw-text-[#0C0F4A] tw-text-xs"
              >4.8/5 (536 Jobs)</span
            >
          </div> -->
        </div>

        <div class="tw-flex tw-items-center tw-gap-2 tw-mt-3">
          <div class="tw-flex tw-items-center">
            <v-icon
              class="tw-text-[12px]"
              icon="mdi-check-circle"
              color="green"
            />
            <span
              class="tw-pl-[4px] tw-text-xs tw-font-semibold tw-text-[#1FB27A]"
            >
              {{
                estimateData?.workStation?.experience
                  ? `${estimateData?.workStation?.experience} + Yrs `
                  : "N/A"
              }}
            </span>
            <span class="tw-text-xs tw-font-semibold tw-text-[#0C0F4A] tw-ml-1">
              Exp.
            </span>
          </div>

          <CustomBadge v-if="getUserSkillList.length">
            <div class="tw-flex tw-gap-1 tw-items-center">
              <div
                class="tw-flex tw-gap-1 tw-items-center tw-bg-[#0c0f4a0a] tw-rounded-xl tw-inline-block tw-px-4 tw-py-2"
                v-for="skill in getUserSkillList.slice(0, 2)"
                :key="skill.id"
              >
                <div
                  class="category__icon tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-[#0c0f4a] tw-flex tw-items-center tw-justify-center"
                >
                  <img
                    class="tw-w-[10px] tw-h-[10px]"
                    :src="
                      require('@/assets/category-icons/' +
                        skill?.iconName +
                        '.svg')
                    "
                    alt="image"
                  />
                </div>
                <span
                  class="tw-font-bold tw-text-[#0C0F4A] tw-text-xs tw-truncate tw-max-w-[50px] tw-inline-block"
                >
                  {{ skill?.label }}
                </span>
              </div>
            </div>
          </CustomBadge>

          <!-- :click="onClickShowRemainingSkill" -->
          <span
            class="tw-font-bold tw-text-[#0C0F4A] tw-text-xs tw-bg-[#0c0f4a0a] tw-rounded-xl tw-inline-block tw-px-4 tw-py-2 tw-truncate-[unset] tw-whitespace-nowrap"
            v-if="getUserSkillList.length > 2"
          >
            +{{ getUserSkillList.length - 2 }} More
          </span>
        </div>

        <div class="tw-mt-4">
          <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mb-1" />
          <div class="tw-grid tw-grid-cols-3 tw-my-2">
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Start Date
              </span>
              <span
                class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A] tw-whitespace-nowrap"
              >
                {{ formattedDate }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-border-0 tw-border-solid tw-border-l tw-border-r tw-border-[#F1F6FB]"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Duration
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                {{ duration }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Cost
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                £ {{ customerTotal }}
              </span>
            </div>
          </div>
          <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mt-1" />
        </div>
      </div>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3"
      >
        <Button
          variant="tertiary"
          label="Back To Project"
          @click="onClickBack"
          class="button button-purple-border"
        />
        <!-- <Button
          variant="tertiary"
          label="Deposit funds into escrow"
          @click="onClickDepositFunds"
          class="button button-orange"
        /> -->
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import ImageHolder from "@/core/uni-components/ImageHolder.vue";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { computed, ref } from "vue";
import moment from "moment";
import { USER_STORE } from "@/store/modules/user";
import { onMounted } from "vue";
import {
  getImageStringToImageURL,
  getUserSubSkillList,
} from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import ChipState from "@/core/components/common/ChipState.vue";
import { EstimateTypeForDeposit } from "@/core/enums/estimateEnum";
const emits = defineEmits(["on-back-project", "on-deposit"]);

const props = defineProps({
  estimateData: {
    type: Object,
  },
});
const modalHeader = {
  title: "Estimate send for approval!",
  description: "Estimate provided by",
};
const totalSubTotal = computed(() => {
  const sum = props.estimateData.estimationPhase.reduce((sum, phase) => {
    const roundedSubTotal = parseFloat(phase.subTotal);
    return sum + roundedSubTotal;
  }, 0);
  return Math.round(sum * 100) / 100;
});

const totalVat = computed(() => {
  const sum = props.estimateData.estimationPhase.reduce((sum, phase, index) => {
    const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
    return sum + roundedTotalVat;
  }, 0);
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03;
  let totalWithServiceFee = props.estimateData.estimationPhase.reduce(
    (sum, phase, index) => {
      const roundedTotalWithServiceFee = parseFloat(
        phase.subTotal * serviceFeePercentage
      );
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

const customerTotal = computed(() => {
  let total = totalSubTotal.value + totalVat.value + serviceCharges.value;
  if (props.estimateData.grantAmount) {
    total -= props.estimateData.grantAmount;
  }
  return Math.round(parseFloat(total) * 100) / 100;
});
const onClickBack = () => {
  emits("on-back-project", false);
};

const onClickDepositFunds = () => {
  emits("on-deposit");
};

const duration = computed(() => {
  if (
    !props.estimateData?.suggestedStartDate ||
    !props.estimateData?.projectEstimateEndDate
  ) {
    return 0;
  }

  const startDate = moment(props.estimateData?.suggestedStartDate) as any;
  const endDate = moment(props.estimateData?.projectEstimateEndDate) as any;

  const totalDays = endDate.diff(startDate, "days");

  if (totalDays % 7 === 0) {
    const totalWeeks = totalDays / 7;
    return `${totalWeeks} week${totalWeeks > 1 ? "s" : ""}`;
  }

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});
const formattedDate = computed(() => {
  if (!props.estimateData?.suggestedStartDate) {
    return "N/A";
  }
  const date = new Date(props.estimateData?.suggestedStartDate);
  const options = { day: "2-digit", month: "long", year: "numeric" } as any;
  return date.toLocaleDateString("en-GB", options);
});

const displayedSkills = computed(() => {
  return props.estimateData?.workStation?.user?.userSkills.slice(0, 2);
});

const remainingCount = computed(() => {
  return props.estimateData?.workStation?.user?.userSkills.length - 2;
});

const store = useStore();
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getUserSkillList = computed(() => {
  const result = getUserSubSkillList(
    displayedSkills.value,
    getAllSpecialism.value
  );
  return result;
});

const onClickShowRemainingSkill = () => {
  displayedSkills.value = props.estimateData?.workStation?.user?.userSkills;
  remainingCount.value = 0;
};
const subContractorsImages: any = ref([]);
const setSubContractorsProfileImages = async () => {
  for (const contractors of props.estimateData?.inviteContractors) {
    const imageUrl = await getImageStringToImageURL(
      contractors?.toContractorWorkstation?.profileImage
    );
    if (imageUrl) {
      subContractorsImages.value.push(imageUrl);
    }
  }

  const imageUrl = await getImageStringToImageURL(
    props.estimateData?.workStation?.profileImage
  );
  if (imageUrl) {
    subContractorsImages.value.push(imageUrl);
  }
};

const getEstimateDepositType = computed(() => {
  if (
    props.estimateData?.depositType == EstimateTypeForDeposit.Initial_Estimate
  ) {
    return "Initial Estimate";
  } else if (
    props.estimateData?.depositType ==
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  ) {
    return "Fixed Cost Estimate";
  } else if (
    props.estimateData?.depositType ==
    EstimateTypeForDeposit.Variable_Cost_Estimation
  ) {
    return "Variable Cost Estimate";
  } else {
    return "Initial Estimate";
  }
});
onMounted(async () => {
  try {
    setSubContractorsProfileImages();
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }
  } catch (error) {
    console.log();
  }
});
</script>

<style lang="scss" scoped>
.category__icon {
  img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(20deg)
      brightness(103%) contrast(101%);
  }
}
</style>
