<template>
  <GeneralDialog
    @on-close="onCloseModal"
    :headerTitle="file?.originalName"
    width="700px"
  >
    <template #body>
      <div
        class="tw-w-full tw-h-full tw-pt-5 tw-border-t-dashed-custom tw-box-border rmd:!tw-min-w-[620px]"
      >
        <div
          class="tw-w-full tw-h-full tw-p-0 tw-box-border tw-rounded-lg tw-overflow-hidden rmd:tw-max-h-[500px]"
        >
          <v-img
            class="tw-w-full tw-h-full tw-cursor-pointer tw-rounded-lg"
            :class="{
              'tw-hidden':
                file?.fileType !== 'image/jpeg' &&
                file?.fileType !== 'image/png',
            }"
            aspect-ratio="16/9"
            cover
            :src="file?.attachmentSource"
          ></v-img>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { USER_STORE } from "@/store/modules/user";

const props = defineProps(["loading", "file"]);
const emits = defineEmits(["on-close", "on-success"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onCloseModal = () => {
  emits("on-close");
};
</script>
