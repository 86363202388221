/**
 * Forgot Password route page
 */
import ForgotPasswordPage from "@/modules/forgot-password/pages/ForgotPassword.vue";

export const FORGOT_PASSWORD_ROUTE = "forgot-password";

export default {
  path: "/forgot-password",
  name: FORGOT_PASSWORD_ROUTE,
  component: ForgotPasswordPage,
  meta: {
    layout: "AuthLayout",
  },
};
