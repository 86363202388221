<template>
  <GeneralDialog class="tw-max-w-[450px]" @on-close="onCloseModal">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <TrashIcon
          fillOpacity="1"
          fill="#F45454"
          class="tw-w-[27px] tw-h-[35px]"
        />
        <Text variant="h4" textAlign="center" lineHeight="28px"
          >Are you sure you would like to delete this Template ?</Text
        >
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          label="Confirm"
          :isLoading="loading"
          :disabled="loading"
          @click="onConfirmModal"
        />
        <Button
          variant="secondary"
          :disabled="loading"
          label="Cancel"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
// import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

const emits = defineEmits(["on-close", "on-confirm"]);
const onCloseModal = () => {
  emits("on-close");
};
const onConfirmModal = () => {
  emits("on-confirm");
};
</script>
