import $axios from "../utils/axios-api-config";
export default {
  getUserNotificationStatus() {
    return $axios.get(`user-notifications`);
  },
  deleteNotification(notificationId: number) {
    return $axios.delete(`user-notifications/${notificationId}`);
  },
  updateNotification(notificationId: number, payload: any) {
    return $axios.put(`/user-notifications/${notificationId}`, payload);
  },
  updateOuterNetworkNotificationStatus(notificationId: number, payload: any) {
    return $axios.put(
      `/user-notifications/updateMetaData/${notificationId}`,
      payload
    );
  },
};
