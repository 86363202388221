<template>
  <GeneralDialog headerTitle="Details" @on-close="onCloseModal">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
            <div class="tw-flex tw-items-center tw-gap-2">
              <FolderCircleIcon v-if="isTypeFolder" />
              <FileCircleIcon v-else />
              <div class="tw-flex tw-flex-col tw-items-start">
                <Text variant="p">{{ document.name }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="#4F55F0"
                  v-if="isTypeFolder"
                  >Folder</Text
                >
                <Text variant="span" textWeight="400" textColor="#4F55F0" v-else
                  >File: {{ document.fileType }}</Text
                >
              </div>
            </div>
            <Text variant="span" textWeight="500">{{ document.fileSize }}</Text>
          </div>
          <v-divider></v-divider>
          <div class="tw-flex tw-items-center tw-justify-between">
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text
                variant="span"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >Last updated</Text
              >
              <Text variant="p" textWeight="600">{{ document.updatedAt }}</Text>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text
                variant="span"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >Files</Text
              >
              <Text variant="p" textWeight="600">{{ document.fileCount }}</Text>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text
                variant="span"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >Owner</Text
              >
              <Text variant="p" textWeight="600">{{ document.owner }}</Text>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3">
        <Button label="Done" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, onMounted } from "vue";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { formatBytes } from "@/core/helpers/file.helper";
import FileCircleIcon from "@/core/components/icons/FileCircleIcon.vue";
import FolderCircleIcon from "@/core/components/icons/FolderCircleIcon.vue";
import documentConstants from "@/modules/documents/contants";
import appFilters from "@/filters";

const emits = defineEmits(["on-close"]);
const props = defineProps(["selectedDocument", "documentType"]);

const isTypeFolder = computed(
  () => props.documentType === documentConstants.FOLDERS
);

const document = computed(() => {
  const { isDir, fileType } = props.selectedDocument;
  return {
    isDir,
    type: isDir ? "Folder" : "File",
    name: props.selectedDocument.name,
    owner: "Me",
    updatedAt: appFilters.formatToDate(
      props.selectedDocument.dateUpdated,
      "DD MMM YYYY"
    ),
    fileCount: "-",
    fileSize: isDir
      ? formatBytes(props.selectedDocument.totalFolderByteSize)
      : formatBytes(props.selectedDocument.fileSize),
    fileType,
  };
});

const onCloseModal = () => {
  emits("on-close");
};

onMounted(() => {
  console.log(props.selectedDocument, "selected docss");
});
</script>
