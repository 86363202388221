import $axios from "../utils/axios-api-config";
import { InviteMember } from "../models/manage-member";

export default {
  getTradeWorkStationContractor(userActiveWorkstationId: number, params = {}) {
    return $axios.get(`user/network/${userActiveWorkstationId}`, { params });
  },
  sendRequestToContractor(payload: {
    senderWorkstationId: number;
    receiverWorkstationId: number;
    projectId: number | null;
  }) {
    return $axios.post(`user/network/request`, payload);
  },
  updateRequestToContractor(requestId: number, payload: { status: string }) {
    return $axios.put(`user/network/request/${requestId}`, payload);
  },
  addContractorCategoryForUser(payload: {
    workstationId: boolean;
    name: string;
  }) {
    return $axios.post(`user/network/category`, payload);
  },
  getUserSentRequestList(userWorkstationId: number) {
    return $axios.get(`/user/network/requests/sent/${userWorkstationId}`);
  },
  getUserReceivedRequestList(userWorkstationId: number) {
    return $axios.get(`/user/network/requests/received/${userWorkstationId}`);
  },
  getUserNetWorkContractor(userWorkstationId: number, params = {}) {
    return $axios.get(
      `/user/network/connected-categorized/${userWorkstationId}`,
      { params }
    );
  },
  removeContractorFromNetwork(payload: any) {
    return $axios.post(`/user/network/remove-users`, payload);
  },
  moveContractorToOtherCategory(payload: {
    activeWorkstationId: number;
    movedWorkstationIds: [number];
    categoryId: number;
  }) {
    return $axios.post(`/user/network/request/category`, payload);
  },
  getAllContractorBasedOnCategory(userWorkstationId: number) {
    return $axios.get(`/user/network/categories/${userWorkstationId}`);
  },
  getAllContractorCategoryList(userWorkstationId: number) {
    return $axios.get(`/user/network/categories/${userWorkstationId}`);
  },
  cancelRequestToContractor(id: number) {
    return $axios.post(`user/network/request/cancel/${id}`);
  },
  getAllContractorWithOutCategory(userWorkstationId: number, params = {}) {
    return $axios.get(`/user/network/connected/${userWorkstationId}`, {
      params,
    });
  },
  inviteExternalContractor(members: InviteMember) {
    return $axios.post("/user/network/outer-request", members);
  },
  getExternalInvitedContractorList() {
    return $axios.get("/user/network/outer-request/sent");
  },
  getExternalInvitedContractorForNotification() {
    return $axios.get("/user/network/outer-request/receiver");
  },
  rejectExternalContractorInvitation(rowId: number) {
    return $axios.delete(`/user/network/outer-request/${rowId}`);
  },
  acceptExternalContractorInvitation(rowId: number, payload: { key: string }) {
    return $axios.put(`/user/network/outer-request/${rowId}`, payload);
  },
};
