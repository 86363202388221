<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px] tw-max-w-full">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-min-h-[200px]"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <v-icon>
            <img src="../../../../assets/icons/notepad.svg" alt="" />
          </v-icon>

          <Text variant="h4" textAlign="center" lineHeight="28px">
            {{ message }}</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          :disabled="loading"
          :isLoading="loading"
          label="Cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          :disabled="loading"
          :isLoading="loading"
          label="confirm"
          :isActive="true"
          @click="onConfirm"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { HistoricalProjectTypeEnum } from "@/core/enums/ProjectsEnum";
const props = defineProps({
  projectType: {
    type: Number,
  },
  historicalProjectForm: {
    type: Object,
  },
});

const message = computed(() => {
  if (
    props.historicalProjectForm.projectType ===
      HistoricalProjectTypeEnum.DIY_PROJECT &&
    props.historicalProjectForm.invitedContractors.length
  ) {
    return `Are yo sure you want to Invite Selected Contractors To Your Historical Project?`;
  } else if (
    props.historicalProjectForm.projectType ===
      HistoricalProjectTypeEnum.TRADE_VERIFY &&
    !props.historicalProjectForm.invitedContractors.length
  ) {
    return `Are you sure you want to change this Historical project Type Trade to
            Diy ?`;
  } else {
    return `Are yo sure you want to Invite Selected Contractors To Your
            Historical Project?`;
  }
});
const emits = defineEmits([
  "on-close",
  "on-success",
  "on-confirm",
  "on-cancel",
  "onConfirmProjectType",
]);

const state = reactive({
  loading: false,
});

const onCloseModal = () => {
  emits("on-close");
};
const onCancel = () => {
  emits("on-cancel");
};

const onConfirm = () => {
  if (
    props.projectType === HistoricalProjectTypeEnum.DIY_PROJECT &&
    props.historicalProjectForm.invitedContractors.length
  ) {
    emits("onConfirmProjectType", HistoricalProjectTypeEnum.DIY_PROJECT);
  } else if (
    props.projectType === HistoricalProjectTypeEnum.TRADE_VERIFY &&
    !props.historicalProjectForm.invitedContractors.length
  ) {
    emits("onConfirmProjectType", HistoricalProjectTypeEnum.TRADE_VERIFY);
  } else {
    emits("on-confirm");
  }
};
</script>
