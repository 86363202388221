import $axios from "../utils/axios-api-config";
import { CreateProject, UpdateProject } from "../models/project";

export default {
  attachmentCategory(label: string, value: string) {
    return $axios.post("/projects/attachment/category", { label, value });
  },
  createProject(data: CreateProject) {
    return $axios.post("/projects", data);
  },
  createRaiseProject(data: CreateProject) {
    return $axios.post("/projects/raise", data);
  },
  getProjectData(projectRefId: string) {
    return $axios.get(`/projects/${projectRefId}`);
  },
  getProjectDataProperties(projectRefId: string) {
    return $axios.get(`/projects/${projectRefId}/properties`);
  },
  getProjectDataSelectedProperties(
    projectRefId: string,
    propertyIds: Array<any>
  ) {
    return $axios.post(`/projects/${projectRefId}/attachments`, {
      propertyIds: propertyIds,
    });
  },
  updateProject(data: any, projectId: number) {
    return $axios.put(`projects/${projectId}`, data);
  },
  updateSubSpecialismCategory(data: any, projectId: number) {
    return $axios.put(`projects/specialism/${projectId}`, data);
  },
  updateSpecialism(specialism: any, projectId: number) {
    return $axios.put(`/projects/${projectId}/specialism-update`, {
      specialism: specialism,
    });
  },
  getFilterProjectData(params: any) {
    return $axios.get(
      `/projects/?projectStatus=${params.projectStatus}&limit=${params.limit}&page=${params.page}`
    );
  },
  getProjectCategoryList(projectId: number) {
    return $axios.get(`/projects/attachment/category/${projectId}`);
  },
  getAllProjects(params = {}) {
    return $axios.get(`/projects`, { params });
  },
  getAllRequestedProjects(payload: any) {
    const { keywords = "", ...filters } = payload;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `/projects/raise?${urlParams.toString()}${
      keywords.length > 0 ? `&keywords[]=${keywords}` : ""
    }`;
    return $axios.get(url);
  },
  updateProjectStatus(projectId: number, payload: any) {
    return $axios.put(`projects/${projectId}/status-update`, payload);
  },
  updateRaiseProjectStatus(projectId: number, payload: any) {
    return $axios.put(`projects/raise/status/${projectId}`, payload);
  },
  inviteContractorToProject(data: any) {
    return $axios.post(`projects/invite-only`, data);
  },
  createProjectChat(payload: any) {
    return $axios.post(`user-messages/project-chat`, payload);
  },
  getProjectChatRequest(projectId: number, projectOwnerId: number) {
    return $axios.get(
      `user-messages/project-chat/${projectId}/${projectOwnerId}`
    );
  },
  getRecommendationForJobDescription(prompt: string) {
    return $axios.post(`jobs/description/recommendation`, {
      prompt,
    });
  },
  getRecommendationForJobTitle(prompt: string) {
    return $axios.post(`jobs/title-recommendation `, {
      prompt,
    });
  },
  getSpecialismRecommendation(description: string) {
    return $axios.post(`jobs/description/recommendation`, {
      description,
    });
  },
  getNewAddedSubCategoryForProject(payload: any) {
    return $axios.post(
      `categories/subCategoriesList
`,
      payload
    );
  },
  getVideoConsultationRoomUrl(payload: any) {
    return $axios.post(`/daily_co/create-room`, payload);
  },
  getMeetingScheduleDetails(projectId: number) {
    return $axios.get(`/jobs/video-project-meeting-scheldule/${projectId}`);
  },
  updateProjectSiteVisitStatus(projectId: any, payload: any) {
    return $axios.put(`/projects/${projectId}`, payload);
  },
  deleteProject(projectId: number) {
    return $axios.delete(`projects/${projectId}`);
  },
  getRateCategories() {
    return $axios.get(`/categories/rate`);
  },
  getTradeRateValidationStatus(payload: any) {
    const { userId, userWorkStationId } = payload;
    return $axios.get(
      `user-workstation-jobs-rates/${userId}/${userWorkStationId}`
    );
  },
};
