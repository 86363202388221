import { AUTH_TOKEN, AUTH_USER } from "@/core/constants";
import { AuthenticationState } from "@/core/models/authentication";
import { getLocalStorageWithExpiry } from "@/core/utils/common";

export default {
  isAuthenticated(): boolean {
    return !!getLocalStorageWithExpiry(AUTH_TOKEN);
  },
  authenticatedUser() {
    return getLocalStorageWithExpiry(AUTH_USER);
  },
  forgotPasswordTempUser: (state: AuthenticationState) =>
    state.forgotPasswordTempUser,
};
