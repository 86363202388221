/* eslint-disable */
import propertyService from "../services/property.service";
import { fromArrayBuffer } from "geotiff";
import * as geoTiff from "geotiff";
import * as geoKeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from "proj4";
import { renderPalette, renderRGB } from "./visualize";
export const binaryPalette = ["212121", "B3E5FC"];
export const rainbowPalette = [
  "3949AB",
  "81D4FA",
  "66BB6A",
  "FFE082",
  "E53935",
];
export const ironPalette = ["00000A", "91009C", "E64616", "FEB400", "FFFFF6"];
export const sunlightPalette = ["212121", "FFCA28"];
export const panelsPalette = ["E8EAF6", "1A237E"];
export interface Bounds {
  north: number;
  south: number;
  east: number;
  west: number;
}
export interface GeoTiff {
  width: number;
  height: number;
  rasters: Array<number>[];
  bounds: Bounds;
}

interface Layer {
  id: LayerId;
  bounds: any;
  palette?: {
    colors: string[];
    min: string | number;
    max: string | number;
  };
  render: (showRoofOnly?: boolean, month?: number, day?: number) => any[];
}

type LayerId =
  | "maskUrl"
  | "dsmUrl"
  | "rgbUrl"
  | "annualFluxUrl"
  | "monthlyFluxUrl"
  | "hourlyShadeUrls";

interface DataLayersResponse {
  maskUrl: string;
  dsmUrl?: string;
  rgbUrl?: string;
  annualFluxUrl?: string;
  monthlyFluxUrl?: string;
  hourlyShadeUrls?: string[];
}
const cacheData: any = [];

const getCacheData = (key: string) => {
  const cacheItem = cacheData.find((item: any) => item.key === key);
  return cacheItem ? cacheItem.data : null;
};
const setCacheData = (key: string, data: object) => {
  const existingIndex = cacheData.findIndex((item: any) => item.key === key);
  if (existingIndex !== -1) {
    cacheData[existingIndex].data = data;
  } else {
    cacheData.push({ key, data });
  }
};
const downloadGeoTIFF = async (tiffId: string): Promise<GeoTiff> => {
  try {
    const cacheData = getCacheData(tiffId);
    const arrayBuffer: any = cacheData
      ? cacheData
      : await propertyService.getTIFFLayer(tiffId);
    if (!cacheData) setCacheData(tiffId, arrayBuffer);
    const tiff = await fromArrayBuffer(arrayBuffer);
    const image = await tiff.getImage();
    const rasters = await image.readRasters();
    const geoKeys = image.getGeoKeys();
    const projObj = geoKeysToProj4.toProj4(geoKeys);
    const projection = proj4(projObj.proj4, "WGS84");
    const box = image.getBoundingBox();
    const sw = projection.forward({
      x: box[0] * projObj.coordinatesConversionParameters.x,
      y: box[1] * projObj.coordinatesConversionParameters.y,
    });
    const ne = projection.forward({
      x: box[2] * projObj.coordinatesConversionParameters.x,
      y: box[3] * projObj.coordinatesConversionParameters.y,
    });
    return {
      width: rasters.width,
      height: rasters.height,
      rasters: [...Array(rasters.length).keys()].map((i) =>
        Array.from(rasters[i] as geoTiff.TypedArray)
      ),
      bounds: {
        north: ne.y,
        south: sw.y,
        east: ne.x,
        west: sw.x,
      },
    };
  } catch (error) {
    console.error("Error processing TIFF layer:", error);
    throw error;
  }
};

export const useSolarLayer = () => {
  const getLayer = async (
    layerId: LayerId,
    urls: DataLayersResponse
  ): Promise<
    | {
        id: LayerId;
        bounds: Bounds;
        palette?: { colors: string[]; min: string; max: string };
        render: (
          showRoofOnly: boolean,
          month: number,
          day: number
        ) => HTMLCanvasElement[];
      }
    | undefined
  > => {
    const get: Record<
      LayerId,
      () => Promise<
        | {
            id: LayerId;
            bounds: Bounds;
            palette?: { colors: string[]; min: string; max: string };
            render: (
              showRoofOnly: boolean,
              month: number,
              day: number
            ) => HTMLCanvasElement[];
          }
        | undefined
      >
    > = {
      maskUrl: async () => {
        const mask = await downloadGeoTIFF(urls.maskUrl);

        const colors = binaryPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: "No roof",
            max: "Roof",
          },
          render: (showRoofOnly: boolean) => [
            renderPalette({
              data: mask,
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
            }),
          ],
        };
      },
      dsmUrl: async () => {
        const [mask, data] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl),
          downloadGeoTIFF(urls.dsmUrl!),
        ]);
        if (!data || !mask) return;
        const sortedValues = Array.from(data.rasters[0]).sort((x, y) => x - y);
        const minValue = sortedValues[0];
        const maxValue = sortedValues.slice(-1)[0];
        const colors = rainbowPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: `${minValue.toFixed(1)} m`,
            max: `${maxValue.toFixed(1)} m`,
          },
          render: (showRoofOnly: boolean) => [
            renderPalette({
              data: data,
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: minValue,
              max: maxValue,
            }),
          ],
        };
      },
      rgbUrl: async () => {
        const [mask, data] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl),
          downloadGeoTIFF(urls.rgbUrl!),
        ]);
        if (!mask || !data) return;
        return {
          id: layerId,
          bounds: mask.bounds,
          render: (showRoofOnly: boolean) => [
            renderRGB(data, showRoofOnly ? mask : undefined),
          ],
        };
      },
      annualFluxUrl: async () => {
        const [mask, data] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl),
          downloadGeoTIFF(urls.annualFluxUrl!),
        ]);
        if (!mask || !data) return;
        const colors = ironPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: "Shady",
            max: "Sunny",
          },
          render: (showRoofOnly: boolean) => [
            renderPalette({
              data: data,
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: 0,
              max: 1800,
            }),
          ],
        };
      },
      monthlyFluxUrl: async () => {
        const [mask, data] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl),
          downloadGeoTIFF(urls.monthlyFluxUrl!),
        ]);
        if (!mask || !data) return;

        const colors = ironPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: "Shady",
            max: "Sunny",
          },
          render: (showRoofOnly: boolean) =>
            [...Array(12).keys()].map((month) =>
              renderPalette({
                data: data,
                mask: showRoofOnly ? mask : undefined,
                colors: colors,
                min: 0,
                max: 200,
                index: month,
              })
            ),
        };
      },
      hourlyShadeUrls: async () => {
        const [mask, ...months] = await Promise.all([
          downloadGeoTIFF(urls.maskUrl),
          ...(urls.hourlyShadeUrls?.map((url) => downloadGeoTIFF(url)) ?? []),
        ]);
        if (!mask || !months) return;

        const colors = sunlightPalette;
        return {
          id: layerId,
          bounds: mask.bounds,
          palette: {
            colors: colors,
            min: "Shade",
            max: "Sun",
          },
          render: (showRoofOnly: boolean, month = 0, day = 0) =>
            [...Array(24).keys()].map((hour) =>
              renderPalette({
                data: {
                  ...months[month],
                  rasters: months[month].rasters.map((values) =>
                    values.map((x: number) => x & (1 << (day - 1)))
                  ),
                },
                mask: showRoofOnly ? mask : undefined,
                colors: colors,
                min: 0,
                max: 1,
                index: hour,
              })
            ),
        };
      },
    };

    try {
      return await get[layerId]();
    } catch (e) {
      console.error(`Error getting layer: ${layerId}`, e);
      throw e;
    }
  };

  return {
    getLayer,
  };
};
