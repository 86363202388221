<template>
  <GeneralDialog @on-close="onCloseModal" headerTitle="Add New Report">
    <template #body>
      <div
        class="tw-w-full rmd:tw-w-[540px] tw-max-h-[570px] tw-border-b-solid-custom tw-flex tw-flex-col tw-gap-4 tw-overflow-x-hidden"
      >
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Report Name</Text>
          <InputField placeholder="Enter Report Name" v-model="form.name" />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <Text variant="span" textWeight="400">Upload Document</Text>
          <div class="tw-w-full">
            <UploadForm
              class="!tw-w-full"
              listType="picture"
              :record="form"
              :allowMultiple="false"
              :attachmentSources="[]"
              :useCustomUploadIcon="false"
              :useDragTrigger="true"
              @on-progress-upload="onProgressUpload"
              @on-upload-success="updateFileOnSuccess"
              @on-handle-error="onHandleError"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3">
        <Button
          class="!tw-w-auto"
          variant="secondary"
          :disabled="state.loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          :disabled="state.loading"
          :isLoading="state.loading"
          label="save"
          :isActive="true"
          @click="onCreateRics"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import UploadForm from "@/core/components/forms/Upload.vue";
import { PROPERTY_STORE } from "@/store/modules/property";

const emits = defineEmits(["on-close", "on-success"]);

const store = useStore();
const route = useRoute();

const form = reactive({
  name: "",
  isActive: true,
  attachments: [],

  disableButton: false,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});

const state = reactive({
  loading: false,
  dateInput: "",
});

const propertyId = computed(() => route?.params?.propertyId);

const hasAttachments = computed(() => {
  return form.attachments.length > 0;
});

const onCloseModal = () => {
  emits("on-close");
};

const onProgressUpload = () => {
  form.isUploading = true;
  form.isLoading = true;
};

const updateFileOnSuccess = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onHandleError = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onCreateRics = async () => {
  if (!propertyId.value) return;
  const propertyIdParse = parseInt(propertyId.value, 10);
  console.log(form.attachments, "attachments");
  let attachmentsModified = [];
  if (form.attachments && form.attachments.length) {
    attachmentsModified = form.attachments.map((attachment) => {
      return {
        ...attachment,
        src: attachment.publicUrl,
      };
    });
  }
  try {
    state.loading = true;
    const payload = {
      formData: {
        propertyId: propertyIdParse,
        name: form.name,
        attachments: attachmentsModified,
        isActive: true,
      },
      propertyId: propertyIdParse,
    };
    const response = await store.dispatch(
      `${PROPERTY_STORE}/createRicsReport`,
      payload
    );
  } catch (error) {
    console.log(error);
  } finally {
    state.loading = false;
    emits("on-success");
  }
};
</script>
