import { Commit } from "vuex";
import { CreatePersonalEvent } from "@/core/models/calendar";
import CalendarService from "@/core/services/calendar.service";

export default {
  async getPersonalCalendarEvent(
    { commit }: { commit: Commit },
    payload: { params: any }
  ) {
    try {
      const calendarEventList = await CalendarService.getPersonalCalendarEvent(
        payload.params || {}
      );
      return calendarEventList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async updateCalendarEvent({ state, commit }: any, payload: any) {
    const { TraderCalenderId, updateData } = payload;
    return CalendarService.updateCalendarEvent(TraderCalenderId, updateData);
  },
  async deleteCalendarEvent({ state, commit }: any, payload: any) {
    const { TraderCalenderId } = payload;
    return CalendarService.deleteCalendarEvent(TraderCalenderId).then(
      (response) => {
        return response;
      }
    );
  },
  async createPersonalEvent(
    { state, commit }: any,
    payload: CreatePersonalEvent
  ) {
    const {
      userId,
      name,
      description,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      invitePeople,
    } = payload;
    return CalendarService.createPersonalEvent(
      userId,
      name,
      description,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      invitePeople
    ).then((response) => {
      return response;
    });
  },

  setProjectCalendarData(
    { state, commit }: any,
    payload: {
      calendarData: {
        calendarData: Array<object>;
        calendarSideList: Array<object>;
      };
      type: string;
    }
  ) {
    commit("setProjectCalendarData", payload);
  },
};
