export default {
  setOpenShareModal(state: { openShareModal: boolean }, value: boolean) {
    state.openShareModal = value;
  },
  setOpenRenameModal(state: { openRenameModal: boolean }, value: boolean) {
    state.openRenameModal = value;
  },
  setOpenMoveModal(state: { openMoveModal: boolean }, value: boolean) {
    state.openMoveModal = value;
  },

  setOpenDetailsModal(state: { openDetailsModal: boolean }, value: boolean) {
    state.openDetailsModal = value;
  },

  setOpenDeleteModal(state: { openDeleteModal: boolean }, value: boolean) {
    state.openDeleteModal = value;
  },

  setOpenNewFolderModal(
    state: { openNewFolderModal: boolean },
    value: boolean
  ) {
    state.openNewFolderModal = value;
  },

  setOpenNewFileUploadModal(
    state: { openNewFileUploadModal: boolean },
    value: boolean
  ) {
    state.openNewFileUploadModal = value;
  },

  setFolderList(state: { folderList: boolean }, value: boolean) {
    state.folderList = value;
  },

  setFileList(state: { fileList: boolean }, value: boolean) {
    state.fileList = value;
  },

  setIsPropertyDocument(
    state: { isPropertyDocument: boolean },
    value: boolean
  ) {
    state.isPropertyDocument = value;
  },

  setOpenedFolder(state: { openedFolder: boolean }, value: boolean) {
    state.openedFolder = value;
  },

  setSelectedDocument(state: { selectedDocument: boolean }, value: boolean) {
    state.selectedDocument = value;
  },

  setOpenDocumentBillingModal(
    state: { openDocumentBillingModal: boolean },
    value: boolean
  ) {
    state.openDocumentBillingModal = value;
  },

  setDocumentList(state: { documentList: boolean }, value: boolean) {
    state.documentList = value;
  },
};
