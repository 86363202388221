1. Updated: September
<template>
  <GeneralDialog
    @on-close="onCloseModal"
    class="tw-w-full tw-h-full rlg:tw-w-[961px]"
    headerTitle="Property Score Analysis"
  >
    <template #body>
      <div class="tw-flex tw-flex-col tw-gap-8">
        <div
          class="tw-flex tw-items-center tw tw-gap-4 tw-w-full md:!tw-flex-col md:!tw-items-start"
        >
          <Text class="!tw-text-[20px]"
            >How is your property score calculated?</Text
          >
          <div
            class="tw-flex tw-items-center tw-justify-center tw-py-3 tw-px-5 tw-box-border tw-bg-[#F98228] tw-rounded-[10px]"
          >
            <Text variant="h6" textColor="white"
              >{{ allEPCCategoryDataList?.propetyScore || 0 }} |
              {{ getRanking(allEPCCategoryDataList?.propetyScore || 0) }}</Text
            >
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
          <Text variant="p" textWeight="400"
            >We analyse and calculate your property score based on the
            information we get from regulated data sources and using our unique
            methods.
          </Text>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <Text variant="p" textWeight="400">That includes:</Text>
            <ul class="tw-list-disc tw-pl-5">
              <li>
                <Text variant="p" textWeight="400"
                  >HM Land Registry and Registers of Scotland;</Text
                >
              </li>
              <li>
                <Text variant="p" textWeight="400">Ordnance Survey;</Text>
              </li>
              <li>
                <Text variant="p" textWeight="400"
                  >Energy performance certificates (EPCs);</Text
                >
              </li>

              <li>
                <Text variant="p" textWeight="400"
                  >Official survey records from accredited surveyors;</Text
                >
              </li>
              <li>
                <Text variant="p" textWeight="400"
                  >Information gathered during the property visits by
                  professional trades contractors;</Text
                >
              </li>
              <li>
                <Text variant="p" textWeight="400"
                  >Any renovations, alterations or modifications done on the
                  property using our platform;</Text
                >
              </li>
              <li>
                <Text variant="p" textWeight="400"
                  >Information shared by verified property owners.</Text
                >
              </li>
            </ul>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-4">
            <Text class="!tw-text-[20px]"
              >What does your property score mean?
            </Text>
            <Text variant="p" textWeight="400"
              >The property score is indication of the quality & accuracy of
              information available about your property. Higher property score
              reflects better quality data and therefore enables us to offer
              better recommendations & solutions on how to improve your property
              energy efficiency, lower your energy consumption, reduce property
              carbon footprint, comply with regulations & increase property
              value using our platform. Additionally, the higher property score
              can be used to receive better offers on property insurance,
              mortgage deals etc.</Text
            >
          </div>
          <div class="tw-flex tw-flex-col tw-gap-4">
            <Text class="!tw-text-[20px]">Improve your property score </Text>
            <Text variant="p" textWeight="400"
              >Boost your property value by keeping up with regular maintenance,
              modernizing key features, and improving curb appeal. Prioritize
              energy efficiency, landscaping, and quick resolution of repairs.
              Upgrades in kitchens and bathrooms deliver great returns. A
              well-maintained property not only boosts its score but also
              enhances tenant satisfaction and resale potential.</Text
            >
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-3 tw-pt-4 tw-border-t-dashed-custom"
      >
        <Button
          variant="secondary"
          class="!tw-w-auto"
          :disabled="loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <!-- <Button
          :disabled="loading"
          :isLoading="loading"
          label="accept"
          :isActive="true"
          @click="onNext"
        /> -->
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { ADD_PROPERTY_ROUTE } from "@/modules/properties/routes";
import { COMPANY_TYPE, PROPERTY_TYPE } from "@/modules/properties/constants";
import { PROPERTY_STORE } from "@/store/modules/property";

const props = defineProps(["loading"]);
const emits = defineEmits(["on-close", "on-success", "on-accept"]);

const store = useStore();
const router = useRouter();

const VERY_POOR = "Very poor";
const POOR = "Poor";
const FAIR = "Fair";
const GOOD = "Good";
const EXCELLENT = "Excellent";

const state = reactive({
  loading: false,
});

const allEPCCategoryDataList = computed(
  () => store.getters[`${PROPERTY_STORE}/epcCategoryDataList`]
);

const getRanking = (score) => {
  if (score >= 0 && score < 40) {
    return VERY_POOR;
  }
  if (score >= 40 && score < 50) {
    return POOR;
  }
  if (score >= 50 && score < 70) {
    return FAIR;
  }
  if (score >= 70 && score < 90) {
    return GOOD;
  }
  if (score >= 90 && score <= 100) {
    return EXCELLENT;
  }
};

const onCloseModal = () => {
  emits("on-close");
};

const onNext = () => {
  emits("on-accept");
};
</script>
