<template>
  <GeneralDialog
    class="tw-max-w-[620px]"
    headerTitle="Out of Hours"
    @on-close="onCloseEmergencyListModal"
    ><template #body>
      <div class="emergency-outhr-event-list-modal">
        <div class="emergency-outhr-event-list-modal__search">
          <div class="emergency-outhr-event-list-modal__search-label">
            <p>Select issue</p>
          </div>
          <v-text-field
            variant="outlined"
            placeholder="Search Issue"
            prepend-inner-icon="mdi-magnify"
            v-model="searchEOHEvent"
          >
          </v-text-field>
        </div>
        <div class="emergency-outhr-event-list-modal__inner">
          <v-radio-group v-model="selectEOHEvent">
            <v-radio
              :label="event?.emergencyOutOfHours?.title"
              :value="event?.emergencyOutOfHours?.id"
              color="#4B4BFF"
              v-for="event in filterEOHEvents"
              :key="event?.emergencyOutOfHours?.id"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="emergency-outhr-event-list-modal__footer">
        <v-btn
          class="button button-purple-border"
          @click="onCloseEmergencyListModal"
          >CANCEL</v-btn
        ><v-btn
          class="button button-orange"
          @click="onClickNextButton"
          :disabled="!selectEOHEvent"
          >Next</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import EOHService from "@/core/services/emergency-out-of-hours.service";
import { onBeforeMount, ref, computed } from "vue";
export default {
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const listAllEOHEvents = ref([]) as any;

    const selectEOHEvent = ref(null) as any;

    const searchEOHEvent = ref(null);

    const onCloseEmergencyListModal = () => {
      ctx.emit("onCloseEmergencyListModal");
    };

    const onClickNextButton = () => {
      ctx.emit("onClickNextButton", selectEOHEvent.value);
    };

    const filterEOHEvents = computed(() => {
      if (searchEOHEvent.value) {
        return listAllEOHEvents.value.filter((event: any) => {
          return event.emergencyOutOfHours.title.includes(searchEOHEvent.value);
        });
      } else {
        return listAllEOHEvents.value;
      }
    });

    onBeforeMount(async () => {
      listAllEOHEvents.value =
        await EOHService.getEOHEventListForPropertiesOccupier();
    });
    return {
      onCloseEmergencyListModal,
      onClickNextButton,
      listAllEOHEvents,
      selectEOHEvent,
      searchEOHEvent,
      filterEOHEvents,
    };
  },
};
</script>

<style lang="scss" scoped>
.emergency-outhr-event-list-modal {
  width: 522px;
  padding-top: 24px;
  border-top: 1px solid #c5d2f2;

  @include respond(s720) {
    width: 100%;
  }

  &__search {
    margin-bottom: 16px;

    &-label {
      font-weight: 600;
      margin-bottom: 16px;
    }

    :deep(.v-field) {
      border: 0;
      border-radius: 30px;
      background-color: rgba($blueDark, 0.04);

      .v-field__field {
        .v-field__input {
          padding: 8px 16px 8px 5px;
          min-height: 38px;
          height: 38px;
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: -0.41px;
          color: rgba($blueDark, 1);
          opacity: 1;
        }

        &::placeholder {
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: -0.41px;
          color: #8e8e93;
          opacity: 1;
        }
      }

      .mdi-magnify {
        font-size: 22px;
        color: #8e8e93;
      }

      .v-field__outline {
        display: none;
      }
    }
  }

  :deep(.v-selection-control-group) {
    gap: 22px;

    .v-label {
      @include fluidFont(16, 12, 1);
      color: $blueDark;
      font-weight: 500;
      padding-left: 10px;
      opacity: 1;
      letter-spacing: normal;
    }
  }

  :deep(.v-selection-control__wrapper) {
    width: 18px;
    height: 18px;

    .v-selection-control__input {
      width: 18px;
      height: 18px;
      padding-bottom: 1px;
    }

    .mdi {
      font-size: 18px;
    }
  }

  &__inner {
    padding-left: 4px;
    max-height: 270px;
    overflow: auto;
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 12px;
  }
}
</style>
