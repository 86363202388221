<template>
  <div class="issue_wrapper">
    <div v-if="!isShowIssueType">
      <div class="empty__content">
        <div class="empty__banner">
          <div class="empty__banner__content text-center">
            <h4>You haven’t raised any issues yet.</h4>
            <p>Raise your next issue at lightning speed.</p>
          </div>
          <div class="empty__banner__img">
            <img src="../../../assets/images/empty-issue.png" alt="" />
          </div>
          <div class="empty__banner__content">
            <!-- <h4>You haven’t raised any issues yet.</h4>
            <p>Raise your next issue at lightning speed.</p> -->
            <ul class="empty__banner__content__list">
              <li>
                <div class="list__item">
                  <v-icon icon="mdi-check-circle" />
                  <p><strong> Dispute protection</strong> as standard</p>
                </div>
              </li>
              <li>
                <div class="list__item">
                  <v-icon icon="mdi-check-circle" />
                  <p>Deposits secured <strong>in Escrow</strong></p>
                </div>
              </li>
              <li>
                <div class="list__item">
                  <v-icon icon="mdi-check-circle" />
                  <p>
                    Verified trade contractors
                    <strong> in your local area</strong>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="empty__banner__footer">
            <v-btn
              class="button button-purple"
              width="100%"
              @click="onClickTogglePropertyIssueSelect"
              >Raise a new issue</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <IssueTypeComponent
      v-else
      :isEditMode="isEditMode"
      @onClickBackButton="onClickBackButton"
      @switchToIssueListTab="switchToIssueListTab"
    />
    <AccessDenied @on-close="onClose" v-if="isShowAccessDenied" />

    <!-- <div class="issue_wrapper">
    <div v-if="!isShowIssueType" class="issue_wrapper__content">
      <div class="issue_wrapper__title">
        <h3>You haven’t raised any issues yet.</h3>
      </div>
      <div class="issue_wrapper__img">
        <img
          src="../../../assets/images/property-owner-dashboard.png"
          alt="img"
        />
      </div>
      <div class="issue_wrapper__list">
        <p>Raise your next issue at lightning speed.</p>

        <ul class="issue_wrapper__list_content">
          <li class="issue_wrapper__list_content_item">
            <v-icon icon="mdi-check-circle" />
            <p><strong>Dispute protection</strong> as standard</p>
          </li>
          <li class="issue_wrapper__list_content_item">
            <v-icon icon="mdi-check-circle" />
            <p>Deposits secured <strong>in Escrow</strong></p>
          </li>
          <li class="issue_wrapper__list_content_item">
            <v-icon icon="mdi-check-circle" />
            <p>
              Verified trade contractors<strong> in your local area</strong>
            </p>
          </li>
        </ul>
      </div>
      <v-btn
        class="button button-purple"
        size="small"
        block
        @click="onClickTogglePropertyIssueSelect"
      >
        Raise New issue
      </v-btn>
    </div> -->

    <!-- <IssueTypeComponent
      v-else
      :isEditMode="isEditMode"
      @onClickBackButton="onClickBackButton"
      @switchToIssueListTab="switchToIssueListTab"
    /> -->
    <!-- <AccessDenied @on-close="onClose" v-if="isShowAccessDenied" /> -->
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from "vue";
import IssueTypeComponent from "@/modules/issues/components/IssueTypeComponent.vue";

import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { PROPERTY_STORE } from "@/store/modules/property";
import { PropertyTypeEnum } from "@/core/enums/PropertiesEnum";
import AccessDenied from "@/modules/issues/components/AccessDenied.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  name: "PostIssuePropertyComponent",
  components: { IssueTypeComponent, AccessDenied },

  setup(props: any, ctx: any) {
    const isShowIssueType = ref(false);
    const route = useRoute();
    const store = useStore();
    const isShowAccessDenied = ref(false);

    const onClickTogglePropertyIssueSelect = () => {
      if (!isPropertiesWithOccupier.value) {
        isShowAccessDenied.value = true;
      } else {
        isShowIssueType.value = true;
      }
    };

    const properties = computed(
      () => store.getters[`${PROPERTY_STORE}/properties`]
    );

    const isPropertiesWithOccupier = computed(() =>
      properties.value.find(
        (property: any) => property?.propertyRole === PropertyTypeEnum.OCCUPIER
      )
    );

    /** */
    const onClickBackButton = () => {
      isShowIssueType.value = false;
    };
    const isEditMode = ref(false);

    const switchToIssueListTab = () => {
      ctx.emit("switchToIssueListTab");
    };

    const onClose = () => {
      isShowAccessDenied.value = false;
    };

    onMounted(async () => {
      if (route?.query?.propertyId) {
        isShowIssueType.value = true;
      }
      await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});
    });

    return {
      isShowIssueType,
      onClickTogglePropertyIssueSelect,
      isEditMode,
      onClickBackButton,
      switchToIssueListTab,
      isPropertiesWithOccupier,
      onClose,
      isShowAccessDenied,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/issues/styles/issues.scss";
</style>
