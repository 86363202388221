// Reference for the local storage auth token
export const AUTH_TOKEN = "auth_token";

// Reference for the local storage authenticated user info
export const AUTH_USER = "auth_user";

// Crypto secret key
export const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY ?? "";

export const IMAGE_EXPIRATION_TOKEN = "image_expiration_storage";

export const IMAGE_EXPIRATION_DURATION = 70; // in minutes

export const IMAGE_EXPIRATION_KEY = "image_expiration_token";

// Reference for auth user session expiry in milliseconds
export const AUTH_SESSION_EXPIRY = 86400000;

// Defautl current symbol
export const DEFAULT_CURRENCY = "£";

// KYC COMPANY APP ID

export const KYC_COMPANY_APP_ID = process.env.VUE_APP_SILT_KYC_COMPANY_APP_ID;
export const KYC_COMPANY_API_TOKEN = process.env.VUE_APP_SILT_KYC_API_TOKEN;
export const KYB_COMPANY_APP_ID = process.env.VUE_APP_SILT_KYB_COMPANY_APP_ID;
export const KYB_COMPANY_API_TOKEN = process.env.VUE_APP_SILT_KYB_API_TOKEN;

// app standard date format
export const DATE_FORMAT = "DD/MM/YYYY";
