<template>
  <section class="tw-flex tw-w-full tw-flex-col tw-gap-6">
    <div v-if="workflowType === HistoricalWorkFlowTypeEnum.PROPERTY">
      <div
        class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-items-start sm:tw-flex-col sm:tw-items-start"
      >
        <div
          class="tw-flex tw-justify-between tw-items-center sm:tw-flex-col tw-w-full tw-max-w-full sm:tw-items-start"
        >
          <Text variant="h4" textWeight="400">Invite Contractors</Text>
          <v-btn
            class="tw-text-xs tw-text-[orange] tw-font-medium tw-normal-case tw-tracking-[0.15px] !tw-p-0 tw-min-h-[auto] tw-h-[auto] sm:tw-hidden"
            variant="plain"
            append-icon="mdi-chevron-right"
            @click="onClickToggleContractorInviteModal"
            >Invite contractor from outside Iknowa</v-btn
          >
        </div>
        <Text
          variant="p"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="500"
          textAlign="left"
          >You can select multiple contractors to invite to review & confirm
          your estimate.</Text
        >
        <v-btn
          class="tw-text-xs tw-text-[orange] tw-font-medium tw-normal-case tw-tracking-[0.15px] !tw-p-0 tw-min-h-[auto] tw-h-[auto] !tw-hidden sm:tw-block sm:!tw-flex tw-items-start"
          variant="plain"
          append-icon="mdi-chevron-right"
          @click="onClickToggleContractorInviteModal"
          >Invite contractor from outside Iknowa</v-btn
        >
      </div>
      <div
        class="contractor__cards tw-h-[calc(100dvh-450px)] tw-overflow-auto md:tw-h-full"
      >
        <div
          class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-my-6 md:tw-flex-wrap"
        >
          <InputField
            class="input_field tw-w-[60%] md:tw-w-full"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            variant="outlined"
            v-model="filters.search"
          />
          <InputField
            class="input_field tw-w-1/5 md:tw-w-full"
            prepend-inner-icon="mdi-map-marker"
            placeholder="KT19 9JG"
            v-model="filters.postcode"
          />

          <InputField
            prepend-inner-icon="mdi-arrow-left-right"
            class="input_field md:tw-w-full"
            placeholder="Miles"
            type="number"
            v-model="filters.radius"
          />

          <v-select
            v-model="listOfQualifications"
            :items="listOfCategories"
            class="input_field_selector tw-w-1/5 md:tw-w-full"
            bg-color="#fff"
            variant="outlined"
            placeholder="Painting & Decorating"
            item-value="id"
            item-title="tpLabel"
            :menu-props="{ contentClass: 'service__selector' }"
          >
            <template v-slot:prepend-inner>
              <img src="../../../assets/icons/painting.svg" />
            </template>
          </v-select>
        </div>
        <div
          class="trade-network-container tw-flex tw-gap-6 tw-items-start tw-overflow-auto no-scrollbar tw-flex-wrap"
          v-if="!isLoading && tradePreferredContractorList.length"
        >
          <TradeNetworkCard
            class="!tw-w-[calc(33.33%-16px)] md:!tw-w-[calc(50%-12px)] sm:!tw-w-full"
            v-for="(contractor, index) in tradePreferredContractorList"
            :key="index"
            :contractor="contractor"
            :class="{
              '!tw-border-[2px] !tw-border-solid !tw-border-[#FFA500] ':
                checkIfContractorSelected(contractor.id),
            }"
            @click="selectContractor(contractor)"
          />
        </div>

        <div
          v-if="!tradePreferredContractorList.length && !isLoading"
          class="tw-flex tw-m-auto tw-justify-center tw-h-[calc(100%-100px)] tw-items-center"
        >
          Not Found InviteContractors
        </div>
      </div>
    </div>
    <div v-else>
      <div class="tw-text-left">
        <h4
          class="tw-text-[25px] tw-font-normal tw-text-[#0C0F4A] tw-leading-[32px]"
        >
          Selected Client
        </h4>
        <p
          class="tw-text-[14px] tw-font-medium tw-text-[#0c0f4a80] tw-leading-[18px] tw-tracking-[0.15px] tw-mt-4"
        >
          Lorem Ipsum
        </p>
      </div>

      <div
        class="trade-network-container tw-flex tw-gap-6 tw-items-start tw-overflow-auto no-scrollbar tw-flex-wrap"
      >
        <v-card
          class="tw-p-4 !tw-rounded-md !tw-shadow-[0px_0px_8px_0px_rgba(12,15,74,0.08)] !tw-w-[calc(33.33%-16px)]"
        >
          <div class="card__header">
            <div class="header__left tw-flex tw-gap-4 tw-items-center">
              <div
                class="card__img tw-w-[56px] tw-h-[56px] tw-rounded-full tw-overflow-hidden"
              >
                <img
                  v-if="selectedUserProfileImage"
                  :src="selectedUserProfileImage"
                  alt=""
                  class="tw-w-full tw-h-full tw-object-cover"
                />
                <UserProfileLogo
                  v-else
                  :userName="selectedClient?.userWorkstation?.name"
                />
              </div>
              <div
                class="title tw-w-[calc(100%-70px)] tw-max-w-full tw-text-left"
              >
                <h5
                  class="tw-text-[20px] tw-font-semibold tw-leading-6 tw-text-[#0C0F4A]"
                >
                  {{ selectedClient?.userWorkstation?.name }}
                </h5>
                <div
                  class="client_info tw-text-xs tw-font-medium tw-text-[#0c0f4a80] tw-leading-[16px] tw-tracking-[0.15px] tw-flex tw-gap-1 tw-items-center tw-mt-1"
                >
                  <span> Property Owner workstation </span>
                  |
                  <span>
                    <p class="">
                      <v-icon icon="mdi-map-marker" size="x-small" />{{
                        selectedClient?.toUser?.postcode || "N/A"
                      }}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <Button
      label="next"
      class="!tw-max-w-full !tw-hidden md:!tw-block"
      @click="$emit('onNext')"
      :isActive="isFormValid"
    />
    <AddPreferredContractorModal
      v-if="isShowContractorInviteModal"
      @onClickCloseTab="onClickToggleContractorInviteModal"
    />
    <CommonLoader
      :loading="isLoading"
      class="tw-absolute tw-left-1/2 tw-top-1/2 tw-translate-x-[-50%] tw-translate-y-[-50%]"
    />
  </section>
</template>
<script setup>
import { computed, reactive, ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import TradeNetworkCard from "@/modules/properties/components/Cards/TradeNetworkCard.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import { PROJECT_STORE } from "@/store/modules/project";
import ContractorInvitePreviewModal from "@/modules/properties/components/Modals/ContractorInvitePreviewModal.vue";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { debounce } from "lodash";
import { watch } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import AddPreferredContractorModal from "@/modules/preferred-contractors/components/AddPreferredContractorModal.vue";
import { HistoricalWorkFlowTypeEnum } from "@/core/enums/ProjectsEnum";
import { getImageStringToImageURL } from "@/core/utils/common";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const emits = defineEmits(["on-select-contractor", "onNext"]);
const props = defineProps({
  workflowType: { type: String, default: HistoricalWorkFlowTypeEnum.PROPERTY },
  selectedClient: {
    type: Object,
    default: null,
  },
  inviteContractors: {
    type: Array,
    default: () => [],
  },
});
const filters = reactive({
  search: "",
  postcode: "",
  radius: "",
  keywords: "",
  // specialism: [],
  // propertyCategory: null,
  // order: "ASC",
  status: "accepted",
});
const isLoading = ref(false);
const modifiedContractors = ref([]);
const selectedContractors = ref([]);
const listOfCategories = ref([]);
const selectedUserProfileImage = ref(null);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const invitedContractors = computed(
  () => store.getters[`${PROJECT_STORE}/invitedContractors`]
);

const tradePreferredContractorList = computed(() => {
  return store.getters[`${PREFERRED_CONTRACTORS}/getPreferredContractorsList`];
});

const propertyId = computed(() => route?.params?.propertyId);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const isShowContractorInviteModal = ref(false);
const onInvite = () => {
  console.log("onInvite");
};
const onClickToggleContractorInviteModal = () => {
  isShowContractorInviteModal.value = !isShowContractorInviteModal.value;
};
const checkIfContractorSelected = (id) => {
  return selectedContractors.value.some((contractor) => contractor.id === id);
};

const selectContractor = (event) => {
  const isExist = checkIfContractorSelected(event.id);

  // if (isExist) {
  //   selectedContractors.value = selectedContractors.value.filter(
  //     (contractor) => contractor.id !== event.id
  //   );
  // } else {
  //   if (!selectedContractors.value.length)
  //     selectedContractors.value.push(event);
  // }
  selectedContractors.value = [];
  selectedContractors.value.push(event);
  emits("on-select-contractor", selectedContractors.value);
};

const refetchPreferredContractors = debounce(async (state) => {
  isLoading.value = true;
  try {
    await store.dispatch(`${PREFERRED_CONTRACTORS}/fetchPreferredContractors`, {
      ...state,
      userId: user.value.id,
    });
  } finally {
    isLoading.value = false;
  }
}, 500);
watch(
  filters,
  (state) => {
    let params = JSON.parse(JSON.stringify({ ...state }));
    // if (!params.specialism?.length) {
    //   delete filters["specialism"];
    // } else {
    //   params.specialism = params.specialism.toString();
    // }
    params = Object.fromEntries(
      Object.entries(filters).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );

    refetchPreferredContractors(params);
  },
  { deep: true }
);
const initialize = async () => {
  try {
    isLoading.value = true;
    listOfCategories.value = await store.dispatch(
      `${USER_STORE}/getCategories`
    );

    let postData = JSON.parse(JSON.stringify({ ...filters }));
    postData = Object.fromEntries(
      Object.entries(filters).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );

    await store.dispatch(`${PREFERRED_CONTRACTORS}/fetchPreferredContractors`, {
      ...postData,
      userId: user.value.id,
    });
  } catch (error) {
    console.log(error, "error in fetching trade network");
  } finally {
    isLoading.value = false;
  }
};
const setHistoricalProjectUserProfile = async () => {
  selectedUserProfileImage.value = await getImageStringToImageURL(
    props.selectedClient?.userWorkstation?.profileImage
  );
};
onMounted(async () => {
  if (props.workflowType === HistoricalWorkFlowTypeEnum.PROPERTY) {
    await initialize();
  } else {
    setHistoricalProjectUserProfile();
  }
  if (props.inviteContractors?.length) {
    selectedContractors.value = props.inviteContractors;
  }
});
</script>
<style lang="scss" scoped>
.trade-network-container {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.input_field,
.input_field_selector {
  background-color: transparent;
  :deep(.v-input__control) {
    background-color: rgba($white, 1);
    --v-input-control-height: auto;
    --v-input-padding-top: 0;
    overflow: hidden;
    border: 1px solid rgba($buttonText, 0.4);
    border-radius: 8px;
    .v-field {
      padding: 13px 16px;
      .v-field__prepend-inner {
        .v-icon {
          opacity: 1;
          @include fluidFont(16, 16, 1);
        }
      }
      .v-field__field {
        .v-field__input {
          min-height: auto;
          padding: 0;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          left: 10px;
          &::placeholder {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.input_field_selector {
  :deep(.v-input__control) {
    .v-field {
      padding: 9px 16px;
      .v-field__field {
        .v-field__input {
          top: 5px;
        }
      }
    }
  }
}
</style>
