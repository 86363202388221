<template>
  <WizardModal
    :hideFooter="false"
    :hideStepper="true"
    :hasCustomActionFooter="true"
    :hideBackButton="false"
    :currentStep="2"
    :hideClose="true"
    :singleData="modelDetails"
    :showHeaderInMobile="true"
    :hideTitleAndDescription="true"
    :contentOverflowHidden="true"
    @on-back="onClickBackButton"
  >
    <template #content>
      <main
        class="tw-overflow-y-auto tw-flex tw-flex-col tw-h-full tw-min-h-[unset] tw-justify-between !tw-mt-0 rmd:!tw-mt-4 tw-gap-4 tw-pt-0 tw-box-border"
      >
        <section class="tw-flex tw-flex-col tw-h-full tw-gap-4 rmd:!tw-gap-8">
          <Text
            variant="h4"
            textWeight="400"
            class="rlg:!tw-text-[36px] rlg:!tw-leading-[42px]"
            >You're one small step away from pre-enrolling yourself on to the
            course!</Text
          >
          <div
            class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-pb-4 rmd:!tw-pb-8 tw-box-border tw-border-b-dashed-custom"
          >
            <div class="tw-w-full tw-flex-col tw-items-start tw-gap-1">
              <div class="tw-flex tw-items-center tw-gap-2">
                <v-icon
                  icon="mdi-check-circle"
                  color="#FAA500"
                  v-if="tcOptIn"
                ></v-icon>
                <v-icon icon="mdi-alert-circle" color="#FAA500" v-else></v-icon>
                <Text variant="h5">Attendance & Exams</Text>
              </div>
              <Text
                variant="h6"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >I understand that attendance for all consecutive 10 course days
                & passing all practical, written, and online exams are both
                required to successfully complete the course.</Text
              >
            </div>
            <div class="tw-w-full tw-flex-col tw-items-start tw-gap-1">
              <div class="tw-flex tw-items-center tw-gap-2">
                <v-icon
                  icon="mdi-check-circle"
                  color="#FAA500"
                  v-if="tcOptIn"
                ></v-icon>
                <v-icon icon="mdi-alert-circle" color="#FAA500" v-else></v-icon>
                <Text variant="h5">Putting Your Skills Into Practice</Text>
              </div>
              <Text
                variant="h6"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >I understand that I must complete and document one job
                placement after completing the course, which is necessary to
                receive my certification.</Text
              >
            </div>
            <div class="tw-w-full tw-flex-col tw-items-start tw-gap-1">
              <div class="tw-flex tw-items-center tw-gap-2">
                <v-icon
                  icon="mdi-check-circle"
                  color="#FAA500"
                  v-if="tcOptIn"
                ></v-icon>
                <v-icon icon="mdi-alert-circle" color="#FAA500" v-else></v-icon>
                <Text variant="h5"
                  >Entry Requirement & 3 Month Commitment
                  <Text
                    variant="h5"
                    textColor="#FAA500"
                    class="tw-cursor-pointer"
                    @click="onCheckEligibility"
                    >(Check Eligibility)</Text
                  ></Text
                >
              </div>
              <Text
                variant="h6"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >I understand that attendance for all consecutive 10 course days
                & passing all practical, written, and online exams are both
                required to successfully complete the course.</Text
              >
            </div>
          </div>
          <v-checkbox v-model="tcOptIn" color="#FAA500">
            <template v-slot:label>
              <Text
                variant="h5"
                lineHeight="18px"
                class="!tw-p-0 md:!tw-text-[14px]"
              >
                I have Read, Understood & Accept the above terms in full</Text
              >
            </template>
          </v-checkbox>
        </section>
      </main>
    </template>
    <template #footer-action>
      <section
        class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-full tw-gap-4 md:!tw-gap-[8px]"
      >
        <Button
          label="Reserve my spot"
          @click="onReserveSpot"
          :disabled="!tcOptIn"
          :loading="loading"
        />
        <!-- <div
          class="tw-w-full tw-pb-4 tw-flex tw-items-center tw-gap-2 tw-justify-center"
        >
          <Text
            variant="xsmall"
            class="md:!tw-leading-[12px] rmd:!tw-text-[16px]"
            lineHeight="18px"
            textAlign="center"
            >Read Full</Text
          >
          <div class="tw-flex tw-items-center tw-cursor-pointer">
            <Text
              variant="xsmall"
              class="md:!tw-leading-[12px] rmd:!tw-text-[16px]"
              lineHeight="18px"
              textAlign="center"
              textColor="#FFA500"
              >Terms & Conditions</Text
            >
            <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
          </div>
        </div> -->
      </section>
    </template>
  </WizardModal>
</template>
<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// import CommonDialog from "@/core/components/CommonDialog.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";

const emits = defineEmits([
  "on-close",
  "on-reserve-spot",
  "on-back",
  "on-check-elegibility",
]);
const props = defineProps(["loading"]);

const store = useStore();
const route = useRoute();
const router = useRouter();
const courseId = ref(null);
const courseEligibility = ref([]);

const isLoading = ref(false);
const isShowCertificateModal = ref(false);
const tcOptIn = ref(false);

const modelDetails = {
  id: 2,
  tab: "preEnrollTerms",
  name: "preEnrollTerms",
  header: "Is the course 100% for you?",
};

const onClickBackButton = () => {
  return emits("on-back");
};

const onCheckEligibility = () => {
  return emits("on-check-elegibility");
};
// const onCreateWorkstation = () => {
//   onClickBackButton();
//   store.dispatch(`${WORKSTATION}/setWsModal`, {
//     isShowWorkStationModal: true,
//   });
//   router.push({ name: DASHBOARD_ROUTE });
// };

const onReserveSpot = () => {
  emits("on-reserve-spot");
};

onBeforeMount(() => {
  //
});

onBeforeUnmount(() => {
  //   eligibility.value = null;
});
</script>
<style lang="scss" scoped></style>
