import { RolesEnum } from "@/core/enums/RolesEnum";
import { parseJSON } from "@/core/utils/common";
import GetStartBoardingRoute from "@/modules/get-start-boarding/pages/GetStartBoarding.vue";
import { LOGIN_ROUTE } from "@/modules/login/routes";

export const GET_START_BOARDING_ROUTE = "getStartBoarding";

export default {
  path: "/get-start-boarding",
  name: GET_START_BOARDING_ROUTE,
  component: GetStartBoardingRoute,
  meta: {
    layout: "AuthLayout",
  },
  beforeEnter: async (to: any, from: any, next: any) => {
    if (!to?.query?.startBoarding && !to?.query?.memberUserEmail)
      return next({ name: LOGIN_ROUTE });
    const startBoarding = parseJSON(
      decodeURIComponent(to?.query?.startBoarding)
    );
    if (
      (startBoarding?.userName &&
        [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON].includes(
          startBoarding?.role
        )) ||
      (to?.query?.memberUserEmail &&
        to?.query?.userType &&
        [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON].includes(
          Number(to.query.userType)
        ))
    ) {
      next();
    } else {
      next({ name: LOGIN_ROUTE });
    }
  },
};
