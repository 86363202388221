<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 11.75C7.5875 11.75 7.25 11.4125 7.25 11V8C7.25 7.5875 7.5875 7.25 8 7.25C8.4125 7.25 8.75 7.5875 8.75 8V11C8.75 11.4125 8.4125 11.75 8 11.75ZM8.75 5.75H7.25V4.25H8.75V5.75Z"
      fill="#0C0F4A"
      fill-opacity="0.3"
    />
  </svg>
</template>
<script>
export default {
  name: "InfoIcon",
};
</script>
