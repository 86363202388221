import $axios from "../utils/axios-api-config";

export default {
  getWorkstationsSettingDetails(userId: number, workstationId: number) {
    return $axios.get(
      `/payment/${userId}/${workstationId}/subscription-details`
    );
  },
  getInvoiceWorkstationsDetails(userId: number, workstationId: number) {
    return $axios.get(
      `/payment/${userId}/download-workstation-latest-invoices/${workstationId}`
    );
  },
  updateSubscriptionsPlan(
    userId: number,
    workstationId: number,
    planType: number
  ) {
    return $axios.put(`/payment/${userId}/subscriptions/change-plan`, {
      workstationId,
      planType,
    });
  },

  updateStorageSubscriptionPlan(
    userId: number,
    workstationId: number,
    planType: number
  ) {
    return $axios.put(`/payment/${userId}/storage-subscriptions/change-plan`, {
      workstationId,
      planType,
    });
  },

  downloadSubscriptionInvoice(
    userId: number,
    workstationId: number,
    invoiceItem: string
  ) {
    return $axios.get(
      `/payment/${userId}/download-workstation-latest-invoices/${workstationId}/${invoiceItem}`
    );
  },

  getCredits(userId: number, workstationId: number) {
    return $axios.get(`/payment/${userId}/${workstationId}/credit-details`);
  },
  getBillingHistoryDetails(userId: number) {
    return $axios.get(`/payment/${userId}/history`);
  },

  getAtmCards(userId: number) {
    return $axios.get(`/payment/${userId}/card-list`);
  },

  getAtmCard(userId: number, id: number) {
    return $axios.get(`/payment/${userId}/card/${id}`);
  },

  addAtmCard(userId: number, formData: any) {
    return $axios.post(`/payment/${userId}/add-card`, formData);
  },

  editAtmCard(userId: number, cardId: number, formData: any) {
    return $axios.put(`/payment/${userId}/update-card/${cardId}`, formData);
  },

  deleteAtmCard(userId: number, id: number) {
    return $axios.delete(`/payment/${userId}/card/${id}`);
  },
  getInvoiceBillingDetails(userId: number, id: number) {
    return $axios.get(`/payment/${userId}/receipt-download/${id}`);
  },
};
