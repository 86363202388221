<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="29" height="29" rx="4" fill="#24BD63" />
    <path
      d="M22.5245 17.0233C22.9904 16.551 23.2517 15.9143 23.2517 15.2508C23.2517 14.5874 22.9904 13.9506 22.5245 13.4783L21.6106 12.5736C21.4037 12.361 21.2878 12.0763 21.2875 11.7796V10.718C21.2853 10.0551 21.021 9.42005 20.5523 8.95134C20.0836 8.48263 19.4485 8.21834 18.7856 8.21615H17.724C17.4274 8.21582 17.1426 8.09991 16.93 7.89304L16.0253 6.97908C15.553 6.51317 14.9162 6.25195 14.2528 6.25195C13.5893 6.25195 12.9526 6.51317 12.4803 6.97908L11.5755 7.89304C11.363 8.09991 11.0782 8.21582 10.7816 8.21615H9.71994C9.05709 8.21834 8.42201 8.48263 7.95329 8.95134C7.48458 9.42005 7.2203 10.0551 7.2181 10.718V11.7796C7.21777 12.0763 7.10186 12.361 6.89499 12.5736L5.98104 13.4783C5.51512 13.9506 5.25391 14.5874 5.25391 15.2508C5.25391 15.9143 5.51512 16.551 5.98104 17.0233L6.89499 17.9281C7.10186 18.1406 7.21777 18.4254 7.2181 18.722V19.7837C7.2203 20.4465 7.48458 21.0816 7.95329 21.5503C8.42201 22.019 9.05709 22.2833 9.71994 22.2855H10.7816C11.0782 22.2858 11.363 22.4017 11.5755 22.6086L12.4803 23.5226C12.9519 23.9898 13.5889 24.252 14.2528 24.252C14.9167 24.252 15.5537 23.9898 16.0253 23.5226L16.93 22.6086C17.1426 22.4017 17.4274 22.2858 17.724 22.2855H18.7856C19.4485 22.2833 20.0836 22.019 20.5523 21.5503C21.021 21.0816 21.2853 20.4465 21.2875 19.7837V18.722C21.2878 18.4254 21.4037 18.1406 21.6106 17.9281L22.5245 17.0233ZM17.5116 13.8937L13.8189 17.5865C13.7547 17.6509 13.6785 17.702 13.5945 17.7369C13.5105 17.7718 13.4205 17.7897 13.3296 17.7897C13.2387 17.7897 13.1486 17.7718 13.0647 17.7369C12.9807 17.702 12.9045 17.6509 12.8403 17.5865L10.9939 15.7401C10.8716 15.6089 10.805 15.4353 10.8082 15.2559C10.8114 15.0765 10.884 14.9054 11.0109 14.7785C11.1378 14.6516 11.3089 14.579 11.4883 14.5758C11.6677 14.5727 11.8413 14.6392 11.9725 14.7615L13.3296 16.1186L16.5331 12.9152C16.6643 12.7929 16.8379 12.7263 17.0173 12.7294C17.1967 12.7326 17.3678 12.8053 17.4947 12.9321C17.6215 13.059 17.6942 13.2301 17.6974 13.4095C17.7005 13.5889 17.6339 13.7625 17.5116 13.8937Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "VerifyIcon",
};
</script>
