<template>
  <commonDialog
    v-model="isShowSiteVisitModal"
    :isShowHeader="false"
    :onClickBackButton="onClickBack"
    width="450px"
    max-width="100%"
    height="auto"
    persistent
    :className="['project_vist__modal_box']"
  >
    <template v-slot:body>
      <div class="action-icon">
        <img src="../../../assets/icons/white-filled-home-icon.svg" />
      </div>
      <div class="custom__modal__heading">
        <h4>Do you want to enable site visit in this project?</h4>

        <p class="description explore__desc">
          Once enabled, Contractors will be able to request site visits for
          properties listed in this project, if required.
        </p>
      </div>
      <v-select
        v-model="siteVisit"
        :items="siteRequestStatus"
        item-title="label"
        item-value="value"
        variant="outlined"
        class="project_vist__selector"
      ></v-select>
      <v-btn
        class="button button-purple btn_purple"
        size="large"
        block
        :loading="loading"
        @click="onClickPostProject"
      >
        Continue
      </v-btn>
    </template>
  </commonDialog>
</template>

<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import ProjectService from "@/core/services/project.service";
import { ref, defineEmits } from "vue";

const props = defineProps({
  projectId: {
    type: Number,
    default: null,
  },
  returnStatus: {
    type: Boolean,
    default: false,
  },
});
const isShowSiteVisitModal = ref(true);
const loading = ref(false);
const emit = defineEmits(["onClickBack", "onClickPostProject"]);
const siteRequestStatus = [
  {
    label: "Enable",
    value: true,
  },
  {
    label: "Disable",
    value: false,
  },
];
const siteVisit = ref(false);

const onClickBack = () => {
  emit("onClickBack");
};
const onClickPostProject = async () => {
  try {
    loading.value = true;
    if (props.returnStatus) {
      return emit("onClickPostProject", siteVisit.value);
    }
    if (props.projectId) {
      const response = await ProjectService.updateProjectSiteVisitStatus(
        props.projectId,
        {
          siteVisitStatus: siteVisit.value,
        }
      );
    }
    emit("onClickPostProject");
  } catch (error) {
    console.log();
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
