<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6 4.8C12.6 7.44668 10.4467 9.6 7.8 9.6C5.15332 9.6 3 7.44668 3 4.8C3 2.15332 5.15332 0 7.8 0C10.4467 0 12.6 2.15332 12.6 4.8ZM15.5988 17.2752C15.5502 14.192 13.6992 11.4316 10.8557 10.2182C10.698 10.1508 10.5199 10.1537 10.3658 10.227C8.74043 10.9875 6.85957 10.9875 5.23418 10.227C5.07949 10.1537 4.90137 10.1508 4.74434 10.2182C1.86211 11.4475 0 14.267 0 17.4C0 17.7316 0.268359 18 0.6 18H15C15.0035 17.9994 15.0082 17.9994 15.0117 18C15.3434 18 15.6117 17.7316 15.6117 17.4C15.6117 17.3572 15.6076 17.3156 15.5988 17.2752ZM17.4 8.4H15.6V6.6C15.6 6.26836 15.3316 6 15 6C14.6684 6 14.4 6.26836 14.4 6.6V8.4H12.6C12.2684 8.4 12 8.66836 12 9C12 9.33164 12.2684 9.6 12.6 9.6H14.4V11.4C14.4 11.7316 14.6684 12 15 12C15.3316 12 15.6 11.7316 15.6 11.4V9.6H17.4C17.7316 9.6 18 9.33164 18 9C18 8.66836 17.7316 8.4 17.4 8.4Z"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#0C0F4A",
  },
  fillOpacity: {
    type: String,
    default: "0.5",
  },
});
</script>

<script>
export default {
  name: "AvatarPlusIcon",
};
</script>
