import $axios from "../utils/axios-api-config";

export default {
  getCertificates() {
    return $axios.get("/certificates");
  },

  getCertificatesById(certificateId: number) {
    return $axios.get(`/certificates/${certificateId}`);
  },
};
