<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="18"
      height="18"
      rx="2"
      transform="matrix(1 0 0 -1 0.398438 18.7598)"
      fill="#F45454"
      fill-opacity="0.2"
    />
    <path
      d="M8.89844 6.25977C8.89844 5.98362 9.1223 5.75977 9.39844 5.75977C9.67458 5.75977 9.89844 5.98362 9.89844 6.25977L8.89844 6.25977ZM9.75199 13.6133C9.55673 13.8086 9.24015 13.8086 9.04488 13.6133L5.8629 10.4313C5.66764 10.2361 5.66764 9.91949 5.8629 9.72423C6.05817 9.52897 6.37475 9.52897 6.57001 9.72423L9.39844 12.5527L12.2269 9.72423C12.4221 9.52897 12.7387 9.52897 12.934 9.72423C13.1292 9.91949 13.1292 10.2361 12.934 10.4313L9.75199 13.6133ZM9.89844 6.25977L9.89844 13.2598L8.89844 13.2598L8.89844 6.25977L9.89844 6.25977Z"
      fill="#F45454"
    />
  </svg>
</template>
<script>
export default {
  name: "DownwardRedIcon",
};
</script>
