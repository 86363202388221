export const PREFERRED_CONTRACTORS_ROUTE = "preferred-contractors";

export default {
  path: "/preferred-contractors",
  name: PREFERRED_CONTRACTORS_ROUTE,
  component: () =>
    import("@/modules/preferred-contractors/pages/preferredContractors.vue"),
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Preferred Contractors",
  },
};
