<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.62675 4.80143H6.80382C6.95311 4.80143 7.08898 4.73249 7.18803 4.62154C7.28707 4.51005 7.34784 4.35709 7.34784 4.18906C7.34784 4.02102 7.28659 3.86806 7.18803 3.75657C7.08898 3.64509 6.95309 3.57668 6.80382 3.57668H2.62675C2.47746 3.57668 2.34111 3.64562 2.24254 3.75657C2.1435 3.86806 2.08225 4.02102 2.08225 4.18906C2.08225 4.35764 2.1435 4.51059 2.24254 4.62154C2.34158 4.73303 2.47747 4.80143 2.62675 4.80143ZM0 2.67879V9.32122C0 9.78333 0.167943 10.2029 0.437795 10.5072C0.707648 10.811 1.08086 11 1.49138 11H7.93876C8.34929 11 8.72201 10.8115 8.99235 10.5072C9.26221 10.2034 9.43015 9.78333 9.43015 9.32122V8.85803L10.4115 9.35784C10.589 9.44832 10.7742 9.48549 10.9569 9.47041C11.1407 9.45533 11.3196 9.38746 11.4837 9.26844C11.6478 9.14941 11.7761 8.99376 11.8646 8.81118C11.9531 8.6286 12 8.42286 12 8.2042V3.79581C12 3.57715 11.9531 3.37195 11.8646 3.18883C11.7766 3.00625 11.6478 2.85114 11.4837 2.73211C11.3196 2.61308 11.1411 2.54575 10.9569 2.52959C10.7742 2.51451 10.5895 2.55168 10.4115 2.64216L9.43015 3.14197V2.67878C9.43015 2.21667 9.26268 1.79712 8.99235 1.49281C8.7225 1.18905 8.34929 1 7.93876 1H1.49138C1.08086 1 0.708138 1.18851 0.437795 1.49281C0.167943 1.79656 0 2.21668 0 2.67879ZM6.80382 5.43415H2.62675C2.32196 5.43415 2.04541 5.29412 1.84493 5.06846C1.64446 4.84279 1.52006 4.53149 1.52006 4.18841C1.52006 3.84532 1.64446 3.53402 1.84493 3.30836C2.04541 3.08269 2.32196 2.94266 2.62675 2.94266H6.80382C7.1086 2.94266 7.38516 3.08269 7.58563 3.30836C7.78611 3.53402 7.91051 3.84586 7.91051 4.18841C7.91051 4.53149 7.78611 4.84279 7.58563 5.06846C7.38516 5.29412 7.10813 5.43415 6.80382 5.43415Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "VideoConsultationIcon",
};
</script>
