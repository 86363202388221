<template>
  <Card
    class="tw-border-[1px] tw-border-solid tw-border-[rgba(12,15,74,0.2)] tw-p-5 tw-min-h-[206px]"
  >
    <template #content>
      <section class="tw-flex-col-between tw-h-full">
        <div class="tw-flex-row-between">
          <VisaIcon v-if="props?.data?.display_brand === 'visa'" />
          <MasterIcon v-else />

          <div class="tw-flex tw-gap-2 tw-items-center">
            <Text
              variant="p"
              textWeight="400"
              class="!tw-cursor-pointer"
              @click="onEdit(data)"
              >Edit</Text
            >
            <el-divider direction="vertical"></el-divider>
            <Text
              variant="p"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.5)"
              class="tw-cursor-pointer"
              :class="{ '!tw-cursor-not-allowed': props?.data?.isActive }"
              @click="onDelete(data)"
              >Delete</Text
            >
          </div>
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-items-start tw-h-full">
          <div class="tw-flex tw-items-center tw-gap-2 tw-w-full">
            <Text variant="h5">************{{ props?.data?.last4 }}</Text>
            <el-divider direction="vertical"></el-divider>
            <Text variant="h5" textWeight="400"
              >Expires {{ formatMonth(props?.data?.exp_month) }}/{{
                formatYear(props?.data?.exp_year)
              }}</Text
            >
          </div>
          <!-- <Text variant="h5">Jacob Hardman</Text> -->
        </div>
        <div
          v-if="props?.data?.isActive"
          class="tw-w-full tw-pt-4 tw-flex tw-items-center tw-justify-start tw-border-t-dashed-custom"
        >
          <Text variant="p" textWeight="400" textAlign="left"
            >Primary Payment Method</Text
          >
        </div>
      </section>
    </template>
  </Card>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import VisaIcon from "@/core/components/icons/VisaIcon.vue";
import MasterIcon from "@/core/components/icons/MasterIcon.vue";

const emits = defineEmits(["on-edit, on-delete"]);
const props = defineProps(["data"]);

const formatMonth = (month) => {
  const monthNumber = parseInt(month, 10);

  if (monthNumber < 10) {
    return "0" + monthNumber;
  }
  return "" + monthNumber;
};

const formatYear = (year) => {
  const yearStr = year.toString();
  if (yearStr.length >= 2) {
    return yearStr.slice(-2);
  }
  return yearStr;
};

const onEdit = (data) => {
  emits("on-edit", data);
};

const onDelete = (data) => {
  if (data?.isActive) return;
  emits("on-delete", data);
};
</script>
<style lang="scss" scoped></style>
