import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54c0b3c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-team-member-modal" }
const _hoisted_2 = { class: "custom-dropdown__inner" }
const _hoisted_3 = { class: "custom-dropdown__menu" }
const _hoisted_4 = { class: "custom-dropdown__search" }
const _hoisted_5 = { class: "custom-dropdown__menu-inner" }
const _hoisted_6 = { class: "custom-dropdown__checkbox-group" }
const _hoisted_7 = { class: "custom-dropdown__checkbox-label" }
const _hoisted_8 = { class: "custom-dropdown__checkbox-image" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "select-team-member-modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_WorkStationProfileLogo = _resolveComponent("WorkStationProfileLogo")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_GeneralDialog = _resolveComponent("GeneralDialog")!

  return (_openBlock(), _createBlock(_component_GeneralDialog, {
    headerTitle: "Select Team Members",
    onOnClose: $setup.onCloseModal
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["custom-dropdown", { 'is-open': $data.isOpen }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "custom-dropdown__trigger",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.isOpen = !$data.isOpen))
            }, [
              _createElementVNode("p", null, _toDisplayString($setup.selectedMember.length
                    ? $setup.selectedMemberName
                    : "Please Select Member"), 1),
              _createVNode(_component_v_icon, {
                icon: "mdi-chevron-down",
                color: "#0C0F4A"
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_text_field, {
                  variant: "outlined",
                  placeholder: "Search Team Member",
                  "prepend-inner-icon": "mdi-magnify",
                  modelValue: $setup.search,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterTeamMemberList, (member) => {
                    return (_openBlock(), _createBlock(_component_v_checkbox, {
                      color: "#4F55F0",
                      key: member.id,
                      modelValue: $setup.selectedMember,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.selectedMember) = $event)),
                      value: member.toUser.id
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            (member.userWorkstation.profileImage)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: member.userWorkstation?.publicUrl,
                                  alt: member.userWorkstation.name,
                                  width: "20",
                                  height: "20"
                                }, null, 8, _hoisted_9))
                              : (_openBlock(), _createBlock(_component_WorkStationProfileLogo, {
                                  key: 1,
                                  WSName: member.userWorkstation.name
                                }, null, 8, ["WSName"]))
                          ]),
                          _createElementVNode("p", null, _toDisplayString(member.toUser.firstName) + " " + _toDisplayString(member.toUser.lastName), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "value"]))
                  }), 128))
                ])
              ])
            ])
          ])
        ], 2)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_v_btn, {
          class: "button button-purple-border",
          onClick: $setup.onCloseModal
        }, {
          default: _withCtx(() => [
            _createTextVNode("CANCEl")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_v_btn, {
          class: "button button-orange",
          onClick: $setup.onClickContinue,
          disabled: !$setup.selectedMember.length
        }, {
          default: _withCtx(() => [
            _createTextVNode("CONTINUE")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["onOnClose"]))
}