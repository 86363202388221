<template>
  <svg
    width="182"
    height="40"
    viewBox="0 0 182 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.0566 40.0013H28.3806V36.4498C28.4065 34.6302 27.729 32.872 26.4911 31.5461C25.9319 30.8842 25.231 30.3586 24.4409 30.0085C23.651 29.6585 22.7924 29.4932 21.9298 29.5252H20.9999V40.0013H13.1172V11.2331H17.0585H20.9999V21.9171H21.9298C22.7215 21.9453 23.5101 21.8036 24.2431 21.5013C24.9761 21.1989 25.6368 20.743 26.1812 20.1636C27.4318 18.9805 28.2134 17.3807 28.3806 15.6612V11.2034H36.0714V14.9479C36.0893 16.9689 35.6753 18.9702 34.8579 20.8161C34.0405 22.662 32.8384 24.3095 31.3329 25.6468C32.8438 27.0003 34.0469 28.6661 34.8596 30.5306C35.6723 32.395 36.0755 34.4138 36.0418 36.4498C36.0418 36.9102 36.0418 37.5046 36.0418 38.2627L36.0566 40.0013Z"
      fill="#4B4BFF"
    />
    <path
      d="M70.4992 40.0013H62.7678V25.9123C62.7833 24.559 62.4009 23.2307 61.6674 22.0904C60.934 20.95 59.8814 20.0473 58.6388 19.493C57.3963 18.9388 56.0179 18.7571 54.6729 18.9703C53.3279 19.1835 52.0749 19.7823 51.0675 20.6932C49.7686 21.8103 48.963 23.3898 48.8249 25.0913V39.9866H40.9904V25.7217C40.981 23.8011 41.3691 21.899 42.1306 20.1337C42.892 18.3683 44.0106 16.7772 45.4166 15.4593C48.1804 12.7332 51.9169 11.2034 55.811 11.2034C59.7055 11.2034 63.4417 12.7332 66.2055 15.4593C67.6081 16.8133 68.7132 18.4409 69.4521 20.2398C70.191 22.0387 70.5474 23.97 70.4992 25.9123V40.0013Z"
      fill="#4B4BFF"
    />
    <path
      d="M104.935 25.6085C104.968 27.4982 104.603 29.3745 103.864 31.1224C103.126 32.8702 102.028 34.453 100.638 35.7733C99.2699 37.1132 97.6428 38.1767 95.8505 38.9023C94.0585 39.6279 92.1364 40.0013 90.1952 40.0013C88.254 40.0013 86.3322 39.6279 84.5399 38.9023C82.7475 38.1767 81.1204 37.1132 79.7519 35.7733C78.3558 34.4563 77.2517 32.8751 76.5076 31.1269C75.7632 29.3786 75.3949 27.5006 75.4256 25.6085C75.397 23.7167 75.7659 21.8391 76.51 20.0911C77.254 18.3431 78.3572 16.7617 79.7519 15.4438C81.1184 14.1003 82.7448 13.0338 84.5375 12.306C86.3302 11.5781 88.253 11.2034 90.1952 11.2034C92.1374 11.2034 94.0606 11.5781 95.8529 12.306C97.6456 13.0338 99.272 14.1003 100.638 15.4438C102.026 16.7652 103.123 18.348 103.862 20.0956C104.601 21.8432 104.966 23.719 104.935 25.6085ZM97.2371 25.6085C97.2391 24.7105 97.0586 23.821 96.706 22.9911C96.3534 22.1612 95.8355 21.4075 95.1824 20.7731C94.5294 20.1388 93.7536 19.6365 92.9006 19.295C92.0471 18.9536 91.1327 18.7798 90.2102 18.7836C89.2944 18.7677 88.3852 18.9348 87.5382 19.2744C86.6916 19.6141 85.9251 20.1192 85.287 20.7585C84.6376 21.3946 84.1283 22.1526 83.7897 22.9864C83.4514 23.8202 83.2906 24.7123 83.3175 25.6085C83.3155 26.4983 83.494 27.3798 83.8425 28.2025C84.191 29.0252 84.7027 29.7728 85.3486 30.4027C85.9942 31.0326 86.7615 31.5324 87.606 31.8733C88.4502 32.2143 89.3558 32.3898 90.2698 32.3898C91.1907 32.4018 92.1047 32.2317 92.9561 31.8898C93.8074 31.5478 94.5788 31.0411 95.223 30.4004C95.8778 29.7784 96.3942 29.0321 96.7407 28.2079C97.0872 27.3836 97.2562 26.4989 97.2371 25.6085Z"
      fill="#4B4BFF"
    />
    <path
      d="M147.567 11.2034V28.7938C147.531 30.9368 146.882 33.0253 145.697 34.8147C144.511 36.6042 142.838 38.0202 140.872 38.8976C138.907 39.7746 136.731 40.0764 134.6 39.7679C132.469 39.4591 130.47 38.5524 128.838 37.1542C126.788 39.0288 124.093 40.0475 121.31 39.9997C119.817 40.0165 118.334 39.7369 116.95 39.1775C115.566 38.618 114.308 37.7897 113.25 36.7411C112.164 35.7587 111.3 34.558 110.715 33.2184C110.13 31.8789 109.837 30.4311 109.856 28.9708V11.2771H117.576V28.8528C117.576 29.807 117.957 30.7221 118.635 31.3968C119.313 32.0715 120.233 32.4505 121.192 32.4505C121.676 32.4597 122.157 32.3702 122.606 32.1877C123.054 32.0051 123.46 31.7333 123.799 31.3889C124.138 31.0592 124.406 30.6643 124.587 30.2284C124.768 29.7924 124.858 29.3244 124.852 28.8528V11.2623H132.572V29.2362C132.679 30.0811 133.102 30.8549 133.757 31.4036C134.426 31.9915 135.295 32.3028 136.187 32.2736C136.657 32.2822 137.123 32.1935 137.557 32.0133C137.99 31.8331 138.382 31.5652 138.706 31.2267C139.042 30.8808 139.305 30.472 139.481 30.0239C139.656 29.5759 139.741 29.0976 139.728 28.6169V11.2034H147.567Z"
      fill="#4B4BFF"
    />
    <path
      d="M181.998 39.9929V26.2637C181.998 26.0458 181.998 25.8278 181.998 25.6099C182.029 23.7195 181.663 21.8427 180.925 20.0942C180.186 18.3457 179.089 16.7621 177.701 15.4401C176.335 14.0959 174.708 13.0289 172.916 12.3007C171.123 11.5725 169.2 11.1975 167.258 11.1975C165.316 11.1975 163.393 11.5725 161.601 12.3007C159.808 13.0289 158.182 14.0959 156.815 15.4401C155.418 16.7565 154.313 18.3384 153.568 20.0878C152.824 21.8371 152.457 23.7166 152.489 25.6099C152.451 27.5039 152.816 29.385 153.561 31.1351C154.305 32.8852 155.413 34.4665 156.815 35.7799C158.164 37.1449 159.787 38.224 161.584 38.9489C163.382 39.6742 165.313 40.0293 167.258 39.9929C169.757 40.0221 172.48 37.6021 174.658 35.7799V39.9929H181.998ZM172.226 30.4043C171.58 31.042 170.808 31.5457 169.957 31.8853C169.106 32.2249 168.193 32.3932 167.273 32.3801C166.359 32.384 165.453 32.2115 164.608 31.8726C163.763 31.5337 162.995 31.0352 162.349 30.4058C161.702 29.7763 161.19 29.0284 160.842 28.2053C160.494 27.3822 160.317 26.5001 160.321 25.6099C160.304 24.7081 160.476 23.8123 160.828 22.9778C161.179 22.1433 161.702 21.3877 162.365 20.7574C163.003 20.1178 163.769 19.6125 164.616 19.2726C165.463 18.9328 166.372 18.7656 167.288 18.7816C168.21 18.7777 169.123 18.9517 169.976 19.2935C170.829 19.6353 171.603 20.1381 172.255 20.773C172.907 21.4078 173.423 22.1621 173.774 22.9923C174.125 23.8225 174.304 24.7121 174.3 25.6099C174.312 26.5051 174.134 27.3931 173.778 28.2181C173.42 29.0432 172.892 29.7875 172.226 30.4043Z"
      fill="#0D0922"
    />
    <path
      d="M8.19814 39.9954H0V11.1975H8.13718L8.19814 39.9954Z"
      fill="#0D0922"
    />
    <path
      d="M7.89717 5.53473C8.10376 5.04847 8.20603 4.52604 8.19767 3.9997C8.20603 3.47339 8.10376 2.95096 7.89717 2.46469C7.69062 1.97842 7.38413 1.53861 6.99659 1.1724C6.62129 0.794278 6.17057 0.495207 5.67223 0.293644C5.17385 0.0920814 4.63849 -0.00769861 4.09907 0.000463186C3.55966 -0.00769861 3.02427 0.0920814 2.52592 0.293644C2.02757 0.495207 1.57683 0.794278 1.20153 1.1724C0.81401 1.53861 0.507508 1.97842 0.300939 2.46469C0.0943693 2.95096 -0.00788987 3.47339 0.000474692 3.9997C-0.00788987 4.52604 0.0943693 5.04847 0.300939 5.53473C0.507508 6.02099 0.81401 6.46079 1.20153 6.82703C1.57683 7.20513 2.02757 7.50421 2.52592 7.70576C3.02427 7.90735 3.55966 8.00712 4.09907 7.99896C4.63849 8.00712 5.17385 7.90735 5.67223 7.70576C6.17057 7.50421 6.62129 7.20513 6.99659 6.82703C7.38413 6.46079 7.69062 6.02099 7.89717 5.53473Z"
      fill="#0D0922"
    />
  </svg>
</template>

<script>
export default {
  name: "AppIknowaLogo",
};
</script>
