import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c782d46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reply__commentbox__card" }
const _hoisted_2 = { class: "reply__commentbox__card__icons" }
const _hoisted_3 = { class: "left__icon" }
const _hoisted_4 = { class: "right__icon" }
const _hoisted_5 = {
  class: "reply__commentbox__section",
  ref: "commentContainer"
}
const _hoisted_6 = { class: "user__info" }
const _hoisted_7 = { class: "user__img" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "user__text" }
const _hoisted_10 = { class: "user__text__title" }
const _hoisted_11 = { class: "user__comment" }
const _hoisted_12 = { class: "reply__commentbox__edit__comment" }
const _hoisted_13 = ["id"]
const _hoisted_14 = {
  key: 0,
  class: "commentbox__card"
}
const _hoisted_15 = { class: "user__img" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "title" }
const _hoisted_18 = { class: "user__comment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_UserProfileLogo = _resolveComponent("UserProfileLogo")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    (
        $props.commentElement?.elementId === $props.activeComment?.elementId &&
        $setup.comments.length > 0
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "reply__commentbox",
          style: _normalizeStyle({
        top: $props.commentElement?.style?.y + 'px',
        left: $props.commentElement?.style?.x + 'px',
      })
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_icon, {
                  icon: "mdi-chevron-left",
                  disabled: $props.index == 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.nextComment($props.index, 'left')))
                }, null, 8, ["disabled"]),
                _createVNode(_component_v_icon, {
                  icon: "mdi-chevron-right",
                  disabled: $props.commentsLength - 1 == $props.index,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.nextComment($props.index, 'right')))
                }, null, 8, ["disabled"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                ($setup.isShowResolve($props.commentElement?.userWorkstation?.id))
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      icon: "mdi-check-circle-outline",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.resolveUserComments($props.commentElement?.id)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_icon, {
                  icon: "mdi-close",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.closeCommentBox()))
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.comments, (comment) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "reply__commentbox__card__text__top",
                  key: comment.id
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (comment?.userWorkstation?.profileImage)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: $setup.getWorkStationImagePublicUrl(comment?.userWorkstation),
                            alt: ""
                          }, null, 8, _hoisted_8))
                        : (_openBlock(), _createBlock(_component_UserProfileLogo, {
                            key: 1,
                            userName: comment?.userWorkstation?.name
                          }, null, 8, ["userName"]))
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h6", null, _toDisplayString(comment?.userWorkstation?.name), 1),
                        _createElementVNode("p", null, _toDisplayString($setup.timeCommentAgo(comment.dateCreated)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("p", null, _toDisplayString(comment.comment), 1)
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_v_text_field, {
              class: "messagechat__message",
              variant: "solo-filled",
              "hide-details": "",
              "single-line": "",
              flat: "",
              modelValue: $setup.commentText,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.commentText) = $event)),
              onKeydown: [
                _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["shift","stop"]), ["enter"])),
                _withKeys($setup.addNewComment, ["enter"])
              ],
              placeholder: "Reply"
            }, {
              "append-inner": _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: "mdi-chevron-right-circle",
                  onClick: $setup.addNewComment
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["modelValue", "onKeydown"])
          ])
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["commentbox", {
        commentbox__hover: $setup.isHoverComment,
      }]),
      ref: `user_comment_${$props.commentElement?.elementId}`,
      id: `user_comment_${$props.commentElement?.elementId}`,
      style: _normalizeStyle({
        top: $props.commentElement?.style?.y + 'px',
        left: $props.commentElement?.style?.x + 'px',
      }),
      onMouseover: _cache[7] || (_cache[7] = ($event: any) => ($setup.isHoverComment = true)),
      onMouseout: _cache[8] || (_cache[8] = ($event: any) => ($setup.isHoverComment = false))
    }, [
      ($props.commentElement?.userComments.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", {
              class: "commentbox__card__text__top",
              onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.openCommentBox()))
            }, [
              _createElementVNode("div", _hoisted_15, [
                ($props.commentElement?.userWorkstation?.profileImage)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: 
                $setup.getWorkStationImagePublicUrl($props.commentElement.userWorkstation)
              ,
                      alt: ""
                    }, null, 8, _hoisted_16))
                  : (_openBlock(), _createBlock(_component_UserProfileLogo, {
                      key: 1,
                      userName: $props.commentElement?.userWorkstation?.name
                    }, null, 8, ["userName"]))
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["user__text", {
              commentbox__user__text: $setup.isHoverComment,
            }])
              }, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("h6", null, _toDisplayString($props.commentElement?.userWorkstation?.name), 1),
                  _createElementVNode("p", null, _toDisplayString($setup.timeCommentAgo($props.commentElement?.userComments[0]?.dateCreated)), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("p", null, _toDisplayString($props.commentElement?.userComments[0]?.comment), 1)
                ])
              ], 2)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 46, _hoisted_13)
  ]))
}