<template>
  <GeneralDialog :isShowCloseButton="false" class="tw-w-[450px] tw-h-[430px]">
    <template #body>
      <main
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <Text variat="h5" textColor="#264FD5">{{ switchingText }}</Text>
        <div class="tw-w-[100px] tw-h-[100px] tw-overflow-hidden">
          <img
            class="tw-w-full tw-h-full"
            cover
            :src="require('@/assets/icons/' + switchingImage)"
          />
        </div>
        <v-stepper
          class="onboard-stepper"
          v-model="currentSelectedTabTradeVerified.name"
        >
          <v-stepper-header class="onboard-stepper__header">
            <v-stepper-item
              class="onboard-stepper__item"
              v-for="(step, index) in tabListItemsTradeVerified"
              :key="index"
              :value="step.name"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </main>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3">
        <Button
          v-if="currentSelectedTabTradeVerified.tab === 'step3'"
          :disabled="loading"
          :isLoading="loading"
          label="Okay"
          :isActive="true"
          @click="onSuccess"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { HistoricProjectStatusEnum } from "@/core/enums/ProjectsEnum";

const props = defineProps({
  projectId: {
    type: Number,
  },
});
const emits = defineEmits(["on-close", "on-success", "on-confirm"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const switchingImage = ref("home-verify.png");
const switchingText = ref("");
const tabListItemsTradeVerified = [
  {
    tab: "step1",
    name: "Project verified Successfully!",
  },
  {
    tab: "step2",
    name: "Project verified Successfully!",
  },
  {
    tab: "step3",
    name: "Project verified Successfully!",
  },
];
const currentSelectedTabTradeVerified = ref(tabListItemsTradeVerified[0]);

const onSuccess = () => {
  emits("on-success");
};

const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

onMounted(async () => {
  switchingText.value = "Project verified Successfully!";
  switchingImage.value = "user-blue-cap.png";
  currentSelectedTabTradeVerified.value = tabListItemsTradeVerified[0];

  // Second step: Wait for 2 seconds
  await delay(2000);
  await store.dispatch(`${PROJECT_STORE}/updateHistoricalProjectStatus`, {
    projectId: props?.projectId,
    status: { historicalProjectStatus: HistoricProjectStatusEnum.VERIFIED },
  });

  switchingImage.value = "home-verify.png";
  currentSelectedTabTradeVerified.value = tabListItemsTradeVerified[1];

  // Third step: Wait for 5 seconds
  await delay(5000);
  switchingImage.value = "check-blue.svg";
  currentSelectedTabTradeVerified.value = tabListItemsTradeVerified[2];
});
</script>
<style lang="scss" scoped>
:deep(.v-stepper-item) {
  width: 100%;
  max-width: 25px;
}

:deep(.v-stepper-item__content) {
  display: none;
}

:deep(.v-stepper-item--selected) {
  max-width: unset;
  .v-stepper-item__content {
    display: inline-block;
  }
}

@media screen and (min-width: 568px) {
  :deep(.v-stepper-item) {
    max-width: unset;
  }
  :deep(.v-stepper-item__content) {
    display: inline-block;
  }
}
</style>
