<template>
  <div
    class="rating-chip tw-flex tw-items-center tw-justify-center tw-h-[36px] tw-box-border"
  >
    <Text variant="h6" :textColor="textColor">{{ score }}</Text>
  </div>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  bgColor: {
    type: String,
    default: "#007F3D",
  },
  textColor: {
    type: String,
    default: "#FFF",
  },
  score: String,
  width: {
    type: String,
    default: "100%",
  },
});
</script>
<style lang="scss" scoped>
.rating-chip {
  background: v-bind("props.bgColor");
  box-shadow: 0px 0px 20px 0px #0c0f4a1a;
  width: v-bind("props.width");
  padding: 0.7rem;
  box-sizing: border-box;
  border-radius: 55% 8px 8px 55%;
}
</style>
