import { Commit } from "vuex";
import CertificateService from "@/core/services/certificates.service";

export default {
  async getAllCertificates({ commit }: { commit: Commit }) {
    return CertificateService.getCertificates().then((response) => {
      commit("setCertificates", response);
    });
  },
};
