import MemberService from "@/core/services/manage-member.service";
import {
  InviteMember,
  TeamMember,
  UpdateMemberDetails,
} from "@/core/models/manage-member";
import { Commit } from "vuex";

export default {
  async inviteMember(
    { state, commit }: any,
    payload: { members: InviteMember; userId: number }
  ) {
    return MemberService.inviteMember(payload.members, payload.userId).then(
      (response) => {
        return response;
      }
    );
  },
  async getTeamMember({ commit }: { commit: Commit }, payload: TeamMember) {
    const { userId } = payload;
    if (userId)
      return MemberService.getTeamMember(userId).then((response) => {
        return response;
      });
  },
  async updateMemberDetails(
    { state, commit }: any,
    payload: {
      members: UpdateMemberDetails;
      userWorkstationMemberId: number;
      userId: number;
    }
  ) {
    return MemberService.updateMemberDetails(
      payload.members,
      payload.userWorkstationMemberId,
      payload.userId
    ).then((response) => {
      return response;
    });
  },
  async deleteMemberDetails(
    { state, commit }: any,
    payload: {
      userMemberId: number;
      userId: number;
    }
  ) {
    return MemberService.deleteMemberDetails(
      payload.userMemberId,
      payload.userId
    ).then((response) => {
      return response;
    });
  },
};
