<template>
  <div class="profile__wrapper_main md:!tw-max-h-[unset]">
    <div class="profile__wrapper">
      <div class="profile__wrapper-upload">
        <div class="upload-wrapper">
          <v-file-input
            append-inner-icon="mdi-image-plus"
            class="upload_file"
            label="Upload"
            variant="solo-filled"
            :class="{ 'added-img': imageUrl }"
            @change="onFileChange"
            @click:clear="clearImageUrl"
            append-icon="mdi-upload"
          >
            <v-icon icon="mdi mdi-file-image-box"></v-icon>
          </v-file-input>
          <v-img
            class="uploaded-img"
            :width="200"
            aspect-ratio="16/9"
            cover
            :src="imageUrl ? imageUrl : userImage"
          ></v-img>
        </div>
      </div>
      <div class="personal__details__input">
        <v-form
          ref="personalDetailsForm"
          @submit.prevent
          :rules="personalDetailsFormRules"
        >
          <div class="form-field">
            <div class="name_form">
              <h5 class="title">Personal Details</h5>
              <div class="form_input">
                <label>First Name</label>
                <v-text-field
                  bg-color="rgba(12, 15, 74, 0.0509803922)"
                  density="compact"
                  variant="outlined"
                  class="c-input , rounded-full"
                  autocomplete="false"
                  placeholder="First Name"
                  rounded
                  outlined="false"
                  v-model="userForm.firstName"
                  :rules="personalDetailsFormRules.firstName"
                ></v-text-field>
              </div>
              <div class="form_input">
                <label class="">Last Name</label>
                <v-text-field
                  bg-color="rgba(12, 15, 74, 0.0509803922)"
                  density="compact"
                  variant="outlined"
                  class="c-input , rounded-full"
                  autocomplete="false"
                  placeholder="Last Name"
                  rounded
                  outlined="false"
                  v-model="userForm.lastName"
                  :rules="personalDetailsFormRules.lastName"
                ></v-text-field>
              </div>
              <div class="form_input">
                <div class="gender__field">
                  <label class="">Gender</label>
                  <v-radio-group
                    :rules="personalDetailsFormRules.gender"
                    v-model="userForm.gender"
                    inline
                  >
                    <v-radio label="Male" value="male"></v-radio>
                    <v-radio label="Female" value="female"></v-radio>
                    <v-radio label="Other" value="others"></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
            <div class="contact_form">
              <h5 class="title">Contact Details</h5>
              <div class="form_input">
                <label class="">Phone Number</label>
                <div class="contact_input">
                  <v-autocomplete
                    bg-color="rgba(12, 15, 74, 0.0509803922)"
                    v-model="userForm.countryCode"
                    :items="filteredCountries"
                    item-title="name"
                    item-value="dial_code"
                    @update:modelValue="handleCountryCode"
                    density="compact"
                    variant="outlined"
                    class="c-input rounded-full flag-selector"
                    autocomplete="false"
                    @keypress.prevent
                    :readOnly="true"
                    :menu-props="{
                      maxHeight: '400px',
                      width: '300px',
                    }"
                    rounded
                    :close-on-click-outside="false"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-text-field
                          class="sticky-search-bar"
                          v-model="searchQuery"
                          label="Search"
                          prepend-icon="mdi-magnify"
                          single-line
                          hide-details
                          dense
                          variant="outlined"
                        />
                      </v-list-item>
                    </template>
                    <template v-slot:chip="{ props, item }">
                      <flag
                        v-bind="props"
                        style="font-size: 50px; width: 100%"
                        :iso="item.raw.code"
                      />
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                        class="country__selector"
                      >
                        <flag
                          v-bind="props"
                          style="font-size: 16px"
                          :iso="item.raw.code"
                      /></v-list-item>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    bg-color="rgba(12, 15, 74, 0.0509803922)"
                    type="text"
                    density="compact"
                    placeholder="Phone Number"
                    variant="solo"
                    v-model="userForm.phoneNumber"
                    :rules="personalDetailsFormRules.phoneNumber"
                    rounded
                  ></v-text-field>
                </div>
              </div>

              <div class="form_input not-active">
                <label>Email Address</label>
                <v-text-field
                  bg-color="rgba(12, 15, 74, 0.0509803922)"
                  class="input_field disabled c-input , rounded-full"
                  density="compact"
                  variant="outlined"
                  rounded
                  size="lg"
                  outlined="false"
                  placeholder="Email address"
                  disabled
                  v-model="userForm.email"
                ></v-text-field>
              </div>
            </div>
          </div>
          <v-btn
            class="button button-orange"
            size="large"
            block
            :loading="loading"
            @click.enter="onClickSaveDetails"
          >
            SAVE DETAILS
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { countryinfo } from "../../../core/utils/countryinfo";
import { isValidPhoneNumber } from "libphonenumber-js";
export default {
  setup() {
    const store = useStore();
    const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
    const internalInstance = getCurrentInstance();
    const loading = ref(false);
    const imageUrl = ref("");
    let imageData = ref("");
    const searchQuery = ref("") as any;
    const userForm = ref({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      gender: "",
      countryCode: null as any,
    });
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const personalDetailsForm = ref(null) as any;

    const filteredCountries = computed(() => {
      return countryinfo.filter((country) => {
        if (searchQuery.value.trim() === "") {
          return true;
        }
        return country.name
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      });
    });

    onBeforeMount(async () => {
      userForm.value.firstName = user.value?.firstName;
      userForm.value.lastName = user.value?.lastName;
      userForm.value.phoneNumber =
        user.value?.contactNo === null ? "" : user.value?.contactNo;
      userForm.value.email = user.value?.email;
      userForm.value.gender = user.value?.gender;
      const countryCode = countryinfo.find((country) => {
        const countryCodeRegex = /^\+(\d+)/; // Regex to extract country code from contact number
        if (user.value?.contactNo) {
          const match = user.value?.contactNo.match(countryCodeRegex);
          if (match && match[1]) {
            return country.dial_code === "+" + match[1];
          }
          return false;
        }
      });

      // Set the country code in userForm.value
      userForm.value.countryCode = countryCode?.dial_code;
    });
    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        imageUrl.value = URL.createObjectURL(file);
        imageData.value = file;
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
    };
    const personalDetailsFormRules = ref({
      firstName: [(v: string) => !!v || "First Name is required"],
      lastName: [(v: string) => !!v || "Last Name is required"],
      phoneNumber: [
        (v: string) => true,
        (v: string) =>
          !v ||
          /^\+\d{1,3}\s?/.test(userForm.value.countryCode + v) ||
          "Please select country code.",

        (v: string) => {
          if (v) {
            const countryCodeVal: any = countryinfo.find(
              (country: any) => country.dial_code === userForm.value.countryCode
            )?.code;
            const phoneNumber = isValidPhoneNumber(v, countryCodeVal);
            return phoneNumber || "Invalid Phone Number";
          }
          return true;
        },
      ],
      gender: [(v: string) => !!v || "Gender is required"],
    });
    const handleCountryCode = () => {
      if (userForm.value.phoneNumber) {
        userForm.value.phoneNumber = "";
      }
      if (!userForm.value.countryCode) {
        userForm.value.countryCode = "";
      }
      userForm.value.phoneNumber =
        userForm.value.countryCode + " " + userForm.value.phoneNumber;
    };
    const onClickSaveDetails = async (e: any) => {
      e.preventDefault();
      try {
        loading.value = true;
        if (imageData.value) {
          const formData = new FormData();
          formData.append("file", imageData.value);
          const response = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
          await store.dispatch(`${USER_STORE}/saveUserProfile`, {
            userId: user.value.id,
            profile: {
              profileImage: response?.attachment,
            },
          });
          await store.dispatch(
            `${USER_STORE}/saveUserImage`,
            response?.publicUrl
          );
        }
        const isFormValid = await personalDetailsForm.value.validate();
        if (isFormValid.valid) {
          await store.dispatch(`${USER_STORE}/updateUser`, {
            userId: user.value?.id,
            userData: {
              firstName: userForm.value.firstName,
              lastName: userForm.value.lastName,
              contactNo: userForm.value.phoneNumber,
              gender: userForm.value.gender,
            },
          });
          displayToast("Profile Updated Successfully.", "success");
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };
    return {
      userImage,
      imageUrl,
      onFileChange,
      clearImageUrl,
      userForm,
      personalDetailsFormRules,
      personalDetailsForm,
      handleCountryCode,
      countryinfo,
      onClickSaveDetails,
      loading,
      searchQuery,
      filteredCountries,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/profile/styles/profile.scss";

:deep(.v-list) {
  padding-top: 30px;
}

:deep(.v-list-item__content) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 12px;
}

:deep(.sticky-search-bar) {
  .v-input__prepend {
    position: absolute;
    top: 0;
    left: 5px;
    width: 50px;
    height: 100%;

    .v-icon {
      font-size: 18px;
    }
  }

  .v-field__input {
    min-height: 1px;
    height: auto;
    padding: 8px 28px !important;
  }

  .v-label.v-field-label {
    margin-left: 32px;
  }
}
</style>
