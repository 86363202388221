<template>
  <ChipState :hasCustomLabel="true" bgColor="#FFF" :hasShadow="true">
    <template #prefix>
      <component :is="styling?.icon" class="tw-max-h-4 tw-max-w-4" />
    </template>
    <template #custom-label>
      <Text variant="span" :textColor="styling?.textColor" whiteSpace="nowrap">
        {{ styling?.coloredLabel }}
        <Text variant="span" whiteSpace="nowrap">{{
          styling?.normalLabel
        }}</Text>
      </Text>
    </template>
  </ChipState>
</template>
<script setup>
import { computed } from "vue";

import ChipState from "@/core/components/common/ChipState";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import InfoIconRed from "@/core/components/icons/InfoIconRed.vue";
import PendingCheck from "@/core/components/icons/PendingCheck.vue";
import Text from "@/core/components/ui/general/Text.vue";

const info = InfoIconRed;
const pending = PendingCheck;
const check = CheckGreen;

const props = defineProps({
  variant: {
    type: String,
    default: "", // required, pending, verified
  },
});

const styling = computed(() => {
  if (props.variant === "required") {
    return {
      textColor: "#FA4B00",
      icon: info,
      coloredLabel: "PROFILE VERIFICATION",
      normalLabel: "REQUIRED",
    };
  }
  if (props.variant === "pending") {
    return {
      textColor: "#FAA500",
      icon: pending,
      coloredLabel: "PROFILE VERIFICATION",
      normalLabel: "PENDING",
    };
  }
  if (props.variant === "verified") {
    return {
      textColor: "#1FB27A",
      icon: check,
      coloredLabel: "VERIFIED",
      normalLabel: "PROFILE",
    };
  }

  if (props.variant === "PO-UNVERIFIED") {
    return {
      textColor: "#FA4B00",
      icon: info,
      coloredLabel: "",
      normalLabel: "UNVERIFIED",
    };
  }

  if (props.variant === "PO-PENDING") {
    return {
      textColor: "#FAA500",
      icon: pending,
      coloredLabel: "",
      normalLabel: "PENDING",
    };
  }

  if (props.variant === "PO-VERIFIED") {
    return {
      textColor: "#1FB27A",
      icon: check,
      coloredLabel: "VERIFIED",
      normalLabel: "PROFILE",
    };
  }

  return {};
});
</script>
<script>
export default {
  name: "ProfileVerificationStatusChip",
};
</script>
