import { PropertyMapLayerType } from "../enums/PropertiesEnum";
import $axios from "../utils/axios-api-config";

export default {
  createPropertyNewLayer(payload: {
    propertyId: number;
    latLang: {
      lat: number;
      lng: number;
    };
  }) {
    return $axios.post(`/property_map_layers`, payload);
  },
  getPropertyMapLayersByPropertyId(propertyId: number) {
    return $axios.get(`/property_map_layers/layers/${propertyId}`);
  },
  createPropertyLayerData(payload: {
    layerDetails: [
      {
        propertyLayerId: number;
        type: PropertyMapLayerType;
        coordinates: Array<any>;
        meta: any;
      }
    ];
  }) {
    return $axios.post(`/property_map_layer_info`, payload);
  },
  getPropertyMapLayerData(layerId: number) {
    return $axios.get(`/property_map_layers/${layerId}`);
  },
  deletePropertyLayer(layerId: number) {
    return $axios.delete(`/property_map_layers/layer/${layerId}`);
  },
};
