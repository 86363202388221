<template>
  <CollapsibleWidgetSlot title="Safety Checks">
    <template #content>
      <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border">
        <div
          class="recommendation-card"
          v-for="standard in getArrangeAndMergeLists"
          :key="standard.name"
        >
          <div class="recommendation-card__header">
            <GasIcon
              v-if="standard.name === AddSafetyModalTitleEnum.GAS_TITLE"
              :fill="standard?.status?.color || 'rgba(12, 15, 74, 0.5)'"
            />
            <ElectricalIcon
              v-if="standard.name === AddSafetyModalTitleEnum.ELECTRICAL_TITLE"
              :fill="standard?.status?.color || 'rgba(12, 15, 74, 0.5)'"
            />
            <WaterTapIcon
              v-if="standard.name === AddSafetyModalTitleEnum.WATER_TITLE"
              :fill="standard?.status?.color || 'rgba(12, 15, 74, 0.5)'"
            />
            <h2>{{ standard.name }}</h2>
          </div>

          <div class="recommendation-card__body">
            <div
              v-if="standard.status"
              class="recommendation-card__status"
              :style="{ 'background-color': standard.status.color }"
            >
              {{ standard.status.label }}
            </div>

            <div
              v-if="standard.status"
              class="recommendation-card__divider"
            ></div>
            <v-btn
              v-if="standard.status"
              :ripple="false"
              class="bright-blue-text"
              variant="text"
              append-icon="mdi-chevron-right"
              @click="onClickManageSafetyDetail(standard)"
            >
              Manage
            </v-btn>
            <v-btn
              v-else
              :ripple="false"
              class="bright-blue-text"
              variant="text"
              append-icon="mdi-chevron-right"
              @click="openAddNewSafetyModal(standard.name)"
            >
              Add Details
            </v-btn>
          </div>
        </div>
        <div>
          <v-btn
            class="button button-orange"
            width="100%"
            @click="
              openAddNewSafetyModal(AddSafetyModalTitleEnum.CREATE_NEW_SAFETY)
            "
            >Add new safety check</v-btn
          >
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>
  <AddNewSafetyModal
    v-if="isShowSafetyModal"
    @closeModal="closeAddSafetyModal"
    :modalTitle="currentAddSafetyModalTitle"
  />

  <SafetyCheckDetailModal
    v-if="isShowSafetyDetailModal"
    :safetyDetail="currentSafetyDetail"
    @on-action="onSafetyAction"
    @on-close="closeSafetyDetailModal"
  />
  <ComplianceRecordModal
    v-if="isShowComplianceRecordModal"
    :safetyDetail="currentSafetyDetail"
    @on-close="closeComplianceRecordModal"
  />
  <PropertyHeatServicePlanModal
    v-if="isShowServicePlanModal"
    @on-close="onToggleServicePlanModal"
  />
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ComplianceRecordModal from "@/modules/properties/components/Modals/ComplianceRecordModal.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import GasIcon from "@/core/components/icons/GasIcon.vue";
import ElectricalIcon from "@/core/components/icons/ElectricalIcon.vue";
import WaterTapIcon from "@/core/components/icons/WaterTapIcon.vue";
import AddNewSafetyModal from "@/modules/properties/components/Modals/AddNewSafetyModal.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import SafetyCheckDetailModal from "@/modules/properties/components/Modals/SafetyCheckDetailModal.vue";
import { AddSafetyModalTitleEnum } from "@/core/enums/PropertiesEnum";
import PropertyHeatServicePlanModal from "@/modules/properties/components/Modals/PropertyHeatServicePlanModal.vue";
const actionType = {
  GET_PLAN: "get heat pump plan",
  ADD_RECORD: "add record",
  VIEW_DETAIL: "view detail",
};
const store = useStore();
const isShowSafetyDetailModal = ref(false);
const isShowServicePlanModal = ref(false);
const isShowComplianceRecordModal = ref(false);
const isShowSafetyModal = ref(false);
const currentAddSafetyModalTitle = ref();
const currentSafetyDetail = ref();
const defaultSafetyStandardList = [
  {
    id: 1,
    name: AddSafetyModalTitleEnum.GAS_TITLE,
    status: null,
  },
  {
    id: 2,
    name: AddSafetyModalTitleEnum.ELECTRICAL_TITLE,
    status: null,
  },
  {
    id: 3,
    name: AddSafetyModalTitleEnum.WATER_TITLE,
    status: null,
  },
];
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const propertySafetyCheckList = computed(
  () => store.getters[`${PROPERTY_STORE}/getPropertySafetyCheckList`]
);

const getArrangeAndMergeLists = computed(() => {
  const titleMap = new Map(
    propertySafetyCheckList.value.map((item) => [item.name, item])
  );
  const arrangedList = defaultSafetyStandardList.map((defaultItem) => {
    const matchingItem = titleMap.get(defaultItem.name);
    titleMap.delete(defaultItem.name);
    if (matchingItem) {
      return matchingItem;
    } else {
      return defaultItem;
    }
  });
  return [...arrangedList, ...Array.from(titleMap.values())];
});

const openAddNewSafetyModal = (title) => {
  if (!title) return;
  currentAddSafetyModalTitle.value = title;
  isShowSafetyModal.value = true;
};

const closeAddSafetyModal = () => {
  isShowSafetyModal.value = false;
  currentAddSafetyModalTitle.value = null;
};

const onClickManageSafetyDetail = (safetyStandardItem) => {
  currentSafetyDetail.value = safetyStandardItem;
  isShowSafetyDetailModal.value = true;
};

const closeSafetyDetailModal = () => {
  isShowSafetyDetailModal.value = false;
};
const closeComplianceRecordModal = () => {
  isShowComplianceRecordModal.value = false;
};
const showComplianceRecordModal = () => {
  isShowComplianceRecordModal.value = true;
};
const onToggleServicePlanModal = () => {
  isShowServicePlanModal.value = !isShowServicePlanModal.value;
};

const onSafetyAction = (type) => {
  closeSafetyDetailModal();
  if (type === actionType.VIEW_DETAIL) {
    showComplianceRecordModal();
  } else if (type === actionType.ADD_RECORD) {
    openAddNewSafetyModal(currentSafetyDetail.value.name);
  } else if (type === actionType.GET_PLAN) {
    onToggleServicePlanModal();
  }
};

onMounted(async () => {
  if (propertyDetails.value?.id) {
    await store.dispatch(
      `${PROPERTY_STORE}/getPropertySafetyCheck`,
      propertyDetails.value.id
    );
  }
});
</script>
<style lang="scss" scoped>
.recommendation-card {
  background-color: #f1f6fb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 0.66px dashed #8687a5;

    h2 {
      @include fluidFont(14, 14, 20px);
      color: $blueDark;
    }
  }

  &__status {
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 128px;
    border-radius: 4px;
    padding: 5px;
    color: #fff;
    font-weight: 600;
    background-color: #036700;
    @include fluidFont(12, 12, 15px);
    letter-spacing: 0.24px;

    &.is-attention-required {
      background-color: #036700;
    }

    &.is-expiring-soon {
      background-color: #9dcb3c;
    }
    &.is-expired {
      background-color: #ff3636;
    }

    &.is-valid {
      background-color: #2c9f29;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    @media (min-width: 768px) and (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
    }

    :deep(.v-btn) {
      padding: 0;
      height: auto;
      min-height: 1px;
      text-transform: none;
      color: rgb($orange, 1);
      font-weight: 600;
      @include fluidFont(11, 11, 12px);

      .v-btn__overlay,
      .v-btn__underlay {
        display: none;
      }

      .v-btn__append {
        margin-inline: 0;
      }
    }
  }

  &__divider {
    height: 20px;
    width: 1px;
    flex-shrink: 0;
    background-color: rgba(12, 15, 74, 0.54);
    margin: 0 auto;

    @media (min-width: 768px) and (max-width: 992px) {
      display: none;
    }
  }
}
</style>
