<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_36_16964)">
      <path
        d="M58.6667 38.7653V34.6667C58.6667 28.784 53.8827 24 48 24C42.1173 24 37.3333 28.784 37.3333 34.6667V38.7653C34.1467 40.6133 32 44.0613 32 48V53.3333C32 59.216 36.784 64 42.6667 64H53.3333C59.216 64 64 59.216 64 53.3333V48C64 44.0613 61.8533 40.6133 58.6667 38.7653ZM48 54.6667C45.792 54.6667 44 52.8747 44 50.6667C44 48.4587 45.792 46.6667 48 46.6667C50.208 46.6667 52 48.4587 52 50.6667C52 52.8747 50.208 54.6667 48 54.6667ZM53.3333 37.3333H42.6667V34.6667C42.6667 31.7253 45.0587 29.3333 48 29.3333C50.9413 29.3333 53.3333 31.7253 53.3333 34.6667V37.3333ZM21.3333 32C30.1573 32 37.3333 24.824 37.3333 16C37.3333 7.176 30.1573 0 21.3333 0C12.5093 0 5.33333 7.176 5.33333 16C5.33333 24.824 12.5093 32 21.3333 32ZM26.6667 53.3333C26.6667 57.4267 28.2133 61.168 30.752 64H2.66667C1.19467 64 0 62.8053 0 61.3333V59.3893C0 48.416 7.98133 38.696 18.888 37.472C21.448 37.184 23.9307 37.3547 26.264 37.9093C27.9067 38.2987 28.6293 40.1467 27.9627 41.696C27.1307 43.632 26.6667 45.7627 26.6667 48V53.3333Z"
        fill="#0C0F4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_36_16964">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LockPageIcon",
};
</script>
