import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e572280e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calender_content md:!tw-pb-16 md:!tw-box-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_PersonalCalendarComponent = _resolveComponent("PersonalCalendarComponent")!
  const _component_ProjectCalendar = _resolveComponent("ProjectCalendar")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      class: "tab-list",
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
      "align-tabs": "center",
      stacked: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendarTabList, (tabItem) => {
          return (_openBlock(), _createBlock(_component_v_tab, {
            key: tabItem.name,
            value: tabItem.name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: tabItem.icon
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(tabItem.label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTab) = $event)),
      touch: false
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendarTabList, (tabValue) => {
          return (_openBlock(), _createBlock(_component_v_window_item, {
            key: tabValue.name,
            value: tabValue.name
          }, {
            default: _withCtx(() => [
              (tabValue.name === 'personal-calendar')
                ? (_openBlock(), _createBlock(_component_PersonalCalendarComponent, { key: 0 }))
                : _createCommentVNode("", true),
              (
            tabValue.name === 'project-calendar' &&
            _ctx.selectedTab === 'project-calendar'
          )
                ? (_openBlock(), _createBlock(_component_ProjectCalendar, {
                    key: 1,
                    calendarType: "project"
                  }))
                : _createCommentVNode("", true),
              (
            tabValue.name === 'team-calendar' && _ctx.selectedTab === 'team-calendar'
          )
                ? (_openBlock(), _createBlock(_component_ProjectCalendar, {
                    key: 2,
                    calendarType: "team"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}