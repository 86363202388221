export default {
  setListingAmount(state: { listingAmount: any }, payload: any) {
    state.listingAmount = payload;
  },
  setHasPropertyListing(state: { hasPropertyListing: any }, payload: any) {
    state.hasPropertyListing = payload;
  },
  setTotalMember(state: { totalMember: any }, payload: any) {
    state.totalMember = payload;
  },
  setAddedMember(state: { addedMember: any }, payload: any) {
    state.addedMember = payload;
  },
  setPaymentMethod(state: { paymentMethod: any }, payload: any) {
    state.paymentMethod = payload;
  },
  setStoragePlan(state: { storagePlan: any }, payload: any) {
    state.storagePlan = payload;
  },

  setAccumulatedSize(state: { accumulatedSize: any }, payload: any) {
    state.accumulatedSize = payload;
  },

  setDefaultTrialDayse(state: { defaultTrialDays: any }, payload: any) {
    state.defaultTrialDays = payload;
  },

  setDefaultPropertyBillingAmount(
    state: { defaultPropertyBillingAmount: any },
    payload: any
  ) {
    state.defaultPropertyBillingAmount = payload;
  },
};
