<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 4.33333H9.33333C9.7 4.33333 10 4.03333 10 3.66667C10 3.3 9.7 3 9.33333 3H7.33333C6.96667 3 6.66667 3.3 6.66667 3.66667C6.66667 4.03333 6.96667 4.33333 7.33333 4.33333ZM7.33333 9H9.33333C9.7 9 10 8.7 10 8.33333C10 7.96667 9.7 7.66667 9.33333 7.66667H7.33333C6.96667 7.66667 6.66667 7.96667 6.66667 8.33333C6.66667 8.7 6.96667 9 7.33333 9ZM10.6667 12H1.33333C0.6 12 0 11.4 0 10.6667V1.33333C0 0.6 0.6 0 1.33333 0H10.6667C11.4 0 12 0.6 12 1.33333V10.6667C12 11.4 11.4 12 10.6667 12ZM2.66667 5.33333H4.66667C5.03333 5.33333 5.33333 5.03333 5.33333 4.66667V2.66667C5.33333 2.3 5.03333 2 4.66667 2H2.66667C2.3 2 2 2.3 2 2.66667V4.66667C2 5.03333 2.3 5.33333 2.66667 5.33333ZM2.66667 2.66667H4.66667V4.66667H2.66667V2.66667ZM2.66667 10H4.66667C5.03333 10 5.33333 9.7 5.33333 9.33333V7.33333C5.33333 6.96667 5.03333 6.66667 4.66667 6.66667H2.66667C2.3 6.66667 2 6.96667 2 7.33333V9.33333C2 9.7 2.3 10 2.66667 10ZM2.66667 7.33333H4.66667V9.33333H2.66667V7.33333Z"
      fill="#264FD5"
    />
  </svg>
</template>
<script>
export default {
  name: "DocumentIcon",
};
</script>
