import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4897fd1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "workstation-setting_content tw-h-full" }
const _hoisted_2 = {
  key: 0,
  class: "tw-h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_AccountDetailsTab = _resolveComponent("AccountDetailsTab")!
  const _component_ManageSubscriptionSettingTab = _resolveComponent("ManageSubscriptionSettingTab")!
  const _component_PaymentMethodAndCreditsTab = _resolveComponent("PaymentMethodAndCreditsTab")!
  const _component_ManageMemberTab = _resolveComponent("ManageMemberTab")!
  const _component_BillingHistoryTab = _resolveComponent("BillingHistoryTab")!
  const _component_InvitationListTab = _resolveComponent("InvitationListTab")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeUserWorkstation?.name !== 'Default')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_tabs, {
            class: "tab-list md:!tw-h-auto md:!tw-pt-[.5rem]",
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
            "align-tabs": "center",
            stacked: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: "tab-1" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Account Details ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "tab-2" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Manage Subscription ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "tab-3" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Payment Method & Credits ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "tab-4" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Manage Members ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "tab-5" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Billing History ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "tab-6" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Invitations ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_window, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
            class: "md:!tw-mt-4"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
                return _createVNode(_component_v_window_item, {
                  key: i,
                  value: 'tab-' + i,
                  class: ""
                }, {
                  default: _withCtx(() => [
                    (i === 1)
                      ? (_openBlock(), _createBlock(_component_AccountDetailsTab, { key: 0 }))
                      : _createCommentVNode("", true),
                    (i === 2)
                      ? (_openBlock(), _createBlock(_component_ManageSubscriptionSettingTab, {
                          key: 1,
                          onOnManageMember: _ctx.onOpenManageMember
                        }, null, 8, ["onOnManageMember"]))
                      : _createCommentVNode("", true),
                    (i === 3)
                      ? (_openBlock(), _createBlock(_component_PaymentMethodAndCreditsTab, { key: 2 }))
                      : _createCommentVNode("", true),
                    (i === 4 && _ctx.activeUserWorkstation?.name !== 'Default')
                      ? (_openBlock(), _createBlock(_component_ManageMemberTab, { key: 3 }))
                      : _createCommentVNode("", true),
                    (i === 5)
                      ? (_openBlock(), _createBlock(_component_BillingHistoryTab, { key: 4 }))
                      : _createCommentVNode("", true),
                    (i === 6 && _ctx.activeUserWorkstation?.name !== 'Default')
                      ? (_openBlock(), _createBlock(_component_InvitationListTab, { key: 5 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["value"])
              }), 64))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}