<template>
  <div class="progress-overlay progress-w-8" :class="className"></div>
  <v-card class="progress-overlay__card md:!tw-w-[80%]">
    <div class="progress-overlay__icon">
      <ProgressIcon />
    </div>
    <div class="progress-overlay__content">
      <h4>Verification in progress</h4>
      <p>
        We are verifying your workstation based on the details provided. This
        may take up to 72h. You are still able to look around your workstation
        but cannot use some areas until the verification has been completed.
        You’ll receive an email as soon as verification is complete.
      </p>
    </div>
  </v-card>
</template>
<script lang="ts">
import ProgressIcon from "./icons/ProgressIcon.vue";
export default {
  components: {
    ProgressIcon,
  },
  props: {
    className: Object,
  },
};
</script>

<style lang="scss" scoped>
.progress-overlay {
  position: absolute;
  border-radius: 8px;
  background-color: rgba(#f1f6fb, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  user-select: none;
  &__card {
    position: absolute;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: rgba($white, 1);
    max-width: 418px;
    box-shadow: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    border: 1px solid #9e9fb7;
    @include respond(s720) {
      left: 50%;
    }
  }
  &__icon {
    margin-bottom: 8px;
    text-align: center;
    img,
    svg {
      width: 65px;
      height: 65px;
      object-fit: contain;
      object-position: center;
      margin: 0 auto;
      opacity: 0.4;
    }
  }
  &__content {
    text-align: center;
    h4 {
      @include fluidFont(16, 16, 1.3);
      color: rgba($blueDark, 1);
      font-weight: 700;
      margin-bottom: 8px;
    }
    p {
      @include fluidFont(14, 14, 1.3);
      color: rgba($blueDark, 0.5);
      font-weight: 500;
    }
  }
}
</style>
