<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0834 0.335938H1.41671C0.683374 0.335938 0.083374 0.935937 0.083374 1.66927V13.6693L2.75004 11.0026H12.0834C12.8167 11.0026 13.4167 10.4026 13.4167 9.66927V1.66927C13.4167 0.935937 12.8167 0.335938 12.0834 0.335938Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
  </svg>
</template>
<script>
export default {
  name: "MessageIcon",
};
</script>
