<template>
  <GeneralDialog
    class="tw-max-w-[450px]"
    @on-close="onCloseModal"
    width="100%"
    max-width="450px"
    borderRadius="20px"
  >
    <template #body>
      <div class="main tw-text-center">
        <div>
          <div>
            <h5
              class="tw-text-[24px] tw-font-semibold tw-leading-[30px] tw-text-[#0c0f4a]"
            >
              Send Request?
            </h5>
          </div>
          <div class="tw-mt-[32px]">
            <p
              class="tw-text-[16px] tw-font-normal tw-leading-[24px] tw-text-black-50 tw-tracking-[0.15px]"
            >
              Are you sure you want to send you connection request to this
              contractor?
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-flex tw-items-center tw-gap-3">
        <Button
          variant="tertiary"
          :disabled="loading"
          label="Cancel"
          @click="onCloseModal"
          class="!tw-w-[calc(50%-8px)] tw-max-w-full"
        />
        <Button
          label="Confirm"
          :isLoading="loading"
          @click="onSave"
          class="!tw-w-[calc(50%-8px)] tw-max-w-full"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { ref } from "vue";

const emits = defineEmits(["on-cancel", "on-save"]);
const loading = ref(false);
const onSave = () => {
  emits("on-save");
};
const onCloseModal = () => {
  emits("on-cancel");
};
</script>
