<template>
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="className"
  >
    <circle cx="25" cy="25.5" r="25" fill="#40AE42" />
    <path
      d="M21.6733 30.675L16.8948 26.0361C16.3623 25.5192 15.5158 25.5192 14.9833 26.0361C14.4509 26.553 14.4509 27.3748 14.9833 27.8917L20.704 33.445C21.2364 33.9619 22.0966 33.9619 22.6291 33.445L37.1013 19.4092C37.6338 18.8923 37.6338 18.0706 37.1013 17.5537C36.5688 17.0368 35.7223 17.0368 35.1899 17.5537L21.6733 30.675Z"
      fill="#fff"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
