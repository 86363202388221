import { Commit } from "vuex";
import AuditLogService from "@/core/services/audit-logs.service";

export default {
  async getAuditLogs(
    { commit }: { commit: Commit },
    payload: { userId: number; params: any }
  ) {
    try {
      const auditLogsList = await AuditLogService.getAuditLogs(
        payload.userId,
        payload.params || {}
      );
      commit("setAuditLogs", auditLogsList?.data);
      return auditLogsList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getAuditLogs_all(
    { commit }: { commit: Commit },
    payload: {
      search: string;
      startDate: string;
      endDate: string;
      projectId: string;
    }
  ) {
    try {
      const auditLogsList_all = await AuditLogService.getAuditLogs_all(
        payload.search,
        payload.startDate,
        payload.endDate,
        payload.projectId
      );
      if (auditLogsList_all) {
        commit("setAuditLogs_all", auditLogsList_all);
        return auditLogsList_all;
      }
    } catch (error) {
      console.log("error", error);
      return [];
    }
  },
};
