<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85938 0.337891C3.51654 0.337891 1.5625 2.22513 1.5625 4.63477C1.5625 5.55148 1.83797 6.36893 2.36664 7.13492L5.53055 12.0719C5.68404 12.3119 6.03503 12.3114 6.1882 12.0719L9.36583 7.11812C9.88313 6.38685 10.1562 5.52818 10.1562 4.63477C10.1562 2.26547 8.22867 0.337891 5.85938 0.337891ZM5.85938 6.58789C4.78247 6.58789 3.90625 5.71167 3.90625 4.63477C3.90625 3.55786 4.78247 2.68164 5.85938 2.68164C6.93628 2.68164 7.8125 3.55786 7.8125 4.63477C7.8125 5.71167 6.93628 6.58789 5.85938 6.58789Z"
      fill="#FFA500"
    />
    <path
      d="M8.91313 9.30859L6.94617 12.3838C6.43703 13.1776 5.27888 13.175 4.77219 12.3845L2.80203 9.3094C1.06859 9.71018 0 10.4444 0 11.3217C0 12.8442 3.01896 13.6655 5.85938 13.6655C8.69979 13.6655 11.7188 12.8442 11.7188 11.3217C11.7188 10.4437 10.6487 9.70917 8.91313 9.30859Z"
      fill="#FFA500"
    />
  </svg>
</template>

<script>
export default {
  name: "MapMarkerYellowIcon",
};
</script>
