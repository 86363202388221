<template>
  <div class="container">
    <v-row>
      <v-col md="12" class="top_content">
        <div>
          <v-btn
            variant="text"
            aria-label="Refresh"
            class="back_btn"
            prepend-icon="mdi-chevron-left"
            @click="onClickBackButton"
            >back</v-btn
          >
        </div>
        <img
          :width="160"
          aspect-ratio="16/9"
          cover
          src="../../../../assets/images/logo/iknowa-logo-v4.svg"
          class="logo_top"
        />
        <h1>Create your workstation, {{ user?.firstName }} 🚀</h1>
        <p class="main_p">
          Select your workstation type, you can change at any time.
        </p>
        <div class="modal_card_list top_list">
          <ul>
            <li>
              <v-icon icon="mdi-check-circle" /><span>
                Access Property Insights
              </span>
            </li>
            <li>
              <v-icon icon="mdi-check-circle" /><span> Raise Projects </span>
            </li>
            <li>
              <v-icon icon="mdi-check-circle" /><span>
                Collaborate With Others
              </span>
            </li>
          </ul>
        </div>

        <v-row>
          <v-col lg="4">
            <v-card class="custom-card">
              <div class="card-logo-header">
                <div class="custom__tags">
                  <div class="onbording-wrap__card-tag">
                    <label class="tag-item__points"
                      ><span>FREE</span>&nbsp;Forever
                    </label>
                  </div>
                </div>
              </div>

              <div class="card_body">
                <div class="card_main">
                  <div class="card_right">
                    <div class="modal_card_list">
                      <div class="onbording-wrap__card-details__text">
                        <div class="card_left">
                          <div class="onbording-wrap__card-details">
                            <div class="onbording-wrap__card-details__icon">
                              <v-icon icon="mdi-home-variant" />
                            </div>
                          </div>
                        </div>
                        <h4>Occupiers / Renters</h4>
                      </div>
                      <ul>
                        <li>
                          <v-icon icon="mdi-check-circle" />
                          <span>
                            Property overview
                            <text>(inc. energy efficiency)</text>
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Record or raise projects to be approved
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Invite your landlord or property manager
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Store & manage key documents (100MB)
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card_footer">
                  <!-- <div class="cardfooter_left">&nbsp;</div> -->
                  <div class="cardfooter_right">
                    <hr />
                    <v-btn
                      class="button button-purple-border purple_btn"
                      size="large"
                      block
                      @click="onClickOccupierscreation"
                    >
                      cREATE workstation 🚀
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card></v-col
          >
          <!-- Homeowners -->
          <v-col lg="4">
            <v-card class="custom-card">
              <div class="card-logo-header">
                <div class="custom__tags">
                  <div class="onbording-wrap__card-tag">
                    <label class="tag-item__points"
                      ><span>FREE</span>&nbsp;Basic Workstation
                    </label>
                  </div>
                </div>
              </div>

              <div class="card_body">
                <div class="card_main">
                  <div class="card_right">
                    <div class="modal_card_list">
                      <div class="onbording-wrap__card-details__text">
                        <div class="card_left">
                          <div class="onbording-wrap__card-details">
                            <div class="onbording-wrap__card-details__icon">
                              <v-icon icon="mdi-home-variant" />
                            </div>
                          </div>
                        </div>
                        <h4>Homeowners</h4>
                      </div>
                      <ul>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Property overview
                            <text> (inc. energy efficiency)</text>
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Record or raise projects to be approved
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Invite your landlord or property manager
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Store & manage key documents (100MB)
                          </span>
                        </li>
                        <li>
                          <img
                            src="../../../../assets/icons/yellow-pound.png"
                          /><span>
                            From £6.39* p/month, for a verified workstation
                            <text>
                              (option to add 3 properties & includes 100GB
                              storage)</text
                            >
                          </span>
                        </li>
                        <li>
                          <img
                            src="../../../../assets/icons/yellow-pound.png"
                          /><span>
                            From £6.39* per additional team member
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card_footer">
                  <!-- <div class="cardfooter_left">&nbsp;</div> -->
                  <div class="cardfooter_right">
                    <hr />
                    <v-btn
                      class="button button-purple-border purple_btn"
                      size="large"
                      block
                      @click="onClickHomeownerscreation"
                    >
                      cREATE workstation 🚀
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <!-- Landlords, Developers & Businesses -->
          <v-col lg="4"
            ><v-card class="custom-card">
              <div class="card-logo-header">
                <div class="custom__tags">
                  <div class="onbording-wrap__card-tag">
                    <label class="tag-item__points"
                      ><span>FROM</span>&nbsp;£9.99 Per Month
                    </label>
                  </div>
                </div>
              </div>

              <div class="card_body">
                <div class="card_main">
                  <div class="card_right">
                    <div class="modal_card_list">
                      <div class="onbording-wrap__card-details__text">
                        <div class="card_left">
                          <div class="onbording-wrap__card-details">
                            <div class="onbording-wrap__card-details__icon">
                              <v-icon icon="mdi-home-variant" />
                            </div>
                          </div>
                        </div>
                        <h4>Landlords, Developers & Businesses</h4>
                      </div>
                      <ul>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Everything in Basic & Standard, plus:
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Store and manage unlimited documents
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Add & manage unlimited properties
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Includes API access*
                          </span>
                        </li>
                        <li>
                          <v-icon icon="mdi-check-circle" /><span>
                            Unlimited Title Lookups*
                          </span>
                        </li>
                        <li>
                          <img
                            src="../../../../assets/icons/yellow-pound.png"
                          /><span> £9.99 per additional team member </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card_footer">
                  <!-- <div class="cardfooter_left">&nbsp;</div> -->
                  <div class="cardfooter_right">
                    <hr />
                    <v-btn
                      class="button button-purple-border purple_btn"
                      size="large"
                      block
                      @click="onClickLandlordscreation"
                    >
                      create workstation 🚀
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <OccupiersWorkstationComponent
    v-if="isShowOccupiersWorkStation"
    @onClickCloseTab="onClickCloseOccupiers"
  />
  <HomeownerWorkStationComponent
    v-if="isShowHomeownersWorkStation"
    @onClickCloseTab="onClickCloseHomeowners"
  />
  <LandlordsWorkStationComponent
    v-if="isShowLandlordsWorkStation"
    @onClickCloseTab="onClickCloseLandlords"
  />
  <HomeownerWelcomeComponent
    v-if="isShowHomeownersWelcome"
    @onClickCloseTab="onClickCloseHomeownersWelcome"
  />
</template>

<script lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import OccupiersWorkstationComponent from "@/modules/workstation/occupiers/components/OccupiersWorkstationComponent.vue";
import HomeownerWorkStationComponent from "@/modules/workstation/homeowner/components/HomeownerWorkStationComponent.vue";
import LandlordsWorkStationComponent from "@/modules/workstation/landlords/components/LandlordsWorkStationComponent.vue";
import HomeownerWelcomeComponent from "@/modules/workstation/homeowner/components/HomeownerWelcomeComponent.vue";
export default {
  components: {
    HomeownerWorkStationComponent,
    LandlordsWorkStationComponent,
    OccupiersWorkstationComponent,
    HomeownerWelcomeComponent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref(null) as any;
    onBeforeMount(async () => {
      user.value = store.getters[`${USER_STORE}/user`];
    });
    const onClickBackButton = () => {
      router.go(-1);
    };
    const isShowHomeownersWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getHomeownersWorkStationState`]
    );

    const isShowLandlordsWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getLandlordsWorkStationState`]
    );

    const isShowOccupiersWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getOccupiersWorkStationState`]
    );

    const isShowHomeownersWelcome = computed(
      () => store.getters[`${WORKSTATION}/getHomeownersWelcomeState`]
    );

    const onClickLandlordscreation = () => {
      store.commit(
        `${WORKSTATION}/setLandlordsWorkStationState`,
        !isShowLandlordsWorkStation.value
      );
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowSoleTraderWorkStation: false,
      });
    };
    const onClickHomeownerscreation = () => {
      store.commit(
        `${WORKSTATION}/setHomeownersWorkStationState`,
        !isShowHomeownersWorkStation.value
      );
      store.commit(`${WORKSTATION}/setHomeownersWelcomeState`, false);
    };
    const onClickOccupierscreation = () => {
      store.commit(
        `${WORKSTATION}/setOccupiersWorkStationState`,
        !isShowOccupiersWorkStation.value
      );
    };

    const onClickCloseHomeowners = () => {
      store.commit(
        `${WORKSTATION}/setHomeownersWorkStationState`,
        !isShowHomeownersWorkStation.value
      );
    };
    const onClickCloseLandlords = () => {
      store.commit(
        `${WORKSTATION}/setLandlordsWorkStationState`,
        !isShowLandlordsWorkStation.value
      );
    };
    const onClickCloseOccupiers = () => {
      store.commit(
        `${WORKSTATION}/setOccupiersWorkStationState`,
        !isShowOccupiersWorkStation.value
      );
    };

    const onClickCloseHomeownersWelcome = () => {
      store.commit(
        `${WORKSTATION}/setHomeownersWorkStationState`,
        !isShowHomeownersWorkStation.value
      );
      store.commit(
        `${WORKSTATION}/setHomeownersWelcomeState`,
        !isShowHomeownersWelcome.value
      );
    };

    return {
      user,
      onClickBackButton,
      onClickLandlordscreation,
      onClickHomeownerscreation,
      onClickCloseHomeowners,
      isShowHomeownersWorkStation,
      isShowLandlordsWorkStation,
      onClickCloseLandlords,
      onClickCloseOccupiers,
      isShowOccupiersWorkStation,
      onClickOccupierscreation,
      isShowHomeownersWelcome,
      onClickCloseHomeownersWelcome,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/workstation/propertyowner/styles/propertyowner.scss";
</style>
