export default {
  setProjectCalendarData(
    state: any,
    payload: {
      calendarData: {
        calendarData: Array<object>;
        calendarSideList: Array<object>;
      };
      type: string;
    }
  ) {
    if (payload.type === "project") {
      state.projectCalendarData = payload.calendarData;
    } else if (payload.type === "team") {
      state.teamCalendarData = payload.calendarData;
    }
  },
};
