/**
 * PropertyOwner Workstation route page
 */
import selectWorkstationRoute from "@/modules/workstation/propertyowner/pages/SelectWorkstation.vue";

export const SELECT_WORKSTATION_ROUTE = "select-workstation";

export default {
  path: "/select-workstation",
  name: SELECT_WORKSTATION_ROUTE,
  component: selectWorkstationRoute,
  meta: {
    requiresAuth: true,
    layout: "Authlayout",
  },
};
