<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['money_out_success_modal']"
    width="665px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
        </div>
        <div class="modal_form">
          <div class="success_img">
            <img src="@/assets/icons/transaction-successful.svg" />
          </div>
          <div class="balance_amount">£{{ balance }}</div>
          <div class="success__message">
            <h4>Transaction Successful</h4>
            <p>Your money transfer to this {{ accountNumber }} account.</p>
          </div>
        </div>
        <div class="modal_content_footer">
          <v-btn class="button button-purple-border" @click="onClickCloseButton"
            >Cancel</v-btn
          >
          <v-btn class="button button-orange" @click="onClickCloseButton"
            >Done</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
import { computed, ref, onBeforeMount } from "vue";

export default {
  components: {
    CommonDialog,
  },
  props: {
    balance: {
      type: Number,
    },
    accountNumber: {
      type: Number,
    },
  },
  setup(props: any, ctx: any) {
    const balance = ref();
    const accountNumber = ref();
    const onClickCloseButton = () => {
      ctx.emit("closeMoneyOutSuccessfullyModal");
    };

    onBeforeMount(() => {
      balance.value = props?.balance;
      accountNumber.value = props?.accountNumber;
    });

    return {
      onClickCloseButton,
    };
  },
};
</script>
