<template>
  <Card class="tw-py-5 tw-px-4 tw-max-w-full">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-5 tw-h-full">
        <!-- Header -->
        <section
          class="tw-flex tw-items-center tw-justify-between lg:tw-flex-col lg:tw-gap-4 2xl:tw-items-start"
        >
          <div
            class="tw-flex tw-gap-4 tw-items-center 2xl:tw-flex-col 2xl:tw-items-start"
          >
            <Text variant="h5" whiteSpace="nowrap">Your Estimate Summary</Text>
            <EstimateBadge
              :badge="estimateBadge"
              class="lg:tw-hidden"
              bgColor="#6B5F4B"
            />
          </div>
          <div
            class="tw-flex tw-gap-4 tw-items-center lg:tw-justify-between lg:tw-items-start lg:tw-w-full"
          >
            <!-- <EstimateBadge
              :badge="estimateBadge"
              class="tw-hidden lg:tw-block"
              bgColor="#6B5F4B"
            /> -->
            <Button
              variant="custom"
              :label="isShowChart ? 'PROJECT DETAILS' : 'PROJECT TIMELINE'"
              height="30px"
              borderColor="#FFA500"
              activeBgColor="#FFF"
              activeFontColor="#FFA500"
              width="auto"
              fontSize="12px"
              @click="toggleChartComponent"
            >
              <template #prefix>
                <TimelineIcon />
              </template>
            </Button>
          </div>
        </section>

        <!-- Estimate Timeline -->
        <GanttChart
          v-if="isShowChart && getTradeEstimateDetails"
          :estimateData="getTradeEstimateDetails"
          :isEditable="false"
        />
        <!-- Estimate Summary -->
        <section
          class="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between tw-pb-4"
          v-if="!isShowChart && getTradeEstimateDetails"
        >
          <div class="tw-flex tw-flex-col tw-gap-4 tw-h-full">
            <EstimateSummaryData
              :selectedEstimate="getTradeEstimateDetails"
              :isReadOnly="true"
            />
            <v-divider :thickness="2"></v-divider>
            <v-expansion-panels>
              <PhaseReceivedItem
                v-for="(
                  phase, index
                ) in getTradeEstimateDetails.estimationPhase"
                :key="index"
                :phase="phase"
                :phaseIndex="index"
                :grantEstimateDetails="grantEstimateDetails"
              />
            </v-expansion-panels>
          </div>
          <section class="tw-flex tw-flex-col tw-gap-4">
            <v-divider :thickness="2"></v-divider>
            <EstimateReceivedTotal
              :totalSubTotal="totalSubTotal"
              :totalVat="totalVat"
              :serviceCharges="serviceCharges"
              :grantName="getTradeEstimateDetails?.grantName"
              :grantAmount="getTradeEstimateDetails?.grantAmount"
            />
          </section>
        </section>
      </main>
    </template>
  </Card>
</template>

<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button";
import Text from "@/core/components/ui/general/Text";
import TimelineIcon from "@/core/components/icons/TimelineIcon";
import InitialEstimateChip from "@/core/uni-components/Chips/InitialEstimateChip.vue";
import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import EstimateReceivedTotal from "@/modules/project/components/common/EstimateReceivedTotal";
import PhaseReceivedItem from "@/modules/project/components/common/PhaseReceivedItem";
import { computed } from "vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { useStore } from "vuex";
import { ref } from "vue";
import GanttChart from "@/modules/jobs/components/estimates/GanttChart.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import {
  EstimatePhaseType,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
const isShowChart = ref(false);
const props = defineProps(["selectedEstimate"]);
const store = useStore();
const getTradeEstimateDetails = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/getTradeEstimateDetails`]
);
const totalPhaseTasks = computed(
  () =>
    getTradeEstimateDetails.value.estimationPhase.filter(
      (phase) => phase.phaseType === EstimatePhaseType.LABOUR
    ).length
);

const grantEstimateDetails = computed(() => {
  const grantAmount = getTradeEstimateDetails.value.grantAmount;

  return {
    id: getTradeEstimateDetails.value.id,
    grantType: getTradeEstimateDetails.value.grantType,
    grantName: getTradeEstimateDetails.value.grantName,
    grantAmount: getTradeEstimateDetails.value.grantAmount,
    lockState: getTradeEstimateDetails.value.lockState,
    suggestedStartDate: getTradeEstimateDetails.value.suggestedStartDate,
    agreeTermsConsumerDisclosure:
      getTradeEstimateDetails.value.agreeTermsConsumerDisclosure,
    depositType: getTradeEstimateDetails.value.depositType,
    totalEstimateCost: getTradeEstimateDetails.value.totalEstimateCost,

    grantDiscountTotal: parseFloat(grantAmount / totalPhaseTasks.value).toFixed(
      2
    ),
  };
});

const toggleChartComponent = () => {
  isShowChart.value = !isShowChart.value;
};

const totalSubTotal = computed(() => {
  const sum = getTradeEstimateDetails.value.estimationPhase.reduce(
    (sum, phase) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );

  return Math.round(sum * 100) / 100;
});

const totalVat = computed(() => {
  const sum = getTradeEstimateDetails.value.estimationPhase.reduce(
    (sum, phase, index) => {
      const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  // 5 pound
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee =
    getTradeEstimateDetails.value.estimationPhase.reduce(
      (sum, phase, index) => {
        const roundedTotalWithServiceFee = parseFloat(
          phase.subTotal * serviceFeePercentage
        );
        return sum + roundedTotalWithServiceFee;
      },
      0
    );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

const estimateBadge = computed(() => {
  if (
    props.selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Initial_Estimate
  )
    return "Initial Estimate";
  else if (
    props.selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    props.selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
});
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  gap: 16px;
}
:deep(.v-expansion-panels):not(.v-expansion-panels--variant-accordion)
  > :first-child:not(:last-child):not(.v-expansion-panel--active):not(
    .v-expansion-panel--before-active
  ) {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: 0px 0px 8px 0px #0c0f4a14 !important;
}
</style>
