import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

export const BILLING_STORE = "billing";

export default {
  namespaced: true,

  state,

  actions,

  getters,

  mutations,
};
