<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-[70vh] tw-w-full"
  >
    <Loader :show="isLoading" v-if="isLoading" />
  </div>
  <div class="tw-w-full tw-flex tw-flex-col tw-gap-7" v-else>
    <div
      class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-justify-start tw-items-start"
    >
      <div class="tw-flex-row-between md:!tw-flex-col md:!tw-items-start">
        <Text
          variant="h4"
          textWeight="500"
          class="md:!tw-text-[20px] md:!tw-font-bold md:!tw-text-start"
          >Manage Payment Method</Text
        >
        <Button
          label="+Add card"
          class="!tw-w-auto"
          :isActive="true"
          @click="onOpenAddCardModal($event, false)"
        />
      </div>
      <div class="atm-card-container tw-grid tw-w-full tw-gap-4">
        <AtmCard
          v-for="(atm, index) in atmCards"
          :key="index"
          :data="atm"
          @on-edit="onOpenAddCardModal($event, true)"
          @on-delete="onOpenDeleteModal"
        />
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-start">
      <Text variant="h4" textWeight="500">Total Credits</Text>
      <Card class="tw-w-full tw-p-5 tw-box-border">
        <template #content>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-5">
            <div
              class="tw-w-full tw-flex tw-items-center tw-gap-4 tw-border-b-dashed-custom tw-pb-5 tw-box-border"
            >
              <WalletCircleYellowIcon />
              <Text variant="h5" textWeight="600">Total Credits</Text>
            </div>
            <div class="tw-flex tw-flex-col tw-w-full tw-gap-5 tw-items-start">
              <Text variant="h1">{{
                CurrencyFilter.formatToCurrency(totalCredits)
              }}</Text>
            </div>
            <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-4">
              <Card
                v-for="(total, index) in credits?.totals"
                :key="index"
                class="tw-w-full tw-p-4 tw-box-border tw-col-span-1"
                bgColor="#F1F6FB"
                ><template #content>
                  <div
                    class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start"
                  >
                    <div
                      class="tw-w-full tw-flex tw-gap-4 tw-border-b-dashed-custom tw-pb-5 tw-box-border"
                    >
                      <Text variant="h6" textWeight="600">{{
                        total?.billingItem === "Storage"
                          ? "Storage credits"
                          : "Total Members credits"
                      }}</Text>
                    </div>
                    <Text variant="h4" textWeight="400">{{
                      CurrencyFilter.formatToCurrency(total?.totalCredits)
                    }}</Text>
                  </div>
                </template></Card
              >
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div
      class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-start tw-overflow-x-auto"
    >
      <Text variant="h4" textWeight="500">Credits</Text>
      <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <div class="tw-flex tw-gap-4 tw-w-full">
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textColor="#80829F" textWeight="600"
              >CREDIT TYPE</Text
            >
          </div>
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textColor="#80829F" textWeight="600"
              >DESCRIPTION</Text
            >
          </div>
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textColor="#80829F" textWeight="600"
              >DURATION</Text
            >
          </div>
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textColor="#80829F" textWeight="600"
              >CREDITS</Text
            >
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-gap-4 tw-w-full"
        v-if="credits?.creditItems.length"
      >
        <div
          class="tw-flex tw-gap-4 tw-w-full tw-pb-4 tw-border-b-dashed-custom"
          v-for="(item, index) in credits?.creditItems"
          :key="index"
        >
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textWeight="400" textAlign="left">{{
              item?.creditItem
            }}</Text>
          </div>
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textWeight="400" textAlign="left">{{
              item?.description
            }}</Text>
          </div>
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textWeight="400" textAlign="left"
              >{{ appFilters.formatToHumanDate(item?.duration?.start) }}
              -
              {{ appFilters.formatToHumanDate(item?.duration?.end) }}</Text
            >
          </div>
          <div class="tw-min-w-[200px] tw-max-w-[200px] tw-flex tw-items-start">
            <Text variant="p" textWeight="400" textAlign="left">{{
              CurrencyFilter.formatToCurrency(item?.amount)
            }}</Text>
          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
    <AddOrUpdateAtmCardModal
      v-if="addCardModal"
      @on-close="onCloseAddCardModal($event)"
      :isEdit="isEditCardMode"
      :selectedAtmCard="selectedAtmCard"
      @on-success="onCloseAddCardModal($event)"
    />

    <ConfirmDeleteAtmCardModal
      v-if="deleteModal"
      @on-close="onCloseDeleteModal"
      :selectedAtmCard="selectedAtmCard"
      @on-success="onCloseDeleteModal"
    />
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import CurrencyFilter from "@/filters/currency.filter";
import appFilters from "@/filters";
import WalletCircleYellowIcon from "@/core/components/icons/WalletCircleYellowIcon.vue";
import AtmCard from "../cards/AtmCard.vue";
import Loader from "@/core/components/common/Loader.vue";
import AddOrUpdateAtmCardModal from "@/modules/workstation-setting/components/modals/AddOrUpdateAtmCardModal.vue";
import ConfirmDeleteAtmCardModal from "@/modules/workstation-setting/components/modals/ConfirmDeleteAtmCardModal.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const isLoading = ref(false);
const credits = ref(null);
const totalCredits = ref(0);

const atmCards = ref([]);

const addCardModal = ref(false);
const isEditCardMode = ref(false);
const selectedAtmCard = ref(null);

const deleteModal = ref(false);

const calculateTotalCredits = (totals) => {
  totalCredits.value = totals.reduce((sum, item) => {
    return sum + item.totalCredits;
  }, 0);

  console.log(totalCredits.value, "totalCredits");
};

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onOpenDeleteModal = (event) => {
  selectedAtmCard.value = event;
  deleteModal.value = true;
};

const onCloseDeleteModal = async (isSuccessDelete) => {
  try {
    if (isSuccessDelete) {
      const userId = user.value.id;
      const atmCardResponse = await store.dispatch(
        `${WORKSTATION_SETTING}/getAtmCards`,
        userId
      );
      if (atmCardResponse) {
        atmCards.value = atmCardResponse;
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    selectedAtmCard.value = null;
    deleteModal.value = false;
  }
};

const onOpenAddCardModal = (event, isEdit) => {
  if (isEdit && event) {
    isEditCardMode.value = isEdit;
    selectedAtmCard.value = event;
  }
  addCardModal.value = true;
};

const onCloseAddCardModal = async (isSucessAddOrEdit) => {
  try {
    if (isSucessAddOrEdit) {
      const userId = user.value.id;
      const atmCardResponse = await store.dispatch(
        `${WORKSTATION_SETTING}/getAtmCards`,
        userId
      );
      if (atmCardResponse) {
        atmCards.value = atmCardResponse;
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    isEditCardMode.value = false;
    addCardModal.value = false;
  }
};

const initialize = async () => {
  try {
    isLoading.value = true;
    const userId = user.value.id;
    const payload = {
      userId,
      workstationId: activeUserWorkstation.value?.id,
    };
    const creditsResponse = await store.dispatch(
      `${WORKSTATION_SETTING}/getCredits`,
      payload
    );
    const atmCardResponse = await store.dispatch(
      `${WORKSTATION_SETTING}/getAtmCards`,
      userId
    );
    if (creditsResponse) {
      credits.value = creditsResponse;
      if (creditsResponse?.totals) {
        calculateTotalCredits(creditsResponse?.totals);
      }
    }
    if (atmCardResponse) {
      atmCards.value = atmCardResponse;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await initialize();
});
</script>
<style lang="scss" scoped>
.subscription-details {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.atm-card-container {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
</style>
