<template>
  <CollapsibleWidgetSlot title="Know Your Property" :isExpand="true">
    <template #content>
      <section
        class="kyp-score tw-grid tw-w-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <PropertySummaryCard />
        <PropertyScoreCard />
        <PropertyLogsCard
          v-if="!isSiteVisitPropertyDetail"
          @route-to-project-logs="onRouteToProjectLogs"
        />
        <!-- <PropertyRatingCard /> -->
      </section>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import PropertySummaryCard from "@/modules/properties/components/Cards/PropertySummaryCard.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import PropertyScoreCard from "@/modules/properties/components/Cards/PropertyScoreCard.vue";
import PropertyLogsCard from "@/modules/properties/components/Cards/PropertyLogsCard.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
// import PropertyRatingCard from "@/modules/properties/components/Cards/PropertyRatingCard.vue";
const store = useStore();
const isSiteVisitPropertyDetail = computed(
  () => store.getters[`${PROPERTY_STORE}/getIsSiteVisitPropertyDetail`]
);
const emits = defineEmits(["route-to-project-logs"]);

const onRouteToProjectLogs = () => {
  emits("route-to-project-logs");
};
</script>
<style lang="scss" scoped>
.kyp-score {
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));

  @media screen and (min-width: 767px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
