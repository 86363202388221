<template>
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8414 7.74707H4.88834C3.84711 7.74707 3 8.54712 3 9.5305V16.6642C3 17.6476 3.84711 18.4477 4.88834 18.4477H21.8414C22.8827 18.4477 23.7298 17.6476 23.7298 16.6642V9.5305C23.7298 8.54712 22.8827 7.74707 21.8414 7.74707ZM7.98274 14.0223C7.82181 14.0223 7.54687 14.0234 7.28951 14.0246V15.3957C7.28951 15.724 7.00769 15.9902 6.66007 15.9902C6.31244 15.9902 6.03062 15.724 6.03062 15.3957L6.02134 10.8799C6.0208 10.7219 6.08689 10.5702 6.20498 10.4583C6.32306 10.3464 6.48353 10.2835 6.65079 10.2835H7.98274C9.08872 10.2835 9.98854 11.1221 9.98854 12.1529C9.98854 13.1837 9.08872 14.0223 7.98274 14.0223ZM13.3862 15.8469C13.0114 15.853 12.0736 15.8564 12.0339 15.8566C12.0331 15.8566 12.0323 15.8566 12.0315 15.8566C11.8654 15.8566 11.706 15.7946 11.588 15.684C11.4695 15.5729 11.4027 15.4219 11.402 15.2642C11.402 15.2484 11.3926 10.8791 11.3926 10.8791C11.3923 10.7213 11.4585 10.5698 11.5765 10.458C11.6946 10.3463 11.8549 10.2835 12.0221 10.2835H13.3368C14.8345 10.2835 15.8408 11.4033 15.8408 13.0701C15.8408 14.6556 14.8085 15.8234 13.3862 15.8469ZM19.8364 12.4375C20.1841 12.4375 20.4659 12.7037 20.4659 13.032C20.4659 13.3603 20.1841 13.6265 19.8364 13.6265H18.6942V15.3168C18.6942 15.6451 18.4124 15.9112 18.0648 15.9112C17.7171 15.9112 17.4353 15.6451 17.4353 15.3168V10.8321C17.4353 10.5038 17.7171 10.2376 18.0648 10.2376H19.9893C20.3369 10.2376 20.6188 10.5038 20.6188 10.8321C20.6188 11.1604 20.3369 11.4266 19.9893 11.4266H18.6942V12.4375H19.8364Z"
      fill="#FF0000"
    />
    <path
      d="M13.3368 11.4727H12.6527C12.6537 12.0093 12.6571 14.1484 12.6589 14.6647C12.9208 14.6631 13.2013 14.661 13.3643 14.6583C14.2055 14.6444 14.582 13.8507 14.582 13.0703C14.582 12.6955 14.4922 11.4727 13.3368 11.4727Z"
      fill="#FF0000"
    />
    <path
      d="M7.98272 11.4727H7.2821C7.28286 11.7372 7.28357 12.0186 7.28357 12.1531C7.28357 12.3102 7.28475 12.5828 7.28609 12.8359C7.54442 12.8346 7.82037 12.8335 7.98276 12.8335C8.38762 12.8335 8.72966 12.5219 8.72966 12.1531C8.72966 11.7842 8.38758 11.4727 7.98272 11.4727Z"
      fill="#FF0000"
    />
    <path
      d="M21.1892 6.55766C21.0433 6.17997 20.8167 5.83089 20.5166 5.53199L17.9021 2.92735C17.3105 2.33802 16.478 2 15.6181 2H7.23832C6.19709 2 5.34998 2.80005 5.34998 3.78343V6.55766H21.1892Z"
      fill="#FF0000"
    />
    <path
      d="M5.34998 19.6367V20.5086C5.34998 21.492 6.19708 22.2921 7.23832 22.2921H19.4915C20.5328 22.2921 21.3799 21.492 21.3799 20.5086V19.6367H5.34998Z"
      fill="#FF0000"
    />
  </svg>
</template>

<script>
export default {
  name: "PdfIcon",
};
</script>
