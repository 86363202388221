<template>
  <ChipState label="Standard" textColor="#FFF" bgColor="#FAA500">
    <template #prefix>
      <StandardCircleWhiteIcon />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import StandardCircleWhiteIcon from "@/core/components/icons/StandardCircleWhiteIcon";
</script>
<script>
export default {
  name: "StandardChip",
};
</script>
