export const COMPANY_TYPE = "company";
export const PROPERTY_TYPE = "property";

export const VIEW = "View";
export const EDIT = "Edit";
export const DELETE = "Delete";

export const ALL = "all";
export const IN_PROGRESS = "in-progress";
export const DRAFT_PROGRESS = "draft-progress";
export const NOT_STARTED = "not-started";
export const COMPLETED = "completed";

// METHODS
export const NEW_PROJECT = "new-project";
export const NEW_HISTORIC_PROJECT = "new-historical-project";

// SCREEN TYPES
export const PROJECT_LIST_SCREEN = "project-list";
export const NEW_HISTORIC_PROJECT_SCREEN = "add-project";
