<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    :className="['restricted__modal_box']"
    v-if="isShowWelcomeModal"
  >
    <template v-slot:body>
      <div
        class="dashboard-welcome__close !tw-top-8 !tw-right-8"
        @click="onCloseModal"
      >
        <v-icon icon="mdi-close"></v-icon>
      </div>
      <div class="header-title">Unlock the Full Experience</div>
      <div class="welcome__image">
        <img src="../../../assets/images/restricted-area-2.png" alt="" />
      </div>
      <div class="header-sub-title" v-if="isDefault">
        This was a taste of the premium features. Create your
        {{
          user.role?.name === UserRolesEnum.TRADESPERSON ? "Trade" : "Property"
        }}
        Workstation today and enjoy the full experience.
      </div>
      <div class="header-sub-title" v-else>
        {{ textForNotifyUser }}
      </div>
    </template>
    <template v-slot:footer
      ><v-btn
        v-if="isDefault"
        @click="onCreateWorkstation"
        class="button button-purple"
        >Create a
        {{
          user.role?.name === UserRolesEnum.TRADESPERSON ? "Trade" : "Property"
        }}
        Workstation</v-btn
      ><v-btn v-else @click="onClickAction" class="button button-orange">{{
        textForNotifyUserBtn || "Verify Workstaion"
      }}</v-btn></template
    >
  </CommonDialog>

  <AddAddressModal
    v-if="isOpenAddBusinessAddressModal"
    @on-close="onClickCloseActionModel"
  />
  <WorkstationVerificationModal
    v-if="isOpenWorkstationVerificationModal"
    :onClickCloseTab="onClickCloseActionModel"
  />
</template>

<script lang="ts" setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  BusinessDetailTypeEnum,
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRouter } from "vue-router";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import AddAddressModal from "@/modules/dashboard/components/Modals/AddAddressModal.vue";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";

const store = useStore();
const router = useRouter();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const emit = defineEmits(["on-close"]);
const isOpenAddBusinessAddressModal = ref(false);
const isOpenWorkstationVerificationModal = ref(false);
const textForNotifyUser = ref("");
const textForNotifyUserBtn = ref("");
const userName = computed(
  () => `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
);
const onSubmitVerification = ref(false);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value?.name === "Default";
});
const isShowWelcomeModal = ref(true);

const onCloseModal = () => {
  emit("on-close");
};

const onCreateWorkstation = () => {
  onCloseModal();
  router.push({ name: DASHBOARD_ROUTE });
  if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
    store.dispatch(`${WORKSTATION}/setWsModal`, {
      isShowWorkStationModal: true,
    });
  }
};

const isUserAddBusinessAddress = computed(() => {
  if (
    user.value?.userBusinessDetails?.type ===
    BusinessDetailTypeEnum.LIMITED_COMPANY
  ) {
    return user.value.userBusinessDetails.businessPostcode;
  } else {
    return user.value.userBusinessDetails.residencyPostcode;
  }
});
const isUserProfileVerified = computed(
  () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
);
const isUserKycVerified = computed(
  () =>
    activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
      UserVerificationStatusEnum.VERIFIED ||
    activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
      UserVerificationStatusEnum.PENDING_VERIFICATION
);

const onClickSubmitVerification = async () => {
  try {
    onSubmitVerification.value = true;
    const data = {
      userId: user.value?.id,
      userWorkstationId: activeUserWorkstation.value?.id,
      formData: {
        verificationStatus: UserVerificationStatusEnum.PENDING_VERIFICATION,
      },
    };
    await store.dispatch(`${WORKSTATION}/updateUserWorkstationStatus`, data);
    onCloseModal();
  } catch (err) {
    console.log(err);
  } finally {
    onSubmitVerification.value = false;
  }
};
const onClickAction = () => {
  if (!isUserAddBusinessAddress.value) {
    isOpenAddBusinessAddressModal.value = true;
  } else if (!isUserKycVerified.value) {
    isOpenWorkstationVerificationModal.value = true;
  } else if (!isUserProfileVerified.value) {
    router.push({ name: TRADE_PASSPOSRT_ROUTE });
    onCloseModal();
  } else {
    onClickSubmitVerification();
  }
};
const onClickCloseActionModel = () => {
  isOpenAddBusinessAddressModal.value = false;
  isOpenWorkstationVerificationModal.value = false;
  onCloseModal();
};

onBeforeMount(() => {
  if (!isUserAddBusinessAddress.value) {
    textForNotifyUser.value =
      "Please provide your business address for verification. Once completed, your all-access pass will be assigned.";
    textForNotifyUserBtn.value = "Add Business Address";
  } else if (!isUserKycVerified.value) {
    textForNotifyUser.value =
      "ID Verification Required. Complete your ID verification to have your all-access pass assigned.";
    textForNotifyUserBtn.value = "Complete ID Verification";
  } else if (!isUserProfileVerified.value) {
    textForNotifyUser.value =
      "Please complete your Trade Passport to have your all-access pass assigned";
    textForNotifyUserBtn.value = "Complete Trade Passport";
  } else {
    textForNotifyUser.value =
      "Submit your workstation for verification to receive your all-access pass.";
    textForNotifyUserBtn.value = "Request for verify workstation";
  }
});
</script>
