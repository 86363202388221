/**
 * Verify Jobs route page
 */
import VerifyJobsRoute from "@/modules/verify-historical-projects/pages/VerifyJobs.vue";

export const VERIFY_JOBS_ROUTE = "verify-jobs";

export default {
  path: "/verify-jobs",
  name: VERIFY_JOBS_ROUTE,
  component: VerifyJobsRoute,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Verify Jobs",
  },
};
