<template>
  <CommonDialog
    v-model="openReviewModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['job_review_comment_modal']"
    width="648px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="modal__header">
          <h2>Comments</h2>
          <v-btn
            class="modal__close"
            variant="plain"
            icon="mdi-close"
            @click="onCloseModal"
          ></v-btn>
        </div>
        <div class="modal__body">
          <v-expansion-panels v-if="reviewData?.comments?.length" multiple>
            <v-expansion-panel
              v-for="(comment, index) of reviewData.comments"
              :key="comment.user.id"
            >
              <v-expansion-panel-title
                collapse-icon="mdi-minus"
                expand-icon="mdi-plus"
                >Comment {{ index + 1 }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="modal__comment-accordion-content">
                  <p>
                    {{ comment?.description }}
                  </p>
                </div>
                <div class="modal__comment-accordion-content-footer">
                  <p>{{ getFormateDate(comment?.createDate) }}</p>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <!-- <v-expansion-panel>
              <v-expansion-panel-title
                collapse-icon="mdi-minus"
                expand-icon="mdi-plus"
              >
                Comment 2
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="modal__comment-accordion-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                </div>
                <div class="modal__comment-accordion-content-footer">
                  <p>29, April 2024</p>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel> -->
          </v-expansion-panels>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { defineEmits } from "vue";
import moment from "moment";

const emits = defineEmits(["on-close"]);
const props = defineProps(["reviewData"]);
const openReviewModal = true;
const onCloseModal = () => {
  emits("on-close");
};
const getFormateDate = (dateString) => {
  return moment(dateString).format("DD, MMMM YYYY");
};
</script>
<script>
export default {
  name: "ProjectReviewCommentModal",
};
</script>
