<template>
  <main
    class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full attachment-list tw-p-4 tw-bg-[#f6f6f8] tw-rounded-xl"
  >
    <div
      class="tw-flex tw-gap-2 tw-items-center tw-justify-start tw-border-0 tw-w-full tw-border-b-dashed-custom tw-pb-4"
    >
      <Text variant="p" whiteSpace="nowrap">Items</Text>
      <InfoIcon />

      <v-icon
        icon="mdi-delete"
        class="trash__icon ml-auto tw-bg-white tw-rounded-full tw-w-[24px] tw-h-[24px] tw-p-1 tw-shrink-0"
        color="#0c0f4a80"
        size="x-small"
        @click="toggleShowDeleteModal"
      />
    </div>
    <el-form
      class="tw-w-full tw-flex tw-gap-4 tw-flex-wrap md:flex-nowrap"
      :model="
        estimateForm.projectStages[phaseIndex].projectStageTasks[itemIndex]
      "
    >
      <el-form-item
        class="tw-w-[40%] tw-max-w-full md:tw-w-full tw-cursor-pointer material__name"
        :class="{ 'is-error': !!subTaskErrors.name }"
      >
        <NoBgInput
          v-model="
            estimateForm.projectStages[phaseIndex].projectStageTasks[itemIndex]
              .name
          "
          placeholder="Material Description"
          @change="updateMaterialPhaseTask"
        />
        <!-- <el-input
          class="no-bg-input"
          :placeholder="placeholder"
          @click="toggleMenu(itemIndex)"
        >
        </el-input> -->

        <!-- <el-popover
          v-model:visible="isShowMenu[itemIndex]"
          :trigger="'click'"
          placement="bottom"
          :reference="`#add-in-group-${itemIndex}`"
          class="attachment-list__menu"
          :teleported="false"
        >
          <div class="attachment-list__menu">
            <div class="attachment-list__menu-header">
              <v-icon icon="mdi-close" @click="closeMenu(itemIndex)"></v-icon>
            </div>

            <div class="attachment-list__menu-inner">
              <div class="attachment-list__menu-group-input">
                <p>Enter Group Name</p>
                <div class="attachment-list__menu-group-input-wrapper">
                  <el-input
                    placeholder="Enter group name"
                    clearable
                    class="attachment-list__menu-input"
                    @input="onChangeCategorySearch"
                  />
                  <el-button
                    type="primary"
                    class="button button-orange"
                    @click="saveGroup"
                  >
                    Save
                  </el-button>
                </div>
              </div>

              <div class="attachment-list__menu-group-list">
                <div
                  v-for="category in filteredUserCategory"
                  :key="category.label"
                  class="attachment-list__menu-group-list-item"
                >
                  <input type="radio" />
                  <p>{{ category.label }}</p>
                </div>
              </div>
            </div>

            
          </div>
        </el-popover> -->
      </el-form-item>
      <el-form-item
        :class="{ 'is-error': !!subTaskErrors.materialUnit }"
        class="tw-w-[20%] tw-max-w-full md:tw-w-[calc(50%-8px)]"
      >
        <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-1">
          <NoBgInput
            type="number"
            min="1"
            v-model.number="
              estimateForm.projectStages[phaseIndex].projectStageTasks[
                itemIndex
              ].materialUnit
            "
            @input="handleMaterialUnit"
            placeholder="Unit"
          />
          <InfoIcon />
        </div>
      </el-form-item>
      <el-form-item
        :class="{ 'is-error': !!subTaskErrors.taskCost }"
        class="tw-w-[20%] tw-max-w-full md:tw-w-[calc(50%-8px)]"
      >
        <div class="tw-flex tw-items-center tw-w-full tw-gap-2">
          <Text variant="p">£</Text>
          <NoBgInput
            @input="handleSubTotalTask($event)"
            @keypress="
              numChecker(
                $event,
                estimateForm.projectStages[phaseIndex].projectStageTasks[
                  itemIndex
                ].taskCost
              )
            "
            placeholder="Unit Cost"
            v-model="
              estimateForm.projectStages[phaseIndex].projectStageTasks[
                itemIndex
              ].taskCost
            "
            :formatter="
              (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
            :parser="(value) => value.replace(/^0+(?=\d)|\$|,/g, '')"
          />
        </div>
      </el-form-item>
      <div class="tw-flex tw-gap-3 tw-items-center tw-max-w-full md:tw-w-[70%]">
        <Text
          variant="span"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          whiteSpace="nowrap"
          >Total item cost:</Text
        >
        <LabelWithValue
          class="!tw-min-w-[200px] md:tw-min-w-[auto] md:tw-w-full md:tw-max-w-full"
          :justifyStart="true"
          :data="formattedCurrency(totalItemCost)"
          label=""
        />
      </div>
    </el-form>
    <section class="tw-flex tw-items-center tw-justify-between tw-w-full">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <!-- <div class="tw-flex tw-items-center tw-gap-1">
          <label
            class="yellow-text tw-capitalize"
            v-if="!assignPhaseMaterial"
            @click="toggleAssignPhaseModal"
            >Apply IBG <v-icon icon="mdi-chevron-right"></v-icon
          ></label>
          <InfoIcon />
        </div> -->
        <div class="tw-flex tw-items-center tw-gap-1">
          <label
            class="yellow-text tw-capitalize tw-text-left"
            v-if="!assignPhaseMaterial"
            @click="toggleAssignPhaseModal"
            >Assign to A phase <v-icon icon="mdi-chevron-right"></v-icon
          ></label>

          <label
            class="yellow-text tw-capitalize"
            v-else
            @click="toggleAssignPhaseModal"
            >Change Assigned Phase <v-icon icon="mdi-chevron-right"></v-icon
          ></label>
          <InfoIcon />
        </div>
        <label
          class="tw-capitalize tw-text-xs tw-font-semibold tw-text-[#0C0F4A] tw-tracking-[0.4px]"
          v-if="assignPhaseMaterial"
          >{{ assignPhaseMaterial?.name }}</label
        >
      </div>
      <!-- <Button
        class="!tw-w-auto tw-text-xs tw-font-semibold tw-text-[#0C0F4A] tw-tracking-[0.4px] button-custom"
        label="Delete Material"
        variant="button-custom"
        :isCapitalize="true"
        @click="toggleShowDeleteModal"
      >
        <template #prefix>
          <TrashIcon />
        </template>
      </Button> -->
    </section>
    <SelectMaterialAssignPhaseModal
      v-if="isShowAssignPhaseModal"
      :assignPhases="assignPhases"
      :selectedAssignPhase="assignPhaseMaterial"
      @on-close="toggleAssignPhaseModal"
      @on-save="onSaveAssignPhase"
    />

    <ConfirmPhaseTaskDeleteModal
      v-if="isShowDeleteModal"
      @on-close="toggleShowDeleteModal"
      @on-confirm="onDeleteMaterialItem"
    />
  </main>
</template>
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import { isNumber } from "@/core/helpers/number.helper";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { EstimatePhaseType } from "@/core/enums/estimateEnum";
import SelectMaterialAssignPhaseModal from "@/modules/jobs/components/modal/SelectMaterialAssignPhaseModal.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import ConfirmPhaseTaskDeleteModal from "../../modal/ConfirmPhaseTaskDeleteModal.vue";

const store = useStore();
const emits = defineEmits(["on-update-material-task"]);
const durationOptions = [
  {
    value: 1,
    label: "Days",
  },
  {
    value: 2,
    label: "Weeks",
  },
];

const props = defineProps(["phaseIndex", "itemIndex", "item"]);
const isShowMenu = ref([]);
const filteredUserCategory = ref([
  { label: "Floor Plan" },
  { label: "Living Room" },
  { label: "Gardening" },
  { label: "Materials" },
  { label: "Maps" },
  { label: "Others" },
]);
const userGroups = ref([
  { id: 1, name: "Group 1" },
  { id: 2, name: "Group 2" },
  { id: 3, name: "Group 3" },
]);
const handleCommand = (command) => {
  if (command === "create") {
    console.log("Create new group clicked");
  } else {
    console.log("Selected group:", command.name);
  }
};
const closeMenu = (index) => {
  isShowMenu.value[index] = false;
};

const toggleMenu = (index) => {
  isShowMenu.value[index] = !isShowMenu.value[index];
};
const onChangeCategorySearch = (value) => {
  console.log("Searching for category:", value);
};

const onSelectCategory = (attachment, category) => {
  attachment.userCategoryName = category.label;
  console.log("Selected category:", category);
};

const saveGroup = () => {
  console.log("Saving group...");
};
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);
const isShowDeleteModal = ref(false);
const toggleShowDeleteModal = () => {
  isShowDeleteModal.value = !isShowDeleteModal.value;
};
const numChecker = (event, value) => {
  return isNumber(event, value);
};

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const onDeleteMaterialItem = async () => {
  if (!isFormReadOnly.value) {
    // Delete the task from estimateForm.projectStages
    const estimatePhaseTask = estimateForm.value.projectStages[
      props.phaseIndex
    ].projectStageTasks.find((data, idx) => idx === props.itemIndex);

    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks =
      estimateForm.value.projectStages[
        props.phaseIndex
      ].projectStageTasks.filter((x, xi) => xi !== props.itemIndex);
    try {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhaseTask`, {
        estimatePhaseTaskId: estimatePhaseTask.id,
      });
    } catch (error) {
      console.log();
    } finally {
      toggleShowDeleteModal();
    }
  }
};
const validCost = computed(() => {
  const MAX_LIMIT = 99999999;
  const cost =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ].taskCost;
  if (cost > MAX_LIMIT) {
    return true;
  }
  return false;
});
const handleSubTotalTask = (value) => {
  if (!value) {
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ].taskCost = "0";
  } else {
    if (!validCost.value) updateMaterialPhaseTask();
  }
};
const handleMaterialUnit = (value) => {
  if (!value) {
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ].materialUnit = "0";
  } else {
    updateMaterialPhaseTask();
  }
};
const totalItemCost = computed(() => {
  const phase = estimateForm.value.projectStages[props.phaseIndex];

  const totalCostPerUnit =
    phase.projectStageTasks[props.itemIndex].taskCost *
    phase.projectStageTasks[props.itemIndex].materialUnit;

  phase.projectStageTasks[props.itemIndex].total = totalCostPerUnit;

  let currentTotal = phase.projectStageTasks.reduce((sum, task, index) => {
    return sum + (parseFloat(task.total) || 0);
  }, 0);
  store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseSubTotal`, {
    phaseIndex: props.phaseIndex,
    newValue: currentTotal,
  });

  return totalCostPerUnit;
});
const updateMaterialPhaseTask = () => {
  const { name, materialUnit, taskCost } =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ];
  if (taskCost) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,

      field: "taskCost",
    });
  }
  if (materialUnit) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,
      field: "materialUnit",
    });
  }
  if (name) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,
      field: "name",
    });
  }
  emits("on-update-material-task");
};

const subTaskErrors = computed(() => {
  const errors =
    store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  if (props.itemIndex !== undefined) {
    return errors[props.phaseIndex]?.subErrors?.[props.itemIndex] || {};
  }
  return {};
});

const isShowAssignPhaseModal = ref(false);
const assignPhaseMaterial = ref([]);
const onSaveAssignPhase = async (payload, phase) => {
  try {
    const isAlready = assignPhaseMaterial.value?.find(
      (data) => data.id === phase?.id
    );
    if (!isAlready) {
      assignPhaseMaterial.value = phase;
      const taskId =
        estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
          props.itemIndex
        ].id;
      await tradeEstimateService.assignPhaseToTaskInEstimate(taskId, payload);
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    isShowAssignPhaseModal.value = false;
  }
};
const toggleAssignPhaseModal = () => {
  isShowAssignPhaseModal.value = !isShowAssignPhaseModal.value;
};
const assignPhases = computed(() => {
  return estimateForm.value.projectStages.filter(
    (phase) => phase.stageType === "labour"
  );
});
onMounted(() => {
  if (estimateForm.value) {
    const isExistPhase =
      estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
        props.itemIndex
      ].assignedPhaseTo;

    if (isExistPhase) {
      assignPhaseMaterial.value = isExistPhase;
    } else assignPhaseMaterial.value = null;
  }
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
.is-error input,
.is-error select {
  border-color: red;
}
.button-custom {
  width: auto !important;
  color: rgba($blueDark, 1);
  @include fluidFont(12, 12, 1.2);
  background-color: transparent;
}
.material__name {
  position: relative;
  :deep(.el-popper) {
    width: 100% !important;
    max-width: 100%;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px #0c0f4a29;

    .attachment-list__menu {
      width: 100%;
      max-width: 100%;
      .attachment-list__menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .v-icon {
          margin-left: auto;
        }
      }
      .attachment-list__menu-inner {
        .attachment-list__menu-group-input {
          padding: 10px;
          background-color: rgba($backgroundGrey, 1);
          border-radius: 4px;
          text-align: left;
          p {
            @include fluidFont(12, 12, 1.3);
            font-weight: 600;
            color: rgba($blueDark, 1);
            margin-bottom: 7px;
          }
          .attachment-list__menu-group-input-wrapper {
            border: 1px solid rgba($buttonText, 0.4);
            border-radius: 6px;
            padding: 4px 4px 4px 6px;
            display: flex;
            align-items: center;
            background-color: #fff;

            .el-input__inner {
              height: 20px;
            }

            .button {
              min-height: 22px;
              height: auto;
              @include fluidFont(8, 8, 1.2);
              font-weight: 700;
              border: 1px solid;
              padding: 6px 12px;
              border-radius: 6px;
            }
          }
        }

        .attachment-list__menu-group-list {
          text-align: left;
          border: 1px solid #80829f66;
          border-radius: 4px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          .attachment-list__menu-group-list-item {
            padding: 6px 12px;
            display: flex;
            gap: 8px;
            align-items: center;
            &:nth-child(even) {
              background-color: rgba($blueDark, 0.04);
            }
            p {
              @include fluidFont(12, 12, 1.3);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
          }
        }
      }
    }
  }
}
</style>
