<template>
  <div
    class="dashboard_calendar_wrapper tw-overflow-hidden tw-border-solid-default tw-rounded-[16px] md:!tw-pb-16 md:!tw-box-border"
  >
    <v-btn
      v-if="getUserWorkStationFieldAccess"
      icon="mdi-plus"
      size="small"
      color="#4B4BFF"
      @click="onClickCreateEventModal"
      class="button button-purple dashboard_button"
    ></v-btn>
    <div class="dashboard_calendar_header">
      <div class="date_selector" id="select-date">
        {{ selectedDate }}
      </div>
    </div>
    <ScheduleXCalendar
      :calendar-app="calendarApp"
      event-modal="modal"
      class="dashboard_calendar sm:!tw-max-h-[unset]"
      :class="`dashboard_calendar_grid_line_${selectedView} ${className}`"
    >
      <!-- WE CAN USE THIS AS CUSTOM COMPONENT -->
      <!-- <template #timeGridEvent="{ calendarEvent }">
        <Card
          :bgColor="'linear-gradient(180deg, #EC8939 0%, #D87525 100%)'"
          class="!tw-rounded-[8px] tw-overflow-hidden tw-px-4 tw-py-5 tw-box-border !tw-min-h-[369px] !tw-h-[359px] !tw-w-full rlg:!tw-w-[400px] rlg:!tw-max-w-[400px]"
        >
          <template #content>
            <Text variant="h6">{{ calendarEvent.title }}</Text>
          </template>
        </Card>
      </template> -->
      <template #eventModal="{ calendarEvent }">
        <div class="calendar_event_modal shadow">
          <h6>{{ calendarEvent?.title }}</h6>
          <p v-if="selectedView == 'month-grid'">
            {{ formatDateRange(calendarEvent?.start, calendarEvent?.end) }}
          </p>
          <p v-else>{{ formatDate(calendarEvent?.start, "fullDayDate") }}</p>

          <div v-if="selectedView == 'week'" class="calendar_time">
            <span>{{ calendarEvent?.start?.split(" ")[1] }}</span> to
            <span>{{ calendarEvent?.end?.split(" ")[1] }}</span>
          </div>
          <div
            v-else-if="selectedView == 'month-grid'"
            class="calendar_time"
          ></div>

          <div v-else class="calendar_time">
            <span>({{ calendarEvent?.start?.split(" ")[1] }} am</span> to
            <span>{{ calendarEvent?.end?.split(" ")[1] }} am)</span>
          </div>
          <div class="calendar_event_modal_footer">
            <div class="calendar_edit_wrapper">
              <v-icon
                @click="fetchModalData(calendarEvent.id)"
                class="edit_modal"
              >
                <img
                  src="../../../../assets/icons/calendar-modal-edit.svg"
                  alt=""
                />
              </v-icon>
              <v-icon
                @click="deleteEvent(calendarEvent.id)"
                class="delete_modal"
              >
                <img
                  src="../../../../assets/icons/calendar-delete-modal.svg"
                  alt=""
                />
              </v-icon>
            </div>
          </div>
        </div>
      </template>
    </ScheduleXCalendar>
    <!-- update Event Modal -->
    <CommonDialog
      v-model="openEditModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      :className="['update_event_modal']"
      width="441px"
      height="auto"
      ><template v-slot:body>
        <div class="modal_content">
          <div class="header">
            <h4>Edit Event</h4>
            <v-btn
              variant="plain"
              icon="mdi-close"
              @click="openEditModal = false"
            ></v-btn>
          </div>
          <div class="modal_form">
            <div class="form_wrapper">
              <div class="list_block__item">
                <div class="event_field">
                  <label>Event Name</label>
                  <v-text-field
                    class="input_field"
                    placeholder="Enter event name"
                    v-model="getCalenderEventById.name"
                    :rules="[
                      (v) => !!v || 'Name is required',
                      (v) => v.length <= 255 || 'Name is too long',
                    ]"
                    variant="outlined"
                  ></v-text-field>
                </div>
                <div class="event_field">
                  <label>Location</label>
                  <v-text-field
                    class="input_field"
                    :rules="[(v) => v.length <= 50 || 'Location is too long']"
                    placeholder="Enter location"
                    v-model="getCalenderEventById.location"
                    variant="outlined"
                  ></v-text-field>
                </div>
                <div class="date_time_selector">
                  <div class="event_field date_selector">
                    <label>Date</label>
                    <DatePicker
                      :min-date="new Date()"
                      v-model="getCalenderEventById.startDate"
                      :popover="true"
                    >
                      <template #default="{ togglePopover, inputValue }">
                        <div
                          class="calendar__select"
                          @click="() => togglePopover()"
                        >
                          <v-text-field
                            :value="inputValue"
                            variant="outlined"
                            placeholder="Choose Date"
                            class="date__start input_field"
                            color="#0C0F4A"
                          >
                            <img
                              src="../../../../assets/icons/personal-calendar.svg"
                              alt=""
                            />
                          </v-text-field>
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                  <!-- From Custom Dropdown -->
                  <div class="event_field time_selector">
                    <label>Choose time</label>
                    <div class="time_wrapper">
                      <div class="time_dropdown">
                        <v-text-field
                          density="compact"
                          placeholder="HH:MM"
                          variant="outlined"
                          v-model="getCalenderEventById.startTime"
                          class="input_field"
                          @click="fromToggleDropdown"
                          :class="{ 'is-open': fromDropdownOpen }"
                        >
                          <v-icon
                            icon="mdi-clock"
                            id="menu-activator"
                            class="cursor-pointer"
                          ></v-icon>
                        </v-text-field>
                        <div
                          v-if="startTimeError"
                          class="text-red error-message tw-text-xs"
                        >
                          {{ startTimeError }}
                        </div>
                        <ul
                          class="custom_dropdown__menu"
                          v-if="fromDropdownOpen"
                        >
                          <li
                            class="custom_dropdown__item"
                            v-for="(time, index) in filteredFromTimes"
                            :key="index"
                            @click="toggleDropdownOutside"
                          >
                            <label
                              @click="getCalenderEventById.startTime = time"
                              for=""
                            >
                              {{ time }}</label
                            >
                          </li>
                        </ul>
                      </div>
                      <span class="time__divider">-</span>
                      <!-- To Custom Dropdown -->
                      <div class="time_dropdown">
                        <v-text-field
                          density="compact"
                          placeholder="HH:MM"
                          variant="outlined"
                          v-model="getCalenderEventById.endTime"
                          class="input_field"
                          @click="toToggleDropdown"
                          :class="{ 'is-open': toDropdownOpen }"
                        >
                          <v-icon
                            icon="mdi-clock"
                            id="menu-activator"
                            class="cursor-pointer"
                          ></v-icon>
                        </v-text-field>
                        <div
                          v-if="endTimeError"
                          class="text-red error-message tw-text-xs"
                        >
                          {{ endTimeError }}
                        </div>
                        <ul class="custom_dropdown__menu" v-if="toDropdownOpen">
                          <li
                            class="custom_dropdown__item"
                            v-for="(time, index) in filteredToTimes"
                            :key="index"
                            @click="toggleDropdownOutside"
                          >
                            <label
                              @click="getCalenderEventById.endTime = time"
                              for=""
                            >
                              {{ time }}</label
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="event_field">
                  <label>Description</label>
                  <v-textarea
                    :rules="[
                      (v) => v.length <= 255 || 'Description is too long',
                    ]"
                    class="input_field"
                    placeholder="Write description.."
                    v-model="getCalenderEventById.description"
                    variant="outlined"
                    rows="2"
                  ></v-textarea>
                </div>
                <div class="event_field">
                  <label>Owner</label>
                  <v-text-field
                    readonly
                    class="input_field"
                    placeholder="Enter location"
                    v-model="ownerEmailInEdit"
                    variant="outlined"
                  ></v-text-field>
                </div>
                <div class="event_field">
                  <label>Invite Members</label>
                  <v-autocomplete
                    v-model="getCalenderEventById.invitePeople"
                    :items="modifiedMembers"
                    class="input_field input_dropdown"
                    color="blue-grey-lighten-2"
                    item-title="email"
                    item-value="email"
                    label="Select"
                    chips
                    closable-chips
                    multiple
                    :menu-props="{ contentClass: 'invite_member_dropdown' }"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :prepend-avatar="item.raw.avatar"
                        :text="item.raw.name"
                      ></v-chip>
                    </template>

                    <template v-slot:item="{ props, item }">
                      <v-list-item v-bind="props">
                        <div class="members_text">
                          <h6>
                            {{ item.raw.userName ? item.raw.userName : "Name" }}
                          </h6>
                          <p>{{ item.raw.email }}</p>
                        </div>
                        <div class="members__img">
                          <img
                            v-if="item.raw?.attachmentSource"
                            :src="item.raw?.attachmentSource"
                          />
                          <div
                            v-else
                            class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                          >
                            <UserProfileLogo :userName="item.raw?.userName" />
                          </div>
                        </div>
                        <v-checkbox
                          disabled
                          v-model="getCalenderEventById.invitePeople"
                          :value="item.raw.email"
                          color="indigo-darken-3"
                        ></v-checkbox
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
            <div class="modal_form_footer">
              <v-btn
                @click="openEditModal = false"
                class="button button-purple-border"
                >Cancel</v-btn
              >
              <v-btn
                class="button button-orange"
                @click="updateData(getCalenderEventById.id)"
                >Update</v-btn
              >
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
    <!-- Create Event Modal -->
    <CommonDialog
      v-model="createEventModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      :className="['update_event_modal']"
      width="441px"
      height="auto"
      ><template v-slot:body>
        <div class="modal_content">
          <div class="header">
            <h4>Add Event</h4>
            <v-btn
              variant="plain"
              icon="mdi-close"
              @click="closeCreateEventModal"
            ></v-btn>
          </div>
          <div class="modal_form">
            <v-form :rules="validation" ref="eventFormRef">
              <div class="form_wrapper">
                <div class="list_block__item">
                  <div class="event_field">
                    <label>Event Name</label>
                    <v-text-field
                      class="input_field"
                      placeholder="Enter event name"
                      v-model="newEvent.title"
                      :rules="validation.name"
                      variant="outlined"
                    ></v-text-field>
                  </div>
                  <div class="event_field">
                    <label>Location</label>
                    <v-text-field
                      class="input_field"
                      placeholder="Enter location"
                      :rules="validation.location"
                      v-model="newEvent.location"
                      variant="outlined"
                    ></v-text-field>
                  </div>
                  <div class="date_time_selector">
                    <div class="event_field date_selector">
                      <label>Date</label>
                      <DatePicker
                        :rules="validation.startDate"
                        :min-date="new Date()"
                        v-model="dataRange"
                        :popover="true"
                      >
                        <template #default="{ togglePopover, inputValue }">
                          <div
                            class="calendar__select"
                            @click="() => togglePopover()"
                          >
                            <v-text-field
                              :value="inputValue"
                              variant="outlined"
                              placeholder="Choose Date"
                              class="date__start input_field"
                              color="#0C0F4A"
                              ><img
                                src="../../../../assets/icons/personal-calendar.svg"
                                alt=""
                              />
                            </v-text-field>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                    <!-- From Custom Dropdown -->
                    <div class="event_field time_selector">
                      <label>Choose time</label>
                      <div class="time_wrapper">
                        <div class="time_dropdown">
                          <v-text-field
                            density="compact"
                            placeholder="HH:MM"
                            variant="outlined"
                            :rules="validation.startTime"
                            v-model="newEvent.startTime"
                            class="input_field"
                            @click="fromToggleDropdown"
                            :class="{ 'is-open': fromDropdownOpen }"
                          >
                            <v-icon
                              icon="mdi-clock"
                              id="menu-activator"
                              class="cursor-pointer"
                            ></v-icon>
                          </v-text-field>
                          <!-- <div
                            v-if="startTimeError"
                            class="text-red error-message tw-text-xs"
                          >
                            {{ startTimeError }}
                          </div> -->
                          <ul
                            class="custom_dropdown__menu"
                            v-if="fromDropdownOpen"
                          >
                            <li
                              class="custom_dropdown__item"
                              v-for="(time, index) in filteredFromTimes"
                              :key="index"
                              @click="toggleDropdownOutside"
                            >
                              <label @click="newEvent.startTime = time" for="">
                                {{ time }}</label
                              >
                            </li>
                          </ul>
                        </div>
                        <span class="time__divider">-</span>
                        <!-- To Custom Dropdown -->
                        <div class="time_dropdown">
                          <v-text-field
                            density="compact"
                            placeholder="HH:MM"
                            variant="outlined"
                            v-model="newEvent.endTime"
                            class="input_field"
                            :rules="validation.endTime"
                            @click="toToggleDropdown"
                            :class="{ 'is-open': toDropdownOpen }"
                          >
                            <v-icon
                              icon="mdi-clock"
                              id="menu-activator"
                              class="cursor-pointer"
                            ></v-icon>
                          </v-text-field>
                          <!-- <div
                            v-if="endTimeError"
                            class="text-red error-message tw-text-xs"
                          >
                            {{ endTimeError }}
                          </div> -->
                          <ul
                            class="custom_dropdown__menu"
                            v-if="toDropdownOpen"
                          >
                            <li
                              class="custom_dropdown__item"
                              v-for="(time, index) in filteredToTimes"
                              :key="index"
                              @click="toggleDropdownOutside"
                            >
                              <label @click="newEvent.endTime = time" for="">
                                {{ time }}</label
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="event_field">
                    <label>Description</label>
                    <v-textarea
                      class="input_field"
                      placeholder="Write description.."
                      v-model="newEvent.description"
                      :rules="validation.description"
                      variant="outlined"
                    ></v-textarea>
                  </div>
                  <div class="event_field">
                    <label>Owner</label>
                    <v-text-field
                      readonly
                      class="input_field"
                      placeholder="Enter location"
                      v-model="eventCreateOwner"
                      variant="outlined"
                    ></v-text-field>
                  </div>
                  <div class="event_field">
                    <label>Invite Members</label>
                    <v-autocomplete
                      v-model="newEvent.member"
                      :items="modifiedMembers"
                      class="input_field input_dropdown"
                      color="blue-grey-lighten-2"
                      item-title="email"
                      item-value="email"
                      label="Select"
                      chips
                      closable-chips
                      multiple
                      :menu-props="{ contentClass: 'invite_member_dropdown' }"
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                          v-bind="props"
                          :prepend-avatar="item.raw.avatar"
                          :text="item.raw.name"
                        ></v-chip>
                      </template>

                      <template v-slot:item="{ props, item }">
                        <v-list-item v-bind="props">
                          <div class="members_text">
                            <h6>
                              {{
                                item.raw.userName ? item.raw.userName : "Name"
                              }}
                            </h6>
                            <p>{{ item.raw.email }}</p>
                          </div>
                          <div class="members__img">
                            <img
                              v-if="item.raw?.attachmentSource"
                              :src="item.raw?.attachmentSource"
                            />
                            <div
                              v-else
                              class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                            >
                              <UserProfileLogo :userName="item.raw?.userName" />
                            </div>
                          </div>
                          <v-checkbox
                            disabled
                            v-model="newEvent.member"
                            :value="item.raw.email"
                            color="indigo-darken-3"
                          ></v-checkbox
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
              <div class="modal_form_footer">
                <v-btn
                  @click="closeCreateEventModal"
                  class="button button-purple-border"
                  >Cancel</v-btn
                >
                <v-btn
                  class="button button-orange"
                  :disabled="!isFormValid"
                  :loading="isUploadEventData"
                  @click="submitData"
                  >Create</v-btn
                >
              </div>
            </v-form>
          </div>
        </div>
      </template>
    </CommonDialog>
    <!-- Delete Modal -->
    <CommonDialog
      v-model="openDeleteModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      :className="['delete_event_modal']"
      width="350px"
      height="auto"
      ><template v-slot:body>
        <div class="modal_content">
          <div class="header">
            <v-btn
              variant="plain"
              icon="mdi-close"
              @click="openDeleteModal = false"
            ></v-btn>
          </div>
          <div class="form_wrapper">
            <div class="modal_form">
              <v-btn variant="plain">
                <img
                  src="../../../../assets/icons/calendar-delete.svg"
                  alt=""
                />
              </v-btn>
              <h6>Delete the event?</h6>
              <p>The event will be permanently deleted.</p>
            </div>
          </div>
          <div class="modal_form_footer">
            <v-btn
              variant="plain"
              @click="openDeleteModal = false"
              class="button button-purple-border"
              >Cancel</v-btn
            >
            <v-btn
              class="button button-orange"
              @click="deletedData(getCalenderEventById?.id)"
              >Delete</v-btn
            >
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>

<script setup>
import { ScheduleXCalendar } from "@schedule-x/vue";
import {
  createCalendar,
  viewDay,
  viewWeek,
  viewMonthGrid,
  viewMonthAgenda,
} from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";
import { createDragAndDropPlugin } from "@schedule-x/drag-and-drop";
import { createEventModalPlugin } from "@schedule-x/event-modal";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { createCurrentTimePlugin } from "@schedule-x/current-time";
import { createScrollControllerPlugin } from "@schedule-x/scroll-controller";
import { ref, watch, computed, onMounted, onBeforeMount } from "vue";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { useStore } from "vuex";
import { DatePicker } from "v-calendar";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { createCalendarControlsPlugin } from "@schedule-x/calendar-controls";
import { CALENDAR_STORE } from "@/store/modules/calendar";
import { USER_STORE } from "@/store/modules/user";
import appFilters from "@/filters";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import moment from "moment";
import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import {
  airSourceHeatPumpData,
  greenSkillsData,
} from "@/modules/courses/data/courseDetails";
import { parseJSON } from "@/core/utils/common";
import { MemberInvitationStatusEnum } from "@/core/enums/RolesEnum";

const openEditModal = ref(false);
const openDeleteModal = ref(false);
const createEventModal = ref(false);
const isUploadEventData = ref(false);
const eventFormRef = ref(null);
const eventsServicePlugin = createEventsServicePlugin();
const store = useStore();
const calendarControls = createCalendarControlsPlugin();
function getScrollTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}
const scrollController = createScrollControllerPlugin({
  initialScroll: getScrollTime(),
});
const isDateAfterCurrentDate = (givenDate) => {
  const today = moment().startOf("day");
  const givenMoment = moment(givenDate, "YYYY-MM-DD");
  return givenMoment.isSameOrAfter(today);
};

const isTimeAfterCurrentTime = (givenTime) => {
  const now = moment();
  if (now.isSame(moment(dataRange.value), "day")) {
    const givenMoment = moment(givenTime, "hh:mm A");
    return givenMoment.isAfter(now);
  } else {
    return true;
  }
};
const validateEndTime = (endTime) => {
  const start = moment(newEvent.value.startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A");
  return end.isAfter(start) && start.isSame(end, "day");
};
const validation = ref({
  name: [
    (v) => !!v || "Name is required.",
    (v) => v.length <= 255 || "Name is too long",
  ],
  location: [
    // (v) => !!v || "Location is required.",
    (v) => v.length <= 50 || "Location is too long",
  ],
  startDate: [
    (v) => !!v || "Start date is required.",
    (v) =>
      isDateAfterCurrentDate(v) ||
      "Date must be  greater than or equal to today's date",
  ],
  startTime: [
    (v) => !!v || "Start time is required.",
    (v) => timeRegex.test(v) || "Invalid time format. Please use HH:MM am/pm.",
    (v) =>
      isTimeAfterCurrentTime(v) || "Time must Be greater than current time.",
  ],
  endTime: [
    (v) => !!v || "End time is required.",
    (v) => timeRegex.test(v) || "Invalid time format. Please use HH:MM am/pm.",
    (v) => validateEndTime(v) || "End time must be later than start time",
  ],
  description: [
    // (v) => !!v || "Description is required.",
    (v) => v.length <= 255 || "Description is too long",
  ],
});
const selectedDate = ref();
const selectedMonth = ref();
const todayDate = ref();
const selectedView = ref();
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const modifiedMembers = ref([]);
const eventCreateOwner = ref("");
const props = defineProps({
  className: String,
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});
const calendarApp = createCalendar({
  locale: "en-US",
  views: [viewDay, viewWeek, viewMonthGrid, viewMonthAgenda],
  plugins: [
    createDragAndDropPlugin(),
    createEventModalPlugin(),
    eventsServicePlugin,
    createCurrentTimePlugin(),
    calendarControls,
    scrollController,
  ],
  defaultView: viewDay.name,
  calendars: {
    purple: {
      colorName: "purple",
      lightColors: {
        main: "#A338D6",
        container: "#F6EBFB",
        onContainer: "#59000d",
      },
      darkColors: {
        main: "#ffc0cc",
        onContainer: "#ffdee6",
        container: "#a24258",
      },
    },
    green: {
      colorName: "green",
      lightColors: {
        main: "#00CA72",
        container: "#E5FAF1",
        onContainer: "#004d3d",
      },
      darkColors: {
        main: "#c0fff5",
        onContainer: "#e6fff5",
        container: "#42a297",
      },
    },
    yellow: {
      colorName: "yellow",
      lightColors: {
        main: "#FFB131",
        container: "#FFF7EA",
        onContainer: "#000",
      },
      darkColors: {
        main: "#e3ae59",
        onContainer: "#c88822",
        container: "#c88822",
      },
    },
  },
  events: [],
  callbacks: {
    onEventUpdate(updatedEvent) {
      getCalenderEventById.value = {
        description: updatedEvent?.description,
        endDate: updatedEvent?.end.split(" ")[0],
        endTime: updatedEvent?.end.split(" ")[1],
        startDate: updatedEvent?.start.split(" ")[0],
        startTime: updatedEvent?.start.split(" ")[1],
        name: updatedEvent?.title,
      };
      updateData(updatedEvent?.id);
    },
    onRangeUpdate(range) {
      const startDate = range.start.split(" ")[0];
      const endDate = range.end.split(" ")[0];
      dataRange.value = new Date(startDate);
      const startMonth = new Date(range.start).getMonth() + 1;
      const endMonth = new Date(range.end).getMonth() + 1;
      selectedMonth.value = formatDate(range.start, "month");
      if (startDate == endDate) {
        const dayViewDate = formatDate(startDate, "fullDate");
        selectedDate.value = moment(dayViewDate).format("ddd, Do MMM");
      } else if (
        formatDate(startDate, "date") !== formatDate(endDate, "date") &&
        startMonth == endMonth
      ) {
        const weekViewDate = formatDateRange(startDate, endDate);
        selectedDate.value = moment(weekViewDate).format("ddd, Do MMM");
      } else {
        const monthViewDate = formatDate(startDate, "month");
        selectedDate.value = moment(monthViewDate).format("ddd, Do MMM");
      }
      getPersonalCalendarEvent(startDate, endDate);
    },
  },
});

const formatDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const formatDate = (date) => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDay = day;
    const formattedMonth = months[month - 1];
    const formattedYear = year;

    return `${formattedDay} ${formattedMonth}`;
  };

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);
  const year = start.getUTCFullYear();

  return `${formattedStart} - ${formattedEnd} ${year}`;
};

const formatDate = (dateString, format) => {
  const date = new Date(dateString);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  switch (format) {
    case "fullDayDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      const dayOfWeek = date.getUTCDay();
      return `${day} ${monthsOfYear[month]} ${year}, ${daysOfWeek[dayOfWeek]}`;
    }
    case "fullDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${day} ${monthsOfYear[month]}, ${year}`;
    }
    case "day-date-month": {
      const dayOfWeek = date.getUTCDay();
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      return `${daysOfWeek[dayOfWeek]} , ${day} ${month}`;
    }
    case "date": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    }
    case "yyyy-mm-dd": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    case "yyyy-mm-dd+1": {
      const day = date.getUTCDate() + 1;
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    case "month": {
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${monthsOfYear[month]} ${year}`;
    }
    case "month-date": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const nextDay = day + 1;
      const monthName = monthsOfYear[month];
      return `${monthName} ${day} - ${monthName} ${nextDay}`;
    }
    default: {
      throw new Error("Invalid format type");
    }
  }
};

const getCalenderEventById = ref({});
const newEvent = ref({
  title: "",
  description: "",
  location: "",
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  member: [],
});

const isFormValid = computed(() => {
  return (
    newEvent.value.title &&
    newEvent.value.title.length <= 255 &&
    newEvent.value.location?.length <= 255 &&
    newEvent.value.description?.length <= 255 &&
    dataRange.value
  );
});

const getCourseEventToBeCreated = computed(
  () => store.getters[`${COURSES_STORE}/getCourseEventToBeCreated`]
);

const fetchModalData = (id) => {
  try {
    getCalenderEventById.value = personalCalendarList.value.find(
      (event) => event.id === id
    );
    getCalenderEventById.value.invitePeople =
      typeof getCalenderEventById.value.invitePeople === "string"
        ? parseJSON(getCalenderEventById.value.invitePeople)
        : getCalenderEventById.value.invitePeople;
    openEditModal.value = true;
  } catch (error) {
    console.log(error);
  }
};

createCurrentTimePlugin({
  // Whether the indicator should be displayed in the full width of the week. Defaults to false
  fullWeekWidth: true,
});

const convertDateFormate = (inputDate) => {
  // Extracting date components
  const dateComponents = inputDate.split(" ");
  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const month = monthMap[dateComponents[1]];
  const day = dateComponents[2];
  const year = dateComponents[3];
  const time = dateComponents[4].slice(0, -3);
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];
  const outputDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return outputDate;
};

watch(
  () => store.state.events,
  (newEvents) => {
    if (!store.state.deleteEvent) {
      calendarApp.events.set(newEvents);
    } else {
      store.commit("setDeleteEventAction", false);
    }
  },
  { deep: true }
);
const dataRange = ref(new Date());
const submitData = async () => {
  const isFormValid = await eventFormRef.value.validate();
  if (!isFormValid.valid) return;
  try {
    isUploadEventData.value = true;
    newEvent.value?.member.push(user.value?.email);
    await store.dispatch(`${CALENDAR_STORE}/createPersonalEvent`, {
      name: newEvent.value?.title,
      description: newEvent.value?.description,
      location: newEvent.value?.location,
      startDate: formatDate(dataRange.value, "yyyy-mm-dd"),
      endDate: formatDate(dataRange.value, "yyyy-mm-dd"),
      startTime: newEvent.value?.startTime,
      endTime: newEvent.value?.endTime,
      userId: user.value?.id,
      invitePeople: JSON.stringify(newEvent.value?.member),
    });
    calendarControls.setDate(moment(dataRange.value).format("YYYY-MM-DD"));
    await getPersonalCalendarEvent(
      formatDate(dataRange.value, "yyyy-mm-dd"),
      formatDate(dataRange.value, "yyyy-mm-dd")
    );
  } catch (err) {
    console.log(err);
  } finally {
    isUploadEventData.value = false;
    createEventModal.value = false;
    fromDropdownOpen.value = false;
    toDropdownOpen.value = false;
  }
};

// const createEvent = async (newEvent) => {
//   try {
//     isUploadEventData.value = true;
//     await store.dispatch(`${CALENDAR_STORE}/createPersonalEvent`, {
//       name: newEvent?.title,
//       description: newEvent?.description,
//       location: newEvent?.location,
//       startDate: newEvent?.startDate,
//       endDate: newEvent?.endDate,
//       startTime: newEvent?.startTime,
//       endTime: newEvent?.endTime,
//       userId: user.value?.id,
//       invitePeople: JSON.stringify(newEvent?.member),
//     });
//   } catch (err) {
//     console.log();
//   } finally {
//     isUploadEventData.value = false;
//     createEventModal.value = false;
//     fromDropdownOpen.value = false;
//     toDropdownOpen.value = false;
//   }
// };

const updateData = async (id) => {
  const index = personalCalendarList.value.find((event) => event.id === id);
  const updateEvents = {
    id: index?.id,
    title: index?.name,
    start: `${
      dataRange.value
        ? formatDate(dataRange.value, "yyyy-mm-dd")
        : index?.startDate
    } ${index?.startTime}`,
    end: `${
      dataRange.value
        ? formatDate(dataRange.value, "yyyy-mm-dd")
        : index?.startDate
    } ${index?.endTime}`,
    description: index?.description,
  };

  await store.dispatch(`${CALENDAR_STORE}/updateCalendarEvent`, {
    TraderCalenderId: index?.id,
    updateData: {
      name: getCalenderEventById.value?.name
        ? getCalenderEventById.value?.name
        : index?.name,
      description: getCalenderEventById.value?.description
        ? getCalenderEventById.value?.description
        : index?.description,
      location: index?.location,
      startDate: formatDate(index?.startDate, "yyyy-mm-dd"),
      endDate: formatDate(index?.startDate, "yyyy-mm-dd"),
      startTime: getCalenderEventById.value?.startTime
        ? getCalenderEventById.value?.startTime
        : index?.startTime,
      endTime: getCalenderEventById.value?.endTime
        ? getCalenderEventById.value?.endTime
        : index?.endTime,
      invitePeople: JSON.stringify(
        getCalenderEventById.value?.invitePeople
          ? getCalenderEventById.value?.invitePeople
          : index?.invitePeople
      ),
    },
  });
  eventsServicePlugin.update(updateEvents);
  calendarControls.setDate(moment(index?.startDate).format("YYYY-MM-DD"));
  await getPersonalCalendarEvent(
    formatDate(getCalenderEventById.value.startDate, "yyyy-mm-dd"),
    formatDate(getCalenderEventById.value.startDate, "yyyy-mm-dd")
  );
  openEditModal.value = false;
  getCalenderEventById.value = {};
};

const deleteEvent = (id) => {
  openDeleteModal.value = true;
  getCalenderEventById.value = personalCalendarList.value.find(
    (event) => event.id === id
  );
};

const deletedData = async (id) => {
  personalCalendarList.value = await store.dispatch(
    `${CALENDAR_STORE}/deleteCalendarEvent`,
    {
      TraderCalenderId: id,
    }
  );
  openDeleteModal.value = false;
  eventsServicePlugin.remove(getCalenderEventById.value?.id);
  await getPersonalCalendarEvent(
    getCalenderEventById.value?.startDate,
    getCalenderEventById.value?.endDate
  );
};

const personalCalendarList = ref();

const getPersonalCalendarEvent = async (startDate, endDate) => {
  const todayDate = new Date();
  let params = {
    userId: user.value?.id,
    startDate: startDate
      ? formatDate(startDate, "yyyy-mm-dd")
      : formatDate(todayDate, "yyyy-mm-dd"),
    endDate: endDate
      ? formatDate(endDate, "yyyy-mm-dd")
      : formatDate(todayDate, "yyyy-mm-dd"),
  };

  params = Object.fromEntries(
    Object.entries(params).filter((value) => (value[1] === 0 ? true : value[1]))
  );

  personalCalendarList.value = await store.dispatch(
    `${CALENDAR_STORE}/getPersonalCalendarEvent`,
    {
      params: params,
    }
  );
  if (personalCalendarList.value?.length) {
    const colors = ["purple", "yellow", "green"];

    const events = personalCalendarList.value.map((event, index) => ({
      id: event.id,
      title: event.name,
      start: `${event.startDate} ${convertTo24Hour(event.startTime)}`,
      end: `${event.endDate} ${convertTo24Hour(event.endTime)}`,
      description: event.description,
      calendarId: colors[index % colors.length],
    }));
    // for (let i = 0; i < personalCalendarList.value?.length; i++) {
    //   eventsServicePlugin.remove(personalCalendarList.value[i].id);
    // }
    calendarApp.events.set([]);
    if (events) {
      // events.forEach((event) => eventsServicePlugin.add(event));
      calendarApp.events.set(events);
    }
  }
};

function convertTo24Hour(time) {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
}
const onClickCreateEventModal = () => {
  createEventModal.value = true;
  newEvent.value.title = "";
  newEvent.value.description = "";
  newEvent.value.startTime = null;
  newEvent.value.endTime = null;
  newEvent.value.member = [];
  newEvent.value.location = "";
  // dataRange.value = new Date();
};

const fromTime = ref(null);
const toTime = ref(null);
const times = ref([]);
const filteredFromTimes = ref([...times.value]);
const filteredToTimes = ref([...times.value]);
const populateTimes = () => {
  times.value = [];
  const currentTime = moment();

  const date = new Date("12/12/2000 12:00:00 AM");

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j = j + 15) {
      date.setHours(i);
      date.setMinutes(j);

      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const timeMoment = moment(formattedTime, "hh:mm A");

      // Check if the current time is before the generated time
      if (currentTime.isSame(moment(dataRange.value), "day")) {
        if (timeMoment.isAfter(currentTime)) {
          times.value.push(formattedTime);
        }
      } else {
        times.value.push(formattedTime);
      }
    }
  }
  times.value.push("11:59 PM");
  filteredFromTimes.value = [...times.value];
  filteredToTimes.value = [...times.value];
};

const updateToTime = () => {
  if (
    newEvent.value.endTime &&
    times.value.indexOf(newEvent.value.endTime) <=
      times.value.indexOf(newEvent.value.startTime)
  ) {
    newEvent.value.endTime = null;
  }
};

const isDisabled = (time, fromTimeValue) => {
  if (!fromTimeValue) return false;
  return times.value.indexOf(time) <= times.value.indexOf(fromTimeValue);
};

// populateTimes();

watch(
  () => dataRange.value,
  (newVal) => {
    populateTimes();
  },
  { immediate: true }
);

const setSelectedDayToday = () => {
  selectedView.value = "day";
  const date = new Date();
  todayDate.value = new Date();
  selectedDate.value = moment(date).format("ddd, Do MMM");
  selectedMonth.value = formatDate(date, "month");
  getPersonalCalendarEvent();
};

const teamMemberList = ref([]);

const getTeamMember = async () => {
  try {
    const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });

    teamMemberList.value = response;
  } catch (err) {
    console.log();
  }
};

const getWorkstationProfile = async (profileImage) => {
  if (!profileImage) {
    return null;
  }
  try {
    const imagePath = profileImage;
    const response = await store.dispatch(`${USER_STORE}/getImage`, imagePath);
    if (response) {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const modifyMembersList = async () => {
  if (!teamMemberList.value && !teamMemberList.value.length) {
    return;
  }
  try {
    const defaultMember = teamMemberList.value[0];
    const { profileImage } = defaultMember?.userWorkstation?.user;

    const source = await getWorkstationProfile(profileImage);

    teamMemberList.value.forEach((member, index) => {
      if (
        member?.invitationStatus === MemberInvitationStatusEnum.ACCEPTED &&
        member?.toUser?.id !== user.value?.id
      ) {
        if (index === 0) {
          modifiedMembers.value.push({
            ...member,
            attachmentSource: source,
          });
        } else {
          modifiedMembers.value.push({
            ...member,
          });
        }
      }
    });
  } catch (error) {
    console.log();
  }
};

// Convert time to Date object
const timeToDate = (time) => {
  const [hourMinute, period] = time.split(" ");
  let [hour, minute] = hourMinute.split(":");
  hour = parseInt(hour);
  minute = parseInt(minute);
  if (period.toLowerCase() === "pm" && hour !== 12) hour += 12;
  if (period.toLowerCase() === "am" && hour === 12) hour = 0;
  return new Date(2000, 0, 1, hour, minute);
};

const fromDropdownOpen = ref(false);
const toDropdownOpen = ref(false);

const startTimeError = ref("");
const endTimeError = ref("");
const loading = ref(false);
const listOfEnrollCourse = ref([]);

// Toggle the from time dropdown
const fromToggleDropdown = () => {
  newEvent.value.startTime = null;
  newEvent.value.endTime = null;
  fromDropdownOpen.value = !fromDropdownOpen.value;
};

// Toggle the to time dropdown
const toToggleDropdown = () => {
  newEvent.value.endTime = null;
  if (
    newEvent.value.startTime != null ||
    getCalenderEventById.value.startTime != null
  ) {
    toDropdownOpen.value = !toDropdownOpen.value;
  }
};

// Close the both time dropdowns (start and end)
const toggleDropdownOutside = () => {
  if (fromDropdownOpen.value) {
    fromDropdownOpen.value = false;
  }
  if (toDropdownOpen.value) {
    toDropdownOpen.value = false;
  }
};

// Close the dropdowns and create event modal
const closeCreateEventModal = () => {
  createEventModal.value = false;
  fromDropdownOpen.value = false;
  toDropdownOpen.value = false;
};

// Regex for validating 12-hour time format
const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/i;

// Common callback function for watching times
const watchTimesCallback = (newValue) => {
  const currentTime = moment();
  const timeToDate = (time) => {
    return moment(time, "HH:mm A");
  };
  fromDropdownOpen.value = false;
  toDropdownOpen.value = false;
  // Validate and filter startTime
  if (newValue.startTime && typeof newValue.startTime === "string") {
    const startTimeMoment = timeToDate(newValue.startTime);

    if (!timeRegex.test(newValue.startTime)) {
      startTimeError.value = "Invalid time format. Please use HH:MM am/pm";
    } else if (
      currentTime.isSame(moment(dataRange.value), "day") &&
      startTimeMoment.isSameOrBefore(currentTime)
    ) {
      startTimeError.value = "Start time must be greater than current time.";
    } else {
      startTimeError.value = "";
      filteredFromTimes.value = times.value.filter((time) =>
        time.toLowerCase().includes(newValue.startTime.toLowerCase())
      );
    }
  } else {
    startTimeError.value = "";
    filteredFromTimes.value = [...times.value];
  }

  // Validate and filter endTime
  if (newValue.endTime && typeof newValue.endTime === "string") {
    const endTimeMoment = timeToDate(newValue.endTime);

    if (!timeRegex.test(newValue.endTime)) {
      endTimeError.value = "Invalid time format. Please use HH:MM am/pm";
    } else if (
      newValue.startTime &&
      typeof newValue.startTime === "string" &&
      timeRegex.test(newValue.startTime)
    ) {
      const startTimeMoment = timeToDate(newValue.startTime);

      if (endTimeMoment.isSameOrBefore(startTimeMoment)) {
        endTimeError.value = "End time must be later than start time";
      } else {
        endTimeError.value = "";
        filteredToTimes.value = times.value.filter((time) =>
          time.toLowerCase().includes(newValue.endTime.toLowerCase())
        );
      }
    } else {
      endTimeError.value = "";
      filteredToTimes.value = times.value.filter((time) =>
        time.toLowerCase().includes(newValue.endTime.toLowerCase())
      );
    }
  } else {
    endTimeError.value = "";
    filteredToTimes.value = [...times.value];
  }
};

const getEnrollCourseData = async () => {
  try {
    loading.value = true;
    let response = await store.dispatch(`${COURSES_STORE}/getEnrollCourses`, {
      userId: user.value.id,
    });
    // listOfCourse.value = response.data;
    // coursesWithExtraInfo.value = response.data.map((course: any) => ({
    //   ...course,
    //   thumbnail: "sample-picture-2.jpg",
    //   courseMode: "In-Person & E-Learning",
    //   templateComponentName: "AirSourceHeatPumpTemplate",
    //   date: "31-12-2023",
    // }));
    if (response.data) {
      listOfEnrollCourse.value = response.data.map((items) => {
        if (items?.userCourseSchedules[0]?.id) {
          if (items.course.id === 1) {
            return {
              ...items,
              course: {
                ...items.course,
                ...airSourceHeatPumpData,
              },
              courseMode: "In-Person & E-Learning",
            };
          }
          if (items.course.id === 2) {
            return {
              ...items,
              course: {
                ...items.course,
                ...greenSkillsData,
              },
              courseMode: "In-Person & E-Learning",
            };
          }
        }
      });
      listOfEnrollCourse.value = listOfEnrollCourse.value.filter((item) => {
        if (item !== null) {
          return item;
        }
      });
    }
  } catch (error) {
    console.log("error:", error);
  } finally {
    loading.value = false;
  }
};

// Helper function to format ISO date to 'YYYY-MM-DD'
const formatISODateToShortDate = (isoDate) => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Helper function to format ISO date to 'YYYY-MM-DD'
// const formatISODateToShortDate = (isoDate) => {
//   return new Date(isoDate).toISOString().split("T")[0];
// };

// Helper function to format ISO date to 'YYYY-MM-DD' and add 1 day USE THIS FOR COURSE SCHEDULE
// const formatISODateToShortDate = (isoDate) => {
//   const date = new Date(isoDate);
//   date.setDate(date.getDate() + 1); // Add 1 day
//   return date.toISOString().split("T")[0];
// };

// Utility function to format dates
const formatDateAndTime = (date, format) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Adjust based on format pattern
  if (format === "yyyy-mm-dd") {
    return date.toISOString().split("T")[0];
  } else if (format === "hh:mm A") {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return date.toLocaleDateString("en-US", options);
};

// Helper function to calculate end date based on duration
function calculateEndDate(startDate, duration) {
  const start = new Date(startDate);
  const end = new Date(start);
  end.setDate(start.getDate() + duration); // Assuming duration is in days
  return formatDateAndTime(end, "yyyy-mm-dd");
}

// Helper function to format time
function formatTime(dateTime) {
  const date = new Date(dateTime);
  return formatDateAndTime(date, "hh:mm A");
}

// Helper function to add hours to a time
function addHoursToTime(time, hours) {
  const [hour, minute] = time.split(":");
  let date = new Date();
  date.setHours(parseInt(hour), parseInt(minute));
  date.setHours(date.getHours() + hours);
  return formatDateAndTime(date, "hh:mm A");
}

const ownerEmailInEdit = computed(() => {
  if (getCalenderEventById.value?.userId?.email) {
    return getCalenderEventById.value?.userId?.email;
  }
  return "";
});

const createEventForReserevedSpots = async (storageReserveSpotsData) => {
  const convertedStorageReserveSpotsData = JSON.parse(storageReserveSpotsData);
  console.log(
    convertedStorageReserveSpotsData,
    "convertedStorageReserveSpotsData"
  );
  if (!convertedStorageReserveSpotsData) return;
  // console.log(
  //   convertedStorageReserveSpotsData,
  //   "converted storageReserveSpotsData in calendar"
  // );

  // Create events in parallel
  const createEventPromises = convertedStorageReserveSpotsData.flatMap(
    (schedule) =>
      schedule?.userCourseSchedules.map(async (userSchedule) => {
        const startDate = formatISODateToShortDate(
          userSchedule.courseSchedule.startDate
        );
        // const endDate = calculateEndDate(
        //   startDate,
        //   userSchedule.courseSchedule.duration
        // );
        // const todayDate = new Date();
        // const formattedTodayDate = formatDate(todayDate, "yyyy-mm-dd");

        const startTime = formatTime(userSchedule.courseSchedule.startDate);
        const endTime = addHoursToTime(startTime, 3);

        // if (startDate === formattedTodayDate) {
        // Create event for each schedule
        return store.dispatch(`${CALENDAR_STORE}/createPersonalEvent`, {
          name: schedule.course.name,
          description: schedule.course.about.substring(0, 255),
          location: userSchedule.courseSchedule.address,
          startDate: startDate,
          endDate: startDate,
          startTime: startTime,
          endTime: endTime,
          userId: user.value?.id,
          invitePeople: JSON.stringify([]),
        });
        // } else {
        //   return;
        // }
      })
  );

  await Promise.all(createEventPromises);

  if (getCourseEventToBeCreated.value) {
    await store.dispatch(`${COURSES_STORE}/setCourseEventToBeCreated`, null);
  }
};

const createEventForEnrolledCourse = async () => {
  let personalCalendarEvents = [];

  if (!listOfEnrollCourse.value.length) {
    return;
  }

  // Extract start dates from userCourseSchedules
  const courseEventDates = listOfEnrollCourse.value.flatMap((course) =>
    course.userCourseSchedules.map(
      (schedule) => schedule.courseSchedule.startDate
    )
  );

  // Loop through each start date, format it, and call the getPersonalCalendarEvent api
  for (let startDate of courseEventDates) {
    const formattedDate = formatISODateToShortDate(new Date(startDate));

    // Call the API for each formatted date
    let params = {
      userId: user.value?.id,
      startDate: formattedDate,
      endDate: formattedDate,
    };

    params = Object.fromEntries(
      Object.entries(params).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );

    const calendarEvents = await store.dispatch(
      `${CALENDAR_STORE}/getPersonalCalendarEvent`,
      {
        params: params,
      }
    );

    // Add the result to personalCalendarList
    if (calendarEvents && calendarEvents.length) {
      personalCalendarEvents.push(...calendarEvents);
    }
  }

  let missingSchedules = listOfEnrollCourse.value.filter((schedule) => {
    return schedule.userCourseSchedules.some((userSchedule) => {
      const scheduleStartDate = formatISODateToShortDate(
        userSchedule.courseSchedule.startDate
      );
      const courseName = schedule.course.title;

      // Check if the schedule's start date and course name exist in the eventList
      const eventExists = personalCalendarEvents.some((event) => {
        return (
          event.startDate == scheduleStartDate && event.name === courseName
        );
      });

      // Only return schedules not found in the event list
      return !eventExists;
    });
  });

  // Create events in parallel
  const createEventPromises = missingSchedules.flatMap((schedule) =>
    schedule.userCourseSchedules.map(async (userSchedule) => {
      const startDate = formatISODateToShortDate(
        userSchedule.courseSchedule.startDate
      );
      // const endDate = calculateEndDate(
      //   startDate,
      //   userSchedule.courseSchedule.duration
      // );
      // const todayDate = new Date();
      // const formattedTodayDate = formatDate(todayDate, "yyyy-mm-dd");

      const startTime = formatTime(userSchedule.courseSchedule.startDate);
      const endTime = addHoursToTime(startTime, 3);

      // if (startDate === formattedTodayDate) {
      // Create event for each schedule
      return store.dispatch(`${CALENDAR_STORE}/createPersonalEvent`, {
        name: schedule.course.title,
        description: schedule.course.about.substring(0, 255),
        location: userSchedule.courseSchedule.address,
        startDate: startDate,
        endDate: startDate,
        startTime: startTime,
        endTime: endTime,
        userId: user.value?.id,
        invitePeople: JSON.stringify([]),
      });
      // } else {
      //   return;
      // }
    })
  );

  await Promise.all(createEventPromises);
  missingSchedules = [];

  // After creating events, refresh the event list
  // await getPersonalCalendarEvent(
  //   formatDate(dataRange.value, "yyyy-mm-dd"),
  //   formatDate(dataRange.value, "yyyy-mm-dd")
  // );
};

watch(
  () => getCourseEventToBeCreated.value,
  async (newVal) => {
    if (newVal) {
      await createEventForReserevedSpots(newVal);
    }
  },
  { immediate: true, deep: true }
);

// Watch both newEvent and getCalenderEventById
watch(newEvent, watchTimesCallback, { deep: true });
watch(getCalenderEventById, watchTimesCallback, { deep: true });

onBeforeMount(async () => {
  selectedView.value = "day";
  const date = new Date();
  todayDate.value = new Date();
  // selectedDate.value = formatDate(date, "date");
  selectedDate.value = moment(date).format("ddd, Do MMM");
  selectedMonth.value = formatDate(date, "month");
  getPersonalCalendarEvent();
  await getEnrollCourseData();
  await getTeamMember();
  await modifyMembersList();
  const storageReserveSpotsData = localStorage.getItem(
    "success_payment_with_reserve_spots_data"
  );
  // console.log(
  //   storageReserveSpotsData,
  //   "storageReserveSpotsData sss test in calendarrrr"
  // );
  // console.log(
  //   typeof storageReserveSpotsData,
  //   "storageReserveSpotsData type of"
  // );
  await createEventForReserevedSpots(storageReserveSpotsData);
  // await createEventForEnrolledCourse();
});

onMounted(() => {
  eventCreateOwner.value = user.value?.email;
});
</script>

<style lang="scss">
@import "@/modules/dashboard/styles/DashboardCalenderComponent.scss";

.input_field {
  label {
    @include fluidFont(12, 12, 2);
    font-weight: 600;
    color: rgba($blueDark, 1);
    letter-spacing: 0.15px;
  }
  .v-input {
    margin: 0;
    .v-input__control {
      .v-field {
        border: 1px solid rgba($blueDark, 0.2);
        border-radius: 8px;
        .v-field__field {
          .v-field__input {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
}
#select-date {
  pointer-events: none;
  margin-top: -12px;
}
.sx__date-input {
  margin-left: 20px;
  height: 30px;
  opacity: 0;
}

.custom-outer-grid {
  height: 4.16667%;
  width: auto !important;

  .custom-inner-grid {
    position: relative;
    height: 100%;
    top: 13.5417%;
    left: 0%;
    background-color: #fff7ea;
    color: #000;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 4px solid #ffa71a;
    width: auto;
    padding: 4px;
    position: absolute;
    border-radius: 4px;
    font-size: 0.75rem;

    .custom-event-title {
      font-weight: 600;
      top: 13.5417%;
      height: 9.375%;
      left: 0%;
      width: 100%;
      background-color: #fff7ea;
      color: #000;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 0px;
    }

    .custom-event-time {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-top: 0.75rem;
    }
  }
}
.custom-modal {
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
</style>
