<template>
  <div
    id="gantt-chart"
    :class="{ 'full-screen-chart-container': isFullscreen }"
  >
    <div
      class="schedule-page__title tw-flex tw-items-center tw-justify-between tw-bg-white tw-px-5 tw-rounded-t-lg lg:tw-py-4 lg:tw-items-end"
    >
      <div
        class="right_block tw-py-3.5 tw-px-2 tw-rounded-lg tw-border-[#FFA500] tw-border-2 lg:tw-py-2 lg:tw-px-0 tw-ml-auto"
      >
        <v-icon
          v-if="isMobileView && !isNotSupportFullScreenMode"
          @click="toggleFullscreen"
        >
          <img
            v-if="!isFullscreen"
            :src="require('@/assets/icons/toggle-mobile-open-fullview.svg')"
            alt=""
          />
          <img
            v-else
            :src="require('@/assets/icons/toggle-mobile-close-fullview.svg')"
            alt=""
          />
        </v-icon>
      </div>
    </div>
    <section
      class="schedule-page__content tw-flex tw-gap-4 tw-h-full tw-bg-white tw-rounded-2 tw-flex-col lg:tw-bg-transparent lg:!tw-px-0 lg:!tw-pt-0 md:!tw-mt-[74px]"
      :class="{ 'mobile-view': isMobileView }"
    >
      <div
        ref="ganttChartContainerRef"
        class="task-content-wrapper tw-flex lg:tw-flex-col-reverse"
      >
        <div
          class="custom-class tw-sticky tw-left-0 tw-bg-white tw-z-10 tw-top-0 tw-pl-5 tw-overflow-y-auto tw-overflow-x-hidden tw-w-[300px] lg:tw-w-full lg:tw-pl-0 lg:tw-overflow-x-hidden lg:tw-order-1"
        >
          <div
            class="tw-h-[95px] tw-w-[200px] tw-bg-white lg:tw-h-[79px] lg:tw-hidden"
          ></div>
          <div
            ref="sideTaskRef"
            v-show="!(isMobileView && isFullscreen)"
            class="schedule-page__list-container tw-w-auto lg:tw-w-full lg:tw-bg-white lg:tw-rounded-lg lg:tw-shadow-[0px 0px 8px 0px #0C0F4A14] lg:tw-p-4 tw-overflow-auto tw-h-[400px] lg:tw-h-[250px]"
          >
            <SmallPhaseCollapseItem
              v-if="projectEstimatePhases.length"
              :chartClickTask="chartClickTask"
              :estimatePhases="projectEstimatePhases"
              @on-click-task="onClickSideBarTask"
            />
          </div>
        </div>
        <div
          ref="chartScrollContainerRef"
          class="work_bars tw-w-[80%] lg:tw-order-2 lg:tw-w-full lg:tw-max-w-full"
        >
          <div class="chart-container-header tw-sticky tw-w-full">
            <ul class="month_wrapper">
              <li
                v-for="monthYear of getMonthYearGrids"
                :key="monthYear.displayMonth"
                class="month_wrapper_item lg:tw-bg-white"
                :style="{ left: `${monthYear.count * 50}px` }"
              >
                {{ monthYear.displayMonth }}
              </li>
            </ul>

            <ul class="chart-values lg:tw-mt-0">
              <li
                v-for="(grid, index) in grids"
                :key="index"
                :class="{ weekend: grid.isWeekend }"
                class="lg:tw-bg-white"
              >
                <div class="date_wrapper">{{ grid.date }}</div>
                <div class="day_wrapper">{{ grid.day }}</div>
              </li>
            </ul>
          </div>
          <div
            class="chart-container"
            :style="{ width: `${getChartContainerWidth}px` }"
            @mousedown="onMouseDown($event)"
            @touchstart="onTouchStart($event)"
            ref="chartBoxRef"
            :class="{ 'chart-bars-mobile': isMobileView && isFullscreen }"
          >
            <draggable
              v-if="projectEstimatePhases.length"
              v-model="projectEstimatePhases"
              group="tasks"
              @end="onDragEnd"
              :animation="400"
              itemKey="id"
              handle=".drag-handle"
              :force-fallback="true"
              :fallback-on-body="true"
              fallback-class="fallback"
              :draggable="'.draggable-item'"
            >
              <template #item="{ element: phase, index: mainTaskIndex }">
                <div
                  :class="{ 'draggable-item': !phase?.isLock }"
                  class="task-container tw-border-dashed tw-border-b tw-border-b-black tw-border-0 tw-rounded-none tw-border-opacity-10"
                >
                  <div
                    class="task-wrapper"
                    :id="`task-box-${mainTaskIndex}`"
                    :data-mainindex="mainTaskIndex"
                  >
                    <div
                      :id="`maintask-${mainTaskIndex}`"
                      :data-isphase="true"
                      class="phase-task tw-text-white tw-text-xs tw-font-bold tw-h-12"
                      :style="{ 'background-color': phase?.color || 'blue' }"
                      :data-color="phase.color"
                      :data-mainindex="mainTaskIndex"
                      :data-duration="phase.phaseStartDate"
                      :data-enddate="phase.phaseEndDate"
                    >
                      <div
                        class="taskbar_inner_content"
                        :data-isphase="true"
                        :data-mainindex="mainTaskIndex"
                      >
                        <div
                          v-if="!phase?.isLock"
                          :data-isphase="true"
                          :data-mainindex="mainTaskIndex"
                          class="phase_bar_btn bar_btn_left left-bar-btn"
                          :style="{
                            width: `${isMobileView ? 10 : 5}px`,
                            height: `${isMobileView ? 38 : 35}px`,
                          }"
                        ></div>
                        <div
                          class="left_block"
                          :data-isphase="true"
                          :data-mainindex="mainTaskIndex"
                        >
                          <div class="drag-handle" v-if="!phase?.isLock">
                            <v-icon icon="mdi-dots-vertical"></v-icon>
                            <v-icon icon="mdi-dots-vertical"></v-icon>
                          </div>

                          <div
                            :data-isphase="true"
                            :data-mainindex="mainTaskIndex"
                            class="phase_task_text"
                          >
                            <span
                              :data-isphase="true"
                              :data-mainindex="mainTaskIndex"
                              @mousemove="cursorMoveOnTooltip"
                            >
                              {{ phase?.name }}
                              <v-tooltip
                                :open-on-click="isMobileView"
                                activator="parent"
                                location="top"
                                :target="[tooltipPoints.x, tooltipPoints.y]"
                                :disabled="isScrolling"
                                class="tooltip_wrapper"
                              >
                                <div class="tw-flex tw-items-center tw-gap-3">
                                  <span class="tw-text-white">
                                    {{ phase?.name }}
                                  </span>
                                  <div
                                    class="task_images"
                                    v-if="phase?.memberProfileImages?.length"
                                  >
                                    <div
                                      class="task_images_list"
                                      v-for="profile in phase?.memberProfileImages"
                                      :key="profile.workStationName"
                                    >
                                      <img
                                        v-if="profile.imageUrl"
                                        :src="profile.imageUrl"
                                        alt="imageUrl"
                                      />
                                      <UserProfileLogo
                                        v-else
                                        :userName="profile.workStationName"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </v-tooltip>
                            </span>
                          </div>
                        </div>
                        <div
                          class="right_block"
                          :data-isphase="true"
                          :data-mainindex="mainTaskIndex"
                        >
                          <div
                            class="task_images"
                            v-if="phase?.memberProfileImages?.length"
                          >
                            <div
                              class="task_images_list"
                              v-for="profile in phase?.memberProfileImages"
                              :key="profile.workStationName"
                            >
                              <img
                                v-if="profile.imageUrl"
                                :src="profile.imageUrl"
                                alt=""
                              />
                              <UserProfileLogo
                                v-else
                                :userName="profile.workStationName"
                              />
                            </div>
                          </div>
                          <v-icon
                            v-if="phase.estimationPhaseTasks.length"
                            @click="onClickMainTask(mainTaskIndex)"
                            icon="mdi-chevron-down-circle"
                            class="tw-cursor-pointer"
                          />
                        </div>
                        <div
                          v-if="!phase?.isLock"
                          :data-isphase="true"
                          :data-mainindex="mainTaskIndex"
                          class="phase_bar_btn bar_btn_right right-bar-btn"
                          :style="{ width: `${isMobileView ? 10 : 5}px` }"
                        ></div>
                      </div>
                    </div>
                    <div :id="`subtask-${mainTaskIndex}`" class="sub-task hide">
                      <div
                        v-for="(
                          subTask, subTaskIndex
                        ) in phase.estimationPhaseTasks"
                        :key="subTaskIndex"
                        class="sub-task-item"
                        :id="`subTask-item-${mainTaskIndex}-${subTaskIndex}`"
                        :data-duration="subTask.phaseTaskStartDate"
                        :data-enddate="subTask.phaseTaskEndDate"
                        :data-subtask="true"
                        :data-subindex="subTaskIndex"
                        :data-mainindex="mainTaskIndex"
                        :data-mainduration="phase.phaseStartDate"
                      >
                        <div
                          v-if="!phase?.isLock"
                          :data-mainindex="mainTaskIndex"
                          :data-subindex="subTaskIndex"
                          :data-issubtask="true"
                          class="subtask__left-btn left-bar-btn"
                          :style="{ width: `${isMobileView ? 10 : 5}px` }"
                        ></div>
                        <div
                          :data-mainindex="mainTaskIndex"
                          :data-subindex="subTaskIndex"
                          :data-issubtask="true"
                          class="subtask-label"
                          :style="{
                            'background-color': phase?.color || 'blue',
                          }"
                        >
                          <p
                            :data-mainindex="mainTaskIndex"
                            :data-subindex="subTaskIndex"
                            :data-issubtask="true"
                            class="subtask-label-text"
                            @mousemove="cursorMoveOnTooltip"
                          >
                            {{ subTask?.name }}
                            <v-tooltip
                              :open-on-click="isMobileView"
                              activator="parent"
                              location="top"
                              :target="[tooltipPoints.x, tooltipPoints.y]"
                              :disabled="isScrolling"
                              class="tooltip_wrapper"
                            >
                              <div class="tw-flex tw-items-center tw-gap-3">
                                <span class="tw-text-white">
                                  {{ subTask?.name }}
                                </span>
                              </div>
                            </v-tooltip>
                          </p>

                          <div
                            v-if="!phase?.isLock"
                            :data-mainindex="mainTaskIndex"
                            :data-subindex="subTaskIndex"
                            :data-issubtask="true"
                            class="subtask__right-btn right-bar-btn"
                            :style="{ width: `${isMobileView ? 10 : 5}px` }"
                          ></div>
                        </div>

                        <div
                          :id="`custom-lines-${mainTaskIndex}-${subTaskIndex}`"
                          :style="[
                            { 'border-left-color': phase?.color || 'blue' },
                            { 'border-bottom-color': phase?.color || 'blue' },
                          ]"
                          class="custom-border"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <CommonLoader
            v-if="isLoading"
            :loading="isLoading"
            class="chart-loader-container"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import SmallPhaseCollapseItem from "@/modules/jobs/components/estimates/SmallPhaseCollapseItem.vue";
import draggable from "vuedraggable";
import { first } from "lodash";
import moment from "moment"; // done
import { getCurrentInstance, onBeforeUnmount, onMounted } from "vue";
import { watch } from "vue";
import { onBeforeMount } from "vue";
import { computed } from "vue";
import { ref } from "vue";
import { useDisplay } from "vuetify";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { WORKSTATION } from "@/store/modules/workstation";
import { displayToastMessage, getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { EstimatePhaseAssignMemberStatusEnum } from "@/core/enums/estimateEnum";
const props = defineProps({
  estimateData: Object,
  isEditable: {
    type: Boolean,
    default: true,
  },
  isShowAllPhase: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const vuetify = useDisplay();
const isMobileView = computed(() => vuetify.smAndDown.value);
const chartBoxRef = ref(null);
const sideTaskRef = ref(null);
const ganttChartContainerRef = ref(null);
const chartScrollContainerRef = ref(null);
const chartClickTask = ref(null);
const isFullscreen = ref(false);
const currentDragElement = ref(null);
const projectEstimatePhases = ref([]);
const isWidthIncreaseBtn = ref(null);
const isLoading = ref(false);

const initialMouseX = ref(0);
const initialLeft = ref(0);
const scrollLeft = ref(0);
const scrollInterval = ref(null);
const scrollDirection = ref(false);
const scrollIncrement = ref(0);
const setPositionTimeout = ref(null);
const setTaskWidthTimeout = ref(null);
const isScrolling = ref(false);
const phaseTaskLeftValue = ref(0);
const tooltipPoints = ref({ x: 0, y: 0 });
const internalInstance = getCurrentInstance();
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const estimateFormIds = computed(() =>
  estimateForm.value.projectStages.map((phase) => phase.id)
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

watch(
  () => isFullscreen.value,
  (newValue) => {
    const elements = document.querySelectorAll(".chart-values li");
    elements.forEach((element) => {
      element.style.setProperty(
        "--before-height",
        newValue ? "100dvh" : "300px"
      );
    });
  }
);
const isEstimateSubmitted = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsEstimateSubmitted`]
);

const isShowEditable = (phase) => {
  if (isEstimateSubmitted.value) return false;

  if (phase?.assignPhaseWorkStation?.id) {
    if (phase?.assignPhaseWorkStation.id == activeUserWorkstation.value?.id) {
      return true;
    } else {
      return false;
    }
  } else {
    if (phase?.workStation?.id !== activeUserWorkstation.value?.id)
      return false;
  }

  return true;
};
const getProjectEstimateData = computed(() => {
  if (!props.estimateData?.estimationPhase) return null;
  const updatedEstimatePhases = props.estimateData.estimationPhase
    .filter(
      (phase) =>
        (estimateFormIds.value.includes(phase.id) || props.isShowAllPhase) &&
        phase.phaseType === 1 &&
        moment(phase.phaseStartDate).isBefore(moment(phase.phaseEndDate))
    )
    .map((phase) => {
      return {
        ...phase,
        isLock: !props.isEditable || !isShowEditable(phase),
      };
    })
    .sort((a, b) => a.phaseOrder - b.phaseOrder);
  return { ...props.estimateData, estimationPhase: updatedEstimatePhases };
});

const getMonthYearGrids = computed(() => {
  if (!getBaseStartDate.value) return [];
  const gridStartDate = moment(
    new Date(getBaseStartDate.value),
    "YYYY-MM-DD"
  ).toDate();
  const gridEndDate = moment(
    new Date(getBaseEndDate.value),
    "YYYY-MM-DD"
  ).toDate();

  const monthYearGrids = [];
  let tempCount = 0;
  const currentDate = moment(gridStartDate);
  while (currentDate.isBefore(gridEndDate)) {
    const dateMoment = currentDate.clone();
    if (!monthYearGrids.length) {
      tempCount = dateMoment.daysInMonth() - dateMoment.date() - 1;
      monthYearGrids.push({
        displayMonth: dateMoment.format("MMM YYYY"),
        count: Math.ceil(tempCount / 2),
      });
    } else if (dateMoment.date() === 1) {
      if (dateMoment.clone().endOf("month").isSameOrBefore(gridEndDate)) {
        monthYearGrids.push({
          displayMonth: dateMoment.format("MMM YYYY"),
          count: tempCount + Math.ceil(dateMoment.daysInMonth() / 2),
        });
      } else {
        monthYearGrids.push({
          displayMonth: dateMoment.format("MMM YYYY"),
          count: tempCount + Math.ceil((gridEndDate.getDate() - 1) / 2),
        });
      }
      tempCount += dateMoment.daysInMonth();
    }

    currentDate.add(1, "day");
  }
  return monthYearGrids;
});

const grids = computed(() => {
  if (getBaseStartDate.value) {
    const gridStartDate = moment(
      new Date(getBaseStartDate.value),
      "YYYY-MM-DD"
    ).toDate();
    const gridEndDate = moment(
      new Date(getBaseEndDate.value),
      "YYYY-MM-DD"
    ).toDate();
    const date = new Date(gridStartDate.getTime());
    date.setDate(date.getDate());
    const dates = [];
    while (date < gridEndDate) {
      const dateMoment = moment(date);
      dates.push({
        date: dateMoment.format("DD"),
        displayMonth: dateMoment.format("YYYY-MM-DD"),
        day: dateMoment.format("ddd"),
        isWeekend: date.getDay() === 0 || date.getDay() === 6,
        month: dateMoment.month() + 1,
      });

      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  return [];
});

const getChartContainerWidth = computed(() => grids.value.length * 50);

const getBaseStartDate = computed(
  () => props.estimateData?.suggestedStartDate || moment().format("YYYY-MM-DD")
);

const getBaseEndDate = computed(() => {
  if (props.estimateData?.projectEstimateEndDate) {
    const startDate = moment(getBaseStartDate.value);
    const endDate = moment(props.estimateData?.projectEstimateEndDate);
    const diffDays = endDate.diff(startDate, "days");
    return getUpdateDate(
      props.estimateData?.projectEstimateEndDate,
      diffDays >= 23 ? 1 : 24 - diffDays
    );
  } else {
    return getUpdateDate(getBaseStartDate.value, 365);
  }
});

const arrangeTaskElements = (element) => {
  if (element.classList.contains("phase-task")) {
    const elementWidth = getNumericValue(element.style.width);
    const phaseSpanElement = element.querySelector(".phase_task_text span");
    const phaseSpanContainerElement = element.querySelector(".phase_task_text");
    const phaseImageElement = element.querySelector(".task_images");
    const changeWidth = Math.round(elementWidth / 50) * 50 || 50;
    if (changeWidth - 100 === 0) {
      phaseSpanElement.style.display = "block";
      phaseSpanElement.style.maxWidth = `60px`;
      phaseSpanContainerElement.style.width = `70px`;
      if (phaseImageElement) phaseImageElement.style.display = "none";
    } else if (changeWidth - 100 > 0) {
      phaseSpanElement.style.display = "block";
      phaseSpanElement.style.maxWidth = `${changeWidth - 110}px`;
      phaseSpanContainerElement.style.width = `${changeWidth - 100}px`;
      if (phaseImageElement) phaseImageElement.style.display = "flex";
    } else {
      phaseSpanElement.style.display = "block";
      phaseSpanContainerElement.style.width = 1;
      if (phaseImageElement) phaseImageElement.style.display = "none";
    }
  }
};

const createChart = () => {
  try {
    const tasks = document.querySelectorAll(".phase-task,.sub-task-item");
    tasks.forEach((el, i) => {
      if (!el.dataset.duration) return;
      const startDate = moment(el.dataset.duration);
      const endDate = moment(el.dataset.enddate);
      const diffDays = endDate.diff(startDate, "days");
      const currentIndex = grids.value.findIndex(
        (grid) => grid.displayMonth == startDate.format("YYYY-MM-DD")
      );
      if (el.classList.contains("phase-task")) {
        el.style.width = `${diffDays * 50}px`;
      } else {
        const subTaskElement = el.querySelector(".subtask-label");
        subTaskElement.style.width = `${diffDays * 50}px`;
      }

      if (el.dataset.subtask) {
        const mainTaskStartDay = moment(el.dataset.mainduration).format(
          "YYYY-MM-DD"
        );
        const mainTaskIndex = grids.value.findIndex(
          (grid) => grid.displayMonth === mainTaskStartDay
        );

        const subTaskCustomLine = document.getElementById(
          `custom-lines-${el.dataset.mainindex}-${el.dataset.subindex}`
        );
        if (subTaskCustomLine)
          subTaskCustomLine.style.width = `${
            (currentIndex - mainTaskIndex) * 50
          }px`;
        subTaskCustomLine.style.left = `-${
          Number(currentIndex - mainTaskIndex) * 50
        }px`;
        el.style.left = `${Number(currentIndex - mainTaskIndex) * 50}px`;
      } else {
        const taskBox = document.getElementById(
          `task-box-${el?.dataset?.mainindex}`
        );
        if (taskBox) taskBox.style.left = `${(currentIndex + 1) * 50 - 25}px`;
      }

      arrangeTaskElements(el);
    });
  } catch (error) {
    console.log("error create chart", error);
  }
};

const onClickSideBarTask = (newValue) => {
  const subTaskContainer = document.getElementById(
    `subtask-${newValue.taskIndex}`
  );
  if (subTaskContainer.classList.contains("hide") === newValue.value)
    onClickMainTask(newValue.taskIndex);
};

const onClickMainTask = (mainTaskIndex) => {
  const subTaskContainer = document.getElementById(`subtask-${mainTaskIndex}`);
  if (subTaskContainer) subTaskContainer.classList.toggle("hide");
  const data = {
    taskIndex: mainTaskIndex,
    value: subTaskContainer.classList.contains("hide") ? false : true,
  };

  chartClickTask.value = {
    taskIndex: mainTaskIndex,
    value: subTaskContainer.classList.contains("hide") ? false : true,
  };
};
const handleScrollChart = () => {
  const chartBoxElement = chartBoxRef.value;
  const sideTaskElement = sideTaskRef.value;
  sideTaskElement.scrollTop = chartBoxElement.scrollTop;
};
const handleSideScroll = () => {
  const chartBoxElement = chartBoxRef.value;
  const sideTaskElement = sideTaskRef.value;
  chartBoxElement.scrollTop = sideTaskElement.scrollTop;
};

const isNotSupportFullScreenMode = /(iPad|iPhone|Macintosh)/g.test(
  navigator.userAgent
);

const toggleFullscreen = async () => {
  const element = document.getElementById("gantt-chart");
  const isFullscreen = document.fullscreenElement !== null;

  if (!isFullscreen) {
    try {
      if (element.requestFullscreen) {
        await element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        await element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        await element.msRequestFullscreen();
      }

      if (screen.orientation && screen.orientation.lock) {
        await screen.orientation.lock("landscape");
      }
    } catch (error) {
      console.log("attempting to enter fullscreen mode:Not Supported");
    }
  } else {
    try {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        await document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        await document.msExitFullscreen();
      }

      if (screen.orientation && screen.orientation.lock) {
        await screen.orientation.lock("any");
      }
    } catch (error) {
      console.log("Error attempting to exit fullscreen mode:Not Supported");
    }
  }
};

const fullscreenchange = () => {
  isFullscreen.value = !!document.fullscreenElement;
};

const getUpdateDate = (date, diffDays) => {
  return moment(new Date(date), "YYYY-MM-DD")
    .add(diffDays, "days")
    .format("YYYY-MM-DD");
};

const updateEstimateDates = async (element, isWidthIncreaseBtn) => {
  try {
    if (!element) return;
    const isSubTask = element.classList.contains("sub-task-item");
    const updateData = {
      date: {
        startDate: null,
        endDate: null,
      },
      taskId: null,
    };

    const phaseIndex = element.dataset.mainindex;
    const subIndex = element.dataset.subindex;

    const phaseTaskData = projectEstimatePhases.value[phaseIndex];
    const subTaskData = isSubTask
      ? phaseTaskData.estimationPhaseTasks[subIndex]
      : null;

    updateData.taskId = isSubTask ? subTaskData.id : phaseTaskData.id;

    const elementLeftValue = getNumericValue(element.style.left);

    const elementStartDateIndex = isSubTask
      ? Math.round(elementLeftValue / 50)
      : Math.round((elementLeftValue - 25) / 50);

    const taskElement = element.querySelector(
      isSubTask ? ".subtask-label" : ".phase-task"
    );
    const taskElementWidth = getNumericValue(taskElement.style.width);
    const elementEndDateIndex = Math.round(taskElementWidth / 50);

    updateData.date.startDate =
      grids.value[elementStartDateIndex] &&
      grids.value[elementStartDateIndex].displayMonth;

    if (isSubTask) {
      updateData.date.startDate = getUpdateDate(
        phaseTaskData.phaseStartDate,
        elementStartDateIndex
      );
    }

    updateData.date.endDate = getUpdateDate(
      updateData.date.startDate,
      elementEndDateIndex
    );

    if (
      isSubTask &&
      (subTaskData.phaseTaskStartDate !== updateData.date.startDate ||
        subTaskData.phaseTaskEndDate !== updateData.date.endDate)
    ) {
      await store.dispatch(
        `${TRADE_ESTIMATE_STORE}/updateEstimatePhaseSubtaskDate`,
        {
          ...updateData,
          phaseId: phaseTaskData.id,
        }
      );

      subTaskData.phaseTaskStartDate = updateData.date.startDate;
      subTaskData.phaseTaskEndDate = updateData.date.endDate;
      if (
        selectedTaskHasWeekend(
          updateData.date.startDate,
          updateData.date.endDate
        )
      ) {
        displayToastMessage(
          internalInstance,
          `Selected Subtask date range includes a weekend. Adjust if necessary.`,
          "info"
        );
      }
    } else if (
      !isSubTask &&
      (phaseTaskData.phaseStartDate !== updateData.date.startDate ||
        phaseTaskData.phaseEndDate !== updateData.date.endDate)
    ) {
      if (!isWidthIncreaseBtn) {
        const startDate = moment(phaseTaskData.phaseStartDate);
        const endDate = moment(updateData.date.startDate);
        const diffDays = endDate.diff(startDate, "days");

        const subTasksData = phaseTaskData.estimationPhaseTasks.map(
          (subTask) => {
            const startDate = getUpdateDate(
              subTask.phaseTaskStartDate,
              diffDays
            );
            subTask.phaseTaskStartDate = startDate;
            const endDate = getUpdateDate(subTask.phaseTaskEndDate, diffDays);
            subTask.phaseTaskEndDate = endDate;
            return {
              startDate,
              endDate,
              phaseTaskId: subTask.id,
            };
          }
        );

        await store.dispatch(
          `${TRADE_ESTIMATE_STORE}/updateEstimatePhaseAndSubTaskDate`,
          {
            data: {
              startDate: updateData.date.startDate,
              endDate: updateData.date.endDate,
              phaseTask: subTasksData,
            },
            estimateId: getProjectEstimateData.value.id,
            phaseId: phaseTaskData.id,
          }
        );
      } else {
        await store.dispatch(
          `${TRADE_ESTIMATE_STORE}/updateEstimatePhaseDate`,
          {
            ...updateData,
            estimateId: getProjectEstimateData.value.id,
          }
        );
      }
      phaseTaskData.phaseStartDate = updateData.date.startDate;
      phaseTaskData.phaseEndDate = updateData.date.endDate;
      if (
        selectedTaskHasWeekend(
          updateData.date.startDate,
          updateData.date.endDate
        )
      ) {
        displayToastMessage(
          internalInstance,
          `Selected task date range includes a weekend. Adjust if necessary.`,
          "info"
        );
      }
    }
  } catch (error) {
    console.log("update date error", error);
  }
};

function selectedTaskHasWeekend(startDate, endDate) {
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  while (currentDate.isSameOrBefore(finalDate)) {
    const day = currentDate.day();
    if (day === 0 || day === 6) {
      return true;
    }
    currentDate.add(1, "days");
  }
  return false;
}
const updatePhaseOrder = async () => {
  try {
    const phaseOrderList = projectEstimatePhases.value.map((phase, index) => ({
      phaseId: phase.id,
      orderNumber: index + 1,
    }));
    const isOrderChange = projectEstimatePhases.value.some((phase, index) => {
      return phase.phaseOrder !== phaseOrderList[index].orderNumber;
    });
    if (!isOrderChange) return;
    await store.dispatch(`${TRADE_ESTIMATE_STORE}/updateEstimatePhaseOrder`, {
      order: phaseOrderList,
      estimateId: getProjectEstimateData.value.id,
    });
    phaseOrderList.forEach((phase, index) => {
      projectEstimatePhases.value[index].phaseOrder = phase.orderNumber;
    });
  } catch (error) {
    console.log("update phase order error", error);
  }
};

const getCurrentSelectElement = (element) => {
  const subTaskIndex = element.dataset.subindex;
  const mainTaskIndex = element.dataset.mainindex;
  const subTaskItemElement = document.getElementById(
    `subTask-item-${mainTaskIndex}-${subTaskIndex}`
  );
  if (
    projectEstimatePhases.value[mainTaskIndex] &&
    projectEstimatePhases.value[mainTaskIndex]?.isLock
  )
    return null;
  if (element.classList.contains("left-bar-btn")) {
    isWidthIncreaseBtn.value = "left";
  }
  if (element.classList.contains("right-bar-btn")) {
    isWidthIncreaseBtn.value = "right";
  }

  const taskBoxElement = document.getElementById(`task-box-${mainTaskIndex}`);

  return element?.dataset.issubtask
    ? subTaskItemElement
    : element?.dataset?.isphase
    ? taskBoxElement
    : null;
};
const getNumericValue = (elementSizeInPx) => {
  return elementSizeInPx ? parseInt(elementSizeInPx.match(/\d+/)[0], 10) : null;
};

const setTaskWidthAfterLeft = (
  element,
  currentSelectElement,
  isSubTask = false
) => {
  clearTimeout(setTaskWidthTimeout.value);
  setTaskWidthTimeout.value = setTimeout(() => {
    let elementWidth = getNumericValue(element.style.width);
    let taskLeft = getNumericValue(currentSelectElement.style.left);
    const subTaskConnectLine =
      currentSelectElement.querySelector(".custom-border");
    const changeWidth = Math.round(elementWidth / 50) * 50 || 50;

    const setPositionInterVal = setInterval(() => {
      elementWidth = elementWidth + (elementWidth < changeWidth ? 1 : -1);
      taskLeft = taskLeft + (elementWidth < changeWidth ? -1 : 1);

      if (elementWidth === changeWidth) {
        taskLeft = isSubTask
          ? Math.round(taskLeft / 50) * 50
          : Math.round((taskLeft - 25) / 50) * 50 + 25;
      }

      element.style.width = `${Math.round(elementWidth)}px`;
      currentSelectElement.style.left = `${Math.round(taskLeft)}px`;

      if (isSubTask) {
        subTaskConnectLine.style.left = `-${Math.round(taskLeft)}px`;
        subTaskConnectLine.style.width = `${Math.round(taskLeft)}px`;
      } else {
        setSubTaskLeftValue(Math.round(taskLeft), currentSelectElement);
      }

      if (elementWidth === changeWidth) {
        clearInterval(setPositionInterVal);
      }
    }, 1);
  }, 400);
};
const setTaskWidthAfterRight = (element) => {
  clearTimeout(setTaskWidthTimeout.value);
  setTaskWidthTimeout.value = setTimeout(() => {
    let elementWidth = getNumericValue(element.style.width);
    const changeWidth = Math.round(elementWidth / 50) * 50 || 50;
    const setPositionInterVal = setInterval(() => {
      if (elementWidth < changeWidth) {
        elementWidth = Math.min(elementWidth + 1, changeWidth);
      } else if (elementWidth > changeWidth) {
        elementWidth = Math.max(elementWidth - 1, changeWidth);
      }
      element.style.width = `${elementWidth}px`;
      if (elementWidth === changeWidth) {
        clearInterval(setPositionInterVal);
      }
    }, 1);
  }, 400);
};

const setTaskPosition = (currentSelectElement, subTaskCustomLineElement) => {
  clearTimeout(setPositionTimeout.value);
  setPositionTimeout.value = setTimeout(() => {
    const mainTaskLeftValue = getNumericValue(currentSelectElement.style.left);
    let currentValue = getNumericValue(currentSelectElement.style.left);

    const perfectPositionLeftValue = subTaskCustomLineElement
      ? Math.round(mainTaskLeftValue / 50) * 50
      : Math.round((mainTaskLeftValue - 25) / 50) * 50 + 25;

    const setPositionInterVal = setInterval(() => {
      let positionValue;
      if (mainTaskLeftValue < perfectPositionLeftValue) {
        positionValue = Math.min(currentValue + 0.5, perfectPositionLeftValue);
        currentValue += 0.5;
      } else {
        positionValue = Math.max(currentValue - 0.5, perfectPositionLeftValue);
        currentValue -= 0.5;
      }
      if (subTaskCustomLineElement) {
        subTaskCustomLineElement.style.width = `${positionValue}px`;
        subTaskCustomLineElement.style.left = `-${positionValue}px`;
      }

      currentSelectElement.style.left = `${positionValue}px`;

      if (positionValue === perfectPositionLeftValue) {
        clearInterval(setPositionInterVal);
      }
    }, 1);
  }, 400);
};

const getPhaseTaskPointValue = (mainTaskIndex) => {
  const taskBoxElement = document.getElementById(`task-box-${mainTaskIndex}`);
  const mainTaskElement = document.getElementById(`maintask-${mainTaskIndex}`);
  const leftValue = getNumericValue(taskBoxElement.style.left);

  return taskBoxElement && mainTaskElement
    ? {
        startPoint: leftValue,
        endPoint: leftValue + getNumericValue(mainTaskElement.style.width),
      }
    : null;
};

const getElementWidth = (element) => {
  const subTaskItems = element.querySelectorAll(".sub-task-item");
  let maxWidth = 0;
  let minWidth = Infinity;
  subTaskItems.forEach((subTask) => {
    const subTaskLeftValue = getNumericValue(subTask.style.left);
    const subtaskLabel = subTask.querySelector(".subtask-label");
    const subTaskWidthValue = getNumericValue(subtaskLabel.style.width);
    const totalWidth = subTaskLeftValue + subTaskWidthValue;
    if (maxWidth < totalWidth) {
      maxWidth = totalWidth;
    }
    if (minWidth > subTaskLeftValue) {
      minWidth = subTaskLeftValue;
    }
  });
  return { maxWidth, minWidth };
};

const setSubTaskLeftValue = (mainTaskLeftValue, currentSelectElement) => {
  const diff = phaseTaskLeftValue.value - mainTaskLeftValue;
  phaseTaskLeftValue.value = mainTaskLeftValue;
  currentSelectElement.style.left = `${Math.round(mainTaskLeftValue)}px`;
  const subTaskElements =
    currentSelectElement.querySelectorAll(".sub-task-item");
  subTaskElements.forEach((subTask) => {
    const changeValue = Math.round(getNumericValue(subTask.style.left) + diff);

    const subTaskConnectLine = subTask.querySelector(".custom-border");
    subTask.style.left = `${changeValue}px`;
    subTaskConnectLine.style.left = `-${changeValue}px`;
    subTaskConnectLine.style.width = `${changeValue}px`;
  });
};

const updatePhaseElementWidth = (
  currentSelectElement,
  mainTaskElement,
  mainTaskLeftValue,
  dx
) => {
  const mainTaskElementWidth = getNumericValue(mainTaskElement.style.width);

  const phaseTask = getElementWidth(currentSelectElement);
  const phaseTaskPoint = getPhaseTaskPointValue(
    currentSelectElement.dataset.mainindex
  );
  const leftSideWidth =
    -dx + mainTaskElementWidth <= mainTaskElementWidth - phaseTask.minWidth
      ? mainTaskElementWidth - phaseTask.minWidth
      : -dx + mainTaskElementWidth >=
        phaseTaskPoint.endPoint - mainTaskLeftValue
      ? phaseTaskPoint.endPoint - mainTaskLeftValue
      : -dx + mainTaskElementWidth;

  const rightSideWidth =
    dx + mainTaskElementWidth < phaseTask.maxWidth
      ? phaseTask.maxWidth
      : dx + mainTaskElementWidth >=
        getChartContainerWidth.value - 25 - mainTaskLeftValue
      ? getChartContainerWidth.value - 25 - mainTaskLeftValue
      : dx + mainTaskElementWidth;
  if (isWidthIncreaseBtn.value === "left" && leftSideWidth >= 50) {
    mainTaskElement.style.width = `${Math.round(leftSideWidth)}px`;
    currentSelectElement.style.left = `${Math.round(mainTaskLeftValue)}px`;
    setSubTaskLeftValue(mainTaskLeftValue, currentSelectElement);
  } else if (isWidthIncreaseBtn.value === "right" && rightSideWidth >= 50) {
    mainTaskElement.style.width = `${Math.round(rightSideWidth)}px`;
  }

  arrangeTaskElements(mainTaskElement);
  if (isWidthIncreaseBtn.value === "left") {
    setTaskWidthAfterLeft(mainTaskElement, currentSelectElement);
  } else {
    setTaskWidthAfterRight(mainTaskElement);
  }

  if (
    (leftSideWidth === mainTaskElementWidth - phaseTask.minWidth &&
      isWidthIncreaseBtn.value === "left") ||
    (isWidthIncreaseBtn.value === "right" &&
      rightSideWidth === phaseTask.maxWidth)
  ) {
    onMouseUp();
  }
};
const updateSubTaskElementWidth = (
  currentSelectElement,
  subTaskElement,
  subTaskLeftValue,
  subTaskCustomLine,
  mainTaskElementWidth,
  dx
) => {
  const subTaskElementWidth = getNumericValue(subTaskElement.style.width);
  const subTaskMaxValue = mainTaskElementWidth - subTaskLeftValue;

  const changeElementWidth =
    isWidthIncreaseBtn.value === "right" &&
    dx + subTaskElementWidth < subTaskMaxValue
      ? dx + subTaskElementWidth <= 50
        ? 50
        : dx + subTaskElementWidth
      : isWidthIncreaseBtn.value === "left" &&
        -dx + subTaskElementWidth < subTaskMaxValue
      ? -dx + subTaskElementWidth <= 50
        ? 50
        : -dx + subTaskElementWidth
      : subTaskMaxValue;

  if (subTaskLeftValue <= 0 && isWidthIncreaseBtn.value === "left") return;
  if (isWidthIncreaseBtn.value === "left") {
    subTaskCustomLine.style.width = `${subTaskLeftValue}px`;
    subTaskCustomLine.style.left = `-${subTaskLeftValue}px`;
    currentSelectElement.style.left = `${subTaskLeftValue}px`;
    subTaskElement.style.width = `${Math.round(changeElementWidth)}px`;
  } else if (isWidthIncreaseBtn.value === "right" && changeElementWidth > 50) {
    subTaskElement.style.width = `${changeElementWidth}px`;
  }

  arrangeTaskElements(currentSelectElement);
  if (isWidthIncreaseBtn.value === "left") {
    setTaskWidthAfterLeft(subTaskElement, currentSelectElement, true);
  } else {
    setTaskWidthAfterRight(subTaskElement);
  }
};

const updateElementPosition = (currentSelectElement, changeLeftValue, dx) => {
  try {
    const mainTaskElement = document.getElementById(
      `maintask-${currentSelectElement.dataset.mainindex}`
    );
    const mainTaskElementWidth = getNumericValue(mainTaskElement.style.width);
    if (currentSelectElement.dataset.subtask) {
      const subTaskElement =
        currentSelectElement.querySelector(".subtask-label");

      const subTaskElementWidth = getNumericValue(subTaskElement.style.width);
      const subTaskMovementAreaWidth =
        mainTaskElementWidth - subTaskElementWidth;

      const subTaskCustomLine = document.getElementById(
        `custom-lines-${currentSelectElement.dataset.mainindex}-${currentSelectElement.dataset.subindex}`
      );
      const subTaskRightSpace =
        subTaskMovementAreaWidth -
        getNumericValue(currentSelectElement.style.left);
      const subTaskLeftValueFromEnd =
        mainTaskElementWidth - (subTaskRightSpace + 50);

      const subTaskLeftValue =
        changeLeftValue > subTaskMovementAreaWidth && !isWidthIncreaseBtn.value
          ? subTaskMovementAreaWidth
          : changeLeftValue < 0
          ? 0
          : isWidthIncreaseBtn.value === "right"
          ? getNumericValue(currentSelectElement.style.left)
          : isWidthIncreaseBtn.value === "left" &&
            changeLeftValue >= subTaskLeftValueFromEnd
          ? subTaskLeftValueFromEnd
          : changeLeftValue;
      if (isWidthIncreaseBtn.value) {
        updateSubTaskElementWidth(
          currentSelectElement,
          subTaskElement,
          Math.round(subTaskLeftValue),
          subTaskCustomLine,
          mainTaskElementWidth,
          dx
        );
      } else {
        subTaskCustomLine.style.width = `${subTaskLeftValue}px`;
        subTaskCustomLine.style.left = `-${subTaskLeftValue}px`;
        currentSelectElement.style.left = `${subTaskLeftValue}px`;
        setTaskPosition(currentSelectElement, subTaskCustomLine);
      }
    } else {
      const phaseTask = getElementWidth(currentSelectElement);
      const phaseTaskPoint = getPhaseTaskPointValue(
        currentSelectElement.dataset.mainindex
      );
      const mainTaskLeftValue =
        changeLeftValue < 25
          ? 25
          : changeLeftValue >
              getChartContainerWidth.value - 25 - mainTaskElementWidth &&
            !isWidthIncreaseBtn.value
          ? getChartContainerWidth.value - 25 - mainTaskElementWidth
          : isWidthIncreaseBtn.value === "right"
          ? getNumericValue(currentSelectElement.style.left)
          : isWidthIncreaseBtn.value === "left" &&
            changeLeftValue >= phaseTaskPoint.startPoint + phaseTask.minWidth
          ? phaseTaskPoint.startPoint + phaseTask.minWidth
          : changeLeftValue;

      if (isWidthIncreaseBtn.value) {
        updatePhaseElementWidth(
          currentSelectElement,
          mainTaskElement,
          mainTaskLeftValue,
          dx,
          changeLeftValue
        );
      } else {
        currentSelectElement.style.left = `${mainTaskLeftValue}px`;
        setTaskPosition(currentSelectElement);
      }
    }
  } catch (error) {
    console.log("update element position error", error);
  }
};

const scrollContainer = (currentSelectElement, event) => {
  const chartScrollContainer = chartScrollContainerRef.value;
  const scrollContainerRect = chartScrollContainer.getBoundingClientRect();
  const isSubTask = currentSelectElement.classList.contains("sub-task-item");
  clearInterval(scrollInterval.value);
  const phaseTaskPoint = getPhaseTaskPointValue(
    currentSelectElement.dataset.mainindex
  );
  if (
    (isSubTask &&
      phaseTaskPoint.endPoint - scrollContainerRect.width < scrollLeft.value &&
      scrollDirection.value === "right") ||
    (isSubTask &&
      phaseTaskPoint.startPoint - 25 > scrollLeft.value &&
      scrollDirection.value === "left")
  )
    return;
  const smoothScroll = () => {
    initialLeft.value = getNumericValue(currentSelectElement.style.left);
    const dx = event.clientX - initialMouseX.value;
    initialMouseX.value = event.clientX;
    if (scrollDirection.value === "right") {
      scrollIncrement.value = Math.min(scrollIncrement.value + 0.5, 20);
      scrollLeft.value += scrollIncrement.value;

      updateElementPosition(
        currentSelectElement,
        getNumericValue(currentSelectElement.style.left) +
          scrollIncrement.value,
        scrollIncrement.value
      );
    } else if (scrollDirection.value === "left") {
      scrollIncrement.value = Math.min(scrollIncrement.value + 0.5, 20);
      scrollLeft.value -= scrollIncrement.value;
      updateElementPosition(
        currentSelectElement,
        getNumericValue(currentSelectElement.style.left) -
          scrollIncrement.value,
        -scrollIncrement.value
      );
    } else {
      scrollIncrement.value = 0;
    }
    chartScrollContainer.scrollLeft = scrollLeft.value;
    if (
      (isSubTask &&
        phaseTaskPoint.endPoint - scrollContainerRect.width <
          scrollLeft.value &&
        scrollDirection.value === "right") ||
      (isSubTask &&
        phaseTaskPoint.startPoint - 25 > scrollLeft.value &&
        scrollDirection.value === "left")
    )
      clearInterval(scrollInterval.value);
  };
  scrollInterval.value = setInterval(smoothScroll, 16);
};

const onMouseDown = (event) => {
  try {
    const currentSelectElement = getCurrentSelectElement(event.target);

    if (!currentSelectElement) return;
    currentDragElement.value = currentSelectElement;
    phaseTaskLeftValue.value = getNumericValue(currentSelectElement.style.left);
    initialLeft.value = getNumericValue(currentSelectElement.style.left);
    initialMouseX.value = event.clientX;
    scrollLeft.value = chartScrollContainerRef.value.scrollLeft;
    const chartContainerElement = chartBoxRef.value;
    chartContainerElement.addEventListener("mousemove", onMouseMove);
    chartContainerElement.addEventListener("mouseleave", onMouseUp);
    chartContainerElement.addEventListener("mouseup", onMouseUp);

    chartContainerElement.addEventListener("touchmove", onTouchMove);
    chartContainerElement.addEventListener("touchend", onTouchEnd);
    chartContainerElement.addEventListener("touchcancel", onTouchEnd);

    // event.preventDefault();
  } catch (error) {
    console.log(" onMouseDown-error", error);
  }
};

const onMouseMove = (event) => {
  try {
    const currentSelectElement = currentDragElement.value;
    if (!currentSelectElement) return;
    isScrolling.value = true;
    const dx = event.clientX - initialMouseX.value;
    const changeLeftValue = initialLeft.value + dx;

    const chartContainer = ganttChartContainerRef.value;
    const containerRect = chartContainer.getBoundingClientRect();
    const currentElement = currentSelectElement.getElementsByClassName(
      currentSelectElement.dataset.subtask ? "subtask-label" : "phase-task"
    );

    const elementRect = currentElement[0].getBoundingClientRect();

    if (
      (elementRect.right > containerRect.right - 10 ||
        (scrollDirection.value === "right" &&
          initialMouseX.value <= event.clientX)) &&
      dx >= 0
    ) {
      scrollDirection.value = "right";
      scrollContainer(currentSelectElement, event);
    } else if (
      (elementRect.left <
        containerRect.left + (window.innerWidth >= 1024 ? 300 : 30) ||
        (scrollDirection.value === "left" &&
          initialMouseX.value >= event.clientX)) &&
      dx < 1
    ) {
      scrollDirection.value = "left";
      scrollContainer(currentSelectElement, event);
    } else {
      clearInterval(scrollInterval.value);
      scrollDirection.value = null;
      updateElementPosition(currentSelectElement, changeLeftValue, dx);
    }
    initialLeft.value = getNumericValue(currentSelectElement.style.left);
    initialMouseX.value = event.clientX;
  } catch (error) {
    console.log("onMouseMove error", error);
  }
};

const onMouseUp = (event) => {
  clearInterval(scrollInterval.value);
  scrollDirection.value = null;
  isScrolling.value = false;

  if (currentDragElement.value) {
    updateEstimateDates(currentDragElement.value, isWidthIncreaseBtn.value);
    currentDragElement.value = null;
    isWidthIncreaseBtn.value = null;
  }
  const chartContainerElement = chartBoxRef.value;
  chartContainerElement.removeEventListener("mousemove", onMouseMove);
  chartContainerElement.removeEventListener("mouseleave", onMouseUp);
  chartContainerElement.removeEventListener("mouseup", onMouseUp);
  chartContainerElement.removeEventListener("touchmove", onTouchMove);
  chartContainerElement.removeEventListener("touchend", onTouchEnd);
  chartContainerElement.removeEventListener("touchcancel", onTouchEnd);
};

const onTouchStart = (event) => {
  onMouseDown(event.touches[0]);
};

const onTouchMove = (event) => {
  onMouseMove(event.touches[0]);
  if (getCurrentSelectElement(event.target)) {
    event.preventDefault();
  }
};

const onTouchEnd = (event) => {
  onMouseUp(event.changedTouches[0]);
};

const onDragEnd = (event) => {
  createChart();
  updatePhaseOrder();
};
const getImageURL = async (profileImage) => {
  try {
    if (!profileImage) return null;
    const imageUrl = getImageApiUrl(profileImage, true);
    const encoded = await $axios.get(imageUrl);
    return encoded.publicUrl;
  } catch (error) {
    return null;
  }
};
const setProfileImageURL = async (estimatePhase) => {
  const updatePhase = await Promise.all(
    estimatePhase.map(async (phase, idx) => {
      const memberProfileImages = [];
      if (phase?.workStation) {
        const userImage = await getImageURL(phase?.workStation?.profileImage);
        memberProfileImages.push({
          imageUrl: userImage,
          workStationName: phase.workStation.name,
        });
      }
      if (phase?.assignTeamMembers?.length) {
        for (const assignMember of phase?.assignTeamMembers) {
          if (
            assignMember.status === EstimatePhaseAssignMemberStatusEnum.ACCEPTED
          ) {
            const teamUser = assignMember?.userWorkstationMember?.toUser;
            const userImage = await getImageURL(
              teamUser?.userPublicProfile?.profileImage
            );
            memberProfileImages.push({
              imageUrl: userImage,
              workStationName: `${teamUser.firstName} ${teamUser.lastName}`,
            });
          }
        }
      }
      if (phase?.assignPhaseWorkStation?.id) {
        const userImage = await getImageURL(
          phase?.assignPhaseWorkStation?.profileImage
        );
        memberProfileImages.push({
          imageUrl: userImage,
          workStationName: phase.assignPhaseWorkStation.name,
        });
      }
      return { ...phase, memberProfileImages };
    })
  );
  return updatePhase;
};
const cursorMoveOnTooltip = (e) => {
  tooltipPoints.value.x = e.x;
  tooltipPoints.value.y = e.y;
};
onMounted(() => {
  const sideTaskElement = sideTaskRef.value;
  if (sideTaskElement)
    sideTaskElement.addEventListener("scroll", handleSideScroll);
  const chartBoxElement = chartBoxRef.value;
  if (chartBoxElement)
    chartBoxElement.addEventListener("scroll", handleScrollChart);
  document.addEventListener("fullscreenchange", fullscreenchange);
});
onBeforeMount(async () => {
  try {
    isLoading.value = true;
    projectEstimatePhases.value = getProjectEstimateData.value
      ? await setProfileImageURL(getProjectEstimateData.value.estimationPhase)
      : [];

    setTimeout(() => {
      createChart();
    }, 0);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
});
onBeforeUnmount(() => {
  const chartBoxElement = chartBoxRef.value;
  if (chartBoxElement)
    chartBoxElement.removeEventListener("scroll", handleScrollChart);
  const sideTaskElement = sideTaskRef.value;
  if (sideTaskElement)
    sideTaskElement.removeEventListener("scroll", handleSideScroll);
  document.removeEventListener("fullscreenchange", fullscreenchange);
});
</script>
<style lang="scss" scoped>
.chart-container {
  padding: 0;
  padding-bottom: 30px;
  position: relative;
  list-style: none;
  position: relative;
  top: 22px;
  overflow-y: auto;
  flex: 1;
  width: 18250px;
  height: auto;
  overflow-x: hidden;
  background-color: transparent;

  :deep(.task-container) {
    padding: 0%;
    margin: 0%;
    .task-wrapper {
      position: relative;
      line-height: 1 !important;
      padding: 8px 0;
      .phase-task {
        max-width: 100%;
        width: 50px;
        background-color: #4a92e5;
        border-radius: 8px;
        height: 38px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 99;
        padding: 0 5px;
      }
    }
  }

  .chart-bars-mobile {
    height: auto;
    overflow: auto;
  }
}

.sub-task {
  transition: 0.3s ease-in-out all;
  position: relative;
  z-index: 10;
}

.hide {
  display: none;
  transition: 0.3s ease-in-out all;
}

.month_wrapper {
  list-style: none;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.month_wrapper_item {
  position: absolute;
  top: 10px;
  width: 100px;
  text-align: left;
  @include fluidFont(14, 14, 1.2);
  color: rgba($blueDark, 1);
  letter-spacing: 0.25px;
}
.sub-task-item:last-child {
  margin-bottom: 0;
}
.sub-task-item {
  margin: 17px 0;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  height: 32px;
  cursor: move;
  .subtask-label {
    position: relative;
    border-radius: 6px;
    padding: 8px 12px;
    background-color: #4a92e5;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    height: 32px;
    .subtask-label-text {
      @include fluidFont(12, 12, 1.2);
      color: white;
      font-weight: 700;
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: block;
      text-transform: capitalize;
    }
    .task_images {
      margin-right: 010;
    }
  }
}

.custom-border {
  width: 100%;
  height: 50px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  position: absolute;
  left: 0;
  top: -33px;
  z-index: -2;
}
#app {
  line-height: 1 !important;
}

.chart-values {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin-top: 50px;
  li {
    position: relative;
    height: 100%;
    width: 50px;
    &::before {
      position: absolute;
      content: "";
      width: 1px;
      height: 404px;
      left: 50%;
      right: 0;
      top: 25px;
      background-color: #0c0f4a1a;
    }
    .date_wrapper {
      width: 50px;
      max-width: 100%;
      @include fluidFont(10, 10, 1.3);
      font-weight: 600;
      color: #0c0f4a;
    }
    .day_wrapper {
      width: 50px;
      max-width: 100%;
      @include fluidFont(8, 8, 1.3);
      font-weight: 600;
      color: #0c0f4a;
    }
  }
  .weekend {
    &::before {
      background-color: orange;
    }
    .date_wrapper {
      color: orange;
    }
    .day_wrapper {
      color: orange;
    }
  }
}
.work_bars {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  height: 480px;
  overflow-y: hidden;
}
.work_bars::-webkit-scrollbar {
  display: none;
}
.chart-container-header {
  position: sticky;
  top: 0;
  z-index: 0;
  background-color: rgba($white, 1);
}

.schedule-page {
  &__content {
    padding: 0;
    box-sizing: border-box;
    border-bottom-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: rgba($white, 1);
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.mobile-view {
  height: 100%;
}

.full-screen-chart-container {
  height: auto;
  overflow: hidden;
}

.full-screen-chart-container .work_bars {
  overflow-y: auto;
  height: 100dvh;
}
.full-screen-chart-container .custom-class {
  display: none;
}
.full-screen-chart-container .chart-values li::before {
  height: calc(100dvh - 150px);
}
.full-screen-chart-container .chart-container {
  padding-bottom: 80px;
}
.drag-handle {
  cursor: grab;
  display: flex;
  gap: 0;
  margin-left: 5px;
  .v-icon {
    width: 6px;
  }
}
.fallback {
  display: none;
}

.taskbar_inner_content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  cursor: move;
  .phase_bar_btn {
    height: 38px;
    width: 5px;
    position: absolute;
    background-color: rgb(143 144 148 / 34%);
    cursor: col-resize;
  }

  .left_block {
    display: flex;
    align-items: center;
    gap: 0;
    .phase_task_text {
      span {
        height: auto;
        padding: 0;
        @include fluidFont(12, 12, 1.4);
        font-weight: 700;
        color: rgba($white, 1);
        text-align: left;
        max-width: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        display: block;
        text-transform: capitalize;
        margin: 0 2px;
      }
    }
  }
  .right_block {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.bar_btn_left {
  left: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.bar_btn_right {
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.task_images {
  display: flex;
  align-items: center;
  margin-right: 8px;
  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin: -5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &:first-child {
    margin-left: 0;
  }
}

.subtask__left-btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  z-index: 21;
  width: 5px;
  height: 100%;
  background-color: rgb(143 144 148 / 34%);
  left: 0px;
  cursor: col-resize;
}
.subtask__right-btn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  z-index: 21;
  width: 5px;
  height: 100%;
  background-color: rgb(143 144 148 / 34%);
  right: 0;
  top: 0;
  cursor: col-resize;
}
.task_images {
  display: flex;
  align-items: center;
  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin: -5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &:first-child {
    margin-left: 0;
  }
}
.tooltip_wrapper {
  :deep(.v-overlay__content) {
    opacity: 1;
  }
}
.chart-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
</style>
