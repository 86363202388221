<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#80829F" />
    <g clip-path="url(#clip0_4170_63440)">
      <path
        d="M11.1429 19.6662C11.1429 20.3995 11.7214 20.9995 12.4286 20.9995H17.5714C18.2786 20.9995 18.8571 20.3995 18.8571 19.6662V12.9995C18.8571 12.2662 18.2786 11.6662 17.5714 11.6662H12.4286C11.7214 11.6662 11.1429 12.2662 11.1429 12.9995V19.6662ZM18.8571 9.66618H17.25L16.7936 9.19285C16.6779 9.07285 16.5107 8.99951 16.3436 8.99951H13.6564C13.4893 8.99951 13.3221 9.07285 13.2064 9.19285L12.75 9.66618H11.1429C10.7893 9.66618 10.5 9.96618 10.5 10.3328C10.5 10.6995 10.7893 10.9995 11.1429 10.9995H18.8571C19.2107 10.9995 19.5 10.6995 19.5 10.3328C19.5 9.96618 19.2107 9.66618 18.8571 9.66618Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4170_63440">
        <rect
          width="14.4"
          height="14.4"
          fill="white"
          transform="translate(7.7998 7.7998)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#0C0F4A",
  },
  fillOpacity: {
    type: String,
    default: "0.5",
  },
});
</script>
<script>
export default {
  name: "TrashCircularGrayIcon",
};
</script>
