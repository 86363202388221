import { QueryBuilder } from "@vepler/property-query-builder";
import { Ref, ref } from "vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import store from "@/store";
import moment from "moment";

interface Comparable {
  locationId: string;
  // Add other fields as needed
}

interface UseQueryComparables {
  OnTheMarketSaleComparables: any;
  OnTheMarketRentComparables: any;
  OffMarketRentComparables: any;
  soldHistoryComparables: any;
  comparables: any;
  loading: Ref<boolean>;
  error: Ref<Error | null>;
  queryComparables: (
    propertyType: string[],
    beds: string,
    radius: number,
    lng: number,
    lat: number,
    uprn: string
  ) => Promise<void>;
}

export function useQueryComparables(): UseQueryComparables {
  const OnTheMarketSaleComparables = ref<Comparable[]>([]);
  const OnTheMarketRentComparables = ref<Comparable[]>([]);
  const OffMarketRentComparables = ref<Comparable[]>([]);
  const soldHistoryComparables = ref<Comparable[]>([]);
  const comparables = ref<Comparable[]>([]);
  const loading = ref(false);
  const error = ref<Error | null>(null);

  const queryComparables = async (
    propertyType: string[],
    beds: string,
    radius: number,
    lng: number,
    lat: number,
    uprn: string
  ) => {
    loading.value = true;
    error.value = null;

    try {
      const fetchComparables = async (query: any) => {
        const params = {
          area: [{ type: "point", coordinates: [lng, lat], radius }],
          query,
          limit: 100,
          IsfiltersApplied: false,
          latitude: lat,
          longitude: lng,
          radius: radius,
        };
        const payload = {
          params,
          uprn,
        };
        return await store.dispatch(
          `${PROPERTY_STORE}/fetchComparables`,
          payload
        );
      };

      const fetchComparablesWithPropbarAPI = async (query: any) => {
        const options = {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-api-key": "9614620b-10dd-4e0e-9865-4edb7ffbd0cd", // pass key here.
          },
          body: JSON.stringify({
            area: [
              {
                type: "point",
                coordinates: [lng, lat],
                radius,
              },
            ],
            limit: 50,
            query,
          }),
        };
        const data = await fetch(
          "https://api2.propbar.co.uk/property/query",
          options
        );
        const jsonData = await data.json();
        return jsonData.result;
      };

      const OnTheMarketSaleQuery = new QueryBuilder();
      const OnTheMarketSaleGroupId = OnTheMarketSaleQuery.addGroup("AND");

      OnTheMarketSaleQuery.addCondition(OnTheMarketSaleGroupId, {
        field: "saleListingStatus",
        comparator: "eq",
        value: "live",
      });

      const OnTheMarketRentQuery = new QueryBuilder();
      const OnTheMarketRentGroupId = OnTheMarketRentQuery.addGroup("AND");

      OnTheMarketRentQuery.addCondition(OnTheMarketRentGroupId, {
        field: "rentalListingStatus",
        comparator: "eq",
        value: "live",
      });

      const OffMarketRentQuery = new QueryBuilder();
      const OffMarketRentGroupId = OffMarketRentQuery.addGroup("AND");

      OffMarketRentQuery.addCondition(OffMarketRentGroupId, {
        field: "listingStatus",
        comparator: "eq",
        value: "removed",
      });
      OffMarketRentQuery.addCondition(OffMarketRentGroupId, {
        field: "listingPublishedDate",
        comparator: "gt",
        value: new Date(
          new Date().setFullYear(new Date().getFullYear() - 5)
        ).toISOString(),
      });

      const soldHistoryQuery = new QueryBuilder();
      const soldHistoryGroupId = soldHistoryQuery.addGroup("AND");

      soldHistoryQuery.addCondition(soldHistoryGroupId, {
        field: "saleHistoryDate",
        comparator: "gt",
        value: new Date(
          new Date().setFullYear(new Date().getFullYear() - 3)
        ).toISOString(),
      });
      soldHistoryQuery.addCondition(soldHistoryGroupId, {
        field: "saleListingStatus",
        comparator: "eq",
        value: ["unavailable"],
      });

      const queries = [
        OnTheMarketSaleQuery,
        OnTheMarketRentQuery,
        OffMarketRentQuery,
        soldHistoryQuery,
      ];
      const groupIds = [
        OnTheMarketSaleGroupId,
        OnTheMarketRentGroupId,
        OffMarketRentGroupId,
        soldHistoryGroupId,
      ];

      if (beds !== "") {
        const split = beds.split(",").map((bed) => parseInt(bed));
        for (let i = 0; i < groupIds.length; i++) {
          const query = queries[i];

          query.addCondition(groupIds[i], {
            field: "beds",
            comparator: "in",
            value: beds.split(",").map((bed) => parseInt(bed)),
          });
        }
      }

      if (propertyType && propertyType.length) {
        for (let i = 0; i < groupIds.length; i++) {
          const query = queries[i];

          query.addCondition(groupIds[i], {
            field: "propertyType",
            comparator: "in",
            value: propertyType,
          });
        }
      }

      const onTheMarketSale = OnTheMarketSaleQuery.getQuery();
      const onTheMarketRent = OnTheMarketRentQuery.getQuery();
      const offMarketRent = OffMarketRentQuery.getQuery();
      const soldHistory = soldHistoryQuery.getQuery();

      if (
        beds !== "" ||
        (propertyType && propertyType.length) ||
        radius > 250
      ) {
        // do the query with filters]
        const [
          OnTheMarketSaleComparable,
          OnTheMarketRentComparable,
          OffMarketRentComparable,
          soldHistoryComparable,
        ] = await Promise.all([
          fetchComparablesWithPropbarAPI(OnTheMarketSaleQuery.getQuery()),
          fetchComparablesWithPropbarAPI(OnTheMarketRentQuery.getQuery()),
          fetchComparablesWithPropbarAPI(OffMarketRentQuery.getQuery()),
          fetchComparablesWithPropbarAPI(soldHistoryQuery.getQuery()),
        ]);

        const mergedComparables = [
          ...OnTheMarketSaleComparable,
          ...OnTheMarketRentComparable,
          ...OffMarketRentComparable,
          ...soldHistoryComparable,
        ].reduce((acc: any, comparable: any) => {
          if (!acc.find((c: any) => c.locationId === comparable.locationId)) {
            acc.push(comparable);
          }
          return acc;
        }, []);

        comparables.value = mergedComparables;

        OnTheMarketSaleComparables.value = OnTheMarketSaleComparable;
        OnTheMarketRentComparables.value = OnTheMarketRentComparable;
        OffMarketRentComparables.value = OffMarketRentComparable;
        soldHistoryComparables.value = soldHistoryComparable;
      } else {
        const defaultQuery = [
          {
            operator: "OR",
            groups: [
              {
                conditions: [
                  {
                    field: "saleListingStatus",
                    comparator: "eq",
                    value: "live",
                  },
                  {
                    field: "saleHistoryDate",
                    comparator: "gt",
                    value: "2021-07-18T12:50:14.818Z",
                  },
                  {
                    field: "saleListingStatus",
                    comparator: "eq",
                    value: '["unavailable"]',
                  },
                  {
                    field: "propertyType",
                    comparator: "in",
                    value: '["detached"]',
                  },
                ],
              },
              {
                conditions: [
                  {
                    field: "rentalListingStatus",
                    comparator: "eq",
                    value: "live",
                  },
                  {
                    field: "propertyType",
                    comparator: "in",
                    value: '["detached"]',
                  },
                  // {
                  //   field: "listingStatus",
                  //   comparator: "eq",
                  //   value: "removed",
                  // },
                  // {
                  //   field: "listingPublishedDate",
                  //   comparator: "gt",
                  //   value: "2019-07-18T12:50:14.818Z",
                  // },
                ],
              },
            ],
          },
        ];

        const comparableQueries = await fetchComparables(defaultQuery);

        const mergedComparables = await comparableQueries.result.reduce(
          (acc: any, comparable: any) => {
            if (!acc.find((c: any) => c.locationId === comparable.locationId)) {
              acc.push(comparable);
            }
            return acc;
          },
          []
        );

        comparables.value = mergedComparables;

        OnTheMarketSaleComparables.value = comparables.value.filter(
          (comparable: any) => comparable?.marketStatus?.forSale === "live"
        );
        OnTheMarketRentComparables.value = comparables.value.filter(
          (comparable: any) => comparable?.marketStatus?.forRent === "live"
        );

        soldHistoryComparables.value = comparables.value.filter(
          (comparable: any) => {
            const momentGivenDate = moment(comparable?.saleHistory[0]?.date);
            const momentCompareDate = moment("2021-07-18T12:50:14.818Z");
            return (
              comparable?.marketStatus?.forSale === "unavailable" &&
              momentGivenDate.isAfter(momentCompareDate) &&
              comparable?.saleHistory.length
            );
          }
        );

        OffMarketRentComparables.value = [];
      }
    } catch (err) {
      error.value = err as Error;
    } finally {
      loading.value = false;
    }
  };

  return {
    OnTheMarketSaleComparables,
    OnTheMarketRentComparables,
    OffMarketRentComparables,
    soldHistoryComparables,
    comparables,
    loading,
    error,
    queryComparables,
  };
}
