<template>
  <GeneralDialog class="tw-max-w-[450px]" @on-close="onCloseModal">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <ContractSignYellowIcon />
        <Text variant="h4" textAlign="center" lineHeight="28px"
          >Payment Unsuccessful</Text
        >
        <Text textAlign="center">Something went wrong. Please try again</Text>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-items-center">
        <Button :disabled="loading" label="Try Again" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";
import { defineEmits } from "vue";

const emits = defineEmits(["on-close", "on-save"]);
const onCloseModal = () => {
  emits("on-close");
};
</script>
