<template>
  <div
    class="header__content__info tw-flex tw-items-center tw-justify-between tw-flex-col tw-bg-[#FAA50033] tw-p-4 tw-rounded-lg llg:tw-flex-wrap tw-text-left"
  >
    <div v-if="isViewMode" class="tw-w-full tw-max-w-full view-mode">
      <div
        class="tw-flex tw-items-center tw-justify-between tw-w-full tw-max-w-full md:tw-flex-col md:tw-items-start md:tw-gap-3"
      >
        <p
          class="tw-text-base tw-font-semibold tw-leading-[20.08px] tw-tracking-[0.15px] tw-text-[#0c0f4a]"
        >
          Expand Your Network: Add this trusted contractor to your team and
          unlock seamless collaboration for your Jobs/Projects.
        </p>
        <v-btn
          @click="toggleTradeNetworkModal"
          class="button button-purple tw-shrink-0 !tw-capitalize"
        >
          Add To Trade Network
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="tw-flex tw-items-center tw-justify-between tw-w-full tw-max-w-full md:tw-flex-col md:tw-items-start md:tw-gap-2"
    >
      <div class="tw-text-[#0C0F4A] tw-w-full tw-max-w-full md:tw-w-full">
        <div class="tw-text-[20px] tw-font-bold tw-leading-normal">
          Get a Link
        </div>
        <div class="tw-text-[16px] tw-font-normal">
          <p>
            Share your WorkStation Link Profile with others to connect and
            collaborate easily.
          </p>
        </div>
      </div>
      <div
        class="tw-text-right tw-w-[50%] tw-max-w-full md:tw-w-full"
        v-if="getLinkData"
      >
        <div
          class="tw-bg-white tw-rounded-[10px] tw-p-4 tw-flex tw-flex-col tw-items-start rsm:tw-flex-row rsm:tw-justify-between rsm:tw-items-center tw-gap-2 tw-w-full tw-max-w-full tw-shrink-0"
        >
          <div
            v-if="getLinkData?.url"
            class="tw-truncate tw-text-[16px] tw-text-normal tw-max-w-full"
          >
            {{ getLinkData.url }}
          </div>
          <v-btn
            v-if="getLinkData?.url"
            @click="onClickCopyLink"
            class="button button-purple tw-shrink-0 !tw-lowercase"
          >
            <img
              src="@/assets/icons/copy-3.svg"
              class="tw-w-full tw-h-auto tw-max-w-3 tw-max-h-full tw-object-contain tw-mr-1"
            />
            Copy Link
          </v-btn>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-flex tw-justify-end" v-if="getLinkData">
    <div
      class="edit__link__text tw-inline-block tw-mt-3 tw-ml-auto md:tw-ml-0 md:tw-mr-auto tw-cursor-pointer"
    >
      <Text variant="p" textColor="#4B4BFF" @click="toggleModal"
        >Edit Link</Text
      >
      <v-icon icon="mdi-chevron-right" color="#4B4BFF"></v-icon>
    </div>
  </div>
  <WorkStationLinkModal v-if="isOpenModal" @onClickCancel="toggleModal" />

  <UnSendTradeNetworkRequest
    v-if="isOpenTradeNetworkModal"
    @on-save="onClickConfirm"
    @on-cancel="toggleTradeNetworkModal"
  />
</template>
<script setup>
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserWorkstationSubStatusEnum } from "@/core/enums";
import WorkStationLinkModal from "@/modules/Specialism/components/WorkStationLinkModal.vue";
import ToolTip from "@/core/components/common/ToolTip.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { displayToastMessage } from "@/core/utils/common";
import UnSendTradeNetworkRequest from "@/modules/workStation-link-tree/modal/UnSendTradeNetworkRequest.vue";
import TradeNetworkService from "@/core/services/trade-network.service";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
const internalInstance = getCurrentInstance();
const isOpenModal = ref(false);
const isOpenTradeNetworkModal = ref(false);
const store = useStore();
const props = defineProps({
  isViewMode: {
    type: Boolean,
    default: false,
  },
});
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const linkWorkStation = computed(
  () =>
    store.getters[`${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`]
);

const getLinkData = computed(() => {
  return activeUserWorkstation.value?.workstationLinkTree;
});
const toggleModal = () => {
  isOpenModal.value = !isOpenModal.value;
};
const toggleTradeNetworkModal = () => {
  isOpenTradeNetworkModal.value = !isOpenTradeNetworkModal.value;
};
const onClickCopyLink = async () => {
  try {
    await navigator.clipboard.writeText(getLinkData.value.url);
    displayToastMessage(internalInstance, "copied", "success");
  } catch (error) {
    console.log();
  }
};

const onClickConfirm = async () => {
  try {
    await TradeNetworkService.sendRequestToContractor({
      senderWorkstationId: activeUserWorkstation.value?.id,
      receiverWorkstationId: linkWorkStation.value?.id,
      projectId: null,
    });

    store.dispatch(`${TRADE_NETWORK}/resetInviteContractorFromTradeNetwork`);
    displayToastMessage(
      internalInstance,
      "Trade network request sent successfully.",
      "success"
    );
  } catch (error) {
    if (error.response) {
      const errorMessage =
        error.response.data.message ||
        "Something went wrong, please try again later.";
      displayToastMessage(internalInstance, errorMessage, "error");
    } else if (error.request) {
      displayToastMessage(
        internalInstance,
        "No response from server. Please try again later.",
        "error"
      );
    } else {
      displayToastMessage(internalInstance, `Error: ${error.message}`, "error");
    }
  } finally {
    toggleTradeNetworkModal();
  }
};
</script>
<style lang="scss" scoped>
.header__content__info:has(.view-mode) .edit__link__text {
  display: none;
}

.button-purple:hover {
  background-color: #4f55f0 !important;
  color: #fff !important;
}
</style>
