/**
 * Documents route page
 */
import DocumentsPage from "@/modules/documents/pages/DocumentsPage.vue";

export const DOCUMENTS_ROUTE = "documents";

export default [
  {
    path: "/documents",
    name: DOCUMENTS_ROUTE,
    component: DocumentsPage,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Documents",
    },
  },
];
