import $axios from "../utils/axios-api-config";

export default {
  createNewEOHEvent(payload: any) {
    return $axios.post("/emergency_out_of_hours", payload);
  },
  getEOHEvents() {
    return $axios.get(`/emergency_out_of_hours`);
  },
  getParticularEOHEvents(eventID: number) {
    return $axios.get(`/emergency_out_of_hours/${eventID}`);
  },
  updateParticularEOHEvents(eventID: number, payload: any) {
    return $axios.patch(`/emergency_out_of_hours/update/${eventID}`, payload);
  },
  deleteParticularEOHEvents(eventID: number) {
    return $axios.delete(`/emergency_out_of_hours/${eventID}`);
  },
  getSelectedPropertiesOccupier(params: any) {
    return $axios.get(`/properties/get-property-occupiers`, { params });
  },
  getEOHEventListForPropertiesOccupier() {
    return $axios.get(`/emergency_out_of_hours/property-occupier/EOH-list`);
  },
  stopAutomateProjectConversion(payload: any) {
    return $axios.post(`/property-issues/deleteTimeout`, payload);
  },
};
