<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#FE5636" />
    <g clip-path="url(#clip0_151_45885)">
      <g clip-path="url(#clip1_151_45885)">
        <path
          d="M21.0822 12.4264C20.7826 12.1266 20.3063 12.1078 19.9843 12.3702C19.9377 12.4081 19.9738 12.3747 19.2646 13.0838L21.1054 14.9246L21.7669 14.2713C22.0868 13.9514 22.0868 13.431 21.7669 13.1111L21.0822 12.4264Z"
          fill="white"
        />
        <path
          d="M15.4894 16.8927L14.4067 19.2129C14.3337 19.3693 14.3663 19.5545 14.4883 19.6764C14.6103 19.7984 14.7955 19.831 14.9518 19.7581L17.2721 18.6753C17.3639 18.6324 17.086 18.8925 20.5212 15.5002L18.6841 13.6631C15.2781 17.0691 15.5331 16.7991 15.4894 16.8927Z"
          fill="white"
        />
        <path
          d="M17.9636 19.1796C17.8631 19.2788 17.7474 19.3593 17.6195 19.419L15.2992 20.5018C14.8429 20.7147 14.2855 20.6336 13.9088 20.2568C13.5443 19.8923 13.4458 19.3336 13.6638 18.8663L14.7466 16.546C14.8074 16.4157 14.8898 16.2981 14.9915 16.1963L18.4798 12.7081V9.23245C18.4798 8.55396 17.9278 8.00195 17.2493 8.00195H9.23734C8.55884 8.00195 8.00684 8.55396 8.00684 9.23245V20.7718C8.00684 21.4503 8.55884 22.0023 9.23734 22.0023H17.2493C17.9278 22.0023 18.4798 21.4503 18.4798 20.7718V18.6698L17.9636 19.1796ZM10.1944 10.6544H16.3196C16.5461 10.6544 16.7297 10.838 16.7297 11.0645C16.7297 11.2911 16.5461 11.4747 16.3196 11.4747H10.1944C9.9679 11.4747 9.78425 11.2911 9.78425 11.0645C9.78425 10.838 9.9679 10.6544 10.1944 10.6544ZM10.1944 12.8419H15.007C15.2336 12.8419 15.4172 13.0256 15.4172 13.2521C15.4172 13.4786 15.2336 13.6623 15.007 13.6623H10.1944C9.9679 13.6623 9.78425 13.4786 9.78425 13.2521C9.78425 13.0256 9.9679 12.8419 10.1944 12.8419ZM9.78425 15.4396C9.78425 15.2131 9.9679 15.0295 10.1944 15.0295H13.6945C13.921 15.0295 14.1047 15.2131 14.1047 15.4396C14.1047 15.6662 13.921 15.8498 13.6945 15.8498H10.1944C9.9679 15.8498 9.78425 15.6662 9.78425 15.4396Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_151_45885">
        <rect
          width="19.7"
          height="18.558"
          fill="white"
          transform="translate(5 5.92188)"
        />
      </clipPath>
      <clipPath id="clip1_151_45885">
        <rect
          width="18.8762"
          height="17.7819"
          fill="white"
          transform="translate(5.46191 6.46191)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "DraftJobs",
};
</script>
