<template>
  <CommonDialog
    HeaderTittle="Is an iknowa course 100% for you?"
    title="You're one small step away from unleashing your green building superpowers!"
    :className="['enrollment__wrapper']"
    v-if="isShowPaymentDialog"
    :onClickBackButton="onClickBackButton"
  >
    <template v-slot:body>
      <div class="modal_card_content tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Attendance</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Committing to all 10 course days.</Text
          >
          <v-checkbox v-model="modelOption1">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Passing the Exam</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Just like any hero needs to show up for training, so do you!
            Attending all ten course sessions is key to unlocking your full
            potential!</Text
          >
          <v-checkbox v-model="modelOption2">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div class="login_wrapper_points tw-flex tw-flex-col tw-gap-2">
          <div class="form-container-list !tw-flex !tw-items-center !tw-gap-2">
            <InfoBlueIcon />
            <Text variant="h6" textWeight="600">Level up</Text>
          </div>
          <Text
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            alineHeight="20px"
            >Take your learning to the next level by completing three projects
            on iknowa. It's a win-win – you gain experience and get to showcase
            your skills to clients!</Text
          >
          <v-checkbox v-model="modelOption3">
            <template v-slot:label>
              <Text variant="p" textWeight="600"
                >I accept & agree to the above</Text
              >
            </template>
          </v-checkbox>
        </div>
        <div
          class="card__buttons tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-w-1/2 md:tw-w-full"
        >
          <v-btn
            class="button button-orange"
            width="auto"
            size="large"
            block
            :loading="loading"
            :disabled="!modelOption1 || !modelOption2 || !modelOption3"
            @click="onClickAcceptAndEnroll"
          >
            accept & enroll
          </v-btn>
          <!-- @click="initializeStripePayment(false, true)" -->

          <label class="yellow-text"
            >Read Full Terms & Conditions
            <v-icon icon="mdi-chevron-right"></v-icon
          ></label>
        </div>
      </div>
    </template>
  </CommonDialog>
  <div class="calendar__wrapper tab__area__wrapper" v-if="isShowCalenderView">
    <label class="blue-text" @click="onClickBackCoursesButton"
      ><v-icon icon="mdi-chevron-left"></v-icon>Back to Courses
    </label>
    <div class="tab__area__blocks">
      <div class="tab__area__left__block">
        <h4 class="tab__area__title">Select your preferred course date</h4>
        <Calendar
          ref="calendarRef"
          title-position="left"
          class="custom__calendar"
          :masks="masks"
          color="sky-blue"
          trim-weeks
          transparent
          :attributes="calendarAttributes"
          v-model="selectedDate"
          expanded
          :min-date="currentMonthStartDate"
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="custom__calender__date">
              <span class="day-label" :class="{ 'today-date': day.isToday }">{{
                day.day
              }}</span>
              <div class="">
                <ul>
                  <li>
                    <div class="event__list">
                      <div
                        v-for="attr in attributes"
                        :key="attr.id"
                        class="custom__calender__event__day !tw-h-auto !tw-p-0 !tw-overflow-visible tw-flex tw-flex-col tw-justify-center tw-gap-3 tw-relative group"
                        :class="{ 'is-active': courseScheduleId === attr.id }"
                        @click="onClickSelectCourse(attr)"
                      >
                        <div
                          class="tw-h-[60px] tw-overflow-auto tw-p-[5px] tw-relative"
                        >
                          <div class="tw-flex tw-items-center tw-gap-1">
                            <v-icon
                              v-if="attr.course?.name"
                              icon="mdi-record"
                            ></v-icon>
                            {{ attr?.course?.name }}
                          </div>
                          <Text
                            class="tw-text-[8px]"
                            textColor="rgba(12, 15, 74, 0.5)"
                            >spot(s) available:
                            {{
                              attr?.allotedSpot < attr?.spotLimit
                                ? attr?.spotLimit - attr?.allotedSpot
                                : "Full"
                            }}</Text
                          >
                        </div>

                        <v-menu
                          :persistent="false"
                          :close-on-content-click="false"
                          scroll-strategy="close"
                          :open-on-hover="isDesktop"
                          location="end"
                        >
                          <template v-slot:activator="{ props }">
                            <div v-bind="props" class="mod-popup-trigger">
                              Trigger
                            </div>
                          </template>
                          <div class="mod-popup__content">
                            <div class="mod-popup__content-inner">
                              <div class="mod-popup__content-heading">
                                <p>Course Details</p>
                              </div>
                              <div class="mod-popup__content-title">
                                <p>{{ attr?.course?.name }}</p>
                              </div>
                              <div class="mod-popup__content-info">
                                <div class="mod-popup__content-info-item">
                                  <img
                                    src="../../../assets/icons/popup-calender-clock.svg"
                                  />
                                  <p>{{ extractTime(attr.startDate) }}</p>
                                </div>
                                <div class="mod-popup__content-info-item">
                                  <img
                                    src="../../../assets/icons/popup-calender-calender.svg"
                                  />

                                  <ul>
                                    <li
                                      v-for="(week, i) in sortedWeeks(
                                        attr.courseScheduleWeeks
                                      )"
                                      :key="week.id"
                                    >
                                      Week:{{ i + 1 }}
                                      {{ formatDate(week?.weekStartDate) }}
                                      -
                                      {{ formatDate(week?.weekEndDate) }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="mod-popup__content-location">
                                <p>Location</p>
                                <div class="mod-popup__content-location-text">
                                  <p>
                                    {{ attr?.location?.lookUpAddress }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </Calendar>
      </div>

      <div class="tab__area__right__block !tw-p-3 tw-box-border">
        <v-card class="tab__area__right__block__card !tw-p-3 tw-box-border">
          <div class="content tw-flex tw-flex-col tw-gap-4">
            <div>
              <h4 class="title">View your course selection</h4>
              <p class="description">
                Here's where your upskilling journey begins!
              </p>
            </div>
            <div v-if="!isShowAddressList" class="location__name__wrapper">
              <div class="heading">
                <h4 class="title">Locations</h4>
                <h6 @click="changeAddress" class="yellow-text">
                  Change Filter
                </h6>
              </div>
              <template v-if="selectedAddress">
                <div class="location__wrapper">
                  <!-- active class below -->
                  <div
                    class="location__city tw-cursor-pointer"
                    v-for="(item, index) in selectedAddress"
                    :key="index"
                    @click="onClickSelectCourse(item)"
                    :class="{ active: courseScheduleId == item?.id }"
                  >
                    <div class="tab__area__list__icon">
                      <img
                        class="open-logo tw-min-w-[18px] tw-max-w-[18px] tw-min-h-[20px] tw-max-h-[20px]"
                        src="../../../assets/icons/yellow-location-icon.svg"
                      />
                    </div>
                    <p>
                      {{ item.address }}
                    </p>
                  </div>
                </div>
              </template>
              <div v-if="!selectedAddress.length" class="location__city">
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-location-icon.svg"
                  />
                </div>
                <p>No selected address filter</p>
              </div>
            </div>
            <div
              v-if="isShowAddressList"
              class="location__name__wrapper tw-flex tw-flex-col tw-gap-4"
            >
              <div class="heading">
                <h4 class="title">Locations</h4>
                <h6 @click="saveAddressChanges" class="yellow-text">Filter</h6>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <!-- <v-text-field
                  density="compact"
                  variant="outlined"
                  class="c-input rounded-full !tw-mt-0 !tw-mb-0"
                  v-model="selectedAddress"
                  disabled
                ></v-text-field> -->
                <div class="postcode__wrapper !tw-h-[300px]">
                  <ul class="address__postcodes !tw-gap-0">
                    <li
                      v-for="(item, index) in addressListFromCourse"
                      :key="index"
                    >
                      <v-checkbox v-model="selectedAddress" :value="item">
                        <template v-slot:label>
                          <Text
                            variant="xsmall"
                            textWeight="600"
                            lineHeight="20px"
                            >{{ item.address }}</Text
                          >
                        </template>
                      </v-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab__area__card" v-if="enrollCourseName">
            <h6 class="title">
              {{ enrollCourseName ? enrollCourseName : courseDetails?.name }}
            </h6>
            <span class="yellow-text"
              >Starting date:
              {{
                enrollCourseStartDate
                  ? formattedStartDate(enrollCourseStartDate)
                  : formattedStartDate(
                      courseDetails?.courseSchedules[0]?.startDate
                    )
              }}</span
            >
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="span" textWeight="600"
                >Spots available:
                <Text variant="span" textColor="#FAA500">{{
                  spotsRemaining ? spotsRemaining : "Full"
                }}</Text></Text
              >
              <Text variant="span" textWeight="600"
                >Location:
                {{
                  enrollCourseAddress
                    ? enrollCourseAddress
                    : courseDetails?.courseSchedules[0]?.address
                }}</Text
              >
            </div>
          </div>

          <v-checkbox v-model="option1" class="tab__area__checkbox">
            <template v-slot:label>
              <p>Add me to the live queue.</p>
              <v-icon icon="mdi-information"></v-icon>
            </template>
          </v-checkbox>

          <v-checkbox
            v-model="option2"
            class="tab__area__checkbox remove-padding"
          >
            <template v-slot:label>
              <p>
                I have read and agree with the
                <span class="yellow-text remove-padding"
                  >Terms of Service & Privacy Policy</span
                >
              </p>
            </template>
          </v-checkbox>
          <v-btn
            class="button button-orange w-full"
            @click="onClickConfirmMyEnrollment"
            :disabled="isEnrollDisable"
            :loading="enrollLoading"
            >confirm my enrollment <v-icon icon="mdi-chevron-right"
          /></v-btn>
        </v-card>
      </div>
    </div>
  </div>
  <CoursesListView v-if="isShowEnrollmentDialog" />
  <ReserveSpotsModal
    v-if="isShowReserveSpotModal"
    @on-close="onToggleReserveSpotModal"
    @on-continue="onReserveSpotContinue"
    :selectedSchedule="selectedCourseSchedule"
  />
  <PaymentStatusModal
    v-if="isShowPaymentSuccessModal"
    :onContinue="onClickPaymentSuccessContinue"
    :paymentStatus="paymentStatus"
    :planType="3"
  />
</template>

<script lang="ts">
import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import { Calendar } from "v-calendar";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  getCurrentInstance,
} from "vue";
import "v-calendar/style.css";
import { useStore } from "vuex";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import {
  cloneObject,
  displayToastMessage,
  parseJSON,
} from "@/core/utils/common";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { useRouter } from "vue-router";
import InfoBlueIcon from "@/core/components/icons/InfoBlueIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CoursesListView from "@/modules/courses/components/CoursesListView.vue";
import moment from "moment";
import ReserveSpotsModal from "@/modules/courses/components/ReserveSpotsModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import PaymentStatusModal from "@/core/components/PaymentStatusModal.vue";
export default defineComponent({
  name: "CalenderView",

  components: {
    Calendar,
    CommonDialog,
    InfoBlueIcon,
    Text,
    CoursesListView,
    ReserveSpotsModal,
    PaymentStatusModal,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const date = new Date();
    const calendarRef = ref(null) as any;
    const store = useStore();
    const router = useRouter();
    const month = new Date().getMonth();
    const selectedDate = ref(moment().format("YYYY-MM-DD"));
    const year = new Date().getFullYear();
    const courseSchedules = ref([]);
    const isShowPaymentDialog = ref(false);
    const enrollLoading = ref(false);
    const isShowCalenderView = ref(true);
    const isShowEnrollmentDialog = ref(
      localStorage.getItem("isShowEnrollmentDialog") === "true"
    );
    const courseScheduleId = ref();
    const enrollCourseStartDate = ref("");
    const enrollCourseName = ref();
    const loading = ref(false);
    const masks = {
      weekdays: "WWW",
    };
    const option1 = ref(false);
    const option2 = ref(false);
    const isShowAddressList = ref(false);
    const addressListFromCourse = ref([]) as any;
    const selectedAddressIndex = ref(null);
    const selectedCourseSchedule = ref(null);
    const selectedAddress = ref([]) as any;

    const onClickBackCoursesButton = () => {
      router.push("/courses");
    };
    const modelOption1 = ref(false);
    const modelOption2 = ref(false);
    const modelOption3 = ref(false);
    const stripe = ref(null);
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const customer = ref("") as any;
    const enrollCourseAddress = ref("");
    const enrollCourseSpotLimit = ref("");
    const spotsRemaining = ref(null) as any;
    const calendarAttributes = ref([]) as any;
    const isShowReserveSpotModal = ref(false);
    const isShowPaymentSuccessModal = ref(false);
    const paymentStatus = ref(null) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const courseDetails = computed(
      () => store.getters[`${COURSES_STORE}/courseDetails`]
    );
    const getCourseSchedule = async () => {
      try {
        let response = await store.dispatch(
          `${COURSES_STORE}/getCourseSchedule`,
          {
            name: courseDetails.value?.name,
          }
        );

        const currentDate = moment().format("YYYY-MM-DD");
        const filteredData = response.data.filter((course: any) => {
          const enrollEnd = moment(course.enrollmentEndDate, "YYYY-MM-DD");

          // Check if the current date is between or equal to the enrollment start and end dates
          return moment(currentDate).isSameOrBefore(enrollEnd);
        });
        courseSchedules.value = filteredData;
        // courseSchedules.value = response.data;
        addressListFromCourse.value = courseSchedules.value

          .filter((item: any) => item.address !== null)
          .map((item: any) => {
            return {
              address: item.address,
              id: item.id,
            };
          });
        selectedAddress.value = addressListFromCourse.value;
        if (courseSchedules.value) {
          calendarAttributes.value = await getCalenderAttribute();
        }
      } catch (error) {
        console.log("error:", error);
      }
    };

    const fetchLearnersCount = async (scheduleId: any) => {
      try {
        const response = await store.dispatch(
          `${COURSES_STORE}/getCourseScheduleById`,
          {
            courseScheduleId: scheduleId,
          }
        );
        const { course } = response;

        const { userCourses } = course;

        if (userCourses.length) {
          let users = [];
          userCourses.forEach((userCourse: any) => {
            const { userCourseSchedules, user } = userCourse;

            if (userCourseSchedules.length) {
              const item = userCourseSchedules.find(
                (userCourseSchedule: any) => {
                  return userCourseSchedule?.courseSchedule?.id === scheduleId;
                }
              );
              if (item) {
                users.push(user);
              }
            }
          });
          return users.length;
        }

        return 0;
      } catch (error) {
        console.error(
          `Failed to fetch learners count for course ${scheduleId}:`,
          error
        );
        return 0; // Default to 0 if there's an error
      }
    };

    const getSpotRemaining = async (scheduleId: any, spotLimit: any) => {
      const enrolledLearnersCount = await fetchLearnersCount(scheduleId);
      let spots: any;
      if (enrolledLearnersCount >= spotLimit) {
        spots = 0;
      } else {
        spots = spotLimit - enrolledLearnersCount;
      }
      return spots;
    };
    const getcourseScheduleWeeks = async (scheduleId: any) => {
      const response = await store.dispatch(
        `${COURSES_STORE}/getCourseScheduleById`,
        {
          courseScheduleId: scheduleId,
        }
      );

      const { courseScheduleWeeks } = response;
      return courseScheduleWeeks;
    };

    const getCalenderAttribute = async () => {
      if (selectedAddress.value.length) {
        const selectedIds = selectedAddress.value.map((item: any) => item.id);

        const filteredCourseSchedulesByAddress = courseSchedules.value.filter(
          (schedule: any) => selectedIds.includes(schedule.id)
        );

        const results = await Promise.all(
          filteredCourseSchedulesByAddress.map(async (course: any) => ({
            ...course,
            spotsRemaining: await getSpotRemaining(course.id, course.spotLimit),
            courseScheduleWeeks: await getcourseScheduleWeeks(course.id),
            dates: new Date(course?.startDate),
          }))
        );

        return results;
      }

      const results = await Promise.all(
        courseSchedules.value.map(async (course: any) => ({
          ...course,
          spotsRemaining: await getSpotRemaining(course.id, course.spotLimit),
          dates: new Date(course?.startDate),
        }))
      );

      return results;
    };
    const onClickConfirmMyEnrollment = async () => {
      const response = await store.dispatch(
        `${COURSES_STORE}/checkCourseAlreadyEnrolled`,
        {
          userId: user.value?.id,
          scheduleId: courseScheduleId.value,
        }
      );
      if (!response?.isAlreadyEnrolled) {
        onToggleReserveSpotModal();
      } else {
        displayToast("You are already enrolled.", "error");
      }
    };
    const onToggleReserveSpotModal = () => {
      isShowReserveSpotModal.value = !isShowReserveSpotModal.value;
    };
    const selectedDates = computed(
      () => store.getters[`${COURSES_STORE}/selectedDates`]
    );

    const onReserveSpotContinue = () => {
      onToggleReserveSpotModal();
      isShowPaymentDialog.value = true;
    };
    const onClickOpenPaymentDialog = async () => {
      const response = await store.dispatch(
        `${COURSES_STORE}/checkCourseAlreadyEnrolled`,
        {
          userId: user.value?.id,
          scheduleId: courseScheduleId.value,
        }
      );
      if (!response?.isAlreadyEnrolled) {
        isShowPaymentDialog.value = !isShowPaymentDialog.value;
      } else {
        displayToast("You are already enrolled.", "error");
      }
    };

    const createCourses = async () => {
      try {
        paymentStatus.value = null;
        enrollLoading.value = true;

        const courseReserveData = selectedDates.value.map((date: any) => {
          return {
            courseId: parseInt(courseDetails.value?.id, 10),
            courseScheduleId: date.id,
            selectedSpotCount: date.spots,
          };
        });

        const request = {
          userId: user.value.id,
          courseReserveData,
        };
        const reserveSpots = await store.dispatch(
          `${COURSES_STORE}/reserveSpot`,
          request
        );

        const userCourseIds = reserveSpots.map((spot: any) => {
          return spot.id;
        });
        const parsedSubscription = parseJSON(
          activeUserWorkstation?.value?.subscription
        );

        const planType = parsedSubscription?.planType
          ? parsedSubscription?.planType
          : parseInt(parsedSubscription?.metadata.planType, 10);
        const payload = {
          userCourseIds,
          workStationId: activeUserWorkstation.value.id,
          planType,
        };

        const response = await store.dispatch(
          `${COURSES_STORE}/courseUpgradeSubscription`,
          {
            userId: user.value.id,
            payload,
          }
        );
        paymentStatus.value = "paid";
        // const createUserCourse = await store.dispatch(
        //   `${COURSES_STORE}/createUserCourse`,
        //   {
        //     userId: user.value?.id,
        //     courseId: courseDetails.value?.id,
        //     forQueue: option1.value,
        //     terms: option2.value,
        //   }
        // );
        // const createUserCourse = await store.dispatch(
        //   `${COURSES_STORE}/createUserCourse`,
        //   {
        //     userId: user.value?.id,
        //     courseId: courseDetails.value?.id,
        //     forQueue: option1.value,
        //     terms: option2.value,
        //   }
        // );
        // await store.dispatch(`${COURSES_STORE}/updateUserCourse`, {
        //   userId: user.value?.id,
        //   courseId: createUserCourse?.id,
        //   forQueue: option1.value,
        //   terms: option2.value,
        //   enrollmentStatus: "1",
        // });
        // if (createUserCourse) {
        //   const createUserCourseSchedules = await store.dispatch(
        //     `${COURSES_STORE}/createUserCourseSchedules`,
        //     {
        //       courseScheduleId: courseScheduleId.value,
        //       courseUserId: createUserCourse?.id,
        //     }
        //   );
        //   if (createUserCourseSchedules) {
        //     enrollLoading.value = false;
        //   }
        // }
      } catch (error) {
        console.log("course enrolled error", error);
        paymentStatus.value = "failed";
      } finally {
        enrollLoading.value = false;
      }
    };

    const togglePaymentSuccessModal = () => {
      isShowPaymentSuccessModal.value = !isShowPaymentSuccessModal.value;
    };
    const onClickPaymentSuccessContinue = () => {
      togglePaymentSuccessModal();
      onClickRedirectToEnrollModal();
    };

    const onClickAcceptAndEnroll = async () => {
      isShowCalenderView.value = false;
      isShowPaymentDialog.value = false;
      const parsedSubscription = parseJSON(
        activeUserWorkstation?.value?.subscription
      );
      if (!parsedSubscription) {
        isShowEnrollmentDialog.value = true;
        return displayToastMessage(
          internalInstance,
          `Please upgrade your workstation`,
          "info"
        );
      }
      togglePaymentSuccessModal();
      await createCourses();
    };
    const onClickRedirectToEnrollModal = () => {
      isShowCalenderView.value = false;
      isShowPaymentDialog.value = false;
      isShowEnrollmentDialog.value = true;
      localStorage.setItem("isShowEnrollmentDialog", "true");
    };

    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean
    ) => {
      loading.value = true;
      stripe.value = initializeStripe();
      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment);
      }
    };

    const onCheckout = async (isTrial: boolean, oneTimePayment: boolean) => {
      await createSession(isTrial, oneTimePayment);

      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log("Error: ", result.error);
            }
          });
      }
    };

    const isDesktop = computed(() => {
      return window.innerWidth > 1024;
    });

    const createSession = async (isTrial = false, oneTimePayment = false) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = enrollCourseName.value
        ? enrollCourseName.value
        : courseDetails.value?.name;

      const entity = JSON.stringify({
        id: userId,
        name: enrollCourseName.value,
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            isShowEnrollmentDialog.value = true;
            customer.value = customerResponse;
            loading.value = false;
          })
          .catch((e) => {
            console.log("Error: ", e.message);
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      isShowEnrollmentDialog.value = true;
      redirectUrl.value = `${host}/courses`;

      const { customerId } = customer.value;
      const totalPrice = 9.99;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: totalPrice,
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              productName,
              userId,
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse.id;
              localStorage.setItem("isShowEnrollmentDialog", "true");
              // createCourses();
            }
          });
      }
    };

    // Compute the current month start date
    const currentMonthStartDate = computed(() => {
      const date = new Date();
      date.setDate(1);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const startDate = `${year}-${month}-${day}`;
      return startDate;
    });

    const sortedWeeks = (
      weeks: { id: number; weekStartDate: string; weekEndDate: string }[]
    ): { id: number; weekStartDate: string; weekEndDate: string }[] => {
      return weeks.sort((a, b) => {
        const startA = new Date(a.weekStartDate).getTime();
        const startB = new Date(b.weekStartDate).getTime();
        return startA - startB; // Returns a number
      });
    };

    const formatDate = (dateString: string): string => {
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const extractTime = (dateTimeString: string): string => {
      const date = new Date(dateTimeString);
      const options: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return date.toLocaleTimeString(undefined, options);
    };
    const formattedStartDate = (value: any) => {
      const startDate = new Date(value);
      const day = startDate.getDate();
      const month = startDate.toLocaleString("default", { month: "short" });
      const year = startDate.getFullYear();
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    };

    function getOrdinalSuffix(day: number) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const isEnrollDisable = computed(() => {
      return !(
        option2.value &&
        spotsRemaining.value &&
        (courseScheduleId.value || enrollCourseName.value)
      );
    });

    const onClickBackButton = () => {
      onToggleReserveSpotModal();
      isShowPaymentDialog.value = false;
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSelectCourse = async (attr: any) => {
      // if (attr?.spotsRemaining === 0) {
      //   displayToast("Available spots are full.", "success");
      // }
      const calenderAtt = calendarAttributes.value.find(
        (item: any) => item.id === attr.id
      );
      if (calenderAtt?.spotLimit - calenderAtt?.allotedSpot <= 0) {
        return displayToast("All spots are currently booked.", "error");
      }
      let newDate = moment(calenderAtt?.startDate).format("YYYY-MM-DD");
      await calendarRef.value.move(newDate);
      spotsRemaining.value = await getSpotRemaining(
        calenderAtt?.id,
        calenderAtt?.spotLimit
      );
      selectedCourseSchedule.value = calenderAtt;
      courseScheduleId.value = calenderAtt?.id;
      enrollCourseStartDate.value = calenderAtt?.startDate;
      enrollCourseName.value = calenderAtt?.course?.name;
      enrollCourseAddress.value = calenderAtt?.address;
      enrollCourseSpotLimit.value = calenderAtt?.spotLimit;
    };

    const changeAddress = () => {
      isShowAddressList.value = true;
      addressListFromCourse.value = courseSchedules.value

        .filter((item: any) => item.address !== null)
        .map((item: any) => {
          return {
            address: item.address,
            id: item.id,
          };
        });
    };

    const saveAddressChanges = async () => {
      calendarAttributes.value = await getCalenderAttribute();
      isShowAddressList.value = false;
    };

    onBeforeMount(async () => {
      getCourseSchedule();
    });

    return {
      date,
      masks,
      getCalenderAttribute,
      onClickOpenPaymentDialog,
      isShowPaymentDialog,
      modelOption1,
      modelOption2,
      modelOption3,
      onClickBackCoursesButton,
      option1,
      option2,
      initializeStripePayment,
      isShowEnrollmentDialog,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading,
      courseDetails,
      formattedStartDate,
      user,
      isEnrollDisable,
      onClickBackButton,
      enrollLoading,
      onClickSelectCourse,
      enrollCourseStartDate,
      enrollCourseName,
      courseScheduleId,
      enrollCourseAddress,
      changeAddress,
      isShowAddressList,
      saveAddressChanges,
      addressListFromCourse,
      selectedAddressIndex,
      enrollCourseSpotLimit,
      selectedAddress,
      onClickRedirectToEnrollModal,
      isShowCalenderView,
      spotsRemaining,
      fetchLearnersCount,
      getSpotRemaining,
      calendarAttributes,
      selectedDate,
      calendarRef,
      currentMonthStartDate,
      isShowReserveSpotModal,
      onToggleReserveSpotModal,
      onReserveSpotContinue,
      onClickConfirmMyEnrollment,
      selectedCourseSchedule,
      isShowPaymentSuccessModal,
      onClickPaymentSuccessContinue,
      onClickAcceptAndEnroll,
      paymentStatus,
      isDesktop,
      sortedWeeks,
      formatDate,
      extractTime,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";
.popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mod-popup-trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  font-size: 0;
  line-height: 0;
}

.mod-popup__content {
  width: 358px;

  @media (max-width: 375px) {
    width: 90vw;
  }

  &-inner {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 64px 0px #0000001c;
    border-radius: 10px;
    padding: 14px 20px;
    @include fluidFont(12, 12, 14px);
    color: #70708c;
  }

  &-heading {
    @include fluidFont(14, 14, 18px);
    font-weight: 600;
    color: #0c0f4a;
    padding-bottom: 14px;
    margin-bottom: 12px;
    border-bottom: 0.66px dashed #8687a5;
  }

  &-title {
    font-weight: 700;
    color: #111111;
    @include fluidFont(16, 16, 28px);
    margin-bottom: 10px;
  }

  &-info {
    display: grid;
    grid-gap: 6px;

    &-item {
      display: flex;
      grid-template-columns: 16px 1fr;
      grid-gap: 6px;
      align-items: center;
    }
  }

  &-location {
    margin-top: 20px;

    &-text {
      @include fluidFont(14, 14, 18px);
      color: #111111;
      margin-top: 6px;
    }
  }
}
</style>
