<template>
  <ChipState
    :label="statusLabel ? statusLabel : 'You’re Enrolled'"
    textColor="#FFF"
    bgColor="#1FB27A"
    :isDense="true"
  >
    <template #prefix>
      <CheckIconWhite />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import CheckIconWhite from "@/core/components/icons/CheckIconWhite.vue";
const props = defineProps({ statusLabel: String });
</script>
<script>
export default {
  name: "EnrolledChipSate",
};
</script>
