<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.71484 3.85156V50.1371H34.7148L46.2863 37.5135V3.85156H7.71484ZM34.7154 44.4286V36.6371H41.8577L34.7154 44.4286ZM42.4288 32.7801H30.8579V46.2801H11.5718V7.70855H42.4288V32.7801Z"
      fill="#FAA500"
    />
    <path
      d="M15.4297 11.5703H38.5727V15.4273H15.4297V11.5703ZM15.4297 19.2848H34.7152V23.1418H15.4297V19.2848ZM15.4297 26.9988H27.0012V30.8558H15.4297V26.9988Z"
      fill="#FAA500"
    />
  </svg>
</template>
<script>
export default {
  name: "ContractAcceptSignYellowIcon",
};
</script>
