<template>
  <Card class="!tw-min-h-[600px] tw-p-4 tw-box-border">
    <template #content>
      <main
        class="estimate-revision-summary tw-min-h-[536px] tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-items-start tw-w-full tw-h-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-w-full">
          <div
            class="tw-flex tw-items-center tw-w-full tw-justify-start tw-gap-2"
          >
            <Text variant="h6" whiteSpace="nowrap">Your Estimate Summary</Text>
            <div class="tooltip__wrapper">
              <v-tooltip
                max-width="242px"
                activator="parent"
                location="top"
                class="estimate_tooltip tw-cursor-pointer"
              >
                Your Estimate Summary
              </v-tooltip>

              <v-icon
                icon="mdi-information-slab-circle-outline"
                color="#80829F"
                size="x-small"
              ></v-icon>
            </div>
          </div>
          <EstimateSummaryData />

          <div
            class="estimate-revision-summary__phase-content tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-2 tw-pr-3"
          >
            <SmallPhaseItem
              v-for="(estimate, index) in estimateForm.projectStages"
              :estimate="estimate"
              :phaseIndex="index"
              :key="index"
            />
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-7 tw-w-full">
          <!-- <Button
            variant="secondary"
            :label="grantLabel"
            @click="onOpenAddGrantModal"
          
          /> -->

          <section class="tw-flex tw-flex-col tw-gap-6">
            <div class="tw-flex tw-flex-col tw-gap-2">
              <LabelWithValue
                :noBg="true"
                label="Your total earnings"
                :data="'£' + formatNumber(totalSubTotal)"
              />
              <LabelWithValue :noBg="true" label="VAT" :data="'£' + totalVat" />
              <LabelWithValue
                :noBg="true"
                label="Service fee"
                :data="'£' + formatNumber(serviceCharges)"
              >
                <template #postfix>
                  <div class="tooltip__wrapper">
                    <v-tooltip
                      max-width="242px"
                      activator="parent"
                      location="top"
                      class="estimate_tooltip tw-cursor-pointer"
                    >
                      Service Fee
                    </v-tooltip>

                    <v-icon
                      icon="mdi-information-slab-circle-outline mdi"
                      color="#80829F"
                    ></v-icon>
                  </div>
                </template>
              </LabelWithValue>

              <v-divider></v-divider>
              <LabelWithValue
                :noBg="true"
                :allFontBold="true"
                :label="isGovernmentEstimate ? totalLabel : customerPaysLabel"
                :data="'£' + formatNumber(customerTotal)"
              />
              <LabelWithValue
                v-if="isGovernmentEstimate"
                :noBg="true"
                :allFontBold="true"
                :label="formData.grantName ? formData.grantName : 'Personal'"
                :data="'- £ ' + formatNumber(formData.grantAmount)"
              />

              <LabelWithValue
                v-if="isGovernmentEstimate"
                :noBg="true"
                :allFontBold="true"
                :label="customerPaysLabel"
                :data="'£' + totalGovernment"
              />
            </div>
            <div
              v-if="!isFirstTimeCreatingEstimate"
              class="tw-grid tw-grid-cols-3 tw-gap-4"
            >
              <Button
                class="tw-col-span-3"
                :label="templateData ? 'update template' : 'save template'"
                @click.prevent="onSubmitTemplate"
                :disabled="isEstimateSubmit"
              />
            </div>
          </section>
        </div>
      </main>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";

import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import Button from "@/core/components/ui/general/Button.vue";

import AddGrantModal from "@/modules/jobs/components/modal/AddGrantModal.vue";
import SmallPhaseItem from "@/modules/jobs/components/estimates/SmallPhaseItem.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import projectEstimationService from "@/core/services/project-estimation.service";
import {
  AgreeTermsConsumerDisclosure,
  EstimatePhaseStatus,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
import { ElMessage } from "element-plus";
import { USER_STORE } from "@/store/modules/user";
import { JOBS_STORE } from "@/store/modules/jobs";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  getCurrentConTractorPhases,
  getSubContractorPhases,
} from "@/core/utils/common";
import EstimateTotal from "./EstimateTotal.vue";
import LabelWithValue from "@/core/uni-components/LabelWithValue.vue";

const store = useStore();
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const props = defineProps({
  templateData: {
    type: Object,
    default: null,
  },
  isFirstTimeCreatingEstimate: { type: Boolean, default: false },
});
const emits = defineEmits(["on-save-template", "on-edit-template"]);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const grantLabel = computed(() => {
  return !formData.value.grantType ? "Add grant" : "Edit grant";
});
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const isFormError = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);
const IsFinalEstimateSubmitted = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/IsFinalEstimateSubmitted`]
);

const totalSubTotal = computed(() => {
  const sum = estimateForm.value.projectStages.reduce((sum, phase) => {
    const roundedSubTotal = parseFloat(phase.subTotal);
    return sum + roundedSubTotal;
  }, 0);

  return Math.round(sum * 100) / 100;
});

const depositModalData = ref(null) as any;
const finalEstimateLoadingModal = reactive({
  visible: false,
  step: 0,
});

const totalVat = computed(() => {
  const sum = estimateForm.value.projectStages.reduce((sum, phase, index) => {
    const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
    return sum + roundedTotalVat;
  }, 0);
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any, index: number) => {
      const roundedTotalWithServiceFee = parseFloat(
        phase.subTotal * serviceFeePercentage
      );
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

const projectEstimate = computed(() => {
  return {
    ...formData.value,
    projectEstimateCreator: user.value,
    projectStages: estimateForm.value?.projectStages,
  };
});

const onSiteVisitRequest = () => {
  if (IsFinalEstimateSubmitted.value) {
    state.showRequestSiteVisitModal = true;
  }
};

defineExpose({
  onSiteVisitRequest,
});

const state = reactive({
  showSelectDepositModal: false,
  showSubmitEstimateConfirmationModal: false,
  showAcceptEstimateModal: false,
  showServiceAgreementModal: false,
  showConfirmJobModal: false,
  showAddGrandModal: false,
  showRequestSiteVisitModal: false,
});

const hasSubContractorsSubmitted = async () => {
  const subContractors = [] as any;
  const invitedContractorsList =
    store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`];
  invitedContractorsList.map((con: any) => {
    if (con.fromContractorId == activeUserWorkstation.value?.id) {
      subContractors.push(con);
    }
  });
  return !subContractors.some(
    (el: any) =>
      el.agreeTermsConsumerDisclosure == AgreeTermsConsumerDisclosure.NOT_ADDED
  );
};

const onSubmitTemplate = async () => {
  // const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  // if (
  //   isValid &&
  //   !isFormError.value.startDate &&
  //   !isFormError.value.validationError
  // ) {
  //   const isAccepted = checkIfAllAccepted();

  //   if (isAccepted.length === 0) {
  //     const hasSubmiited = await hasSubContractorsSubmitted();
  //     if (hasSubmiited) {
  //       if (isSubContractor.value) {
  //         state.showSubmitEstimateConfirmationModal = true;
  //       } else {
  //         state.showSelectDepositModal = true;
  //       }
  //     } else {
  //       ElMessage.error("Some of the sub-contractors have not submitted yet");
  //     }
  //   } else {
  //     ElMessage.error("please accept or reject the pending phases");
  //   }
  // } else {
  //   ElMessage.error(
  //     isFormError.value.validationError
  //       ? `validation failed`
  //       : `please fill out all fields`
  //   );
  // }
  if (props.templateData) {
    emits("on-edit-template");
  } else {
    emits("on-save-template");
  }
};

const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);

const onCloseSelectDepositModal = () => {
  state.showSelectDepositModal = false;
};

const projectEstimateInvitation = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimationInvitation`]
);

const onConfirmSelectDepositModal = async (payload: any) => {
  if (payload) {
    depositModalData.value = payload;
    state.showSelectDepositModal = false;
    state.showSubmitEstimateConfirmationModal = true;
  }
};

const onSubmitEstimateConfirmationModal = () => {
  state.showSubmitEstimateConfirmationModal = false;
  state.showServiceAgreementModal = true;
};

const onCloseSubmitEstimateConfirmationModal = () => {
  state.showSubmitEstimateConfirmationModal = false;
};

const onCloseAcceptEstimateModal = () => {
  state.showAcceptEstimateModal = false;
};

const onCloseServiceAgreementModal = () => {
  state.showServiceAgreementModal = false;
};

const onConfirmServiceAgreementModal = async () => {
  const isSubmitProject = await submitEstimate();
  if (isSubmitProject) {
    state.showServiceAgreementModal = false;
  }
};

const onCloseConfirmJobModal = () => {
  state.showConfirmJobModal = false;
};

const onCloseAddGrantModal = () => {
  state.showAddGrandModal = false;
};

const onOpenAddGrantModal = () => {
  state.showAddGrandModal = true;
};
const onCloseRequestSiteVisitModal = () => {
  state.showRequestSiteVisitModal = false;
};

const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const checkIfAllAccepted = () => {
  const invitedContractorsList =
    store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`];
  const setTotalPhases = store.getters[`${JOB_TEMPLATE_STORE}/getTotalPhases`];

  const subCon = getSubContractorPhases(
    activeUserWorkstation.value?.id,
    invitedContractorsList,
    setTotalPhases
  );
  const currCon = getCurrentConTractorPhases(
    activeUserWorkstation.value?.id,
    setTotalPhases
  );

  const subFinal = subCon.filter((el: any) => {
    let acceptedBy = null;
    if (el.acceptedBy == null) {
      acceptedBy = [];
    } else {
      if (Array.isArray(el.acceptedBy)) {
        acceptedBy = el.acceptedBy;
      } else {
        if (
          typeof el.acceptedBy == "string" &&
          JSON.parse(el.acceptedBy) &&
          Array.isArray(JSON.parse(el.acceptedBy))
        ) {
          acceptedBy = JSON.parse(el.acceptedBy);
        } else {
          acceptedBy = [];
        }
      }
    }

    if (
      [EstimatePhaseStatus.PENDING, EstimatePhaseStatus.WAITING].includes(
        el.phaseStatus
      )
    ) {
      return true;
    }

    if (
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      !acceptedBy.includes(activeUserWorkstation.value?.id)
    ) {
      return true;
    }
    return false;
  });

  const currFinal = currCon.filter((el: any) => {
    let acceptedBy = null;
    if (el.acceptedBy == null) {
      acceptedBy = [];
    } else {
      if (Array.isArray(el.acceptedBy)) {
        acceptedBy = el.acceptedBy;
      } else {
        if (
          typeof el.acceptedBy == "string" &&
          JSON.parse(el.acceptedBy) &&
          Array.isArray(JSON.parse(el.acceptedBy))
        ) {
          acceptedBy = JSON.parse(el.acceptedBy);
        } else {
          acceptedBy = [];
        }
      }
    }
    if (
      el.assignPhaseWorkStation &&
      [EstimatePhaseStatus.PENDING, EstimatePhaseStatus.WAITING].includes(
        el.phaseStatus
      )
    ) {
      return true;
    }

    if (
      !el.assignPhaseWorkStation &&
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      el.workStation.id == activeUserWorkstation.value?.id
    ) {
      return false;
    }

    if (
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      !acceptedBy.includes(activeUserWorkstation.value?.id)
    ) {
      return true;
    }
    return false;
  });

  return [...subFinal, ...currFinal];
};

const submitEstimate = async () => {
  return;
};

const formatNumber = (value: any) => {
  if (!value) return "";

  let parts = value.toString().split(".");

  parts[0] = parts[0].replace(/\D/g, "");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};
const isGovernmentEstimate = computed(() => {
  return formData.value.grantType;
});
const customerTotal = computed(() => {
  const total = totalSubTotal.value + totalVat.value + serviceCharges.value;
  return Math.round(parseFloat(total) * 100) / 100;
});
const customerPaysLabel = "Customer pays";
const totalLabel = "Total";
const totalGovernment = computed(() => {
  return customerTotal.value - parseFloat(formData.value.grantAmount);
});
</script>
<style lang="scss" scoped>
.tooltip__wrapper {
  .v-icon {
    @include fluidFont(16, 16, 30px);
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
