<template>
  <GeneralDialog
    class="tw-w-[500px] tw-max-w-full md:tw-w-[340px]"
    @on-close="onCloseModal"
    width="500px"
    max-width="100%"
  >
    <template #body>
      <CommonLoader
        v-if="loading"
        :loading="loading"
        class="loader-container"
      />
      <div class="tw-text-center tw-mt-20 md:tw-mt-0" v-if="!loading">
        <h3
          class="tw-text-2xl tw-tracking-[0.15px] tw-leading-6 tw-font-bold tw-text-[#0C0F4A] md:tw-text-xl"
        >
          Pay Deposit
        </h3>

        <p
          class="tw-text-sm tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0C0F4A] tw-mt-5 md:tw-text-xs tw-max-w-[82%] tw-m-auto md:tw-max-w-full"
        >
          Deposing funds enables the trade contractor to start working on your
          project. Be sure the estimate reflects the work you need completed
          before paying your deposit.
        </p>

        <div class="confirm_amount tw-mt-5">
          <p
            class="tw-text-sm tw-tracking-[0.4px] tw-leading-5 tw-font-bold tw-text-[#0c0f4a80]"
          >
            Deposit amount
          </p>
          <h4
            class="tw-text-[38px] tw-tracking-[0.25px] tw-leading-10 tw-font-bold tw-text-[#0c0f4a] md:tw-text-3xl"
          >
            {{ formattedCurrency(totalPayAmount) }}
          </h4>
        </div>
        <p
          class="tw-text-xs tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0c0f4a80] tw-mt-7 tw-max-w-[82%] tw-m-auto md:tw-max-w-full"
        >
          By confirming you are agreeing to our
          <strong class="tw-text-[#0c0f4a]"> Terms & Conditions </strong>
          and
          <strong class="tw-text-[#0c0f4a]"> Privacy Policy.</strong>
        </p>

        <p
          class="tw-text-xs tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0c0f4a80] tw-mt-7 tw-max-w-[86%] tw-m-auto md:tw-max-w-full"
        >
          Additionally, by confirming you are permitting our partner Modulr FS
          Limited to initiate a payment from your bank account and also agree to
          their
          <strong class="tw-text-[#0c0f4a]">Terms & Conditions</strong> and
          <strong class="tw-text-[#0c0f4a]">Privacy Policy</strong>
        </p>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3 tw-mt-6"
        v-if="!loading"
      >
        <Button
          variant="tertiary"
          :disabled="loading"
          label="Cancel"
          @click="onCloseModal"
          class="button button-purple-border"
        />
        <Button
          variant="tertiary"
          label="Confirm"
          :isLoading="loading"
          @click="onConfirm"
          class="button button-orange"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { defineEmits, ref, defineProps } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";

const props = defineProps({
  totalPayAmount: {
    type: Number,
  },
  paymentPayload: {
    type: Object,
  },
});
const loading = ref(false);
const formattedCurrency = (value: any) => {
  return CurrencyFilter.formatToCurrency(value);
};
const emits = defineEmits(["on-close", "on-confirm"]);
const onCloseModal = () => {
  emits("on-close");
};

const onConfirm = async () => {
  try {
    loading.value = true;
    const response = await tradeEstimateService.createPayDeposit(
      props?.paymentPayload
    );

    if (response) {
      let a = document.createElement("a");
      a.href = response?.redirectUrl;
      a.click();
      emits("on-confirm");
    }
  } catch (error) {
    console.log("error", error);
    emits("on-confirm");
  } finally {
    loading.value = false;
  }
};
</script>
<style lang="scss" scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
