import { ChatMessages } from "@/core/models/chat-messages";

export default {
  setNetworkDirectMessage(state: ChatMessages, payload: object) {
    state.NetworkDirectMessage = payload;
  },
  setCurrentMessageType(state: ChatMessages, payload: string) {
    state.currentMessageType = payload;
  },
  setProjectChatRequests(state: ChatMessages, payload: Array<any>) {
    state.projectChatRequests = payload;
  },
};
