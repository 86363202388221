<template>
  <GeneralDialog
    class="tw-max-w-[560px] tw-min-w-[300px] rmd:tw-min-w-[560px]"
    @on-close="onCloseModal"
  >
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-h-[233px]"
          >
            <img
              :src="require('@/assets/images/site-visit-thumbnail.svg')"
              alt="site visit"
            />
          </div>
          <Text
            class="tw-w-[70%]"
            variant="h6"
            textAlign="center"
            textWeight="400"
            lineHeight="28px"
          >
            By accepting a site visit request or if the project is in progress,
            you will be able to access and edit property data.
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-4 tw-items-center">
        <Button variant="tertiary" label="close" @click="onCloseModal" />
        <Button label="Request Site Visit" @click="onRequestASiteVisit" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close", "on-request-a-site-visit"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const onCloseModal = () => {
  emits("on-close");
};

const onRequestASiteVisit = () => {
  emits("on-request-a-site-visit");
};
</script>
