<template>
  <ChipState label="Video Consultation" textColor="#FFF" bgColor="#7467B7">
    <template #prefix>
      <VideoConsultationIcon />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import VideoConsultationIcon from "@/core/components/icons/VideoConsultationIcon";
</script>
<script>
export default {
  name: "VideoConsultationChip",
};
</script>
