import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "callFrameRef",
  class: "call-frame call-frame-fullscreen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isLoading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          show: $setup.isLoading
        }, null, 8, ["show"]))
      : _createCommentVNode("", true)
  ]))
}