import { ChatMessages } from "@/core/models/chat-messages";

export default {
  getUserWorkStationMessages: (state: ChatMessages) =>
    state.userWorkstationMessages,

  getChatMessagesRooms: (state: ChatMessages) => state.chatMessagesRooms,
  getNetworkDirectMessage: (state: ChatMessages) => state.NetworkDirectMessage,
  getCurrentMessageType: (state: ChatMessages) => state.currentMessageType,
  getProjectChatRequests: (state: ChatMessages) => state.projectChatRequests,
};
