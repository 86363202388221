<template>
  <WizardModal
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="modelDetails"
    :showHeaderInMobile="true"
    :hideTitleAndDescription="true"
    @on-close="$emit('onCloseButtonClick')"
  >
    <template #content>
      <main>
        <v-carousel
          class="!tw-h-auto !tw-pb-12 !tw-overflow-x-hidden"
          :show-arrows="false"
          hide-delimiter-background
          :touch="true"
        >
          <v-carousel-item v-for="(slide, i) in carouselItem" :key="i">
            <v-sheet height="100%" tile>
              <section
                class="benefits-content tw-flex tw-flex-col tw-gap-5 tw-w-full tw-px-2"
              >
                <div class="img">
                  <img
                    class="open-logo"
                    :src="require(`../../assets/images/${slide?.image}.png`)"
                  />
                </div>
                <div class="tw-w-full tw-flex tw-flex-col tw-gap-8">
                  <Text variant="h4">{{ slide?.title }}</Text>

                  <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
                    <Text
                      variant="p"
                      textColor="rgba(12, 15, 74, 0.5019607843)"
                      textWeight="400"
                      lineHeight="18px"
                      >{{ slide?.description }}</Text
                    >
                    <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
                      <div
                        class="tw-flex tw-items-start tw-gap-2 tw-w-full"
                        v-for="(item, ii) in Object.keys(slide?.benefits)"
                        :key="ii"
                      >
                        <img
                          class="tw-min-w-[1.5rem] tw-w-[1.5rem] tw-h-[1.5rem]"
                          src="../../assets/icons/tick.svg"
                        />
                        <Text
                          class="sm:!tw-text-[13px]"
                          variant="span"
                          textWeight="400"
                          lineHeight="18px"
                        >
                          {{ slide?.benefits[item] }}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </main>
    </template>
  </WizardModal>
</template>
<script setup>
import { ref } from "vue";

import WizardModal from "@/core/components/modals/WizardModal.vue";
import Text from "@/core/components/ui/general/Text.vue";

const modelDetails = ref({
  id: 2,
  tab: "info",
  name: "certifications",
  header: "iknowa Benefits",
});

const carouselItem = [
  {
    title: "Soar to new heights with iknowa",
    description:
      "Discover how an iknowa HQ can take your business to the next level:",
    image: "trade-login-image",
    benefits: {
      data1:
        "Helping you secure high-quality building and retrofitting projects:",
      data2: "Upskill and improve your green skills without breaking the bank",
      data3: "Win more contracts with our iknowa Verified Trade Passport",
    },
  },
  {
    title: "Soar to new heights with iknowa",
    description:
      "Backed by the New Homes Ombudsman to improve and supercharge the property universe:",
    image: "property-owner-login-image",
    benefits: {
      data1: "Enjoy the peace of mind, that comes with an embedded Ombudsman.",
      data2:
        "Level up with seamless property information and insights to add value to your property (Know Your Property - KYP)",
      data3: "Build with the best and partner with iknowa Verified Contractors",
    },
  },
];
</script>
<style lang="scss" scoped>
.open-logo {
  margin: 0 auto;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-height: 161px;
    object-fit: contain;
  }
}
.benefits-content {
  //   padding: 24px 24px 50px;
  padding-top: 15px;
  box-sizing: border-box;
  .img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 50px;
    box-sizing: border-box;
    z-index: 1;
    &:before {
      content: "";
      background-image: url("@/assets/images/module-bg-img.png");
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      filter: blur(18px);
      z-index: -1;
      top: -0px;
      right: 0;
      background-size: cover;
      background-position: center;
    }
    @media screen and (max-width: 767px) {
      background-size: cover;
    }
  }
}

.v-carousel {
  :deep(.v-carousel__controls) {
    gap: 0.2rem;
    .v-carousel__controls__item {
      margin: 0;
      width: 23px;
      height: 23px;

      .v-btn__content {
        .v-icon {
          opacity: 1;
        }
      }
    }
    .v-btn--active {
      .v-btn__content {
        .v-icon {
          color: #9bbeff;
          opacity: 1;
        }
      }
    }
  }
}
</style>
