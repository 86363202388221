<template>
  <div class="invite_contractor_wrapper md:!tw-h-full">
    <div v-if="isLoading" class="md:!tw-mt-16"><Loader v-show="true" /></div>
    <div v-else class="invite_contractor md:!tw-mt-0">
      <div class="invite_contractor__header">
        <div class="invite_contractor__title">
          <h3>Your Project</h3>
        </div>
        <v-card class="invite_contractor__summary">
          <div class="invite_contractor__summary__title">
            <div class="left_block">
              <div class="commercial">
                <v-icon>
                  <img src="../../../assets/icons/user.svg" alt="" />
                </v-icon>
                <p>Commercial</p>
              </div>

              <div class="residential">
                <v-icon>
                  <img src="../../../assets/icons/house.svg" alt="" />
                </v-icon>
                <p>{{ projectDetails?.projectProperties?.length }} Property</p>
              </div>
            </div>
            <div class="right_block">
              <EmergencyChip
                v-if="projectDetails?.type === ProjectTypesEnum.EMERGENCY"
              />
              <StandardChip
                v-if="projectDetails?.type === ProjectTypesEnum.STANDARD"
              />
              <RoutineChip
                v-if="projectDetails?.type === ProjectTypesEnum.ROUTINE"
              />
            </div>
          </div>
          <div class="invite_contractor__summary__content">
            <h5>{{ projectDetails?.name }}</h5>
            <p>Project ID: #{{ projectDetails?.refId }}</p>
          </div>
          <div class="invite_contractor__summary__footer">
            <v-checkbox
              label="Invite Only Project"
              v-model="isInviteOnlyProject"
            ></v-checkbox>
            <v-icon>
              <img src="../../../assets/icons/info-icon-transparent.svg" alt=""
            /></v-icon>
          </div>
        </v-card>
      </div>
      <div
        class="invite_contractor__banner"
        v-if="!userPreferredContractor?.length"
      >
        <div class="left_block md:!tw-w-full">
          <h5>Invite contractors from your network</h5>
          <p>Invite contractors from your network to work on Your projects</p>
          <v-btn
            class="button button-orange"
            @click="openContractorInvitationModal"
            >invite CONTRAcTORS</v-btn
          >
        </div>
        <div class="right_block">
          <img src="../../../assets/images/invite-banner.png" alt="" />
        </div>
      </div>
      <div class="invite_contractor__list">
        <h5>Your Contractor Network</h5>

        <div
          class="invite_contractor__list__cards"
          v-if="userPreferredContractor?.length"
        >
          <div
            class="invite_contractor__list__card__item"
            v-for="contractor in userPreferredContractor"
            :key="contractor.id"
          >
            <div class="invite_contractor__list__card__item__header">
              <div class="inner__content">
                <div class="contractor__img">
                  <img src="../../../assets/images/dashboard-img.png" alt="" />
                </div>
                <span></span>
                <div class="contractor__info">
                  <h6>{{ contractor?.userWorkstation?.name }}</h6>
                  <div class="contractor__info__summary">
                    <p>
                      {{
                        contractorType(
                          contractor.toUser?.userBusinessDetails?.type
                        )
                      }}
                    </p>
                    |
                    <p>$22 <span>/hr</span></p>
                    |
                    <div
                      class="contractor_location"
                      v-if="
                        contractor.toUser.userBusinessDetails.businessPostcode
                      "
                    >
                      <v-icon>
                        <img
                          src="../../../assets/icons/pin-location.svg"
                          alt=""
                        />
                      </v-icon>
                      <p>
                        {{
                          contractor.toUser.userBusinessDetails.businessPostcode
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="contractor__info__summary__footer">
                    <div class="left_block">
                      <v-icon>
                        <img src="../../../assets/icons/star-icon.svg" alt="" />
                      </v-icon>
                      <p>
                        {{ contractor.ratings }}/5 ({{
                          contractor.jobsCompleted
                        }}
                        Jobs)
                      </p>
                    </div>
                    <span>|</span>
                    <div class="right_block">
                      <v-icon icon="mdi-check-circle" />

                      <p>Currently Available</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="menu">
              <v-icon>
                <img src="../../../assets/icons/dots.svg " />
              </v-icon>
            </div> -->
            </div>
            <div class="invite_contractor__list__card__item__content">
              <div
                class="experience__tag"
                v-if="contractor?.userWorkstation?.experience"
              >
                <v-icon icon="mdi-check-circle" />
                <p>{{ contractor?.userWorkstation?.experience }}+ YEARS</p>
                <span>Experience</span>
              </div>
              <div
                class="contract_service_tag"
                v-if="contractor.userWorkstation.userSkill.length"
              >
                <div
                  class="custom_tags"
                  v-for="skill in getUserSkillList(
                    contractor.userWorkstation.userSkill
                  ).slice(0, 3)"
                  :key="skill.id"
                >
                  <v-icon>
                    <img
                      v-if="skill?.iconName"
                      :src="
                        require('@/assets/category-icons/' +
                          skill?.iconName +
                          '.svg')
                      "
                  /></v-icon>
                  <p>{{ skill?.label }}</p>
                </div>

                <div
                  v-if="
                    getUserSkillList(contractor.userWorkstation.userSkill)
                      ?.length > 3
                  "
                  class="custom_tags__more"
                >
                  <span>
                    +{{
                      getUserSkillList(contractor.userWorkstation.userSkill)
                        ?.length - 3
                    }}
                    More
                  </span>
                </div>
              </div>
            </div>
            <div
              class="invite_contractor__description"
              v-if="contractor.description"
            >
              <p v-html="contractor.userWorkstation.description"></p>
            </div>
            <div class="footer__buttons">
              <v-btn
                class="button button-purple-border"
                append-icon="mdi-send"
                :disabled="!isInviteOnlyProject"
                >MESSAGE
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="
                  onClickInviteEstimate(
                    contractor?.toUser?.id,
                    contractor?.userWorkstation?.id
                  )
                "
                :disabled="
                  isInviteButtonDisable(
                    contractor?.toUser?.id,
                    contractor?.userWorkstation?.id
                  )
                "
                >{{
                  isInviteDisable(
                    contractor?.toUser?.id,
                    contractor?.userWorkstation?.id
                  )
                    ? `Invited`
                    : `invite to Estimate`
                }}</v-btn
              >
            </div>
          </div>
        </div>
        <!-- <v-btn class="load_more" append-icon="mdi-chevron-down" variant="text"
        >Load More</v-btn
      > -->
        <div class="footer">
          <v-btn
            v-if="
              projectDetails?.projectJobStatus?.id ===
                ProjectJobStatusEnum.DRAFT_PROJECT && !isProjectLiveClicked
            "
            class="button button-orange"
            @click="onPostLiveProjectClick"
            :loading="isShowPostLiveLoading"
            >post live project</v-btn
          >
        </div>
      </div>
    </div>

    <ConfirmContractorInvitationModal
      v-if="iShowConfirmationModal"
      :selectedContractor="selectedContractor"
      @closeInviteButtonModal="closeInviteButtonModal"
    />
    <ShowInvitedModal
      v-if="isShowInviteModal"
      :isInviteOnlyProject="isInviteOnlyProject"
      @onCloseInviteModal="onCloseInviteModal"
    />
    <AddPreferredContractorModal
      v-if="isShowAddPreferredContractorModal"
      @onClickCloseTab="onClickCloseModel"
    />
  </div>
</template>

<script lang="ts">
import {
  ProjectJobStatusEnum,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import projectService from "@/core/services/project.service";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { USER_STORE } from "@/store/modules/user";
import { ref } from "vue";
import { computed } from "vue";
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ConfirmContractorInvitationModal from "../components/ConfirmContractorInvitationModal.vue";
import ShowInvitedModal from "@/modules/project/components/ShowInvitedModal.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import { getUserSubSkillList } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import Loader from "@/core/components/common/Loader.vue";
import AddPreferredContractorModal from "@/modules/preferred-contractors/components/AddPreferredContractorModal.vue";

export default {
  components: {
    ConfirmContractorInvitationModal,
    Loader,
    AddPreferredContractorModal,
    ShowInvitedModal,
    EmergencyChip,
    StandardChip,
    RoutineChip,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const isShowAddPreferredContractorModal = ref(false);
    const isProjectLiveClicked = ref(false);
    const router = useRouter();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userPreferredContractor = ref([]) as any;

    const isInviteOnlyProject = ref(false);

    const projectDetails = ref(null) as any;

    const iShowConfirmationModal = ref(false);

    const selectedContractor = ref(null) as any;

    const isShowInviteModal = ref(false);

    const isShowPostLiveLoading = ref(false);

    const isLoading = ref(false);

    const jobTypes = ref([
      {
        text: "Emergency",
        value: ProjectTypesEnum.EMERGENCY,
      },
      {
        text: "Routine",
        value: ProjectTypesEnum.ROUTINE,
      },
      {
        text: "Standard",
        value: ProjectTypesEnum.STANDARD,
      },
    ]);

    const contractorType = (type: string) =>
      type === BusinessDetailTypeEnum.SOLETRADER
        ? "Sole Trader"
        : "Limited Company";

    const onPostLiveProjectClick = async () => {
      try {
        isShowPostLiveLoading.value = true;
        await projectService.updateProjectStatus(projectDetails.value?.id, {
          projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
          inviteOnlyProject: isInviteOnlyProject.value,
        });
        isShowInviteModal.value = true;
      } catch (error) {
        console.log("error :", error);
      } finally {
        isShowPostLiveLoading.value = false;
      }
    };

    const projectJobType = (jobType: any) => {
      const selectJob = jobTypes.value.find((type: any) => {
        return type.value === jobType;
      });

      return selectJob?.text;
    };

    const onClickInviteEstimate = async (
      userId: number,
      workStationId: number
    ) => {
      iShowConfirmationModal.value = true;
      selectedContractor.value = {
        project: projectDetails.value?.id,
        user: userId,
        userWorkstation: workStationId,
      };
    };

    const closeInviteButtonModal = async () => {
      try {
        isLoading.value = true;
        iShowConfirmationModal.value = false;
        selectedContractor.value = null;
        const projectRefId: any = router?.currentRoute.value?.params?.refId;
        projectDetails.value = await projectService.getProjectData(
          projectRefId
        );

        isInviteOnlyProject.value = projectDetails.value?.inviteOnlyProject;
      } catch (error) {
        console.log("error :", error);
      } finally {
        isLoading.value = false;
      }
    };

    const isInviteDisable = (userId: number, workStationId: number) => {
      if (projectDetails.value?.inviteOnlyData.length) {
        const match = projectDetails.value?.inviteOnlyData?.find(
          (inviteMember: any) => {
            return (
              inviteMember.userId === userId &&
              inviteMember.workstationId === workStationId
            );
          }
        );
        return !match;
      } else {
        return false;
      }
    };

    const isInviteButtonDisable = (userId: number, workStationId: number) => {
      if (!isInviteOnlyProject.value) {
        return true;
      } else if (projectDetails.value?.inviteOnlyData.length) {
        const match = projectDetails.value?.inviteOnlyData?.find(
          (inviteMember: any) => {
            return (
              inviteMember.userId === userId &&
              inviteMember.workstationId === workStationId
            );
          }
        );
        return !match;
      } else {
        return false;
      }
    };
    const openContractorInvitationModal = () => {
      isShowAddPreferredContractorModal.value = true;
    };

    const onCloseInviteModal = () => {
      isShowInviteModal.value = false;
      isProjectLiveClicked.value = true;
    };
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const getAllSpecialism = computed(
      () => store.getters[`${USER_STORE}/getAllSpecialism`]
    );

    const getUserSkillList = (userSkill: any) => {
      return getUserSubSkillList(userSkill, getAllSpecialism.value);
    };

    const onClickCloseModel = () => {
      isShowAddPreferredContractorModal.value = false;
    };
    onBeforeMount(async () => {
      try {
        if (!getAllSpecialism.value.length) {
          await store.dispatch(
            `${USER_STORE}/setAllSpecialismsCategory`,
            activeUserWorkstation.value.id
          );
        }

        isLoading.value = true;
        const projectRefId: any = router?.currentRoute.value?.params?.refId;
        projectDetails.value = await projectService.getProjectData(
          projectRefId
        );

        isInviteOnlyProject.value = projectDetails.value?.inviteOnlyProject;

        let params: any = {
          status: "accepted",
        };
        userPreferredContractor.value = await store.dispatch(
          `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
          {
            userId: user.value?.id,
            params: params,
          }
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading.value = false;
      }
    });

    return {
      userPreferredContractor,
      contractorType,
      onPostLiveProjectClick,
      isInviteOnlyProject,
      projectDetails,
      jobTypes,
      projectJobType,
      onClickInviteEstimate,
      iShowConfirmationModal,
      selectedContractor,
      closeInviteButtonModal,
      isInviteDisable,
      isInviteButtonDisable,
      isLoading,
      isShowInviteModal,
      onCloseInviteModal,
      isProjectLiveClicked,
      isShowPostLiveLoading,
      ProjectTypesEnum,
      getUserSkillList,
      openContractorInvitationModal,
      isShowAddPreferredContractorModal,
      onClickCloseModel,
      ProjectJobStatusEnum,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/inviteContractor.scss";
</style>
