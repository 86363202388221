<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['add-new-safety-modal']"
    width="520px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>{{ modalTitle }}</h4>
          <v-btn
            variant="plain"
            icon="mdi-close"
            :disabled="loading"
            @click="closeModal"
          ></v-btn>
        </div>
        <div class="modal_form">
          <v-form :rules="validation" ref="eventFormRef">
            <div class="form_wrapper">
              <div class="list_block__item">
                <div class="event_field full-width">
                  <label>Safety Check Name</label>
                  <v-text-field
                    :disabled="
                      props.modalTitle !==
                      AddSafetyModalTitleEnum.CREATE_NEW_SAFETY
                    "
                    class="input_field"
                    placeholder="Enter event name"
                    :rules="validation.name"
                    variant="outlined"
                    v-model="safetyForm.name"
                  ></v-text-field>
                </div>
                <div
                  v-if="modalTitle === AddSafetyModalTitleEnum.GAS_TITLE"
                  class="event_field full-width"
                >
                  <label>Heating System Type</label>
                  <v-select
                    :items="heatingSystemTypeList"
                    :placeholder="HeatingSystemTypeEnum.AIR_SOURCE_HEAT_PUMP"
                    variant="outlined"
                    v-model="safetyForm.heatingSystemType"
                    item-title="name"
                    item-name="name"
                    class="input_field"
                    :menu-props="{
                      contentClass:
                        'layer_selector safety-modal-selector radio_selector',
                    }"
                    required
                    :rules="validation.heatingType"
                  ></v-select>
                </div>

                <div class="event_field full-width event_field__group">
                  <div class="full-width">
                    <label>Servicing Frequency </label>
                  </div>

                  <v-text-field
                    class="input_field"
                    :rules="validation.frequencyNo"
                    placeholder="12"
                    variant="outlined"
                    type="number"
                    :min="0"
                    v-model="safetyForm.serviceFrequency.frequency"
                  ></v-text-field>

                  <v-select
                    :items="['day', 'week', 'month', 'year']"
                    placeholder="Months"
                    variant="outlined"
                    :menu-props="{
                      contentClass: 'layer_selector safety-modal-selector',
                    }"
                    required
                    v-model="safetyForm.serviceFrequency.interval"
                    :rules="validation.frequencyValue"
                  ></v-select>
                </div>
              </div>

              <div class="list_block__item">
                <div class="list_block__item_title full-width">
                  <p>Self-Declaration/ Compliance Update</p>
                </div>

                <div class="event_field">
                  <label>Service Date</label>
                  <v-text-field
                    class="input_field"
                    type="date"
                    variant="outlined"
                    v-model="safetyForm.serviceDate"
                    :rules="validation.serviceDate"
                  ></v-text-field>
                </div>

                <div class="event_field">
                  <label>Provider</label>
                  <v-text-field
                    class="input_field"
                    placeholder="Enter Name"
                    variant="outlined"
                    v-model="safetyForm.provider"
                    :rules="validation.provider"
                  ></v-text-field>
                </div>
                <div class="event_field">
                  <label>Expiry Date </label>
                  <v-text-field
                    class="input_field"
                    type="date"
                    variant="outlined"
                    v-model="safetyForm.expiryDate"
                    :rules="validation.expiryDate"
                  ></v-text-field>
                </div>

                <div class="event_field">
                  <label>Result</label>
                  <v-select
                    :items="['Pass', 'Fail']"
                    placeholder="Pass"
                    variant="outlined"
                    :menu-props="{
                      contentClass: 'layer_selector safety-modal-selector',
                    }"
                    required
                    :rules="[(v) => !!v || 'Result is required']"
                    v-model="safetyForm.result"
                  ></v-select>
                </div>
                <div class="event_field full-width">
                  <label>Evidence</label>
                  <div class="tw-w-full">
                    <UploadForm
                      class="!tw-w-full"
                      listType="picture"
                      :record="form"
                      :allowMultiple="false"
                      :attachmentSources="[]"
                      :useCustomUploadIcon="false"
                      :useDragTrigger="true"
                    />
                  </div>

                  <!-- <div
                    class="uploaded-file tw-grid tw-grid-cols-[20px_1fr_20px] tw-gap-[12px] tw-items-center tw-text-[12px] tw-text-[#0C0F4A] tw-mt-[12px]"
                  >
                    <img src="@/assets/icons/uploaded-image.svg" alt="Image" />
                    <p>gas&heating_lgsr.JPG</p>
                    <img
                      class="tw-cursor-pointer tw-ml-auto tw-mr-auto"
                      src="@/assets/icons/cancel.svg"
                      alt="Image"
                    />
                  </div> -->
                </div>
              </div>
            </div>
            <div class="modal_form_footer">
              <v-btn
                class="button button-orange"
                block
                :loading="loading"
                :disabled="!isButtonDisabled"
                @click="onSubmitSafetyForm"
                >Submit</v-btn
              >
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import moment from "moment";
import { ref, defineProps, reactive, computed } from "vue";
import { useStore } from "vuex";
import UploadForm from "@/core/components/forms/Upload.vue";
import {
  HeatingSystemTypeEnum,
  AddSafetyModalTitleEnum,
} from "@/core/enums/PropertiesEnum";

const props = defineProps({
  modalTitle: {
    type: String,
    default: AddSafetyModalTitleEnum.CREATE_NEW_SAFETY,
  },

  modalType: {
    type: String,
    default: "create",
  },
});
const heatingSystemTypeList = computed(() =>
  Object.values(HeatingSystemTypeEnum)
);
const store = useStore();
const loading = ref(false);
const form = reactive({
  attachments: [],
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});
const safetyForm = reactive({
  name:
    props.modalTitle === AddSafetyModalTitleEnum.CREATE_NEW_SAFETY
      ? null
      : props.modalTitle,
  serviceFrequency: { interval: null, frequency: null },
  heatingSystemType: null,
  provider: null,
  result: null,
  serviceDate: null,
  expiryDate: null,
  attachments: form.attachments,
});

const emits = defineEmits(["closeModal", "action"]);
const eventFormRef = ref(null);
const validation = ref({
  name: [
    (v) => !!v || "Name is required.",
    (v) => v.length <= 255 || "Name is too long",
  ],
  frequencyNo: [
    (v) => !!v || "frequency number is required.",
    (v) => v > 0 || "frequency Number must be greater then 0.",
  ],
  frequencyValue: [(v) => !!v || "frequency value is required."],
  heatingType: [(v) => !!v || "heating system type is required."],
  startDate: [(v) => !!v || "startDate is required."],
  invitePeople: [(v) => v.length || "please selected invite people."],
  serviceDate: [(v) => !!v || "Service date is required"],
  expiryDate: [(v) => !!v || "Expiry date is required"],
  provider: [
    (v) => !!v || "Provider is required",
    (v) => v.length <= 255 || "Provider Name is too long",
  ],
});

const isButtonDisabled = computed(() => {
  return (
    safetyForm.name &&
    safetyForm.name.length <= 255 &&
    safetyForm.serviceFrequency.interval &&
    safetyForm.serviceFrequency.frequency &&
    safetyForm.expiryDate &&
    safetyForm.serviceDate &&
    safetyForm.provider &&
    safetyForm.provider.length <= 255 &&
    safetyForm.result &&
    safetyForm.attachments.length > 0 &&
    (props.modalTitle === AddSafetyModalTitleEnum.GAS_TITLE
      ? safetyForm.heatingSystemType
      : true)
  );
});

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const closeModal = () => emits("closeModal");

const onSubmitSafetyForm = async () => {
  loading.value = true;
  safetyForm.serviceFrequency.frequency = parseInt(
    safetyForm.serviceFrequency.frequency
  );
  try {
    await store.dispatch(`${PROPERTY_STORE}/createPropertySafetyCheck`, {
      data: safetyForm,
      propertyId: propertyDetails.value.id,
    });
    await store.dispatch(
      `${PROPERTY_STORE}/getPropertySafetyCheck`,
      propertyDetails.value.id
    );
    emits("closeModal");
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};
</script>
<style scoped lang="scss"></style>
