import WorkstationSettingService from "@/core/services/workstation-setting.service";

import { Commit } from "vuex";

export default {
  setUserSubscriptionDetails({ commit }: { commit: Commit }, value: any) {
    commit("setUserSubscriptionDetails", value);
  },
  async getWorkstationsSettingDetails({ commit }: any, payload: any) {
    const { userId, workstationId } = payload;
    const response =
      await WorkstationSettingService.getWorkstationsSettingDetails(
        userId,
        workstationId
      );
    commit("setUserSubscriptionDetails", response);
    return response;
  },
  getInvoiceWorkstationsDetails({ commit }: any, payload: any) {
    const { userId, userWorkstationId } = payload;
    const response = WorkstationSettingService.getInvoiceWorkstationsDetails(
      userId,
      userWorkstationId
    );
    return response;
  },
  updateSubscriptionsPlan({ commit }: any, payload: any) {
    const { userId, userWorkstationId, planType } = payload;
    const response = WorkstationSettingService.updateSubscriptionsPlan(
      userId,
      userWorkstationId,
      planType
    );
    return response;
  },

  updateStorageSubscriptionPlan({ commit }: any, payload: any) {
    const { userId, userWorkstationId, planType } = payload;
    const response = WorkstationSettingService.updateStorageSubscriptionPlan(
      userId,
      userWorkstationId,
      planType
    );
    return response;
  },

  downloadSubscriptionInvoice({ commit }: any, payload: any) {
    const { userId, workstationId, invoiceItem } = payload;
    const response = WorkstationSettingService.downloadSubscriptionInvoice(
      userId,
      workstationId,
      invoiceItem
    );
    return response;
  },

  getCredits({ commit }: any, payload: any) {
    const { userId, workstationId } = payload;
    const response = WorkstationSettingService.getCredits(
      userId,
      workstationId
    );
    return response;
  },

  getAtmCards({ commit }: any, userId: number) {
    const response = WorkstationSettingService.getAtmCards(userId);
    return response;
  },

  getAtmCard({ commit }: any, payload: any) {
    const { userId, id } = payload;
    const response = WorkstationSettingService.getAtmCard(userId, id);
    return response;
  },

  addAtmCard({ commit }: any, payload: any) {
    const { userId, formData } = payload;
    const response = WorkstationSettingService.addAtmCard(userId, formData);
    return response;
  },

  editAtmCard({ commit }: any, payload: any) {
    const { userId, cardId, formData } = payload;
    const response = WorkstationSettingService.editAtmCard(
      userId,
      cardId,
      formData
    );
    return response;
  },

  deleteAtmCard({ commit }: any, payload: any) {
    const { userId, id } = payload;
    const response = WorkstationSettingService.deleteAtmCard(userId, id);
    return response;
  },
  async getBillingHistoryDetails({ commit }: any, payload: any) {
    const { userId } = payload;
    const response = await WorkstationSettingService.getBillingHistoryDetails(
      userId
    );
    return response;
  },
  getInvoiceBillingDetails({ commit }: any, payload: any) {
    const { userId, id } = payload;
    const response = WorkstationSettingService.getInvoiceBillingDetails(
      userId,
      id
    );
    return response;
  },
};
