<template>
  <div>
    <SoleTraderDashboard
      v-if="user?.role?.name == UserRolesEnum.TRADESPERSON"
    />
    <PropertyOwnerDashboard
      v-if="user?.role?.name == UserRolesEnum.PROPERTY_OWNER"
    />
    <!-- <HomeOwnerDashboard v-if="user?.role?.name == 'Home Owner'" />
    <OccupierDashboard v-if="user?.role?.name == 'Occupier'" /> -->
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { computed } from "vue";
import SoleTraderDashboard from "@/modules/dashboard/components/SoleTraderDashboard.vue";
import PropertyOwnerDashboard from "@/modules/dashboard/components/PropertyOwnerDashboard.vue";
// import HomeOwnerDashboard from "@/modules/dashboard/components/HomeOwnerDashboard.vue";
// import OccupierDashboard from "@/modules/dashboard/components/OccupierDashboard.vue";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";

export default {
  components: {
    SoleTraderDashboard,
    PropertyOwnerDashboard,
    // HomeOwnerDashboard,
    // OccupierDashboard,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    return {
      user,
      UserRolesEnum,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/SoleTraderDashboard.scss";
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/courses/styles/courses.scss";
</style>
