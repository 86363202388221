<template>
  <ChipState label="Routine" textColor="#FFF" bgColor="#4A92E5">
    <template #prefix>
      <RoutineCircleWhiteIcon />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import RoutineCircleWhiteIcon from "@/core/components/icons/RoutineCircleWhiteIcon";
</script>
<script>
export default {
  name: "RoutineChip",
};
</script>
