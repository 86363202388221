export default {
  getTradeEstimate: (state: any) => state.tradeEstimate,
  getTradeEstimateDetails: (state: any) => state.tradeEstimateDetail,
  getTradeEstimateDataWithEscrowStatus: (state: any) =>
    state.tradeEstimateWithEscrowStatus,
  getProjectEstimateStatus: (state: any) => state.projectEstimateStatus,
  getContractorTradePassportWorkstationId: (state: any) =>
    state.contractorTradePassportWorkstationId,
  getContractorTradePassportDetail: (state: any) =>
    state.contractorTradePassportDetail,
  isOpenTradePassportasViewMode: (state: any) =>
    state.isOpenTradePassportasViewMode,
};
