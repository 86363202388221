<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.398438"
      y="0.759766"
      width="18"
      height="18"
      rx="2"
      fill="#ECF7EC"
    />
    <path
      d="M8.89844 13.2598C8.89844 13.5359 9.1223 13.7598 9.39844 13.7598C9.67458 13.7598 9.89844 13.5359 9.89844 13.2598L8.89844 13.2598ZM9.75199 5.90621C9.55673 5.71095 9.24015 5.71095 9.04488 5.90621L5.8629 9.08819C5.66764 9.28345 5.66764 9.60004 5.8629 9.7953C6.05817 9.99056 6.37475 9.99056 6.57001 9.7953L9.39844 6.96687L12.2269 9.7953C12.4221 9.99056 12.7387 9.99056 12.934 9.7953C13.1292 9.60004 13.1292 9.28346 12.934 9.08819L9.75199 5.90621ZM9.89844 13.2598L9.89844 6.25977L8.89844 6.25977L8.89844 13.2598L9.89844 13.2598Z"
      fill="#40AE42"
    />
  </svg>
</template>
<script>
export default {
  name: "UpwardGreenIcon",
};
</script>
