<template>
  <el-form
    :model="formEntity"
    class="attachments-form"
    label-position="top"
    size="small"
    :inline="false"
    ref="attachmentsForm"
  >
    <div
      v-if="failedUpload"
      class="el-alert el-alert--error is-light margin-top-12"
    >
      <div class="el-alert__content">
        <span class="el-alert__title"
          >File type .{{ fileExtention }} not allowed.</span
        >
        <v-icon
          @click="onClickCloseError"
          class="el-icon el-alert__close-btn"
          :size="15"
          >mdi-close</v-icon
        >
      </div>
    </div>
    <el-alert
      class="margin-top-12"
      v-if="failedLargeUpload"
      title="File size is too large."
      type="error"
    >
    </el-alert>
    <el-form-item label="">
      <el-upload
        @click="onClickCloseError"
        :disabled="!allowMultiple && fileList.length === 1"
        class="iknowa-upload tw-w-full"
        :class="uploadContainerClass"
        :action="actionUrl"
        :headers="uploadHeaders"
        :list-type="listType"
        :on-error="handleErrorFiles"
        :on-success="updateFileOnSuccess"
        :on-progress="onProgressUpload"
        :before-upload="onBeforeUpload"
        :file-list="fileList"
        :show-file-list="showFileList"
        :on-remove="handleRemoveFile"
        :multiple="allowMultiple"
        :http-request="httpRequest"
        :limit="allowMultiple ? '' : 1"
        :accept="accept"
        drag
      >
        <template v-if="listType && !useCustomUploadIcon && !useDragTrigger">
          <v-icon icon="mdi-plus"></v-icon>
        </template>
        <div
          class="upload-icon-wrapper tw-flex tw-items-center tw-gap-2"
          v-if="useCustomUploadIcon"
        >
          <UploadIcon />
          <Text variant="p" textWeight="400">Upload Attachment</Text>
        </div>
        <div
          class="upload-icon-wrapper flex-row ai-c cursor-pointer"
          v-if="useClipUploadIcon"
        >
          <ClipIcon />
          <p class="default-text">Add attachment</p>
        </div>
        <div class="upload-icon-wrapper" v-if="userEmptyAvatarIcon">
          <UploadIcon />
        </div>
        <div
          v-if="!useCustomUploadIcon && useDragTrigger"
          class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-gap-2"
        >
          <img src="@/assets/images/upload-icon-drag.png" />
          <Text
            variant="span"
            textWeight="400"
            textColor="rgba(128, 130, 159, 1)"
            >Drag and drop documents, or
            <Text variant="span">Browse</Text></Text
          >
        </div>
        <template v-if="listType" v-slot:file="{ file }">
          <div class="thumbnail-wrapper" v-if="fileIsImage(file)">
            <ImageDynamicLayout
              :isLoading="file.isLoading"
              :isDeleting="file.isDeleting"
              :file="file"
              :uploadDone="isFileUpload"
              :currentNumber="fileUploadPercentage"
              style="position: relative"
            >
              <el-image
                fit="cover"
                class="el-upload-list__item-thumbnail"
                :src="file.src"
              >
                <template #placeholder>
                  <div
                    class="image-slot tw-flex tw-items-center tw-justify-center"
                  >
                    <v-progress-circular
                      class="!tw-w-[30px] !tw-h-[30px]"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
                <template #error>
                  <div class="image-slot">
                    <v-icon icon="mdi-image-remove"></v-icon>
                  </div>
                </template>
              </el-image>
              <span
                class="el-upload-list__item-actions"
                v-if="!file.isDeleting && listType !== PICTURE"
              >
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemoveFile(file)"
                >
                  <TrashIcon />
                </span>
              </span>
              <span
                class="el-upload-list__item-actions--picture"
                v-if="!file.isDeleting && listType === PICTURE"
              >
                <v-icon
                  @click="handleRemoveFile(file)"
                  icon="mdi-close-circle"
                  size="small"
                ></v-icon>
              </span>
            </ImageDynamicLayout>
          </div>
          <div v-else-if="!fileIsImage(file)">
            <ImageDynamicLayout
              :isLoading="file.isLoading"
              :isDeleting="file.isDeleting"
              :file="file"
              :uploadDone="isFileUpload"
              :currentNumber="fileUploadPercentage"
              style="position: relative"
            >
              <span class="el-upload-list__item-thumbnail tw-min-h-[60px]">
                <v-icon
                  icon="mdi-file-video"
                  color="#264FD5"
                  v-if="file.raw.type === 'video/mp4'"
                ></v-icon>
                <PdfIcon v-else-if="file.raw.type === 'application/pdf'" />
                <DocumentIcon v-else />
                <span
                  class="el-upload-list__item-actions--picture"
                  v-if="!file.isDeleting && listType === PICTURE"
                >
                  <v-icon
                    @click="handleRemoveFile(file)"
                    icon="mdi-close-circle"
                    size="small"
                  ></v-icon>
                </span>
              </span>
              <span
                class="el-upload-list__item-actions"
                v-if="!file.isDeleting"
              >
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemoveFile(file)"
                >
                  <v-icon icon="mdi-delete-empty" size="small"></v-icon>
                </span>
              </span>
            </ImageDynamicLayout>
          </div>
        </template>
      </el-upload>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
import UploadIcon from "@/core/components/icons/UploadIcon.vue";
import ClipIcon from "@/core/components/icons/ClipIcon.vue";
import DocumentIcon from "@/core/components/icons/DocumentIcon.vue";
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import ImageDynamicLayout from "@/core/components/forms/ImageDynamicLayout.vue";
import { AUTH_TOKEN } from "@/core/constants";
import {
  DocumentFileTypes,
  ImageFileTypes,
  GalleryDocumentFileTypes,
  GalleryImageFileTypes,
  ArchiveFileTypes,
  AudioFileTypes,
  TextFileTypes,
  HEICFileTypes,
  HEVCFileTypes,
  VideoFileTypes,
} from "@/core/helpers/file.helper";
import { getLocalStorageWithExpiry } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import axios from "axios";
const PICTURE = "picture";

export default defineComponent({
  name: "upload-form",

  emits: [
    "on-progress-upload",
    "on-upload-success",
    "custom-fn-remove-file",
    "on-handle-error",
  ],

  props: {
    uploadContainerClass: {
      type: String,
      default: "",
      required: false,
    },
    useCustomUploadIcon: {
      type: Boolean,
      default: true,
      required: false,
    },
    useClipUploadIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    userEmptyAvatarIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    listType: {
      type: String,
      default: "",
      required: false,
    },
    record: {
      type: Object,
      required: true,
    },
    attachmentSources: {
      type: Array,
      required: true,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
      required: true,
    },
    showFileList: {
      type: Boolean,
      default: true,
      required: false,
    },
    uploadUrl: {
      type: String,
      default: "",
      required: false,
    },
    hasCustomFnRemoveFile: {
      type: Boolean,
      default: false,
      required: false,
    },
    forDocuments: {
      type: Boolean,
      default: false,
      required: false,
    },
    isGallery: Boolean,
    useDragTrigger: Boolean,
    accept: {
      type: String,
      default: "",
    },
  },

  components: {
    ImageDynamicLayout,
    UploadIcon,
    ClipIcon,
    DocumentIcon,
    TrashIcon,
    Text,
    PdfIcon,
  },

  data: () => {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      failedUpload: false as boolean,
      failedLargeUpload: false as boolean,

      imageFileTypes: ImageFileTypes as any[],
      documentFileTypes: DocumentFileTypes as any[],
      galleryImageFileTypes: GalleryImageFileTypes as any[],
      galleryDocumentFileTypes: GalleryDocumentFileTypes as any[],
      archiveFileTypes: ArchiveFileTypes as any[],
      audioFileTypes: AudioFileTypes as any[],
      textFileTypes: TextFileTypes as any[],
      heicFileTypes: HEICFileTypes as any[],
      hevcFileTypes: HEVCFileTypes as any[],
      videoFileTypes: VideoFileTypes as any[],

      fileList: [] as any[],

      enableMultiple: false,

      isFileUpload: false,

      fileUploadPercentage: 0,

      actionUrl: "",
      fileExtention: "",
      PICTURE,
    };
  },

  created() {
    const component = this as any;
    const { formEntity, attachmentSources } = component;

    this.actionUrl = this.uploadUrl || `${this.apiUrl}upload`;

    if (attachmentSources && attachmentSources.length) {
      component.fileList = attachmentSources.map((attachment: any) => {
        const { originalName } = attachment;

        return {
          ...attachment,
          name: originalName,
          isLoading: false,
          isDeleting: false,
        };
      });
      formEntity.attachments = component.fileList;
    }

    if (!formEntity.attachments) {
      formEntity.attachments = [];
    }
  },

  computed: {
    formEntity(): any {
      return (this as any).record;
    },
    uploadHeaders() {
      const token = getLocalStorageWithExpiry(AUTH_TOKEN);

      return {
        Authorization: `Bearer ${token}`,
      };
    },
  },

  methods: {
    getFileType(filename: string) {
      let fileType = "";

      if (filename && typeof filename === "string") {
        fileType = filename.split(".").pop()?.toLowerCase() || "";
      }

      return fileType?.toLocaleLowerCase();
    },

    onBeforeUpload(file: any) {
      const name = file.name || "";
      const fileExt = this.getFileType(name);
      const ext = name?.split(".")?.pop()?.toLowerCase();
      let allow = false;
      // this.failedUpload = false;
      this.failedLargeUpload = false;
      const documentsModuleTypes = this.imageFileTypes;

      let imageTypes = (this as any).imageFileTypes;
      let fileTypes = (this as any).documentFileTypes;
      const archiveTypes = (this as any).archiveFileTypes;
      const audioFileTypes = (this as any).audioFileTypes;
      const textFileTypes = (this as any).textFileTypes;
      const heicFileTypes = (this as any).heicFileTypes;
      const hevcFileTypes = (this as any).hevcFileTypes;
      const videoFileTypes = (this as any).videoFileTypes;

      // Handle actionUrl based on file sizes only for user documents
      if (Math.ceil(file.size / 1024 / 1024) > 30 && this.forDocuments) {
        this.actionUrl = `${this.apiUrl}upload/signed-url`;
      } else {
        this.actionUrl = this.uploadUrl || `${this.apiUrl}upload`;
      }
      // const maxSizeInMB = 10;
      // const fileSizeInMB = file.size / 1024 / 1024;
      // if (fileSizeInMB > maxSizeInMB) {
      //   this.failedLargeUpload = true;
      //   return false;
      // }

      if ((this as any).isGallery) {
        imageTypes = (this as any).galleryImageFileTypes;
        fileTypes = (this as any).galleryDocumentFileTypes;
        // (this as any).failedUpload = false;

        if (imageTypes.includes(ext) || fileTypes.includes(ext)) {
          allow = true;
        }

        if (!allow) {
          this.fileExtention = fileExt;
          (this as any).failedUpload = true;
        }
      }

      if (documentsModuleTypes.includes(fileExt)) {
        allow = true;
      } else if (fileTypes.includes(fileExt)) {
        allow = true;
      } else if (archiveTypes.includes(fileExt)) {
        allow = true;
      } else if (audioFileTypes.includes(fileExt)) {
        allow = true;
      } else if (textFileTypes.includes(fileExt)) {
        allow = true;
      } else if (heicFileTypes.includes(fileExt)) {
        allow = true;
      } else if (hevcFileTypes.includes(fileExt)) {
        allow = true;
      } else if (videoFileTypes.includes(fileExt)) {
        allow = true;
      } else {
        this.fileExtention = fileExt;
        this.failedUpload = true;
      }

      if (!allow) {
        this.handleRemoveFile(file);
      }

      return allow;
    },

    fileIsImage(file: any) {
      let { attachment: filename } = file;
      const { name } = file;
      const imageTypes = (this as any).imageFileTypes;

      if (!filename) {
        filename = name;
      }
      if (!filename) return false;

      const extension = filename.split(".")[filename.split(".").length - 1];

      return imageTypes.includes(extension.toLowerCase());
    },
    async handleRemoveFile(file: any) {
      const component = this as any;
      const currentFile = file;
      const { response } = file;
      const { formEntity, fileList } = component;
      const { attachments } = formEntity;
      const attachment = response?.attachment;

      currentFile.isDeleting = true;

      // Remove the specific file from the file list
      const newList = fileList.filter(
        (item: any) => item.uid !== currentFile.uid
      );
      component.fileList = newList;

      // Remove from attachments based on whether the response has an attachment
      if (attachment) {
        formEntity.attachments = attachments.filter(
          (a: any) => a.attachment !== attachment
        );
      } else {
        formEntity.attachments = attachments.filter(
          (a: any) => a.attachment !== currentFile.attachment
        );
      }

      if (this.hasCustomFnRemoveFile) {
        this.$emit("custom-fn-remove-file", file);
      }
    },

    // async handleRemoveFile(file: any) {
    //   const component = this as any;
    //   const currentFile = file;
    //   const { response } = file;
    //   const { formEntity, fileList } = component;
    //   const { attachments } = formEntity;
    //   const attachment = response?.attachment;
    //   let newList = [] as any;

    //   currentFile.isDeleting = true;

    //   if (!attachment) {
    //     newList = fileList.filter(
    //       (item: any) => item.attachment !== file.attachment
    //     );
    //     component.fileList = newList;
    //     component.formEntity.attachments = attachments.filter(
    //       (a: any) => a.attachment !== file.attachment
    //     );
    //   }

    //   if (attachment) {
    //     newList = fileList.filter((item: any) => item.uid !== file.uid);
    //     component.fileList = newList;
    //     component.formEntity.attachments = attachments.filter(
    //       (a: any) => a.attachment !== attachment
    //     );
    //   }

    //   if (this.hasCustomFnRemoveFile) {
    //     this.$emit("custom-fn-remove-file", file);
    //   }
    // },
    async updateFileOnSuccess(event: any, file: any, fileList: any) {
      const { formEntity } = this as any;
      const currentFile = file;
      const { response } = currentFile;
      (this as any).fileList = fileList;
      if (Math.ceil(file.size / 1024 / 1024) > 30 && this.forDocuments) {
        formEntity.attachments.push({
          attachment: response.fileName,
          url: response.url,
          mime: file.raw.type,
          originalName: file.name,
          size: file.size,
          raw: file.raw,
        });
      } else {
        currentFile.src = response.src;
        delete response.src;
        formEntity.attachments.push(response);
      }
      currentFile.isLoading = false;
      this.$emit("on-upload-success");
    },

    onProgressUpload(event: any, file: { isLoading: boolean }) {
      // eslint-disable-next-line no-param-reassign
      file.isLoading = true;

      this.$emit("on-progress-upload");
    },

    handleErrorFiles(err: any) {
      (this as any).$notify.error({
        title: "Upload Error",
        message: err || "Uploading files error.",
      });
      this.$emit("on-handle-error");
    },

    async httpRequest(options: any) {
      // const component = this as any;
      // const { formEntity } = component;
      // console.log(formEntity.attachments[0]);
      const pathName = new URL(options.action).pathname.slice(1);
      if (pathName === "upload/signed-url" && this.forDocuments) {
        try {
          const response: any = await $axios.post(pathName, {
            contentType: options.file.type,
            filename: options.file.name,
          });
          const fileResponce = await axios.put(response.url, options.file, {
            headers: {
              "Content-Type": options.file.type,
            },

            onUploadProgress: (progressEvent: any) => {
              this.isFileUpload = false;
              this.fileUploadPercentage = Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            },
          });
          return response;
        } catch (error) {
          console.log("error", error);
        } finally {
          this.isFileUpload = true;
        }
      } else {
        try {
          this.isFileUpload = false;
          const form = new FormData();
          form.set("file", options.file);

          return await $axios.post(pathName, form, {
            headers: {
              hasContentType: "multipart/formdata",
            },
            onUploadProgress: (progressEvent: any) => {
              this.isFileUpload = false;
              this.fileUploadPercentage = Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            },
          });
        } catch (error) {
          console.log("error", error);
        } finally {
          this.isFileUpload = true;
        }
      }
    },
    onClickCloseError() {
      this.failedUpload = false;
      this.fileExtention = "";
    },
  },
});
</script>
<style lang="scss" scoped>
.upload-icon-wrapper {
  gap: 0.5rem;
  p {
    font-family: Mulish;
    font-size: 10px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: #4f55f0;
  }
}
.attachments-form {
  position: relative;

  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    font-size: 1.5em !important;
  }

  .thumbnail-wrapper {
    .el-image {
      width: 20%;
    }
  }

  :deep(.el-progress) {
    display: none !important;
  }

  :deep(.estimate-form) {
    .el-upload-list {
      position: relative;
      right: 150%;
      top: 20px;
    }
  }

  :deep(.estimate-form-material) {
    .el-upload-list {
      position: relative;
      top: 20px;
    }
  }

  :deep(.el-upload-list--picture) {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }
  :deep(.el-upload-list--picture .el-upload-list__item-thumbnail) {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  :deep(.el-upload-list--picture .el-upload-list__item-actions) {
    position: absolute;
    z-index: 999;
  }

  :deep(.el-upload-list--picture .el-upload-list__item-actions--picture) {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  :deep(.el-upload-list--picture .el-upload-list__item) {
    padding: 0;
    margin-top: 0;
  }
  :deep(.el-upload-list__item div) {
    width: 100%;
    height: 100%;
  }
}
</style>
