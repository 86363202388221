<template>
  <div
    class="rating-item tw-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded-[4px] tw-h-[36px] tw-px-4 tw-box-border"
  >
    <div class="tw-flex tw-items-center tw-gap-3">
      <Text variant="span" class="rmd:!tw-text-[14px]" :textColor="textColor">
        {{ `(${score})` }}
      </Text>
      <Text
        variant="xsmall"
        class="rmd:!tw-text-[12px]"
        :textColor="textColor"
        v-if="isIllegalToRent"
      >
        Can’t legally be rented out!
      </Text>
    </div>
    <Text variant="span" class="rmd:!tw-text-[14px]" :textColor="textColor">{{
      rating
    }}</Text>
  </div>
</template>

<script setup>
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  bgColor: {
    type: String,
    default: "#007F3D",
  },
  textColor: {
    type: String,
    default: "#FFF",
  },
  score: String,
  rating: String,
  isIllegalToRent: Boolean,
  width: {
    type: String,
    default: "100%",
  },
  widthSubtract: {
    type: String,
    default: "0%",
  },
});
</script>

<style lang="scss" scoped>
.rating-item {
  background: v-bind("props.bgColor");
  box-shadow: 0px 0px 20px 0px #0c0f4a1a;
  width: v-bind("props.width");
}

@media (max-width: 767px) {
  .rating-item {
    width: calc(100% - v-bind("props.widthSubtract"));
  }
}
</style>
