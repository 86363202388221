/**
 * Login route page
 */
import LoginRoute from "@/modules/login/pages/LoginPage.vue";

export const LOGIN_ROUTE = "login";

export default {
  path: "/",
  name: LOGIN_ROUTE,
  component: LoginRoute,
  meta: {
    layout: "AuthLayout",
  },
};
