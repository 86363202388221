<template>
  <svg
    width="1680"
    height="638"
    viewBox="0 0 1680 638"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1682.44 520.195C1682.44 520.195 1505.33 429.41 1161.86 517.772C818.391 606.134 745.926 667.489 456.142 623.308C166.359 579.127 1.45849e-05 471.168 1.45849e-05 471.168L5.57757e-05 -2.82969e-05L1720 0.00012207L1682.44 520.266L1682.44 520.195Z"
      fill="url(#paint0_linear_5708_65348)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5708_65348"
        x1="1720"
        y1="638"
        x2="592.99"
        y2="-558.535"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5FAFF" />
        <stop offset="1" stop-color="#C8E4FF" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "OnboardingWave",
};
</script>
