<template>
  <GeneralDialog class="tw-max-w-[450px]" @on-close="onCloseModal">
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <LockPageIcon />
          <Text
            variant="h4"
            textAlign="center"
            lineHeight="28px"
            textWeight="600"
          >
            Access Denied
          </Text>
          <Text
            class="tw-max-w-[90%] tw-mt-4"
            variant="h6"
            textAlign="center"
            textColor="rgba(12, 15, 74, 1)"
            textWeight="400"
          >
            You don't have any properties with Occupier so you can't create a
            issue
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-mt-5">
        <Button variant="accent" label="CLOSE" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import LockPageIcon from "@/core/components/icons/LockPageIcon.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close", "on-create-workstation"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onCloseModal = () => {
  emits("on-close");
};
</script>
