<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#FFA500" />
    <path
      d="M8.88298 13.3244C9.3099 11.8045 10.2818 10.5043 11.6196 9.6634C12.8586 8.88461 14.2801 8.56197 15.7307 8.73032C16.7822 8.8523 17.7885 9.23769 18.6479 9.83736L17.9822 9.86032L18.0112 10.7036L20.2033 10.6282L20.1241 8.46318L19.2807 8.49406L19.3093 9.27439C18.298 8.52175 17.0918 8.03879 15.8279 7.89208C15.031 7.79966 14.2313 7.83988 13.4512 8.01186C12.6439 8.18988 11.8765 8.50516 11.1705 8.94889C9.65496 9.90142 8.55408 11.3744 8.07056 13.0963C7.59045 14.8057 7.75496 16.6214 8.53364 18.2089L9.29133 17.8372C8.60419 16.4365 8.45913 14.8337 8.88298 13.3244Z"
      fill="white"
    />
    <path
      d="M21.2681 11.5039L20.5338 11.9197C21.34 13.3436 21.564 15.004 21.1648 16.5951C20.7642 18.1912 19.7761 19.5526 18.3824 20.4286C17.0956 21.2374 15.5606 21.558 14.0605 21.3313C12.8933 21.1549 11.803 20.6581 10.9112 19.9076L11.6978 19.8805L11.6688 19.0371L9.47656 19.1126L9.5559 21.2776L10.3992 21.2466L10.3739 20.5584C11.383 21.4056 12.6156 21.9663 13.9345 22.1657C14.2906 22.2195 14.6485 22.2461 15.0052 22.2461C16.3508 22.2461 17.6791 21.8674 18.8315 21.143C20.4103 20.1507 21.5295 18.6085 21.9833 16.8005C22.4356 14.9983 22.1816 13.1172 21.2681 11.5039Z"
      fill="white"
    />
    <path
      d="M15.0345 13.5938C14.2896 13.5938 13.6836 14.1997 13.6836 14.9446C13.6836 15.6895 14.2896 16.2954 15.0345 16.2954C15.7793 16.2954 16.3852 15.6895 16.3852 14.9446C16.3852 14.1997 15.7793 13.5938 15.0345 13.5938Z"
      fill="white"
    />
    <path
      d="M19.4784 16.057V13.8394L18.6235 13.6734C18.5779 13.5441 18.5253 13.4172 18.4659 13.2934L18.954 12.5716L17.3876 11.0019L16.6648 11.4886C16.5388 11.4278 16.4095 11.3741 16.2777 11.3276L16.1117 10.4727H13.894L13.7281 11.3276C13.5988 11.3732 13.4718 11.4258 13.3481 11.4852L12.6262 10.9971L11.0566 12.5635L11.5433 13.2863C11.4825 13.4123 11.4288 13.5416 11.3823 13.6733L10.5273 13.8394V16.057L11.3823 16.223C11.4279 16.3523 11.4805 16.4791 11.5399 16.603L11.0517 17.3247L12.6182 18.8945L13.3411 18.4078C13.467 18.4686 13.5962 18.5223 13.7281 18.5688L13.894 19.4237H16.1117L16.2777 18.5688C16.407 18.5232 16.5339 18.4706 16.6578 18.4112L17.3795 18.8994L18.9493 17.3329L18.4626 16.6101C18.5233 16.4841 18.5771 16.3549 18.6236 16.223L19.4784 16.057ZM15.038 17.1429C13.8278 17.1429 12.8433 16.1584 12.8433 14.9481C12.8433 13.738 13.8278 12.7535 15.038 12.7535C16.2481 12.7535 17.2326 13.738 17.2326 14.9481C17.2326 16.1584 16.2481 17.1429 15.038 17.1429Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "InProgressIcon",
};
</script>
