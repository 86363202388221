<template>
  <GeneralWidgetLayoutSlot
    :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
    class="!tw-min-h-[470px]"
    title="Jobs Overview"
    buttonLabel="Manage Jobs"
    :borderBottom="true"
    @on-action-click="onClickGotoJob"
    :withBorder="true"
    :isRoundedxl="true"
  >
    <template #content>
      <main class="tw-w-full tw-h-full tw-flex tw-justify-between tw-flex-col">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-4 tw-box-border tw-cursor-pointer"
          :class="{ 'tw-border-b-solid-custom': !item?.noBorder }"
          v-for="(item, index) in items"
          :key="index"
          @click="onClickGotoMyJob(item)"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <component :is="item.icon" />
            <Text variant="p" textWeight="500">{{ item.title }}</Text>
          </div>
          <Text class="!tw-text-[20px]" textWeight="600">{{
            String(
              item.title === "Verify Jobs"
                ? verifyJobCount || 0
                : getJobCountBaseOnStatus(item.statusId)
            )
          }}</Text>
        </div>
      </main>
    </template>
  </GeneralWidgetLayoutSlot>
</template>
<script setup>
import { reactive, computed, onMounted } from "vue";

import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
// import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
// import EstimateSentIcon from "@/core/components/icons/EstimateSentIcon.vue";
// import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";

import JobNotStartedIcon from "@/core/components/icons/JobNotStartedIcon.vue";
import JobEstimateSentIcon from "@/core/components/icons/JobEstimateSentIcon.vue";
import JobsRejectedIcon from "@/core/components/icons/JobsRejectedIcon.vue";
import JobsCompletedIcon from "@/core/components/icons/JobsCompletedIcon.vue";

import { JOBS_STORE } from "@/store/modules/jobs";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import { JOBS_ROUTE, JOBS_ROUTE_WITH_TYPE } from "@/modules/jobs/routes";
import VerifyIcon from "@/core/components/icons/VerifyIcon.vue";
const props = defineProps({
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});
const inProgress = InProgressIcon;
const estimateSent = JobEstimateSentIcon;
const notStarted = JobNotStartedIcon;
const draftJobs = DraftJobsIcon;
const completed = JobsCompletedIcon;
const rejected = JobsRejectedIcon;

const store = useStore();
const router = useRouter();

const verifyJobCount = computed(() => {
  return store.getters[`${JOBS_STORE}/getHistoricalJobs`]?.length;
});
const items = reactive([
  {
    icon: inProgress,
    title: "In-progress",
    route: "in-progress",
    statusId: [ProjectJobStatusEnum.IN_PROGRESS],
    count: "0",
  },
  {
    icon: notStarted,
    title: "Not Started",
    route: "not-started",
    statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
    count: "0",
  },
  {
    icon: estimateSent,
    title: "Estimates sent",
    route: "estimates-sent",
    statusId: [
      ProjectJobStatusEnum.QUOTES_RECEIVED,
      ProjectJobStatusEnum.IN_PROGRESS,
    ],
    count: "0",
  },
  {
    icon: rejected,
    title: "Rejected",
    route: "rejected",
    statusId: [ProjectJobStatusEnum.CANCELLED],
    count: "0",
  },
  {
    icon: draftJobs,
    title: "Draft Jobs",
    route: "draft-jobs",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
    count: "0",
  },
  {
    icon: completed,
    title: "Completed Jobs",
    route: "completed-jobs",
    statusId: [ProjectJobStatusEnum.COMPLETED],
    count: "0",
  },
  {
    icon: VerifyIcon,
    title: "Verify Jobs",
    count: "0",
    noBorder: true,
  },
]);
const getJobStatusCountsList = computed(
  () => store.getters[`${JOBS_STORE}/getJobStatusCountsList`]
);
const getJobCountBaseOnStatus = (statusId) => {
  try {
    return statusId.reduce((count, id) => {
      count += getJobStatusCountsList.value[id] || 0;
      return count;
    }, 0);
  } catch (error) {
    return "0";
  }
};
const onClickShow = (item) => {
  if (item.title === "Completed Jobs") {
    return router.push(`/verify-jobs`);
  }
  return;
};
const onClickGotoJob = () => {
  router.push({ name: JOBS_ROUTE });
};
const onClickGotoMyJob = (item) => {
  if (item.title === "Verify Jobs") {
    return router.push("/verify-jobs");
  }
  router.push({ name: JOBS_ROUTE_WITH_TYPE, params: { type: item.route } });
};
onMounted(async () => {
  if (!Object.keys(getJobStatusCountsList.value)?.length) {
    await store.dispatch(`${JOBS_STORE}/setJobStatusCounts`);
    // console.log(getJobStatusCountsList.value, "getJobStatusCountsList");
  }
});
</script>
<style lang="scss" scoped></style>
