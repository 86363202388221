import ViewRatingPage from "@/modules/view-rating/pages/ViewRatingPage.vue";

export const VIEW_RATING_ROUTE = "view-rating";

export default {
  path: "/view-rating",
  name: VIEW_RATING_ROUTE,
  component: ViewRatingPage,

  meta: {
    requiresAuth: true,
    title: "View Rating",
  },
};
