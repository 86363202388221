import { CRYPTO_SECRET_KEY } from "../constants";
import CryptoJS from "crypto-js";

export const encryptModalData = (data: object) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_SECRET_KEY
  ).toString();
  window.localStorage.setItem("ws_modal", encryptedData);
  window.localStorage.setItem("ws_modalData", JSON.stringify(data));
};
export const decryptModalData = () => {
  try {
    const encryptedData = window.localStorage.getItem("ws_modal");

    const bytes =
      encryptedData && CryptoJS.AES.decrypt(encryptedData, CRYPTO_SECRET_KEY);
    const decryptData: any = bytes && bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptData);
  } catch (error) {
    window.localStorage.removeItem("ws_modal");
    return null;
  }
};
