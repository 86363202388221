<template>
  <v-dialog
    v-model="isShowLandlordsWorkStation"
    width="968px"
    class="custom__modal__box landlord__modal__box"
    height="100%"
    persistent
  >
    <v-btn
      variant="text"
      aria-label="Refresh"
      class="back_btn"
      prepend-icon="mdi-chevron-left"
      @click="onClickToggleTradesWorkStation"
      >back</v-btn
    >
    <main class="main">
      <header class="custom__modal__header">
        <img
          class="open-logo"
          src="../../../../assets/images/logo/iknowa-logo-v4.svg"
        />
        <h4>Company Setup</h4>
      </header>
      <div class="custom__modal__content">
        <div class="custom__modal__heading">
          <h3 class="title">
            Great stuff. What type of company do <br />
            you have, {{ user?.firstName }}?
          </h3>
          <p class="description">
            Before you can bid on new project, we need to understand how
            you&apos;re setup as a business.
          </p>
        </div>
        <div class="modal_card_content">
          <v-card @click="onClickSoleTrader()" class="modal_card">
            <div class="icon_btn">
              <v-btn
                color="#0c0f4a"
                size="large"
                variant="tonal"
                aria-label="Refresh"
                icon="mdi-home"
                class="icon_home"
              ></v-btn>
            </div>
            <div class="modal_content">
              <h4>Sole Trader</h4>
              <p>This can also include partnerships</p>
            </div>
          </v-card>
          <v-card @click="onClickLimitedCompany()" class="modal_card">
            <div class="icon_btn">
              <v-btn
                color="#0c0f4a"
                size="large"
                variant="tonal"
                aria-label="Refresh"
                icon="mdi-home"
                class="icon_home"
              ></v-btn>
            </div>
            <div class="modal_content">
              <h4>Limited Company</h4>
              <p>Registered on Companies House</p>
            </div>
          </v-card>
        </div>
      </div>
    </main>
  </v-dialog>
  <SoleTraderWorkStationComponent
    v-if="isShowSoleTraderWorkStation"
    @onClickCloseTab="onClickCloseSoloTrader"
  />
  <LimitedCompanyWorkStationComponent
    v-if="isShowLimitedCompanyWorkStation"
    @onClickCloseTab="onClickCloseLimitedCompany"
  />
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import SoleTraderWorkStationComponent from "@/modules/workstation/soletrader/components/SoleTraderWorkStationComponent.vue";
import LimitedCompanyWorkStationComponent from "@/modules/workstation/limitedcompany/components/LimitedCompanyWorkStationComponent.vue";
export default {
  components: {
    SoleTraderWorkStationComponent,
    LimitedCompanyWorkStationComponent,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const isShowLandlordsWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getLandlordsWorkStationState`]
    );
    const isShowSoleTraderWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getSoleTraderWorkStationState`]
    );
    const isShowLimitedCompanyWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getLimitedCompanyWorkStationState`]
    );

    const onClickToggleTradesWorkStation = () => {
      store.commit(
        `${WORKSTATION}/setLandlordsWorkStationState`,
        !isShowLandlordsWorkStation.value
      );
    };

    const onClickSoleTrader = () => {
      store.commit(`${WORKSTATION}/setLandlordsWorkStationState`, true);
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowSoleTraderWorkStation: !isShowSoleTraderWorkStation.value,
      });
    };

    const onClickCloseSoloTrader = () => {
      store.commit(`${WORKSTATION}/setLandlordsWorkStationState`, true);
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowSoleTraderWorkStation: false,
      });
    };

    const onClickLimitedCompany = () => {
      store.commit(`${WORKSTATION}/setLandlordsWorkStationState`, true);

      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowLimitedCompanyWorkStation: !isShowLimitedCompanyWorkStation.value,
      });
    };

    const onClickCloseLimitedCompany = () => {
      store.commit(`${WORKSTATION}/setLandlordsWorkStationState`, true);
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowLimitedCompanyWorkStation: false,
      });
    };

    return {
      user,
      onClickToggleTradesWorkStation,
      isShowLandlordsWorkStation,
      onClickSoleTrader,
      isShowSoleTraderWorkStation,
      onClickCloseSoloTrader,
      onClickLimitedCompany,
      isShowLimitedCompanyWorkStation,
      onClickCloseLimitedCompany,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/workstation/homeowner/styles/HomeownerWorkstation.scss";
@import "@/modules/workstation/soletrader/styles/SoleTraderWorkStationComponent.scss";
</style>
