export default {
  folderList: (state: { folderList: boolean }) => state.folderList,

  isPropertyDocument: (state: { isPropertyDocument: boolean }) =>
    state.isPropertyDocument,

  fileList: (state: { fileList: boolean }) => state.fileList,
  openedFolder: (state: { openedFolder: boolean }) => state.openedFolder,
  selectedDocument: (state: { selectedDocument: boolean }) =>
    state.selectedDocument,

  openShareModal: (state: { openShareModal: boolean }) => state.openShareModal,
  openRenameModal: (state: { openRenameModal: boolean }) =>
    state.openRenameModal,
  openMoveModal: (state: { openMoveModal: boolean }) => state.openMoveModal,
  openDetailsModal: (state: { openDetailsModal: boolean }) =>
    state.openDetailsModal,
  openDeleteModal: (state: { openDeleteModal: boolean }) =>
    state.openDeleteModal,
  openNewFolderModal: (state: { openNewFolderModal: boolean }) =>
    state.openNewFolderModal,

  openNewFileUploadModal: (state: { openNewFileUploadModal: boolean }) =>
    state.openNewFileUploadModal,
  openDocumentBillingModal: (state: { openDocumentBillingModal: boolean }) =>
    state.openDocumentBillingModal,
  documentList: (state: { documentList: boolean }) => state.documentList,
};
