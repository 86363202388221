<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4528 9.41246H7.25577V8.15063H9.63923C9.77943 8.15063 9.89159 8.03847 9.89159 7.89826V6.39809C9.89159 6.25789 9.77943 6.14572 9.63923 6.14572H7.25577V4.88389H11.56C11.6722 4.88389 11.7703 4.77173 11.7703 4.63153V2.87899C11.7703 2.73878 11.6722 2.62662 11.56 2.62662H7.25577V0.49553C7.25577 0.355327 7.14361 0.243164 7.00341 0.243164C6.8632 0.243164 6.75104 0.355327 6.75104 0.49553V2.62662H4.32552C4.21336 2.62662 4.11522 2.73878 4.11522 2.87899V4.63153C4.11522 4.77173 4.21336 4.88389 4.32552 4.88389H6.75104V6.14572H0.596116C0.455913 6.14572 0.34375 6.25789 0.34375 6.39809V7.91228C0.34375 8.05249 0.455913 8.16465 0.596116 8.16465H6.75104V9.42648H4.32552C4.21336 9.42648 4.11522 9.53864 4.11522 9.67885V11.193C4.11522 11.3332 4.21336 11.4454 4.32552 11.4454H6.75104V13.3241C6.75104 13.4643 6.8632 13.5765 7.00341 13.5765C7.14361 13.5765 7.25577 13.4643 7.25577 13.3241V11.4314H13.4528C13.5649 11.4314 13.6631 11.3192 13.6631 11.179V9.66483C13.6631 9.52462 13.5649 9.41246 13.4528 9.41246Z"
      fill="#FFA500"
    />
  </svg>
</template>
<script>
export default {
  name: "TimelineIcon",
};
</script>
