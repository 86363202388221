<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <TrashIcon
            fillOpacity="1"
            fill="#F45454"
            class="tw-w-[27px] tw-h-[35px]"
          />
          <Text variant="h4" textAlign="center">Delete This Project?</Text>
          <template v-if="!projectDetail?.projectEstimateCount">
            <Text variant="p" textWeight="400" textAlign="center">
              Are you sure tou want to delete this Project? Once deleted, You’ll
              not be able to restore it back.</Text
            >
          </template>
          <template v-else>
            <Text variant="p" textWeight="400" textAlign="center">
              You are about to delete the project
              <strong> {{ projectDetail?.name }}</strong> It has
              <strong> {{ projectDetail?.projectEstimateCount }}</strong>
              associated estimates.</Text
            >
            <Text variant="p" textWeight="400" textAlign="center">
              Deleting this project will permanently remove all related data,
              including the estimates.</Text
            >
            <Text variant="p" textWeight="400" textAlign="center">
              Are you sure you want to proceed?</Text
            >
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
      >
        <Button
          :disabled="loading"
          :isLoading="loading"
          label="Delete Project"
          :isActive="true"
          @click="onDelete"
        />
        <v-btn
          @click="onCloseModal"
          variant="plain"
          class="blue-text"
          color="blue"
          >cancel</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import projectService from "@/core/services/project.service";
import { PROJECT_ROUTE } from "../../routes";
import { displayToastMessage } from "@/core/utils/common";

const emits = defineEmits(["on-close", "on-success", "on-delete"]);
const props = defineProps({
  projectDetail: {
    type: Object,
    default: null,
  },
});
const internalInstance = getCurrentInstance();

const router = useRouter();

const loading = ref(false);

const onCloseModal = () => {
  emits("on-close");
};

const onDelete = async () => {
  try {
    loading.value = true;
    if (props.projectDetail) {
      const response = await projectService.deleteProject(
        props.projectDetail?.id
      );
      router.push({ name: PROJECT_ROUTE });
      displayToastMessage(
        internalInstance,
        "Project Deleted Successfully",
        "success"
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};
</script>
