import WorkStationLinkTreeRoute from "@/modules/workStation-link-tree/pages/WorkStationLinkPage.vue";

export const WORKSTATION_LINK_TREE_ROUTE = "workstation-link";

export default {
  path: "/link/:token",
  name: WORKSTATION_LINK_TREE_ROUTE,
  component: WorkStationLinkTreeRoute,
  meta: {
    layout: "AuthLayout",
  },
};
