<template>
  <v-table
    fixed-header
    class="tw-border-t-solid-custom tw-pb-4 tw-box-border !tw-rounded-none !tw-max-h-[500px]"
  >
    <thead>
      <tr>
        <th class="text-left">
          <Text variant="p" textColor="rgba(12, 15, 74, 0.5)">Agent Name</Text>
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Listing Price</Text
          >
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Change</Text
          >
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Date Created</Text
          >
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Status</Text
          >
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in props.data"
        :key="index"
        class="tw-cursor-pointer"
        :class="{
          'tw-bg-blue-100':
            state.selectedRow === item.listingId && state.selectedRow !== null,
        }"
        @click="onHandleClickRow($event, item)"
      >
        <td class="text-left">
          <Text variant="p">{{ item?.seller?.name }}</Text>
        </td>
        <td class="text-left">
          <Text variant="p">{{
            CurrencyFilter.formatToCurrency(item?.pricing?.price)
          }}</Text>
        </td>
        <td class="text-left">
          <Text
            variant="p"
            :textColor="
              getChangeLabel(item?.priceChange).direction === 'down'
                ? '#FF3636'
                : getChangeLabel(item?.priceChange).direction === 'initial'
                ? '#0C0F4A'
                : '#40903E'
            "
            >{{ getChangeLabel(item?.priceChange).amount }}</Text
          >
        </td>
        <td class="text-left">
          <Text variant="p">{{ getAddressLabel(item?.publishedDate) }}</Text>
        </td>
        <td class="text-left">
          <div class="tw-flex">
            <LiveChip v-if="item?.status === 'live'" />
            <RemovedChip v-else />
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>

  <!-- Modals -->
</template>
<script setup>
import moment from "moment";
import { computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Text from "@/core/components/ui/general/Text.vue";
import AreaIcon from "@/core/components/icons/AreaIcon.vue";
import EnergyRatingChip from "@/modules/properties/components/EnergyRatingChip.vue";
import RemovedChip from "@/core/uni-components/Chips/RemovedChip.vue";
import LiveChip from "@/core/uni-components/Chips/LiveChip.vue";
import CurrencyFilter from "@/filters/currency.filter";
import appFilters from "@/filters";

const router = useRouter();
const store = useStore();

const emits = defineEmits(["on-edit"]);
const props = defineProps(["data"]);

const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedProperty: null,
});

const onEdit = (item) => {
  emits("on-edit", item);
};

const getLatestDateObject = (array, dateKey) => {
  return array.reduce((latest, current) => {
    const currentDate = moment(current[dateKey]);
    if (!latest || currentDate.isAfter(moment(latest[dateKey]))) {
      return current;
    }
    return latest;
  }, null);
};

const removeCharacters = (str) => {
  return str.replace(/[^0-9]/g, "");
};

const formatPercentage = (value) => {
  if (typeof value !== "number") {
    throw new TypeError("Expected a number");
  }

  const roundedValue = Math.round(value * 100) / 100; // Round to 2 decimal places
  const formattedValue = `${roundedValue.toFixed(2)}%`;

  return value < 0 ? `(${formattedValue})` : formattedValue;
};

const getChangeLabel = (priceChange) => {
  const change = getLatestDateObject(priceChange, "date");
  const amountChange = `${
    change?.direction === "down"
      ? "-"
      : change?.direction === "initial"
      ? ""
      : "+"
  }${CurrencyFilter.formatToCurrency(
    removeCharacters(change?.amount.toString())
  )}`;
  const percentage = formatPercentage(change?.percent);
  if (change?.direction === "initial") {
    return {
      amount: "No Change",
      direction: change?.direction,
    };
  }
  return {
    amount: `${amountChange} ${percentage}`,
    direction: change?.direction,
  };
};

const getAddressLabel = (address) => {
  const filteredAddress = moment(address); // Moment can directly parse ISO 8601 strings
  const now = moment();

  if (!filteredAddress.isValid()) {
    throw new Error("Invalid date format");
  }

  const difference = filteredAddress.from(now);
  return `${filteredAddress.format("DD/MM/YYYY")} ( ${difference} )`;
};

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      state.selectedRow =
        state.selectedRow === item.listingId ? null : item.listingId;
      state.result.push(event.type);
      state.clicks = 0;
    }, state.delay);
  } else {
    clearTimeout(state.timer);
    state.selectedProperty = item;
    state.selectedRow = item.listingId;
    state.clicks = 0;
    // emits("on-view-property", item);
  }
};
</script>
<style lang="scss" scoped></style>
