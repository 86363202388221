<template>
  <div
    class="service-icon-wrapper tw-flex tw-items-center tw-justify-center tw-h-[40px] tw-w-[40px] tw-min-h-[40px] tw-min-w-[40px] tw-rounded-full"
  >
    <!-- NOTE: This only accepts svg for now -->
    <img
      class="service-icon-wrapper__icon tw-w-[18px] tw-h-[15px]"
      :src="require(`@/assets/${path}/${iconName}.svg`)"
      alt="icon"
    />
  </div>
</template>
<script setup>
const props = defineProps({
  iconName: {
    type: String,
    default: "tools",
  },
  path: {
    type: String,
    default: "icons",
  },
  bgColor: {
    type: String,
    default: "#C5D2F2",
  },
  filter: {
    type: String,
    default:
      "invert(37%) sepia(85%) saturate(5364%) hue-rotate(220deg) brightness(50%) contrast(150%)",
  },
});
</script>
<style lang="scss" scoped>
.service-icon-wrapper {
  background: v-bind("props.bgColor");

  &__icon {
    // filter: invert(37%) sepia(85%) saturate(5364%) hue-rotate(220deg)
    //   brightness(87%) contrast(91%);
    filter: v-bind("props.filter");
  }
}
</style>
