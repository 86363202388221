import AnalyticsService from "@/core/services/analytics.service";
import { Commit } from "vuex";
export default {
  async getAnalyticsData(
    { commit }: { commit: Commit },
    payload: {
      conversationId?: string;
      prompt: string;
    }
  ) {
    try {
      const response = await AnalyticsService.getAnalyticsData(payload);
      commit("setAnalyticsData", response);
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchTradeAnalytics({ commit }: { commit: Commit }, params: any) {
    try {
      const response = await AnalyticsService.fetchTradeAnalytics(params);
      commit("setTradeAnalytics", response);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchServiceRevenue({ commit }: { commit: Commit }, params: any) {
    try {
      const response = await AnalyticsService.fetchServiceRevenue(params);
      console.log(response, "revenue service response");
      commit("setRevenueService", response);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchRevenuePerformance({ commit }: { commit: Commit }, params: any) {
    try {
      const response = await AnalyticsService.fetchRevenuePerformance(params);
      console.log(response, "revenue performance response");
      commit("setRevenuePerformance", response);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchTeamPerformance({ commit }: { commit: Commit }, params: any) {
    try {
      const response: any = await AnalyticsService.fetchTeamPerformance(params);
      console.log(response, "team performance response");
      commit("setTeamPerformance", response?.teamMembers);
    } catch (error) {
      console.log(error);
    }
  },
};
