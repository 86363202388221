<template>
  <ChipState
    label="Commercial"
    textColor="#4A92E5"
    bgColor="transparent"
    class="!tw-px-0 !tw-gap-1"
  >
    <template #prefix>
      <TradeAvatarCircleBlue class="!tw-w-[16px]" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import TradeAvatarCircleBlue from "@/core/components/icons/TradeAvatarCircleBlue";
</script>
<script>
export default {
  name: "CommercialChip",
};
</script>
