import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import actions from "./actions";
export const WORKSTATION = "workstation";

export default {
  namespaced: true,

  actions,

  state,

  getters,

  mutations,
};
