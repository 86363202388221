export default {
  getEstimatesSent: (state: any) => state.estimatesSent,
  getJobsCompleted: (state: any) => state.jobsCompleted,
  getCustomerRating: (state: any) => state.customerRating,
  getRevenueOverview: (state: any) => state.revenueOverview,
  getRevenueData: (state: any) => state.revenueData,
  getRevenuePerformance: (state: any) => state.revenuePerformance,
  getRevenueByService: (state: any) => state.revenueByService,
  getJobDurationByService: (state: any) => state.jobDurationByService,
  getRevenueAndJobService: (state: any) => state.revenueAndJobService,
  getRevenuePerformanceData: (state: any) => state.revenuePerformanceData,
  getTeamPerformance: (state: any) => state.teamPerformance,
};
