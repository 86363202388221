<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full tw-pb-6"
        >
          <Text variant="h4" textAlign="center" textWeight="400"
            >Congratulations 🎉</Text
          >
          <Text variant="h6" textWeight="400" textAlign="center"
            >Your review has been successfully submitted. Thank you for sharing
            your feedback with us. We appreciate your time and effort in helping
            us improve!</Text
          >
          <img :src="require('@/assets/images/success-image.png')" alt="" />
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

const emits = defineEmits(["on-close", "on-success", "on-delete"]);
// const props = defineProps(["type", "label"]);
const props = defineProps({
  type: {
    type: String,
  },
  label: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const onCloseModal = () => {
  emits("on-close");
};
</script>
