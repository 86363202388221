import ProjectStageTaskMemberService from "@/core/services/project-stage-task-member.service";

export default {
  getStageTaskMembers(context: any, taskId: number) {
    return ProjectStageTaskMemberService.getStageTaskMembers(taskId);
  },

  createStageTaskMembers(context: any, params: any) {
    const { taskId, data } = params;
    return ProjectStageTaskMemberService.createStageTaskMembers(taskId, data);
  },
};
