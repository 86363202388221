<template>
  <svg
    width="142"
    height="116"
    viewBox="0 0 142 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M118 56.5679C105.023 56.5679 94.5227 67.0906 94.5227 80.0451C94.5227 92.9997 105.045 103.522 118 103.522C130.977 103.522 141.477 92.9997 141.477 80.0451C141.477 67.0906 130.955 56.5679 118 56.5679ZM128.455 82.977H120.932V90.4997C120.932 92.1133 119.614 93.4315 118 93.4315C116.386 93.4315 115.068 92.1133 115.068 90.4997V82.977H107.545C105.932 82.977 104.614 81.6588 104.614 80.0451C104.614 78.4315 105.932 77.1133 107.545 77.1133H115.068V69.5906C115.068 67.977 116.386 66.6588 118 66.6588C119.614 66.6588 120.932 67.977 120.932 69.5906V77.1133H128.455C130.068 77.1133 131.386 78.4315 131.386 80.0451C131.386 81.6588 130.068 82.977 128.455 82.977Z"
      fill="#FFA500"
    />
    <path
      d="M123.864 30.0452V18.3407C123.864 15.0907 121.227 12.4771 118 12.4771H47.0454C45.4772 12.4771 43.9999 11.8634 42.8863 10.7498L34.5908 2.45432C33.4999 1.36341 31.9999 0.727051 30.4318 0.727051H6.43175C3.18175 0.727051 0.568115 3.36341 0.568115 6.59069V74.9316L10.5454 30.0452H123.864Z"
      fill="#0C0F4A"
    />
    <path
      d="M89.1363 74.5226C91.2727 62.8635 100.591 53.4771 112.25 51.2498C120.386 49.6816 128.068 51.5453 134.182 55.5907L136.955 43.068C137.773 39.4089 134.977 35.9316 131.227 35.9316H15.25L0.79545 101C-0.818186 108.318 4.74999 115.273 12.2727 115.273H111.523C115.909 115.273 119.795 112.818 121.795 109.136C120.545 109.295 119.295 109.409 118 109.409C100 109.409 85.7273 93.1362 89.1363 74.5226Z"
      fill="#0C0F4A"
    />
  </svg>
</template>

<script>
export default {
  name: "AddDocumentIcon",
};
</script>
