<template>
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_8370_59004)">
      <rect
        x="39"
        y="39.6514"
        width="33.9961"
        height="33.9961"
        rx="16.998"
        transform="rotate(-180 39 39.6514)"
        fill="white"
      />
    </g>
    <path
      d="M22.0015 31.1511C22.9875 31.1511 23.7942 30.4058 23.7942 29.4949H20.2087C20.2087 30.4058 21.0065 31.1511 22.0015 31.1511ZM27.3797 26.1824V22.0419C27.3797 19.4996 25.9097 17.3713 23.3461 16.8082V16.2451C23.3461 15.5578 22.7455 15.0029 22.0015 15.0029C21.2575 15.0029 20.6569 15.5578 20.6569 16.2451V16.8082C18.0843 17.3713 16.6232 19.4913 16.6232 22.0419V26.1824L15.4669 27.2507C14.9022 27.7724 15.2966 28.6667 16.0944 28.6667H27.8996C28.6974 28.6667 29.1008 27.7724 28.5361 27.2507L27.3797 26.1824Z"
      fill="#0C0F4A"
    />
    <defs>
      <filter
        id="filter0_d_8370_59004"
        x="0.00390625"
        y="0.655273"
        width="43.9961"
        height="43.9961"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0470588 0 0 0 0 0.0588235 0 0 0 0 0.290196 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8370_59004"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8370_59004"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "NotificationIcon",
};
</script>
