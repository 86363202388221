<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="570px"
    max-width="100%"
    persistent
    :className="['specialisms_multiselect_modal work_station_link_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <h2>{{ isAlreadyLink ? "Update a Link" : "Create a Link" }}</h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onClickCancel"
        ></v-icon>
      </div>

      <div class="specialisms_multiselect_modal_body">
        <div class="tw-mb-[24px]">
          <p v-if="!isAlreadyLink">
            Create a WorkStation Link Profile to share important details about
            your insurance plans, services offered, and contact information.
            Make it simple for clients and partners to connect and learn about
            your offerings in one convenient link.
          </p>
          <p v-else>
            Update your WorkStation Link Profile to reflect the latest insurance
            plans, services, or updates. Keep your audience informed with
            accurate details to build trust and ensure seamless communication.
          </p>
        </div>

        <!--  -->
        <v-expansion-panels v-model="expandedPanel">
          <div
            class="tw-text-left tw-font-semibold !tw-shadow-none tw-w-full tw-mb-[16px]"
          >
            Select Services
          </div>

          <v-expansion-panel>
            <v-expansion-panel-title>
              <div class="tw-flex tw-whitespace-nowrap tw-truncate">
                <span v-if="!mainSelectedCategory.length">Select Service</span>
                <span v-else>
                  {{ selectedServices }}
                </span>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="specialisms_multiselect_modal_search">
                <v-text-field
                  variant="outlined"
                  placeholder="Search Services"
                  prepend-inner-icon="mdi-magnify"
                  @input="debouncedCategoryFilter"
                >
                </v-text-field>
              </div>

              <div
                class="specialisms_multiselect_modal_inner no-scrollbar"
                v-if="filterCategory.length"
              >
                <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel
                    v-for="(specialism, index) in filterCategory"
                    :key="index"
                  >
                    <v-expansion-panel-title>
                      <div class="specialisms_multiselect_modal_item_heading">
                        <v-checkbox
                          color="#4B4BFF"
                          v-model="mainSelectedCategory"
                          :value="specialism"
                          @click="onClickMainCategory(specialism.id)"
                        />
                        <div class="specialisms_multiselect_modal_item_title">
                          <v-icon
                            v-if="specialism.iconName"
                            class="specialisms_multiselect_modal_item_title_icon"
                          >
                            <img
                              style="width: 14px"
                              v-if="specialism.iconName"
                              :src="
                                require('@/assets/category-icons/' +
                                  specialism.iconName +
                                  '.svg')
                              "
                              alt="icon"
                            />
                          </v-icon>
                          <div
                            class="specialisms_multiselect_modal_item_title_text"
                          >
                            <h2>{{ specialism?.poLabel }}</h2>
                            <p>
                              {{
                                specialism.subCategories
                                  .slice(0, 2)
                                  .map((sub: any) => sub.label)
                                  .join(", ")
                              }}
                              <span v-if="specialism.subCategories?.length > 2">
                                etc..</span
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-title>
                    <!-- <v-expansion-panel-text>
                      <div class="specialisms_multiselect_modal_item_content">
                        <v-checkbox
                          color="#4B4BFF"
                          v-model="selectedSubCategory"
                          :label="subCategory.label"
                          :key="subCategory.id"
                          :value="subCategory.id"
                          @change="sanitizationInMainCategory()"
                          v-for="subCategory in specialism.subCategories"
                        >
                        </v-checkbox>
                      </div>
                    </v-expansion-panel-text> -->
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div
                v-if="!filterCategory.length"
                class="tw-mx-auto tw-flex tw-justify-center"
              >
                <p>Not any Services Found</p>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <div
            class="tw-text-left tw-font-semibold !tw-shadow-none tw-w-full tw-mt-[30px] tw-mb-[16px]"
          >
            Select Certificates
          </div>

          <v-expansion-panel>
            <v-expansion-panel-title>
              <p v-if="!selectedCertificate.length">Select Certificate</p>
              <p v-for="crt in selectedCertificate?.slice(0, 3)" :key="crt.id">
                {{ crt.name }}
              </p>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="specialisms_multiselect_modal_search">
                <v-text-field
                  variant="outlined"
                  placeholder="Search Certification"
                  prepend-inner-icon="mdi-magnify"
                  @input="debouncedCertificateFilter"
                >
                </v-text-field>
              </div>
              <div class="tw-mt-5">
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-pb-3 tw-border-b-solid-custom tw-px-1"
                  v-if="filterCertificates.length"
                >
                  <v-checkbox
                    color="#4B4BFF"
                    v-model="selectAllCertificates"
                    @click="onClickAllCertificates($event)"
                  />
                  <div>
                    <span>Select All</span>
                  </div>
                </div>
                <div
                  class="specialisms_multiselect_modal_inner specialisms_multiselect_modal_checkbox_list no-scrollbar !tw-mt-0"
                >
                  <v-checkbox
                    class="tw-flex tw-gap-2 tw-my-2 tw-items-center tw-px-1"
                    v-for="certificate in filterCertificates"
                    :key="certificate.id"
                    :value="certificate"
                    :label="certificate.name"
                    color="#4B4BFF"
                    v-model="selectedCertificate"
                    @change="onChangeCertificate"
                  >
                  </v-checkbox>
                </div>
              </div>

              <div
                v-if="!filterCertificates.length"
                class="tw-mx-auto tw-flex tw-justify-center"
              >
                <p>Not any Certificate Found</p>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="specialisms_multiselect_modal_footer">
          <v-btn
            class="button button-purple-border"
            @click="onClickCancel"
            block
          >
            <Text variant="span"> Cancel </Text>
          </v-btn>
          <v-btn
            class="button button-orange"
            block
            @click="onClickContinue"
            :loading="isLoading"
          >
            <Text variant="span"> CONTINUE </Text>
          </v-btn>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { CERTIFICATE_STORE } from "@/store/modules/certificates";
import { displayToastMessage } from "@/core/utils/common";
import { getCurrentInstance } from "vue";

const props = defineProps({
  phaseDetails: { type: Object, default: null },
});
const emits = defineEmits([
  "onClickCloseTab",
  "onClickCancel",
  "onClickAddNewTradeSpecialism",
]);
const internalInstance = getCurrentInstance();
const mainSelectedCategory = ref([]) as any;
const selectedSubCategory = ref([]) as any;
const panel = ref([]) as any;
const expandedPanel = ref([0]) as any;
const isLoading = ref(false);
const store = useStore();
const filterCategory = ref([]);
const filterCertificates = ref([]);
const selectAllCertificates = ref(false);
let selectedCertificate = ref([]) as any;
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const listOfSpecialismsCategory = ref([]) as any;

const onClickMainCategory = (mainCategoryId: number) => {
  const isExits = mainSelectedCategory.value.find(
    (cat: any) => cat?.id === mainCategoryId
  );
  if (isExits) {
    const mainCategory = listOfSpecialismsCategory.value.find(
      (category: any) => category?.id === mainCategoryId
    );
    mainCategory.subCategories.forEach((element: any) => {
      const elementIndex = selectedSubCategory.value.findIndex(
        (subCategory: any) => subCategory === element.id
      );
      if (elementIndex > -1) {
        selectedSubCategory.value.splice(elementIndex, 1);
      }
    });
  } else {
    const mainCategory = listOfSpecialismsCategory.value.find(
      (category: any) => category?.id === mainCategoryId
    );

    mainCategory.subCategories.forEach((element: any) => {
      if (!selectedSubCategory.value.includes(element?.id)) {
        selectedSubCategory.value.push(element?.id);
      }
    });
  }
};

const onClickAllCertificates = (event: any) => {
  const isChecked = event.target.checked;
  if (isChecked)
    selectedCertificate.value = certificates.value.map(
      (certificate: any) => certificate
    );
  else selectedCertificate.value = [];
};

const onClickCancel = () => {
  emits("onClickCancel");
};

const onClickContinue = async () => {
  if (!selectedSubCategory.value.length) {
    displayToastMessage(internalInstance, `select Services`, "info");
    return;
  }
  if (!selectedCertificate.value) {
    displayToastMessage(internalInstance, `select Certificates`, "info");
    return;
  }
  try {
    isLoading.value = true;
    const map = new Map();
    const categories: any = [];

    selectedSubCategory.value.forEach((subId: any) => {
      const categoryId = listOfSpecialismsCategory.value.find(
        (mainCategory: any) =>
          mainCategory.subCategories.some((subObj: any) => subObj.id === subId)
      ).id;
      map.set(categoryId, [
        ...(map.has(categoryId) ? map.get(categoryId) : []),
        {
          subcategoryId: subId,
        },
      ]);
    });
    map.forEach((subcategories, categoryId) => {
      categories.push({ categoryId, subcategories });
    });

    const certificatesData = selectedCertificate.value.map(
      (crt: any) => crt.id
    );
    if (!isAlreadyLink.value) {
      //update Link
      await store.dispatch(`${WORKSTATION}/createWorkStationLink`, {
        userId: user.value?.id,
        userWorkstationId: activeUserWorkstation.value?.id,
        certificates: certificatesData,
        services: JSON.stringify(categories),
      });
    } else {
      //update Link
      await store.dispatch(`${WORKSTATION}/updateWorkStationLink`, {
        linkId: activeUserWorkstation.value?.workstationLinkTree.id,
        data: {
          userId: user.value?.id,
          userWorkstationId: activeUserWorkstation.value?.id,
          certificates: certificatesData,
          services: JSON.stringify(categories),
        },
      });
    }
    emits("onClickCancel", true);
  } catch (e) {
    console.log("Error", e);
  } finally {
    isLoading.value = false;
  }
};

const debouncedCategoryFilter = (event: any) => {
  let debounceTimeOut;
  const search = event?.target?.value || "";
  clearTimeout(debounceTimeOut);
  debounceTimeOut = setTimeout(() => {
    if (search) {
      const searchTerm = search.toLowerCase();
      filterCategory.value = listOfSpecialismsCategory.value.filter(
        (category: any) => {
          const mainCategoryMatch = category.poLabel
            .toLowerCase()
            .includes(searchTerm);

          if (mainCategoryMatch) {
            return true;
          }
          const subCategoryMatch = category.subCategories?.some(
            (subCategory: any) =>
              subCategory.label.toLowerCase().includes(searchTerm)
          );

          return subCategoryMatch;
        }
      );
    } else {
      filterCategory.value = listOfSpecialismsCategory.value;
    }
  }, 300);
};

const debouncedCertificateFilter = (event: any) => {
  let debounceTimeOut;
  const search = event?.target?.value || "";
  clearTimeout(debounceTimeOut);
  debounceTimeOut = setTimeout(() => {
    if (search) {
      const searchTerm = search.toLowerCase();
      filterCertificates.value = certificates.value.filter(
        (certificate: any) => {
          return certificate.name.toLowerCase().includes(searchTerm);
        }
      );
    } else {
      filterCertificates.value = certificates.value;
    }
  }, 300);
};

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);
const userSpecialisms = computed(
  () => store.getters[`${USER_STORE}/userSpecialisms`]
);
const certificates = computed(() => {
  return store.getters[`${USER_STORE}/userCertificates`]?.map(
    (data: any) => data?.certificate
  );
});

const selectedServices = computed(() => {
  return (
    mainSelectedCategory.value
      .map((cat: any) => cat.poLabel)
      .slice(0, 3)
      .join(", ") + (mainSelectedCategory.value.length > 3 ? "..." : "")
  );
});

const isAlreadyLink = computed(() => {
  return activeUserWorkstation.value?.workstationLinkTree ? true : false;
});

const selectedWorkStationLinkData = () => {
  const workStationLinkTreeData =
    activeUserWorkstation.value?.workstationLinkTree;

  if (!workStationLinkTreeData) return;
  const workStationCertificates = workStationLinkTreeData.certificates;
  const workStationServices = JSON.parse(workStationLinkTreeData.services);

  certificates.value.forEach((crt: any) => {
    if (workStationCertificates.includes(crt?.id.toString())) {
      selectedCertificate.value.push(crt);
    }
  });
  if (selectedCertificate.value.length === certificates.value.length) {
    selectAllCertificates.value = true;
  }

  //services
  workStationServices?.forEach((service: any) => {
    service.subcategories.forEach((subCategory: any) => {
      selectedSubCategory.value.push(Number(subCategory.subcategoryId));
    });
  });
  sanitizationInMainCategory();
};
const onChangeCertificate = () => {
  if (certificates.value.length !== selectedCertificate.value.length) {
    selectAllCertificates.value = false;
  } else {
    selectAllCertificates.value = true;
  }
};
const sanitizationInMainCategory = () => {
  mainSelectedCategory.value = [];

  listOfSpecialismsCategory.value.forEach((specialism: any) => {
    const isAllSubcategorySelected = specialism.subCategories.every(
      (category: any) => selectedSubCategory.value.includes(category.id)
    );
    if (
      isAllSubcategorySelected &&
      !mainSelectedCategory.value.find((cat: any) => cat?.id === specialism.id)
    ) {
      mainSelectedCategory.value.push(specialism);
    }
  });
};

onMounted(() => {
  listOfSpecialismsCategory.value = userSpecialisms.value.map(
    (specialism: any) => {
      const category = specialism?.category;
      const subCategories = specialism?.subCategories;
      return {
        ...category,
        subCategories,
      };
    }
  );
  filterCategory.value = listOfSpecialismsCategory.value;
  filterCertificates.value = certificates.value;

  //selected Workstation data
  selectedWorkStationLinkData();
});
</script>
