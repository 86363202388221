<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74996 5.9974C11.2233 5.9974 12.4166 4.80406 12.4166 3.33073C12.4166 1.8574 11.2233 0.664062 9.74996 0.664062C8.27663 0.664062 7.08329 1.8574 7.08329 3.33073C7.08329 4.80406 8.27663 5.9974 9.74996 5.9974ZM3.74996 4.66406V3.33073C3.74996 2.96406 3.44996 2.66406 3.08329 2.66406C2.71663 2.66406 2.41663 2.96406 2.41663 3.33073V4.66406H1.08329C0.716626 4.66406 0.416626 4.96406 0.416626 5.33073C0.416626 5.6974 0.716626 5.9974 1.08329 5.9974H2.41663V7.33073C2.41663 7.6974 2.71663 7.9974 3.08329 7.9974C3.44996 7.9974 3.74996 7.6974 3.74996 7.33073V5.9974H5.08329C5.44996 5.9974 5.74996 5.6974 5.74996 5.33073C5.74996 4.96406 5.44996 4.66406 5.08329 4.66406H3.74996ZM9.74996 7.33073C7.96996 7.33073 4.41663 8.22406 4.41663 9.9974V10.6641C4.41663 11.0307 4.71663 11.3307 5.08329 11.3307H14.4166C14.7833 11.3307 15.0833 11.0307 15.0833 10.6641V9.9974C15.0833 8.22406 11.53 7.33073 9.74996 7.33073Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
  </svg>
</template>
<script>
export default {
  name: "AddAvatarIcon",
};
</script>
