<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.9987 0.166016C2.5487 0.166016 0.332031 2.04435 0.332031 4.94935C0.332031 6.80435 1.7612 8.98602 4.6137 11.5002C4.83536 11.6927 5.16786 11.6927 5.38953 11.5002C8.2362 8.98602 9.66536 6.80435 9.66536 4.94935C9.66536 2.04435 7.4487 0.166016 4.9987 0.166016ZM4.9987 5.99935C4.35703 5.99935 3.83203 5.47435 3.83203 4.83268C3.83203 4.19102 4.35703 3.66602 4.9987 3.66602C5.64036 3.66602 6.16536 4.19102 6.16536 4.83268C6.16536 5.47435 5.64036 5.99935 4.9987 5.99935Z"
      fill="#264FD5"
    />
  </svg>
</template>

<script>
export default {
  name: "MapMarkerBlueIcon",
};
</script>
