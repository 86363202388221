<template>
  <div class="tw-relative tw-h-full tw-w-full tw-rounded-2xl">
    <div
      id="map"
      class="tw-w-full tw-h-full tw-max-h-full tw-rounded-2xl tw-z-0"
    />
    <!-- <div
      class="tw-z-[200000] tw-flex tw-justify-end tw-p-4 tw-items-center tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-[56px] tw-bg-white tw-rounded-[16px_16px_0_0]"
    >
    <v-btn
        prepend-icon="mdi-location"
        size="small"
        class="tw-text-[12px] tw-text-[#0C0F4A]"
        variant="text"
        >
        My Saved Areas
      </v-btn>
    </div> -->
  </div>
</template>
<script lang="ts" setup>
import { JobsState } from "@/core/models/jobs";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { computed, defineProps, onMounted, watch } from "vue";
import mapMarkerImage from "@/core/uni-assets/images/map-marker.png";

const props = defineProps({
  data: {
    type: Object as () => JobsState["jobs"],
    required: true,
  },
});

const mapData = computed(() => props.data);

let map: L.Map;

let markersLayer = new L.LayerGroup();

const updateMap = (data: JobsState["jobs"]) => {
  // NOTE: The first thing we do here is clear the markers from the layer.
  markersLayer.clearLayers();

  const locationCoor = [];
  let marker: any;

  const customIcon = {
    iconUrl: mapMarkerImage,
    iconSize: [20, 40],
  };

  const mapIcon = L.icon(customIcon);

  for (let i = 0; i < data.length; i++) {
    const iconProps = {
      title: data[i].name,
      clickable: true,
      icon: mapIcon,
    };

    const lat = parseFloat(data[i].latitude);
    const lon = parseFloat(data[i].longitude);

    if (lat && lon) {
      locationCoor[i] = [lat, lon];

      const popup = L.popup().setLatLng([lat, lon]).setContent(`
      <h1>${data[i].name}</h1>
      <p>${data[i].postcode}</p>
      <p>${data[i].description}</p>
    `);

      marker = L.marker([lat, lon], iconProps).bindPopup(popup);

      markersLayer.addLayer(marker);
    }
  }

  if (locationCoor.length > 0) {
    const bounds = new L.latLngBounds(locationCoor);
    map.fitBounds(bounds, { padding: [50, 50] });
  }
};

onMounted(() => {
  map = L.map("map", {
    zoomControl: false,
  }).setView([51.3593966, -0.2863208], 12);
  L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
    maxZoom: 19,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  markersLayer.addTo(map);
  // Initialize mapMarkers

  updateMap(mapData.value);
});

watch(
  mapData,
  (state) => {
    updateMap(state);
  },
  { deep: true }
);
</script>
<style lang="scss" scoped></style>
