<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#B7677A" />
    <path
      d="M9.38978 7.7569C8.71934 7.7569 8.06396 7.95408 7.50652 8.32351C6.94907 8.69295 6.5146 9.21803 6.25803 9.83237C6.00147 10.4467 5.93434 11.1227 6.06514 11.7749C6.19593 12.4271 6.51877 13.0261 6.99284 13.4963C7.46691 13.9665 8.07091 14.2867 8.72846 14.4165C9.38602 14.5462 10.0676 14.4796 10.687 14.2251C11.3064 13.9707 11.8358 13.5397 12.2083 12.9869C12.5807 12.434 12.7795 11.7839 12.7795 11.119C12.779 10.2275 12.4216 9.37266 11.7861 8.74228C11.1505 8.11189 10.2886 7.75748 9.38978 7.7569ZM10.8951 11.9985L10.2677 12.6208L9.38091 11.7413L8.49411 12.6208L7.8667 11.9985L8.7535 11.119L7.8667 10.2394L8.49411 9.61935L9.38091 10.4989L10.2677 9.61935L10.8951 10.2372L10.0083 11.1168L10.8951 11.9985ZM19.2975 10.9387C18.6918 10.9358 18.1119 10.6952 17.6845 10.2696C17.257 9.84405 17.0168 9.26796 17.0162 8.66724V6H10.5559C10.242 6.00093 9.93399 6.08446 9.66329 6.24208C9.39259 6.3997 9.16891 6.62574 9.0151 6.89714C9.13926 6.88615 9.26341 6.87735 9.38978 6.87735C9.95943 6.86448 10.5259 6.96466 11.056 7.17198C11.5861 7.37931 12.069 7.6896 12.4765 8.08465C12.884 8.47969 13.2077 8.95151 13.4288 9.4724C13.6498 9.99329 13.7637 10.5527 13.7637 11.1179C13.7637 11.683 13.6498 12.2425 13.4288 12.7634C13.2077 13.2843 12.884 13.7561 12.4765 14.1511C12.069 14.5462 11.5861 14.8565 11.056 15.0638C10.5259 15.2711 9.95943 15.3713 9.38978 15.3584C9.18795 15.3564 8.98649 15.341 8.78675 15.3122V22.2409C8.78675 22.7074 8.97361 23.1549 9.30623 23.4848C9.63884 23.8147 10.09 24 10.5603 24H20.2264C20.6968 24 21.1479 23.8147 21.4805 23.4848C21.8131 23.1549 22 22.7074 22 22.2409V10.9387H19.2975ZM18.7166 21.5944H12.0657V20.7149H18.7166V21.5944ZM18.7166 19.3956H12.0657V18.516H18.7166V19.3956ZM18.7166 17.1967H12.0657V16.3171H18.7166V17.1967ZM17.903 8.66724V6.18031L21.7384 10.0591H19.2997C18.9289 10.0568 18.5739 9.90921 18.3123 9.64849C18.0507 9.38777 17.9036 9.03507 17.903 8.66724Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "JobsRejectedIcon",
};
</script>
