<template>
  <GeneralDialog @on-close="onCloseModal" headerTitle="View Asset Details">
    <template #body>
      <div
        class="tw-w-[740px] tw-h-[900px] tw-border-t-dashed-custom tw-border-b-solid-custom tw-flex tw-flex-col tw-gap-4 tw-py-5 tw-box-border tw-overflow-x-hidden"
      >
        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)">Type</Text>
            <Text variant="span">Physical Asset</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >ID Number</Text
            >
            <Text variant="span">{{ selectedAsset?.idNumber }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Location</Text
            >
            <Text variant="span">{{ selectedAsset?.location }}</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)">Size</Text>
            <Text variant="span">{{ selectedAsset?.size }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Access Information</Text
            >
            <Text variant="span">{{ selectedAsset?.accessInformation }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Supplier</Text
            >
            <Text variant="span">{{ selectedAsset?.supplier }}</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Installer</Text
            >
            <Text variant="span">{{ selectedAsset?.installer }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Purchase Price</Text
            >
            <Text variant="span">{{
              formattedCurrency(selectedAsset?.purchasePrice)
            }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Date of acquisition</Text
            >
            <Text variant="span">{{
              formattedDate(selectedAsset?.dateOfAcquisition)
            }}</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Purchase Order Number</Text
            >
            <Text variant="span">{{ selectedAsset?.purchaseOrderNumber }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Date Of Delivery</Text
            >
            <Text variant="span">{{
              formattedDate(selectedAsset?.dateOfDelivery)
            }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Ownership records</Text
            >
            <Text variant="span">{{ selectedAsset?.ownershipRecords }}</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Current Value</Text
            >
            <Text variant="span">{{
              formattedCurrency(selectedAsset?.currentValue)
            }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Current Value</Text
            >
            <Text variant="span">{{
              formattedCurrency(selectedAsset?.currentValue)
            }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Conditions and defects</Text
            >
            <Text variant="span">{{
              selectedAsset?.conditionsAndDefects
            }}</Text>
          </div>
        </div>

        <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
          <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
            >Photographs</Text
          >
          <div
            class="tw-flex tw-gap-3 tw-items-center"
            v-if="attachments.photographs && attachments.photographs.length"
          >
            <div
              class="tw-flex tw-w-[90px] tw-h-[90px] tw-overflow-hidden"
              v-for="file in attachments.photographs"
              :key="file"
            >
              <img
                class="tw-w-full tw-h-full tw-object-cover"
                :src="file.attachmentSource"
              />
            </div>
          </div>
          <Text variant="span" v-else>N/A</Text>
          <CommonLoader
            v-if="state.loading"
            :loading="state.loading"
            class="loader-container"
          />
        </div>

        <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
          <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
            >Drawing references</Text
          >
          <div
            class="tw-flex tw-gap-3 tw-items-center"
            v-if="attachments.drawingRef && attachments.drawingRef.length"
          >
            <div
              class="tw-flex tw-w-[90px] tw-h-[90px] tw-overflow-hidden"
              v-for="file in attachments.drawingRef"
              :key="file"
            >
              <img
                class="tw-w-full tw-h-full tw-object-cover"
                :src="file.attachmentSource"
              />
            </div>
          </div>

          <Text variant="span" v-else>N/A</Text>
          <CommonLoader
            v-if="state.loading"
            :loading="state.loading"
            class="loader-container"
          />
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Information about spares</Text
            >
            <Text variant="span">{{
              selectedAsset?.informationAboutSpares
            }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Running Cost</Text
            >
            <Text variant="span">{{
              formattedCurrency(selectedAsset?.runningCost)
            }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Energy performance</Text
            >
            <Text variant="span">{{ selectedAsset?.energyPerformance }}</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Certification</Text
            >
            <Text variant="span">{{ selectedAsset?.certification }}</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Health and safety information</Text
            >
            <Text variant="span">-</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Service level agreements</Text
            >
            <Text variant="span">-</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Warranties</Text
            >
            <Text variant="span">-</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Disposal Date</Text
            >
            <Text variant="span">-</Text>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Method of disposal</Text
            >
            <Text variant="span">-</Text>
          </div>
        </div>

        <div class="view-asset-container tw-grid tw-grid-cols-3">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2">
            <Text variant="span" textColor="rgba(12, 15, 74, 0.5)"
              >Sale Price</Text
            >
            <Text variant="span">-</Text>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3">
        <Button
          class="!tw-w-auto"
          label="done"
          :isActive="true"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import CurrencyFilter from "@/filters/currency.filter";
import appFilters from "@/filters";
import { getImageApiUrl, getImageStringToImageURL } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import CommonLoader from "@/core/components/CommonLoader.vue";

const emits = defineEmits(["on-close", "on-success"]);
const props = defineProps(["selectedAsset"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});
const attachments = reactive({
  photographs: [],
  drawingRef: [],
});

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const formattedDate = (date) => {
  return appFilters.formatToDate(date, "DD MMM YYYY");
};

const onCloseModal = () => {
  emits("on-close");
};

const getAttachments = async () => {
  if (
    props.selectedAsset?.attachments &&
    props.selectedAsset?.attachments.length
  ) {
    state.loading = true;
    try {
      const attachmentPromises = props.selectedAsset.attachments.map(
        async (currentFile) => {
          const { attachment } = currentFile;
          const publicURL = await getImageStringToImageURL(attachment);
          return { ...currentFile, attachmentSource: publicURL };
        }
      );

      const fileList = await Promise.all(attachmentPromises);
      attachments.photographs = fileList.filter(
        (file) => file.assetDocType === "Photographs"
      );

      attachments.drawingRef = fileList.filter(
        (file) => file.assetDocType === "DrawingRefrences"
      );

      console.log(attachments, "attachments");
    } catch (error) {
      console.log(error);
    } finally {
      state.loading = false;
    }
  }
};

onMounted(async () => {
  await getAttachments();
});
</script>
