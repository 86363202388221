<template>
  <v-table height="450px" fixed-header class="tw-pb-4 tw-box-border tw-w-full">
    <thead>
      <tr>
        <th class="text-left">
          <Text variant="p" textColor="rgba(12, 15, 74, 0.5)">Title Type</Text>
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Title Number</Text
          >
        </th>
        <th class="text-left">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Duration</Text
          >
        </th>
        <th class="text-left">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in data"
        :key="index"
        class="tw-cursor-pointer"
        :class="{
          'tw-bg-blue-100':
            state.selectedRow === item.id && state.selectedRow !== null,
        }"
        @click="onHandleClickRow($event, item)"
      >
        <td class="text-left">
          <div class="tw-flex tw-items-center tw-gap-2">
            <Text variant="p">{{ item?.titleType }}</Text>
          </div>
        </td>
        <td class="text-left">
          <Text variant="p">{{ item?.titleNumber }}</Text>
        </td>
        <td class="text-left">
          <Text variant="p">{{ item?.duration }}</Text>
        </td>
        <td class="text-left">
          <Text
            @click="onShowCoordinates(item)"
            class="!tw-underline"
            variant="span"
            textWeight="500"
            textColor="#4B4BFF"
            >Show Coordinates</Text
          >
        </td>
      </tr>
    </tbody>
  </v-table>

  <!-- Modals -->
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PropertyListActionMenu from "@/modules/properties/components/PropertyListActionMenu.vue";
import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";

const router = useRouter();
const store = useStore();

const emits = defineEmits(["on-show-coordinates"]);

const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedProperty: null,
});

// Temporary data
const data = ref([
  {
    id: 1,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 2,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 3,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 4,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 5,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 6,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 7,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
  {
    id: 8,
    titleType: "Freehold",
    titleNumber: "MS 324342343",
    duration: "MS 324342343",
  },
]);

const onShowCoordinates = (item) => {
  emits("on-show-coordinates", item);
};

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      state.selectedRow = state.selectedRow === item.id ? null : item.id;
      state.result.push(event.type);
      state.clicks = 0;
    }, state.delay);
  } else {
    clearTimeout(state.timer);
    state.selectedProperty = item;
    state.selectedRow = item.id;
    state.clicks = 0;
    emits("on-view-property", item);
  }
};
</script>
<style lang="scss" scoped></style>
