/**
 * Profile route page
 */
import ProfileRoute from "@/modules/profile/pages/ProfileView.vue";

export const PROFILE_ROUTE = "profile";

export default {
  path: "/profile",
  name: PROFILE_ROUTE,
  component: ProfileRoute,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Profile",
  },
};
