// import { ProjectStageStatusEnum, UpdateProjectStageRequestDto, UpdateProjectStageStatusRequestDto } from '@/core/packages/shared-library';
import ProjectStagesTasksService from "@/core/services/project-stages-tasks.service";

const ProjectStageStatusEnum = {
  NOT_STARTED: "1",
  NOT_STARTED_POKED: "2",
  IN_PROGRESS: "3",
  IN_PROGRESS_POKED: "4",
  STAGE_COMPLETE: "5",
  CANCELLED: "6",
  PAYMENT_IN_PROCESS: "7",
};

export default {
  /**
   * Update project quote stage status
   * @param projectQuoteId
   * @param projectStageId
   * @param formData
   * @returns
   */
  updateProjectStageStatus(
    context: any,
    {
      projectQuoteId,
      projectStageId,
      formData,
    }: {
      projectQuoteId: number;
      projectStageId: number;
      formData: any;
    }
  ) {
    return ProjectStagesTasksService.updateProjectStageStatus(
      projectQuoteId,
      projectStageId,
      formData
    );
  },

  /**
   * Update project quote stage task status
   * @param projectQuoteId
   * @param projectStageId
   * @param projectStageTaskId
   * @param projectStageTaskStatus
   * @returns
   */
  updateProjectStageTaskStatus(
    context: any,
    {
      projectQuoteId,
      projectStageId,
      projectStageTaskId,
      projectStageTaskStatus,
    }: {
      projectQuoteId: number;
      projectStageId: number;
      projectStageTaskId: number;
      projectStageTaskStatus: boolean;
    }
  ) {
    return ProjectStagesTasksService.updateProjectStageTaskStatus(
      projectQuoteId,
      projectStageId,
      projectStageTaskId,
      projectStageTaskStatus
    );
  },

  /**
   * Update project quote stage details
   * @param projectQuoteId
   * @param projectStageId
   * @param formData
   * @returns
   */
  updateProjectStage(
    context: any,
    {
      projectQuoteId,
      projectStageId,
      formData,
    }: {
      projectQuoteId: number;
      projectStageId: number;
      formData: any;
    }
  ) {
    return ProjectStagesTasksService.updateProjectStage(
      projectQuoteId,
      projectStageId,
      formData
    );
  },

  /**
   * Delete project stage attachment
   * @param projectQuoteId
   * @param projectStageId
   * @param projectStageAttachmentId
   * @returns
   */
  deleteProjectStageAttachment(
    context: any,
    {
      projectQuoteId,
      projectStageId,
      projectStageAttachmentId,
    }: {
      projectQuoteId: number;
      projectStageId: number;
      projectStageAttachmentId: number;
    }
  ) {
    return ProjectStagesTasksService.deleteProjectStageAttachment(
      projectQuoteId,
      projectStageId,
      projectStageAttachmentId
    );
  },

  /**
   * Check if all project stages are completed
   * @param projectQuoteId
   * @param projectStageId
   * @param formData
   * @returns
   */
  checkAllProjectStagesIfCompleted(context: any, projectStages: any) {
    let hasInProgressStage = true;

    if (projectStages) {
      hasInProgressStage = projectStages.some(
        (p: any) =>
          p.projectStageStatus?.id !== ProjectStageStatusEnum.STAGE_COMPLETE
      );
    }

    return hasInProgressStage;
  },
};
