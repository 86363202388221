<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['safety-check-details-modal']"
    width="520px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>{{ manageForm.name }}</h4>
          <v-btn variant="plain" icon="mdi-close" @click="closeModal"></v-btn>
        </div>

        <div class="modal_form">
          <v-form>
            <div class="form_wrapper">
              <div class="list_block__item">
                <div class="event_field">
                  <label>Safety Check Name</label>
                  <v-text-field
                    class="input_field"
                    v-model="manageForm.name"
                    variant="outlined"
                    disabled
                  ></v-text-field>
                </div>
                <div class="event_field">
                  <label>Servicing Frequency </label>
                  <v-text-field
                    class="input_field"
                    variant="outlined"
                    v-model="manageForm.servicingFrequency"
                    disabled
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-form>
        </div>

        <div
          v-if="manageForm.name === AddSafetyModalTitleEnum.GAS_TITLE"
          class="safety-check-details-modal__block safety-check-details-modal__block-service-plan"
        >
          <div class="safety-check-details-modal__block-header">
            <p>Do You have a Heat Pump Service Plan ?</p>
          </div>

          <div class="safety-check-details-modal__block-inner">
            <v-radio-group v-model="manageForm.hasHeatPumpServicePlan" inline>
              <v-radio
                label="Yes"
                :value="true"
                color="#4B4BFF"
                :ripple="false"
              ></v-radio>
              <v-radio
                :disabled="isUserHapHeatPumpServicePlan"
                label="No"
                :value="false"
                color="#4B4BFF"
                :ripple="false"
              ></v-radio>
            </v-radio-group>

            <v-btn
              v-if="!manageForm.hasHeatPumpServicePlan"
              :ripple="false"
              class="bright-blue-text"
              variant="text"
              append-icon="mdi-chevron-right"
              @click="onAction(actionType.GET_PLAN)"
            >
              Get Your Service Plan
            </v-btn>
          </div>
        </div>

        <div class="safety-check-details-modal__block">
          <div class="safety-check-details-modal__block-header">
            <p>Compliance Record</p>
            <v-btn
              @click="onAction(actionType.ADD_RECORD)"
              class="button button-orange"
              >Add Record</v-btn
            >
          </div>

          <div class="safety-check-details-modal__card">
            <div class="safety-check-details-modal__card-header">
              <div class="safety-check-details-modal__card-title">
                <GasIcon
                  v-if="safetyDetail.name === AddSafetyModalTitleEnum.GAS_TITLE"
                  :fill="safetyDetail?.status?.color || 'rgba(12, 15, 74, 0.5)'"
                />
                <ElectricalIcon
                  v-if="
                    safetyDetail.name ===
                    AddSafetyModalTitleEnum.ELECTRICAL_TITLE
                  "
                  :fill="safetyDetail?.status?.color || 'rgba(12, 15, 74, 0.5)'"
                />
                <WaterTapIcon
                  v-if="
                    safetyDetail.name === AddSafetyModalTitleEnum.WATER_TITLE
                  "
                  :fill="safetyDetail?.status?.color || 'rgba(12, 15, 74, 0.5)'"
                />

                <p>
                  <strong>{{ safetyDetail.provider }}</strong>
                </p>
              </div>

              <p><strong>Result:</strong> {{ safetyDetail?.result }}</p>
            </div>

            <div class="safety-check-details-modal__card-footer">
              <!-- TODO : Add 'is-red' For Red Background With Date -->
              <div
                class="safety-check-details-modal__card-date"
                :class="{
                  'is-red':
                    safetyDetail?.status?.label ===
                    SafetyStandardStatusEnum.EXPIRED,
                }"
              >
                <p>Expiry Date: {{ formateDate(safetyDetail?.expiryDate) }}</p>
              </div>
              <div class="safety-check-details-modal__card-divider"></div>
              <v-btn
                :ripple="false"
                class="bright-blue-text"
                variant="text"
                append-icon="mdi-chevron-right"
                @click="onAction(actionType.VIEW_DETAIL)"
              >
                View
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import GasIcon from "@/core/components/icons/GasIcon.vue";
import ElectricalIcon from "@/core/components/icons/ElectricalIcon.vue";
import WaterTapIcon from "@/core/components/icons/WaterTapIcon.vue";
import { onMounted, reactive, onBeforeUnmount, computed } from "vue";
import { parseJSON } from "@/core/utils/common";
import { PROPERTY_STORE } from "@/store/modules/property";
import { useStore } from "vuex";
import { PropertySubscriptionTypeEnum } from "@/core/enums/ProjectsEnum";
import moment from "moment";
import {
  AddSafetyModalTitleEnum,
  SafetyStandardStatusEnum,
} from "@/core/enums/PropertiesEnum";

const props = defineProps(["safetyDetail"]);
const emits = defineEmits(["on-close", "on-action"]);

const actionType = {
  GET_PLAN: "get heat pump plan",
  ADD_RECORD: "add record",
  VIEW_DETAIL: "view detail",
};
const closeModal = () => {
  emits("on-close");
};

const onAction = (type) => {
  emits("on-action", type);
};

const store = useStore();

const manageForm = reactive({
  servicingFrequency: null,
  name: null,
  hasHeatPumpServicePlan: null,
});

const formateDate = (dateString) => moment(dateString).format("MMM D, YYYY");

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const isUserHapHeatPumpServicePlan = computed(() =>
  propertyDetails.value.propertySubscriptions.some(
    (plan) => plan.type === PropertySubscriptionTypeEnum.HEAT_PUMP_SERVICE
  )
);
const updateHeatPumpServicePlan = async () => {
  try {
    await store.dispatch(`${PROPERTY_STORE}/updatePropertySafetyCheck`, {
      data: { hasHeatPumpServicePlan: manageForm.hasHeatPumpServicePlan },
      propertyId: propertyDetails.value.id,
      safetyCheckId: props.safetyDetail.id,
    });
  } catch (error) {
    console.log("safety update error", error);
  }
};

onMounted(() => {
  manageForm.name = props.safetyDetail?.name;
  manageForm.hasHeatPumpServicePlan =
    props.safetyDetail?.hasHeatPumpServicePlan;
  const serviceFrequency = parseJSON(props.safetyDetail?.serviceFrequency);
  manageForm.servicingFrequency =
    serviceFrequency.frequency && serviceFrequency.interval
      ? `${serviceFrequency.frequency} ${serviceFrequency.interval}`
      : "";
});
</script>

<style lang="scss" scoped></style>
