<template>
  <section class="tw-flex tw-flex-col tw-gap-6 tw-w-full tw-items-start">
    <div
      class="tw-flex tw-flex-col tw-gap-4 tw-items-start md:tw-w-full md:tw-max-w-full"
    >
      <div class="title">
        <Text variant="h4" textWeight="500">Account Details</Text>
      </div>
      <div
        class="uploade-wrapper tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center !tw-m-0 md:!tw-w-[150px] md:!tw-h-[150px] md:!tw-m-auto"
      >
        <v-file-input
          class="upload_file !tw-m-0 md:!tw-w-[150px]"
          append-inner-icon="mdi-image-plus"
          variant="solo-filled"
          :class="{ 'added-img': imageUrl }"
          @change="onFileChange"
          @click:clear="clearImageUrl"
          accept="image/*"
        >
        </v-file-input>
        <v-img
          class="uploaded-img"
          :width="200"
          aspect-ratio="16/9"
          cover
          :src="imageUrl"
        ></v-img>
      </div>
      <div>
        <Text variant="p" textAlign="center" textColor="rgba(255, 0, 0,0.5)">{{
          fileUploadErrorMsg
        }}</Text>
      </div>
    </div>
    <div class="tw-w-[506px] tw-flex tw-flex-col tw-gap-4 md:!tw-w-full">
      <div
        class="input__field__wrapper tw-w-full tw-flex tw-flex-col tw-gap-2 tw-items-start !-tw-m-0"
      >
        <label>Name</label>
        <v-text-field
          density="compact"
          class="c-input rounded-full tw-w-full !tw-m-0"
          variant="outlined"
          v-model="updatedUserInfo.workstationName"
          @update:modelValue="onValueChange"
        ></v-text-field>
      </div>
      <div
        class="input__field__wrapper tw-w-full tw-flex tw-flex-col tw-gap-2 tw-items-start"
      >
        <label>Email</label>
        <v-select
          :items="manageEmailList"
          item-title="aliasEmail"
          item-value="id"
          placeholder="email"
          variant="outlined"
          class="c-select tw-w-full"
          v-model="updatedUserInfo.aliasEmail"
          :menu-props="{ contentClass: 'tags_dropdown' }"
          @update:modelValue="onValueChange"
        ></v-select>
      </div>
      <Button
        label="save details"
        @click="onClickSaveButton"
        :isActive="isProFileImageUploading"
        :isLoading="isWorkStationDetailUpdating"
      />
    </div>
    <div
      class="tw-w-full tw-pt-5 tw-box-border tw-border-t-dashed-custom tw-flex tw-justify-start"
    >
      <Text
        variant="p"
        textColor="#F45454"
        class="tw-cursor-pointer"
        textAlign="left"
        >Delete Workstation</Text
      >
    </div>
  </section>
</template>
<script setup>
import axios from "axios";
import $axios from "@/core/utils/axios-api-config";
import { computed, ref, onBeforeMount, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { getImageApiUrl } from "@/core/utils/common";
import UserService from "@/core/services/user.service";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import manageEmailService from "@/core/services/manage-email.service";

const store = useStore();
const router = useRouter();

const imageUrl = ref("");
const imageData = ref("");
const isProFileImageUploading = ref(false);
const updatedUserInfo = ref({
  profileImage: "",
  workstationName: "",
  aliasEmail: "",
});
const manageEmailList = ref([]);
const fileUploadErrorMsg = ref("");
const isWorkStationDetailUpdating = ref(false);

const userWorkStationImage = computed(
  () => store.getters[`${USER_STORE}/userWorkStationImage`]
);
const internalInstance = getCurrentInstance();

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onFileChange = async (event) => {
  fileUploadErrorMsg.value = "";
  const fileInput = event.target;
  if (fileInput.files && fileInput.files.length > 0) {
    try {
      const file = fileInput.files[0];
      imageUrl.value = URL.createObjectURL(file);
      imageData.value = file;
      const formData = new FormData();
      formData.append("file", imageData.value);
      const uploadImg = await store.dispatch(
        `${USER_STORE}/uploadProfileImage`,
        formData
      );
      updatedUserInfo.value.profileImage = uploadImg.attachment;
      isProFileImageUploading.value = true;
    } catch (error) {
      console.log("error", error);
      fileUploadErrorMsg.value = error?.response?.data?.message;
    } finally {
      // isProFileImageUploading.value = false;
    }
  } else {
    console.error("Error: No file selected.");
  }
};

const clearImageUrl = () => {
  imageUrl.value = "";
};

const onClickSaveButton = async () => {
  const userBusinessDetails = user.value?.userBusinessDetails;

  delete userBusinessDetails.dateUpdated;
  delete userBusinessDetails.dateCreated;

  try {
    isWorkStationDetailUpdating.value = true;
    const payload = {
      name: updatedUserInfo.value.workstationName,
    };
    if (updatedUserInfo.value.profileImage) {
      payload.profileImage = updatedUserInfo.value.profileImage;
    }
    if (
      updatedUserInfo.value.profileImage ||
      updatedUserInfo.value.workstationName !==
        activeUserWorkstation.value?.name
    ) {
      await UserService.updateUserWorkstation(
        user.value?.id,
        activeUserWorkstation.value?.id,
        payload
      );
    }

    if (
      updatedUserInfo.value.workstationName &&
      updatedUserInfo.value.workstationName !== "" &&
      updatedUserInfo.value.aliasEmail &&
      updatedUserInfo.value.aliasEmail !== ""
    )
      await UserService.updateUserWorkstationAlias(
        user.value?.id,
        activeUserWorkstation.value?.id,
        {
          name: updatedUserInfo.value.workstationName,
          newAliasEmail: updatedUserInfo.value.aliasEmail.toString(),
        }
      );

    displayToast("WorkStation Updated Successfully...", "success");
    store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
  } catch (error) {
    console.log("error", error);
    displayToast(error?.response?.data?.message, "error");
  } finally {
    isWorkStationDetailUpdating.value = false;
    isProFileImageUploading.value = false;
  }
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};
const setManageEmailList = async () => {
  try {
    const response = await manageEmailService.getEmailList(user.value?.id);
    manageEmailList.value = response?.aliases
      .filter((response) => response.invitationStatus !== "pending")
      .map((response) => ({ ...response }));

    manageEmailList.value = [
      { aliasEmail: response?.email, id: -1 },
      ...manageEmailList.value,
    ];
    console.log(response, "response");
  } catch (error) {
    console.error("get email list error", error);
  }
};

const onValueChange = (newValue) => {
  isProFileImageUploading.value = true;
};
onBeforeMount(async () => {
  if (user.value?.id) {
    await setManageEmailList();
  }
  if (activeUserWorkstation.value) {
    updatedUserInfo.value.workstationName = activeUserWorkstation.value?.name;
    updatedUserInfo.value.aliasEmail =
      activeUserWorkstation.value?.aliasEmail?.id || -1;
    try {
      // isProFileImageUploading.value = true;
      const imageApiUrl = getImageApiUrl(
        activeUserWorkstation.value?.profileImage,
        true
      );
      const encoded = await $axios.get(imageApiUrl);
      imageUrl.value = encoded.publicUrl;
    } catch (error) {
      return null;
    } finally {
      // isProFileImageUploading.value = false;
    }
  } else {
    await router.push({ name: "dashboard" });
  }
});
</script>
<style lang="scss" scoped></style>
