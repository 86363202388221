<template>
  <v-dialog
    width="570px"
    max-width="100%"
    v-model="isShowModel"
    class="invite__modal__box"
    persistent
  >
    <div class="modal_wrapper">
      <main class="main">
        <div>
          <div class="modal-header">
            <h2 class="modal-title">
              Invite a team-mate to your iknowa platform
            </h2>
            <p>Add a personalised message.</p>
          </div>
          <v-btn class="close-icon" variant="text">
            <v-icon icon="mdi-close" @click="onCloseForm"></v-icon>
          </v-btn>
          <v-form @submit.prevent :rules="validation" ref="memberFormRef">
            <div class="input__form">
              <label>Email Addresses:</label>
              <v-text-field
                density="compact"
                :rules="validation.email"
                placeholder="enter email address"
                v-model="memberForm.email"
                variant="outlined"
                class="c-input-border rounded-8px"
              ></v-text-field>
              <p>
                You can add multiple email addresses using commas to separate
              </p>
            </div>
            <div class="input__form">
              <label>Message (Optional): </label>
              <v-textarea
                class="c-input-border rounded-8px textarea__wrapper"
                v-model="memberForm.message"
                variant="outlined"
              ></v-textarea>
              <p v-if="errorMessage" class="">{{ errorMessage }}</p>
            </div>

            <div class="bottom-btn">
              <v-btn class="button button-orange" @click="onClickSendInvitation"
                >Send Invitation</v-btn
              >
            </div>
          </v-form>
        </div>
      </main>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
.invite__modal__box {
  .modal_wrapper {
    padding: 24px;
    background-color: rgba($white, 1);
    border-radius: 8px;
    .main {
      position: relative;
      .modal-header {
        .modal-title {
          @include fluidFont(20, 20, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.15px;
        }
        p {
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 0.5);
          letter-spacing: 0.25px;
          margin-top: 8px;
        }
      }
    }
    .close-icon {
      position: absolute;
      padding: 0;
      right: 0;
      top: 0;
      min-width: 20px;
      height: 20px;
    }
    .v-form {
      label {
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
        letter-spacing: 0.25px;
        margin-bottom: 8px;
        display: inline-block;
      }
      p {
        @include fluidFont(12, 12, 1.2);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.25px;
        margin-top: 5px;
      }

      .input__form {
        margin-top: 24px;
        .textarea__wrapper {
          padding-bottom: 24px;
          border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
        }
      }
      .bottom-btn {
        margin-top: 24px;
      }
    }
  }
}
</style>

<script lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
export default {
  props: {
    onCloseForm: {
      type: Function,
    },
    isInviteProject: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const isShowModel = true;
    const store = useStore();
    const memberFormRef = ref(null) as any;
    const errorMessage = ref("");
    const memberForm = ref({
      email: "",
      message: "",
      role: "Admin",
    }) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const memberPermissions = ref();
    const validation = ref({
      email: [
        (v: string) => !!v || "Email is required",
        (v: string) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email. Try again.",
      ],
    });

    const onClickSendInvitation = async () => {
      try {
        const isFormValid = await memberFormRef.value.validate();
        if (!isFormValid.valid) return;
        if (!props.isInviteProject) {
          const formData = Object.fromEntries(
            Object.entries(memberForm.value).filter(([key, value]) => value)
          );
          await store.dispatch(`${WORKSTATION}/createUserWorkstationMembers`, {
            userId: user.value.id,
            members: [formData],
          });
          await await store.dispatch(
            `${WORKSTATION}/setUserWorkstationMembers`,
            {
              userId: user.value.id,
            }
          );
        }
        props.onCloseForm();
      } catch (error: any) {
        if (error?.response?.status === 400) {
          errorMessage.value = error?.response?.data?.message;
        }
      }
    };

    return {
      isShowModel,
      validation,
      memberFormRef,
      memberForm,
      onClickSendInvitation,
      errorMessage,
    };
  },
};
</script>
