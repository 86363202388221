<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2H10L8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM16 10H14V12C14 12.55 13.55 13 13 13C12.45 13 12 12.55 12 12V10H10C9.45 10 9 9.55 9 9C9 8.45 9.45 8 10 8H12V6C12 5.45 12.45 5 13 5C13.55 5 14 5.45 14 6V8H16C16.55 8 17 8.45 17 9C17 9.55 16.55 10 16 10Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "AddMiniFolderIcon",
};
</script>
