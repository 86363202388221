import $axios from "../utils/axios-api-config";

export default {
  getUserCommentsRooms(userId: number) {
    return $axios.get(`/user-comments/${userId}`);
  },

  createUserComments(payload: any) {
    return $axios.post(`/user-comments`, payload);
  },
  createUserCommentsRoom(payload: any) {
    return $axios.post(`/user-comments/comment-room`, payload);
  },

  getUserCommentsRoomByProjectId(
    projectId: number,
    projectWorkStationId: number,
    workStationId: number
  ) {
    return $axios.get(
      `/user-comments/trade/${projectId}/?projectWorkStationId=${projectWorkStationId}&workStationId=${workStationId}`
    );
  },

  getPropertyOwnerCommentsRoomByProjectId(projectId: number) {
    return $axios.get(`/user-comments/project/${projectId}`);
  },
  updateUserCommentRoomStatus(commentRoomId: number, payload: any) {
    return $axios.put(`/user-comments/status/${commentRoomId}`, payload);
  },
};
