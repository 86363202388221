<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.85219 0.159446C6.74435 0.0246245 6.5675 -0.0301477 6.39926 0.01619C6.23102 0.0625278 6.11025 0.201567 6.08868 0.3701C6.08437 0.408004 5.55376 4.17872 1.25733 10.0602C-0.11874 11.9434 -0.381869 14.3365 0.549879 16.4599C1.32742 18.2318 2.8498 19.5155 4.70529 20C3.77241 19.498 3.06883 18.5383 3.0475 16.9444C3.04321 16.8965 2.8879 14.93 4.66946 12.1932C4.73418 12.0932 4.83338 12.0236 4.94986 12.0018C5.06202 11.98 5.18279 12.0061 5.27771 12.0758C5.32083 12.1063 6.11457 12.6805 6.51574 13.7291C7.05495 12.8111 7.7365 11.223 7.18867 9.67409C7.12396 9.49136 7.18867 9.28685 7.34828 9.17373C7.50788 9.06064 7.71925 9.06933 7.87022 9.19117C7.91769 9.23031 12.4859 13.0721 10.424 17.6971C10.3982 17.7653 9.82313 19.1893 8.48473 19.9572C11.6586 19.1102 14 16.2779 14 12.9124C14 9.00692 7.14552 0.517548 6.85219 0.159446Z"
      :fill="fill"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#FF0004",
  },
});
</script>

<script>
export default {
  name: "GasIcon",
};
</script>
