<template>
  <ChipState
    class="tw-cursor-pointer"
    label="Delete"
    textColor="#FFF"
    bgColor="#F45454"
  >
    <template #prefix>
      <TrashIcon fill="#FFF" fillOpacity="1" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
</script>
<script>
export default {
  name: "DeleteActionChip",
};
</script>
