<template>
  <div>
    <section class="tw-flex tw-flex-col tw-w-full tw-gap-4">
      <div
        class="tw-flex-row-between tw-border-b-solid-custom tw-pb-7 tw-pl-0 tw-box-border"
      >
        <v-btn
          class="back_button"
          @click.prevent="() => $emit('on-back')"
          variant="text"
          prepend-icon="mdi-chevron-left"
          >Back</v-btn
        >

        <Button
          label="Verify the project"
          class="!tw-max-w-[316px] md:!tw-hidden"
          @click="onNext"
        />
      </div>
    </section>

    <main
      v-if="!isLoading"
      class="tw-h-full tw-w-full tw-mt-6"
      :class="{
        'tw-grid tw-grid-cols-3 lg:tw-grid-cols-1':
          state.screenType === ESTIMATE_FORM && !isShowGantt,
      }"
    >
      <section
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-col-span-2 md:tw-py-4 md:tw-border-b md:tw-border-b-[#C5D2F2] md:tw-border-solid md:tw-border-x-0 md:tw-border-t-0"
      >
        <div
          class="tw-flex tw-flex-col tw-gap-4 tw-pb-4 tw-border-b tw-border-b-[#C5D2F2] tw-border-solid tw-border-x-0 tw-border-t-0 tw-items-start"
        >
          <Text variant="h4">{{ historicalProjectForm?.title }}</Text>
          <div class="tw-flex tw-items-center tw-gap-4">
            <StandardChip />
            <CommercialChip />
            <Postcode :value="projectProperty?.property?.postcode" />
          </div>
        </div>

        <HistoricalEstimateFormScreen
          :estimateForm="estimateForm"
          :showTimeLineButton="true"
          @on-toggle-gantt="onToggleGantt"
        />
      </section>
      <section
        v-if="state.screenType === ESTIMATE_FORM && !isShowGantt"
        class="tw-pl-9 tw-box-border lg:tw-pl-0 md:tw-pt-4"
      >
        <EstimateSummaryCard
          :isProperty="true"
          :project="jobDetails"
          :projectEstimateId="formData.id"
          @on-save-estimation="debouncedSaveEstimation"
        />
      </section>
    </main>
    <div v-else class="tw-h-full tw-w-full">
      <CommonLoader :loading="isLoading" />
    </div>

    <GanttChart
      v-if="isShowGantt && getGanttChartEstimateFormData"
      :isEditable="false"
      :estimateData="getGanttChartEstimateFormData"
      :isShowAllPhase="true"
    />
    <ConfirmVerifyModal
      v-if="isConfirmSaveModal"
      @on-close="onClickToggleConfirmSave"
      @on-confirm="onSaveProject"
    />

    <VerifyStepperModal
      v-if="isPostLoadingModal"
      :projectId="jobDetails?.id"
      @on-success="onSaveSuccess"
    />
  </div>
</template>
<script setup>
import { computed, onBeforeMount, reactive } from "vue";
import HistoricalEstimateFormScreen from "@/modules/jobs/components/screens/HistoricalEstimateFormScreen.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { ref, onMounted } from "vue";
import {
  AgreeTermsConsumerDisclosure,
  ContractorRequestStatusEnum,
} from "@/core/enums/estimateEnum";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import CommonLoader from "@/core/components/CommonLoader.vue";
import EstimateSummaryCard from "@/modules/jobs/components/estimates/EstimateSummaryCard.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { getTotalDuration } from "@/core/utils/common";
import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import { ElMessage } from "element-plus";
import VerifyStepperModal from "@/modules/verify-historical-projects/components/model/VerifyStepperModal.vue";
import ConfirmVerifyModal from "@/modules/verify-historical-projects/components/model/ConfirmVerifyModal.vue";
import GanttChart from "@/modules/jobs/components/estimates/GanttChart.vue";
import moment from "moment";
const store = useStore();
const isShowGantt = ref(false);
const emits = defineEmits(["redirectTab"]);
const props = defineProps({
  jobDetails: {
    type: Object,
    value: null,
  },
  projectProperty: {
    type: Object,
    value: null,
  },
});
const ESTIMATE_FORM = "estimate-form";
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const state = reactive({
  hideBanner: false,
  saveTemplateModal: false,
  contractorInvitationModal: false,
  screenType: ESTIMATE_FORM,
});
const isLoading = ref(false);
const isConfirmSaveModal = ref(false);
const isPostLoadingModal = ref(false);

const receiveInvitationList = ref([]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onToggleGantt = (event) => {
  isShowGantt.value = event;
};
const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];
  if (
    estimates &&
    estimates.projectStages.length &&
    !estimates.projectStages[0].user
  ) {
    estimates.projectStages[0].user = user.value;
  }
  if (
    estimates &&
    estimates.projectStages.length &&
    !estimates.projectStages[0].workStation
  ) {
    estimates.projectStages[0].workStation = activeUserWorkstation.value;
  }

  return estimates;
});
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const projectPayload = () => {
  let estimationPhaseData = [];
  if (estimateForm.value) {
    estimateForm.value.projectStages.map((estimate, index) => {
      const estimateRecord = {
        id: estimate.id,
        phaseName: estimate.name,
        phaseType: estimate.stageType === "labour" ? 1 : 2,
        durationType: estimate.durationType,
        phaseStartDate: estimate.startDate,
        phaseEndDate: estimate.endDate,
        vat: estimate.vat,
        subTotal:
          typeof estimate.subTotal === "number"
            ? estimate.subTotal.toString()
            : estimate.subTotal,
        phaseOrder: estimate.phaseOrder,
        estimationPhaseTasks: [],
        estimationPhaseProperties: estimate.estimationPhaseProperties,
        color: estimate.color,
        user: { id: estimate?.user?.id },
        workStation: { id: estimate?.workStation?.id },
        // estimatePhaseAttachment: [],
      };

      if (estimate?.projectStageTasks && estimate?.projectStageTasks?.length) {
        estimate.projectStageTasks.map((task) => {
          const estimateTask = {
            id: task?.id,
            name: task.name,
            phaseTaskStartDate: task.startDate,
            phaseTaskEndDate: task.endDate,
            durationType: task.durationType,
            taskCost:
              typeof task.taskCost === "number"
                ? task.taskCost.toString()
                : task.taskCost,
            estimationPhaseTasksProperties: task.estimationPhaseTasksProperties,
            materialUnit: task.materialUnit?.toString(),
          };

          estimateRecord.estimationPhaseTasks.push(estimateTask);
        });
      }
      estimationPhaseData.push(estimateRecord);
    });
  }
  const payload = {
    id: formData?.value.id,
    grantType: formData?.value.grantType,
    grantName: formData?.value.grantName,
    grantAmount: formData?.value.grantAmount,
    suggestedStartDate: formData?.value.suggestedStartDate,
    estimationPhaseData,
  };
  return payload;
};
const getEstimateData = computed(() => props.jobDetails?.projectEstimation[0]);
const setEstimateData = async () => {
  const estimationData = props.jobDetails?.projectEstimation[0];
  if (estimationData) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/setFormData`, {
      suggestedStartDate: estimationData?.suggestedStartDate,
      grantType: estimationData?.grantType,
      grantName: estimationData?.grantName,
      grantAmount: estimationData?.grantAmount,

      id: estimationData?.id,
    });

    const estimateForm = {
      projectStages: [],
    };
    if (estimationData?.estimationPhase.length) {
      estimationData?.estimationPhase?.forEach((phase) => {
        const estimatePhaseRecord = {
          id: phase?.id,
          stageType:
            phase.phaseType === ProjectPhaseType.LABOUR
              ? "labour"
              : "materials",
          subTotal: phase?.subTotal,
          phaseOrder: phase?.phaseOrder,
          phaseStatus: phase?.phaseStatus,
          name: phase?.name,
          durationType: phase?.durationType,
          startDate: phase?.phaseStartDate,
          endDate: phase?.phaseEndDate,

          totalDuration: getTotalDuration(
            phase?.phaseStartDate,
            phase?.phaseEndDate,
            phase?.durationType
          ),
          vat: phase?.vat,
          projectStageTasks: [],
          color: phase?.color,
          estimationPhaseProperties: [],
          assignPhaseWorkStation: phase?.assignPhaseWorkStation,
          user: phase?.user,
          workStation: phase?.workStation,
          attachments: phase?.estimatePhaseAttachment,
          acceptedBy: phase?.acceptedBy,
        };
        phase?.estimationPhaseTasks.forEach((subTask) => {
          const estimatePhaseSubTaskRecords = {
            id: subTask?.id,
            name: subTask?.name,
            taskCost: subTask?.taskCost && subTask?.taskCost.toString(),
            durationType: subTask?.durationType,
            startDate: subTask?.phaseTaskStartDate,
            endDate: subTask?.phaseTaskEndDate,
            duration: getTotalDuration(
              subTask?.phaseTaskStartDate,
              subTask?.phaseTaskEndDate,
              subTask?.durationType
            ),
            materialUnit: subTask?.materialUnit,
            estimationPhaseTasksProperties: [],
            assignedPhaseTo: subTask?.assignedPhaseTo,
          };
          subTask.estimationPhaseTasksProperties.forEach((property) => {
            estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
              id: property.id,
              propertyId: property.property.id,
            });
          });
          estimatePhaseRecord.projectStageTasks.push(
            estimatePhaseSubTaskRecords
          );
        });
        estimateForm.projectStages.push(estimatePhaseRecord);
        phase.estimationPhaseProperties?.forEach((property) => {
          estimatePhaseRecord?.estimationPhaseProperties?.push({
            id: property.id,
            propertyId: property.property.id,
          });
        });
      });
      store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimateForm`, estimateForm);
    } else {
      store.dispatch(`${JOB_TEMPLATE_STORE}/resetFormAndData`);
    }
  }
};
const getGanttChartEstimateFormData = computed(() => {
  const estimationPhase = estimateForm.value.projectStages.map(
    (phase, index) => ({
      ...phase,
      phaseStartDate: phase?.startDate,
      phaseEndDate: phase?.endDate,
      id: phase.id || index + 1,
      phaseType: phase.stageType === "labour" ? 1 : 2,
      estimationPhaseTasks: phase.projectStageTasks.map((subTask, idx) => ({
        ...subTask,
        id: subTask.id || idx + 1,
        phaseTaskStartDate: subTask.startDate,
        phaseTaskEndDate: subTask.endDate,
      })),
    })
  );
  return {
    estimationPhase,
    projectEstimateEndDate: projectEstimateEndDate.value,
    ...formData.value,
  };
});
const isFormError = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);
const projectEstimateEndDate = computed(() =>
  estimateForm.value.projectStages
    .filter((phase) => phase.stageType === "labour")
    .reduce((endDate, phase) => {
      return (endDate = moment(endDate).isAfter(moment(phase?.endDate))
        ? moment(endDate).format("YYYY-MM-DD")
        : moment(phase?.endDate).format("YYYY-MM-DD"));
    }, "")
);
const onClickToggleConfirmSave = () => {
  isConfirmSaveModal.value = !isConfirmSaveModal.value;
};
const onNext = async () => {
  const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  if (
    isValid &&
    !isFormError.value.startDate &&
    !isFormError.value.validationError
  ) {
    onClickToggleConfirmSave();
  } else {
    ElMessage.error(
      isFormError.value.validationError
        ? `validation failed`
        : `please fill out all fields`
    );
  }
};

const onSaveSuccess = () => {
  onClickTogglePostLoadingModal();
  emits("redirectTab", "Verified");
};
const onSaveProject = async () => {
  try {
    const payload = {
      propertyId: props.projectProperty?.property?.id,
      projectJobStatusId: "0",
      updateProjectId: props.jobDetails?.id,
      description: props.jobDetails?.description,
      name: props.jobDetails?.name,
      historicalProjectType: 1,
      projectEstimate: projectPayload(),
    };
    await store.dispatch(`${JOBS_STORE}/createHistoricalJob`, payload);
    onClickTogglePostLoadingModal();
  } catch (error) {
    console.log("error", error);
  } finally {
    onClickToggleConfirmSave();
  }
};
const onClickTogglePostLoadingModal = () => {
  isPostLoadingModal.value = !isPostLoadingModal.value;
};
onBeforeMount(() => {
  setEstimateData();
});
</script>
<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
.back_button {
  text-transform: capitalize !important;
  padding: 0;
  background-color: white;
  border-radius: 50px;
  height: auto;
  .v-btn__prepend {
    margin: 0;
  }
  :deep(.v-btn__content) {
    @include fluidFont(12, 12, 2);
    font-weight: 600;
    color: rgba($blueDark, 1);
    letter-spacing: 0.4px;
  }
}
</style>
