<template>
  <div class="tw-flex tw-items-center tw-gap-4">
    <!-- <Text
                variant="span"
                whiteSpace="nowrap"
                textWeight="600"
                textColor="rgba(12, 15, 74, 0.5)"
                >You haven’t added any properties yet</Text
              > -->
    <AddressItem
      :showAddAction="true"
      :phaseIndex="phaseIndex"
      @on-save-project-properties="saveProperties"
    />
  </div>
</template>
<script setup>
import AddressItem from "@/modules/project/components/common/AddressItem.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";

const props = defineProps({
  phaseIndex: Number,
});
const emits = defineEmits([" on-save-phase-properties"]);
const saveProperties = (payload) => {
  emits("on-save-phase-properties", payload);
};
</script>
<style lang="scss" scoped></style>
