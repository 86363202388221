<template>
  <div class="tw-w-full tw-text-secondary">
    <div>
      <div
        class="tw-min-h-[100dvh] tw-p-6 tw-py-8 rmd:p-8 tw-flex tw-flex-col"
        :class="
          currentSection === sectionList.CONTROL_CENTER
            ? '!tw-bg-white !tw-p-4 rmd:!tw-p-8'
            : 'tw-bg-on-boarding-bg'
        "
      >
        <div
          class="tw-tw-text-left"
          :class="
            currentSection === sectionList.CONTROL_CENTER
              ? '!tw-pb-8 tw-border-b tw-border-solid tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-b-[#C5D2F2] tw-mb-10 rsm:!tw-mb-11 '
              : 'tw-mb-8 rsm:tw-mb-10 '
          "
        >
          <a
            href="#"
            @click="gotoLoginPage"
            class="tw-max-w-[178px] tw-w-full tw-block"
          >
            <img
              src="@/assets/images/iknowa-logo.svg"
              alt="Logo"
              class="tw-w-full tw-h-auto tw-object-contain"
            />
          </a>
        </div>
        <div
          class="tw-flex tw-flex-auto tw-justify-center tw-w-full tw-text-[#0C0F4A]"
          :class="
            currentSection === sectionList.CONTROL_CENTER
              ? 'tw-items-start'
              : 'tw-items-center'
          "
        >
          <SingleInputField
            v-if="currentSection === sectionList.FIRST_NAME"
            v-model="queryParams.userName"
            heading="Let's get you started! 🚀
          Enter your first name below."
            label="First Name"
            @onNext="(value) => onNextClick(sectionList.FIRST_NAME, value)"
            :isShowLoginBtn="true"
            :rules="userNameRule"
          />

          <TradeCompanySelect
            v-if="currentSection === sectionList.USER_TYPE"
            :userName="queryParams.userName"
            header="How are you planning to use iknowa?"
            @onContinue="(value) => onNextClick(sectionList.USER_TYPE, value)"
            :selectionOptions="userTypes"
          />

          <BoardingControlCenter
            v-if="currentSection === sectionList.CONTROL_CENTER"
            @onNext="(value) => onNextClick(sectionList.CONTROL_CENTER, value)"
            :details="queryParams"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BoardingControlCenter from "../components/BoardingControlCenter.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { RolesEnum } from "@/core/enums/RolesEnum";
import { GET_START_BOARDING_ROUTE } from "@/modules/get-start-boarding/routes";
import SingleInputField from "@/modules/get-start-boarding/components/SingleInputField.vue";
import TradeCompanySelect from "@/modules/get-start-boarding/components/TradeCompanySelect.vue";
import OnboardingTradesIcon from "@/core/components/icons/OnboardingTradesIcon.vue";
import OnboardingPropertyIcon from "@/core/components/icons/OnboardingPropertyIcon.vue";
import { LOGIN_ROUTE } from "@/modules/login/routes";
const router = useRouter();
const sectionList = {
  FIRST_NAME: "firstName",
  USER_TYPE: "userType",
  CONTROL_CENTER: "controlCenter",
};
const userTypes = [
  {
    id: 1,
    component: OnboardingPropertyIcon,
    title: "As a Property Owner",
    description:
      "Receive property insights & start retrofit projects with ease.",
    value: RolesEnum.PROJECT_OWNER,
  },
  {
    id: 1,
    component: OnboardingTradesIcon,
    title: "As a Trade Contractor",
    description: "Access up-skilling course & find new jobs",
    value: RolesEnum.TRADESPERSON,
  },
];
const userNameRule = [
  (v) => !!v?.trim() || "First Name is required",
  (v) =>
    /^[a-zA-Z]+[a-zA-Z0-9\s'.,-]*$/.test(v) ||
    "Invalid format. Must start with a letter and can include numbers (not at the beginning), spaces, and valid special characters.",
  (v) => v.length <= 20 || "First Name must be less than 20 characters",
];
const queryParams = ref({
  userName: "",
  role: 0,
});
const currentSection = ref(sectionList.FIRST_NAME);
const onNextClick = (type, value) => {
  if (type === sectionList.FIRST_NAME && queryParams.value.userName) {
    currentSection.value = sectionList.USER_TYPE;
  } else if (type === sectionList.USER_TYPE && value) {
    queryParams.value.role = value;
    currentSection.value = sectionList.CONTROL_CENTER;
  } else if (type === sectionList.CONTROL_CENTER) {
    if (value === "createWs") {
      router.push({
        name: GET_START_BOARDING_ROUTE,
        query: {
          startBoarding: encodeURIComponent(JSON.stringify(queryParams.value)),
        },
      });
    }
  }
};
const gotoLoginPage = () => {
  router.push({ name: LOGIN_ROUTE });
};
</script>
