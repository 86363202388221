<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_383_53912)">
      <path
        d="M5.74995 3.76825C7.55441 3.76825 9.19079 4.75549 10.3983 6.35902C10.4968 6.4904 10.4968 6.67394 10.3983 6.80531C9.19079 8.41078 7.55441 9.39802 5.74995 9.39802C3.94548 9.39802 2.3091 8.41078 1.10162 6.80724C1.00309 6.67587 1.00309 6.49233 1.10162 6.36096C2.3091 4.75549 3.94548 3.76825 5.74995 3.76825ZM5.6205 8.56534C6.81833 8.64068 7.8075 7.65345 7.73215 6.45369C7.67033 5.46452 6.86856 4.66275 5.87939 4.60093C4.68157 4.52558 3.6924 5.51282 3.76774 6.71257C3.8315 7.69981 4.63327 8.50158 5.6205 8.56534ZM5.68039 7.64958C6.32567 7.69015 6.8589 7.15886 6.81639 6.51358C6.78355 5.98036 6.35079 5.54953 5.81756 5.51475C5.17229 5.47418 4.63906 6.00547 4.68157 6.65075C4.71634 7.18591 5.1491 7.61674 5.68039 7.64958Z"
        fill="#0C0F4A"
        fill-opacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_383_53912">
        <rect
          width="9.44444"
          height="9.44444"
          fill="white"
          transform="matrix(-1 0 0 1 10.4722 1.86099)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "EyeIcon",
};
</script>
