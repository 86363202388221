<template>
  <CommonDialog
    class="estimate_details_modal"
    title="Your Digital Details"
    width="365px"
    max-width="100%"
    :onClickBackButton="onClickBackButton"
    :isShowHeader="false"
  >
    <template v-slot:body>
      <CommonLoader :loading="loading" />
      <div class="user_details" v-if="!loading && details">
        <ul>
          <li>
            <p>User ID :</p>
            <span>{{ details?.user?.id }}</span>
          </li>
          <li>
            <p>User Name :</p>
            <span>{{ details?.user?.firstName }}</span>
          </li>
          <li>
            <p>IP Address:</p>
            <span>{{ details?.ipAddress }}</span>
          </li>
          <li>
            <p>MAC Address:</p>
            <span>{{ details?.macAddress }}</span>
          </li>
        </ul>
      </div>
      <div
        class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3 tw-mt-6"
      >
        <Button
          variant="tertiary"
          label="Close"
          class="button button-orange"
          @click="onClickBackButton"
        />
      </div>
    </template>
  </CommonDialog>
</template>
<script setup lang="ts">
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { defineEmits, onMounted, defineProps, ref } from "vue";

const emits = defineEmits(["on-close", "on-save"]);
const props = defineProps({
  projectEstimateId: {
    type: Number,
  },
});
const onClickBackButton = () => {
  emits("on-close");
};
const details = ref(null);
const loading = ref(false);
onMounted(async () => {
  try {
    loading.value = true;
    const response = await tradeEstimateService.getEstimateDigitalSignature(
      props.projectEstimateId
    );

    if (response) details.value = response.projectEstimate;
  } catch (error) {
    console.log("error", error);
  } finally {
    loading.value = false;
  }
});
</script>
