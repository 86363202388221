<template>
  <div
    class="contractor-detail-card lg:!tw-w-[calc(50%_-_8px)] lg:!tw-max-w-full !tw-rounded-lg !tw-rounded-l-lg !tw-rounded-r-lg !tw-w-[340px] tw-max-w-full !tw-rounded-bl-lg !tw-rounded-br-lg md:!tw-w-full"
  >
    <v-expansion-panel class="!tw-rounded-lg">
      <v-expansion-panel-title v-slot="{ expanded }" class="tw-p-4">
        <section class="tw-flex tw-items-start tw-justify-center tw-gap-4">
          <ProfileThumbnail :src="imageUrl" />
          <v-fade-transition leave-absolute>
            <div v-if="!expanded" class="tw-flex tw-flex-col">
              <Text whiteSpace="nowrap" variant="h6">{{
                subContractor?.toContractorWorkstation?.name
              }}</Text>
              <Text
                variant="span"
                textColor="rgba(12, 15, 74, 0.5)"
                textWeigth="600"
                >Limited Company</Text
              >
            </div>
          </v-fade-transition>
        </section>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="tw-p-4">
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2">
          <Text whiteSpace="nowrap" variant="h6">{{
            subContractor?.toContractorWorkstation?.name
          }}</Text>
          <div class="tw-flex tw-items-center tw-justify-start">
            <Text
              variant="span"
              textColor="rgba(12, 15, 74, 0.5)"
              textWeigth="600"
              >Limited Company</Text
            >
            <Postcode :value="subContractor?.toUser?.postcode" />
            <ChipState :hasCustomLabel="true">
              <template #prefix>
                <RatingIcon />
              </template>
              <template #custom-label>
                <Text variant="span" textWeight="400">
                  {{ subContractor?.ratings }}/5 ({{
                    subContractor?.jobcompleted
                  }}
                  Jobs)
                </Text>
              </template>
            </ChipState>
          </div>
          <div class="tw-flex tw-items-center tw-justify-start tw-gap-2">
            <ChipState :hasCustomLabel="true" bgColor="rgba(12, 15, 74, 0.04)">
              <template #prefix>
                <CheckGreen class="tw-w-[16px] tw-h-[16px]" />
              </template>
              <template #custom-label>
                <Text variant="span" textWeight="400">
                  <span class="!tw-text-[#1FB27A] !tw-font-bold">
                    {{
                      subContractor?.toUser?.experience
                        ? `${subContractor?.toUser?.experience} + Yrs `
                        : "N/A"
                    }}
                  </span>
                  Exp.
                </Text>
              </template>
            </ChipState>
            <CustomBadge v-if="displayedSkills && displayedSkills.length">
              <!-- <v-icon icon="mdi-check" color="green" /> -->
              <span
                class="tw-text-[12px] tw-font-[700] tw-font-[#0C0F4A]"
                v-for="skill in getUserSkillList(displayedSkills)"
                :key="skill.id"
              >
                {{ skill?.label }}
              </span>
            </CustomBadge>
            <span v-if="remainingCount > 0"> +{{ remainingCount }} </span>
          </div>
        </div>
        <v-divider class="tw-mt-2 tw-mb-2"></v-divider>
        <v-card-actions class="tw-border-t-2">
          <div class="tw-w-full tw-flex tw-items-center tw-justify-end">
            <Button
              class="!tw-w-auto !tw-px-0 !tw-py-0 tw-box-border !tw-text-[#4B4BFF] !tw-capitalize !tw-font-normal !tw-tracking-[0.15px]"
              variant="p"
              label="View Trade Passport"
            >
              <template #postfix>
                <v-icon icon="mdi-chevron-right"></v-icon>
              </template>
            </Button>
          </div>
        </v-card-actions>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </div>
</template>
<script lang="ts" setup>
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import RatingIcon from "@/core/components/icons/RatingIcon.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import CountChip from "@/core/uni-components/Chips/CountChip.vue";

import $axios from "@/core/utils/axios-api-config";
import { onMounted, ref, computed } from "vue";
import { getImageApiUrl, getUserSubSkillList } from "@/core/utils/common";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
const props = defineProps({
  subContractor: {
    type: Object,
  },
});
const imageUrl = ref(null);
const displayedSkills = computed(() => {
  return props.subContractor?.toUser?.user?.userSkills.slice(0, 2);
});

const remainingCount = computed(() => {
  return props.subContractor?.toUser?.user?.userSkills.length - 2;
});
const store = useStore();
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};

onMounted(async () => {
  console.log("subcontractors", props.subContractor);
  try {
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }
    const imageApiUrl = getImageApiUrl(
      props?.subContractor?.toContractorWorkstation?.profileImage,
      true
    );
    const encoded = await $axios.get(imageApiUrl);
    imageUrl.value = encoded.publicUrl;
  } catch (error) {
    console.log();
  }
});
</script>
<script lang="ts">
export default {
  name: "ContractorDetailCard",
};
</script>
<style lang="scss" scoped>
.contractor-detail-card {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08) !important;

  :deep(.v-expansion-panel__shadow) {
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  }
}
.v-expansion-panel-title {
  .v-expansion-panel-title__overlay {
    opacity: 0;
  }
}
:deep(.v-expansion-panel-text) {
  .v-expansion-panel-text__wrapper {
    padding: 0;
    .v-card-actions {
      padding: 0;
      min-height: auto;
    }
  }
}
:deep(.v-btn:hover) > .v-btn__overlay {
  opacity: 0;
}
.v-btn {
  :deep(.v-btn__content) {
    height: auto;
  }
}

:deep(.v-expansion-panel-title):hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}
</style>
