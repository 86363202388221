<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#EB7945" />
    <g clip-path="url(#clip0_7396_73939)">
      <path
        d="M11.3333 8.83301C10.6 8.83301 10 9.43301 10 10.1663L10.0067 12.2863C10.0067 12.6397 10.1467 12.973 10.3933 13.2263L12.6667 15.4997L10.3933 17.7863C10.1467 18.033 10.0067 18.373 10.0067 18.7263L10 20.833C10 21.5663 10.6 22.1663 11.3333 22.1663H16.6667C17.4 22.1663 18 21.5663 18 20.833V18.7263C18 18.373 17.86 18.033 17.6133 17.7863L15.3333 15.4997L17.6067 13.233C17.86 12.9797 18 12.6397 18 12.2863V10.1663C18 9.43301 17.4 8.83301 16.6667 8.83301H11.3333ZM16.6667 18.773V20.1663C16.6667 20.533 16.3667 20.833 16 20.833H12C11.6333 20.833 11.3333 20.533 11.3333 20.1663V18.773C11.3333 18.593 11.4067 18.4263 11.5267 18.2997L14 15.833L16.4733 18.3063C16.5933 18.4263 16.6667 18.5997 16.6667 18.773Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7396_73939">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(6 7.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "AwaitingEstimates",
};
</script>
