import { ConnectionState } from "@/core/models/connections";

export default {
  setUserConnections(state: ConnectionState, payload: Array<any>) {
    state.userConnections.allConnection = payload;
  },
  setUserFilteredConnection(state: ConnectionState, payload: Array<any>) {
    state.userConnections.filterConnection = payload;
  },
  setPropertyUserConnection(state: ConnectionState, payload: Array<any>) {
    state.tradeUserConnectionList = payload;
  },
  setPreferredContractorsCategory(state: ConnectionState, payload: Array<any>) {
    state.contractorsCategoryList = payload;
  },
  setPropertyConnectionRole(state: ConnectionState, payload: string | null) {
    state.propertyConnectionRole = payload;
  },
  setPropertyConnections(state: ConnectionState, payload: Array<any>) {
    state.propertyConnections = payload;
  },
  setPropertyOccupiers(state: ConnectionState, payload: Array<any>) {
    state.propertyOccupiers = payload;
  },
};
