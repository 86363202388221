/**
 * Onboarding route page
 */
import { LOGIN_ROUTE } from "@/modules/login/routes";
import OnboardingRoute from "@/modules/onboarding/pages/OnboardingPage.vue";
import SpecilalismsRoute from "@/modules/onboarding/pages/SpecilalismsPage.vue";
import store from "@/store";
import { USER_STORE } from "@/store/modules/user";

export const ONBOARDING_ROUTE = "onboarding";
export const SPECILALISMS_ROUTE = "specilalisms";

export default [
  {
    path: "/onboarding",
    name: ONBOARDING_ROUTE,
    component: OnboardingRoute,
    meta: {
      requiresAuth: true,
      layout: "Authlayout",
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      let user = store.getters[`${USER_STORE}/user`];
      if (!user) {
        user = await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
      if (user && !user.hasAccountSetup) {
        next();
      } else {
        next({ name: LOGIN_ROUTE });
      }
    },
  },
  {
    path: "/specilalisms",
    name: SPECILALISMS_ROUTE,
    component: SpecilalismsRoute,
    meta: {
      requiresAuth: true,
      layout: "Authlayout",
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      let user = store.getters[`${USER_STORE}/user`];
      if (!user) {
        user = await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
      if (user && !user.hasAccountSetup) {
        next();
      } else {
        next({ name: LOGIN_ROUTE });
      }
    },
  },
];
