<template>
  <div
    class="tw-text-[#0C0F4A] tw-text-left tw-max-w-full rsm:tw-max-w-[500px] tw-w-full tw-mx-auto"
  >
    <div class="tw-mb-6">
      <div class="tw-text-[24px] tw-font-semibold tw-mb-4">
        <p>Terms & Conditions</p>
      </div>
      <p>
        Please read through and agree to the terms & conditions to access your
        Iknowa platform.
      </p>
    </div>

    <div class="tw-mb-6 tw-max-w-[90%] tw-w-full">
      <div class="tw-text-[16px] tw-font-semibold tw-mb-2">
        <p>Highlights</p>
      </div>
      <div class="tw-grid tw-gap-2">
        <div class="tw-grid tw-grid-cols-[24px_1fr] tw-gap-2 tw-items-center">
          <v-icon color="#4887FF" class="tw-text-[24px]">
            mdi-check-circle
          </v-icon>
          <p>Last Updated 21 September 2023</p>
        </div>
        <div class="tw-grid tw-grid-cols-[24px_1fr] tw-gap-2 tw-items-center">
          <v-icon color="#4887FF" class="tw-text-[24px]">
            mdi-check-circle
          </v-icon>
          <p>
            Iknowa stores your data in the UK, we will never sell your data or
            share without your consent
          </p>
        </div>
        <div class="tw-grid tw-grid-cols-[24px_1fr] tw-gap-2 tw-items-center">
          <v-icon color="#4887FF" class="tw-text-[24px]">
            mdi-check-circle
          </v-icon>
          <p>We use cookies to give you the best experience</p>
        </div>
      </div>
    </div>

    <div class="tw-mb-5">
      <div class="tw-text-[18px] tw-font-semibold tw-mb-2">
        <p>General Platform Usage</p>
      </div>
      <p>
        Iknowa is a comprehensive platform designed to connect property owners
        and trade professionals. It facilitates project management, contractor
        hiring, and seamless communication. The platform ensures secure
        transactions, real-time updates, and tailored solutions to streamline
        property-related projects from inception to completion.
      </p>
    </div>

    <v-checkbox class="checkbox__btn tw-mb-6" v-model="isSelectAgree">
      <template v-slot:label>
        <div class="tw-grid tw-grid-cols-[18px_1fr] tw-items-start tw-gap-2">
          <v-icon v-if="isSelectAgree" color="#FFA500">
            mdi-check-circle
          </v-icon>
          <v-icon v-else color="#0C0F4A80"> mdi-circle-outline </v-icon>
          <p>
            I have read & agree to the

            <a
              href="https://iknowa.com/pdf/MFSL-Introduced-Client-Terms.pdf"
              class="tw-text-[#FFA500] tw-no-underline hover:tw-underline"
            >
              Terms of Service</a
            >
          </p>
        </div>
      </template>
    </v-checkbox>

    <Button
      class="w-full"
      label="Agree & Continue"
      :disabled="!isSelectAgree"
      @click="nextStep"
    ></Button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Button from "@/core/components/ui/general/Button.vue";

const isSelectAgree = ref(false);
const emits = defineEmits(["onNext"]);
const nextStep = () => {
  emits("onNext");
};
</script>

<style scoped lang="scss">
:deep(.v-checkbox-btn) {
  min-height: 1px;

  .v-selection-control__wrapper {
    display: none;
  }

  label {
    width: 100%;
    font-size: 14px;
  }

  .v-icon {
    font-size: 18px;
  }
}
</style>
