<template>
  <div class="auth_wrapper">
    <div
      class="form-container-main"
      :class="{ mobileView: isShowAuthIknowaInfoMobile && isMoblieView }"
    >
      <div class="form-container-default left-block">
        <div class="logo-wrapper">
          <img
            class="open-logo"
            src="../../../assets/images/logo/iknowa-logo-v4.svg"
          />
        </div>
        <div class="form_wrapper">
          <div class="title">
            <h3 class="logo-description default-text">
              Welcome back to iknowa 👋
            </h3>
          </div>
          <LoginFormComponent />
        </div>
      </div>
      <div class="right_block">
        <el-carousel
          indicator-position="outside"
          :autoplay="false"
          arrow="never"
          class=""
          trigger="click"
        >
          <el-carousel-item
            v-for="(item, index) in carouselItem"
            :key="index"
            class=""
          >
            <div class="right_block_content">
              <div class="inner-content">
                <div class="login_img">
                  <img
                    class="open-logo"
                    :src="require(`../../../assets/images/${item?.image}.png`)"
                  />
                </div>
                <div class="login_content">
                  <h2 class="title">{{ item?.title }}</h2>
                  <p class="description">
                    {{ item?.description }}
                  </p>
                  <div class="benifit_points">
                    <div
                      class="benifit_points_list"
                      v-for="(desc, i) in Object.keys(item?.benefits)"
                      :key="i"
                    >
                      <img class="" src="../../../assets/icons/tick.svg" />
                      <p>{{ item?.benefits[desc] }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <AuthIknowaInfoMobileModal
        v-if="isShowAuthIknowaInfoMobile"
        @onCloseButtonClick="onCloseButtonClick"
      />
    </div>
  </div>
</template>
<script lang="ts">
import LoginFormComponent from "@/modules/login/components/LoginFormComponent.vue";
import AuthIknowaInfoMobileModal from "@/core/components/AuthIknowaInfoMobileModal.vue";
import { computed, ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

export default {
  name: "LoginPage",

  components: {
    LoginFormComponent,
    AuthIknowaInfoMobileModal,
  },
  setup() {
    const carouselItem = [
      {
        title: "Soar to new heights with iknowa",
        description:
          "Discover how an iknowa HQ can take your business to the next level:",
        image: "trade-login-image",
        benefits: {
          data1:
            "Helping you secure high-quality building and retrofitting projects:",
          data2:
            "Upskill and improve your green skills without breaking the bank",
          data3: "Win more contracts with our iknowa Verified Trade Passport",
        },
      },
      {
        title: "Soar to new heights with iknowa",
        description:
          "Backed by the New Homes Ombudsman to improve and supercharge the property universe:",
        image: "property-owner-login-image",
        benefits: {
          data1:
            "Enjoy the peace of mind, that comes with an embedded Ombudsman.",
          data2:
            "Level up with seamless property information and insights to add value to your property (Know Your Property - KYP)",
          data3:
            "Build with the best and partner with iknowa Verified Contractors",
        },
      },
    ];
    const vuetify = useDisplay();

    const isMoblieView = computed(() => vuetify.mdAndDown.value);

    const isShowAuthIknowaInfoMobile = ref(false);
    const openOverview = () => {
      isShowAuthIknowaInfoMobile.value = true;
      document.body.classList.add("noScrollModal");
    };
    const onCloseButtonClick = () => {
      isShowAuthIknowaInfoMobile.value = false;
      document.body.classList.remove("noScrollModal");
    };
    return {
      isShowAuthIknowaInfoMobile,
      openOverview,
      onCloseButtonClick,
      isMoblieView,
      carouselItem,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/login/styles/login.scss";
</style>
