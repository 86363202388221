<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6.875" r="6" fill="#4A92E5" />
    <path
      d="M5.37433 9.06211V7.49961H6.62433V9.06211C6.62433 9.23398 6.76495 9.37461 6.93683 9.37461H7.87433C8.0462 9.37461 8.18683 9.23398 8.18683 9.06211V6.87461H8.71808C8.86183 6.87461 8.93058 6.69648 8.8212 6.60273L6.2087 4.24961C6.08995 4.14336 5.9087 4.14336 5.78995 4.24961L3.17745 6.60273C3.0712 6.69648 3.13683 6.87461 3.28058 6.87461H3.81183V9.06211C3.81183 9.23398 3.95245 9.37461 4.12433 9.37461H5.06183C5.2337 9.37461 5.37433 9.23398 5.37433 9.06211Z"
      fill="white"
    />
    <path
      d="M7.7535 4.53125H6.9375L7.875 5.46875V4.66487C7.875 4.59108 7.8206 4.53125 7.7535 4.53125Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "HomeCircleBlue",
};
</script>
