<template>
  <GeneralDialog
    class="tw-max-w-[450px] !tw-w-[450px]"
    @on-close="onCloseModal"
    width="450px"
    max-width="100%"
    borderRadius="20px"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <ContractSignYellowIcon />
        <Text variant="h4" textAlign="center" lineHeight="28px"
          >Please confirm that <br />
          you are happy to Raise this project</Text
        >
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          :disabled="loading"
          label="Cancel"
          @click="onCloseModal"
        />
        <Button
          label="Confirm"
          :isLoading="loading"
          :disabled="loading"
          @click="onConfirmModal"
        />
      </div>

      <div class="tw-text-center">
        <Text
          class="tw-max-w-[80%] tw-mt-6"
          variant="h6"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          textAlign="center"
          >All projects need to follow our
          <a
            href="#"
            class="yellow-text !tw-text-[16px] !tw-font-medium tw-no-underline tw-m-auto"
            >Terms & Conditions</a
          >
        </Text>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";
const emits = defineEmits(["on-close", "on-confirm"]);
const onCloseModal = () => {
  emits("on-close");
};
const onConfirmModal = () => {
  emits("on-confirm");
};
</script>
