export const CONNECTIONS_ROUTE = "connections";

export default {
  path: "/connection",
  name: CONNECTIONS_ROUTE,
  component: () => import("@/modules/connections/pages/ConnectionsPage.vue"),
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Connections",
  },
};
