/**
 * Dashboard route page
 */
import DashboardRoute from "@/modules/dashboard/pages/DashboardPage.vue";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { ONBOARDING_ROUTE } from "@/modules/onboarding/routes";
import store from "@/store";
import { USER_STORE } from "@/store/modules/user";

export const DASHBOARD_ROUTE = "dashboard";
export const DASHBOARD_WITH_COURSE_ROUTE = "dashboard-with-course";
export const DASHBOARD_WITH_COURSE_LAUNCH_WS =
  "dashboard-with-course-launch-ws";

export default [
  {
    path: "/dashboard",
    name: DASHBOARD_ROUTE,
    component: DashboardRoute,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      let user = store.getters[`${USER_STORE}/user`];
      if (!user) {
        user = await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
      if (user && user.hasAccountSetup) {
        next();
      } else {
        next({ name: ONBOARDING_ROUTE });
      }
    },
  },
  {
    path: "/dashboard/:courseId",
    name: DASHBOARD_WITH_COURSE_ROUTE,
    component: DashboardRoute,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      let user = store.getters[`${USER_STORE}/user`];
      if (!user) {
        user = await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
      if (user && user.hasAccountSetup) {
        next();
      } else {
        next({ name: ONBOARDING_ROUTE });
      }
    },
  },
  {
    path: "/dashboard/course/:courseId/workstation/:workstationId",
    name: DASHBOARD_WITH_COURSE_LAUNCH_WS,
    component: DashboardRoute,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      let user = store.getters[`${USER_STORE}/user`];
      if (!user) {
        user = await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
      if (user && user.hasAccountSetup) {
        next();
      } else {
        next({ name: ONBOARDING_ROUTE });
      }
    },
  },
];
