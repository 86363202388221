<template>
  <GeneralDialog class="tw-max-w-[544px]" :isShowCloseButton="false"
    ><template #body>
      <div class="manual-intervention-modal">
        <div class="manual-intervention-modal__icon">
          <img src="@/assets/icons/bell-icon.svg" alt="icon" />
        </div>
        <div class="manual-intervention-modal__content">
          <h2>SLA Time (Manual Intervention)</h2>
          <p>
            <strong
              >{{ issueDetail.user.firstName }}
              {{ issueDetail.user.lastName }}</strong
            >
            has raised an emergency out-of-hours Issue
            <strong>{{ issueDetail.title }}</strong>
            Would you like to intervene?
          </p>
        </div>
        <div class="manual-intervention-modal__buttons">
          <v-btn
            class="button button-purple-border"
            block
            @click="onClickNoIntervene"
            :disabled="isLoading"
            >No, I Don't Want to Intervene</v-btn
          >
          <v-btn
            class="button button-orange"
            block
            @click="onClickManualIntervention"
            :loading="isLoading"
            >Yes, I want to Intervene</v-btn
          >
        </div>
      </div>
    </template></GeneralDialog
  >
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
export default {
  components: {
    GeneralDialog,
  },
  props: {
    issueDetail: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const onClickNoIntervene = () => {
      ctx.emit("onClickNoIntervene");
    };

    const onClickManualIntervention = () => {
      ctx.emit("onClickManualIntervention");
    };
    return { onClickNoIntervene, onClickManualIntervention };
  },
};
</script>

<style lang="scss" scoped>
.manual-intervention-modal {
  width: 430px;
  text-align: center;
  color: $blueDark;

  @include respond(s720) {
    width: 100%;
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin: 0 auto 16px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  h2 {
    @include fluidFont(24, 18, 30px);
    font-weight: 700;
    max-width: 335px;
    width: 100%;
    margin: 0 auto 22px;

    @include respond(s720) {
      margin-bottom: 16px;
    }
  }

  &__content {
    margin-bottom: 22px;

    @include respond(s720) {
      font-size: 12px;
      line-height: 2;
    }

    p {
      color: rgba($blueDark, 0.5);
      font-weight: 500;

      strong {
        font-weight: 500;
        color: $blueDark;
      }
    }
  }

  &__buttons {
    display: grid;
    grid-gap: 8px;
  }
}
</style>
