import $axios from "../utils/axios-api-config";

export default {
  getUserConnectionList(userId: number, params = {}) {
    return $axios.get(`pv-connections/${userId}/members`, { params });
  },
  inviteContractors(
    members: {
      email: string;
      tags: Array<any>;
    },
    userId: number
  ) {
    return $axios.post(`/pv-connections/${userId}/members`, members);
  },

  getPropertyUserConnection(userId: number) {
    return $axios.get(`pv-connections/${userId}/members/trade-user`);
  },
  acceptPropertyUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    data: {
      key: string;
      workstationIds: Array<number>;
    }
  ) {
    return $axios.put(
      `pv-connections/${userId}/members/accept/${tradeUserConnectionId}`,
      data
    );
  },
  rejectPropertyUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    type: string
  ) {
    return $axios.delete(
      `pv-connections/${userId}/members/${tradeUserConnectionId}/${type}`
    );
  },
  resendPropertyUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    key: any
  ) {
    return $axios.put(
      `pv-connections/${userId}/members/resent-invitation/${tradeUserConnectionId}`,
      key
    );
  },
  getConnectionRoleByPropertyId(userId: number, propertyId: number) {
    return $axios.get(
      `/pv-connections/${userId}/members/connection-role/${propertyId}`
    );
  },
  getConnectionsOfProperty(userId: number, propertyId: number) {
    return $axios.get(
      `/pv-connections/${userId}/members/connections/${propertyId}`
    );
  },
  updateUserConnection(
    userId: number,
    tradeUserConnectionId: number,
    payload: object
  ) {
    return $axios.put(
      `pv-connections/${userId}/members/${tradeUserConnectionId}`,
      payload
    );
  },
};
