<template>
  <div class="project_banner !tw-mt-0" v-if="showBanner">
    <div class="project_banner__header">
      <v-icon>
        <img :src="require('@/assets/icons/project-banner-icon.svg')" alt="" />
      </v-icon>
      <h5>iKnowa Job Estimates</h5>
    </div>
    <div class="project_banner__content">
      <div class="left__block">
        <h4>Your First Estimates Are Here!</h4>
        <!-- <p>
          Lorem ipsum dolor sit amet consectetur. Sed odio tristique a libero.
          Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel
          est arcu. Ornare nisi sed odio id. Eget in vestibulum lorem lectus
          justo enim.
        </p> -->
      </div>
      <div class="right__block">
        <img :src="estimatesBannerSvg" />
        <v-icon
          @click="onCloseBanner"
          icon="mdi-close-circle-outline"
          class="close_icon"
        />
      </div>
    </div>
  </div>

  <div class="content_field !tw-mb-4">
    <v-text-field
      prepend-inner-icon="mdi-magnify"
      class="input_field"
      placeholder="Search"
      variant="outlined"
    />
    <div class="sort__wrapper">
      <v-menu
        :persistent="true"
        :close-on-content-click="false"
        class="desktop_sort_menu"
      >
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="plain">
            Sort By
            <span>
              <img :src="require('@/assets/icons/sort.svg')" alt="" />
            </span>
          </v-btn>
        </template>
        <v-radio-group
          class="tw-bg-white tw-rounded-[8px] tw-py-[1rem] tw-pr-[1rem] tw-pl-[.5rem]"
        >
          <v-radio label="Most Recent" value="ASC" color="indigo"></v-radio>
          <v-radio label="Oldest" value="DESC" color="indigo"></v-radio>
        </v-radio-group>
      </v-menu>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import estimatesBannerSvg from "@/core/uni-assets/svgs/estimates-banner.svg";
import Text from "@/core/uni-components/Text.vue";

const showBanner = ref(true);

const onCloseBanner = () => {
  showBanner.value = false;
};
</script>

<style lang="scss" scoped>
.project_banner {
  width: 100%;
  padding: 16px;
  text-align: left;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  border-radius: 8px;
  margin: 24px 0 0;
  background-color: rgba($white, 1);
  position: relative;
  .project_banner__header {
    display: flex;
    gap: 8px;
    align-items: center;
    h5 {
      @include fluidFont(16, 16, 1.2);
      font-weight: 700;
      color: rgba($blueDark, 1);
      letter-spacing: 0.15px;
    }
  }
  .project_banner__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left__block {
      h4 {
        @include fluidFont(20, 20, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 8px;
        @include fluidFont(14, 14, 1.2);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
    }
    .right__block {
      margin-right: 150px;

      .close_icon {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        color: rgba($blueDark, 0.5);
      }
    }
  }
}
.content_field {
  margin: 24px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input_field {
    margin: 0;
    width: 100%;
    max-width: 216px;
    :deep(.v-input__control) {
      .v-field {
        border: 1px solid rgba($blueDark, 0.2);
        border-radius: 8px;
        padding: 12px 16px;
        .v-field__field {
          .v-field__input {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            top: 0;
            padding: 0;
            min-height: auto;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
        }
        .v-field__prepend-inner {
          height: 16px;
          .v-icon {
            color: rgba($blueDark, 1);
            opacity: 1;
          }
        }
        .v-field__outline {
          display: none;
        }
      }
    }
  }
  .sort__wrapper {
    .v-btn {
      padding: 0;
      height: auto;
      opacity: 1;
      :deep(.v-btn__content) {
        text-transform: capitalize;
        display: flex;
        gap: 10px;
        align-items: center;
        @include fluidFont(14, 14, 1.2);
        font-weight: 600;
        color: rgba($blueDark, 1);
        span {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background-color: rgba($blueDark, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      :deep(.v-ripple__container) {
        display: none !important;
      }
    }
  }
  .v-menu {
    :deep(.v-overlay__content) {
      background-color: rgba($white, 1);
      min-width: 185px !important;
      border-radius: 16px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      padding: 16px;
      // top: 222px !important;
      // left: auto !important;
      // right: 40px !important;
      // @include respond(xlg) {
      //   right: 10px !important;
      // }
      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 30px;
      //   height: 20px;
      //   background-image: url(../../assets/icons/triangle.svg);
      //   right: 10px;
      //   top: -10px;
      // }
      .v-input {
        .v-input__control {
          .v-selection-control-group {
            gap: 10px;
            .v-selection-control {
              gap: 8px;
              .v-selection-control__wrapper {
                width: auto;
                height: auto;
                .v-selection-control__input {
                  width: auto;
                  height: auto;
                }
              }
              .v-label {
                @include fluidFont(14, 14, 1.3);
                font-weight: 600;
                color: rgba($blueDark, 1);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
