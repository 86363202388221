/**
 * Change Password Route Page
 */

import ChangePasswordPage from "@/modules/change-password/pages/ChangePasswordPage.vue";

export const CHANGE_PASSWORD_ROUTE = "change-password";

export default {
  path: "/change-password",
  name: CHANGE_PASSWORD_ROUTE,
  component: ChangePasswordPage,
  meta: {
    layout: "AuthLayout",
  },
};
