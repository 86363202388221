<template>
  <GeneralDialog @on-close="onCloseModal" headerTitle="Asset Register">
    <template #body>
      <div
        class="tw-w-[607px] tw-h-[450px] tw-border-b-solid-custom tw-flex tw-flex-col tw-gap-4 tw-overflow-x-hidden"
      >
        <div class="tw-w-full tw-h-full tw-rounded-lg tw-overflow-hidden">
          <img
            class="tw-w-full tw-h-full tw-object-cover"
            src="@/assets/images/sample-polygon-image.jpg"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-flex tw-justify-end tw-items-center tw-gap-3">
        <Button
          class="!tw-w-auto"
          variant="secondary"
          :disabled="state.loading"
          :isLoading="state.loading"
          label="close"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          :disabled="state.loading"
          :isLoading="state.loading"
          label="done"
          :isActive="true"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";

const emits = defineEmits(["on-close", "on-success"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const onCloseModal = () => {
  emits("on-close");
};
</script>
