import { UserWorkstationMemberRoleEnum } from "@/core/enums/RolesEnum";
import { USER_STORE } from "../user";
import { WORKSTATION } from "../workstation";

export default {
  getAllCourses: (state: { courses: any }) => state.courses,
  courseDetails: (state: { courseDetails: any; courses: any }) =>
    state.courseDetails,
  selectedDates: (state: { selectedDates: any }) => state.selectedDates,
  totalReserveSpotAmount: (state: { totalReserveSpotAmount: any }) =>
    state.totalReserveSpotAmount,
  spotReservedDateStarted: (state: { spotReservedDateStarted: any }) =>
    state.spotReservedDateStarted,

  reservedSpotsData: (state: { reservedSpotsData: any }) =>
    state.reservedSpotsData,

  storedReservedSpotsData: (state: { storedReservedSpotsData: any }) =>
    state.storedReservedSpotsData,
  courseUpgradeResponse: (state: { courseUpgradeResponse: any }) =>
    state.courseUpgradeResponse,
  getCoursesWithRemainingSlots: (state: any) => state.coursesWithRemainingSlots,
  getEnrollCourses: (state: any) => state.enrollCourses,
  getAssignedCourses: (state: any) => state.assignedCourses,
  getCourseEventToBeCreated: (state: any) => state.courseEventToBeCreated,
  getCourseModuleRedirect: (state: any) => state.courseModuleRedirect,
};
