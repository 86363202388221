<template>
  <GeneralDialog
    class="tw-w-full tw-max-w-[860px] tw-min-w-[300px] rmd:!tw-min-w-[860px]"
    @on-close="onCloseModal"
    width="auto"
    :isShowCloseButton="false"
    :isShowHeader="false"
  >
    <template #body>
      <main
        v-if="!loading"
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
      >
        <div class="tw-w-full tw-flex tw-items-center tw-justify-start">
          <ChipState
            class="tw-justify-start !tw-p-0 tw-cursor-pointer"
            label="Back To Control Centre"
            textColor="#0C0F4A"
            @click="onBackToDashboard"
          >
            <template #prefix>
              <v-icon icon="mdi-chevron-left" color="#0C0F4A"></v-icon>
            </template>
          </ChipState>
        </div>
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center tw-px-0 rlg:tw-px-12"
        >
          <Text variant="h4" class="rmd:!tw-text-[32px]"
            >Congratulations! 🥳</Text
          >

          <Text
            variant="h6"
            textWeight="400"
            textAlign="center"
            lineHeight="24px"
            ><strong
              >We've reserved your {{ numberOfSpotsReserved }} spot(s) for
              {{ courseName }} course on:</strong
            >
            {{ formattedStartDates }}</Text
          >
          <Text
            variant="h6"
            textWeight="400"
            textAlign="center"
            lineHeight="24px"
          >
            Due to high demand, we can only hold your spot(s) for 72 hours. To
            guarantee your full enrolment, please create your workstation and
            submit it for verification within this time frame. After this time,
            the spot will be offered to other candidates.</Text
          >
        </div>
        <div
          class="tw-rounded-[8px] tw-bg-[#F3F3F6] tw-py-6 tw-px-5 tw-box-border tw-flex tw-items-center tw-justify-between tw-gap-2"
        >
          <div
            class="tw-rounded-[8px] tw-bg-white tw-min-w-[68px] tw-w-[68px] tw-h-[51px] rlg:tw-min-w-[146px] rlg:tw-w-[146px] rlg:tw-h-[110px] tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h4" class="rlg:!tw-text-[56px]">{{ hours }}</Text>
          </div>
          <Text variant="h4" class="rlg:!tw-text-[56px]">:</Text>
          <div
            class="tw-rounded-[8px] tw-bg-white tw-min-w-[68px] tw-w-[68px] tw-h-[51px] rlg:tw-min-w-[146px] rlg:tw-w-[146px] rlg:tw-h-[110px] tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h4" class="rlg:!tw-text-[56px]">{{ minutes }}</Text>
          </div>
          <Text variant="h4" class="rlg:!tw-text-[56px]">:</Text>
          <div
            class="tw-rounded-[8px] tw-bg-white tw-min-w-[68px] tw-w-[68px] tw-h-[51px] rlg:tw-min-w-[146px] rlg:tw-w-[146px] rlg:tw-h-[110px] tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h4" class="rlg:!tw-text-[56px]">{{ seconds }}</Text>
          </div>
        </div>
      </main>
      <main
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
        v-else
      >
        <CommonLoader
          v-if="loading"
          :loading="loading"
          class="loader-container"
        />
      </main>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-0 tw-gap-4 md:!tw-gap-[8px]"
      >
        <Button label="Create a workstation" @click="onCreateWorkstation" />
        <div
          class="tw-w-full tw-pb-4 tw-flex tw-items-center tw-gap-2 tw-justify-center"
        >
          <Text
            variant="xsmall"
            class="md:!tw-leading-[12px] rmd:!tw-text-[14px]"
            lineHeight="18px"
            textAlign="center"
            textWeight="600"
            >Already have a workstation?</Text
          >
          <div
            class="tw-flex tw-items-center tw-cursor-pointer"
            @click="onSelectWorkstation"
          >
            <Text
              variant="xsmall"
              class="md:!tw-leading-[12px] rmd:!tw-text-[14px]"
              lineHeight="18px"
              textAlign="center"
              textColor="#4F55F0"
              textWeight="600"
              >Click here</Text
            >
            <v-icon icon="mdi-chevron-right" color="#4F55F0"></v-icon>
          </div>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import moment from "moment";
import {
  computed,
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import { useTimer } from "vue-timer-hook";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { COURSES_STORE } from "@/store/modules/courses";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_ROUTE,
} from "@/modules/dashboard/routes";
import { USER_STORE } from "@/store/modules/user";
import CommonLoader from "@/core/components/CommonLoader.vue";
import ChipState from "@/core/components/common/ChipState.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();

const emits = defineEmits(["on-close", "on-select-ws"]);

const courseId = ref(null);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const loading = ref(false);

// const time = new Date();
// time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
// const timer = useTimer(time);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const selectedDatesStore = computed(
  () => store.getters[`${COURSES_STORE}/selectedDates`]
);

const spotReservedDateStarted = computed(
  () => store.getters[`${COURSES_STORE}/spotReservedDateStarted`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const courseName = computed(() => {
  courseId.value = route?.params.courseId;
  if (courseId.value === "1") {
    return "Air Source Heat Pump (Level 3)";
  }
  return "Solar PV, Battery Storage & EV Charging Level 3";
});

const reservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
);

const filteredReserveSpots = computed(() => {
  const courseId = route?.params?.courseId;

  if (reservedSpotsData.value && reservedSpotsData.value.length) {
    // Filter spots by courseId and and no subscription
    return reservedSpotsData.value.filter((spot) => {
      return spot.course?.id == courseId && !spot.subscription;
    });
  }

  return [];
});

const numberOfSpotsReserved = computed(() => {
  // Sum all spots in selectedDates
  let totalSpots = 0;
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    totalSpots = filteredReserveSpots.value.reduce((sum, item) => {
      return sum + item.selectedSpotCount;
    }, 0);
  }

  return totalSpots;
});

const formattedStartDates = computed(() => {
  if (selectedDatesStore.value && selectedDatesStore.value.length) {
    return selectedDatesStore.value
      .map((item) => moment(item.startDate).format("DD MMMM YYYY")) // Format each startDate
      .join(", "); // Join all formatted dates with a comma and space
  }
  console.log(filteredReserveSpots.value, "filteredReserveSpots valueee");
  return filteredReserveSpots.value
    .map((item) =>
      moment(item.userCourseSchedules[0].courseSchedule.startDate).format(
        "DD MMMM YYYY"
      )
    ) // Format each startDate
    .join(", "); // Join all formatted dates with a comma and space
});

const onCreateWorkstation = () => {
  store.dispatch(`${WORKSTATION}/setWsModal`, {
    isShowWorkStationModal: true,
    isFromCourse: true,
  });
  router.push({
    name: DASHBOARD_WITH_COURSE_ROUTE,
    params: route?.params.courseId,
  });
  emits("on-close");
};

const onBackToDashboard = () => {
  router.push({ name: DASHBOARD_ROUTE });
  emits("on-close");
};

const onCloseModal = () => {
  // Add an alert to confirm with the user
  // const confirmation = window.confirm(
  //   "All the data gathered will be lost if you close the modal. Do you wish to proceed?"
  // );

  // If the user presses "OK", emit the on-close event
  // if (confirmation) {
  //   emits("on-close");
  // }
  emits("on-close");
};

const onSelectWorkstation = () => {
  emits("on-select-ws");
};

// const handleBeforeUnload = (event) => {
//   // Show a confirmation dialog
//   event.preventDefault();
//   event.returnValue = "";
// };

onBeforeMount(() => {
  console.log(
    filteredReserveSpots.value,
    "filteredReserveSpots valueee before mount"
  );
});

onMounted(async () => {
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    console.log(reservedSpotsData.value, "reservedSpotsData.value");
    console.log(filteredReserveSpots.value, "filteredReserveSpots.value");
    // Calculate the current time and the creation time of the reservation spot
    const currentTime = new Date();
    const startTime = new Date(filteredReserveSpots.value[0].dateCreated);

    // Calculate the elapsed time in seconds between the current time and the start time
    const elapsedTime = Math.floor((currentTime - startTime) / 1000);

    // 72 hours in seconds (72 * 60 * 60 = 259200 seconds)
    const totalTime = 72 * 60 * 60;

    // Calculate the remaining countdown time
    let countdownTime = totalTime - elapsedTime;

    // If the countdown time is less than or equal to 0, close the modal
    if (countdownTime <= 0) {
      countdownTime = 0; // Prevent negative countdown
      onCloseModal(); // Close the modal immediately
      return; // Exit early as there's no need to continue the timer
    }

    // Start the countdown if the timer hasn't expired
    const countdownInterval = setInterval(() => {
      hours.value = Math.floor(countdownTime / 3600);
      minutes.value = Math.floor((countdownTime % 3600) / 60);
      seconds.value = countdownTime % 60;

      // If the countdown reaches 0, stop the timer and close the modal
      if (countdownTime <= 0) {
        clearInterval(countdownInterval);
        console.log("Timer finished!");
        onCloseModal(); // Close modal when the timer expires
      }

      countdownTime--; // Decrease the remaining time
    }, 1000);
  } else {
    console.log("No spots available.");
  }

  // Add event listener when the component is mounted
  // window.addEventListener("beforeunload", handleBeforeUnload);
});
onUnmounted(() => {
  // Clean up event listener when the component is unmounted
  // window.removeEventListener("beforeunload", handleBeforeUnload);
});
</script>
