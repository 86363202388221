<template>
  <GeneralDialog class="tw-max-w-[450px]" @on-close="onCloseModal">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <ContractSignYellowIcon />
        <Text variant="h4" textAlign="center" lineHeight="28px"
          >Are you happy to accept the estimate?</Text
        >

        <Text
          class="tw-max-w-[80%] tw-mt-6"
          variant="h6"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          textAlign="center"
          >All project should follow our
          <span class="!tw-text-[orange]">Terms & Conditions</span></Text
        >
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          label="Accept"
          :isLoading="loading"
          :disabled="loading"
          @click="onAccept"
        />
        <Button
          variant="secondary"
          :disabled="loading"
          label="Reject"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";

const emits = defineEmits(["on-close"]);

const onCloseModal = () => {
  emits("on-close");
};
</script>
