<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" :fill="fill || '#FFA500'" />
    <path
      d="M6.46154 8.81781H8.30769C8.30769 9.31907 8.72169 9.72672 9.23077 9.72672H10.1538C10.6629 9.72672 11.0769 9.31907 11.0769 8.81781H17.5385C17.7932 8.81781 18 8.61467 18 8.36336C18 8.11205 17.7932 7.90891 17.5385 7.90891H11.0769C11.0769 7.40764 10.6629 7 10.1538 7H9.23077C8.72169 7 8.30769 7.40764 8.30769 7.90891H6.46154C6.20677 7.90891 6 8.11205 6 8.36336C6 8.61467 6.20677 8.81781 6.46154 8.81781Z"
      fill="white"
    />
    <path
      d="M17.5385 11.5495H14.7692C14.7692 11.0483 14.3552 10.6406 13.8462 10.6406H12.9231C12.414 10.6406 12 11.0483 12 11.5495H6.46154C6.20677 11.5495 6 11.7527 6 12.004C6 12.2553 6.20677 12.4584 6.46154 12.4584H12C12 12.9597 12.414 13.3673 12.9231 13.3673H13.8462C14.3552 13.3673 14.7692 12.9597 14.7692 12.4584H17.5385C17.7932 12.4584 18 12.2553 18 12.004C18 11.7527 17.7932 11.5495 17.5385 11.5495Z"
      fill="white"
    />
    <path
      d="M17.5385 15.1822H11.0769C11.0769 14.6809 10.6629 14.2733 10.1538 14.2733H9.23077C8.72169 14.2733 8.30769 14.6809 8.30769 15.1822H6.46154C6.20677 15.1822 6 15.3853 6 15.6366C6 15.888 6.20677 16.0911 6.46154 16.0911H8.30769C8.30769 16.5924 8.72169 17 9.23077 17H10.1538C10.6629 17 11.0769 16.5924 11.0769 16.0911H17.5385C17.7932 16.0911 18 15.888 18 15.6366C18 15.3853 17.7932 15.1822 17.5385 15.1822Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "FilterCircleYellowIcon",
  props: {
    fill: {
      type: String,
    },
  },
};
</script>
