<template>
  <el-select class="!tw-w-full" :placeholder="placeholder">
    <slot name="option"></slot>
  </el-select>
</template>

<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
});
</script>

<script>
export default {
  name: "NoBgSelect",
};
</script>

<style lang="scss" scoped>
:deep(.el-select__wrapper) {
  @apply tw-bg-transparent tw-border-0 tw-rounded-none tw-shadow-none tw-p-0
      tw-min-h-[2.625rem] tw-max-h-[2.625rem] tw-h-full tw-w-full tw-border-b-[1px] tw-border-[rgba(12,15,74,0.2)] tw-text-xs tw-font-normal tw-text-[#0C0F4A] tw-leading-7;
}
</style>
