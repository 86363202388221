<template>
  <GeneralDialog
    class="tw-max-w-[560px] tw-min-w-[300px] rmd:tw-min-w-[560px]"
    @on-close="onCloseModal"
  >
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <InfoIconRed class="!tw-w-[70px] !tw-h-[70px]" />
          <Text
            class="!tw-text-[30px]"
            textAlign="center"
            textWeight="400"
            lineHeight="28px"
          >
            SUBMIT VERIFICATION REQUIRED!
          </Text>
          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="center"
          >
            To access all areas you need to have a verified status. Once
            submitted, our verification team may reach out, for additional
            clarification(s).
          </Text>
          <Text
            class="tw-max-w-[80%]"
            variant="p"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="center"
            >Verification times may vary, however, the avaerage verification
            time is 72 hours.</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-4 tw-items-center">
        <Button label="go to dashboard" @click="onRouteToDashboard" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InfoIconRed from "./../icons/InfoIconRed.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close", "on-route-to-dashboard"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value?.name === "Default";
});

const onCloseModal = () => {
  emits("on-close");
};

const onRouteToDashboard = () => {
  emits("on-route-to-dashboard");
};
</script>
