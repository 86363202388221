/**
 * Registration route page
 */
import RegistrationRoute from "@/modules/registration/pages/RegistrationPage.vue";
import VerifyRegistrationRoute from "@/modules/registration/pages/VerifyRegistrationPage.vue";
import NewRegistrationFlowRoute from "@/modules/registration/pages/NewRegistrationFlowPage.vue";

export const REGISTRATION_ROUTE = "registration";

export const VERIFY_REGISTRATION_ROUTE = "verify-registration";

export default [
  {
    path: "/registration",
    name: REGISTRATION_ROUTE,
    component: NewRegistrationFlowRoute,
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/verify-code",
    name: VERIFY_REGISTRATION_ROUTE,
    component: VerifyRegistrationRoute,
    meta: {
      layout: "AuthLayout",
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (typeof to.query.email !== "undefined") {
        next();
      } else {
        next("/");
      }
    },
  },
];
