<template>
  <div class="profile-logo" :style="{ backgroundColor: logoBackgroundColor }">
    {{ getWSNameLogoChar(WSName) }}
  </div>
</template>

<script lang="ts">
import { PropType, computed } from "vue";

export default {
  props: {
    WSName: {
      type: String as PropType<string>,
      required: true,
    },
    color: {
      type: String,
      default: "#4a92ef",
    },
  },
  setup(props: any) {
    const getWSNameLogoChar = (WSName: string) => {
      if (WSName) {
        const initials = WSName.split(" ")
          .map((word) => word.charAt(0))
          .join("");
        return initials;
      }
      return "";
    };

    const logoBackgroundColor = computed(() => props.color || "#4a92ef");

    return {
      getWSNameLogoChar,
      logoBackgroundColor,
    };
  },
};
</script>

<style scoped>
/* .profile-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #4a92ef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
} */
</style>
