/**
 * Calendar route page
 */
import CalendarRoute from "@/modules/calendar/pages/CalendarOverviewPage.vue";

export const CALENDAR_ROUTE = "calendar";

export default [
  {
    path: "/calendar",
    name: CALENDAR_ROUTE,
    component: CalendarRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Calendar",
    },
  },
];
