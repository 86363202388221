<template>
  <CollapsibleWidgetSlot title="Insurance">
    <template #content>
      <section
        class="tw-flex tw-flex-col rmd:tw-flex-row tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <div
          class="tw-w-full tw-max-w-[404px] rmd:tw-min-w-[404px] tw-h-full tw-bg-[#F1F6FB] tw-rounded-lg tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-4"
        >
          <div
            class="tw-flex tw-flex-col-reverse rmd:tw-flex-row tw-items-center tw-gap-3 tw-w-full"
          >
            <InputField
              prepend-inner-icon="mdi-magnify"
              class="tw-w-full"
              placeholder="Search"
              v-model="filters.search"
              @input="debouncedFilterReports"
            />
            <Button
              v-if="isUserHasEditRight"
              class="rmd:!tw-w-auto"
              label="New insurance"
              @click="onOpenModal"
            >
              <template #prefix>
                <v-icon icon="mdi-plus-circle"></v-icon>
              </template>
            </Button>
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
            <CollapsiblePropertyInsurance
              :insurances="propertyInsurances"
              @on-select-insurance="onSelectInsurance"
            />
            <CollapsibleWarranties
              :insurances="warrantyInsurances"
              @on-select-insurance="onSelectInsurance"
            />
            <CollapsibleProjectInsurance
              :insurances="projectInsurances"
              @on-select-insurance="onSelectInsurance"
            />
          </div>
        </div>
        <div
          class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-rounded-lg tw-p-4 tw-box-border tw-max-h-[650px] tw-min-h-[650px]"
        >
          <div v-if="fetchingInsurances" class="loader-container">
            <CommonLoader
              :loading="fetchingInsurances"
              class="loader-container"
            />
          </div>
          <embed
            v-if="
              selectedInsurance &&
              selectedInsurance?.files.length &&
              selectedInsurance?.files[0]?.isPdf
            "
            :src="selectedInsurance?.files[0].attachmentSource"
            type="application/pdf"
            width="100%"
            height="615px"
          />
          <v-img
            v-if="
              selectedInsurance &&
              selectedInsurance?.files.length &&
              !selectedInsurance?.files[0]?.isPdf
            "
            class="tw-w-full tw-h-full tw-cursor-pointer"
            aspect-ratio="16/9"
            cover
            :src="selectedInsurance?.files[0]?.attachmentSource"
          ></v-img>
          <div
            v-if="selectedInsurance && !selectedInsurance?.files.length"
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h6">No Attachment</Text>
          </div>
          <div
            v-if="!selectedInsurance"
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h6">Please select a report</Text>
          </div>
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>

  <!-- MODALS -->
  <AddPropertyInsuranceModal
    v-if="addPropertyInsurance"
    @on-close="onCloseModal"
    @on-success="onSuccessAddInsurance"
  />
</template>
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import InputField from "@/core/components/common/InputField.vue";
import CollapsibleProjectInsurance from "@/modules/properties/components/Collapsibles/sub-modules/CollapsibleProjectInsurance.vue";
import CollapsiblePropertyInsurance from "@/modules/properties/components/Collapsibles/sub-modules/CollapsiblePropertyInsurance.vue";
import CollapsibleWarranties from "@/modules/properties/components/Collapsibles/sub-modules/CollapsibleWarranties.vue";
import AddPropertyInsuranceModal from "@/modules/properties/components/Modals/AddPropertyInsuranceModal.vue";
import { CONNECTIONS } from "@/store/modules/connections";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import { PROPERTY_STORE } from "@/store/modules/property";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import CommonLoader from "@/core/components/CommonLoader.vue";
import debounce from "lodash/debounce";

const filters = reactive({
  search: "",
});
const store = useStore();
const route = useRoute();

const fetchingInsurances = ref(false);
const insurances = ref([]);
const propertyInsurances = ref([]);
const propertyInsurancesTemp = ref([]);
const warrantyInsurances = ref([]);
const warrantyInsurancesTemp = ref([]);
const projectInsurances = ref([]);
const projectInsurancesTemp = ref([]);
const selectedInsurance = ref(null);
const loadingAttachment = ref(false);

const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);

const propertyId = computed(() => route?.params?.propertyId);

const addPropertyInsurance = ref(false);

const onOpenModal = () => {
  addPropertyInsurance.value = true;
};
const onCloseModal = () => {
  addPropertyInsurance.value = false;
};

const isInsuranceExpired = (insurance) => {
  if (!insurance || !insurance.expiryDate) {
    return false; // Return false if insurance or expiryDate is not provided
  }

  const expiryDate = new Date(insurance.expiryDate);
  const currentDate = new Date();

  // Compare expiryDate with currentDate
  return expiryDate < currentDate;
};

const calculateWarrantyLength = (dateIssued, expiryDate) => {
  if (!dateIssued || !expiryDate) {
    return ""; // Return empty string if either dateIssued or expiryDate is not provided
  }

  const issuedDate = new Date(dateIssued);
  const endDate = new Date(expiryDate);

  // Calculate the difference in milliseconds
  const differenceMs = endDate - issuedDate;

  // Convert milliseconds to days, months, or years
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisecondsPerMonth = millisecondsPerDay * 30.436875; // Approximate value for average days in a month
  const millisecondsPerYear = millisecondsPerDay * 365.25; // Approximate value for average days in a year

  if (differenceMs < millisecondsPerDay) {
    const days = Math.ceil(differenceMs / millisecondsPerDay);
    return `${days} day${days !== 1 ? "s" : ""}`;
  } else if (differenceMs < millisecondsPerMonth) {
    const months = Math.floor(differenceMs / millisecondsPerDay / 30);
    return `${months} month${months !== 1 ? "s" : ""}`;
  } else {
    const years = Math.floor(differenceMs / millisecondsPerYear);
    return `${years} year${years !== 1 ? "s" : ""}`;
  }
};

const initialize = async () => {
  if (!propertyId.value) return;
  const parsedPropertyId = parseInt(propertyId.value, 10);
  try {
    fetchingInsurances.value = true;
    const response = await store.dispatch(
      `${PROPERTY_STORE}/fetchPropertyInsurances`,
      propertyId.value
    );
    if (response.length) {
      insurances.value = response;

      // Clear existing arrays
      propertyInsurances.value = [];
      warrantyInsurances.value = [];
      projectInsurances.value = [];

      // Iterate over each insurance item and categorize it based on insuranceType
      insurances.value.forEach((insurance) => {
        const isExpired = isInsuranceExpired(insurance);
        const lengthOfWarranty = calculateWarrantyLength(
          insurance.dateIssued,
          insurance.expiryDate
        );
        const modifiedInsurance = {
          ...insurance,
          isExpired,
          lengthOfWarranty,
        };
        if (insurance.insuranceType === "1") {
          propertyInsurances.value.push(modifiedInsurance);
          propertyInsurancesTemp.value.push(modifiedInsurance);
        } else if (insurance.insuranceType === "2") {
          warrantyInsurances.value.push(modifiedInsurance);
          warrantyInsurancesTemp.value.push(modifiedInsurance);
        } else if (insurance.insuranceType === "3") {
          projectInsurances.value.push(modifiedInsurance);
          projectInsurancesTemp.value.push(modifiedInsurance);
        }
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    fetchingInsurances.value = false;
  }
};

const isPdf = (filename) => {
  let extension = filename.split(".").pop().toLowerCase();

  return extension === "pdf";
};

const onSelectInsurance = async (insurance) => {
  if (!insurance?.files.length) {
    selectedInsurance.value = insurance;
    return;
  }

  try {
    loadingAttachment.value = true;
    const data = insurance;
    const attachmentPromises = insurance.files.map(async (currentFile) => {
      const { attachment } = currentFile;
      const imageUrl = getImageApiUrl(attachment, true);
      const encoded = await $axios.get(imageUrl);
      const publicURL = encoded?.publicUrl;
      return {
        ...currentFile,
        attachmentSource: publicURL,
        isPdf: isPdf(currentFile.originalName),
      };
    });
    data.files = await Promise.all(attachmentPromises);
    selectedInsurance.value = data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingAttachment.value = false;
  }
};

const onSuccessAddInsurance = async () => {
  await initialize();
  onCloseModal();
};

const PropertyInsuranceTypes = {
  1: "Building Instance",
  2: "Contents Insurance",
};

const ProjectInsuranceTypes = {
  1: "Insurance Backed Guarantee",
  2: "Building Works Insurance",
};

const WarrantyInsuranceTypes = {
  1: "Driveway Installation",
  2: "Air Source Heat Pump Installation",
};

const filterInsurance = () => {
  if (filters.search) {
    propertyInsurances.value = propertyInsurancesTemp.value.filter((item) => {
      return PropertyInsuranceTypes[item?.propertyInsuranceType]
        ?.toLowerCase()
        .includes(filters.search.toLowerCase());
    });
    warrantyInsurances.value = warrantyInsurancesTemp.value.filter((item) => {
      return WarrantyInsuranceTypes[item?.warrantyInsuranceType]
        ?.toLowerCase()
        .includes(filters.search.toLowerCase());
    });
    projectInsurances.value = projectInsurancesTemp.value.filter((item) => {
      return ProjectInsuranceTypes[item?.projectInsuranceType]
        ?.toLowerCase()
        .includes(filters.search.toLowerCase());
    });
  } else {
    propertyInsurances.value = propertyInsurancesTemp.value;
    warrantyInsurances.value = warrantyInsurancesTemp.value;
    projectInsurances.value = projectInsurancesTemp.value;
  }
};
const debouncedFilterReports = debounce(filterInsurance, 300);

onMounted(() => {
  initialize();
});
</script>
<style lang="scss" scoped></style>
