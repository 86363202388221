<template>
  <section
    class="document-breadcrumbs tw-flex tw-items-center tw-justify-between"
  >
    <div
      class="tw-flex tw-items-center"
      v-if="state.breadCrumbLinks.length < 4"
    >
      <div
        class="tw-flex tw-items-center tw-cursor-pointer"
        v-for="(item, index) in state.breadCrumbLinks"
        :key="index"
        @click="onViewFolder(item)"
      >
        <Text variant="h5">{{ item.name }}</Text>
        <v-icon
          icon="mdi-chevron-right"
          v-if="isLastIndex(state.breadCrumbLinks, index)"
          color="rgba(12, 15, 74, 0.40)"
        ></v-icon>
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-gap-2" v-else>
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            icon="mdi-dots-horizontal"
            color="rgba(12, 15, 74, 0.40)"
          ></v-icon>
        </template>
        <DocumentBreadcrumbsMenu
          :menuList="state.popOverData"
          @on-handle-action="setViewFolder($event)"
        />
      </v-menu>
      <div class="tw-flex tw-items-center">
        <div
          class="tw-flex tw-items-center tw-cursor-pointer"
          v-for="(item, index) in state.modifiedbreadCrumbLinks"
          :key="index"
          @click="onViewFolder(item)"
        >
          <Text variant="h5">{{ item.name }}</Text>
          <v-icon
            icon="mdi-chevron-right"
            v-if="isLastIndex(state.modifiedbreadCrumbLinks, index)"
            color="rgba(12, 15, 74, 0.40)"
          ></v-icon>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed, onMounted, reactive, watch } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
import DocumentBreadcrumbsMenu from "./DocumentBreadcrumbsMenu.vue";

const props = defineProps(["titlePath", "isGallery"]);
const emits = defineEmits(["on-view-folder"]);

const state = reactive({
  breadCrumbLinks: [],

  dropDownLinks: [],
  modifiedbreadCrumbLinks: [],

  popOverData: [],
});

const onViewFolder = (folder) => {
  emits("on-view-folder", folder);
};

const modifyBreadCrumbLinks = () => {
  if (state.breadCrumbLinks.length > 3) {
    state.dropDownLinks = state.breadCrumbLinks.slice(0, -2);

    state.modifiedbreadCrumbLinks = state.breadCrumbLinks.slice(-2);

    state.popOverData = state.dropDownLinks.map((link) => ({
      id: link?.id,
      name: link?.name,
    }));

    if (state.popOverData.length > 0) {
      state.popOverData[0] = {
        name: props.isGallery ? "Gallery" : "Documents",
      };
    }
  }
};

const setViewFolder = (id) => {
  let folder = {};

  if (id === "Documents" || id === "Gallery") {
    folder = {}; // Set folder to an empty object
  } else {
    folder = { id };
  }

  onViewFolder(folder);
};

const isLastIndex = (array, index) => {
  return array.length > 1 && array[index + 1];
};

watch(
  () => props.titlePath,
  (newValue, oldValue) => {
    if (newValue) {
      state.breadCrumbLinks = newValue;
      modifyBreadCrumbLinks();
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  state.breadCrumbLinks = props.titlePath;
  // modifyBreadCrumbLinks();
});
</script>
<style lang="scss" scoped></style>
