export const API_URL = process.env.VUE_APP_API_URL;

// stripe keys
export const VUE_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || "";

export const VUE_APP_OS_POSTCODE_URL =
  process.env.VUE_APP_OS_POSTCODE_URL || "";
export const VUE_APP_OS_FIND_URL = process.env.VUE_APP_OS_FIND_URL || "";
export const VUE_APP_OS_API = process.env.VUE_APP_OS_API || "";

// Property Data Api
export const VUE_APP_PROPERTY_DATA_API =
  process.env.VUE_APP_PROPERTY_DATA_API || "";

// KYC COMPANY APP ID

export const VUE_APP_SILT_KYC_COMPANY_APP_ID =
  process.env.VUE_APP_SILT_KYC_COMPANY_APP_ID || "";
export const VUE_APP_SILT_KYC_API_TOKEN =
  process.env.VUE_APP_SILT_KYC_API_TOKEN || "";
export const VUE_APP_SILT_KYB_COMPANY_APP_ID =
  process.env.VUE_APP_SILT_KYB_COMPANY_APP_ID || "";
export const VUE_APP_SILT_KYB_API_TOKEN =
  process.env.VUE_APP_SILT_KYB_API_TOKEN || "";
// EPC API
export const VUE_APP_EPC_API = process.env.VUE_APP_EPC_API || "";
export const VUE_APP_EPC_URL = process.env.VUE_APP_EPC_URL || "";

export const VUE_APP_LEAFLET_LAYER_URL =
  process.env.VUE_APP_LEAFLET_LAYER_URL || "";

export const PUSHER_API_KEY = process.env.VUE_APP_PUSHER_API_KEY || "";
export const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_CLUSTER || "";

export const VUE_APP_SOLAR_GOOGLE_API_KEY =
  process.env.VUE_APP_SOLAR_GOOGLE_API_KEY || "";

export const PRODUCTS = {
  storage: "documents",
};

export const config = {
  development: {
    apiUrl: "https://app.barrythebonsai.com/",
    hostname: "barrythebonsai",
  },
  staging: {
    apiUrl: "https://app.lucythepalm.com/",
    hostname: "lucythepalm",
  },
  production: {
    apiUrl: "https://app.iknowa.com/",
    hostname: "iknowa",
  },
};
