<template>
  <GeneralDialog @on-close="closeModal"
    ><template #body>
      <div class="meeting__wrapper tw-text-center">
        <h4 class="tw-text-xl tw-font-medium tw-text-[#0c0f4a]">
          Meeting has not generated from trade side
        </h4>
        <p class="tw-text-sm tw-font-normal tw-text-[#0c0f4a80] tw-mt-4">
          Please contact Trade Person
        </p>
      </div> </template
    ><template #footer>
      <v-btn class="button button-purple-border" @click="closeModal"
        >Cancel</v-btn
      ></template
    ></GeneralDialog
  >
</template>

<script lang="ts" setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";

const emits = defineEmits(["closeModal"]);

const closeModal = () => {
  console.log("onCloseModal");
  emits("closeModal");
};
</script>
