<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#4F55F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4729 12.0271V9.2305C18.4729 8.552 17.9209 8 17.2424 8H9.2305C8.552 8 8 8.552 8 9.2305V20.7699C8 21.4484 8.552 22.0004 9.2305 22.0004H17.2424C17.9209 22.0004 18.4729 21.4484 18.4729 20.7699V19.5155L18.5 19.5V12L18.4729 12.0271ZM16.3127 10.6524H10.1876C9.96106 10.6524 9.77742 10.8361 9.77742 11.0626C9.77742 11.2891 9.96106 11.4727 10.1876 11.4727H16.3127C16.5393 11.4727 16.7229 11.2891 16.7229 11.0626C16.7229 10.8361 16.5393 10.6524 16.3127 10.6524ZM15.0002 12.84H10.1876C9.96106 12.84 9.77742 13.0236 9.77742 13.2501C9.77742 13.4767 9.96106 13.6603 10.1876 13.6603H15.0002C15.2267 13.6603 15.4104 13.4767 15.4104 13.2501C15.4104 13.0236 15.2267 12.84 15.0002 12.84ZM10.1876 15.0275C9.96106 15.0275 9.77742 15.2112 9.77742 15.4377C9.77742 15.6642 9.96106 15.8479 10.1876 15.8479H13.6877C13.9142 15.8479 14.0978 15.6642 14.0978 15.4377C14.0978 15.2112 13.9142 15.0275 13.6877 15.0275H10.1876Z"
      fill="white"
    />
    <circle cx="19" cy="18" r="3" fill="#4F55F0" />
    <path
      d="M18.6007 18.6211L18.0273 18.0644C17.9634 18.0024 17.8618 18.0024 17.7979 18.0644C17.734 18.1264 17.734 18.225 17.7979 18.2871L18.4844 18.9535C18.5483 19.0155 18.6515 19.0155 18.7154 18.9535L20.4521 17.2692C20.516 17.2072 20.516 17.1085 20.4521 17.0465C20.3882 16.9845 20.2866 16.9845 20.2227 17.0465L18.6007 18.6211Z"
      fill="white"
      stroke="white"
      stroke-width="0.3"
    />
  </svg>
</template>
<script>
export default {
  name: "JobsCompletedIcon",
};
</script>
