<template>
  <Card class="!tw-max-h-[unset] !tw-min-w-[212px] !tw-p-4 tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
        <section
          v-for="(item, index) in menu"
          :key="index"
          @click="onHandleAction(item.name)"
          class="project_list tw-flex tw-items-center tw-gap-2 tw-box-border tw-cursor-pointer tw-border-0 tw-border-b-[#C5D2F2] tw-border-b tw-border-solid tw-pb-2 tw-last:border-[none]"
        >
          <v-icon
            class="project_list_icon"
            :icon="item.mdiIcon"
            color="rgba(12, 15, 74, .54)"
            size="small"
          ></v-icon>
          <Text
            class="hover:!tw-text-[#FFA500]!tw-capitalize project_list_text"
            variant="p"
            textColor="rgba(12, 15, 74, .54)"
            textWeight="600"
            >{{ item.label }}</Text
          >
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { reactive } from "vue";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { NEW_PROJECT, NEW_HISTORIC_PROJECT } from "../constants";

const emits = defineEmits(["on-handle-action"]);

const menu = reactive([
  {
    mdiIcon: "mdi-account-hard-hat",
    name: NEW_PROJECT,
    label: "Create a New Project",
  },
  {
    mdiIcon: "mdi-clipboard-text-clock",
    name: NEW_HISTORIC_PROJECT,
    label: "Record Historic Project",
  },
]);

const onHandleAction = (name) => {
  emits("on-handle-action", name);
};
</script>
<style lang="scss" scoped>
.project_list:hover .project_list_icon,
.project_list:hover .project_list_text {
  color: rgba($orange, 1) !important;
}

.project_list:last-child {
  border: none !important;
  padding-bottom: 0;
}
.project_list_icon {
  width: 20px;
  height: 20px;
}
.card {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  position: relative;
  top: 15px;
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-image: url(../../../assets/icons/triangle.svg);
  }
}
</style>
