<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script setup>
import { polygon } from "leaflet";
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  polygonList: {
    type: Array,
    required: true,
  },
  location: Object,
});

const mapContainer = ref(null);
const mapInstance = ref(null);

const initMap = async () => {
  try {
    const center = calculatePolygonCenter(
      props.polygonList[0]?.polygon_points[0][0]
    );

    mapInstance.value = new window.google.maps.Map(mapContainer.value, {
      center: {
        lat: props.location?.latitude || center.lat,
        lng: props.location?.longitude || center.lng,
      },
      zoom: 23,
      mapTypeId: "satellite",
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      zoomControl: false,
      fullscreenControl: true,
      tilt: 0,
    });

    drawPolygons();
    addCoordinateLabels();
  } catch (error) {
    console.log("error", error);
  }
};

const drawPolygons = () => {
  try {
    props.polygonList.forEach((polygon) => {
      try {
        const polygonPaths = polygon.polygon_points[0][0]?.map(
          ([lng, lat]) => ({
            lat,
            lng,
          })
        );

        const polygonInstance = new google.maps.Polygon({
          paths: polygonPaths,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });

        polygonInstance.setMap(mapInstance.value);
      } catch (error) {
        console.log("");
      }
    });
  } catch (error) {
    console.log("Error drawing polygons:", error);
  }
};

const getRandomValues = (array, count) => {
  const result = [];
  const availableIndices = Array.from({ length: array.length }, (_, i) => i);

  while (result.length < count) {
    const randomIndex = Math.floor(Math.random() * availableIndices.length);
    const index = availableIndices.splice(randomIndex, 1)[0];
    result.push(array[index]);
  }

  return result;
};
const addCoordinateLabels = () => {
  try {
    const allPolygonCoord = props.polygonList.flatMap(
      (polygon) => polygon.polygon_points[0][0]
    );

    getRandomValues(
      allPolygonCoord,
      allPolygonCoord.length > 30 ? 30 : allPolygonCoord.length
    ).forEach(([lng, lat]) => {
      const contentString = `<div>${lat.toFixed(6)}, ${lng.toFixed(6)}</div>`;

      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
        position: { lat, lng },
        disableAutoPan: true,
        pixelOffset: new google.maps.Size(0, -2),
      });

      infoWindow.open(mapInstance.value);
    });
  } catch (error) {
    console.log("Error adding coordinate labels:", error);
  }
};

const calculatePolygonCenter = (polygonData) => {
  const allLatitudes = polygonData.map((coord) => coord[1]);
  const allLongitudes = polygonData.map((coord) => coord[0]);

  const avgLat =
    allLatitudes.reduce((sum, lat) => sum + lat, 0) / allLatitudes.length;
  const avgLng =
    allLongitudes.reduce((sum, lng) => sum + lng, 0) / allLongitudes.length;

  return { lat: avgLat, lng: avgLng };
};

onMounted(async () => {
  await initMap();
});

watch(
  () => props.polygons,
  () => {
    if (mapInstance.value) {
      drawPolygons();
      addCoordinateLabels();
    }
  }
);
</script>

<style lang="scss">
.map-container {
  width: 100%;
  height: 100%;
}
.gm-ui-hover-effect,
.gm-style-iw .gm-style-iw-chr {
  display: none !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}
.gm-style .gm-style-iw-tc {
  width: 15px !important;
}
.gm-style .gm-style-iw-tc::after {
  width: 100%;
}
.gm-style .gm-style-iw-d {
  overflow: unset !important;
  padding: 5px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  color: #0c0f4a !important;
}
</style>
