import axios from "axios";
import $axios from "../utils/axios-api-config";

export default {
  getUsersTextPromptsResponse(payload: {
    conversationId?: string;
    prompt: string;
  }) {
    return $axios.post(`/gemini-flash/prompt-question`, payload);
  },
  getUsersAudioPromptsResponse(formData: any) {
    return $axios.post(`/gemini-flash/speech-to-text`, formData);
  },
  getSpeechToSpeech(formData: any) {
    return $axios.post(`/gemini-flash/speech-to-speech`, formData);
  },
  getAudioFromText(payload: any) {
    return $axios.post(`/gemini-flash/text-to-speech`, payload);
  },
  getConversationData(conversationId: number) {
    return $axios.get(`/gemini-flash/conversation/${conversationId}`);
  },
  reGenerateResponse(payload: any) {
    return $axios.post(`/gemini-flash/regenerate-answer`, payload);
  },
  getConversationsList() {
    return $axios.get(`/gemini-flash/conversation-lists`);
  },
  editConversationQuestion(questionId: number, payload: { prompt: string }) {
    return $axios.put(`/gemini-flash/update-question/${questionId}`, payload);
  },
};
