<template>
  <svg
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.80741 24.3662C6.70545 17.609 11.0262 11.8284 16.9739 8.08992C22.4824 4.62752 28.8024 3.19312 35.2513 3.94159C39.9263 4.48389 44.4 6.19726 48.221 8.86331L45.2614 8.96542L45.3903 12.7146L55.1361 12.3794L54.7838 2.75391L51.0341 2.89119L51.1617 6.36043C46.6655 3.01431 41.3026 0.867103 35.6837 0.21488C32.1407 -0.195996 28.5855 -0.0171846 25.1172 0.747406C21.5278 1.53887 18.1162 2.94054 14.9772 4.91333C8.23952 9.14814 3.34516 15.6967 1.19551 23.3519C-0.938992 30.9519 -0.207623 39.0243 3.25429 46.082L6.62289 44.4297C3.56794 38.2021 2.92305 31.0765 4.80741 24.3662Z"
      fill="#9E9FB7"
    />
    <path
      d="M59.8533 16.2734L56.5888 18.1221C60.1728 24.4524 61.169 31.8345 59.3941 38.9083C57.6133 46.0041 53.2202 52.0568 47.0238 55.9516C41.3028 59.5474 34.4786 60.9725 27.8093 59.9646C22.6199 59.1805 17.7729 56.9717 13.8078 53.6353L17.3049 53.5147L17.1759 49.765L7.42969 50.1006L7.78243 59.7262L11.5316 59.5884L11.4192 56.5286C15.9057 60.2948 21.3853 62.788 27.249 63.6742C28.8324 63.9136 30.4236 64.0318 32.0095 64.0318C37.9918 64.0318 43.897 62.3483 49.0205 59.1277C56.0396 54.716 61.0156 47.8596 63.0328 39.8214C65.0437 31.8091 63.9147 23.4459 59.8533 16.2734Z"
      fill="#9E9FB7"
    />
    <path
      d="M32.1562 25.5645C28.8448 25.5645 26.1504 28.2584 26.1504 31.5703C26.1504 34.8817 28.8448 37.5756 32.1562 37.5756C35.4677 37.5756 38.1616 34.8817 38.1616 31.5703C38.1616 28.2584 35.4677 25.5645 32.1562 25.5645Z"
      fill="#9E9FB7"
    />
    <path
      d="M51.9126 36.515V26.6554L48.1116 25.9177C47.9089 25.3427 47.6748 24.7784 47.411 24.2283L49.5812 21.0189L42.6168 14.0404L39.4036 16.2042C38.8432 15.934 38.2686 15.6951 37.6824 15.4885L36.9447 11.6875H27.0851L26.3474 15.4885C25.7723 15.6912 25.2081 15.9252 24.6579 16.1891L21.4486 14.0189L14.4701 20.9833L16.6339 24.1965C16.3637 24.7569 16.1248 25.3314 15.9182 25.9172L12.1172 26.6554V36.515L15.9182 37.2527C16.1209 37.8277 16.3549 38.3915 16.6187 38.9421L14.4486 42.151L21.413 49.13L24.6267 46.9662C25.1866 47.2364 25.7611 47.4753 26.3474 47.6819L27.0851 51.4829H36.9447L37.6824 47.6819C38.2574 47.4792 38.8217 47.2451 39.3723 46.9813L42.5811 49.1515L49.5602 42.1871L47.3964 38.9739C47.666 38.4135 47.9054 37.839 48.1121 37.2527L51.9126 36.515ZM32.171 41.3424C26.7905 41.3424 22.4135 36.9654 22.4135 31.5849C22.4135 26.205 26.7905 21.828 32.171 21.828C37.551 21.828 41.9279 26.205 41.9279 31.5849C41.9279 36.9654 37.551 41.3424 32.171 41.3424Z"
      fill="#9E9FB7"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
