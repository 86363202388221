<template>
  <GeneralDialog :showBackButton="false" @on-close="onClickCancel"
    ><template #body
      ><h6
        class="tw-mt-4 tw-text-2xl tw-font-bold tw-text-[#0C0F4A] tw-tracking-[0.25px] tw-text-center"
      >
        Send Invitation?
      </h6>
      <p
        class="tw-mt-2 tw-text-sm tw-font-bold tw-text-[#0c0f4a80] tw-tracking-[0.14px] tw-text-center"
      >
        Are you sure you want to send invitation for estimation?
      </p></template
    ><template #footer>
      <div class="tw-flex tw-gap-3 tw-items-center tw-justify-between">
        <v-btn class="button button-purple-border" @click="onClickCancel()"
          >CANCEL</v-btn
        ><v-btn class="button button-orange" @click="onClickConfirmButton"
          >CONFIRM</v-btn
        >
      </div></template
    ></GeneralDialog
  >
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import projectService from "@/core/services/project.service";
export default {
  components: {
    GeneralDialog,
  },
  props: {
    selectedContractor: { type: Object, default: null },
  },
  setup(props: any, ctx: any) {
    const onClickCancel = () => {
      ctx.emit("closeInviteButtonModal");
    };
    const onClickConfirmButton = async () => {
      await projectService.inviteContractorToProject(props.selectedContractor);
      ctx.emit("closeInviteButtonModal");
    };
    return { onClickConfirmButton, onClickCancel };
  },
};
</script>
