<template>
  <WizardModal
    :hideFooter="false"
    :hideStepper="true"
    :hasCustomActionFooter="true"
    :hideBackButton="true"
    :singleData="modelDetails"
    :showHeaderInMobile="true"
    :hideTitleAndDescription="true"
    :contentOverflowHidden="true"
    @on-close="onClickBackButton"
  >
    <template #content>
      <main
        v-if="courseId && courseEligibility"
        class="tw-overflow-y-auto tw-flex tw-flex-col tw-h-full tw-min-h-[unset] tw-justify-between !tw-mt-0 rmd:!tw-mt-4 tw-gap-4 tw-pt-0 tw-box-border"
        :class="{ '!tw-pt-0 rmd:!tw-pt-12': courseId === 2 }"
      >
        <section class="tw-flex tw-flex-col tw-h-full tw-gap-4">
          <div
            class="tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-6 rmd:!tw-gap-8 tw-items-start"
          >
            <Text
              class="rmd:!tw-text-sm"
              variant="span"
              textWeight="500"
              v-if="courseId === 2"
            >
              To qualify for the course you need a
              <strong
                >valid 18th Edition & 1 of the 3 prerequisite’s below</strong
              >
              the dotted line & subscribe for a
              <strong>minimum of 3 months</strong>, for £9.99 + VAT/ monthly.
            </Text>
            <Text
              class="rmd:!tw-text-sm"
              variant="span"
              textWeight="500"
              v-else
            >
              To qualify for the course each candidate requires
              <strong>1 valid prerequisite from below</strong> & subscribe for a
              <strong>minimum of 3 months</strong>, for £9.99 + VAT/ monthly,
              per spot.
            </Text>
            <div
              class="tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4 rmd:!tw-gap-6 tw-items-start"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-pb-4 tw-box-border tw-border-b-dashed-custom tw-w-full"
              >
                <ListIcon
                  class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-min-w-4 rmd:tw-min-h-4 rmd:tw-w-4 rmd:tw-h-4"
                />
                <Text
                  class="rmd:!tw-text-base"
                  variant="xsmall"
                  whiteSpace="wrap"
                  lineHeight="18px"
                  >18th Edition (Issued within the last 5 years)</Text
                >
              </div>
              <div
                v-for="(eligibility, index) in courseEligibility"
                :key="index"
                class="tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-2"
              >
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <ListIcon
                    class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-min-w-4 rmd:tw-min-h-4 rmd:tw-w-4 rmd:tw-h-4"
                  />
                  <Text
                    class="rmd:!tw-text-base"
                    variant="xsmall"
                    whiteSpace="wrap"
                    lineHeight="18px"
                    >{{ eligibility }}</Text
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </template>
    <template #footer-action>
      <section
        class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-full tw-gap-4 md:!tw-gap-[8px]"
      >
        <Button label="Reserve my spot" @click="onReserveSpot" />
        <Text
          variant="xsmall"
          textWeight="400"
          class="!tw-italic tw-pb-4 md:!tw-leading-[12px] rmd:!tw-text-[16px]"
          lineHeight="18px"
          textAlign="center"
          ><strong>Please note:</strong> Verified workstation is required to be
          fully enrolled on the course</Text
        >
      </section>
    </template>
  </WizardModal>
  <CertificationModel
    v-if="isShowCertificateModal"
    @onClickCloseTab="onClickCloseModel"
  />
</template>
<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CertificationModel from "@/modules/certification/components/CertificationModel.vue";

// import CommonDialog from "@/core/components/CommonDialog.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";

const store = useStore();
const route = useRoute();
const router = useRouter();
const courseId = ref(null);
const courseEligibility = ref([]);

const isLoading = ref(false);
const isShowCertificateModal = ref(false);

const modelDetails = {
  id: 2,
  tab: "entryRequirements",
  name: "entryRequirements",
  header: "Entry Requirements",
};

const eligibilityGreenSkills = [
  "NVQ Level 3 Electrical Installation & Maintenance",
  "ECS Gold Card - Electrical Installation",
  "Part 1 & 2 Electrical Installation (Issued before 1994)",
];

const eligibilityAirSource = [
  "Gas Safe (CENWAT)",
  "OFTEC (OFT10-105E or OFT15-108W), MCS (Heat Pump or Solar Thermal Hot Water) or HETAS (H004) registration",
  "N/SVQ Level 2/3 in Plumbing or equivalent earlier certification that provides evidence of competence",
  "N/SVQ Level 2/3 in Heating and Ventilating (Domestic Installation) or equivalent earlier certification that provides evidence of competence",
  "N/SVQ Level 2/3 in Heating and Ventilating (Industrial and Commercial Installation) or equivalent earlier certification that provides evidence of competence",
  "N/SVQ Level 2/3 in Oil-Fired Technical Services or equivalent earlier certification that provides evidence of competence",
  "N/SVQ Level 2/3 in Gas Installation and Maintenance or equivalent earlier certification that provides evidence of competence",
];

const emits = defineEmits(["on-close", "on-reserve-spot", "on-back"]);

const onClickBackButton = () => {
  return emits("on-back");
};

const onCreateWorkstation = () => {
  onClickBackButton();
  store.dispatch(`${WORKSTATION}/setWsModal`, {
    isShowWorkStationModal: true,
  });
  router.push({ name: DASHBOARD_ROUTE });
};

const onReserveSpot = () => {
  onClickBackButton();
  emits("on-reserve-spot");
};

onBeforeMount(() => {
  courseId.value = parseInt(route.params?.courseId, 10);
  if (courseId.value && courseId.value === 2) {
    courseEligibility.value = eligibilityGreenSkills;
  } else if (courseId.value && courseId.value === 1) {
    courseEligibility.value = eligibilityAirSource;
  }
});

onBeforeUnmount(() => {
  //   eligibility.value = null;
});
</script>
<style lang="scss" scoped></style>
