<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_994_92694)">
      <path
        d="M7.46 0.911875L2.79333 2.98521C2.31333 3.19854 2 3.67854 2 4.20521V7.33854C2 11.0385 4.56 14.4985 8 15.3385C11.44 14.4985 14 11.0385 14 7.33854V4.20521C14 3.67854 13.6867 3.19854 13.2067 2.98521L8.54 0.911875C8.2 0.758542 7.8 0.758542 7.46 0.911875ZM6.19333 10.8652L4.46667 9.13854C4.20667 8.87854 4.20667 8.45854 4.46667 8.19854C4.72667 7.93854 5.14667 7.93854 5.40667 8.19854L6.66667 9.45188L10.5867 5.53188C10.8467 5.27187 11.2667 5.27187 11.5267 5.53188C11.7867 5.79187 11.7867 6.21187 11.5267 6.47188L7.13333 10.8652C6.88 11.1252 6.45333 11.1252 6.19333 10.8652Z"
        fill="#40AE42"
      />
    </g>
    <defs>
      <clipPath id="clip0_994_92694">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts">
export default {
  name: "CheckGreenShield",
  props: {
    className: String,
  },
};
</script>
