<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_224_11162)">
      <rect
        x="34"
        y="34"
        width="24"
        height="24"
        rx="8"
        transform="rotate(-180 34 34)"
        fill="white"
      />
    </g>
    <path
      d="M22 14C17.584 14 14 17.584 14 22C14 26.416 17.584 30 22 30C26.416 30 30 26.416 30 22C30 17.584 26.416 14 22 14ZM22 22.8C21.56 22.8 21.2 22.44 21.2 22V18.8C21.2 18.36 21.56 18 22 18C22.44 18 22.8 18.36 22.8 18.8V22C22.8 22.44 22.44 22.8 22 22.8ZM22.8 26H21.2V24.4H22.8V26Z"
      fill="#FA4B00"
    />
    <defs>
      <filter
        id="filter0_d_224_11162"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0470588 0 0 0 0 0.0588235 0 0 0 0 0.290196 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_224_11162"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_224_11162"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "ErrorRedIcon",
};
</script>
