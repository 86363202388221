<template>
  <main>
    <!-- desktop filter -->
    <div class="desktop__wrapper tw-mt-6">
      <div class="lg:tw-hidden tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex tw-gap-[8px] tw-w-full tw-mb-2">
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[294px] c-input input_field"
            placeholder="Search"
            v-model="filters.search"
          />
          <InputField
            prepend-inner-icon="mdi-map-marker"
            class="tw-max-w-[143px] c-input input_field"
            placeholder="KT19 9JG"
            v-model="filters.postcode"
          />
          <!-- <InputField
            prepend-inner-icon="mdi-arrow-left-right"
            class="tw-max-w-[143px] c-input input_field"
            placeholder="Miles"
            type="number"
            v-model="filters.radius"
          /> -->
        </div>

        <div class="tw-flex tw-gap-2 tw-justify-end tw-items-center">
          <v-btn-toggle v-model="toggledView" mandatory>
            <v-btn
              prepend-icon="mdi-view-grid"
              :active="toggledView === 'tile'"
              :color="toggledView === 'tile' ? '#FAA100' : ''"
              class="tab__buttons"
              variant="plain"
              value="tile"
              >Tile</v-btn
            >
            <v-btn
              prepend-icon="mdi-map"
              :active="toggledView === 'map'"
              :color="toggledView === 'map' ? '#FAA100' : ''"
              class="tab__buttons"
              variant="plain"
              value="map"
              >Map</v-btn
            >
          </v-btn-toggle>
          <div class="sort__wrapper desktop__wrapper">
            <v-menu
              :persistent="true"
              :close-on-content-click="false"
              class="desktop_sort_menu"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain">
                  Sort By
                  <span>
                    <img :src="require('@/assets/icons/sort.svg')" alt="" />
                  </span>
                </v-btn>
              </template>
              <v-radio-group v-model="filters.order">
                <v-radio
                  label="Most Recent"
                  value="DESC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
              </v-radio-group>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="lg:tw-hidden tw-flex tw-gap-2 tw-w-full">
        <!-- <v-select
          prepend-inner-icon="mdi-tools"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="Skills Required"
          item-value="id"
          item-title="tpLabel"
          :items="listOfCategories"
          v-model="filters.specialism"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          multiple
          clearable
          variant="outlined"
        ></v-select> -->
        <v-select
          prepend-inner-icon="mdi-newspaper-variant"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="All Jobs (Including Emergency)"
          :items="jobTypes"
          item-title="text"
          item-value="value"
          v-model="filters.projectTypes"
          clearable
          variant="outlined"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
        >
        </v-select>
        <InputSelect
          prepend-inner-icon="mdi-medal-outline"
          class="tw-max-w-full tw-w-1/4 input_field"
          placeholder="Verified Property Owners Only"
          v-model="filters.verifiedOwners"
          :items="['All (Project Owners)', 'Verified & Clients']"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          variant="outlined"
        />
        <v-select
          prepend-inner-icon="mdi-account-hard-hat"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="Commercial / Residential"
          item-title="text"
          item-value="value"
          :items="propertyTypeItems"
          v-model="filters.propertyCategory"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          clearable
          variant="outlined"
        ></v-select>
      </div>
    </div>

    <!-- Mobile filter -->
    <div class="mobile__wrapper tw-hidden lg:tw-block mobile__filter tw-mt-6">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <template v-slot:title>
            <img src="../../../assets/icons/filter-icon.svg" />
            <h6>All Filters</h6>
          </template>
          <v-expansion-panel-text>
            <div class="mobile__inputs">
              <div class="tw-flex tw-gap-[8px] tw-w-full tw-mb-2">
                <InputField
                  prepend-inner-icon="mdi-magnify"
                  class="tw-max-w-full tw-w-3/5 c-input input_field"
                  placeholder="Search"
                  v-model="filters.search"
                />
                <InputField
                  prepend-inner-icon="mdi-map-marker"
                  class="tw-max-w-full tw-w-1/5 c-input input_field"
                  placeholder="KT19 9JG"
                  v-model="filters.postcode"
                />
                <!-- <InputField
                  prepend-inner-icon="mdi-arrow-left-right"
                  class="tw-max-w-full tw-w-1/5 c-input input_field"
                  placeholder="Miles"
                  type="number"
                  v-model="filters.radius"
                /> -->
              </div>
              <div class="tw-flex tw-gap-2 tw-w-full tw-flex-col">
                <!-- <v-select
                  prepend-inner-icon="mdi-tools"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Skills Required"
                  item-value="id"
                  item-title="tpLabel"
                  :items="listOfCategories"
                  v-model="filters.specialism"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  multiple
                  clearable
                  variant="outlined"
                ></v-select> -->
                <v-select
                  prepend-inner-icon="mdi-newspaper-variant"
                  class="tw-max-w-full c-select input_field"
                  placeholder="All Jobs (Including Emergency)"
                  :items="jobTypes"
                  item-title="text"
                  item-value="value"
                  v-model="filters.projectTypes"
                  clearable
                  variant="outlined"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                >
                </v-select>
                <InputSelect
                  prepend-inner-icon="mdi-medal-outline"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Verified Property Owners Only"
                  v-model="filters.verifiedOwners"
                  :items="['All (Project Owners)', 'Verified & Clients']"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  variant="outlined"
                />
                <v-select
                  prepend-inner-icon="mdi-account-hard-hat"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Commercial / Residential"
                  item-title="text"
                  item-value="value"
                  :items="propertyTypeItems"
                  v-model="filters.propertyCategory"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  clearable
                  variant="outlined"
                ></v-select>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div>
      <!-- <CommonLoader
        v-if="loading"
        :loading="loading"
        class="loader-container"
      /> -->
      <div
        v-if="loading"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-[calc(100dvh-221px)]"
      >
        <Loader :show="loading" />
      </div>
      <div v-if="!loading">
        <div class="tw-hidden lg:tw-flex tw-items-center tw-justify-between">
          <v-btn-toggle v-model="toggledView" mandatory>
            <v-btn
              prepend-icon="mdi-view-grid"
              :active="toggledView === 'tile'"
              :color="toggledView === 'tile' ? '#FAA10066' : ''"
              class="tw-text-[12px]"
              variant="plain"
              value="tile"
              @click="onClickToggleView('tile')"
              >Tile</v-btn
            >
            <v-btn
              prepend-icon="mdi-map"
              :active="toggledView === 'map'"
              :color="toggledView === 'map' ? '#FAA10066' : ''"
              class="tw-text-[12px]"
              variant="plain"
              value="map"
              @click="onClickToggleView('map')"
              >Map</v-btn
            >
          </v-btn-toggle>
          <div class="sort__wrapper mobile__wrapper">
            <v-menu
              :persistent="true"
              :close-on-content-click="false"
              class="mobile_sort_menu"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="plain"
                  @click="openSortButton"
                  menu
                >
                  Sort By
                  <span>
                    <img :src="require('@/assets/icons/sort.svg')" alt="" />
                  </span>
                </v-btn>
              </template>
              <v-radio-group v-model="filters.order">
                <v-radio
                  label="Most Recent"
                  value="DESC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
              </v-radio-group>
            </v-menu>
          </div>
        </div>

        <div class="tw-flex tw-gap-3 tw-mt-6 tw-mb-4 md:!tw-mt-0">
          <p
            class="tw-text-xs tw-font-normal tw-text-[#0C0F4A80] tw-tracking-[0.4px] tw-leading-4"
          >
            <strong>{{ totalDraftJobs }}</strong> Results
          </p>
        </div>

        <div
          class="tw-flex tw-gap-4 tw-overflow-y-auto tw-overflow-x-hidden no-scrollbar md:tw-max-h-[unset] working-2"
          :class="
            toggledView === 'map' && jobsState.length
              ? 'md:tw-px-0 md:tw-overflow-x-auto'
              : ''
          "
        >
          <div
            class=""
            :class="
              toggledView === 'tile' || !jobsState.length
                ? 'tw-w-full '
                : ' tw-w-1/2 md:tw-absolute md:tw-z-[999] md:tw-bottom-0 md:tw-w-full md:tw-max-w-full md:tw-h-auto md:tw-overflow-y-hidden md:tw-overflow-x-auto  md:tw-flex  md:tw-items-end working-1 tw-max-h-[620px] tw-overflow-auto no-scrollbar'
            "
          >
            <div
              v-if="!jobsState.length && !loading"
              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-9 md:tw-mb-3 md:tw-gap-4"
            >
              <div class="tw-flex tw-flex-col">
                <img
                  width="340"
                  height="168"
                  src="../../../assets/images/property-owner-dashboard.png"
                />
              </div>
              <div class="title">
                <h3
                  class="tw-mb-6 tw-text-2xl tw-text-[#0C0F4A] tw-leading-[30.12px] tw-tracking-[0.25px] tw-font-normal"
                >
                  Find your next job, simply and easily.
                </h3>
                <p
                  class="tw-text-sm tw-text-[#0C0F4A80] tw-leading-[17.57px] tw-tracking-[0.15px] tw-font-medium"
                >
                  Change your radius or add services to find more jobs.
                </p>
              </div>
            </div>
            <div
              v-if="jobsState.length && !loading"
              @scroll="onScroll"
              class="tw-flex tw-gap-5 tw-flex-wrap scroll-container"
              :class="
                toggledView === 'map' && jobsState.length ? 'working ' : ''
              "
            >
              <JobCard
                v-for="job in jobsState"
                :key="job.refId"
                @onSelect="setSelectedJob"
                :value="job.refId"
                :job="job"
                jobType="all"
                :class="`${
                  selectedJob === job.refId
                    ? 'tw-shadow-[0_0_0_4px_#FAA100_inset]'
                    : ''
                } ${
                  toggledView === 'map' && jobsState.length
                    ? 'tw-max-w-full tw-w-[calc(50%-14px)] xl:tw-w-full md:tw-min-w-[360px] md:tw-max-w-[360px]'
                    : '!tw-w-[calc(25%-15px)] 3xl:!tw-w-[calc(33.33%-14px)] xl:!tw-w-[calc(50%-10px)] md:!tw-w-full !tw-max-w-full'
                }`"
              />
            </div>
            <div
              v-if="isLoadingMorePage"
              class="tw-absolute tw-bottom-[100px] tw-left-1/2 -tw-translate-1/2"
            >
              <Loader :show="isLoadingMorePage" />
            </div>
          </div>
          <template v-if="toggledView === 'map' && jobsState.length">
            <div
              class="tw-w-[calc(50%-16px)] tw-max-w-full md:tw-w-full md:tw-relative md:z-0 tw-h-[620px]"
            >
              <!-- <JobMap :data="jobsState" /> -->
              <PropertyMapModal :properties="jobPropertyAddress" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import JobCard from "./JobCard.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";

import JobMap from "./JobMap.vue";
import { onMounted, reactive, ref, watch } from "vue";
import { JOB_DETAILS_ROUTE } from "../routes";
import { computed } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JobFilters, JobsState } from "@/core/models/jobs";
import debounce from "lodash/debounce";
import Loader from "@/core/components/common/Loader.vue";
import { USER_STORE } from "@/store/modules/user";
import {
  ProjectTypesEnum,
  PropertyCategoryEnum,
} from "@/core/enums/ProjectsEnum";

const router = useRouter();
const store = useStore();
const isLoadingMorePage = ref(false);
const selectedJob = ref("");
const toggledView = ref("tile");
const loading = ref(false);
const listOfCategories = ref([]) as any;

const jobTypes = ref([
  {
    text: "Emergency",
    value: ProjectTypesEnum.EMERGENCY,
  },
  {
    text: "Routine",
    value: ProjectTypesEnum.ROUTINE,
  },
  {
    text: "Standard",
    value: ProjectTypesEnum.STANDARD,
  },
  {
    text: "Video Consultation",
    value: ProjectTypesEnum.VIDEO_CONSULTATION,
  },
]);

const propertyTypeItems = computed(() => {
  return [
    {
      text: "Commercial",
      value: PropertyCategoryEnum.COMMERCIAL,
    },
    { text: "Residential", value: PropertyCategoryEnum.RESIDENTIAL },
  ];
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const jobsState = computed<JobsState["jobs"]>(() =>
  store.getters[`${JOBS_STORE}/getDraftJobs`]
    .map((job: any) => ({
      ...job,
      properties: job.projectProperties
        ?.filter((projProperty: any) => projProperty.property)
        ?.map((projProperty: any) => projProperty.property),
    }))
    .filter((property: any) => property)
);

const jobPropertyAddress = computed(() => {
  let address: any = [];

  jobsState.value.forEach((job: any) => {
    if (job?.projectProperties?.length) {
      job?.projectProperties?.forEach((item: any) => {
        address.push({ addressData: item?.property?.addressData });
      });
    }
  });
  return address;
});
const selectedCategory = ref([]);
const panel = ref([]);

const filters = reactive({
  search: "",
  postcode: "",
  radius: "",
  projectTypes: null,
  verifiedOwners: null,
  propertyCategory: null,
  specialism: [],
  order: "ASC",
  limit: 20,
  page: 1,
}) as any;

const prevPage = ref(filters.page);
const setSelectedJob = async (value: string) => {
  await store.dispatch(`${JOBS_STORE}/fetchJobDetails`, value);

  router.push({ name: JOB_DETAILS_ROUTE, params: { refId: value } });
  selectedJob.value = value;
};

const onClickToggleView = (value: string) => {
  toggledView.value = value;
};

// FetchJobs
const refetchJobs = debounce(async (state: JobFilters) => {
  loading.value = true;
  try {
    await store.dispatch(`${JOBS_STORE}/fetchDraftJobs`, {
      ...state,
    });
  } finally {
    loading.value = false;
  }
}, 500);

watch(
  filters,
  async (state: any) => {
    let params = JSON.parse(JSON.stringify({ ...state }));
    if (state.page == prevPage.value && state.page != 1) {
      state.page = 1;
    }
    prevPage.value = state.page;
    // Handle verifiedOwners logic
    if (params.verifiedOwners === "All (Project Owners)") {
      delete params.verifiedOwners;
    } else if (params.verifiedOwners === "Verified & Clients") {
      params.verifiedOwners = true;
    }

    if (!params.specialism?.length) {
      delete params.specialism;
    } else {
      params.specialism = params.specialism.toString();
    }

    params = Object.fromEntries(
      Object.entries(params).filter((value: any) =>
        value[1] === 0 ? true : value[1]
      )
    );

    if (filters.page > 1) {
      await store.dispatch(`${JOBS_STORE}/fetchDraftJobs`, {
        ...params,
      });
      isLoadingMorePage.value = false;
    } else {
      refetchJobs(params);
    }
  },
  { deep: true }
);

const openSortButton = () => {
  panel.value = [];
};

const totalDraftPages = computed(
  () => store.getters[`${JOBS_STORE}/getTotalDraftPages`]
);

const totalDraftJobs = computed(
  () => store.getters[`${JOBS_STORE}/getTotalDraftJobs`]
);
const onScroll = (event: Event) => {
  const container = event.target as HTMLElement;
  const tolerance = 1;
  console.log("totalDraftPages " + totalDraftPages.value);
  if (
    Math.abs(
      container.scrollHeight - container.scrollTop - container.clientHeight
    ) <= tolerance
  ) {
    if (filters.page + 1 <= totalDraftPages.value) {
      isLoadingMorePage.value = true;
      setTimeout(() => {
        filters.page += 1;
      }, 1000);
    }
  }
};

onMounted(async () => {
  listOfCategories.value = await store.dispatch(`${USER_STORE}/getCategories`);
  let postData = JSON.parse(JSON.stringify({ ...filters }));
  if (!postData.specialism?.length) {
    delete postData["specialism"];
  } else {
    postData.specialism = postData.specialism.toString();
  }
  postData = Object.fromEntries(
    Object.entries(postData).filter((value: any) =>
      value[1] === 0 ? true : value[1]
    )
  );

  // if (user.value.postcode) {
  //   postData.postcode = user.value.postcode;
  //   filters.postcode = user.value.postcode;
  // }

  loading.value = true;
  try {
    await store.dispatch(`${JOBS_STORE}/fetchDraftJobs`, postData);
  } catch (error) {
    console.log();
  } finally {
    loading.value = false;
  }
});
</script>
<script lang="ts">
import { defineComponent } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import PropertyMapModal from "@/modules/properties/components/Modals/PropertyMapModal.vue";

export default defineComponent({
  name: "DraftJobListView",
});
</script>

<style lang="scss" scoped>
.scroll-container {
  max-height: 800px;
  overflow-y: scroll;
}
.custom-select {
  @include respond(md) {
    width: 100% !important;
  }
}

/* Sort Button */
.sort__wrapper {
  .v-btn {
    padding: 0;
    height: auto;
    :deep(.v-btn__content) {
      text-transform: capitalize;
      display: flex;
      gap: 10px;
      align-items: center;
      span {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($blueDark, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    :deep(.v-ripple__container) {
      display: none !important;
    }
  }
}
.v-menu {
  :deep(.v-overlay__content) {
    background-color: rgba($white, 1);
    min-width: 185px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    padding: 16px;
    // top: 230px !important;
    // left: auto !important;
    // right: 30px !important;
    // @include respond(xlg) {
    //   right: 10px !important;
    // }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 40px;
    //   height: 40px;
    //   background-image: url(../../../assets/icons/triangle.svg);
    //   right: 10px;
    //   top: -20px;
    // }
    .v-input {
      .v-input__control {
        .v-selection-control-group {
          gap: 10px;
          .v-selection-control {
            gap: 8px;
            .v-selection-control__wrapper {
              width: auto;
              height: auto;
              .v-selection-control__input {
                width: auto;
                height: auto;
              }
            }
            .v-label {
              @include fluidFont(14, 14, 1.3);
              font-weight: 600;
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
        .v-field {
          padding: 0 10px;
          border: 1px solid rgba($buttonText, 0.4);
          border-radius: 8px;
          background-color: transparent;
          .v-field__prepend-inner {
            padding: 0;
            width: 16px;
            height: 16px;
            .v-icon {
              opacity: 1;
              @include fluidFont(16, 16, 1);
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              filter: invert(13%) sepia(20%) saturate(5279%) hue-rotate(219deg)
                brightness(80%) contrast(112%);
              vertical-align: middle;
            }
          }
          .v-field__field {
            display: block;
            .v-field__input {
              padding: 0;
              background-color: transparent;
              min-height: 40px;
              padding-left: 8px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.4px;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              margin: 0;
              top: 0;
              input {
                top: 50%;
                transform: translateY(-50%);
                &::placeholder {
                  color: rgba($blueDark, 1);
                  opacity: 1;
                }
              }
            }
          }
          .c-input .v-field__outline {
            display: none;
          }
        }
      }
    }
  }
}
/* mobile Sort */
// .mobile_sort_menu {
//   :deep(.v-overlay__content) {
//     @include respond(s1024) {
//       top: 280px !important;
//     }
//     @include respond(sm) {
//       top: 260px !important;
//       right: 0 !important;
//     }
//   }
// }
/*Mobile Filters */
.mobile__filter {
  :deep(.v-expansion-panels) {
    .v-expansion-panel {
      background-color: transparent;
      border: 1px solid #c5d2f2;
      border-radius: 8px;
      .v-expansion-panel__shadow {
        box-shadow: none;
      }
      .v-expansion-panel-title {
        .v-expansion-panel-title__overlay {
          background-color: transparent;
        }
        img {
          width: 16px;
          margin-right: 8px;
        }
        h6 {
          @include fluidFont(12, 12, 1.2);
          font-weight: 700;
          letter-spacing: 0.4px;
          color: rgba($blueDark, 1);
        }
      }
      .v-expansion-panel-text {
        .v-expansion-panel-text__wrapper {
          padding: 8px 24px 16px;
          flex: 1 1 auto;
          max-width: 100%;
          .mobile__inputs {
            .v-input {
              width: 100%;
              max-width: 100%;
              min-width: unset;
              background-color: transparent;
              margin-bottom: 0;
              .v-input__control {
                .v-field {
                  padding: 0 10px;
                  border: 1px solid rgba($buttonText, 0.4);
                  border-radius: 8px;
                  background-color: transparent;
                  .v-field__prepend-inner {
                    padding: 0;
                    width: 16px;
                    height: 16px;
                    .v-icon {
                      opacity: 1;
                      @include fluidFont(16, 16, 1);
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      filter: invert(13%) sepia(20%) saturate(5279%)
                        hue-rotate(219deg) brightness(80%) contrast(112%);
                      vertical-align: middle;
                    }
                  }
                  .v-field__field {
                    display: block;
                    .v-field__input {
                      padding: 0;
                      background-color: transparent;
                      min-height: 40px;
                      padding-left: 8px;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      max-width: 100%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      margin: 0;
                      top: 0;
                      input {
                        top: 50%;
                        transform: translateY(-50%);
                        &::placeholder {
                          color: rgba($blueDark, 1);
                          opacity: 1;
                        }
                      }
                    }
                  }
                  .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}

.v-input {
  width: 100%;
  min-width: unset;
  background-color: transparent;
  margin-bottom: 0;
  :deep(.v-input__control) {
    .v-field {
      padding: 0 10px;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      background-color: rgba($white, 1);
      align-items: center;
      .v-field__prepend-inner {
        padding: 0;
        width: 16px;
        height: 16px;
        .v-icon {
          opacity: 1;
          @include fluidFont(16, 16, 1);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: invert(13%) sepia(20%) saturate(5279%) hue-rotate(219deg)
            brightness(80%) contrast(112%);
          vertical-align: middle;
        }
      }
      .v-field__field {
        display: block;
        .v-field__input {
          padding: 0;
          background-color: transparent;
          min-height: 40px;
          padding-left: 8px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          letter-spacing: 0.4px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
          top: 0;
          input {
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            &::placeholder {
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
        &::before {
          border: none;
        }
      }
    }
  }
}
.v-field--variant-filled .v-field__outline::before,
.v-field--variant-underlined .v-field__outline::before {
  border: none;
}
.tab__buttons {
  padding: 0;
  opacity: 1;
  :deep(.v-btn__content) {
    @include fluidFont(12, 12, 1.2);
    font-weight: 600;
    text-transform: capitalize;
    color: rgba($blueDark, 0.4);
  }
  :deep(.v-btn__prepend) {
    color: rgba($blueDark, 0.4);
  }
}
.v-btn--active {
  :deep(.v-btn__content) {
    color: rgba($orange, 1);
  }
  :deep(.v-btn__prepend) {
    color: rgba($orange, 1);
  }
}
@media (max-width: 767px) {
  .working {
    flex-wrap: unset;
  }
}
</style>
