<template>
  <div class="projectlogs__cardslist">
    <div class="projectlogs__cardsitems">
      <div class="first-row">
        <div
          v-if="
            project?.historicalProjectType ===
            HistoricalProjectTypeEnum.DIY_PROJECT
          "
          class="card__header"
        >
          <div class="custom__chip">
            <v-icon>
              <img src="../../../../assets/icons/user.svg" alt="" />
            </v-icon>
            <p>diy</p>
          </div>
          <p class="date_wrapper">
            {{ moment(project?.dateCreated).format("DD/MM/YYYY") }}
          </p>
        </div>
        <div
          v-else-if="
            project?.historicalProjectType ==
              HistoricalProjectTypeEnum.TRADE_VERIFY &&
            [
              HistoricProjectStatusEnum.VERIFIED,
              HistoricProjectStatusEnum.ACCEPTED,
            ].includes(project?.projectHistoricalStatus)
          "
          class="card__header"
        >
          <div class="custom__chip">
            <v-icon>
              <img src="../../../../assets/icons/user.svg" alt="" />
            </v-icon>
            <p>trade <span> verified</span></p>
          </div>
          <p class="date_wrapper">
            {{ moment(project?.dateCreated).format("DD/MM/YYYY") }}
          </p>
        </div>
        <!-- v-else-if ="
            project?.historicalProjectType ===
              HistoricalProjectTypeEnum.TRADE_VERIFY &&
            [
              HistoricProjectStatusEnum.DRAFT,
              HistoricProjectStatusEnum.PENDING,
              HistoricProjectStatusEnum.REJECTED,
            ].includes(project?.projectHistoricalStatus)
          " -->
        <div v-else class="card__header">
          <div class="custom__chip">
            <v-icon>
              <img src="../../../../assets/icons/user.svg" alt="" />
            </v-icon>
            <p>trade</p>
          </div>
          <p class="date_wrapper">
            {{ moment(project?.dateCreated).format("DD/MM/YYYY") }}
          </p>
        </div>
      </div>
      <div class="second-row">
        <h4>{{ project?.name }}</h4>
        <div class="second-row__sub">
          <EmergencyChip v-if="project?.type === 'emergency'" />
          <StandardChip v-if="project?.type === 'standard'" />
          <RoutineChip v-if="project?.type === 'routine'" />

          <CommercialChip
            v-if="
              propertyDetails?.propertyCategory === PropertyTypeEnum.COMMERCIAL
            "
          />
          <ResidentialChip
            v-if="
              propertyDetails?.propertyCategory === PropertyTypeEnum.RESIDENTIAL
            "
          />
        </div>
        <div class="description">
          <p ref="descriptionElement">
            {{ projectDescription }}
            <span
              v-if="stringWithoutTags.length > 100"
              @click="toggleReadMore"
              >{{ buttonText }}</span
            >
          </p>
        </div>
      </div>
      <div class="projectlogs__cardfooter">
        <v-stepper model-value="0" mobile class="stepper__wrapper">
          <v-stepper-header>
            <div
              class="stepper__step inprogress"
              :class="{
                inprogress: [].includes(getProjectStatus.value),
              }"
            >
              <v-stepper-item
                :complete="
                  [
                    ProjectJobStatusEnum.COMPLETED,
                    ProjectJobStatusEnum.QUOTE_ACCEPTED,
                    ProjectJobStatusEnum.QUOTE_CONFIRMED,
                  ].includes(getProjectStatus.value)
                "
                value="P1"
              ></v-stepper-item>
            </div>
            <div
              class="stepper__step"
              :class="{
                inprogress: [ProjectJobStatusEnum.QUOTE_ACCEPTED].includes(
                  getProjectStatus.value
                ),
                unselected: [
                  ProjectJobStatusEnum.DRAFT_PROJECT,
                  ProjectJobStatusEnum.CANCELLED,
                  ProjectJobStatusEnum.AWAITING_QUOTES,
                  ProjectJobStatusEnum.QUOTES_RECEIVED,
                ].includes(getProjectStatus.value),
              }"
            >
              <div class="step__connector"></div>
              <v-stepper-item
                value="P2"
                :complete="
                  [
                    ProjectJobStatusEnum.COMPLETED,
                    ProjectJobStatusEnum.QUOTE_CONFIRMED,

                    ProjectJobStatusEnum.IN_PROGRESS,
                  ].includes(getProjectStatus.value)
                "
              ></v-stepper-item>
            </div>
            <div
              class="stepper__step"
              :class="{
                inprogress: [ProjectJobStatusEnum.IN_PROGRESS].includes(
                  getProjectStatus.value
                ),
                unselected: [
                  ProjectJobStatusEnum.DRAFT_PROJECT,
                  ProjectJobStatusEnum.QUOTES_RECEIVED,
                  ProjectJobStatusEnum.QUOTE_ACCEPTED,
                  ProjectJobStatusEnum.CANCELLED,
                  ProjectJobStatusEnum.AWAITING_QUOTES,
                ].includes(getProjectStatus.value),
              }"
            >
              <div class="step__connector"></div>
              <v-stepper-item
                value="P3"
                :complete="
                  [
                    ProjectJobStatusEnum.COMPLETED,
                    ProjectJobStatusEnum.QUOTE_CONFIRMED,
                  ].includes(getProjectStatus.value)
                "
              ></v-stepper-item>
            </div>
            <div
              class="stepper__step"
              :class="{
                inprogress: [].includes(getProjectStatus.value),
                unselected: [
                  ProjectJobStatusEnum.DRAFT_PROJECT,
                  ProjectJobStatusEnum.QUOTES_RECEIVED,
                  ProjectJobStatusEnum.QUOTE_ACCEPTED,
                  ProjectJobStatusEnum.AWAITING_QUOTES,
                  ProjectJobStatusEnum.IN_PROGRESS,
                  ProjectJobStatusEnum.QUOTE_CONFIRMED,
                  ProjectJobStatusEnum.CANCELLED,
                ].includes(getProjectStatus.value),
              }"
            >
              <div class="step__connector"></div>
              <v-stepper-item
                value="END"
                :complete="
                  [ProjectJobStatusEnum.COMPLETED].includes(
                    getProjectStatus.value
                  )
                "
              ></v-stepper-item>
            </div>
          </v-stepper-header>
        </v-stepper>
        <div class="cards__status">
          <ChipState
            :label="getProjectStatus.text"
            :textColor="getProjectStatus.color"
            bgColor="#FFF"
            :hasShadow="true"
          >
            <template #prefix>
              <div
                class="tw-rounded-full tw-w-[16px] tw-h-[16px] tw-flex tw-items-center tw-justify-center"
              >
                <v-icon
                  :icon="getProjectStatus.icon"
                  :color="getProjectStatus.color"
                  size="x-small"
                ></v-icon>
              </div>
            </template>
          </ChipState>
          <ChipState
            label="Details"
            bgColor="#FAA500"
            textColor="#FFF"
            class="!tw-rounded btn cursor-pointer"
            @click="showHistoricProject"
          >
            <template #postfix>
              <div
                class="tw-rounded-full tw-w-[16px] tw-h-[16px] tw-flex tw-items-center tw-justify-center"
              >
                <v-icon
                  icon="mdi-chevron-right"
                  color="#FFF"
                  size="x-small"
                  class="right-chevron"
                ></v-icon>
              </div>
            </template>
          </ChipState>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import ChipState from "@/core/components/common/ChipState.vue";

import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import {
  HistoricalProjectTypeEnum,
  HistoricProjectStatusEnum,
  ProjectJobStatusEnum,
} from "@/core/enums/ProjectsEnum";
import { PropertyTypeEnum } from "@/core/enums/PropertiesEnum";
import moment from "moment";
import { computed, ref } from "vue";
import {
  HISTORIC_PROJECT_DETAILS_ROUTE,
  PROJECT_DETAILS_ROUTE,
} from "@/modules/project/routes";
import { useRouter } from "vue-router";
const props = defineProps({
  project: {
    type: Object,
    default: null,
  },
  propertyDetails: {
    type: Object,
    default: null,
  },
});
const isDescriptionExpanded = ref(false);
const router = useRouter();
const projectStatusItems = [
  {
    text: "Not Started",
    value: "-1",
    icon: "mdi-alert-circle",
    color: "rgba(12, 15, 74, 0.8)",
  },
  {
    text: "Awaiting estimates",
    value: ProjectJobStatusEnum.AWAITING_QUOTES,
    icon: "mdi-alert-circle",
    color: "#48597B",
  },
  {
    text: "Estimates received",
    value: ProjectJobStatusEnum.QUOTES_RECEIVED,
    icon: "mdi-alert-circle",
    color: "#3375F2",
  },
  {
    text: "Estimates Accepted",
    value: ProjectJobStatusEnum.QUOTE_ACCEPTED,
    icon: "mdi-alert-circle",
    color: "#3375F2",
  },
  {
    text: "In progress",
    value: ProjectJobStatusEnum.IN_PROGRESS,
    icon: "mdi-alert-circle",
    color: "#FAA500",
  },
  {
    text: "In progress",
    value: ProjectJobStatusEnum.QUOTE_CONFIRMED,
    icon: "mdi-alert-circle",
    color: "#FAA500",
  },
  {
    text: "Cancelled",
    value: ProjectJobStatusEnum.CANCELLED,
    icon: "mdi-alert-circle",
    color: "#F45454",
  },
  {
    text: "Completed",
    value: ProjectJobStatusEnum.COMPLETED,
    icon: "mdi-check-circle",
    color: "#1FB27A",
  },
  {
    text: "Draft",
    value: ProjectJobStatusEnum.DRAFT_PROJECT,
    icon: "mdi-alert-circle",
    color: "#80829F",
  },
];
const getProjectStatus = computed(() =>
  projectStatusItems.find(
    (item) => item.value === (props.project?.projectJobStatus?.id || "-1")
  )
);

const buttonText = computed(() =>
  isDescriptionExpanded.value ? "Read Less" : "Read More"
);
const toggleReadMore = () => {
  isDescriptionExpanded.value = !isDescriptionExpanded.value;
};
const stringWithoutTags = computed(
  () =>
    props.project?.description &&
    props.project?.description
      .trim()
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/\s\s+/g, " ")
);

const projectDescription = computed(() => {
  return isDescriptionExpanded.value || stringWithoutTags.value.length <= 100
    ? stringWithoutTags.value
    : stringWithoutTags.value.substring(0, 100) + "...";
});

const showHistoricProject = () => {
  if (props?.project?.isHistoricProject) {
    router.push({
      name: HISTORIC_PROJECT_DETAILS_ROUTE,
      params: {
        refId: props.project.refId,
        estimateId: props.project.projectEstimateId,
      },
    });
  } else {
    // project-details/Y6TK66AE498IyMP4zKSsD
    router.push({
      name: PROJECT_DETAILS_ROUTE,
      params: {
        refId: props.project.refId,
      },
    });
  }
};
</script>
<style lang="scss" scoped>
.projectlogs__cardfooter {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  .stepper__wrapper {
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    .v-stepper-header {
      .stepper__step {
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 100%;
        &:first-child {
          width: auto;
        }
        .v-stepper-item--selected,
        .v-stepper-item--complete {
          padding: 0 !important;
          opacity: 1 !important;
          :deep(.v-avatar) {
            margin: 0 !important;
            background-color: rgba($orange, 1) !important;
            color: rgba($white, 1) !important;
          }
        }
        .step__connector {
          width: 100%;
          height: 2px;
          background-color: rgba($orange, 1);
        }
      }
      .inprogress {
        .v-stepper-item {
          padding: 0;
          :deep(.v-avatar) {
            border: 2px solid rgba($orange, 1);
            background-color: rgba($orange, 0.2);
            margin: 0;
            color: rgba($orange, 1);
          }
        }
      }
      .unselected {
        .v-stepper-item {
          padding: 0;
          :deep(.v-avatar) {
            border: none;
            background-color: rgba($blueDark, 0.05);
            margin: 0;
            color: rgba($blueDark, 0.5);
          }
        }
        .step__connector {
          width: 100%;
          height: 2px;
          background-color: rgba($blueDark, 0.05);
        }
      }
    }
  }
  .cards__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    .chip-state {
      .right-chevron {
        border-radius: 100%;
        background-color: rgba($white, 1);
        color: rgba($orange, 1) !important;
        @include fluidFont(12, 12, 1);
      }
    }
  }
}
.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  .custom__chip {
    background-color: rgba(243, 243, 246, 1);
    display: flex;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    align-items: center;
    box-shadow: 0px 0px 20px 0px #0c0f4a14;
    .v-icon {
      width: 12px;
      height: 12px;
      img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
    p {
      @include fluidFont(12, 12, 2);
      font-weight: 700;
      color: rgba($blueDark, 1);
      text-transform: uppercase;
      margin: 0;
      span {
        color: rgba($orange, 1);
      }
    }
  }
  .date_wrapper {
    color: rgba(133, 135, 164, 1);
    @include fluidFont(12, 12, 2);
    font-weight: 700;
    letter-spacing: 0.25;
  }
  .card__personalizer {
    .v-btn {
      padding: 0;
      height: auto;
      min-width: auto;
      opacity: 1;
      :deep(.v-btn__prepend) {
        margin: 0;
        margin-inline: 0;
        .v-icon {
          color: rgba($blueDark, 1);
        }
      }
    }
  }
}

.projectlogs__cardslist {
  width: calc(25% - 12px);
  max-width: 100%;
  background-image: linear-gradient(
    180deg,
    #ffa500 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 16px;
  padding: 2px;
  @include respond(s1024) {
    width: calc(33.33% - 11px);
  }
  @include respond(s720) {
    width: calc(50% - 8px);
  }
  @include respond(sm) {
    width: 100%;
  }
  .projectlogs__cardsitems {
    background-color: rgba($white, 1);
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .first-row {
      border-bottom: 1px dashed rgba(134, 135, 165, 1);
    }
    .second-row {
      padding: 0 0 8px;

      border-bottom: 1px dashed rgba(134, 135, 165, 1);
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      h4 {
        color: rgba($blueDark, 1);
        @include fluidFont(16, 16, 1.4);
        font-weight: 700;
        letter-spacing: 0.25;
        margin: 0;
      }
      .second-row__sub {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
      }
      .description {
        margin: 0;
        p {
          color: rgba($blueDark, 0.5);
          @include fluidFont(12, 12, 1.4);
          font-weight: 400;
          letter-spacing: 0.25;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            color: rgba($PrimaryPurple, 1);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
