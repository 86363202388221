<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10.7246" r="10" fill="#4A92E5" />
    <g clip-path="url(#clip0_296_57674)">
      <path
        d="M6.40039 12.909V14.125C6.40039 14.237 6.48839 14.325 6.60039 14.325H7.81639C7.86839 14.325 7.92039 14.305 7.95639 14.265L12.3244 9.90102L10.8244 8.40102L6.46039 12.765C6.42039 12.805 6.40039 12.853 6.40039 12.909ZM13.4844 8.74102C13.6404 8.58502 13.6404 8.33302 13.4844 8.17702L12.5484 7.24102C12.3924 7.08502 12.1404 7.08502 11.9844 7.24102L11.2524 7.97302L12.7524 9.47302L13.4844 8.74102Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_296_57674">
        <rect
          width="9.6"
          height="9.6"
          fill="white"
          transform="translate(5.2002 5.9248)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "PencilCircularBlueIcon",
};
</script>
