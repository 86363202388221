<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 392 512.303"
  >
    <path
      fill="#26A9F1"
      d="M58.884 0h203.881L392 139.817v313.602c0 32.329-26.555 58.884-58.884 58.884H58.884C26.545 512.303 0 485.809 0 453.419V58.884C0 26.495 26.495 0 58.884 0z"
    />
    <path
      fill="#BCE2F9"
      d="M262.766 0L392 139.816v.175h-55.664c-46.151-.734-70.996-23.959-73.57-62.858V0z"
    />
    <path
      fill="#1C7BB0"
      fill-rule="nonzero"
      d="M107.777 403.337c-7.205 0-13.05-5.844-13.05-13.051 0-7.205 5.845-13.05 13.05-13.05h141.706c7.206 0 13.051 5.845 13.051 13.05 0 7.207-5.845 13.051-13.051 13.051H107.777zm0-152.327c-7.205 0-13.05-5.845-13.05-13.051s5.845-13.05 13.05-13.05h176.447c7.206 0 13.051 5.844 13.051 13.05s-5.845 13.051-13.051 13.051H107.777zm0 73.164c-7.205 0-13.05-5.844-13.05-13.05s5.845-13.051 13.05-13.051h167.099c7.206 0 13.05 5.845 13.05 13.051s-5.844 13.05-13.05 13.05H107.777z"
    />
    <path
      fill="#1F89C3"
      d="M310.106 136.615h-.02L392 204.911v-64.92h-55.664c-9.685-.153-18.43-1.304-26.23-3.376z"
    />
  </svg>
</template>

<script>
export default {
  name: "FileIcon",
};
</script>
