<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#4B4BFF" />
    <g clip-path="url(#clip0_7396_73926)">
      <g clip-path="url(#clip1_7396_73926)">
        <path
          d="M21.7087 14.322H22.4525C22.3159 12.8119 21.6784 11.4345 20.6972 10.3381L20.1738 10.8312C19.9204 11.0699 19.5097 11.0699 19.2564 10.8312C19.003 10.5926 19.003 10.2056 19.2564 9.96697L19.7798 9.47389C18.616 8.54961 17.1538 7.94897 15.5508 7.82031V8.52105C15.5165 9.33186 14.2873 9.33125 14.2533 8.52105V7.82031C12.6503 7.94897 11.1881 8.54961 10.0243 9.47389L10.5477 9.96697C10.8011 10.2056 10.8011 10.5925 10.5477 10.8312C10.2944 11.0699 9.88366 11.0699 9.63031 10.8312L9.10689 10.3381C8.12573 11.4345 7.48814 12.8118 7.35156 14.322H8.09542C8.95613 14.3543 8.95548 15.5122 8.09542 15.5442H7.35156C7.48814 17.0543 8.12573 18.4317 9.10685 19.5281L9.63031 19.035C9.88366 18.7964 10.2944 18.7963 10.5477 19.035C10.8011 19.2736 10.8011 19.6605 10.5477 19.8992L10.0243 20.3923C11.1881 21.3166 12.6502 21.9172 14.2533 22.0459V21.3451C14.2876 20.5343 15.5168 20.5349 15.5507 21.3451V22.0459C17.1538 21.9172 18.6159 21.3166 19.7797 20.3923L19.2562 19.8992C19.0029 19.6605 19.0029 19.2736 19.2563 19.0349C19.5096 18.7963 19.9203 18.7964 20.1737 19.0349L20.6971 19.5281C21.6783 18.4317 22.3159 17.0543 22.4524 15.5442H21.7086C20.848 15.5119 20.8486 14.354 21.7087 14.322ZM17.1123 17.0152C16.8589 17.2539 16.4482 17.2539 16.1949 17.0152L14.4434 15.3652C14.3217 15.2507 14.2533 15.0952 14.2533 14.9331V12.2443C14.2876 11.4335 15.5168 11.4341 15.5508 12.2443V14.68L17.1122 16.151C17.3656 16.3896 17.3656 16.7766 17.1123 17.0152Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7396_73926">
        <rect
          width="19.7"
          height="18.558"
          fill="white"
          transform="translate(5 5.5)"
        />
      </clipPath>
      <clipPath id="clip1_7396_73926">
        <rect
          width="18.8762"
          height="17.7819"
          fill="white"
          transform="translate(5.46094 6.03906)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "IssueRaisedIcon",
};
</script>
