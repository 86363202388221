export default {
  setListingAmount({ commit }: any, value: any) {
    commit("setListingAmount", value);
  },
  setHasPropertyListing({ commit }: any, value: boolean) {
    commit("setHasPropertyListing", value);
  },
  setTotalMember({ commit }: any, value: number) {
    commit("setTotalMember", value);
  },
  setAddedMember({ commit }: any, value: any) {
    commit("setAddedMember", value);
  },
  setPaymentMethod({ commit }: any, value: string) {
    commit("setPaymentMethod", value);
  },
  setStoragePlan({ commit }: any, value: any) {
    commit("setStoragePlan", value);
  },

  setAccumulatedSize({ commit }: any, value: any) {
    commit("setAccumulatedSize", value);
  },

  setDefaultTrialDayse({ commit }: any, value: any) {
    commit("setDefaultTrialDayse", value);
  },

  setDefaultPropertyBillingAmount({ commit }: any, value: any) {
    commit("setDefaultPropertyBillingAmount", value);
  },
};
