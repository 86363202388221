<template>
  <CommonDialog
    class="emergency_service_modal"
    :title="!selectedEventId ? `Add New` : `Edit Out Of Hours`"
    width="570px"
    :onClickBackButton="onClickCancel"
    ><template v-slot:body>
      <v-form :rules="validation" ref="formRef">
        <div class="emergency_service_content">
          <v-text-field
            v-model="eventFrom.title"
            placeholder="Add Title"
            variant="underlined"
            :rules="validation.title"
            class="emergency_service_input"
          ></v-text-field>
          <div class="emergency_service_editor">
            <quill-editor
              v-model:value="eventFrom.description"
              :rules="validation.description"
            ></quill-editor>
          </div>

          <div
            v-click-outside="closeSelectProperty"
            class="custom_dropdown custom_column custom_dropdown-property"
          >
            <div class="title">
              <label>Select Property</label>
            </div>
            <div
              class="custom_dropdown_trigger"
              @click="togglePropertyDropdown"
              :class="{ 'is-open': isShowPropertyDropdownOpen }"
            >
              <p>
                {{
                  eventFrom.EOHProperties.length
                    ? selectedPropertyName
                    : "Please Select Property"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" />
            </div>
            <div
              class="custom_dropdown_content"
              v-if="isShowPropertyDropdownOpen"
            >
              <div class="custom_dropdown_content_inner">
                <div class="custom_dropdown_search">
                  <v-text-field
                    class="custom_input"
                    variant="outlined"
                    bg-color="#F5F6F8"
                    placeholder="Search Property"
                    prepend-inner-icon="mdi-magnify"
                    v-model="propertySearch"
                  >
                  </v-text-field>
                </div>

                <div
                  class="custom_dropdown_select_all"
                  v-if="filterProperties.length"
                >
                  <v-checkbox
                    color="#4F55F0"
                    label="Select All"
                    v-model="isSelectAllProperties"
                    @change="onChangeSelectAllProperties()"
                  ></v-checkbox>
                </div>

                <ul class="custom_dropdown_list">
                  <li v-for="property in filterProperties" :key="property.id">
                    <v-checkbox
                      color="#4F55F0"
                      v-model="eventFrom.EOHProperties"
                      :label="property.address"
                      :value="property.id"
                    >
                    </v-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-click-outside="closeSelectSpecialisms"
            class="custom_dropdown custom_column custom_dropdown-specialisms"
          >
            <div class="title">
              <label>Select Specialisms</label>
            </div>
            <div
              class="custom_dropdown_trigger"
              @click="toggleDropdown"
              :class="{ 'is-open': isDropdownOpen }"
            >
              <p>
                {{
                  eventFrom.EOHSpecialism.length
                    ? selectedSubCategoryName
                    : "Select Specialisms"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" />
            </div>
            <div class="custom_dropdown_content" v-if="isDropdownOpen">
              <div class="custom_dropdown_content_inner">
                <div class="custom_dropdown_search">
                  <v-text-field
                    class="custom_input"
                    variant="outlined"
                    bg-color="#F5F6F8"
                    placeholder="Search Specialisms"
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchCategory"
                  >
                  </v-text-field>
                </div>
                <div class="custom_dropdown_list">
                  <v-expansion-panels multiple>
                    <v-expansion-panel
                      v-for="specialism in filterCategory"
                      :key="specialism.id"
                    >
                      <v-expansion-panel-title>
                        <div class="custom_dropdown__heading">
                          <v-checkbox
                            color="#4B4BFF"
                            v-model="selectedMainCategory"
                            :value="specialism.id"
                            @click="onClickMainCategory(specialism.id)"
                          />
                          <div class="custom_dropdown__title">
                            <v-icon
                              class="custom_dropdown__title_icon"
                              v-if="specialism.iconName"
                            >
                              <img
                                style="width: 14px"
                                :src="
                                  require('@/assets/category-icons/' +
                                    specialism.iconName +
                                    '.svg')
                                "
                                alt="icon"
                              />
                            </v-icon>
                            <div class="custom_dropdown__title_text">
                              <h2>{{ specialism.poLabel }}</h2>
                              <p>
                                {{
                                  specialism.subCategories
                                    .slice(0, 2)
                                    .map((sub: any) => sub.label)
                                    .join(", ")
                                }}
                                <span
                                  v-if="specialism.subCategories?.length > 2"
                                >
                                  etc..</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div class="custom_dropdown__content">
                          <v-checkbox
                            color="#4B4BFF"
                            v-model="eventFrom.EOHSpecialism"
                            :label="subCategory.label"
                            :key="subCategory.id"
                            :value="subCategory.id"
                            v-for="subCategory in specialism.subCategories"
                          ></v-checkbox>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- <li
                  v-for="category in listOfCategories"
                  :key="category.id"
                  @click="onClickCategory(category.id)"
                >
                  <v-checkbox
                    v-model="eventFrom.EOHSpecialism"
                    :value="category.id"
                  ></v-checkbox>
                  <div class="custom_dropdown_list_content">
                    <ServiceIconWrapper
                      path="category-icons"
                      :iconName="category.iconName"
                    />
                    <p>{{ category.tpLabel }}</p>
                  </div>
                </li> -->
                </div>
              </div>
            </div>
          </div>

          <div class="emergency_date_time_picker custom_column">
            <div class="title">
              <label>Date & Time </label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <div class="date_time_selector">
              <div class="weekdays">
                <div class="weekdays_wrapper">
                  <label>Weekdays</label>
                  <div class="allday_selector">
                    <input
                      type="checkbox"
                      v-model="everyday"
                      @change="onChangeCheckbox"
                    />
                    Everyday
                  </div>
                </div>
                <div class="days">
                  <button
                    v-for="(day, index) in days"
                    :key="index"
                    :class="{ selected: isDaySelected(day.value) }"
                    @click="toggleDay(day.value)"
                  >
                    {{ day.name }}
                  </button>
                </div>
              </div>
              <div class="time-range">
                <div class="time-select">
                  <input
                    type="number"
                    v-model="eventTime.startHour"
                    min="1"
                    max="12"
                    @input="onChangeEventTime()"
                  />

                  :
                  <input
                    type="number"
                    v-model="eventTime.startMinute"
                    min="0"
                    max="60"
                    @input="onChangeEventTime()"
                  />

                  <select v-model="eventTime.startDay">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
                <span>To</span>
                <div class="time-select">
                  <input
                    type="number"
                    v-model="eventTime.endHour"
                    min="1"
                    max="12"
                    @input="onChangeEventTime()"
                  />
                  :
                  <input
                    type="number"
                    v-model="eventTime.endMinute"
                    min="0"
                    max="59"
                    @input="onChangeEventTime()"
                  />
                  <select v-model="eventTime.endDay">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="emergency_service_manual custom_column">
            <div class="title">
              <label for="SLATime">SLA Time (Manual Intervention)</label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <div class="emergency_service_time_inputs">
              <v-text-field
                class="custom_input"
                variant="outlined"
                placeholder="Hours"
                type="number"
                min="0"
                max="12"
                v-model.number="slaTimeHours"
                @input="onChangeEventSlaTime()"
              ></v-text-field>
              <span>:</span>
              <v-text-field
                class="custom_input"
                variant="outlined"
                placeholder="Minutes"
                type="number"
                min="0"
                max="60"
                v-model.number="slaTimeMinutes"
                @input="onChangeEventSlaTime()"
              ></v-text-field>
            </div>

            <!-- <v-text-field
            v-model="eventFrom.SLATime"
            class="custom_input"
            variant="outlined"
            placeholder="15 min"
          ></v-text-field> -->
          </div>

          <div class="emergency_service_budget custom_column">
            <div class="title">
              <label for="SLATime">Earmark Budget*</label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <v-text-field
              :rules="validation.budget"
              v-model="formattedBudget"
              class="custom_input"
              variant="outlined"
              placeholder="£1,000.00"
              @input="formatBudget($event)"
            ></v-text-field>
          </div>

          <div class="emergency_service_fee custom_column">
            <div class="title">
              <label>Emergency call out fee*</label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <v-text-field
              v-model="formattedEmergencyCallOutFee"
              :rules="validation.emergencyCallOutFee"
              class="custom_input"
              variant="outlined"
              placeholder="£100.00"
              @input="formatEmergencyCallOutFee($event)"
            ></v-text-field>
          </div>

          <div class="emergency_service_rate custom_column">
            <div class="title">
              <label for="ECOR">Emergency call out rate*</label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <v-text-field
              v-model="formattedEmergencyCallOutRate"
              :rules="validation.emergencyCallOutRate"
              class="custom_input"
              variant="outlined"
              placeholder="£50.00 / hour"
              @input="formatEmergencyCallOutRate($event)"
            ></v-text-field>
          </div>

          <div
            v-click-outside="closePreferredContractor"
            class="custom_dropdown custom_column custom_dropdown-contractor"
          >
            <div class="title">
              <label>Preferred Contractor</label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <div
              class="custom_dropdown_trigger"
              @click="togglePreferredDropdown"
              :class="{ 'is-open': isPreferredDropdownOpen }"
            >
              <p>
                {{
                  selectedPreferredContractor.length
                    ? selectedPreferredContractorName
                    : "Select Preferred Contractor"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" />
            </div>
            <div class="custom_dropdown_content" v-if="isPreferredDropdownOpen">
              <div class="custom_dropdown_content_inner">
                <div class="custom_dropdown_search">
                  <v-text-field
                    class="custom_input"
                    variant="outlined"
                    bg-color="#F5F6F8"
                    placeholder="Search Specialisms"
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchPreferredContractor"
                  >
                  </v-text-field>
                </div>

                <div class="custom_dropdown_select_all">
                  <v-checkbox
                    color="#4F55F0"
                    label="Select All"
                    v-model="selectAllPreferredContractor"
                    @change="onChangeSelectAllPreferredContractor"
                  ></v-checkbox>
                </div>

                <ul class="custom_dropdown_list">
                  <li
                    v-for="preferredContractor in filterPreferredContractor"
                    :key="preferredContractor.id"
                  >
                    <v-checkbox
                      color="#4F55F0"
                      v-model="selectedPreferredContractor"
                      :value="preferredContractor.userWorkstation.id"
                    >
                      <template v-slot:label>
                        <div class="custom_dropdown__checkbox-label">
                          <div class="custom_dropdown__checkbox-image">
                            <img
                              :src="
                                preferredContractor.userWorkstation.publicURL
                              "
                              alt="Image"
                              width="20"
                              height="20"
                            />
                          </div>
                          <p>{{ preferredContractor.userWorkstation.name }}</p>
                        </div>
                      </template>
                    </v-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-click-outside="closeSelectOccupier"
            class="custom_dropdown custom_column custom_dropdown-contractor custom_dropdown-occupier"
          >
            <div class="title">
              <label>Select Occupier</label>
              <v-icon icon="mdi-information-variant-circle-outline" />
            </div>
            <div
              class="custom_dropdown_trigger"
              @click="toggleOccupierDropdown"
              :class="{ 'is-open': isShowSelectOccupierDropdown }"
            >
              <p>
                {{
                  eventFrom.EOHPropertyOccupier.length
                    ? selectedOccupierName
                    : "Select Occupier"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" />
            </div>
            <div
              class="custom_dropdown_content"
              v-if="isShowSelectOccupierDropdown"
            >
              <div class="custom_dropdown_content_inner">
                <div class="custom_dropdown_search">
                  <v-text-field
                    class="custom_input"
                    variant="outlined"
                    bg-color="#F5F6F8"
                    placeholder="Search Occupier"
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchOccupier"
                  >
                  </v-text-field>
                </div>

                <ul class="custom_dropdown_list">
                  <li v-for="occupier in filterOccupier" :key="occupier.userId">
                    <v-checkbox
                      color="#4F55F0"
                      v-model="eventFrom.EOHPropertyOccupier"
                      :value="occupier.userId"
                    >
                      <template v-slot:label>
                        <div class="custom_dropdown__checkbox-label">
                          <div class="custom_dropdown__checkbox-image">
                            <img
                              :src="occupier.publicURL"
                              alt="Image"
                              width="20"
                              height="20"
                            />
                          </div>
                          <p>{{ occupier.name }}</p>
                        </div>
                      </template>
                    </v-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </v-form> </template
    ><template v-slot:footer>
      <div class="emergency_footer">
        <v-btn class="button button-purple-border" @click="onClickCancel"
          >CANCEL</v-btn
        >
        <v-btn class="button button-orange" @click="onClickPostButton"
          >POST</v-btn
        >
      </div>
    </template></CommonDialog
  >
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { quillEditor } from "vue3-quill";
import { ref, watch } from "vue";
import { useStore } from "vuex";
import EOHService from "@/core/services/emergency-out-of-hours.service";
import { USER_STORE } from "@/store/modules/user";
import { onBeforeMount } from "vue";
// import ServiceIconWrapper from "@/core/components/common/ServiceIconWrapper.vue";
import { computed } from "vue";
import { debounce, property } from "lodash";
import { PROPERTY_STORE } from "@/store/modules/property";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { getImageApiUrl, parseJSON } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { PropertyTypeEnum } from "@/core/enums/PropertiesEnum";
import CurrencyFilter from "@/filters/currency.filter";
export default {
  components: {
    CommonDialog,
    quillEditor,
    // ServiceIconWrapper,
  },
  props: { selectedEventId: { type: Number, default: null } },
  setup(props: any, ctx: any) {
    const days = [
      { name: "Sun", value: 1 },
      { name: "Mon", value: 2 },
      { name: "Tue", value: 3 },
      { name: "Wed", value: 4 },
      { name: "Thu", value: 5 },
      { name: "Fri", value: 6 },
      { name: "Sat", value: 7 },
    ];
    const selectedDays = ref([]) as any;

    const listOfCategories = ref([]) as any;

    const selectedCategories = ref([]) as any;

    const everyday = ref(false);

    const isShowPropertyDropdownOpen = ref(false);

    const selectedProperty = ref([]) as any;

    const propertySearch = ref("");

    const searchCategory = ref("");

    const searchPreferredContractor = ref("");

    const searchOccupier = ref("");

    const isSelectAllProperties = ref(false);

    const selectedMainCategory = ref([]) as any;

    const userPreferredContractor = ref([]) as any;

    const isPreferredDropdownOpen = ref(false);

    const selectedPreferredContractor = ref([]) as any;

    const selectAllPreferredContractor = ref(false);

    const isShowSelectOccupierDropdown = ref(false);

    const listOfOccupier = ref([]) as any;

    const slaTimeHours = ref(null) as any;

    const slaTimeMinutes = ref(null) as any;

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const formRef = ref(null) as any;
    const validation = ref({
      title: [
        (v: any) => !!v || "Title name is required",
        (v: any) =>
          (!!v && v.length <= 255) ||
          "Title name must be less than 255 characters",
      ],
      description: [(v: any) => !!v || "Description is required"],
      selectProperty: [(v: any) => !!v || "Property selection is required"],
      selectSpecialisms: [
        (v: any) => !!v || "Specialisms selection is required",
      ],
      budget: [
        (v: any) => !!v || "Budget is required",
        (v: any) => {
          // Regex to validate currency format: optional £ symbol, followed by numbers with commas
          const regex = /^£?\d{1,3}(?:,\d{3})*$/;
          return regex.test(v) || "Invalid currency format";
        },
      ],
      preferredContractor: [
        (v: any) => !!v || "Preferred Contractor is required",
      ],
      emergencyCallOutFee: [
        (v: any) => !!v || "EmergencyCallOutFee is required",
        (v: any) => {
          // Regex to validate currency format: optional £ symbol, followed by numbers with commas
          const regex = /^£?\d{1,3}(?:,\d{3})*$/;
          return regex.test(v) || "Invalid currency format";
        },
      ],
      emergencyCallOutRate: [
        (v: any) => !!v || "EmergencyCallOutRate is required",
        (v: any) => {
          // Regex to validate currency format: optional £ symbol, followed by numbers with commas
          const regex = /^£?\d{1,3}(?:,\d{3})*$/;
          return regex.test(v) || "Invalid currency format";
        },
      ],
    });

    const eventTime = ref({
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
      startDay: "AM",
      endDay: "AM",
    });

    const eventFrom = ref({
      title: "",
      description: "",
      SLATime: null,
      earnmarkBudget: null,
      emergencyCallOutFee: null,
      emergencyCallOutRate: null,
      EOHSpecialism: [],
      EOHProperties: [],
      EOHPreferredContractor: [],
      EOHPropertyOccupier: [],
      days: null,
      fromTime: null,
      toTime: null,
    }) as any;

    const store = useStore();

    const properties = computed(
      () => store.getters[`${PROPERTY_STORE}/properties`]
    );

    const onClickPostButton = async () => {
      const isFormValid = await formRef.value.validate();
      if (!isFormValid.valid) return;

      eventFrom.value.EOHSpecialism = [
        ...new Set(eventFrom.value.EOHSpecialism),
      ];
      eventFrom.value["fromTime"] = {
        time: `${eventTime.value.startHour}:${eventTime.value.startMinute}`,
        day: `${eventTime.value.startDay}`,
      };

      eventFrom.value["toTime"] = {
        time: `${eventTime.value.endHour}:${eventTime.value.endMinute}`,
        day: `${eventTime.value.endDay}`,
      };

      const sortedSelectedDays = selectedDays.value.sort(
        (a: number, b: number) => a - b
      );
      eventFrom.value.days = sortedSelectedDays;
      everyday.value = selectedDays.value === 7;

      const preferredContractor = selectedPreferredContractor.value.map(
        (contractor: any) => {
          const user = filterPreferredContractor.value.find(
            (preferredContractor: any) => {
              return preferredContractor.userWorkstation.id === contractor;
            }
          );

          return { userWorkstationId: contractor, userId: user.toUser.id };
        }
      );

      eventFrom.value.SLATime = slaTimeHours.value * 60 + slaTimeMinutes.value;
      eventFrom.value.EOHPreferredContractor = preferredContractor;

      ctx.emit("onClickPostButton", eventFrom.value);
    };

    const onClickCancel = () => {
      ctx.emit("onClickCancel");
    };

    const toggleDay = (daysValue: number) => {
      if (!selectedDays.value.includes(daysValue)) {
        selectedDays.value.push(daysValue);
      } else {
        const findIndex = selectedDays.value.indexOf(daysValue);
        selectedDays.value.splice(findIndex, 1);
      }
    };

    const isDaySelected = (daysValue: number) => {
      return selectedDays.value.includes(daysValue);
    };

    const onClickCategory = (categoryId: number) => {
      eventFrom.value.EOHSpecialism = [
        ...new Set(eventFrom.value.EOHSpecialism),
      ];
      if (!eventFrom.value.EOHSpecialism.includes(categoryId)) {
        eventFrom.value.EOHSpecialism.push(categoryId);
      } else {
        const findIndex = eventFrom.value.EOHSpecialism.indexOf(categoryId);
        eventFrom.value.EOHSpecialism.splice(findIndex, 1);
      }
    };

    const onChangeCheckbox = () => {
      if (everyday.value) {
        days.forEach((day: any) => {
          if (!selectedDays.value.includes(day.value))
            selectedDays.value.push(day.value);
        });
      } else {
        selectedDays.value = [];
      }
    };

    const isDropdownOpen = ref(false);

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const closeSelectSpecialisms = () => {
      isDropdownOpen.value = false;
    };
    const isSelectedCategory = (categoryId: number) => {
      eventFrom.value.EOHSpecialism.includes(categoryId);
    };

    const onChangeEventTime = () => {
      changeEventTime();
    };

    const changeEventTime = debounce(() => {
      eventTime.value.startHour = removeAlphaFromString(
        eventTime.value.startHour
      );
      eventTime.value.startMinute = removeAlphaFromString(
        eventTime.value.startMinute
      );
      eventTime.value.endHour = removeAlphaFromString(eventTime.value.endHour);
      eventTime.value.endMinute = removeAlphaFromString(
        eventTime.value.endMinute
      );
      if (eventTime.value.startHour > 12) {
        eventTime.value.startHour = 12;
      }
      if (eventTime.value.startMinute > 60) {
        eventTime.value.startMinute = 60;
      }
      if (eventTime.value.endHour > 12) {
        eventTime.value.endHour = 12;
      }
      if (eventTime.value.endMinute > 60) {
        eventTime.value.endMinute = 60;
      }
    }, 100);

    const removeAlphaFromString = (numValue: number) => {
      return parseInt(numValue.toString().replace(/[^0-9]/g, ""));
    };
    const togglePropertyDropdown = () => {
      isShowPropertyDropdownOpen.value = !isShowPropertyDropdownOpen.value;
    };

    const closeSelectProperty = () => {
      isShowPropertyDropdownOpen.value = false;
    };
    const filterProperties = computed(() => {
      if (propertySearch.value) {
        return properties.value.filter((property: any) => {
          const connectionTag = parseJSON(property.connectionTags);
          return (
            ([PropertyTypeEnum.OWNER, PropertyTypeEnum.OCCUPIER].includes(
              property.propertyRole
            ) &&
              property.address.includes(propertySearch.value)) ||
            (connectionTag && connectionTag.includes(PropertyTypeEnum.OCCUPIER))
          );
        });
      } else {
        return properties.value.filter((property: any) => {
          const connectionTag = parseJSON(property.connectionTags);

          return (
            [PropertyTypeEnum.OWNER, PropertyTypeEnum.OCCUPIER].includes(
              property.propertyRole
            ) ||
            (connectionTag && connectionTag.includes(PropertyTypeEnum.OCCUPIER))
          );
        });
      }
    });

    const onChangeSelectAllProperties = () => {
      if (isSelectAllProperties.value) {
        filterProperties.value.forEach((property: any) => {
          if (!eventFrom.value.EOHProperties.includes(property.id)) {
            eventFrom.value.EOHProperties.push(property.id);
          }
        });
      } else {
        eventFrom.value.EOHProperties = [];
      }
    };

    const onChangeSelectAllPreferredContractor = () => {
      if (selectAllPreferredContractor.value) {
        filterPreferredContractor.value.forEach((contractor: any) => {
          if (
            !selectedPreferredContractor.value.includes(
              contractor.userWorkstation.id
            )
          ) {
            selectedPreferredContractor.value.push(
              contractor.userWorkstation.id
            );
          }
        });
      } else {
        selectedPreferredContractor.value = [];
      }
    };

    const selectedPropertyName = computed(() => {
      return filterProperties.value
        .filter((property: any) => {
          return eventFrom.value.EOHProperties.includes(property.id);
        })
        .map((property: any) => property.address)
        .toString();
    });

    const selectedSubCategoryName = computed(() => {
      const subCategoryName: any = [];

      listOfCategories.value.forEach((category: any) => {
        category.subCategories.forEach((subCategory: any) => {
          if (eventFrom.value.EOHSpecialism.includes(subCategory.id)) {
            subCategoryName.push(subCategory.label);
          }
        });
      });

      return subCategoryName.toString();
    });

    watch(
      () => selectedDays.value,
      (newValue) => {
        everyday.value = newValue.length === 7;
      },
      { deep: true }
    );

    watch(
      () => eventFrom.value.EOHProperties,
      async (value) => {
        if (value.length) {
          listOfOccupier.value = await EOHService.getSelectedPropertiesOccupier(
            {
              propertyIds: value.toString(),
            }
          );
        } else {
          listOfOccupier.value = [];
        }
        if (listOfOccupier.value.length) {
          try {
            for (const occupier of listOfOccupier.value) {
              if (occupier.profileImage !== null) {
                const imageUrl = getImageApiUrl(occupier.profileImage, true);

                const encoded: any = await $axios.get(imageUrl);
                occupier.publicURL = encoded?.publicUrl;
              }
            }
          } catch (error) {
            console.log("error", error);
          }
        }
      },
      { deep: true }
    );

    const onClickMainCategory = (mainCategoryId: number) => {
      if (selectedMainCategory.value.includes(mainCategoryId)) {
        const mainCategory = listOfCategories.value.find(
          (category: any) => category?.id === mainCategoryId
        );
        mainCategory.subCategories.forEach((element: any) => {
          const elementIndex = eventFrom.value.EOHSpecialism.findIndex(
            (subCategory: any) => subCategory === element.id
          );
          if (elementIndex > -1) {
            eventFrom.value.EOHSpecialism.splice(elementIndex, 1);
          }
        });
      } else {
        const mainCategory = listOfCategories.value.find(
          (category: any) => category?.id === mainCategoryId
        );

        mainCategory.subCategories.forEach((element: any) => {
          if (!eventFrom.value.EOHSpecialism.includes(element?.id)) {
            eventFrom.value.EOHSpecialism.push(element?.id);
          }
        });
      }
    };

    const filterCategory = computed(() => {
      if (searchCategory.value) {
        return listOfCategories.value.filter((category: any) =>
          category.poLabel.includes(searchCategory.value)
        );
      } else {
        return listOfCategories.value;
      }
    });

    const filterPreferredContractor = computed(() => {
      if (searchPreferredContractor.value) {
        return userPreferredContractor.value.filter((contractor: any) =>
          contractor.userWorkstation.name.includes(
            searchPreferredContractor.value
          )
        );
      } else {
        return userPreferredContractor.value;
      }
    });

    const togglePreferredDropdown = () => {
      isPreferredDropdownOpen.value = !isPreferredDropdownOpen.value;
    };

    const closePreferredContractor = () => {
      isPreferredDropdownOpen.value = false;
    };
    const selectedPreferredContractorName = computed(() => {
      return filterPreferredContractor.value
        .filter((contractor: any) => {
          return selectedPreferredContractor.value.includes(
            contractor.userWorkstation.id
          );
        })
        .map((contractor: any) => contractor.userWorkstation.name)
        .toString();
    });

    const selectedOccupierName = computed(() => {
      return listOfOccupier.value
        .filter((occupier: any) => {
          return eventFrom.value.EOHPropertyOccupier.includes(occupier.userId);
        })
        .map((occupier: any) => {
          return occupier.name;
        })
        .toString();
    });

    const filterOccupier = computed(() => {
      console.log(listOfOccupier.value);
      if (searchOccupier.value) {
        return listOfOccupier.value.filter((occupier: any) => {
          return occupier.name.includes(searchOccupier.value);
        });
      } else {
        return listOfOccupier.value;
      }
    });

    const toggleOccupierDropdown = () => {
      isShowSelectOccupierDropdown.value = !isShowSelectOccupierDropdown.value;
    };

    const closeSelectOccupier = () => {
      isShowSelectOccupierDropdown.value = false;
    };
    const onChangeEventSlaTime = () => {
      changeSlaTime();
    };

    const changeSlaTime = debounce(() => {
      if (slaTimeHours.value && slaTimeHours.value > 12) {
        slaTimeHours.value = 12;
      }

      if (slaTimeMinutes.value && slaTimeMinutes.value > 60) {
        slaTimeMinutes.value = 60;
      }
    }, 100);

    onBeforeMount(async () => {
      if (props.selectedEventId) {
        const particularEvents = (await EOHService.getParticularEOHEvents(
          props.selectedEventId
        )) as any;
        eventFrom.value.title = particularEvents?.title;
        eventFrom.value.description = particularEvents?.description;
        eventFrom.value.EOHSpecialism = particularEvents?.EOHSpecialism.map(
          (specialism: any) => {
            selectedMainCategory.value.push(
              specialism?.subCategories?.category?.id
            );
            return specialism?.subCategories?.id;
          }
        );
        selectedPreferredContractor.value =
          particularEvents?.EOHPreferredContractor.map((contractor: any) => {
            return contractor.userWorkstation?.id;
          });

        eventFrom.value.EOHProperties = particularEvents?.EOHProperties.map(
          (property: any) => {
            return property?.property?.id;
          }
        );
        if (eventFrom.value.EOHProperties.length) {
          listOfOccupier.value = await EOHService.getSelectedPropertiesOccupier(
            {
              propertyIds: eventFrom.value.EOHProperties.toString(),
            }
          );
          if (listOfOccupier.value.length) {
            try {
              for (const occupier of listOfOccupier.value) {
                if (occupier.profileImage !== null) {
                  const imageUrl = getImageApiUrl(occupier.profileImage, true);
                  const encoded: any = await $axios.get(imageUrl);
                  occupier.publicURL = encoded?.publicUrl;
                }
              }
            } catch (error) {
              console.log("error", error);
            }
          }
        }
        eventFrom.value.EOHPropertyOccupier =
          particularEvents?.EOHPropertyOccupier.map((occupier: any) => {
            return occupier.user.id;
          });
        slaTimeHours.value = Math.floor(particularEvents?.SLATime / 60);
        slaTimeMinutes.value =
          particularEvents?.SLATime - 60 * slaTimeHours.value;
        eventFrom.value.earnmarkBudget = particularEvents?.earnmarkBudget;
        eventFrom.value.emergencyCallOutFee =
          particularEvents?.emergencyCallOutFee;
        eventFrom.value.emergencyCallOutRate =
          particularEvents?.emergencyCallOutRate;
        eventTime.value.startDay = particularEvents?.fromTimeAndDay?.day;
        eventTime.value.endDay = particularEvents?.toTimeAndDay?.day;
        eventTime.value.startHour =
          particularEvents.fromTimeAndDay.time.split(":")[0];
        eventTime.value.startMinute =
          particularEvents.fromTimeAndDay.time.split(":")[1];
        eventTime.value.endHour =
          particularEvents.toTimeAndDay.time.split(":")[0];
        eventTime.value.endMinute =
          particularEvents.toTimeAndDay.time.split(":")[1];
        selectedDays.value = particularEvents.days;
        everyday.value = selectedDays.value.length === 7;
      }
      listOfCategories.value = await store.dispatch(
        `${USER_STORE}/getCategories`
      );

      let params: any = {
        status: "accepted",
      };
      userPreferredContractor.value = await store.dispatch(
        `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
        {
          userId: user.value?.id,
          params: params,
        }
      );
      try {
        for (const contractor of userPreferredContractor.value) {
          if (contractor.userWorkstation.profileImage !== null) {
            const imageUrl = getImageApiUrl(
              contractor.userWorkstation.profileImage,
              true
            );
            const encoded: any = await $axios.get(imageUrl);
            contractor.userWorkstation.publicURL = encoded?.publicUrl;
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    });

    const formattedBudget = computed(() => {
      // Format the value to currency format
      return eventFrom.value.earnmarkBudget
        ? `£${eventFrom.value.earnmarkBudget.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`
        : "";
    });
    const formatBudget = (event: any) => {
      // Remove non-numeric characters (except for the decimal point)
      eventFrom.value.earnmarkBudget = event.target.value.replace(
        /[^\d.]/g,
        ""
      );
    };

    const formattedEmergencyCallOutFee = computed(() => {
      // Format the value to currency format
      return eventFrom.value.emergencyCallOutFee
        ? `£${eventFrom.value.emergencyCallOutFee.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`
        : "";
    });

    const formatEmergencyCallOutFee = (event: any) => {
      // Remove non-numeric characters (except for the decimal point)
      eventFrom.value.emergencyCallOutFee = event.target.value.replace(
        /[^\d.]/g,
        ""
      );
    };
    const formattedEmergencyCallOutRate = computed(() => {
      // Format the value to currency format
      return eventFrom.value.emergencyCallOutRate
        ? `£${eventFrom.value.emergencyCallOutRate.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`
        : "";
    });

    const formatEmergencyCallOutRate = (event: any) => {
      // Remove non-numeric characters (except for the decimal point)
      eventFrom.value.emergencyCallOutRate = event.target.value.replace(
        /[^\d.]/g,
        ""
      );
    };

    return {
      days,
      selectedDays,
      onClickCancel,
      eventFrom,
      onClickPostButton,
      listOfCategories,
      toggleDay,
      isDaySelected,
      everyday,
      onClickCategory,
      selectedCategories,
      selectedSubCategoryName,
      onChangeCheckbox,
      eventTime,
      isDropdownOpen,
      toggleDropdown,
      isSelectedCategory,
      onChangeEventTime,
      isShowPropertyDropdownOpen,
      togglePropertyDropdown,
      properties,
      selectedProperty,
      propertySearch,
      filterProperties,
      isSelectAllProperties,
      onChangeSelectAllProperties,
      selectedPropertyName,
      selectedMainCategory,
      userPreferredContractor,
      onClickMainCategory,
      filterCategory,
      searchCategory,
      isPreferredDropdownOpen,
      togglePreferredDropdown,
      selectedPreferredContractor,
      searchPreferredContractor,
      filterPreferredContractor,
      selectAllPreferredContractor,
      selectedPreferredContractorName,
      onChangeSelectAllPreferredContractor,
      isShowSelectOccupierDropdown,
      toggleOccupierDropdown,
      listOfOccupier,
      searchOccupier,
      filterOccupier,
      slaTimeHours,
      slaTimeMinutes,
      onChangeEventSlaTime,
      selectedOccupierName,
      validation,
      formRef,
      CurrencyFilter,
      formattedBudget,
      formatBudget,
      formattedEmergencyCallOutFee,
      formatEmergencyCallOutFee,
      formattedEmergencyCallOutRate,
      formatEmergencyCallOutRate,
      closeSelectProperty,
      closeSelectSpecialisms,
      closePreferredContractor,
      closeSelectOccupier,
    };
  },
};
</script>
