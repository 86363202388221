<template>
  <commonDialog
    v-if="isShowSubscriptionPlan"
    v-model="isShowHomeownersWelcome"
    HeaderTittle="Subscription Plan"
    :onClickBackButton="onClickToggleHomeownerWelcome"
    class="subscription__dialog"
  >
    <template v-slot:body>
      <div class="subscription_plan">
        <div class="left__block">
          <div class="subscription__product">
            <div class="subscription__product__name">
              <h5>Iknowa Workstation</h5>
              <p>(Limited Company)</p>
            </div>
            <div class="subscription__product__price">
              <h5>£{{ selectedPlanPrice }}</h5>
            </div>
          </div>
          <div class="add__member">
            <h4>Add members <v-icon icon="mdi-information" /></h4>
            <span>£{{ membershipPrice }} + VAT per member/ monthly</span>
            <div class="add__total__member">
              <v-btn
                variant="text"
                :disabled="totalMember <= 1 ? true : false"
                @click="handleTotalMember('desc')"
              >
                <v-icon icon="mdi-minus-circle" variant="tonal"></v-icon>
              </v-btn>
              <h4>{{ totalMember }}</h4>
              <v-btn variant="text" @click="handleTotalMember('inc')">
                <v-icon icon="mdi-plus-circle" variant="tonal"></v-icon>
              </v-btn>
            </div>
          </div>
          <div class="subscription__product total__product__price">
            <div class="subscription__product__name">
              <h5>Members fee</h5>
            </div>
            <div class="subscription__product__price">
              <h5>£{{ membershipPrice }}</h5>
            </div>
          </div>
          <div class="subscription__product total__product__price">
            <div class="subscription__product__name">
              <p>Sub total</p>
              <p>VAT (20%)</p>
            </div>
            <div class="subscription__product__price">
              <p>£{{ subTotal }}</p>
              <p>£1.50</p>
            </div>
          </div>
          <div class="subscription__product total__product__price">
            <div class="subscription__product__name">
              <h5>Due today</h5>
              <p>Billed monthly after 14 days</p>
            </div>
            <div class="subscription__product__price">
              <h5>£0.00</h5>
              <p>£8.98</p>
            </div>
          </div>
          <v-btn
            class="button button-orange w-full"
            @click="initializeStripePayment(false, false)"
            >next</v-btn
          >
          <div class="subscription__product__note">
            <p>
              After your 14 day trial ends, you will be charged £8.98 monthly,
              starting [DD/MM/YYYY]. Cancel anytime.
            </p>
          </div>
        </div>
        <div class="right__block">
          <div class="right__block__img__1">
            <img src="../../../../assets/images/subscription-review.png" />
          </div>
          <div class="right__block__img__2">
            <img src="../../../../assets/images/subscription-review.png" />
          </div>
          <div class="right__block__img__3">
            <img src="../../../../assets/images/subscription-review.png" />
          </div>
        </div>
      </div>
    </template>
  </commonDialog>
  <commonDialog
    v-else
    v-model="isShowHomeownersWelcome"
    HeaderTittle="Subscription Plan"
    :title="`Welcome To iknowa ${user?.firstName}!`"
    description="Explore the platform, we’ve a lot of amazing features for you in iknowa! Feel free 
to go around and check all the things we have made for you."
    :onClickBackButton="onClickToggleHomeownerWorkstation"
    :className="['carousel__dialog', 'subplan_modal']"
  >
    <template v-slot:body>
      <div class="modal_centertext">
        <div class="modal_card_list">
          <ul>
            <li>
              <v-icon icon="mdi-check-circle" /><span>
                Property overview <text> (inc. energy efficiency)</text>
              </span>
            </li>
            <li>
              <v-icon icon="mdi-check-circle" /><span>
                Record or raise projects to be approved
              </span>
            </li>
            <li>
              <v-icon icon="mdi-check-circle" /><span>
                Invite your landlord or property manager
              </span>
            </li>
            <li>
              <v-icon icon="mdi-check-circle" /><span>
                Store & manage key documents (100MB)
              </span>
            </li>
            <li>
              <img src="../../../../assets/icons/yellow-pound.png" /><span>
                From £6.39* p/month, for a verified workstation
                <text>
                  (option to add 3 properties & includes 100GB storage)</text
                >
              </span>
            </li>
            <li>
              <img src="../../../../assets/icons/yellow-pound.png" /><span>
                From £6.39* per additional team member
              </span>
            </li>
          </ul>
        </div>
        <div class="main_img">
          <img
            src="../../../../assets/images/welcome-homeowners.png"
            alt="Create Workstation"
          />
        </div>
      </div>
      <v-divider color="info" class="mt-6 mb-10"></v-divider>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn
            class="button button-orange"
            size="large"
            block
            @click="onClickContinueSubscription"
          >
            Continue to SUBSCRIPTION
          </v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            class="button"
            size="large"
            variant="plain"
            block
            @click="onClickMoveToDashboard"
          >
            close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import { ref, computed } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER } from "@/store/modules/user";
import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import $axios from "@/core/utils/axios-api-config";
import { cloneObject } from "@/core/utils/common";

export default {
  components: {
    CommonDialog,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isShowSubscriptionPlan = ref(false);
    const priceValue = ref("");
    const totalMember = ref(1);
    const subTotal = ref(4.99);
    const customer = ref("") as any;
    const stripe = ref(null);
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const paymentStatus = ref("");
    const membershipPrice = ref(2.49);
    const selectedPlanPrice = ref(4.99) as any;

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowHomeownersWelcome = computed(
      () => store.getters[`${WORKSTATION}/getHomeownersWelcomeState`]
    );

    const onClickToggleHomeownerWorkstation = () => {
      store.commit(`${WORKSTATION}/setHomeownersWorkStationState`, true);
      store.commit(`${WORKSTATION}/setHomeownersWelcomeState`, false);
    };
    const onClickToggleHomeownerWelcome = () => {
      isShowSubscriptionPlan.value = !isShowSubscriptionPlan.value;
    };

    const onClickContinueSubscription = () => {
      isShowSubscriptionPlan.value = true;
    };

    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean
    ) => {
      stripe.value = initializeStripe();

      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment);
      }
    };

    const onCheckout = async (isTrial: boolean, oneTimePayment: boolean) => {
      await createSession(isTrial, oneTimePayment);
      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log("Error: ", result.error);
            }
          });
      }
    };

    const createSession = async (isTrial = false, oneTimePayment = false) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = `tradesperson-profile-${userId}`;

      const entity = JSON.stringify({
        id: userId,
        name: "tradesperson-profile",
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            customer.value = customerResponse;
          })
          .catch((e) => {
            console.log("Error: ", e.message);
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      redirectUrl.value = `${host}/dashboard`;

      const { customerId } = customer.value;
      const totalPrice = 4.99;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: totalPrice,
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              productName,
              userId,
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse.id;
            }
          })
          .catch((error) => {
            console.log("sessionResponse   error", error);
          });
      }
    };
    const onClickPaymentStatusContinue = () => {
      const url = new URL(window.location.href);

      // Remove all query parameters
      url.search = "";

      // Replace the current URL without query parameters
      history.replaceState(null, "", url.toString());
      // isShowLimitedCompanyWorkStationModal.value = true;

      // currentSelectedTab.value = tabListItems[2];
      // store.dispatch(`${WORKSTATION}/setWsModal`,{activeLimitedCompanyWorkStationModal:2});
    };

    const handleTotalMember = (type: string) => {
      if (type === "inc") {
        totalMember.value += 1;
      } else if (type === "desc" && totalMember.value > 1) {
        totalMember.value -= 1;
      }

      subTotal.value = (
        selectedPlanPrice.value +
        (totalMember.value - 1) * membershipPrice.value
      ).toFixed(2);
    };

    const onClickMoveToDashboard = () => {
      store.commit(`${USER}/setRole`, "Home Owner");
      router.push("/dashboard");
    };

    return {
      user,
      isShowHomeownersWelcome,
      onClickToggleHomeownerWelcome,
      onClickContinueSubscription,
      isShowSubscriptionPlan,
      priceValue,
      totalMember,
      subTotal,
      customer,
      stripe,
      sessionId,
      redirectUrl,
      paymentStatus,
      onCheckout,
      initializeStripePayment,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      onClickPaymentStatusContinue,
      handleTotalMember,
      membershipPrice,
      selectedPlanPrice,
      onClickMoveToDashboard,
      onClickToggleHomeownerWorkstation,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/workstation/homeowner/styles/HomeownerWorkstation.scss";
</style>
