<template>
  <div class="calender_content md:!tw-pb-16 md:!tw-box-border">
    <v-tabs class="tab-list" v-model="selectedTab" align-tabs="center" stacked>
      <v-tab
        v-for="tabItem in calendarTabList"
        :key="tabItem.name"
        :value="tabItem.name"
      >
        <v-icon :icon="tabItem.icon"></v-icon>

        {{ tabItem.label }}
      </v-tab>
    </v-tabs>

    <v-window v-model="selectedTab" :touch="false">
      <v-window-item
        v-for="tabValue in calendarTabList"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <PersonalCalendarComponent
          v-if="tabValue.name === 'personal-calendar'"
        />

        <ProjectCalendar
          calendarType="project"
          v-if="
            tabValue.name === 'project-calendar' &&
            selectedTab === 'project-calendar'
          "
        />
        <ProjectCalendar
          calendarType="team"
          v-if="
            tabValue.name === 'team-calendar' && selectedTab === 'team-calendar'
          "
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import PersonalCalendarComponent from "@/modules/calendar/components/PersonalCalendarComponent.vue";
import ProjectCalendar from "@/modules/calendar/components/ProjectCalendar.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { CALENDAR_STORE } from "@/store/modules/calendar";

export default defineComponent({
  name: "CalendarPage",
  components: {
    PersonalCalendarComponent,
    ProjectCalendar,
  },
  setup() {
    const selectedTab = ref();
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const calendarTabList = [
      { name: "team-calendar", icon: "mdi-menu", label: "Team Calendar" },
      {
        name: "personal-calendar",
        icon: "mdi-home",
        label: "Personal Calendar",
      },
      {
        name: "project-calendar",
        icon: "mdi-camera-iris",
        label: "Project Calendar",
      },
    ];

    onMounted(() => {
      store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
        calendarData: {
          calendarData: [],
          calendarSideList: [],
        },
        type: "team",
      });

      store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
        calendarData: {
          calendarData: [],
          calendarSideList: [],
        },
        type: "project",
      });
    });
    return { calendarTabList, selectedTab, UserRolesEnum, user };
  },
});
</script>
<style lang="scss" scoped>
:deep .calender_content {
  :deep .v-tabs--density-default.v-tabs--stacked {
    height: unset;
  }
}
.calender_content {
  .v-slide-group {
    min-height: auto;
    height: auto;
  }
}
</style>
