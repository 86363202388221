<template>
  <div class="verify_jobs_requests_wrapper" v-if="!isLoading">
    <div v-if="!verifiedJobDetail">
      <!-- Heading -->
      <div class="heading">
        <h3 class="tw-flex tw-items-center">Your Verify Jobs Requests</h3>
      </div>
      <div class="tw-mb-8"></div>

      <!-- Total Results -->
      <div class="tw-flex tw-gap-3">
        <p class="tw-text-xs">
          <span class="tw-font-bold">{{ verifyJobs.length }}</span>
          Results
        </p>
      </div>
      <div class="tw-mb-8"></div>

      <!-- Cards -->
      <div class="tw-flex tw-gap-4">
        <div class="tw-basis-full">
          <div
            class="tw-grid tw-gap-7 sm:tw-grid-cols-1 md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-grid-cols-4"
          >
            <RequestJobCard
              v-for="job in verifyJobs"
              :key="job.id"
              :jobDetails="job.project"
              @click="onClickJobCard(job.project)"
            />
            <div v-if="!verifyJobs.length && !isLoading">
              No Found Verfiy Jobs
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CommonLoader :loading="isLoading" />
</template>

<script setup>
import { ref } from "vue";
import RequestJobCard from "./RequestJobCard.vue";
import VerifiedJobDetails from "./VerifiedJobDetails.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { JOBS_STORE } from "@/store/modules/jobs";
import { HistoricProjectStatusEnum } from "@/core/enums/ProjectsEnum";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { onBeforeMount } from "vue";
import project from "@/store/modules/project";

const verifiedJobDetail = ref(false);
const store = useStore();
const activeJob = ref(null);
const onClickJobCard = (job) => {
  activeJob.value = job;
  verifiedJobDetail.value = true;
  store.dispatch(`${JOBS_STORE}/setActiveHistoricalJobs`, job);
  emits("redirectTab", "VerifyJobs");
};
const emits = defineEmits("onClickActiveJob", "redirectTab");
const isLoading = ref(false);

const verifyJobs = computed(() => {
  const jobs = store.getters[`${JOBS_STORE}/getHistoricalJobs`];
  return jobs.filter(
    (data) =>
      data.project?.isHistoricProject &&
      data.project?.projectHistoricalStatus == HistoricProjectStatusEnum.DRAFT
  );
});

onBeforeMount(async () => {
  isLoading.value = true;
  try {
    await store.dispatch(`${JOBS_STORE}/fetchHistoricalJobs`);
  } catch (error) {
    console.log("error", error);
  } finally {
    isLoading.value = false;
  }
});
</script>
