<template>
  <CommonDialog
    :className="['property_owner_terms_modal']"
    :onClickBackButton="onClickCloseButton"
    HeaderTittle="Terms & Conditions"
    title="Terms & Conditions"
    description="Please read through and agree to the terms & conditions to access your iknowa platform."
    width="968px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <TermsAndCondition />
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import { ref } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import TermsAndCondition from "@/core/components/common/TermsAndCondition.vue";

export default {
  components: {
    CommonDialog,
    TermsAndCondition,
  },
  setup(props: any, ctx: any) {
    const isShowTermsAndConditionModal = ref(false);

    const onClickCloseButton = () => {
      ctx.emit("closeTermsAndConditionModal");
    };

    const onClickTermsAndConditionModal = () => {
      isShowTermsAndConditionModal.value = true;
    };

    return {
      onClickCloseButton,
      isShowTermsAndConditionModal,
      onClickTermsAndConditionModal,
    };
  },
};
</script>
