<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-min-h-[200px]"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <v-icon icon="mdi-notebook-edit-outline" color="#FAA500"></v-icon>
          <Text variant="h4" textAlign="center" lineHeight="28px"
            >Are you sure you want to Verify the project?</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          :disabled="loading"
          :isLoading="loading"
          label="Cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          :disabled="loading"
          :isLoading="loading"
          label="confirm"
          :isActive="true"
          @click="onConfirm"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

const props = defineProps(["loading"]);
const emits = defineEmits(["on-close", "on-success", "on-confirm"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const onCloseModal = () => {
  emits("on-close");
};

const onConfirm = () => {
  emits("on-confirm");
};
</script>
