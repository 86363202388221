<template>
  <div
    v-if="!loading"
    class="main_project_preview md:!tw-pb-16 md:!tw-box-border"
  >
    <div class="left_block">
      <v-card class="project_type__overview_card">
        <div class="project_type__overview_card_content">
          <div class="heading">
            <h4 class="title">Project Overview</h4>
          </div>
          <div class="project_type__overview_card_text">
            <div class="project_type__overview_card_text_item">
              <div class="project_type__overview_list">
                <h6>Address</h6>
                <p>
                  {{ ProjectData?.projectProperties[0]?.property?.address }}
                </p>
              </div>
            </div>
            <div class="project_type__overview_card_text_item">
              <div class="heading">Project Details</div>
              <div class="project_type__overview_list">
                <h6>Project Type</h6>
                <p class="tw-capitalize">{{ ProjectData?.type }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="inner__content">
          <div class="title">
            <h5>Estimate</h5>
          </div>
          <div class="estimate_item">
            <h6>Start Date</h6>
            <p>{{ formatDate(ProjectData?.startDate) }}</p>
          </div>
          <div class="estimate_item">
            <h6>Total Phases</h6>
            <p>{{ estimateDetails?.estimationPhase?.length || 0 }}</p>
          </div>
          <div class="estimate_item">
            <h6>Total Tasks</h6>
            <p>
              {{
                estimateDetails?.estimationPhase?.reduce(
                  (oldCount: any, phase: any) => oldCount + phase.taskCount,
                  0
                ) || 0
              }}
            </p>
          </div>
          <div class="estimate_item">
            <h6>Duration</h6>
            <p>
              {{ formatDuration(ProjectData?.startDate, ProjectData?.endDate) }}
            </p>
          </div>
          <div class="estimate_item">
            <h6>Total Cost</h6>
            <p>
              {{
                CurrencyFilter.formatToCurrency(
                  estimateDetails?.totalEstimateCost
                )
              }}
            </p>
          </div>
        </div>
        <v-btn
          class="button button-orange"
          width="100%"
          max-width="100%"
          @click="$emit('view-estimate')"
          >VIEW ESTIMATE</v-btn
        >
      </v-card>
    </div>
    <div class="right_block">
      <!-- Back Button -->
      <div class="top_links_main">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click="$emit('back')"
            >Back</v-btn
          >
        </div>
        <EmergencyChip
          v-if="ProjectData?.type === ProjectTypesEnum.EMERGENCY"
        />
        <StandardChip
          v-else-if="ProjectData?.type === ProjectTypesEnum.STANDARD"
        />
        <RoutineChip
          v-else-if="ProjectData?.type === ProjectTypesEnum.ROUTINE"
        />
      </div>
      <!-- Project Notes -->
      <div class="project_info__wrapper">
        <template v-if="!isEditProjectNoteSection">
          <v-card class="project_info">
            <div class="card_content">
              <div class="card_content__header">
                <div class="card_content__title">
                  <h5 id="project_name">{{ ProjectData?.name }}</h5>
                  <div class="card_content__title_info">
                    <div
                      class="raised"
                      data-comment-element="true"
                      id="project_review_item_icon_commerical"
                    >
                      <v-icon class="pointer_events_none">
                        <img
                          class="pointer_events_none"
                          src="../../../assets/icons/user.svg"
                          alt=""
                        />
                      </v-icon>
                      <p class="pointer_events_none">Commercial</p>
                    </div>
                  </div>
                </div>
                <v-icon
                  v-if="isProjectPending"
                  class="custom_edit"
                  icon="mdi-pencil"
                  @click.stop="updateProjectNameNote(ProjectData)"
                ></v-icon>
              </div>

              <div class="card_content__footer">
                <h6>Project Notes</h6>
                <p
                  v-html="ProjectData?.description"
                  id="project_html_description"
                ></p>
              </div>
            </div>
          </v-card>
        </template>
        <template v-else>
          <v-card class="edit_project_info">
            <div class="title">
              <h6>Project Title</h6>
            </div>
            <v-text-field
              density="compact"
              placeholder="Enter Title"
              variant="outlined"
              class="property_input"
              v-model="projectTitle"
            ></v-text-field>

            <div class="description">
              <h6>Project Notes</h6>
              <div class="custom_editor">
                <quill-editor
                  v-model:value="state.content"
                  :options="state.editorOption"
                  :disabled="state.disabled"
                  class="editor_ql"
                />
              </div>
            </div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="onCancelProjectNotes"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveProjectNotes"
                :loading="isLoadingProjectNotes"
                prepend-icon="mdi-content-save"
              >
                save
              </v-btn>
            </div>
          </v-card>
        </template>
      </div>

      <!-- Specialism -->
      <div class="specialism__wrapper">
        <div class="header">
          <div class="title">
            <h4>Service</h4>
          </div>
          <v-icon
            class="custom_edit"
            v-if="!isEditSpecialismSection && isProjectPending"
            icon="mdi-pencil"
            @click.stop="updateSpecialismSection()"
          ></v-icon>
        </div>
        <div class="specialism__wrapper__content">
          <div v-if="!ProjectData?.subCategories?.length">No Service</div>
          <div v-else class="specialism-card-list">
            <div
              class="specialism-card-list__item"
              v-for="subCategory in ProjectData?.subCategories"
              :key="subCategory.id"
            >
              <v-btn
                variant="text"
                aria-label="Delete"
                class="specialism-card-list__item-delete"
                prepend-icon="mdi-delete"
                v-if="isEditSpecialismSection"
                @click="onClickDeleteCategoryButton(subCategory?.id)"
              ></v-btn>

              <div class="specialism-card-list__item-author">
                <div
                  class="specialism-card-list__item-author-header"
                  :class="specialismCategoryClass(subCategory?.generatedBy)"
                >
                  <div class="speicalism__icon">
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'krystal'
                      "
                      :src="require('@/assets/icons/krystal.svg')"
                      alt="krystalimg"
                    />
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'property'
                      "
                      :src="require('@/assets/icons/house.svg')"
                      alt="houseimage"
                    />
                  </div>
                  <h6>{{ subCategory?.generatedBy }}</h6>
                  <v-icon icon="mdi-information-variant-circle-outline" />
                </div>
                <div class="specialism-card-list__item-author-content">
                  <div class="category_icon">
                    <img
                      :src="
                        require('@/assets/category-icons/' +
                          subCategory?.category?.iconName +
                          '.svg')
                      "
                      alt="Image"
                    />
                  </div>
                  <div class="category_text">
                    <h5>{{ subCategory?.label }}</h5>
                    <p>{{ subCategory?.category?.poLabel }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEditSpecialismSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            variant="text"
            @click="onClickAddMoreSpecialism"
          >
            Add more Specialism
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              @click="onClickCancelSpecialismButton"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveSpecialismButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Project Properties -->
      <div class="properties_wrapper">
        <div class="header">
          <div class="title">
            <h4>Properties</h4>
          </div>
        </div>
        <div class="properties_wrapper__content">
          <div class="properties_wrapper__left no-scrollbar">
            <div class="properties_wrapper__info">
              <div class="properties_wrapper__info__left">
                <div class="location">
                  <v-icon
                    icon="mdi mdi-map-marker"
                    class="pointer_events_none"
                  ></v-icon>
                  {{ ProjectData?.projectProperties[0]?.property?.postcode }}
                </div>

                <div class="prop_name">
                  <v-icon>
                    <img :src="require('@/assets/icons/house.svg')" alt="" />
                  </v-icon>
                  <h6>
                    {{ ProjectData?.projectProperties[0]?.property?.nickName }}
                  </h6>
                </div>
                <div class="property__info">
                  <div class="property__info__item">
                    {{
                      ProjectData?.projectProperties[0]?.property
                        ?.propertyType || "NA"
                    }}
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">
                      {{
                        ProjectData?.projectProperties[0]?.property?.beds ||
                        "NA"
                      }}</span
                    >
                    bedroom
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">{{
                      ProjectData?.projectProperties[0]?.property
                        ?.propertySize || "NA"
                    }}</span>
                    sqm
                  </div>
                </div>
                <div class="property_type">
                  <v-icon
                    icon="mdi mdi-account-circle"
                    class="pointer_events_none"
                  ></v-icon
                  >Commercial
                </div>
              </div>
              <div class="properties_wrapper__info__right">
                <div class="property_image">
                  <img
                    :src="require('@/assets/images/property-sample-image.jpg')"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="properties_wrapper__right">
            <div
              class="map__wrapper"
              id="map_properties"
              v-if="addresses.length"
            >
              <AddressMapComponent
                class="pointer_events_none"
                :latlng="[53.5809811, -0.6393335]"
                :addresses="addresses"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Documents / Attachments -->
      <div class="documents_attachment">
        <div class="title">
          <h3>Documents / Attachments</h3>
          <v-select
            label="All categories"
            :items="getFilterCategory(projectAttachments)"
            item-title="userCategoryName"
            item-value="userCategoryName"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            @update:modelValue="changeCategoryId"
            :menu-props="{ contentClass: 'custom__text__selector' }"
          >
          </v-select>
        </div>
        <v-btn
          v-if="!cloneAttachmentDataList?.length && isProjectPending"
          class="button button-purple-border"
          @click="onClickToggleAttachmentModal"
        >
          Upload Attachment
        </v-btn>

        <v-expansion-panels v-model="panel" multiple class="accordion__wrapper">
          <v-expansion-panel
            v-for="(attachmentCategory, index) in cloneAttachmentDataList"
            :key="index"
          >
            <v-expansion-panel-title>
              <v-icon class="house__icon">
                <img src="../../../assets/icons/house.svg" alt="" />
              </v-icon>
              <h6>{{ attachmentCategory?.userCategoryName }}</h6>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  class="custom_edit"
                  v-if="
                    expanded &&
                    !isEditAttachmentsSection.flag &&
                    isProjectPending
                  "
                  icon="mdi-pencil"
                  @click="updateAttachmentsSection(index)"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-chevron-down"
                  class="bottom__chevron"
                ></v-icon>
              </template>
            </v-expansion-panel-title>

            <div class="group__cards" v-if="isShowGroupCard(index)">
              <div class="group__card__text sdasdasdas">
                <v-expansion-panel-text>
                  <div class="accordion_inner_title">
                    <div class="doc_main">
                      <div
                        class="doc_main__list"
                        v-for="attachment in attachmentCategory.attachments"
                        :key="attachment.id"
                      >
                        <div class="property__img__name">
                          <v-icon icon="mdi mdi-image-outline"></v-icon>
                          <span>{{ attachment?.uploadedImages?.name }}</span>
                        </div>

                        <div
                          v-if="
                            isEditAttachmentsSection.flag &&
                            isEditAttachmentsSection.index === index
                          "
                          @click="
                            onClickRemoveAttachment(
                              attachment?.uploadedImages?.id
                            )
                          "
                        >
                          <v-icon
                            icon="mdi-close"
                            class="pointer_events_none"
                          ></v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="property__images">
                    <div
                      v-for="attachment in attachmentCategory.attachments"
                      :key="attachment.id"
                      class="property__item__images"
                    >
                      <img
                        :src="attachment?.uploadedImages?.url"
                        @click="openCarousel(index)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      isEditAttachmentsSection.flag &&
                      isEditAttachmentsSection.index == index
                    "
                    class="edit-mode-button"
                  >
                    <v-btn
                      class="button button-purple-border"
                      @click="onClickToggleAttachmentModal"
                    >
                      Upload More
                    </v-btn>
                    <div class="edit__buttons">
                      <v-btn
                        class="button button-white"
                        variant="outlined"
                        @click="onCancelAttachments"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="button button-orange"
                        size="large"
                        block
                        @click="onClickSaveButtonAttachments(null)"
                      >
                        save
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <CommonLoader :loading="isDocumentsLoading" />
    </div>

    <SpecialismMultiSelectModel
      v-if="isShowSpecialismModal"
      :isHistorical="false"
      :projectId="ProjectData.id"
      :selectedSpecialism="ProjectData?.subCategories"
      :isLoading="isLoadingForSaveSpecialism"
      @onClickCloseTab="onClickCloseTab"
      @onClickSaveButtonClick="onClickSaveButtonClick"
    />
    <UploadProjectAttachmentsModal
      v-if="isShowProjectAttachmentUploadModal"
      :projectAttachments="attachmentsData"
      :projectData="ProjectData"
      @on-save="onClickSaveButtonAttachments"
      @on-close="onClickToggleAttachmentModal"
    />
    <!-- CarouselDialog -->
    <v-dialog
      v-model="isCarouselDialog"
      height="auto"
      max-width="600"
      class="project__carousal__modal"
      :show-arrows="false"
    >
      <v-card>
        <template v-slot:actions>
          <v-btn
            icon="mdi-close"
            @click="isCarouselDialog = false"
            class="d-flex"
          ></v-btn>
          <v-carousel
            hide-delimiter-background
            show-arrows="hover"
            v-model="currentIndex"
          >
            <v-carousel-item
              v-for="attachment in carouselImageList"
              :key="attachment.id"
            >
              <v-img
                cover
                :src="attachment?.url"
                height="100%"
                width="100%"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </template>
      </v-card>
    </v-dialog>
  </div>
  <CommonLoader :loading="loading" />
</template>
<script setup lang="ts">
import { ref, reactive, getCurrentInstance, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { quillEditor } from "vue3-quill";
import moment from "moment";
import SpecialismMultiSelectModel from "@/modules/project/modal/SpecialismMultiSelectModel.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import { parseJSON } from "@/core/utils/common";
import {
  HistoricProjectStatusEnum,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { PROJECT, PROJECT_STORE } from "@/store/modules/project";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import CurrencyFilter from "@/filters/currency.filter";
import UploadProjectAttachmentsModal from "../modal/UploadProjectAttachmentsModal.vue";

const internalInstance = getCurrentInstance();
const props = defineProps({
  projectRefId: {
    type: String,
  },
});
const loading = ref(true);
const isLoadingProjectData = ref(false);
const ProjectData = ref() as any;
const estimateDetails = ref() as any;
const availableCategoryList = ref();

// Project Notes
const isEditProjectNoteSection = ref(false);
const projectTitle = ref("");
const isLoadingProjectNotes = ref(false);
const state = reactive({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "Description here",
    modules: {},
  },
  disabled: false,
});

// Specialisms
const isEditSpecialismSection = ref(false);
const isShowSpecialismModal = ref(false);
const isLoadingForSaveSpecialism = ref(false);

// Properties
const addresses = ref([]) as any;

// Documents
const isDocumentsLoading = ref(false) as any;
const projectAttachments = ref() as any;
const cloneAttachmentDataList = ref() as any;
const panel = ref([]) as any;
const isEditAttachmentsSection = ref({
  flag: false,
  index: -1,
});
const attachmentsData = ref() as any;
const isCarouselDialog = ref(false);
const carouselImageList = ref([]) as any;
const currentIndex = ref(0);
const isShowProjectAttachmentUploadModal = ref(false);

const store = useStore();

const isProjectPending = computed(
  () =>
    ProjectData.value?.projectHistoricalStatus ==
    HistoricProjectStatusEnum.PENDING
);

const formatDate = (startDate: string) => {
  if (!startDate) {
    return "N/A";
  }
  const date = new Date(startDate);
  const options = { day: "2-digit", month: "long", year: "numeric" } as any;
  return date.toLocaleDateString("en-GB", options);
};

const formatDuration = (sDate: any, eDate: any) => {
  if (!sDate || !eDate) {
    return 0;
  }

  const startDate = moment(sDate) as any;
  const endDate = moment(eDate) as any;

  const totalDays = endDate.diff(startDate, "days");

  if (totalDays % 7 === 0) {
    const totalWeeks = totalDays / 7;
    return `${totalWeeks} week${totalWeeks > 1 ? "s" : ""}`;
  }

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
};

const onClickSaveButtonAttachments = async (attachmentsPayload: any) => {
  try {
    if (
      attachmentsPayload?.updatePayload?.length ||
      attachmentsData.value.length != projectAttachments.value.length
    ) {
      isDocumentsLoading.value = true;
      const presentAttachmentsIds = attachmentsData.value.map(
        (o: any) => o.uploadedImages?.id
      );
      let removedAttachment = projectAttachments.value.filter(
        (o: any) => !presentAttachmentsIds.includes(o.uploadedImages.id)
      );
      removedAttachment = removedAttachment.map((o: any) => ({
        id: o.uploadedImages?.id,
        propertyIds: [
          {
            propertyId: ProjectData.value?.id,
            isDeleted: true,
          },
        ],
        isDeleted: true,
      }));

      const updateAttachmentDataRes = await store.dispatch(
        `${PROJECT_STORE}/updateHistoricalProject`,
        {
          projectId: ProjectData.value?.id,
          data: {
            attachments: [
              ...(attachmentsPayload?.updatePayload || []),
              ...removedAttachment,
            ],
          },
        }
      );

      if (attachmentsPayload?.updatePayload?.length) {
        attachmentsPayload?.attachmentData.forEach((attachment: any) => {
          if (attachment.uploadedImages.imageData) {
            const updatedAttachment =
              updateAttachmentDataRes.projectAttachments.find(
                (projectAttachment: any) =>
                  projectAttachment.originalName ===
                  attachment.uploadedImages.name
              );

            if (updatedAttachment?.id) {
              attachment.uploadedImages = {
                ...(attachment?.uploadedImages || {}),
                imageData: null,
                id: updatedAttachment.id,
                attachment: updatedAttachment.attachment,
              };
            }
          }
        });
        attachmentsData.value = attachmentsPayload?.attachmentData;
      }
      projectAttachments.value = JSON.parse(
        JSON.stringify(attachmentsData.value)
      );
      cloneAttachmentDataList.value = getFilterCategory(
        projectAttachments.value
      );
      displayToast("Update successfully.", "success");
      // await setProjectData();
    }
    isEditAttachmentsSection.value.flag = false;
  } catch (error) {
    console.log("attechment update error", error);
  } finally {
    isDocumentsLoading.value = false;
  }
};

const toggleEditAttachmentsSection = () => {
  isEditAttachmentsSection.value.flag = !isEditAttachmentsSection.value.flag;
};

const onCancelAttachments = async () => {
  toggleEditAttachmentsSection();
  attachmentsData.value = JSON.parse(JSON.stringify(projectAttachments.value));
  cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
};

const onClickToggleAttachmentModal = (payload: any) => {
  isShowProjectAttachmentUploadModal.value =
    !isShowProjectAttachmentUploadModal.value;
};

const openCarousel = (idx = 0) => {
  isCarouselDialog.value = true;
  const carouselImages = [] as any;
  cloneAttachmentDataList.value.map((data: any) => {
    data.attachments.map((attachment: any) => {
      carouselImages.push({ url: attachment?.uploadedImages?.url });
    });
    carouselImageList.value = carouselImages;
  });
  currentIndex.value = idx;
};

const onClickRemoveAttachment = (imgId: any) => {
  cloneAttachmentDataList.value = cloneAttachmentDataList.value.map(
    (category: any) => {
      return {
        userCategoryName: category.userCategoryName,
        attachments: category.attachments.filter(
          (attachment: any) => attachment.uploadedImages.id !== imgId
        ),
      };
    }
  );

  attachmentsData.value = attachmentsData.value.filter(
    (attachment: any) => attachment.uploadedImages.id !== imgId
  );
};

const isShowGroupCard = (index: any) => {
  return panel.value.includes(index);
};

const updateAttachmentsSection = (index: any) => {
  isEditAttachmentsSection.value.flag = true;
  isEditAttachmentsSection.value.index = index;
  panel.value.push(index);
};

const getFilterCategory = (attachments: any) => {
  const grouped =
    attachments?.reduce((acc: any, item: any) => {
      const { userCategoryName, uploadedImages, propertyId } = item;
      if (!acc[userCategoryName]) {
        acc[userCategoryName] = [];
      }
      acc[userCategoryName].push({ uploadedImages, propertyId });
      return acc;
    }, {}) || {};
  return Object.keys(grouped).map((key) => ({
    userCategoryName: key,
    attachments: grouped[key],
  }));
};

const changeCategoryId = (categories: any) => {
  if (categories.length > 0) {
    const filterData = projectAttachments.value.filter((attachement: any) =>
      categories.includes(attachement.userCategoryName)
    );

    cloneAttachmentDataList.value = getFilterCategory(filterData);
  } else {
    cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
  }
};

const updateSpecialismSection = () => {
  isEditSpecialismSection.value = true;
};

const onClickDeleteCategoryButton = (subCategoryId: number) => {
  ProjectData.value.subCategories = ProjectData.value.subCategories.filter(
    (subCategory: any) => {
      return subCategory.id !== subCategoryId;
    }
  );
};

const specialismCategoryClass = (val: string) => {
  if (val.toLowerCase() === "krystal") {
    return "krystal";
  }
  if (val.toLowerCase() === "property") {
    return "property";
  }
  if (val.toLowerCase() === "trade") {
    return "trade";
  }
};

const onClickAddMoreSpecialism = () => {
  isShowSpecialismModal.value = true;
};

const onClickCloseTab = () => {
  isShowSpecialismModal.value = false;
  setProjectData();
};

const onClickSaveButtonClick = async (selectedCategory: any) => {
  try {
    isLoadingForSaveSpecialism.value = true;
    const projectSubCategories = ProjectData.value?.subCategories.map(
      (subCategory: any) => {
        return subCategory.id;
      }
    );

    const removedSubCategories = projectSubCategories.filter(
      (subCategoryId: number) => {
        return !selectedCategory.includes(subCategoryId);
      }
    );

    if (removedSubCategories.length) {
      ProjectData.value.subCategories = ProjectData.value.subCategories.filter(
        (subCategory: any) => {
          return !removedSubCategories.includes(subCategory.id);
        }
      );
    }

    const newAddedSubCategoriesIds = selectedCategory.filter(
      (subCategoryId: number) => {
        return !projectSubCategories.includes(subCategoryId);
      }
    );

    if (newAddedSubCategoriesIds.length) {
      const newAddedSubCategoriesData = await store.dispatch(
        `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
        {
          subCategoriesIds: newAddedSubCategoriesIds,
        }
      );

      newAddedSubCategoriesData.forEach((subCategory: any) => {
        ProjectData.value?.subCategories.push(subCategory);
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoadingForSaveSpecialism.value = false;
    isShowSpecialismModal.value = false;
  }
};

const onClickCancelSpecialismButton = async () => {
  isEditSpecialismSection.value = false;
  await setProjectData();
};

const onClickSaveSpecialismButton = async () => {
  let selectedCategoryFinal = ProjectData.value?.subCategories.map(
    (subCategory: any) => {
      return subCategory.id;
    }
  );

  await store.dispatch(`${PROJECT_STORE}/updateSubSpecialismCategory`, {
    projectId: ProjectData.value?.id,
    data: {
      categoryData: selectedCategoryFinal,
    },
  });

  onClickCancelSpecialismButton();
};

const updateProjectNameNote = (ProjectDetails: any) => {
  isEditProjectNoteSection.value = true;
  projectTitle.value = ProjectDetails?.name;
  state.content = ProjectDetails?.description;
};

const displayToast = (message: string, type: string) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const onCancelProjectNotes = () => {
  isEditProjectNoteSection.value = false;
};

const onClickSaveProjectNotes = async () => {
  try {
    isLoadingProjectNotes.value = true;
    await store.dispatch(`${PROJECT_STORE}/updateProject`, {
      projectId: ProjectData.value?.id,
      data: {
        description: state.content,
        name: projectTitle.value,
      },
    });
    await setProjectData();
    displayToast("Update successfully.", "success");
  } catch (error) {
    console.log(error);
  } finally {
    isEditProjectNoteSection.value = false;
    isLoadingProjectNotes.value = false;
  }
};

const setProjectData = async () => {
  try {
    isLoadingProjectData.value = true;
    const projectDataResponse = await store.dispatch(
      `${PROJECT_STORE}/getProjectData`,
      {
        projectRefId: props.projectRefId,
      }
    );

    availableCategoryList.value = await store.dispatch(
      `${PROJECT_STORE}/getProjectCategoryList`,
      projectDataResponse.id
    );
    ProjectData.value = projectDataResponse;

    // This just sets the project details
    store.dispatch(`${PROJECT}/projectDetails`, {
      inviteMembers: ProjectData.value?.invitedMembersAndRoles,
    });

    const address = parseJSON(
      ProjectData.value?.projectProperties?.[0]?.property?.addressData
    );

    if (address) {
      address.latlang = [53.5809811, -0.6393335];
      addresses.value.push(address);
    }

    projectAttachments.value = projectDataResponse?.projectAttachments?.length
      ? Object.values(
          projectDataResponse?.projectAttachments[0]?.categories
        ).flatMap((attachments: any) => {
          return attachments.map((attachment: any) => ({
            propertyId: projectDataResponse?.projectAttachments[0]?.propertyId,
            userCategoryName: attachment.name,
            uploadedImages: {
              id: attachment.id,
              name: attachment.originalName,
              attachment: attachment.attachment,
              url: attachment.attachmentUrl,
            },
          }));
        })
      : [];
    cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
    attachmentsData.value = JSON.parse(
      JSON.stringify(projectAttachments.value)
    );
  } catch (error) {
    console.log();
  } finally {
    isLoadingProjectData.value = false;
  }
};

const getProjectData = async () => {
  try {
    loading.value = true;
    await setProjectData();
  } catch (error) {
    console.log("error:", error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await getProjectData();
  estimateDetails.value = await store.dispatch(
    `${TRADE_ESTIMATE_STORE}/setTradeEstimateDetails`,
    {
      projectEstimateId: ProjectData.value.projectEstimateId,
    }
  );
});
</script>
<style lang="scss" scoped>
@import "@/modules/properties/styles/propertyReview.scss";
:deep(.v-select__selection-text) {
  font-size: 12px;
}
</style>
