export const emailValidationSchema = (value: string) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  )
    return true;
  return "Invalid email. Try again.";
};

export const nameValidationSchema = (name: string, value: string) => {
  if (value) return true;
  return `${name} is required.`;
};

export const confirmPasswordValidationSchema = (
  name: string,
  value: string,
  comparisonValue: string
) => {
  if (value === comparisonValue) return true;
  return `Your Passwords Do Not Match`;
};

export const UKPhoneValidationSchema = (value: string) => {
  const phoneRegex =
    /^(?:(?:(?:00\s?|\+)(44)\s?(?:\(?0\)?\s?|0))(?:(?:1\d{3}|7[1-9]\d{2}|20\s?[7-9]\d{2}|2[1-9](?:\d{3})?)\s?\d{3}\s?\d{3})|(?:1\d{10}|7[1-9]\d{9}|20[7-9]\d{8}|2[1-9]\d{9}))$/;

  if (phoneRegex.test(value)) {
    return true;
  }

  return "Invalid phone number. Please enter a valid UK phone number.";
};
