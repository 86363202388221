<template>
  <GeneralDialog
    @on-close="onCloseModal"
    headerTitle="Add New Report"
    :isShowHeader="!showRemoveConfirmation"
    :isShowCloseButton="!showRemoveConfirmation"
  >
    <template #body>
      <div class="tw-w-full tw-h-full">
        <div
          v-if="showRemoveConfirmation"
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-w-full"
          >
            <TrashIcon
              fillOpacity="1"
              fill="#F45454"
              class="tw-w-[27px] tw-h-[35px]"
            />
            <Text variant="h4" textAlign="center">Remove Connection</Text>
            <Text
              variant="p"
              textWeight="400"
              textAlign="center"
              textColor="rgba(12, 15, 74, 0.5)"
              lineHeight="18px"
              >Are you sure you want to<br />
              Remove Connection?</Text
            >
          </div>
        </div>
        <div
          v-else
          class="tw-w-[540px] tw-max-h-[570px] tw-border-b-solid-custom tw-flex tw-flex-col tw-gap-4 tw-overflow-x-hidden tw-overflow-y-auto tw-pb-4 tw-max-w-full"
        >
          <div class="tw-flex tw-flex-col tw-gap-3">
            <InputField
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              v-model="filters.keywords"
            />
          </div>
          <div
            class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-3"
            v-if="propertyConnections && propertyConnections.length"
          >
            <div
              v-for="(connection, index) in propertyConnections"
              :key="index"
              class="tw-bg-[rgba(241,246,251,0.86)] tw-w-full tw-rounded-md tw-flex tw-flex-col tw-justify-between tw-gap-3 tw-p-4 tw-box-border"
            >
              <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
                <!-- <div
              class="tw-w-full tw-flex tw-items-center tw-justify-between tw-pb-4 tw-box-border tw-border-b-dashed-custom"
            > -->
                <div class="tw-flex tw-items-center tw-gap-2">
                  <ProfileThumbnail
                    size="mmd"
                    class="!tw-rounded-full !tw-overflow-hidden"
                    v-if="connection?.userWorkstation?.profileImageUrl"
                    :src="connection?.userWorkstation?.profileImageUrl"
                  />
                  <div
                    v-else
                    class="!tw-rounded-lg tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                  >
                    <UserProfileLogo
                      class="ws-profile-logo"
                      :userName="connection?.userWorkstation?.name"
                    />
                  </div>
                  <h6
                    class="tw-text-xs tw-text-[#0C0F4A] tw-leading-4 tw-font-semi-bold"
                  >
                    {{ connection?.userWorkstation?.name }}
                  </h6>
                </div>
                <div
                  @click="onConfirmRemoveConnection(connection)"
                  class="tw-cursor-pointer tw-rounded-[5px] tw-border-solid tw-border-[.83px] tw-border-[#FF3636] tw-min-w-[140px] tw-max-w-[140px] tw-min-h-[20px] tw-flex tw-items-center tw-justify-center tw-gap-1"
                >
                  <Text variant="xsmall" textColor="#FF3636" whiteSpace="nowrap"
                    >REMOVE CONNECTION</Text
                  >
                </div>
              </div>

              <!-- <v-select
              class="c-select tw-w-full"
              v-model="member.inviteAs"
              :items="inviteAsType"
              :rules="validation.inviteAs"
              placeholder="Select Role"
              variant="outlined"
            /> -->
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full">
        <div
          v-if="showRemoveConfirmation"
          class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3"
        >
          <Button
            variant="secondary"
            :disabled="isDeleting"
            label="Cancel"
            :isActive="true"
            @click="onCancelRemove"
          />
          <Button
            :disabled="isDeleting"
            :isLoading="isDeleting"
            variant="accent"
            label="Remove"
            :isActive="true"
            @click="onRemoveConnection"
          />
        </div>
        <div
          v-else
          class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3"
        >
          <Button
            variant="secondary"
            label="close"
            :isActive="true"
            @click="onCloseModal"
          />
          <Button
            variant="accent"
            label="done"
            :isActive="true"
            @click="onDone"
          />
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import UploadForm from "@/core/components/forms/Upload.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

import { PROPERTY_STORE } from "@/store/modules/property";
import { CONNECTIONS } from "@/store/modules/connections";
import { USER_STORE } from "@/store/modules/user";

const emits = defineEmits(["on-close", "on-success"]);

const store = useStore();
const route = useRoute();

const filters = reactive({
  keywords: "",
});

const selectedConnection = ref(null) as any;
const isDeleting = ref(false);
const loading = ref(false);
const showRemoveConfirmation = ref(false);
const inviteAsType = ref(["admin", "editor", "viewer"]);

const propertyId = computed(() => route?.params?.propertyId);
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const propertyConnections = computed(
  () => store.getters[`${CONNECTIONS}/propertyConnections`]
);

const onConfirmRemoveConnection = (connection: any) => {
  selectedConnection.value = connection;
  showRemoveConfirmation.value = true;
};

const onCancelRemove = () => {
  selectedConnection.value = null;
  showRemoveConfirmation.value = false;
};
const initialize = async () => {
  const payload = {
    userId: user.value?.id,
    propertyId: propertyId.value,
  };
  loading.value = true;
  try {
    await store.dispatch(`${CONNECTIONS}/getConnectionsOfProperty`, payload);
  } catch (error) {
    loading.value = false;
  } finally {
    loading.value = false;
  }
};

const onRemoveConnection = async () => {
  try {
    isDeleting.value = true;
    // console.log(selectedConnection.value, "selectedConnection");
    await store.dispatch(`${CONNECTIONS}/rejectPropertyUserConnection`, {
      userId: user.value?.id,
      tradeUserConnectionId: selectedConnection.value.id,
    });
    await initialize();
  } catch (error) {
    console.log("error", error);
  } finally {
    isDeleting.value = false;
    showRemoveConfirmation.value = false;
  }
};

const onCloseModal = () => {
  emits("on-close");
};
const onDone = () => {
  onCloseModal();
};
</script>
