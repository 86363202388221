import { InsuranceState } from "@/core/models/insurance";

export default {
  setInsurances: (state: InsuranceState, payload: Array<any>) => {
    state.insurances = payload;
  },
  setInsuranceExpired: (state: InsuranceState, status: boolean) => {
    state.isInsuranceExpired = status;
  },
};
