<template>
  <div
    v-if="loading"
    class="custom_loader flex justify-center items-center h-40"
  >
    <v-progress-circular
      indeterminate
      :color="color"
      :size="51"
      time
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>
