<template>
  <!-- LOADER -->
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
  >
    <Loader :show="isLoading" />
  </div>
  <div v-if="!isLoading" class="e-wallet-transaction-list">
    <div class="e-wallet-money-card">
      <h6>Your Wallet</h6>
      <div class="e-wallet-money">
        <div
          v-if="activeUserWorkstation?.name === 'Default'"
          class="e-wallet-money-text"
        >
          £25,000.00
        </div>
        <div v-else class="e-wallet-money-text">£ {{ availableBalance }}</div>
        <p>Available Balance</p>
      </div>
      <div class="e-wallet-money-out">
        <div class="e-wallet-money-out-inner-box" @click="onClickMoneyOut">
          <img src="@/assets/icons/money-card.svg" />MONEY OUT
          <img src="@/assets/icons/blue-money-out.svg" />
        </div>
      </div>
    </div>
    <div class="e-wallet-transaction-list-card">
      <div class="e-wallet-header">
        <h6>Transactions</h6>
        <div class="e-wallet-download">
          <v-btn
            class="button"
            @click="onClickDownload"
            v-if="activeUserWorkstation?.name !== 'Default'"
            ><img src="@/assets/icons/download-icon.svg" />Download</v-btn
          >
          <div v-if="isShowRange" class="download-card">
            <div class="download-card-header">Download Statement</div>
            <div class="download-card-content">
              <h5>From</h5>
              <DatePicker v-model.range="dataRange" :popover="true">
                <template #default="{ togglePopover, inputValue, inputEvents }">
                  <div class="calendar__select" @click="() => togglePopover()">
                    <v-text-field
                      :value="inputValue.start"
                      variant="outlined"
                      v-on="inputEvents"
                      placeholder="Choose Date"
                      class="date__start input_field"
                      prepend-inner-icon="mdi-calendar-text"
                      color="#0C0F4A"
                    />
                    <v-text-field
                      :value="inputValue.end"
                      variant="outlined"
                      v-on="inputEvents"
                      placeholder="Choose Date"
                      class="date__start"
                      prepend-inner-icon="mdi-calendar-text"
                      color="#0C0F4A"
                    />
                  </div>
                </template>
              </DatePicker>
              <v-btn
                class="button button-orange"
                @click="onClickDownloadInvoice"
                >DOWNLOAD</v-btn
              >
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          transactionList.length > 0 &&
          activeUserWorkstation?.name !== 'Default'
        "
      >
        <div class="transaction-list__content desktop__wrapper">
          <div class="transaction-list__first_row">
            <div class="transaction-list__column">
              <h5>Date</h5>
            </div>
            <div class="transaction-list__column"><h5>Time</h5></div>
            <div class="transaction-list__column">
              <h5>Description</h5>
            </div>
            <div class="transaction-list__column">
              <h5>Status</h5>
            </div>
            <div class="transaction-list__column"><h5>Amount</h5></div>
          </div>
          <div class="transaction-list__bottom-content">
            <div
              class="transaction-list__last_row"
              v-for="(transaction, index) in transactionList"
              :key="index"
            >
              <div class="transaction-list__column">
                <p>{{ formatDate(transaction?.transactionDate) }}</p>
              </div>
              <div class="transaction-list__column">
                <p>{{ formatTime(transaction?.transactionDate) }}</p>
              </div>
              <div class="transaction-list__column">
                <!-- <p>{{ transaction?.user ? [transaction?.user] : "-" }}</p> -->
                <span v-if="transaction?.description">{{
                  transaction?.description
                }}</span>
              </div>

              <div class="transaction-list__column">
                <p>{{ transaction?.status || "-" }}</p>
              </div>

              <div class="transaction-list__column">
                <p
                  :class="
                    transaction?.status === 'paid'
                      ? 'text-success'
                      : transaction?.status === 'released'
                      ? 'text-danger'
                      : ''
                  "
                >
                  {{
                    transaction?.status === "paid"
                      ? "+"
                      : transaction?.status === "released"
                      ? "-"
                      : ""
                  }}£{{ transaction?.transactionAmount }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="transaction-list__content mobile__wrapper"
          v-for="(transaction, index) in transactionList"
          :key="index"
        >
          <div class="transaction-list__content__item">
            <h5>Date</h5>
            <div class="right__data">
              <p>{{ formatDate(transaction?.transactionDate) }}</p>
            </div>
          </div>
          <div class="transaction-list__content__item">
            <h5>Time</h5>
            <div class="right__data">
              <p>{{ formatTime(transaction?.transactionDate) }}</p>
            </div>
          </div>
          <div class="transaction-list__content__item">
            <h5>Description</h5>
            <div class="right__data">
              <!-- <p>{{ [transaction?.user || "NA"] }}</p> -->
              <span>{{ transaction?.description }}</span>
            </div>
          </div>
          <div class="transaction-list__content__item">
            <h5>Status</h5>
            <div class="right__data">
              <span>{{ transaction?.status || "-" }}</span>
            </div>
          </div>

          <div class="transaction-list__content__item">
            <h5>Amount</h5>
            <div class="right__data">
              <p
                :class="
                  transaction?.status === 'paid'
                    ? 'text-success'
                    : transaction?.status === 'released'
                    ? 'text-danger'
                    : ''
                "
              >
                {{
                  transaction?.status === "paid"
                    ? "+"
                    : transaction?.status === "released"
                    ? "-"
                    : ""
                }}£{{ transaction?.transactionAmount }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="e-wallet-transaction-list-empty-card"
        v-if="
          !transactionList.length && activeUserWorkstation?.name !== 'Default'
        "
      >
        <div class="empty__icon">
          <img src="@/assets/icons/empty-transaction-list.svg" />
        </div>
        <p>Your e-wallet transactions will show up here.</p>
      </div>

      <div v-if="activeUserWorkstation?.name === 'Default'">
        <div class="transaction-list__content desktop__wrapper">
          <div class="transaction-list__first_row">
            <div class="transaction-list__column">
              <h5>Date</h5>
            </div>
            <div class="transaction-list__column"><h5>Time</h5></div>
            <div class="transaction-list__column">
              <h5>Description</h5>
            </div>
            <div class="transaction-list__column"><h5>Amount</h5></div>
          </div>
          <div class="transaction-list__bottom-content">
            <div
              class="transaction-list__last_row"
              v-for="(transaction, index) in transactionListDummyData"
              :key="index"
            >
              <div class="transaction-list__column">
                <p>{{ transaction?.date }}</p>
              </div>
              <div class="transaction-list__column">
                <p>{{ transaction?.time }}</p>
              </div>
              <div class="transaction-list__column">
                <p>{{ transaction?.description }}</p>
                <span>Phase {{ index + 1 }}</span>
              </div>
              <div class="transaction-list__column">
                <p class="!tw-text-[#FFA500]">{{ transaction?.amount }}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="transaction-list__content mobile__wrapper"
          v-for="(transaction, index) in transactionListDummyData"
          :key="index"
        >
          <div class="transaction-list__content__item">
            <h5>Date</h5>
            <div class="right__data">
              <p>{{ transaction?.date }}</p>
            </div>
          </div>
          <div class="transaction-list__content__item">
            <h5>Time</h5>
            <div class="right__data">
              <p>{{ transaction?.time }}</p>
            </div>
          </div>
          <div class="transaction-list__content__item">
            <h5>Description</h5>
            <div class="right__data">
              <p>{{ transaction?.description }}</p>
              <span>Phase {{ index + 1 }}</span>
            </div>
          </div>
          <div class="transaction-list__content__item">
            <h5>Amount</h5>
            <div class="right__data">
              <p class="!tw-text-[#FFA500]">{{ transaction?.amount }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MoneyOutModalComponent
    v-if="isShowMoneyOutModal"
    :availableBalance="availableBalance"
    @closeMoneyOutModal="onClickCloseButton"
  />
</template>
<script lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { E_WALLET_STORE } from "@/store/modules/e-wallet";
import { DatePicker } from "v-calendar";
import MoneyOutModalComponent from "@/modules/e-wallet/components/MoneyOutModalComponent.vue";
import Loader from "@/core/components/common/Loader.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    MoneyOutModalComponent,
    DatePicker,
    Loader,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const internalInstance = getCurrentInstance();

    const dataRange = ref(new Date()) as any;
    const isShowRange = ref(false);
    const isShowMoneyOutModal = ref(false);
    const isLoading = ref(false);
    const availableBalance = ref(0.0);
    const transactionList = ref([]) as any;
    const downloadLoading = ref(false);

    const transactionListDummyData = ref([
      {
        date: "22/07/2024",
        time: "10:21",
        description: "Gas Leakage",
        amount: "+ £1,500.00",
      },
      {
        date: "17/07/2024",
        time: "13:12",
        description: "Solar PV Installation",
        amount: "+ £12,000.00",
      },
      {
        date: "08/07/2024",
        time: "09:51",
        description: "Air Source Heat Pump Installation",
        amount: "+ £8,000.00",
      },
      {
        date: "04/07/2024",
        time: "11:11",
        description: "Boiler Replacement",
        amount: "+ £3,500.00",
      },
    ]);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickMoneyOut = () => {
      isShowMoneyOutModal.value = true;
    };
    const onClickCloseButton = () => {
      isShowMoneyOutModal.value = false;
      getWalletBalance();
    };

    const onClickDownload = () => {
      isShowRange.value = !isShowRange.value;
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickDownloadInvoice = async () => {
      try {
        downloadLoading.value = true;
        const response = await store.dispatch(
          `${E_WALLET_STORE}/getInvoiceTransactionDetails`,
          {
            userId: user.value.id,
            startTransactionDate: formatStartEndDate(dataRange.value?.start),
            endTransactionDate: formatStartEndDate(dataRange.value?.end),
          }
        );

        if (response) {
          const blob = new Blob([response], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Transactions List.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          displayToast("Download Details Successfully.", "success");
        } else {
          console.error("Invoice URL not found in the response.");
        }
        onClickDownload();
      } catch (error) {
        console.error("Error fetching invoice details:", error);
      } finally {
        downloadLoading.value = false;
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const formatStartEndDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    };

    const formatTime = (dateString: string) => {
      const date = new Date(dateString);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const formattedHours = hours < 10 ? "0" + hours : hours;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${formattedHours}:${formattedMinutes}`;
    };

    const getWalletBalance = async () => {
      try {
        isLoading.value = true;
        const response = await store.dispatch(
          `${E_WALLET_STORE}/getWalletBalance`,
          user.value?.id
        );
        const transactionListResponse = await store.dispatch(
          `${E_WALLET_STORE}/getTransactionList`,
          user.value?.id
        );
        availableBalance.value = response.availableBalance;
        transactionList.value = transactionListResponse?.data;
      } catch (err) {
        console.log(err);
      } finally {
        isLoading.value = false;
      }
    };

    onBeforeMount(() => {
      getWalletBalance();
    });

    return {
      onClickMoneyOut,
      transactionList,
      isShowMoneyOutModal,
      onClickCloseButton,
      formatDate,
      dataRange,
      isShowRange,
      onClickDownload,
      onClickDownloadInvoice,
      isLoading,
      availableBalance,
      formatTime,
      downloadLoading,
      activeUserWorkstation,
      transactionListDummyData,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/e-wallet/styles/WalletTransactionsListComponent.scss";
</style>
