// import {
//   UpdateProjectStageRequestDto,
//   UpdateProjectStageStatusRequestDto,
// } from "@/core/packages/shared-library";

import $axios from "../utils/axios-api-config";

export default {
  /**
   * Update project quote stage status
   * @param projectQuoteId
   * @param projectStageId
   * @param updateProjectStageStatusRequestDto
   * @returns
   */
  updateProjectStageStatus(
    projectQuoteId: number,
    projectStageId: number,
    updateProjectStageStatusRequestDto: any
  ): Promise<any> {
    return $axios.put(
      `/project-quotes/${projectQuoteId}/stages/${projectStageId}/status`,
      updateProjectStageStatusRequestDto
    );
  },

  /**
   * Update project quote stage status
   * @param projectQuoteId
   * @param projectStageId
   * @param updateProjectStageRequestDto
   * @returns
   */
  updateProjectStage(
    projectQuoteId: number,
    projectStageId: number,
    updateProjectStageRequestDto: any
  ): Promise<any> {
    return $axios.put(
      `/project-quotes/${projectQuoteId}/stages/${projectStageId}`,
      updateProjectStageRequestDto
    );
  },

  /**
   * delete project quote stage attachment
   * @param projectQuoteId
   * @param projectStageId
   * @param projectStageAttachmentId
   * @returns
   */
  deleteProjectStageAttachment(
    projectQuoteId: number,
    projectStageId: number,
    projectStageAttachmentId: number
  ): Promise<any> {
    return $axios.delete(
      `/project-quotes/${projectQuoteId}/stages/${projectStageId}/attachment/${projectStageAttachmentId}`
    );
  },

  /**
   * Update project quote stage task status
   * @param projectQuoteId
   * @param projectStageId
   * @param projectStageTaskId
   * @param projectStageTaskStatus
   * @returns
   */
  updateProjectStageTaskStatus(
    projectQuoteId: number,
    projectStageId: number,
    projectStageTaskId: number,
    projectStageTaskStatus: boolean
  ): Promise<any> {
    return $axios.put(
      `/project-quotes/${projectQuoteId}/stages/${projectStageId}/tasks/${projectStageTaskId}`,
      { done: projectStageTaskStatus }
    );
  },
};
