<template>
  <CommonDialog
    v-model="onCloseModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="848px"
    max-width="100%"
    persistent
    :className="['contract_assign_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <!-- group name -->
        <h2>Phase # notes</h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onCloseModal"
        ></v-icon>
      </div>
      <div class="modal__content">
        <v-expansion-panels v-if="phaseTasks.length">
          <v-expansion-panel v-for="(task, index) in phaseTasks" :key="task.id">
            <v-expansion-panel-title v-if="task?.phaseNotes?.length">
              <div class="contract_assign_modal_item_title">
                <h2>Note {{ index + 1 }}</h2>
                <div class="contract_assign_modal_tag">
                  <p>{{ task.name }}</p>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text v-if="task?.phaseNotes?.length">
              <div class="contract_assign_modal_item_content">
                <div
                  class="contract_assign_modal_info"
                  v-for="notes in task.phaseNotes"
                  :key="notes?.id"
                >
                  <p>
                    {{ notes?.note }}
                  </p>
                  <div class="contract_assign_modal_info_footer">
                    <div class="contract_assign_modal_info_author">
                      <div class="contract_assign_modal_info_author_image">
                        <img
                          v-if="notes?.user?.profileImageUrl"
                          :src="notes?.user?.profileImageUrl"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/contractor-profile.png"
                          alt=""
                        />
                      </div>
                      <p>
                        {{
                          notes?.user
                            ? `${notes.user.firstName} ${notes.user.lastName}`
                            : "Toby Sparks"
                        }}
                      </p>
                    </div>
                    <div class="contract_assign_modal_info_date">
                      <p>{{ formatDate(notes?.dateCreated) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        class="contract_assign_modal_footer"
        :class="{ 'show-add-btn': isShowAddNoteBtn }"
      >
        <Button
          v-if="isShowAddNoteBtn"
          label="+Add Note"
          variant="elevated"
          class="button button-orange"
          @click="onAddNote"
        />
        <Button
          label="Cancel"
          variant="elevated"
          class="button button-purple-border"
          @click="onCloseModal"
        />
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import { computed } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import moment from "moment";
export default {
  components: {
    CommonDialog,
    Button,
  },
  emits: ["on-action", "on-close"],
  props: {
    phaseDetails: { type: Object, default: null },
    isEstimateNote: { type: Boolean, default: false },
    isShowAddNoteBtn: { type: Boolean, default: false },
  },

  setup(props: any, ctx: any) {
    const onCloseModal = () => {
      ctx.emit("on-close");
    };
    const onAddNote = () => {
      ctx.emit("on-action", "add-note");
    };

    const phaseTasks = computed(() => {
      if (props.isEstimateNote) {
        const groupedByTaskOrPhase = props.phaseDetails.reduce(
          (acc: any, note: any) => {
            const key = note.task
              ? `task_${note.task.id}`
              : `phase_${note.phase.id}`;

            if (!acc[key]) {
              acc[key] = {
                ...(note.task ? note.task : note.phase),
                phaseNotes: [],
              };
            }

            acc[key].phaseNotes.push(note);

            return acc;
          },
          {} as { [key: string]: { id: number; name: string; phaseNotes: [] } }
        );
        return Object.values(groupedByTaskOrPhase);
      } else {
        return props.phaseDetails.estimationPhaseTasks;
      }
    });

    const formatDate = (date: any) =>
      moment(date).format("DD, MMMM YYYY | hh:mm A");
    return {
      formatDate,
      onCloseModal,
      phaseTasks,
      onAddNote,
    };
  },
};
</script>
