<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#0C0F4A" fill-opacity="0.05" />
    <g clip-path="url(#clip0_231_67356)">
      <path
        d="M11.5234 14.8564H12.4758C12.7377 14.8564 12.952 14.6421 12.952 14.3802C12.952 14.1183 12.7377 13.904 12.4758 13.904H11.5234C11.2615 13.904 11.0472 14.1183 11.0472 14.3802C11.0472 14.6421 11.2615 14.8564 11.5234 14.8564ZM7.71387 9.61828C7.71387 9.88019 7.92815 10.0945 8.19006 10.0945H15.8091C16.071 10.0945 16.2853 9.88019 16.2853 9.61828C16.2853 9.35638 16.071 9.14209 15.8091 9.14209H8.19006C7.92815 9.14209 7.71387 9.35638 7.71387 9.61828ZM9.61863 12.4754H14.3805C14.6424 12.4754 14.8567 12.2611 14.8567 11.9992C14.8567 11.7373 14.6424 11.523 14.3805 11.523H9.61863C9.35672 11.523 9.14244 11.7373 9.14244 11.9992C9.14244 12.2611 9.35672 12.4754 9.61863 12.4754Z"
        fill="#0C0F4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_231_67356">
        <rect
          width="11.4286"
          height="11.4286"
          fill="white"
          transform="translate(6.28516 6.28516)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SortIcon",
};
</script>
