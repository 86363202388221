<template>
  <ChipState
    :label="value"
    bgColor="transparent"
    whiteSpace="pre-wrap"
    lineHeight="16px"
  >
    <template #prefix>
      <HomeCircleBlue class="!tw-min-w-[15px]" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import HomeCircleBlue from "@/core/components/icons/HomeCircleBlue";

const props = defineProps({
  value: String,
});
</script>
<script>
export default {
  name: "Postcode",
};
</script>
