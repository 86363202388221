<template>
  <GeneralDialog headerTitle="Storage" @on-close="onCloseModal(false)">
    <template #body>
      <section
        class="manage-memory tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6"
      >
        <div
          class="tw-py-7 tw-px-6 tw-box-border tw-w-[689px] tw-h-[120px] tw-rounded-md tw-bg-[#0C0F4A] tw-flex tw-items-center tw-justify-between"
        >
          <div class="tw-flex tw-flex-col tw-items-start tw-gap-3">
            <Text variant="h5" textWeight="400" textColor="#FFF"
              >Current monthly plan -
              <Text variant="h5" textColor="#FFA500">{{
                // `${state.currentSubscription?.capacity || "1GB"}`
                getCurrentActivePlan(+state.currentSubscription?.activePlan)
              }}</Text></Text
            >
            <Text variant="h5" textColor="#FFF"
              >{{
                // state.currentSubscription?.monthly
                getCurrentPlanCost(state.currentSubscription?.upcomingTotal)
              }}
              <Text
                variant="h6"
                textColor="rgba(255, 255, 255, 0.5)"
                textWeight="600"
                >monthly</Text
              ></Text
            >
          </div>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[80px] tw-h-[80px] tw-rounded-full tw-border-[5px] tw-border-[#FFF] tw-border-solid"
          >
            <!-- <Text variant="h6" textColor="#FFA500" lineHeight="18px"
              >{{ state.currentSubscription?.capacity || "1GB"
              }}<Text variant="p" textColor="#FFA500" lineHeight="18px">{{
                state.currentSubscription?.type
              }}</Text></Text
            > -->
            <Text variant="h6" textColor="#FFA500" lineHeight="18px">{{
              getCurrentActivePlan(+state.currentSubscription?.activePlan)
            }}</Text>
            <Text
              variant="span"
              textColor="#FFF"
              textWeight="500"
              lineHeight="18px"
              >Available</Text
            >
          </div>
        </div>
        <div class="manage-memory__container tw-grid tw-w-full tw-gap-6">
          <Card
            :isOverflowAuto="true"
            @click="setSubscription(item)"
            :noShadow="false"
            class="!tw-max-h-[80px] !tw-py-3 !tw-px-4 tw-box-border tw-cursor-pointer tw-relative tw-border-2 tw-border-solid tw-border-[transparent]"
            :class="{
              '!tw-border-[#FFA500]':
                state.selectedSubscription?.activePlan == item.selectedPlan,
            }"
            v-for="(item, index) in subscriptions"
            :key="index"
          >
            <template #content>
              <div class="tw-flex tw-items-center tw-gap-4 tw-w-full tw-h-full">
                <div
                  v-if="item.isPopular"
                  class="tw-absolute tw-top-[-10px] tw-right-2 tw-bg-[#FFA500] tw-rounded-md tw-px-[8px] tw-box-border tw-flex tw-items-center tw-justify-center"
                >
                  <Text variant="span" textColor="#FFF">Most Popular</Text>
                </div>
                <div
                  class="tw-flex tw-items-center tw-justify-center tw-w-[98px] tw-h-[58px] tw-rounded-md tw-bg-[#F3F3F6]"
                >
                  <Text variant="h4">{{ item.capacity }}</Text>
                </div>
                <Text variant="h6"
                  >£{{ item.monthly }}
                  <Text
                    variant="p"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >+ VAT / Monthly</Text
                  ></Text
                >
              </div>
            </template>
          </Card>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-6 tw-w-full">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <LabelWithValue
              :noBg="true"
              label="Sub total"
              :data="pricing?.monthly"
            />
            <LabelWithValue
              :noBg="true"
              label="VAT (20%)"
              :data="pricing?.vat"
            />
            <LabelWithValue
              :noBg="true"
              :allFontBold="true"
              label="Total "
              subLabel="(billed monthly)"
              :data="pricing?.total"
            />
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3"
      >
        <Button
          :isActive="hasSelected"
          :isLoading="isLoading"
          label="pay"
          @click="onUpdateSubscription"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, onMounted, ref, reactive, getCurrentInstance } from "vue";
import { PRODUCTS, VUE_APP_STRIPE_PUBLISHABLE_KEY } from "@/config";
import { useStore } from "vuex";
import Stripe from "@/composables/stripe";
import $axios from "@/core/utils/axios-api-config";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import Text from "@/core/components/ui/general/Text.vue";
import documentsConstants from "@/modules/documents/contants";
import Card from "@/core/components/ui/general/Card.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import CurrencyFilter from "@/filters/currency.filter";
import {
  BusinessDetailTypeEnum,
  WorkstationAccountTypeEnum,
} from "@/core/enums/RolesEnum";
import { UserWorkstationSubStatusEnum } from "@/core/enums";
const emits = defineEmits(["on-close"]);

const store = useStore();
const internalInstance = getCurrentInstance();
const stripe = ref(null);
const sessionId = ref(null);
const redirectUrl = ref("");

const state = reactive({
  selectedSubscription: {},
  currentSubscription: {},
});

const subscriptions = reactive([
  {
    selectedPlan: 1,
    capacity: "1GB",
    monthly: 0.99,
    isPopular: false,
  },
  {
    selectedPlan: 2,
    capacity: "10GB",
    monthly: 1.49,
    isPopular: false,
  },
  {
    selectedPlan: 3,
    capacity: "50GB",
    monthly: 2.49,
    isPopular: false,
  },
  {
    selectedPlan: 4,
    capacity: "100GB",
    monthly: 3.49,
    isPopular: true,
  },
  {
    selectedPlan: 5,
    capacity: "250GB",
    monthly: 4.49,
    isPopular: false,
  },
  {
    selectedPlan: 6,
    capacity: "500GB",
    monthly: 5.49,
    isPopular: false,
  },
  // {
  //   selectedPlan: 7,
  //   capacity: "1TB",
  //   monthly: 5.49,
  //   isPopular: false,
  // },
]);

const isLoading = ref(false);

const hasSelected = computed(() => {
  // return state.selectedSubscription !== "" && state.currentSubscription === "";
  return (
    state.selectedSubscription !== "" &&
    Object.keys(state.selectedSubscription)?.length &&
    state.selectedSubscription !== state.currentSubscription
  );
});

const userSubscriptionDetails = computed(
  () => store.getters[`${WORKSTATION_SETTING}/userSubscriptionDetails`]
);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const pricing = computed(() => {
  let { monthly, vat, total } = getFinalPricing();
  vat = parseFloat(vat);
  monthly = parseFloat(monthly);
  total = parseFloat(monthly) + vat;

  return {
    monthly: CurrencyFilter.formatToCurrency(monthly),
    vat: CurrencyFilter.formatToCurrency(vat),
    total: CurrencyFilter.formatToCurrency(total),
  };
});

const setSubscription = (subscription) => {
  if (state.selectedSubscription) {
    state.selectedSubscription.activePlan = subscription.selectedPlan;
    state.selectedSubscription = {
      ...state.selectedSubscription,
      ...subscription,
    };
  } else {
    state.selectedSubscription = subscription;
  }
};

const setCurrentSubscription = (subscription) => {
  state.currentSubscription = subscription;
};

const setCurrentSubscriptionPlan = () => {
  if (
    user.value &&
    activeUserWorkstation.value.id &&
    userSubscriptionDetails.value &&
    userSubscriptionDetails.value?.length
  ) {
    // const data = JSON.parse(activeUserWorkstation.value.subscription);
    // if (data) {
    //   const { metadata } = data;
    //   setCurrentSubscription(metadata);
    //   setSubscription(metadata);
    // }

    const currentStorageSubscription = userSubscriptionDetails.value.find(
      (subscription) => subscription.subscriptionType === "storage"
    );

    const storageSubscriptionTemp = subscriptions.find(
      (subscription) =>
        subscription.selectedPlan == currentStorageSubscription.activePlan
    );
    setCurrentSubscription(currentStorageSubscription);
    setSubscription({
      ...storageSubscriptionTemp,
      ...currentStorageSubscription,
    });
  }
};

const getCurrentActivePlan = (activePlan) => {
  if (activePlan === 0) {
    return "100MB";
  }
  if (activePlan === 1) {
    return "1GB";
  }
  if (activePlan === 2) {
    return "10GB";
  }
  if (activePlan === 3) {
    return "50GB";
  }
  if (activePlan === 4) {
    return "100GB";
  }
  if (activePlan === 5) {
    return "250GB";
  }
  if (activePlan === 6) {
    return "500GB";
  }
  if (activePlan === 7) {
    return "1TB";
  }
  return "100MB";
};

const getCurrentPlanCost = (cost) => {
  return CurrencyFilter.formatToCurrency(cost);
};
const onCloseModal = (isUpdated) => {
  state.currentSubscription = {};
  state.selectedSubscription = {};
  emits("on-close", isUpdated);
};

const getFinalPricing = () => {
  const { monthly } = state.selectedSubscription;
  const vat = monthly * 0.2; // fixed vat
  const priceWithVat = monthly + vat;

  return {
    ...state.selectedSubscription,
    monthly,
    vat,
    total: priceWithVat,
  };
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const onUpdateSubscription = async () => {
  if (hasSelected.value && !state.currentSubscription.value) {
    try {
      isLoading.value = true;
      if (
        !activeUserWorkstation.value?.subscription &&
        activeUserWorkstation.value?.subStatus !==
          UserWorkstationSubStatusEnum.SUCCEEDED &&
        (!userSubscriptionDetails.value ||
          !userSubscriptionDetails.value?.length)
      ) {
        initializeStripePayment(
          false,
          false,
          state.selectedSubscription?.selectedPlan
        );
      } else {
        const payload = {
          userId: user.value.id,
          userWorkstationId: activeUserWorkstation.value?.id,
          planType: state.selectedSubscription?.selectedPlan,
        };
        await store.dispatch(
          `${WORKSTATION_SETTING}/updateStorageSubscriptionPlan`,
          payload
        );
        isLoading.value = false;
        displayToast("Your storage plan changed successfully.", "success");
        onCloseModal(true);
      }
    } catch (error) {
      displayToast("error in updating storage plan.", "error");
      isLoading.value = false;
      onCloseModal(true);
    }
  }
};

const initializeStripe = () => {
  let stripe = null;

  if ("Stripe" in window) {
    const { Stripe } = window;

    // eslint-disable-next-line no-new
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }

  return stripe;
};

const onCheckout = async (isTrial, oneTimePayment, planType) => {
  await createSession(isTrial, oneTimePayment, planType);
  if (sessionId.value) {
    stripe.value
      .redirectToCheckout({
        sessionId: sessionId.value,
      })
      .then((result) => {
        if (result.error) {
          console.log();
        }
      });
  }
};

const createSession = async (
  isTrial = false,
  oneTimePayment = false,
  planType
) => {
  const productName = "tradesperson-profile";
  const host = `${window.location.protocol}//${window.location.host}`;
  redirectUrl.value = `${host}/documents`;

  const selectedPlan = planType;

  await $axios
    .post(`/payment/${user.value?.id}/stroage/session`, {
      planType: selectedPlan,
      redirectUrl: redirectUrl.value,
      price: 0,
      metadata: JSON.stringify({
        userWorkstationId: activeUserWorkstation.value?.id,
        name: activeUserWorkstation.value?.name,
        companyName: activeUserWorkstation.value?.name,
        productName,
        userId: user.value?.id,
        businessType: BusinessDetailTypeEnum.PROPERTY_OWNER,
        planType: selectedPlan,
        workstationAccountType: WorkstationAccountTypeEnum.HOMEOWNER,
        capacity: "100MB",
        totalMember: 1,
      }),
      isTrial,
      oneTimePayment,
    })
    .then(async (sessionResponse) => {
      if (sessionResponse) {
        sessionId.value = sessionResponse?.id;
      }
    })
    .catch((error) => {
      console.log();
    });
};

const initializeStripePayment = async (isTrial, oneTimePayment, planType) => {
  stripe.value = initializeStripe();
  if (stripe.value) {
    await onCheckout(isTrial, oneTimePayment, planType);
  }
};
// const onPay = async () => {
//   if (hasSelected.value && !state.currentSubscription.value) {
//     const host = `${window.location.protocol}//${window.location.host}`;
//     const redirectUrl = `${host}/${PRODUCTS.storage}`;
//     const pricing = getFinalPricing();
//     const { stripe } = Stripe.useInitialize();
//     const { id: userId, userStripeCustomer, email } = user.value;
//     const productName = PRODUCTS.storage;
//     const metadata = JSON.stringify({
//       userWorkstationId: activeUserWorkstation.value.id,
//       ...pricing,
//       totalMember: 0, // not required
//     });
//     let sessionId = null;

//     isLoading.value = true;

//     if (stripe) {
//       const payload = {
//         userId,
//         userStripeCustomer,
//         email,
//         productName,
//         metadata,
//         pricing,
//         redirectUrl,
//       };

//       try {
//         const { session } = await Stripe.useCreateSession(payload);
//         sessionId = session.value.id;
//       } catch (e) {
//         // prompt error
//         console.log("e", e);
//       }

//       if (sessionId) {
//         stripe
//           .redirectToCheckout({
//             sessionId,
//           })
//           .then((result) => {
//             if (!result.error) {
//               isLoading.value = false;
//             } else {
//               // prompt error
//               console.log("e", result.error);
//             }
//           });
//       }
//     }
//   }
// };

onMounted(() => {
  setCurrentSubscriptionPlan();
});
</script>

<style lang="scss" scoped>
.manage-memory {
  &__container {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}
</style>
