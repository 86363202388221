<template>
  <GeneralDialog
    class="tw-max-w-[560px] tw-min-w-[300px] rmd:tw-min-w-[560px]"
    @on-close="onCloseModal"
    :showBackButton="true"
    @on-back="onBack"
  >
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-start">
            <Text variant="span" textWeight="400">Select Property</Text>
            <!-- <InputSelect
              class="tw-max-w-full !tw-w-full"
              placeholder="Select an email"
              density="compact"
              v-model="siteVisitModel.property"
              :items="[
                '108 Oxford Street, London',
                '11 Iknowa Way, Wolverhampton',
                '10 Earls Terrace, London',
                '108 Oxford Street, London',
              ]"
              variant="outlined"
              item-title="aliasEmail"
              item-value="aliasEmail"
              return-object
            /> -->
            <InputSelect
              variant="outlined"
              class="tw-max-w-full !tw-w-full"
              v-model="siteVisitModel.property"
              :items="propertyList"
              item-title="address"
              item-value="id"
              :menu-props="{ contentClass: 'text__selector' }"
              placeholder="Select Property"
              :rules="[(v) => !!v || 'Property is required']"
              required
              @update:model-value="selectSiteProperty"
            />
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-start">
            <Text variant="span" textWeight="400">Select Date</Text>
            <DatePicker
              :rules="validation.startDate"
              :min-date="new Date()"
              v-model="dataRange"
              :popover="true"
            >
              <template #default="{ togglePopover, inputValue }">
                <div class="tw-w-full" @click="() => togglePopover()">
                  <InputField
                    :value="inputValue"
                    variant="outlined"
                    placeholder="Choose Date"
                    class="date__start input_field"
                    color="#0C0F4A"
                    ><img
                      class="tw-absolute tw-right-4"
                      src="../../../../assets/icons/personal-calendar.svg"
                      alt=""
                    />
                  </InputField>
                </div>
              </template>
            </DatePicker>
          </div>
          <div
            class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-start time_selector"
          >
            <Text variant="span" textWeight="400">Select Time</Text>
            <div class="time_wrapper">
              <div class="time_dropdown">
                <v-text-field
                  density="compact"
                  placeholder="HH:MM"
                  variant="outlined"
                  :rules="validation.startTime"
                  v-model="siteVisitModel.startTime"
                  class="input_field"
                  @click="fromToggleDropdown"
                  :class="{ 'is-open': fromDropdownOpen }"
                >
                  <v-icon
                    icon="mdi-clock"
                    id="menu-activator"
                    class="cursor-pointer"
                  ></v-icon>
                </v-text-field>
                <!-- <div
                            v-if="startTimeError"
                            class="text-red error-message tw-text-xs"
                          >
                            {{ startTimeError }}
                          </div> -->
                <ul class="custom_dropdown__menu" v-if="fromDropdownOpen">
                  <li
                    class="custom_dropdown__item"
                    v-for="(time, index) in filteredFromTimes"
                    :key="index"
                    @click="toggleDropdownOutside"
                  >
                    <label @click="siteVisitModel.startTime = time" for="">
                      {{ time }}</label
                    >
                  </li>
                </ul>
              </div>
              <span class="time__divider">-</span>
              <!-- To Custom Dropdown -->
              <div class="time_dropdown">
                <v-text-field
                  density="compact"
                  placeholder="HH:MM"
                  variant="outlined"
                  v-model="siteVisitModel.endTime"
                  class="input_field"
                  :rules="validation.endTime"
                  @click="toToggleDropdown"
                  :class="{ 'is-open': toDropdownOpen }"
                >
                  <v-icon
                    icon="mdi-clock"
                    id="menu-activator"
                    class="cursor-pointer"
                  ></v-icon>
                </v-text-field>
                <!-- <div
                            v-if="endTimeError"
                            class="text-red error-message tw-text-xs"
                          >
                            {{ endTimeError }}
                          </div> -->
                <ul class="custom_dropdown__menu" v-if="toDropdownOpen">
                  <li
                    class="custom_dropdown__item"
                    v-for="(time, index) in filteredToTimes"
                    :key="index"
                    @click="toggleDropdownOutside"
                  >
                    <label @click="siteVisitModel.endTime = time" for="">
                      {{ time }}</label
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-start">
            <Text variant="span" textWeight="400">Message (optional):</Text>
            <v-textarea
              class="input_field !tw-w-full"
              placeholder="Write message.."
              v-model="siteVisitModel.message"
              variant="outlined"
            ></v-textarea>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-4 tw-items-center">
        <!-- <Button variant="tertiary" label="close" @click="onCloseModal" /> -->
        <Button
          class="!tw-w-auto"
          label="Submit Request"
          @click="onRequestASiteVisit"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import moment from "moment";
import { computed, onBeforeMount, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import InputField from "@/core/components/common/InputField.vue";
import { DatePicker } from "v-calendar";
import InputSelect from "@/core/components/common/InputSelect.vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";

const store = useStore();

const emits = defineEmits(["on-close", "on-back", "on-submit-success"]);

// Regex for validating 12-hour time format
const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/i;

const siteVisitModel = ref({
  property: "",
  startDate: null,
  startTime: null,
  endTime: null,
  message: "",
});

const selectedProperty = ref(null);
const siteVisitRequests = ref([]);
const siteId = ref(null);

const times = ref([]);
const dataRange = ref(moment());
const startTimeError = ref("");
const endTimeError = ref("");
const isLoadingRequest = ref(false);

const validation = ref({
  startDate: [
    (v) => !!v || "Start date is required.",
    (v) =>
      isDateAfterCurrentDate(v) ||
      "Date must be  greater than or equal to today's date",
  ],
  startTime: [
    (v) => !!v || "Start time is required.",
    (v) => timeRegex.test(v) || "Invalid time format. Please use HH:MM am/pm.",
    (v) =>
      isTimeAfterCurrentTime(v) || "Time must Be greater than current time.",
  ],
  endTime: [
    (v) => !!v || "End time is required.",
    (v) => timeRegex.test(v) || "Invalid time format. Please use HH:MM am/pm.",
    (v) => validateEndTime(v) || "End time must be later than start time",
  ],
});

const fromDropdownOpen = ref(false);
const toDropdownOpen = ref(false);
const getCalenderEventById = ref({});

const populateTimes = () => {
  times.value = [];
  const currentTime = moment();

  const date = new Date("12/12/2000 12:00:00 AM");

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j = j + 15) {
      date.setHours(i);
      date.setMinutes(j);

      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const timeMoment = moment(formattedTime, "hh:mm A");

      // Check if the current time is before the generated time
      if (currentTime.isSame(moment(dataRange.value), "day")) {
        if (timeMoment.isAfter(currentTime)) {
          times.value.push(formattedTime);
        }
      } else {
        times.value.push(formattedTime);
      }
    }
  }
  times.value.push("11:59 PM");
  filteredFromTimes.value = [...times.value];
  filteredToTimes.value = [...times.value];
};

// populateTimes();

const filteredFromTimes = ref([...times.value]);
const filteredToTimes = ref([...times.value]);

const estimateformData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const propertyList = computed(() => {
  return currentJobDetails.value.projectProperties.map(
    (property) => property.property
  );
});

// Toggle the from time dropdown
const fromToggleDropdown = () => {
  siteVisitModel.value.startTime = null;
  siteVisitModel.value.endTime = null;
  fromDropdownOpen.value = !fromDropdownOpen.value;
};

// Toggle the to time dropdown
const toToggleDropdown = () => {
  siteVisitModel.value.endTime = null;
  if (
    siteVisitModel.value.startTime != null ||
    getCalenderEventById.value.startTime != null
  ) {
    toDropdownOpen.value = !toDropdownOpen.value;
  }
  // toDropdownOpen.value = !toDropdownOpen.value;
};

// Close the both time dropdowns (start and end)
const toggleDropdownOutside = () => {
  if (fromDropdownOpen.value) {
    fromDropdownOpen.value = false;
  }
  if (toDropdownOpen.value) {
    toDropdownOpen.value = false;
  }
};

const isDateAfterCurrentDate = (givenDate) => {
  const today = moment().startOf("day");
  const givenMoment = moment(givenDate, "YYYY-MM-DD");
  return givenMoment.isSameOrAfter(today);
};

const isTimeAfterCurrentTime = (givenTime) => {
  const now = moment();
  if (now.isSame(moment(dataRange.value), "day")) {
    const givenMoment = moment(givenTime, "hh:mm A");
    return givenMoment.isAfter(now);
  } else {
    return true;
  }
};
const validateEndTime = (endTime) => {
  const start = moment(siteVisitModel.value.startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A");
  return end.isAfter(start) && start.isSame(end, "day");
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

// Common callback function for watching times
const watchTimesCallback = (newValue) => {
  const currentTime = moment();
  const timeToDate = (time) => {
    return moment(time, "HH:mm A");
  };
  fromDropdownOpen.value = false;
  toDropdownOpen.value = false;
  // Validate and filter startTime
  if (newValue.startTime && typeof newValue.startTime === "string") {
    const startTimeMoment = timeToDate(newValue.startTime);

    if (!timeRegex.test(newValue.startTime)) {
      startTimeError.value = "Invalid time format. Please use HH:MM am/pm";
    } else if (startTimeMoment.isSameOrBefore(currentTime)) {
      startTimeError.value = "Start time must be greater than current time.";
    } else {
      startTimeError.value = "";
      filteredFromTimes.value = times.value.filter((time) =>
        time.toLowerCase().includes(newValue.startTime.toLowerCase())
      );
    }
  } else {
    startTimeError.value = "";
    filteredFromTimes.value = [...times.value];
  }

  // Validate and filter endTime
  if (newValue.endTime && typeof newValue.endTime === "string") {
    const endTimeMoment = timeToDate(newValue.endTime);

    if (!timeRegex.test(newValue.endTime)) {
      endTimeError.value = "Invalid time format. Please use HH:MM am/pm";
    } else if (
      newValue.startTime &&
      typeof newValue.startTime === "string" &&
      timeRegex.test(newValue.startTime)
    ) {
      const startTimeMoment = timeToDate(newValue.startTime);

      if (endTimeMoment.isSameOrBefore(startTimeMoment)) {
        endTimeError.value = "End time must be later than start time";
      } else {
        endTimeError.value = "";
        filteredToTimes.value = times.value.filter((time) =>
          time.toLowerCase().includes(newValue.endTime.toLowerCase())
        );
      }
    } else {
      endTimeError.value = "";
      filteredToTimes.value = times.value.filter((time) =>
        time.toLowerCase().includes(newValue.endTime.toLowerCase())
      );
    }
  } else {
    endTimeError.value = "";
    filteredToTimes.value = [...times.value];
  }
};

const selectSiteProperty = (value) => {
  const isExistRequest = siteVisitRequests.value.find(
    (request) => request.property?.id === value
  );

  if (isExistRequest) {
    siteId.value = isExistRequest?.id;
    siteVisitModel.value.startDate = isExistRequest?.date;
    // selectedTime.value = JSON.parse(isExistRequest?.startTime);
    siteVisitModel.value.message = isExistRequest?.description;
  } else {
    siteVisitModel.value.startDate = "";
    siteVisitModel.value.startTime = "";
    siteVisitModel.value.endTime = "";
    siteVisitModel.value.message = "";
    siteId.value = null;
  }
};

const formatDate = (dateString, format) => {
  const date = new Date(dateString);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  switch (format) {
    case "fullDayDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      const dayOfWeek = date.getUTCDay();
      return `${day} ${monthsOfYear[month]} ${year}, ${daysOfWeek[dayOfWeek]}`;
    }
    case "fullDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${day} ${monthsOfYear[month]}, ${year}`;
    }
    case "day-date-month": {
      const dayOfWeek = date.getUTCDay();
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      return `${daysOfWeek[dayOfWeek]} , ${day} ${month}`;
    }
    case "date": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    }
    case "yyyy-mm-dd": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    case "yyyy-mm-dd+1": {
      const day = date.getUTCDate() + 1;
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    case "month": {
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${monthsOfYear[month]} ${year}`;
    }
    case "month-date": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const nextDay = day + 1;
      const monthName = monthsOfYear[month];
      return `${monthName} ${day} - ${monthName} ${nextDay}`;
    }
    default: {
      throw new Error("Invalid format type");
    }
  }
};

// Watch both siteVisitModel and getCalenderEventById
watch(siteVisitModel, watchTimesCallback, { deep: true });
watch(getCalenderEventById, watchTimesCallback, { deep: true });
watch(
  () => dataRange.value,
  (newVal) => {
    populateTimes();
  },
  { immediate: true }
);
const onCloseModal = () => {
  emits("on-close");
};

const onBack = () => {
  emits("on-back");
};

const parseTime = (timeString) => {
  const [time, modifier] = timeString.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  // Convert to 24-hour format
  if (modifier === "PM" && hours < 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  return { hours, minutes, seconds: 0 };
};

const onRequestASiteVisit = async () => {
  isLoadingRequest.value = true;
  const date = formatDate(dataRange.value, "yyyy-mm-dd");
  const startObj = parseTime(siteVisitModel.value.startTime);
  const endObj = parseTime(siteVisitModel.value.endTime);

  const timeRange = JSON.stringify([startObj, endObj]);
  const formData = {
    id: siteId.value ? parseInt(siteId.value) : null,
    property: siteVisitModel.value.property,
    date,
    startTime: timeRange,
    description: siteVisitModel.value.message,
  };
  console.log(estimateformData.value, "estimateformData.value");
  if (currentJobDetails.value.id && estimateformData.value.id) {
    try {
      const response = await store.dispatch(
        `${JOB_TEMPLATE_STORE}/setSiteRequest`,
        {
          projectEstimateId: estimateformData.value.id,
          projectId: currentJobDetails.value.id,
          formData,
        }
      );
      console.log("response site visit", response);
    } catch (error) {
      console.log();
    } finally {
      isLoadingRequest.value = false;
      emits("on-submit-success");
    }
  }
};

onMounted(async () => {
  siteVisitRequests.value = await store.dispatch(
    `${JOB_TEMPLATE_STORE}/getSiteRequestAll`,
    {
      projectId: currentJobDetails.value.id,
      wsID: activeUserWorkstation?.value?.id,
    }
  );
});

onBeforeMount(async () => {
  console.log(filteredFromTimes.value, "filteredFromTimes");
  console.log(times.value, "times");
});
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/DashboardCalenderComponent.scss";

.is-open {
  .custom-dropdown__trigger {
    .mdi {
      transform: scaleY(-1);
    }
  }

  .custom-dropdown__menu {
    display: block;
  }
}

.time_selector {
  width: 100%;
  max-width: 100%;
  .time_wrapper {
    display: flex;
    gap: 4px;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    .time_dropdown {
      width: 50%;
      max-width: 100%;
      position: relative;
      .v-input {
        margin: 0;
        .v-input__control {
          .v-field {
            .v-field__field {
              .v-label {
                display: none;
              }
              .v-field__input {
                padding: 12px 10px;
                min-height: auto;
                flex-direction: row-reverse;
                border-radius: 8px;
                color: rgba($blueDark, 1);
                opacity: 1;
                img {
                  width: 16px;
                }
                .v-icon {
                  @include fluidFont(16, 16, 1);
                  color: rgba($buttonText, 1);
                }
                input {
                  padding: 0;
                  background-color: transparent;
                  border: none;
                  min-height: auto;
                }
                &::placeholder {
                  @include fluidFont(12, 12, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  opacity: 1;
                }
              }
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
      .custom_dropdown__menu {
        position: absolute;
        background-color: rgba($white, 1);
        width: 100%;
        border-radius: 6px;
        padding: 12px;
        box-shadow: 0px 0px 8px 0px #0c0f4a14;
        max-height: 190px;
        overflow: auto;
        top: 50px;
        z-index: 1;
        .custom_dropdown__item {
          cursor: pointer;
        }
      }
    }
    .time__divider {
      margin-top: 10px;
    }
    &_item {
      position: relative;
      &_placeholder {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @include fluidFont(12, 12, 1);
        color: rgba(12, 15, 74, 0.5);
        opacity: 1;
        padding: 12px 10px;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 4px;
        max-width: 100%;
      }
    }

    .select_field {
      margin: 0;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 12px 10px;
      height: 42px;
      @include fluidFont(12, 12, 1);

      @include respond(s720) {
        width: 100%;
      }

      &::placeholder {
        color: red;
      }
    }
  }
}

.input_field {
  label {
    @include fluidFont(12, 12, 2);
    font-weight: 600;
    color: rgba($blueDark, 1);
    letter-spacing: 0.15px;
  }
  .v-input {
    margin: 0;
    .v-input__control {
      .v-field {
        border: 1px solid rgba($blueDark, 0.2);
        border-radius: 8px;
        .v-field__field {
          .v-field__input {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
}

.custom-outer-grid {
  height: 4.16667%;
  width: auto !important;

  .custom-inner-grid {
    position: relative;
    height: 100%;
    top: 13.5417%;
    left: 0%;
    background-color: #fff7ea;
    color: #000;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 4px solid #ffa71a;
    width: auto;
    padding: 4px;
    position: absolute;
    border-radius: 4px;
    font-size: 0.75rem;

    .custom-event-title {
      font-weight: 600;
      top: 13.5417%;
      height: 9.375%;
      left: 0%;
      width: 100%;
      background-color: #fff7ea;
      color: #000;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 0px;
    }

    .custom-event-time {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-top: 0.75rem;
    }
  }
}
.custom-modal {
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
</style>
