<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9987 1.9835C11.9903 1.8934 11.9405 1.81353 11.8651 1.76932L8.91049 0.0350993C8.80212 -0.028742 8.66447 -0.00359237 8.58247 0.0950714L0.0644682 10.3923C-0.00435295 10.4755 -0.0193827 10.5932 0.025707 10.6924C0.0707967 10.7914 0.162558 10.8563 0.272514 10.8494L5.52164 10.6665L2.50353 15.4261L1.83879 14.1401C1.78632 14.039 1.67952 13.9815 1.57036 13.9981C1.46119 14.0141 1.37312 14.0992 1.34939 14.2117L0.192354 19.6637C0.170469 19.7665 0.206593 19.8737 0.28517 19.9395C0.332897 19.9795 0.390907 20 0.449708 20C0.488206 20 0.526704 19.9914 0.562564 19.9735L5.36528 17.5926C5.46416 17.5434 5.52401 17.4356 5.51531 17.3214C5.50688 17.207 5.43199 17.11 5.32704 17.0777L3.98121 16.662L10.9561 8.35491C11.0247 8.27339 11.041 8.15759 10.9983 8.05837C10.9556 7.95943 10.8615 7.89586 10.7581 7.89586H5.55592L11.9072 2.2209C11.9729 2.16231 12.0072 2.0736 11.9987 1.9835Z"
      :fill="fill"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#9DCB3C",
  },
});
</script>

<script>
export default {
  name: "ElectricalIcon",
};
</script>
