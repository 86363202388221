import { ChatMessages } from "@/core/models/chat-messages";

export default {
  getUserWorkStationMessages: (state: ChatMessages) =>
    state.userWorkstationMessages,

  getChatMessagesRooms: (state: ChatMessages) => state.chatMessagesRooms,
  getChatBoxRooms: (state: ChatMessages) => state.chatBoxRooms,
  filterMessagesRooms: (state: ChatMessages) => state.filterMessagesRooms,
  getNetworkDirectMessage: (state: ChatMessages) => state.NetworkDirectMessage,
  getCurrentMessageType: (state: ChatMessages) => state.currentMessageType,
  getProjectChatRequests: (state: ChatMessages) => state.projectChatRequests,
  getKrystalConversation: (state: ChatMessages) => state.krystalConversation,
  getKrystalConversationLastAnswer(state: ChatMessages) {
    if (state.krystalConversation.length) {
      const index =
        state.krystalConversation[state.krystalConversation.length - 1]?.answers
          ?.length;
      return {
        text: state.krystalConversation[
          state.krystalConversation.length - 1
        ]?.answers[index - 1]?.text
          ?.slice(0, 30)
          ?.replaceAll("<br/>", ""),
        date: state.krystalConversation[state.krystalConversation.length - 1]
          ?.dataCreated,
      };
    } else {
      return {
        text: "Hi there! How can I assist you",
        date: new Date(),
      };
    }
  },
};
