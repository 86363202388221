import { PreferredContractorsState } from "@/core/models/preferred-contractors";

export default {
  getPreferredContractorsConnection: (state: PreferredContractorsState) =>
    state.contractorsConnectionList,
  getPreferredContractorsCategory: (state: PreferredContractorsState) =>
    state.contractorsCategoryList,
  getPreferredContractorsList: (state: PreferredContractorsState) =>
    state.preferredContractorsList,
};
