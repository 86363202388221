<template>
  <Card class="!tw-min-h-[unset] shadow-none">
    <template #content>
      <main
        class="estimate-form tw-flex tw-flex-col tw-justify-between tw-h-full tw-w-full"
      >
        <SandBoxLabourForm :phaseIndex="phaseIndex" v-if="isLabour" />
        <SandBoxMaterialForm v-else :phaseIndex="phaseIndex" />

        <SandBoxActionFooter
          @on-delete-phase="onDeletePhase"
          :hideDeleteAction="hideDeleteAction"
          :phaseIndex="phaseIndex"
          :isPhaseAssignedToMe="isPhaseAssignedToMe"
          @on-update-phase-Estimation="updatePhaseEstimation"
        />
      </main>
    </template>
  </Card>
</template>
<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import SandBoxLabourForm from "@/modules/jobs/components/estimates/form/SandBoxLabourForm.vue";
import SandBoxMaterialForm from "@/modules/jobs/components/estimates/form/SandBoxMaterialForm.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import SandBoxActionFooter from "./SandBoxActionFooter.vue";

const store = useStore();

const emits = defineEmits(["on-delete-phase", "on-update-phase-Estimation"]);

const props = defineProps({
  phaseBgColor: {
    type: String,
    default: "rgba(74, 146, 229, 0.16)",
  },
  phaseTitleColor: {
    type: String,
    default: "#4A92E5",
  },
  phaseIndex: Number,
  formProp: Object,
  isPhaseAssignedToMe: {
    type: Boolean,
  },
});

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isLabour = computed(() => {
  return props.formProp?.stageType === "labour";
});

const hideDeleteAction = computed(
  () =>
    estimateForm.value.projectStages.length === 1 || props.isPhaseAssignedToMe
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const onDeletePhase = async () => {
  try {
    const estimate = estimateForm.value.projectStages.find(
      (data, index) => index === props.phaseIndex
    );
    estimateForm.value.projectStages = estimateForm.value.projectStages.filter(
      (x, i) => i !== props.phaseIndex
    );
    await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhase`, {
      estimateId: estimate.id,
    });
    updatePhaseEstimation();
  } catch (error) {
    console.log();
  }
};

const updatePhaseEstimation = () => {
  emits("on-update-Estimation", props.phaseIndex);
};
</script>
<script>
export default {
  name: "sandBoxEstimateCard",
};
</script>
<style lang="scss" scoped>
.estimate-form {
  &__wrapper.estimate-header {
    background-color: v-bind("props.phaseBgColor");
  }
  &__phase-item {
    border-bottom: 2px solid #5db9e7;
  }
}
</style>
