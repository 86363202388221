// import {
//   ProjectQuote, ProjectQuoteStatus, ProjectQuoteStatusEnum, ProjectStage
// } from '@/core/packages/shared-library';

const ProjectQuoteStatusEnum = {
  CANCELLED: "-1",
  DRAFT_QUOTE: "0",
  QUOTE_SUBMITTED: "1",
  QUOTE_ACCEPTED: "2",
  PROJECT_REJECTED: "3",
  QUOTE_REJECTED: "4",
  QUOTE_CONFIRMED: "5",
  IN_PROGRESS: "6",
  COMPLETED: "7",
  QUOTE_DELETED: "8",
};

export default {
  defaultProjectQuoteState: (state: any) => {
    return state.defaultProjectQuoteState;
  },

  defaultStageState: (state: { defaultStageState: any }) => {
    return state.defaultStageState;
  },

  defaultTaskState: (state: { defaultTaskState: any }) => {
    return state.defaultTaskState;
  },

  getTotalProjectStageTasks: () => {
    return (quote: any) => {
      let total = 0 as number;

      if (quote.projectStages.length) {
        quote.projectStages.forEach((stage: any) => {
          total += parseFloat(stage.projectStageTasks.length);
        });
      }

      return total;
    };
  },

  getTotalProjectStageDurationDays:
    (
      store: any,
      getters: { getProjectStageDurationDays: (arg0: any) => string }
    ) =>
    (quote: any) => {
      let total = 0 as number;

      if (quote.projectStages.length) {
        quote.projectStages.forEach(
          (stage: { projectStageTasks: string | any[] }) => {
            if (stage?.projectStageTasks?.length) {
              total += parseFloat(
                getters.getProjectStageDurationDays(stage.projectStageTasks)
              );
            }
          }
        );
      }

      return total;
    },

  getProjectStageDurationDays: () => (tasks: any[]) => {
    let total = 0 as number;

    if (tasks.length) {
      tasks.forEach((task) => {
        total +=
          task?.duration && task.duration > 0 ? parseFloat(task.duration) : 0;
      });
    }

    return total.toFixed(1);
  },

  getAverageQuoteValue: () => {
    return (quotes: any) => {
      let value = 0;

      if (quotes.length) {
        quotes.forEach((quote: any) => {
          if (quote?.projectStages?.length) {
            quote.projectStages.forEach((projectStage: any) => {
              value += projectStage.stageCost;
            });
          }
        });
      }

      return value / quotes.length;
    };
  },

  getQuoteStatusLabel: () => {
    return (projectQuote: { projectQuoteStatus: any }) => {
      const projectQuoteStatus =
        projectQuote?.projectQuoteStatus?.id.toString();

      const status = {
        name: projectQuote?.projectQuoteStatus?.display || "",
        type: "warning",
      };

      switch (projectQuoteStatus) {
        case ProjectQuoteStatusEnum.DRAFT_QUOTE:
          status.type = "warning";
          break;

        case ProjectQuoteStatusEnum.QUOTE_SUBMITTED:
          status.type = "primary";
          break;

        case ProjectQuoteStatusEnum.QUOTE_ACCEPTED:
          status.type = "success";
          break;

        case ProjectQuoteStatusEnum.IN_PROGRESS:
          status.type = "primary";
          break;

        case ProjectQuoteStatusEnum.COMPLETED:
          status.type = "success";
          break;

        case ProjectQuoteStatusEnum.QUOTE_REJECTED:
        case ProjectQuoteStatusEnum.PROJECT_REJECTED:
        case ProjectQuoteStatusEnum.CANCELLED:
          status.type = "danger";
          break;

        default:
      }

      return status;
    };
  },

  getProjectQuoteSummary: () => {
    return (param: { columns: any; data: any }) => {
      const { columns, data } = param;
      const sums: string[] = [];

      columns.forEach(
        (column: { property: string | number }, index: number) => {
          if (index === 0) {
            sums[index] = "Your total earnings";
            return;
          }

          const values = data.map((item: { [x: string]: any }) =>
            Number(item[column.property])
          );

          if (!values.every((value: unknown) => Number.isNaN(value))) {
            sums[index] = `£${values
              .reduce((prev: any, curr: any) => {
                const value = Number(curr);

                if (!Number.isNaN(value)) {
                  return prev + curr;
                }

                return prev;
              }, 0)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}`;
          } else {
            sums[index] = "N/A";
          }
        }
      );

      return sums;
    };
  },

  totalFee: (state: { totalFee: any }) => {
    return state.totalFee;
  },

  type: (state: { type: any }) => {
    return state.type;
  },
};
