<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12.7266" r="12" fill="#4887FF" />
    <path
      d="M10.5075 5.37662C10.4866 5.16805 10.5096 4.95741 10.5751 4.75828C10.6405 4.55915 10.747 4.37595 10.8876 4.22051C11.0283 4.06506 11.1999 3.94081 11.3915 3.85578C11.5831 3.77074 11.7904 3.72681 12 3.72681C12.2096 3.72681 12.4169 3.77074 12.6085 3.85578C12.8001 3.94081 12.9717 4.06506 13.1124 4.22051C13.253 4.37595 13.3595 4.55915 13.4249 4.75828C13.4904 4.95741 13.5134 5.16805 13.4925 5.37662L12.9105 14.9016C12.888 15.1275 12.7824 15.3369 12.6141 15.4893C12.4459 15.6416 12.227 15.726 12 15.726C11.773 15.726 11.5541 15.6416 11.3859 15.4893C11.2176 15.3369 11.112 15.1275 11.0895 14.9016L10.5075 5.37662ZM10.5 18.7266C10.5 18.3288 10.658 17.9473 10.9393 17.666C11.2206 17.3847 11.6022 17.2266 12 17.2266C12.3978 17.2266 12.7794 17.3847 13.0607 17.666C13.342 17.9473 13.5 18.3288 13.5 18.7266C13.5 19.1244 13.342 19.506 13.0607 19.7873C12.7794 20.0686 12.3978 20.2266 12 20.2266C11.6022 20.2266 11.2206 20.0686 10.9393 19.7873C10.658 19.506 10.5 19.1244 10.5 18.7266Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "InfoBlueIcon",
};
</script>
