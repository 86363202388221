export default {
  accumulatedSize: (state: { accumulatedSize: any }) => state.accumulatedSize,

  getListingAmount: (state: { listingAmount: any }) => state.listingAmount,
  getHasPropertyListing: (state: { hasPropertyListing: any }) =>
    state.hasPropertyListing,
  getTotalMember: (state: { totalMember: any }) => state.totalMember,
  getAddedMember: (state: { addedMember: any }) => state.addedMember,
  getPerMemberPrice: (state: { perMemberPrice: any }) => state.perMemberPrice,
  getListingPrice: (state: { listingPrice: any }) => state.listingPrice,
  getPaymentMethod: (state: { paymentMethod: any }) => state.paymentMethod,
  getStoragePlan: (state: { storagePlan: any }) => state.storagePlan,
  getDefaultTrialDays: (state: { defaultTrialDays: any }) =>
    state.defaultTrialDays,
  getDefaultPropertyBillingAmount: (state: {
    defaultPropertyBillingAmount: any;
  }) => state.defaultPropertyBillingAmount,
  //   templates: (state) => {
  //     const templates = {};

  //     if (state.certificates && state.certificates.length) {
  //       state.certificates.forEach((certificate) => {
  //         templates[certificate.id] = certificate.template;
  //       });
  //     }

  //     return templates;
  //   }
};
