<template>
  <CollapsibleWidgetSlot title="Connections">
    <template #content>
      <section
        class="tw-flex tw-flex-col tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <div
          class="tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-between"
        >
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[412px]"
            placeholder="Search"
            v-model="filters.keywords"
          />
          <Button
            class="!tw-w-[auto]"
            label="Manage"
            v-if="isUserHasEditRight"
            @click="onOpenManageConnectionModal"
          >
            <template #prefix>
              <SettingsIconWhite />
            </template>
          </Button>
        </div>
        <div
          v-if="propertyConnections && propertyConnections.length"
          class="connection-grid tw-gap-4 tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-grid tw-p-4 tw-box-border tw-rounded-lg"
        >
          <div
            v-for="(connection, index) in propertyConnections"
            :key="index"
            class="tw-w-full tw-min-h-[80px] tw-max-h-[80px] tw-rounded-lg tw-overflow-hidden tw-p-4 tw-box-border tw-bg-white tw-flex tw-flex-col tw-justify-between"
          >
            <div class="tw-flex tw-items-center tw-gap-3">
              <ProfileThumbnail
                class="!tw-rounded-full !tw-overflow-hidden"
                v-if="connection?.userWorkstation?.profileImageUrl"
                :src="connection?.userWorkstation?.profileImageUrl"
              />
              <div
                v-else
                class="!tw-rounded-lg tw-overflow-hidden tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
              >
                <UserProfileLogo
                  class="ws-profile-logo"
                  :userName="connection?.userWorkstation?.name"
                />
              </div>
              <div class="tw-flex tw-flex-col tw-items-start">
                <Text variant="h5">{{
                  connection?.userWorkstation?.name
                }}</Text>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <Text variant="span" textColor="#80829F">{{
                    connection?.tags.join(", ")
                  }}</Text>
                </div>
              </div>
            </div>
            <!-- <div
              class="tw-cursor-pointer tw-rounded-[5px] tw-border-solid tw-border-[.83px] tw-border-[#FFA500] tw-min-w-[71px] tw-max-w-[71px] tw-min-h-[20px] tw-flex tw-items-center tw-justify-center tw-gap-1"
            >
              <SettingsYellowIcon />
              <Text variant="xsmall" textColor="#FFA500">Manage</Text>
            </div> -->
          </div>
        </div>
        <Text v-else>No Connections in this property</Text>

        <v-alert
          v-if="state.alert"
          class="tw-absolute tw-bottom-8 tw-right-0"
          transition="slide-y-transition"
          :title="state.alertConfig.title"
          :text="state.alertConfig.text"
          :type="state.alertType"
        >
        </v-alert>
      </section>
    </template>
  </CollapsibleWidgetSlot>

  <!-- MODALS -->
  <ManageConnectionModal
    v-if="manageConnectionModal"
    @on-close="onCloseManageConnectionModal"
  />
</template>
<script setup>
import { computed, reactive, ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputField from "@/core/components/common/InputField.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { CONNECTIONS } from "@/store/modules/connections";
import { USER_STORE } from "@/store/modules/user";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import SettingsYellowIcon from "@/core/components/icons/SettingsYellowIcon.vue";
import SettingsIconWhite from "@/core/components/icons/SettingsIconWhite.vue";
import ManageConnectionModal from "../Modals/ManageConnectionsModal.vue";
import {
  BusinessDetailTypeEnum,
  connectionMemberInvitationStatusEnum,
} from "@/core/enums/RolesEnum";

const store = useStore();
const route = useRoute();

const filters = reactive({
  keywords: "",
});

const state = reactive({
  alert: false,
  alertType: "",
  actionType: "",
  alertConfig: {
    text: "",
    title: "",
  },
});
// const assetRegisterModal = ref(false);
// const viewAssetRegisterModal = ref(false);
const loading = ref(false);
const connections = ref([]);
const manageConnectionModal = ref(false);

// const selectedAsset = ref(null);
// const isEditMode = ref(false);

const propertyId = computed(() => route?.params?.propertyId);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const propertyConnections = computed(
  () => store.getters[`${CONNECTIONS}/propertyConnections`]
);

// const onOpenAssetRegisterModal = () => {
//   assetRegisterModal.value = true;
// };
// const onCloseAssetRegisterModal = () => {
//   selectedAsset.value = null;
//   isEditMode.value = false;
//   assetRegisterModal.value = false;
// };

const onCloseManageConnectionModal = () => {
  manageConnectionModal.value = false;
};
const onOpenManageConnectionModal = () => {
  manageConnectionModal.value = true;
};

const hideAlert = (event) => {
  setTimeout(() => {
    state.alert = false;
  }, 3000);
};

const setAlert = (e) => {
  // e.type = error or success
  // e.message = alert message
  // e.title = alert title
  state.alertType = e?.type;
  state.alert = true;
  state.alertConfig = {
    text: e?.message,
    title: e?.title,
  };
};

// const onSuccess = (isAdd) => {
//   const alert = {
//     type: "success",
//     message: isAdd ? "Asset added successfully" : "Asset edited successfully",
//     title: "Success",
//   };
//   setAlert(alert);
//   initialize();
// };

// const onError = () => {
//   const alert = {
//     type: "error",
//     message: "Something went wrong. Please try again!",
//     title: "Error",
//   };
//   setAlert(alert);
// };
const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);

// watch(
//   () => state.alert,
//   (newValue, oldValue) => {
//     if (newValue) {
//       hideAlert();
//     }
//   },
//   { deep: true, immediate: true }
// );

// const initialize = async () => {
//   const payload = {
//     userId: user.value?.id,
//     propertyId: propertyId.value,
//   };
//   loading.value = true;
//   try {
//     const connectionRes = await store.dispatch(
//       `${CONNECTIONS}/getConnectionsOfProperty`,
//       payload
//     );

//     if (connectionRes) {
//       connections.value = connectionRes;
//       // console.log(connections.value, "connections response");
//     }
//   } catch (error) {
//     loading.value = false;
//   } finally {
//     loading.value = false;
//   }
// };

// onBeforeMount(async () => {
//   await initialize();
//   console.log(propertyConnections.value, "propertyConnections");
// });
</script>
<style lang="scss" scoped>
.connection-grid {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
@media (max-width: 500px) {
  .connection-grid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
</style>
