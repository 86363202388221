<template>
  <component :is="layout">
    <router-view :key="routeFullPath" />
  </component>
</template>

<script lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import AppLayoutDefault from "./core/components/layout/GeneralLayout.vue";
import AuthLayout from "./core/components/layout/AuthLayout.vue";

export default {
  setup() {
    const route = useRoute();
    const routeFullPath = computed(() => route.fullPath);
    const layout = computed(() => {
      // Check if the route has a meta property defining a specific layout
      if (route?.meta?.layout || !route?.name) {
        return AuthLayout;
      }
      // If not, use the default layout
      return AppLayoutDefault;
    });
    return { layout, routeFullPath };
  },
};
</script>

<style>
#app {
  font-size: 1rem;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
