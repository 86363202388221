<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['experience_modal']"
    width="480px"
    height="360px"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Edit Experience</h4>
          <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
        </div>

        <div class="form_wrapper">
          <label>Experience</label>
          <v-text-field
            variant="outlined"
            class="c-input"
            density="compact"
            placeholder="20"
            :min="0"
            suffix="Year(s)"
            v-model="userExperience"
            type="number"
          ></v-text-field>
        </div>

        <v-btn class="button button-orange" @click="onClickSaveButton"
          >SAVE</v-btn
        >
      </div>
    </template></CommonDialog
  >
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import UserService from "@/core/services/user.service";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const userExperience = ref(activeUserWorkstation?.value.experience || 0);
    const onClickCloseButton = () => {
      ctx.emit("closeUpdateUserExperienceModal");
    };
    const onClickSaveButton = async () => {
      await UserService.updateUserWorkstation(
        user.value?.id,
        activeUserWorkstation.value?.id,
        { experience: Number(userExperience.value) }
      );

      await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

      ctx.emit("closeUpdateUserExperienceModal");
    };
    return { onClickCloseButton, onClickSaveButton, userExperience };
  },
};
</script>
