import $axios from "../utils/axios-api-config";

export default {
  getAnalyticsData(payload: any) {
    return $axios.get(`/projects/team-calendar`);
  },

  fetchTradeAnalytics(params: any) {
    return $axios.get(`/trade-analytics`, { params });
  },

  fetchServiceRevenue(params: any) {
    return $axios.get(`/trade-analytics/service-revenue`, { params });
  },

  fetchRevenuePerformance(params: any) {
    return $axios.get(`/trade-analytics/revenue-performance`, { params });
  },

  fetchTeamPerformance(params: any) {
    return $axios.get(`/trade-analytics/team-performance`, { params });
  },
};
