// import {
//   ProjectStage, UpdateProjectQuoteRequestDto
// } from '@/core/packages/shared-library';
import ProjectQuoteService from "@/core/services/project-quotes.service";

export default {
  /**
   * Get project quote details with projectStages, projectTasks
   * @param context
   * @param projectId
   * @param projectQuoteId
   * @returns
   */
  getProjectQuoteDetails(
    context: any,
    { projectId, projectQuoteId }: { projectId: number; projectQuoteId: number }
  ) {
    return ProjectQuoteService.getProjectQuoteDetails(
      projectId,
      projectQuoteId
    );
  },

  /**
   * Get project quote page list by projectId
   * @param context
   * @param projectId
   * @returns
   */
  getProjectQuotesByProject(context: any, projectId: number) {
    return ProjectQuoteService.getProjectQuotesByProject(projectId);
  },

  /**
   *
   * @param context
   * @param projectStages
   * @returns
   */
  getTotalStageCost(context: any, projectStages: any) {
    if (projectStages?.length) {
      const stageAmounts = projectStages.map((stage: any) => {
        return stage.stageCost || stage.subTotal || 0;
      });

      const totalStageAmount = stageAmounts.reduce((acc: any, curr: any) => {
        return acc + curr;
      }, 0);

      return totalStageAmount > 0 ? parseFloat(totalStageAmount.toFixed(2)) : 0;
    }

    return 0;
  },

  /**
   *
   * @param context
   * @param projectStages
   * @returns
   */
  getTotalDuration(context: any, projectStages: any) {
    if (projectStages?.length) {
      const durations = projectStages.map((stage: any) => {
        return stage.totalDuration || 0;
      });

      const total = durations.reduce((acc: any, curr: any) => {
        return acc + curr;
      }, 0);

      return !Number.isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
    }

    return 0;
  },

  /**
   *
   * @param context
   * @param param
   * @returns
   */
  async calculateServiceFee(
    context: any,
    { projectId, projectStages }: { projectId: number; projectStages: any }
  ) {
    const totalStageAmount = projectStages
      ? await context.dispatch("getTotalStageCost", projectStages)
      : 0;

    const serviceFeePayload = {
      total: totalStageAmount,
      threshold_percentage: 0,
    };

    if (!Number.isNaN(totalStageAmount) || totalStageAmount > 0) {
      const serviceFee = await ProjectQuoteService.calculateServiceFee(
        projectId,
        serviceFeePayload
      );

      return serviceFee;
    }

    return 0;
  },

  /**
   *
   * @param context
   * @param payload
   * @returns
   */
  async getStageCostWithServiceFee(context: any, payload: any) {
    const stageCostPercentage = payload.stageCost / payload.totalStageCost;
    const stageServiceFee = payload.serviceFee * stageCostPercentage;
    const stageCostWithServiceFee = parseFloat(
      payload.stageCost + stageServiceFee
    );

    return !Number.isNaN(stageCostWithServiceFee) ? stageCostWithServiceFee : 0;
  },

  /**
   *
   * @param param
   * @param quote
   * @returns
   */
  async getCustomerPay(
    { dispatch }: any,
    { projectId, quote }: { projectId: number; quote: any }
  ) {
    const totalStageAmount = quote
      ? await dispatch("getTotalStageCost", quote.projectStages)
      : 0;
    const serviceFee = await dispatch("calculateServiceFee", {
      projectId,
      projectStages: quote.projectStages,
    });
    const totalStageAmoutWithServiceFee =
      parseFloat(parseFloat(totalStageAmount).toFixed(2)) +
      parseFloat(parseFloat(serviceFee).toFixed(2));
    const result = {
      total: !Number.isNaN(totalStageAmoutWithServiceFee)
        ? totalStageAmoutWithServiceFee
        : 0,
      serviceFee: !Number.isNaN(serviceFee) ? serviceFee : 0,
    };

    return result;
  },

  /**
   * Create new project quote
   * @param context
   * @param projectId
   * @param formData CreateProjectQuoteRequestDto
   * @returns
   */
  createProjectQuote(
    context: any,
    { projectId, formData }: { projectId: number; formData: any }
  ) {
    return ProjectQuoteService.createProjectQuote(projectId, formData);
  },

  /**
   * Update existing project quote details
   * @param context
   * @param projectId
   * @param projectQuoteId
   * @param formData UpdateProjectQuoteRequestDto
   * @returns
   */
  updateProjectQuote(
    context: any,
    {
      projectId,
      projectQuoteId,
      formData,
    }: {
      projectId: number;
      projectQuoteId: number;
      formData: any;
    }
  ) {
    const payload = {
      ...formData,
    };

    const deleteUnnecessaryUpdateFields = [
      "id",
      "dateCreated",
      "dateUpdated",
      "dateDeleted",
    ];

    deleteUnnecessaryUpdateFields.forEach((field) => {
      delete payload[field];
    });

    return ProjectQuoteService.updateProjectQuote(
      projectId,
      projectQuoteId,
      payload
    );
  },

  /**
   * Delete existing project quote details
   * @param context
   * @param projectId
   * @param projectQuoteId
   * @returns
   */
  deleteProjectQuote(
    context: any,
    { projectId, projectQuoteId }: { projectId: number; projectQuoteId: number }
  ) {
    return ProjectQuoteService.deleteProjectQuote(projectId, projectQuoteId);
  },

  /**
   * Create new project quote
   * @param context
   * @param projectId
   * @param projectQuoteId
   * @param formData
   * @returns
   */
  updateProjectQuoteStatus(
    context: any,
    {
      projectId,
      projectQuoteId,
      formData,
    }: { projectId: number; projectQuoteId: number; formData: any }
  ) {
    return ProjectQuoteService.updateProjectQuoteStatus(
      projectId,
      projectQuoteId,
      formData
    );
  },

  setTotalFee({ commit }: any, value: any) {
    commit("setTotalFee", value);
  },

  setType({ commit }: any, value: any) {
    commit("setType", value);
  },
};
