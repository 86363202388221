<template>
  <div class="tw-text-left rsm:tw-max-w-[342px] w-full tw-mx-auto">
    <div class="tw-text-[24px] tw-font-semibold tw-mb-10">
      <p>Let’s create a password to your account.</p>
    </div>

    <v-text-field
      class="c-input-border tw-mb-6"
      variant="outlined"
      density="compact"
      v-model="userForm.password"
      :rules="validation.password"
      label="Password"
      :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append-inner="() => (isShowPassword = !isShowPassword)"
      :type="isShowPassword ? 'text' : 'password'"
    >
    </v-text-field>

    <v-text-field
      class="c-input-border tw-mb-6"
      variant="outlined"
      density="compact"
      v-model="userForm.confirmPassword"
      :rules="validation.confirmPassword"
      label="Confirm New Password"
      :append-inner-icon="isShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append-inner="
        () => (isShowConfirmPassword = !isShowConfirmPassword)
      "
      :type="isShowConfirmPassword ? 'text' : 'password'"
    ></v-text-field>

    <div class="checkbox__wrapper">
      <v-checkbox
        class="checkbox__btn tw-mb-4"
        v-model="userForm.tcOptIn"
        :rules="[() => nameValidationSchema('Check', userForm.tcOptIn)]"
      >
        <template v-slot:label>
          <div class="tw-grid tw-grid-cols-[18px_1fr] tw-items-start tw-gap-2">
            <v-icon v-if="userForm.tcOptIn" color="#FFA500">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="#0C0F4A80"> mdi-circle-outline </v-icon>
            <p>
              I have read & agree to the

              <a
                href="https://iknowa.com/pdf/MFSL-Introduced-Client-Terms.pdf"
                class="tw-text-[#FFA500] tw-no-underline hover:tw-underline"
                target="_blank"
              >
                Terms of Service</a
              >
              and
              <a
                href="https://iknowa.com/pdf/iknowa%20Privacy%20and%20Cookie%20Policy%20(Revised%202024).docx.pdf"
                class="tw-text-[#FFA500] tw-no-underline hover:tw-underline"
                target="_blank"
                >Privacy Policy</a
              >
            </p>
          </div>
        </template>
      </v-checkbox>

      <v-checkbox
        class="checkbox__btn tw-mb-4"
        v-model="userForm.marketingOptIn"
      >
        <template v-slot:label>
          <div class="tw-grid tw-grid-cols-[18px_1fr] tw-items-start tw-gap-2">
            <v-icon v-if="userForm.marketingOptIn" color="#FFA500">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="#0C0F4A80"> mdi-circle-outline </v-icon>
            <p>Please don’t send me any marketing material</p>
          </div>
        </template>
      </v-checkbox>
    </div>

    <Button
      class="w-full tw-mt-6"
      label="verify email & continue"
      :isLoading="isLoading || loading"
      :disabled="!nextButtonDisable"
      @click="savePassword"
    ></Button>
  </div>
</template>

<script setup>
import { nameValidationSchema } from "@/core/validation/validation";
import { ref } from "vue";
import CryptoJS from "crypto-js";
import {
  AUTH_SESSION_EXPIRY,
  AUTH_USER,
  CRYPTO_SECRET_KEY,
} from "@/core/constants";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { computed } from "vue";
import { setLocalStorageWithExpiry } from "@/core/utils/common";
import Button from "@/core/components/ui/general/Button.vue";

const props = defineProps(["heading", "label", "rules", "loading"]);
const emits = defineEmits(["onNext"]);
const isShowPassword = ref(false);
const isLoading = ref(false);
const isShowConfirmPassword = ref(false);
const store = useStore();
const userForm = ref({
  password: "",
  confirmPassword: "",
  marketingOptIn: false,
  tcOptIn: false,
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const validation = ref({
  password: [
    (v) => !!v || "Password is required",
    (v) =>
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(
        v
      ) ||
      "Password must contain at least 8 characters including at least 1 letter, 1 number and 1 special character",
  ],
  confirmPassword: [
    (v) => !!v || "Confirm Password is required",
    (v) => v === userForm.value.password || "Confirm Password does not match",
  ],
});

const nextButtonDisable = computed(() => {
  return (
    userForm.value.password &&
    userForm.value.password === userForm.value.confirmPassword &&
    userForm.value.tcOptIn
  );
});

const savePassword = async () => {
  try {
    isLoading.value = true;
    const encryptPassword = CryptoJS.AES.encrypt(
      userForm.value.password ?? "",
      CRYPTO_SECRET_KEY ?? ""
    ).toString();

    await store.dispatch(`${AUTHENTICATION_STORE}/changePassword`, {
      email: user.value.email,
      password: encryptPassword,
      userId: user.value.id,
    });
    await setUser();
    await updateUserData();
    nextStep();
    console.log("after set user");
  } catch (error) {
    console.log("save password error", error);
  } finally {
    isLoading.value = false;
  }
};
const setUser = async () => {
  try {
    const encryptPassword = CryptoJS.AES.encrypt(
      userForm.value.password ?? "",
      CRYPTO_SECRET_KEY ?? ""
    ).toString();

    const loginUser = await store.dispatch(`${AUTHENTICATION_STORE}/login`, {
      username: user.value.email,
      password: encryptPassword,
    });

    const userResponse = await store.dispatch(
      `${USER_STORE}/getUser`,
      loginUser?.user?.id
    );

    await store.dispatch(`${USER_STORE}/saveUserState`, userResponse);
    setLocalStorageWithExpiry(
      {
        roleId: loginUser?.user?.role?.id,
        userId: loginUser?.user?.id,
        hasChosenRole: loginUser?.user?.hasChosenRole,
        hasAccountSetup: loginUser?.user?.hasAccountSetup,
        hasBusinessType: loginUser?.user?.hasBusinessType,
      },
      AUTH_USER,
      AUTH_SESSION_EXPIRY
    );
    await store.dispatch(`${USER_STORE}/selectRole`, {
      userId: user.value?.id,
      roleId: loginUser?.user?.role?.id,
      email: user.value?.email,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const updateUserData = async () => {
  try {
    await store.dispatch(`${USER_STORE}/saveUserProfile`, {
      userId: user.value?.id,
      profile: {
        profileImage: null,
      },
    });
    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: {
        tcOptIn: userForm.value.tcOptIn,
        marketingOptIn: userForm.value.marketingOptIn,
        hasAccountSetup: true,
      },
    });
  } catch (error) {
    console.log();
  }
};
const nextStep = () => {
  emits("onNext");
};
</script>

<style scoped lang="scss">
.v-btn {
  width: 100%;
}

:deep(.v-checkbox-btn) {
  min-height: 1px !important;

  .v-selection-control__wrapper {
    display: none;
  }

  label {
    width: 100%;
    font-size: 14px;
  }

  .v-icon {
    font-size: 18px;
  }
}

:deep(.v-text-field .v-field) {
  height: 42px;
  background-color: #fff;
  color: #0c0f4a;
  border-radius: 8px;
  font-weight: 500;
}

:deep(.v-text-field .v-field .v-icon) {
  font-size: 18px;
}

:deep(.v-text-field .v-field .v-field__outline) {
  --v-field-border-width: 2px;
  --v-field-border-opacity: 0.51;
}

:deep(
    .v-text-field .v-field--variant-outlined .v-label.v-field-label--floating
  ) {
  background: linear-gradient(360deg, #ffffff 0%, #e7f3ff 95%);
}
</style>
