<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6536_52792)">
      <path
        d="M3.61212 15.4435C3.22612 15.6415 2.78812 15.2945 2.86612 14.8515L3.69612 10.1215L0.173118 6.7655C-0.155882 6.4515 0.0151183 5.8775 0.456118 5.8155L5.35412 5.1195L7.53812 0.7925C7.73512 0.4025 8.26812 0.4025 8.46512 0.7925L10.6491 5.1195L15.5471 5.8155C15.9881 5.8775 16.1591 6.4515 15.8291 6.7655L12.3071 10.1215L13.1371 14.8515C13.2151 15.2945 12.7771 15.6415 12.3911 15.4435L8.00012 13.1875L3.61212 15.4435Z"
        fill="#FFA500"
      />
    </g>
    <defs>
      <clipPath id="clip0_6536_52792">
        <rect width="16" height="16" fill="#000" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "RatingIcon",
};
</script>
