<template>
  <div class="project_content md:!tw-pb-16">
    <v-tabs class="tab-list" v-model="selectedTab" align-tabs="center" stacked>
      <v-tab
        v-for="tabItem in projectTabList"
        :key="tabItem.name"
        :value="tabItem.name"
      >
        <img
          :src="require('@/assets/icons/' + tabItem?.icon + '.svg')"
          alt=""
        />

        {{ tabItem.label }}
      </v-tab>
    </v-tabs>
    <v-window v-model="selectedTab">
      <v-window-item
        v-for="tabValue in projectTabList"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <component
          v-if="selectedTab === tabValue.name"
          :is="tabValue.component"
        ></component>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import PostProjectComponent from "@/modules/project/components/PostProjectComponent.vue";
import DraftProjectsComponent from "@/modules/project/components/DraftProjectsComponent.vue";
import ActiveProjectsComponent from "@/modules/project/components/ActiveProjectsComponent.vue";
import CompleteProjectsComponent from "@/modules/project/components/CompleteProjectsComponent.vue";
import ProjectRequestedComponent from "@/modules/project/components/ProjectRequestedComponent.vue";
import HistoricProjectComponent from "@/modules/project/components/HistoricProjectComponent.vue";
import DraftHistoricProjectComponent from "@/modules/project/components/DraftHistoricProjectComponent.vue";

import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { WORKSTATION } from "@/store/modules/workstation";
import projectService from "@/core/services/project.service";

export default defineComponent({
  name: "ProjectOverviewPage",
  components: {
    PostProjectComponent,
    DraftProjectsComponent,
    CompleteProjectsComponent,
    HistoricProjectComponent,
    // ActiveProjectsComponent,
    ProjectRequestedComponent,
    DraftHistoricProjectComponent,
  },
  setup() {
    const selectedTab = ref();
    const store = useStore();
    const postProjectComponent = ref();
    const getProjectRedirectTabName = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectRedirectTabName`]
    );
    const dynamicLabelForFirstTab = ref("New Projects");
    const projectTabList = computed(() => [
      // { name: "new-project", icon: "mdi-home", label: "New Project" },
      {
        name: "active-project",
        icon: "active-projects",
        label: dynamicLabelForFirstTab.value,
        component: PostProjectComponent,
      },
      {
        name: "draft-project",
        icon: "draft-projects",
        label: "Draft Projects",
        component: DraftProjectsComponent,
      },
      {
        name: "complete-projects",
        icon: "complete-projects",
        label: "Complete Projects",
        component: CompleteProjectsComponent,
      },
      {
        name: "historic-projects-request",
        icon: "user-tie",
        label: "Historic Projects Request",
        component: HistoricProjectComponent,
      },
      {
        name: "requested-projects",
        icon: "complete-projects",
        label: "Project Requested",
        component: ProjectRequestedComponent,
      },
      {
        name: "draft-historic-projects",
        icon: "complete-projects",
        label: "Historic Draft Project",
        component: DraftHistoricProjectComponent,
      },
    ]);

    watch(
      getProjectRedirectTabName,
      (newValue, oldValue) => {
        selectedTab.value = newValue;
      },
      { immediate: true }
    );
    onBeforeMount(async () => {
      try {
        dynamicLabelForFirstTab.value =
          (await projectService.getAllProjects()).data.length > 0
            ? "Active Projects"
            : "New Projects";
      } catch (error) {
        console.log();
      }
    });
    return {
      selectedTab,
      projectTabList,
      dynamicLabelForFirstTab,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/project.scss";

.courses_content {
  .v-slide-group {
    height: unset;
  }
}
</style>
