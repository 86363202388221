<template>
  <Card
    class="!tw-h-[204px] !tw-min-h-[204px] rmd:!tw-h-[198px] rmd:!tw-min-h-[198px] tw-overflow-hidden"
  >
    <template #content>
      <section class="tw-w-full tw-h-full tw-flex tw-flex-col">
        <div
          class="tw-h-[160px] rmd:tw-h-[133px] tw-w-full tw-relative tw-p-4 tw-box-border tw-flex tw-flex-col tw-justify-between tw-items-start tw-overflow-hidden"
        >
          <div
            class="tw-w-full tw-h-full tw-absolute tw-z-0 tw-left-0 tw-top-0 tw-overflow-hidden"
          >
            <LeafletMap
              v-if="!propertyDetails?.localData?.propertyImage"
              :defaultViewZoom="17"
              :isDraggable="false"
              :showControls="false"
              :data="[]"
              :latlang="getLatLng(propertyDetails?.localData?.addressData)"
            />

            <img
              v-else
              class="tw-w-full tw-h-full"
              :src="propertyDetails?.localData?.publicUrl"
            />
          </div>
          <div
            class="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-full"
          >
            <ChipState
              class="tw-relative tw-z-10"
              bgColor="#0C0F4A"
              :label="propertyDetails?.localData?.postcode"
              textColor="#FFF"
            >
              <template #prefix>
                <v-icon
                  class="!tw-text-[14px]"
                  icon="mdi-map-marker"
                  color="#FFF"
                ></v-icon>
              </template>
            </ChipState>
            <span
              @click="toggleUpdateImages"
              class="tw-cursor-pointer tw-px-[10px] tw-py-[4px] tw-rounded-md tw-bg-white tw-w-[130px] tw-flex tw-items-center tw-text-xs tw-gap-1 tw-font-normal tw-z-10 tw-text-[#0C0F4A80] tw-leading-[13.99px]"
            >
              <div
                v-if="!propertyDetails?.localData?.propertyImage"
                class="tw-w-full tw-flex tw-items-center tw-gap-2"
              >
                <div
                  class="tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-[#FFA500] tw-flex tw-items-center tw-justify-center"
                >
                  <img
                    src="../../../../assets/icons/upload-icon.svg"
                    class="edit_icon tw-w-[8px] tw-h-[8px]"
                    style="
                      filter: invert(93%) sepia(93%) saturate(0%)
                        hue-rotate(265deg) brightness(105%) contrast(108%);
                    "
                  />
                </div>
                <span
                  class="tw-inline-block tw-w-[calc(100%-30px)] tw-text-left tw-whitespace-nowrap"
                >
                  Upload Image
                </span>
              </div>
              <div v-else class="tw-w-full tw-flex tw-items-center tw-gap-2">
                <div
                  class="tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-[#4A92E5] tw-flex tw-items-center tw-justify-center"
                >
                  <img
                    src="../../../../assets/icons/edit-pen-icon.svg"
                    class="edit_icon tw-w-[8px] tw-h-[8px]"
                    style="
                      filter: invert(93%) sepia(93%) saturate(0%)
                        hue-rotate(265deg) brightness(105%) contrast(108%);
                    "
                  />
                </div>
                <span
                  class="tw-inline-block tw-w-[calc(100%-30px)] tw-text-left tw-whitespace-nowrap"
                >
                  Edit Image
                </span>
              </div>
            </span>
          </div>
          <ChipState
            class="tw-relative tw-z-10"
            :hasCustomLabel="true"
            bgColor="#FFF"
            :hasShadow="true"
          >
            <!-- <template #prefix>
              <v-icon icon="mdi-shield-check" color="#FFA500"></v-icon>
            </template> -->
            <template #custom-label>
              <div
                v-if="
                  activeUserWorkstation?.subscription &&
                  activeUserWorkstation.value?.subStatus ===
                    UserWorkstationSubStatusEnum.SUCCEEDED
                "
                class="free_workstation_box tw-flex tw-items-center tw-gap-1 tw-px-2 tw-py-1 tw-rounded-lg"
              >
                <img
                  src="@/assets/icons/red-exclaimation.svg"
                  class="md:tw-min-w-[10px] md:tw-max-w-[10px] md:tw-min-h-[10px] md:tw-max-h-[10px]"
                  v-if="userWorkstationStatusByWord === 'UNVERIFIED'"
                />
                <v-icon
                  v-if="userWorkstationStatusByWord === 'VERIFIED'"
                  icon="mdi-shield-check"
                  color="#FFA500"
                  class="md:!tw-text-[10px]"
                ></v-icon>
                <span
                  class="tw-text-[10px] rmd:tw-text-xs tw-font-bold tw-leading-6 tw-tracking-wide"
                  :class="[
                    userWorkstationStatusByWord === 'UNVERIFIED'
                      ? 'tw-text-[#FA4B00]'
                      : 'tw-text-[#24BD63]',
                  ]"
                  >{{ userWorkstationStatusByWord }}</span
                >
                <Text
                  variant="xsmall"
                  class="rmd:!tw-text-[12px]"
                  whiteSpace="nowrap"
                  textColor="#FFA500"
                  >{{ propertyDetails?.localData?.propertyType }} PROPERTY
                  OWNER</Text
                >
              </div>
              <div
                v-if="
                  userWorkStationStatus ===
                    UserVerificationStatusEnum.NOT_ADDED &&
                  (!activeUserWorkstation?.subscription ||
                    activeUserWorkstation.value?.subStatus !==
                      UserWorkstationSubStatusEnum.SUCCEEDED)
                "
                class="tw-flex tw-items-center tw-gap-1"
              >
                <div
                  class="free_workstation_box tw-flex tw-items-center tw-gap-1 tw-px-2 tw-py-1 tw-rounded-lg"
                >
                  <img src="@/assets/icons/green-star-circle.svg" />

                  <span
                    class="tw-text-[10px] rmd:tw-text-xs tw-font-bold tw-leading-6 tw-text-[#24BD63] tw-tracking-wide"
                    >{{
                      propertyDetails?.localData?.userWorkstation.subscription
                        ? "PAID WORKSTATION"
                        : "FREE WORKSTATION"
                    }}</span
                  ><Text
                    variant="xsmall"
                    class="rmd:!tw-text-[12px]"
                    whiteSpace="nowrap"
                    textColor="#FFA500"
                  >
                    PROPERTY OWNER</Text
                  >
                </div>
              </div>

              <!-- <Text
                variant="span"
                whiteSpace="nowrap"
                v-if="
                  userWorkStationStatus ===
                    UserVerificationStatusEnum.NOT_ADDED &&
                  !activeUserWorkstation?.subscription
                "
              >
                {{ userWorkstationStatusByWord }}
                <Text variant="span" whiteSpace="nowrap" textColor="#FFA500"
                  >{{ propertyDetails?.localData?.propertyType }} PROPERTY
                  OWNER</Text
                >
              </Text> -->
            </template>
          </ChipState>
        </div>
        <div
          class="tw-flex tw-flex-col tw-justify-between tw-items-start tw-p-3 tw-box-border"
        >
          <Text
            variant="p"
            class="rmd:!tw-text-[18px] rmd:!tw-leading-6"
            textWeight="600"
            textAlign="left"
            lineHeight="18px"
            >{{ propertyDetails?.localData?.address }}</Text
          >
          <Text class="!tw-text-[11px]" textWeight="600"
            >Property ID: {{ propertyDetails?.localData?.id }}</Text
          >
        </div>

        <PropertyImageModal
          v-if="isUpdateImage"
          @on-close="toggleUpdateImages"
          :isGallery="false"
          :forDocuments="false"
          :isPropertyDocument="false"
        />
      </section>
    </template>
  </Card>
</template>
<script setup lang="ts">
import { LatLngExpression } from "leaflet";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import Text from "@/core/components/ui/general/Text.vue";
import LeafletMap from "@/core/components/map/LeafletMap.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import { parseJSON } from "@/core/utils/common";
import { UserWorkstationSubStatusEnum } from "@/core/enums";
import PropertyImageModal from "../Modals/PropertyImageModal.vue";

const store = useStore();
const isUpdateImage = ref(false);
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const getLatLng = (addressData: string): LatLngExpression | undefined => {
  if (!addressData) return undefined;

  try {
    const unParsedData = parseJSON(addressData);
    const latlng: LatLngExpression = [
      unParsedData.LAT ? unParsedData.LAT : unParsedData.lat,
      unParsedData.LNG ? unParsedData.LNG : unParsedData.lng,
    ];
    return latlng;
  } catch (error) {
    console.error("Invalid address data:", error);
    return undefined;
  }
};

const userWorkStationStatus = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.verificationStatus
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const userWorkstationStatusByWord = computed(() => {
  if (userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED) {
    return null;
  }
  if (userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED) {
    return "VERIFIED";
  }
  if (
    userWorkStationStatus.value ===
    UserVerificationStatusEnum.PENDING_VERIFICATION
  ) {
    return "UNVERIFIED";
  }
  return "";
});

const toggleUpdateImages = () => {
  isUpdateImage.value = !isUpdateImage.value;
};
</script>
<style lang="scss" scoped></style>
