<template>
  <span :class="[textClass, italicClass, xsMobileFontClass, capitalize]">
    <slot></slot>
  </span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  variant: {
    type: String,
    default: "p",
  },
  textWeight: {
    type: String,
    default: "bold",
  },
  textColor: {
    type: String,
    default: "#0C0F4A",
  },
  whiteSpace: {
    type: String,
    default: "normal",
  },
  lineHeight: {
    type: String,
    default: "24px",
  },
  isItalic: {
    type: Boolean,
    default: false,
  },
  xsMobileFont: {
    type: Number,
    default: 0,
  },
  textAlign: {
    type: String,
    default: "",
  },
  isCapitalize: {
    type: Boolean,
    default: false,
  },
});

const textClass = computed(() => `typography-${props.variant}`);
const italicClass = computed(() => (props.isItalic ? "italic" : ""));
const capitalize = computed(() => (props.isCapitalize ? "tw-capitalize" : ""));
const xsMobileFontClass = computed(() =>
  props.xsMobileFont !== 0 ? `xs-fs-${props.xsMobileFont}` : ""
);
</script>

<script>
// Explicitly export the component definition
export default {
  name: "Text",
};
</script>

<style lang="scss" scoped>
.typography-xsmall {
  font-size: 0.625rem; // 10px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-span {
  font-size: 0.75rem; // 12px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-p {
  font-size: 0.875rem; // 14px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-h1 {
  font-size: 2.5rem; // 40px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-h2 {
  font-size: 2rem; // 32px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-h3 {
  font-size: 1.75rem; // 28px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-h4 {
  font-size: 1.5rem; // 24px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-h5 {
  font-size: 1.125rem; // 18px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.typography-h6 {
  font-size: 1rem; // 16px
  font-weight: v-bind("props.textWeight");
  color: v-bind("props.textColor");
  white-space: v-bind("props.whiteSpace");
  line-height: v-bind("props.lineHeight");
  text-align: v-bind("props.textAlign");
}

.italic {
  font-style: italic;
}

.xs-fs-10 {
  font-size: 10px;
}

.xs-fs-12 {
  font-size: 12px;
}
.xs-fs-14 {
  font-size: 14px;
}
.xs-fs-18 {
  font-size: 18px;
}
.xs-fs-20 {
  font-size: 20px;
}
.xs-fs-22 {
  font-size: 22px;
}
.xs-fs-24 {
  font-size: 24px;
}
</style>
