<template>
  <svg
    width="54"
    height="44"
    viewBox="0 0 54 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="className"
  >
    <path
      d="M40.485 17.2841C40.485 16.7562 40.0546 16.334 39.5349 16.334H24.2687C23.7409 16.334 23.3105 16.7644 23.3105 17.2841C23.3105 17.8038 23.7409 18.2341 24.2687 18.2341H39.5349C40.0627 18.2341 40.485 17.8038 40.485 17.2841Z"
      fill="#40AE42"
    />
    <path
      d="M14.7266 18.2325C17.3576 18.2325 19.4932 16.0887 19.4932 13.4658C19.4932 10.843 17.3576 8.69922 14.7266 8.69922C12.0956 8.69922 9.95996 10.843 9.95996 13.4658C9.95996 16.0887 12.0956 18.2325 14.7266 18.2325ZM14.7266 10.5994C16.3019 10.5994 17.5849 11.8824 17.5849 13.4577C17.5849 15.0331 16.3019 16.3161 14.7266 16.3161C13.1512 16.3161 11.8682 15.0331 11.8682 13.4577C11.8682 11.8824 13.1512 10.5994 14.7266 10.5994Z"
      fill="#40AE42"
    />
    <path
      d="M40.4845 23.0067C40.4845 22.4789 40.0542 22.0566 39.5345 22.0566H28.0848C27.557 22.0566 27.1348 22.487 27.1348 23.0067C27.1348 23.5264 27.5651 23.9568 28.0848 23.9568H39.5345C40.0623 23.9568 40.4845 23.5264 40.4845 23.0067Z"
      fill="#40AE42"
    />
    <path
      d="M21.2476 21.1546C19.721 20.2532 17.358 19.1895 14.727 19.1895C12.096 19.1895 9.72492 20.2613 8.1983 21.1546C6.92341 21.9016 6.13574 23.3633 6.13574 24.9549V25.5639C6.13574 26.7819 7.05334 27.7726 8.18206 27.7726H21.272C22.4007 27.7726 23.3183 26.7819 23.3183 25.5639V24.9549C23.3183 23.3633 22.5306 21.9016 21.2557 21.1546H21.2476ZM21.4019 25.5639C21.4019 25.7588 21.2882 25.8643 21.2639 25.8643H8.18206C8.18206 25.8643 8.04401 25.7669 8.04401 25.5639V24.9549C8.04401 24.0454 8.48251 23.2009 9.16461 22.7949C10.4801 22.0153 12.5183 21.0896 14.7189 21.0896C16.9195 21.0896 18.9577 22.0153 20.2732 22.7949C20.9553 23.1928 21.3938 24.0454 21.3938 24.9549V25.5639H21.4019Z"
      fill="#40AE42"
    />
    <path
      d="M50.9841 27.4414C50.635 27.1085 50.2614 26.7999 49.8635 26.5238C48.8972 25.858 47.7929 25.3708 46.6154 25.1028V4.89948C46.6154 4.664 46.5829 4.43663 46.5504 4.21738C46.4124 3.41347 46.0389 2.6664 45.4461 2.07362C44.6909 1.31843 43.6921 0.904297 42.6202 0.904297H4.00819C2.93631 0.904297 1.92939 1.31843 1.18233 2.07362C0.427138 2.82881 0.00488281 3.83573 0.00488281 4.89948V32.5329C0.00488281 33.5966 0.427138 34.6035 1.18233 35.3587C1.77511 35.9515 2.52218 36.3251 3.32609 36.4631C3.38293 36.4712 3.43977 36.4712 3.49661 36.4793C3.66714 36.5037 3.83766 36.5281 4.00819 36.5281H35.247C35.5231 37.7136 36.0184 38.8099 36.6924 39.7762C37.6262 41.1241 38.9092 42.2041 40.4034 42.9187C41.6458 43.5196 43.0262 43.8688 44.496 43.8688C49.7417 43.8688 54.0049 39.6057 54.0049 34.3599C54.0049 31.6315 52.8356 29.1792 50.9923 27.4414H50.9841ZM34.9871 34.3599C34.9871 34.3599 34.9871 34.3924 34.9871 34.4087H4.00819C3.74834 34.4087 3.48849 34.3518 3.253 34.2463C3.04188 34.1569 2.84699 34.027 2.67646 33.8565C2.31917 33.4992 2.12428 33.0282 2.12428 32.5329V4.89948C2.12428 4.39603 2.31917 3.92505 2.67646 3.57588C3.02563 3.2267 3.49661 3.0237 4.00007 3.0237H42.6202C43.1237 3.0237 43.5947 3.21858 43.9438 3.57588C44.1144 3.7464 44.2362 3.94129 44.3336 4.15242C44.4392 4.38791 44.496 4.63964 44.496 4.89948V24.8511C39.2503 24.8511 34.9871 29.1142 34.9871 34.3599ZM44.496 41.8387C42.5065 41.8387 40.6957 41.0511 39.3559 39.7762C38.5113 38.9723 37.8617 37.9735 37.4638 36.861C37.4232 36.7473 37.3745 36.6417 37.342 36.5281C37.139 35.8541 37.0253 35.1395 37.0172 34.4005C37.0172 34.3843 37.0172 34.3681 37.0172 34.3518C37.0172 30.2835 40.2897 26.9705 44.3336 26.8811C44.3904 26.8811 44.4392 26.873 44.496 26.873C45.235 26.873 45.9495 26.9867 46.6235 27.1816C47.8741 27.5551 48.9865 28.2453 49.8717 29.1629C51.1709 30.5109 51.9748 32.338 51.9748 34.3518C51.9748 38.4769 48.6211 41.8306 44.496 41.8306V41.8387Z"
      fill="#40AE42"
    />
    <path
      d="M48.4402 32.3137C48.389 32.1904 48.314 32.0785 48.2194 31.9843C48.1253 31.8898 48.0136 31.8148 47.8905 31.7635C47.7674 31.7123 47.6355 31.6858 47.5021 31.6855C47.3688 31.6853 47.2368 31.7113 47.1135 31.7622C46.9903 31.813 46.8782 31.8876 46.7838 31.9817L43.4436 35.3216L42.1323 34.0125C41.9417 33.822 41.6833 33.715 41.4137 33.715C41.1442 33.715 40.8857 33.822 40.6951 34.0125C40.5045 34.203 40.3975 34.4614 40.3975 34.7309C40.3975 35.0003 40.5045 35.2587 40.6951 35.4492L42.7241 37.4775C42.8184 37.572 42.9304 37.6471 43.0537 37.6982C43.177 37.7494 43.3092 37.7758 43.4427 37.7758C43.5763 37.7758 43.7085 37.7494 43.8318 37.6982C43.9551 37.6471 44.0671 37.572 44.1613 37.4775L48.2194 33.4209C48.314 33.3267 48.389 33.2148 48.4402 33.0915C48.4914 32.9682 48.5178 32.8361 48.5178 32.7026C48.5178 32.5691 48.4914 32.437 48.4402 32.3137Z"
      fill="#40AE42"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
