<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#0C0F4A" />
    <g clip-path="url(#clip0_1859_49477)">
      <path
        d="M24.375 21.8749C24.375 24.3749 21.25 26.2499 21.25 28.1249H18.75C18.75 26.2499 15.625 24.3749 15.625 21.8749C15.625 19.4624 17.5875 17.4999 20 17.4999C22.4125 17.4999 24.375 19.4624 24.375 21.8749ZM21.25 29.3749H18.75V29.9999C18.75 30.6874 19.3125 31.2499 20 31.2499C20.6875 31.2499 21.25 30.6874 21.25 29.9999V29.3749ZM28.75 21.2499C28.75 22.9874 28.2375 24.6124 27.35 25.9749C27.0375 26.4624 27.1125 27.1124 27.525 27.5249C28.075 28.0749 29.025 27.9999 29.45 27.3374C30.5875 25.5874 31.25 23.4999 31.25 21.2499C31.25 18.2999 30.1125 15.6124 28.25 13.5999C27.7625 13.0749 26.9375 13.0624 26.4375 13.5624C25.9625 14.0374 25.9625 14.7999 26.4125 15.2999C27.8625 16.8624 28.75 18.9499 28.75 21.2499ZM24.5625 10.8124L21.075 7.32492C20.675 6.92492 20 7.19992 20 7.76242V9.99992C13.7875 9.99992 8.75 15.0374 8.75 21.2499C8.75 23.4999 9.4125 25.5874 10.55 27.3499C10.975 28.0124 11.925 28.0874 12.475 27.5374C12.8875 27.1249 12.9625 26.4874 12.65 25.9999C10.9125 23.3124 10.6 19.6249 12.8125 15.9999C14.3125 13.5624 17.1375 12.2624 20 12.4999V14.7374C20 15.2999 20.675 15.5749 21.0625 15.1749L24.55 11.6874C24.8 11.4499 24.8 11.0499 24.5625 10.8124Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1859_49477">
        <rect width="30" height="30" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "LightBulbIcon",
};
</script>
