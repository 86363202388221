import { Commit } from "vuex";
import InsuranceService from "@/core/services/insurances.service";

export default {
  async getInsurances({ commit }: { commit: Commit }) {
    return InsuranceService.getInsurances().then((response) => {
      commit("setInsurances", response);
    });
  },
};
