<template>
  <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
    <div
      class="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-text-center"
      v-if="!hasCustomContent"
    >
      <component :is="iconComponent"></component>
      <Text
        class="tw-max-w-[297px]"
        textColor="rgba(12, 15, 74, 0.5)"
        variant="h5"
      >
        {{ label }}
      </Text>
      <slot name="action"></slot>
    </div>
    <slot name="custom-content" v-else></slot>
  </div>
</template>

<script setup lang="ts">
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps(["label", "hasCustomContent", "iconComponent"]);
</script>

<script lang="ts">
export default {
  name: "EmptyState",
};
</script>

<style lang="scss" scoped></style>
