<template>
  <GeneralDialog
    class="tw-max-w-[560px] tw-min-w-[300px] rmd:tw-min-w-[560px]"
    @on-close="onCloseModal"
  >
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-h-auto"
          >
            <v-icon
              icon="mdi-check-circle"
              color="#FAA500"
              class="!tw-text-[68px]"
            ></v-icon>
          </div>
          <Text
            class="!tw-text-[24px] tw-w-[80%]"
            textAlign="center"
            lineHeight="28px"
          >
            Your Site Visit Request has been submitted
          </Text>
          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textAlign="center"
            textColor="rgba(12, 15, 74, 0.5)"
            textWeight="500"
          >
            You’ll be notified once your request gets accepted by the property
            owner
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-4 tw-items-center">
        <!-- <Button variant="tertiary" label="close" @click="onCloseModal" /> -->
        <Button label="Continue" @click="onContinue" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close", "on-continue"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const onCloseModal = () => {
  emits("on-close");
};

const onContinue = () => {
  emits("on-continue");
};
</script>
