<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="white" />
    <path
      d="M11.1429 19.6667C11.1429 20.4 11.7214 21 12.4286 21H17.5714C18.2786 21 18.8571 20.4 18.8571 19.6667V13C18.8571 12.2667 18.2786 11.6667 17.5714 11.6667H12.4286C11.7214 11.6667 11.1429 12.2667 11.1429 13V19.6667ZM18.8571 9.66667H17.25L16.7936 9.19333C16.6779 9.07333 16.5107 9 16.3436 9H13.6564C13.4893 9 13.3221 9.07333 13.2064 9.19333L12.75 9.66667H11.1429C10.7893 9.66667 10.5 9.96667 10.5 10.3333C10.5 10.7 10.7893 11 11.1429 11H18.8571C19.2107 11 19.5 10.7 19.5 10.3333C19.5 9.96667 19.2107 9.66667 18.8571 9.66667Z"
      fill="#80829F"
    />
  </svg>
</template>
<script>
export default {
  name: "TrashCircularWhiteIcon",
};
</script>
