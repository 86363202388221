<template>
  <div class="workstation-setting_content tw-h-full">
    <div class="tw-h-full" v-if="activeUserWorkstation?.name !== 'Default'">
      <v-tabs
        class="tab-list md:!tw-h-auto md:!tw-pt-[.5rem]"
        v-model="tab"
        align-tabs="center"
        stacked
      >
        <v-tab value="tab-1"> Account Details </v-tab>
        <v-tab value="tab-2"> Manage Subscription </v-tab>
        <v-tab value="tab-3"> Payment Method & Credits </v-tab>
        <v-tab value="tab-4"> Manage Members </v-tab>
        <v-tab value="tab-5"> Billing History </v-tab>
        <v-tab value="tab-6"> Invitations </v-tab>

        <!-- <v-tab value="tab-2"> Manage Members </v-tab>
      <v-tab value="tab-3">Invitations </v-tab> -->
      </v-tabs>

      <v-window v-model="tab" class="md:!tw-mt-4">
        <v-window-item v-for="i in 6" :key="i" :value="'tab-' + i" class="">
          <AccountDetailsTab v-if="i === 1" />
          <ManageSubscriptionSettingTab
            v-if="i === 2"
            @on-manage-member="onOpenManageMember"
          />
          <PaymentMethodAndCreditsTab v-if="i === 3" />
          <ManageMemberTab
            v-if="i === 4 && activeUserWorkstation?.name !== 'Default'"
          />
          <BillingHistoryTab v-if="i === 5" />
          <InvitationListTab
            v-if="i === 6 && activeUserWorkstation?.name !== 'Default'"
          />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script lang="ts">
import ManageSubscriptionSettingTab from "../components/tabs/ManageSubscriptionSettingTab.vue";
import PaymentMethodAndCreditsTab from "../components/tabs/PaymentMethodAndCreditsTab.vue";
import ManageMemberTab from "../components/tabs/ManageMemberTab.vue";
import BillingHistoryTab from "../components/tabs/BillingHistoryTab.vue";
import InvitationListTab from "../components/tabs/InvitationListTab.vue";
import AccountDetailsTab from "@/modules/workstation-setting/components/tabs/AccountDetailsTab.vue";
import { defineComponent, ref, computed } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { RolesEnum } from "@/core/enums/RolesEnum";

export default defineComponent({
  name: "WorkstationSettingPage",
  components: {
    ManageSubscriptionSettingTab,
    PaymentMethodAndCreditsTab,
    ManageMemberTab,
    BillingHistoryTab,
    InvitationListTab,
    AccountDetailsTab,
  },
  setup() {
    const tab = ref("tab-1");

    const store = useStore();

    const onOpenManageMember = () => {
      tab.value = "tab-4";
    };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    return { tab, activeUserWorkstation, onOpenManageMember };
  },
});
</script>
<style lang="scss" scoped>
:deep .workstation-setting_content {
  :deep .v-tabs--density-default.v-tabs--stacked {
    height: unset;
  }
}
:deep .workstation-setting_content .tab-list .v-slide-group__content {
  gap: 48px;
}
</style>
