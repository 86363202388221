// add attachment menu
const FILE = "file upload";
const FOLDER = "folder upload";
const NEW_FOLDER = "new folder";

// sorting
const ALL = "all";
const FOLDERS = "folders";
const FILES = "files";
const SHARED = "shared with me";

// dropdown menu
const SHARE = "share";
const RENAME = "rename";
const MOVE = "move to";
const DETAILS = "details";
const DOWNLOAD = "download";
const DELETE = "delete";

// type
const FILE_TPYE = "file";
const FOLDER_TYPE = "folder";

export default {
  FILE,
  FOLDER,
  NEW_FOLDER,
  ALL,
  FOLDERS,
  FILES,
  SHARED,
  SHARE,
  RENAME,
  MOVE,
  DETAILS,
  DOWNLOAD,
  DELETE,
  FILE_TPYE,
  FOLDER_TYPE,
};
