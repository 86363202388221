<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8.59 0.59Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "MiniFolderIcon",
};
</script>
