<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.398438"
      y="0.759766"
      width="18"
      height="18"
      rx="2"
      fill="#DCDCDC"
    />
    <path
      d="M5.39844 9.75977H13.3984"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: "MinusGrayIcon.vue",
};
</script>
