<template>
  <GeneralDialog headerTitle="Select Team Members" @on-close="onCloseModal"
    ><template #body>
      <div class="select-team-member-modal">
        <div class="custom-dropdown" :class="{ 'is-open': isOpen }">
          <div class="custom-dropdown__inner">
            <button
              type="button"
              class="custom-dropdown__trigger"
              @click="isOpen = !isOpen"
            >
              <p>
                {{
                  selectedMember.length
                    ? selectedMemberName
                    : "Please Select Member"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" color="#0C0F4A"></v-icon>
            </button>
            <div class="custom-dropdown__menu">
              <div class="custom-dropdown__search">
                <v-text-field
                  variant="outlined"
                  placeholder="Search Team Member"
                  prepend-inner-icon="mdi-magnify"
                  v-model="search"
                >
                </v-text-field>
              </div>

              <div class="custom-dropdown__menu-inner">
                <div class="custom-dropdown__checkbox-group">
                  <v-checkbox
                    color="#4F55F0"
                    v-for="member in filterTeamMemberList"
                    :key="member.id"
                    v-model="selectedMember"
                    :value="member.toUser.id"
                  >
                    <template v-slot:label>
                      <div class="custom-dropdown__checkbox-label">
                        <div class="custom-dropdown__checkbox-image">
                          <img
                            v-if="member.userWorkstation.profileImage"
                            :src="member.userWorkstation?.publicUrl"
                            :alt="member.userWorkstation.name"
                            width="20"
                            height="20"
                          />
                          <WorkStationProfileLogo
                            v-else
                            :WSName="member.userWorkstation.name"
                          />
                        </div>
                        <p>
                          {{ member.toUser.firstName }}
                          {{ member.toUser.lastName }}
                        </p>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="select-team-member-modal-footer">
        <v-btn class="button button-purple-border" @click="onCloseModal"
          >CANCEl</v-btn
        ><v-btn
          class="button button-orange"
          @click="onClickContinue"
          :disabled="!selectedMember.length"
          >CONTINUE</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { onBeforeMount, computed, ref } from "vue";
import { useStore } from "vuex";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { UserWorkstationMemberRoleEnum } from "@/core/enums/RolesEnum";

export default {
  data() {
    return {
      isOpen: false,
      checkbox: false,
    };
  },
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const teamMemberList = ref([]) as any;

    const selectedMember = ref([]) as any;

    const search = ref("");

    const onCloseModal = () => {
      ctx.emit("onCloseModal");
    };

    const selectedMemberName = computed(() =>
      teamMemberList.value
        .filter((member: any) =>
          selectedMember.value.includes(member.toUser.id)
        )
        .map(
          (member: any) =>
            `${member.toUser.firstName} ${member.toUser.lastName}`
        )
        .toString()
    );

    const filterTeamMemberList = computed(() => {
      if (search.value) {
        return teamMemberList.value.filter((member: any) => {
          console.log(member);
          return `${member.toUser.firstName} ${member.toUser.lastName}`.includes(
            search.value
          );
        });
      } else {
        return teamMemberList.value;
      }
    });

    const onClickContinue = () => {
      store.commit(`${WORKSTATION}/setAssignWorkStationData`, {
        teamMemberIds: selectedMember.value,
      });
      ctx.emit("onClickContinue");
    };

    onBeforeMount(async () => {
      try {
        const projectDataResponse = await store.dispatch(
          `${MEMBER_STORE}/getTeamMember`,
          {
            userId: user.value?.id,
          }
        );
        console.log("projectDataResponse", projectDataResponse);
        teamMemberList.value = projectDataResponse
          .filter((data: any) => data.id)
          .filter(
            (member: any) => member.invitationStatus === "accepted"
            // &&
            // (member.role === UserWorkstationMemberRoleEnum.EDITOR ||
            //   member.role === UserWorkstationMemberRoleEnum.VIEWER)
          );

        try {
          for (const member of teamMemberList.value) {
            if (member.userWorkstation.profileImage !== null) {
              const imageUrl = getImageApiUrl(
                member.userWorkstation.profileImage,
                true
              );
              const encoded: any = await $axios.get(imageUrl);
              member.userWorkstation.publicUrl = encoded?.publicUrl;
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    });

    return {
      teamMemberList,
      onCloseModal,
      selectedMember,
      selectedMemberName,
      onClickContinue,
      search,
      filterTeamMemberList,
    };
  },
};
</script>

<style lang="scss" scoped>
.select-team-member-modal {
  color: $blueDark;
  width: 522px;
  padding: 24px 0;
  border-top: 1px solid #c5d2f2;
  border-bottom: 1px solid #c5d2f2;

  @include respond(s720) {
    width: 100%;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    @include respond(s720) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.custom-dropdown {
  @include fluidFont(12, 12, 1);
  padding: 0 10px;

  &__label {
    @include fluidFont(16, 16, 1);
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__trigger {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(#80829f, 0.4);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-weight: 600;
    @include fluidFont(14, 14, 1);
    padding: 8px 8px 8px 16px;
    letter-spacing: 0.15px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .mdi {
      font-size: 26px;
      flex-shrink: 0;
      transition: transform 0.3s ease;
    }
  }

  &__menu {
    border-radius: 8px;
    padding: 16px 10px 10px;
    margin-top: 8px;
    display: none;
    box-shadow: 0px 0px 20px 0px #2a2a2a1a;

    :deep(.v-label) {
      width: 100%;
      @include fluidFont(12, 12, 1);
      font-weight: 500;
      opacity: 1;
      padding-left: 10px;
    }

    &-inner {
      max-height: 112px;
      overflow: auto;
      padding: 12px 12px 6px;
    }
  }

  &__checkbox {
    &-label {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 6px;
      align-items: center;
    }

    &-group {
      display: grid;
      grid-gap: 16px;
    }

    &-image {
      position: relative;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      border-radius: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  :deep(.v-selection-control__wrapper),
  :deep(.v-selection-control__input) {
    width: 16px;
    height: 16px;

    .mdi {
      font-size: 20px;
    }
  }

  :deep(.v-checkbox .v-selection-control) {
    min-height: 1px;
  }

  &__search {
    :deep(.v-field) {
      border: 0;
      border-radius: 30px;
      background-color: rgba($blueDark, 0.04);

      .v-field__field {
        .v-field__input {
          padding: 8px 16px 8px 5px;
          min-height: 38px;
          height: 38px;
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: -0.41px;
          color: rgba($blueDark, 1);
          opacity: 1;
        }

        &::placeholder {
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: -0.41px;
          color: #8e8e93;
          opacity: 1;
        }
      }

      .mdi-magnify {
        font-size: 18px;
      }

      .v-field__outline {
        display: none;
      }
    }
  }

  &.is-open {
    .custom-dropdown__trigger {
      .mdi {
        transform: scaleY(-1);
      }
    }

    .custom-dropdown__menu {
      display: block;
    }
  }
}
</style>
