import projectEstimationService from "@/core/services/project-estimation.service";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { Commit } from "vuex";
import {
  UpdateEstimatePhaseDate,
  UpdateEstimateSubTaskDate,
  UpdateEstimatePhaseAndSubTaskDate,
  UpdateEstimatePhaseOrder,
} from "@/core/models/trade-estimate";
import { USER_STORE } from "../user";
import { getImageStringToImageURL } from "@/core/utils/common";
export default {
  async getTradeEstimateData({ commit }: { commit: Commit }, payload: any) {
    try {
      const { projectId } = payload;
      const response = await tradeEstimateService.getProjectEstimationList(
        projectId
      );
      commit("setTradeEstimate", response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async setTradeEstimateDetails({ commit }: { commit: Commit }, payload: any) {
    const { projectEstimateId } = payload;
    try {
      const response: any =
        await tradeEstimateService.getProjectEstimationDetails(
          projectEstimateId
        );
      for (const phase of response.estimationPhase) {
        if (phase?.phaseNotes?.length) {
          for (const note of phase.phaseNotes) {
            note.user["profileImageUrl"] = await getImageStringToImageURL(
              note?.user?.userPublicProfile?.profileImage
            );
          }
        }
      }
      commit("setTradeEstimateDetails", response);
      return response;
    } catch (error) {
      commit("setTradeEstimateDetails", []);
      return null;
    }
  },
  async setProjectEstimateDetails(
    { commit }: { commit: Commit },
    projectId: number
  ) {
    try {
      const response =
        await projectEstimationService.getProjectEstimateByProjectId(projectId);
      commit("setTradeEstimateDetails", response);
      return response;
    } catch (error) {
      commit("setTradeEstimateDetails", []);
      return null;
    }
  },
  async updateEstimatePhaseDate(
    { commit }: { commit: Commit },
    payload: UpdateEstimatePhaseDate
  ) {
    try {
      const response = await projectEstimationService.updateEstimatePhaseDate(
        payload
      );

      return response;
    } catch (error) {
      return error;
    }
  },

  async updateEstimatePhaseSubtaskDate(
    { commit }: { commit: Commit },
    payload: UpdateEstimateSubTaskDate
  ) {
    try {
      const response =
        await projectEstimationService.updateEstimatePhaseSubtaskDate(payload);

      return response;
    } catch (error) {
      return error;
    }
  },
  async updateEstimatePhaseAndSubTaskDate(
    { commit }: { commit: Commit },
    payload: UpdateEstimatePhaseAndSubTaskDate
  ) {
    try {
      const response =
        await projectEstimationService.updateEstimatePhaseAndSubTaskDate(
          payload
        );

      return response;
    } catch (error) {
      return error;
    }
  },
  async updateEstimatePhaseOrder(
    { commit }: { commit: Commit },
    payload: UpdateEstimatePhaseOrder
  ) {
    try {
      const response = await projectEstimationService.updateEstimatePhaseOrder(
        payload
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  async getProjectEstimateStatus(
    { commit }: { commit: Commit },
    projectEstimateId: number
  ) {
    try {
      if (!projectEstimateId) {
        commit("setProjectEstimateStatus", null);
        return null;
      }
      const response: any = await tradeEstimateService.getProjectEstimateStatus(
        projectEstimateId
      );
      commit("setProjectEstimateStatus", response.estimateStatus || null);
      return response;
    } catch (error) {
      commit("setProjectEstimateStatus", null);
      return null;
    }
  },
  async getProjectEstimateWithEscrowStatus(
    { commit }: { commit: Commit },
    projectEstimateId: number
  ) {
    try {
      if (!projectEstimateId) {
        commit("setTradeEstimateDataWithEscrowStatus", null);
        return null;
      }
      const response =
        await tradeEstimateService.getProjectEstimateWithEscrowStatus(
          projectEstimateId
        );

      commit("setTradeEstimateDataWithEscrowStatus", response);
      return response;
    } catch (error) {
      commit("setTradeEstimateDataWithEscrowStatus", null);
      return null;
    }
  },
  async getContractorTradePassportDetail(
    { commit, dispatch }: { commit: Commit; dispatch: any },
    payload: any
  ) {
    try {
      const { workStationId, userId } = payload;
      const response: any =
        await tradeEstimateService.getContractorTradePassportDetail(
          workStationId,
          userId
        );
      commit("setContractorTradePassportDetail", response);
      return response;
    } catch (error) {
      return null;
    }
  },
  async getContractorWorkStationImageUrl(
    { commit }: { commit: Commit },
    profileImg: any
  ) {
    try {
      return getImageStringToImageURL(profileImg);
    } catch (error) {
      return "";
    }
  },
  viewTradeUserAccount({ commit }: { commit: Commit }, workstationId: number) {
    commit(`setContractorTradePassportWorkstationId`, workstationId);
    commit(`setIsOpenTradePassportasViewMode`, true);
  },
  resetTradeUserAccount({ commit }: { commit: Commit }) {
    commit(`setContractorTradePassportWorkstationId`, null);
    commit(`setIsOpenTradePassportasViewMode`, false);
  },
};
