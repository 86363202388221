<template>
  <svg
    width="164"
    height="121"
    viewBox="0 0 164 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11019_30227)">
      <path
        d="M147.009 112.516H136.238V112.597H147.009V112.516Z"
        fill="#EBEBEB"
      />
      <path
        d="M108.416 113.398H105.59V113.48H108.416V113.398Z"
        fill="#EBEBEB"
      />
      <path
        d="M135.912 109.496H129.672V109.577H135.912V109.496Z"
        fill="#EBEBEB"
      />
      <path
        d="M31.8069 110.043H17.7617V110.124H31.8069V110.043Z"
        fill="#EBEBEB"
      />
      <path
        d="M36.7655 110.043H34.707V110.124H36.7655V110.043Z"
        fill="#EBEBEB"
      />
      <path
        d="M73.9212 111.414H43.457V111.495H73.9212V111.414Z"
        fill="#EBEBEB"
      />
      <path
        d="M77.7775 92.7781H14.9858C13.9614 92.7781 13.1289 91.9456 13.1289 90.9213V2.65374C13.1289 1.62937 13.9614 0.796875 14.9858 0.796875H77.7808C78.8052 0.796875 79.6377 1.62937 79.6377 2.65374V90.9213C79.6344 91.9456 78.8019 92.7781 77.7775 92.7781ZM14.9858 0.878174C14.0069 0.878174 13.2102 1.6749 13.2102 2.65374V90.9213C13.2102 91.9001 14.0069 92.6968 14.9858 92.6968H77.7808C78.7596 92.6968 79.5564 91.9001 79.5564 90.9213V2.65374C79.5564 1.6749 78.7596 0.878174 77.7808 0.878174H14.9858Z"
        fill="#EBEBEB"
      />
      <path
        d="M148.117 92.864H85.3217C84.2973 92.864 83.4648 92.0315 83.4648 91.0072V2.73967C83.4648 1.71531 84.2973 0.882812 85.3217 0.882812H148.117C149.141 0.882812 149.974 1.71531 149.974 2.73967V91.0072C149.974 92.0315 149.141 92.864 148.117 92.864ZM85.3217 0.964111C84.3429 0.964111 83.5461 1.76084 83.5461 2.73967V91.0072C83.5461 91.986 84.3429 92.7827 85.3217 92.7827H148.117C149.096 92.7827 149.892 91.986 149.892 91.0072V2.73967C149.892 1.76084 149.096 0.964111 148.117 0.964111H85.3217Z"
        fill="#EBEBEB"
      />
      <path d="M163.3 107.281H0.703125V107.363H163.3V107.281Z" fill="#EBEBEB" />
      <path
        d="M25.3111 31.0558H54.803C58.5817 31.0558 59.4923 25.7649 55.9249 24.5259C55.8794 24.5097 55.8338 24.4934 55.7883 24.4804C59.7362 21.1927 51.073 14.2563 41.6684 23.8593C42.4001 20.6594 34.0393 21.8138 31.4833 26.1812C30.5695 23.313 22.5957 28.2949 25.3111 31.0558Z"
        fill="url(#paint0_linear_11019_30227)"
      />
      <path
        d="M104.193 17.8677H121.877C121.877 17.8677 126.023 16.3068 125.486 12.1703C124.95 8.03382 118.69 11.4809 118.69 11.4809C118.69 11.4809 120.589 5.70868 114.524 7.34766C108.462 8.98339 107.871 14.7068 107.871 14.7068C107.871 14.7068 99.9066 13.315 104.193 17.8677Z"
        fill="url(#paint1_linear_11019_30227)"
      />
      <path
        d="M120.442 29.5031H132.116C132.116 29.5031 134.855 28.4722 134.5 25.7406C134.146 23.009 130.012 25.2853 130.012 25.2853C130.012 25.2853 131.264 21.4741 127.261 22.557C123.258 23.6366 122.868 27.4154 122.868 27.4154C122.868 27.4154 117.613 26.4983 120.442 29.5031Z"
        fill="#EBEBEB"
      />
      <path
        d="M118.195 88.6811L118.163 85.2275V85.0194L118.133 81.9431V81.7447L118.104 78.818L118.101 78.6261L118.075 75.8392L118.072 75.6603L118.046 73.0002L118.042 72.8311L118.016 70.2881V70.1255L117.994 67.6963L117.99 67.5402L117.971 65.5305C117.87 65.5923 117.76 65.6443 117.636 65.6866L117.649 67.3841L115.496 67.3939L117.229 65.8037C117.064 65.8362 116.878 65.8622 116.69 65.8687L115.259 67.176L115.285 65.6443C115.158 65.589 115.041 65.524 114.95 65.4492L114.914 67.5565V67.7126L114.872 70.1418L114.869 70.3076L114.823 72.8441V73.0165L114.777 75.6733L114.774 75.8522L114.725 78.6391L114.722 78.831L114.673 81.7512L114.67 81.9528L114.615 85.0259L114.612 85.234L114.553 88.6844L114.254 103.582L118.407 103.63L118.195 88.6811ZM117.763 88.0372L115.262 85.3349H117.746L117.763 88.0372ZM117.724 85.0129L115.249 85.0162L117.707 82.4341L117.724 85.0129ZM117.75 81.2179L115.369 78.7789L117.733 78.7724L117.75 81.2179ZM117.72 78.444L115.363 78.4505L117.707 76.1026L117.72 78.444ZM117.704 75.2148L115.434 73.0002L117.688 72.9937L117.704 75.2148ZM117.688 72.6555L115.438 72.6653L117.672 70.5255L117.688 72.6555ZM117.649 67.7256L117.662 69.7515L115.493 67.7353L117.649 67.7256ZM115.203 68.1906L117.373 70.2133L115.174 70.2198L115.203 68.1906ZM115.171 70.5645L117.369 70.5548L115.138 72.6848L115.171 70.5645ZM115.125 73.5238L117.395 75.7416L115.099 75.7481L115.125 73.5238ZM115.093 76.0831L117.399 76.0766L115.06 78.4115L115.093 76.0831ZM115.047 79.3123L117.428 81.761L115.012 81.7642L115.047 79.3123ZM115.025 82.0894L117.451 82.0862L114.992 84.6552L115.025 82.0894ZM115.008 85.4877L117.509 88.2031H114.969L115.008 85.4877Z"
        fill="#EBEBEB"
      />
      <path
        d="M117.951 65.1822L117.782 65.1854L117.665 65.0781L116.673 65.9854C116.865 65.9789 117.047 65.9529 117.213 65.9204L117.616 65.5497L117.62 65.8033C117.743 65.7643 117.857 65.709 117.955 65.6472L117.951 65.1822ZM114.94 65.2017L114.934 65.5659C115.025 65.6407 115.142 65.7058 115.269 65.761L115.275 65.2017H114.94Z"
        fill="#EBEBEB"
      />
      <path
        d="M118.438 64.9109C118.467 65.0833 118.402 65.2556 118.262 65.4052L118.145 65.5125C118.09 65.5581 118.022 65.6068 117.95 65.6459C117.849 65.7076 117.739 65.7597 117.615 65.802C117.488 65.8507 117.355 65.893 117.209 65.9223C117.043 65.9548 116.861 65.9808 116.669 65.9873C116.646 65.9906 116.62 65.9906 116.6 65.9906L116.425 65.9938C115.976 65.9873 115.573 65.8995 115.264 65.7597C115.137 65.7044 115.02 65.6394 114.929 65.5646C114.919 65.5581 114.909 65.5516 114.9 65.5418L114.792 65.4377C114.659 65.2947 114.604 65.1288 114.633 64.9564L114.88 63.5744L114.227 63.5841L116.5 62.4297L118.87 63.5093L118.204 63.5191L118.438 64.9109Z"
        fill="#EBEBEB"
      />
      <path
        d="M128.871 66.6484L128.959 103.581H131.639H147.215H149.895L150.015 66.6484H128.871Z"
        fill="#EBEBEB"
      />
      <path
        d="M132.957 61.9492L132.967 70.1831H134.625H144.267H145.926L145.942 61.9492H132.957Z"
        fill="#EBEBEB"
      />
      <path
        d="M132.945 70.1831H130.206L130.203 67.7148H132.941L132.945 70.1831Z"
        fill="#FAFAFA"
      />
      <path
        d="M138.195 67.7148H135.457V70.1831H138.195V67.7148Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.449 67.7148H140.711V70.1831H143.449V67.7148Z"
        fill="#FAFAFA"
      />
      <path
        d="M148.703 70.1831H145.965L145.971 67.7148H148.709L148.703 70.1831Z"
        fill="#F0F0F0"
      />
      <path
        d="M132.906 74.676H130.233L130.227 72.2695H132.903L132.906 74.676Z"
        fill="#F0F0F0"
      />
      <path
        d="M138.153 72.2695H135.477V74.676H138.153V72.2695Z"
        fill="#F0F0F0"
      />
      <path
        d="M143.403 72.2695H140.727V74.676H143.403V72.2695Z"
        fill="#FAFAFA"
      />
      <path
        d="M148.645 74.676H145.969L145.975 72.2695H148.652L148.645 74.676Z"
        fill="#FAFAFA"
      />
      <path
        d="M132.883 79.0627H130.272L130.266 76.7148H132.88L132.883 79.0627Z"
        fill="#F0F0F0"
      />
      <path
        d="M138.126 76.7148H135.512V79.0627H138.126V76.7148Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.365 76.7148H140.75V79.0627H143.365V76.7148Z"
        fill="#FAFAFA"
      />
      <path
        d="M138.177 64.1328H135.387V66.6498H138.177V64.1328Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.442 64.1328H140.648V66.6498H143.442V64.1328Z"
        fill="#F0F0F0"
      />
      <path
        d="M148.6 79.0627H145.988L145.992 76.7148H148.606L148.6 79.0627Z"
        fill="#F0F0F0"
      />
      <path
        d="M132.876 83.3441H130.323L130.316 81.0547H132.872L132.876 83.3441Z"
        fill="#FAFAFA"
      />
      <path
        d="M138.111 81.0547H135.559V83.3473H138.111V81.0547Z"
        fill="#F0F0F0"
      />
      <path
        d="M143.346 83.3441H140.793L140.796 81.0547H143.349L143.346 83.3441Z"
        fill="#F0F0F0"
      />
      <path
        d="M148.576 83.3441H146.023L146.03 81.0547H148.583L148.576 83.3441Z"
        fill="#FAFAFA"
      />
      <path
        d="M132.993 88.155H130.147L130.141 85.6055H132.989L132.993 88.155Z"
        fill="#F0F0F0"
      />
      <path
        d="M138.213 88.155H135.367V85.6055H138.216L138.213 88.155Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.439 88.155H140.594V85.6055H143.442L143.439 88.155Z"
        fill="#FAFAFA"
      />
      <path
        d="M148.662 88.155H145.816L145.823 85.6055H148.668L148.662 88.155Z"
        fill="#F0F0F0"
      />
      <path
        d="M133.01 92.2254H130.229L130.223 89.7344H133.006L133.01 92.2254Z"
        fill="#F0F0F0"
      />
      <path
        d="M138.226 89.7344H135.445V92.2254H138.226V89.7344Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.452 89.7344H140.672V92.2254H143.452V89.7344Z"
        fill="#F0F0F0"
      />
      <path
        d="M148.671 92.2254H145.891L145.897 89.7344H148.678L148.671 92.2254Z"
        fill="#FAFAFA"
      />
      <path
        d="M132.95 96.7977H130.238L130.234 94.375H132.946L132.95 96.7977Z"
        fill="#FAFAFA"
      />
      <path
        d="M138.161 94.375H135.449V96.8009H138.161V94.375Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.376 94.375H140.664V96.8009H143.376V94.375Z"
        fill="#F0F0F0"
      />
      <path
        d="M148.584 96.7977H145.875L145.878 94.375H148.59L148.584 96.7977Z"
        fill="#F0F0F0"
      />
      <path
        d="M132.947 100.958H130.3L130.297 98.5938H132.944L132.947 100.958Z"
        fill="#F0F0F0"
      />
      <path
        d="M148.574 100.958H145.93L145.933 98.5938H148.58L148.574 100.958Z"
        fill="#FAFAFA"
      />
      <path
        d="M143.387 98.5938H135.484L135.501 99.1856H135.719L135.722 103.579H136.512L136.509 99.1856H142.375L142.369 103.579H143.159L143.165 99.1856H143.403L143.387 98.5938Z"
        fill="#F5F5F5"
      />
      <path
        d="M139.477 103.581H136.55L136.547 99.1875H139.48L139.477 103.581Z"
        fill="#E6E6E6"
      />
      <path
        d="M142.332 103.581H139.402L139.406 99.1875H142.339L142.332 103.581Z"
        fill="#EBEBEB"
      />
      <path
        d="M128.904 78.3984L128.962 103.581H125.603H106.072H102.709L102.469 78.3984H128.904Z"
        fill="#F5F5F5"
      />
      <path
        d="M123.881 83.9944H127.159L127.149 81.0547H123.871L123.881 83.9944Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.34 83.9944H120.618L120.605 81.0547H117.324L117.34 83.9944Z"
        fill="#E6E6E6"
      />
      <path
        d="M110.8 83.9944H114.078L114.059 81.0547H110.777L110.8 83.9944Z"
        fill="#E6E6E6"
      />
      <path
        d="M104.26 83.9944H107.538L107.512 81.0547H104.234L104.26 83.9944Z"
        fill="#E6E6E6"
      />
      <path
        d="M123.755 89.7344H127.287L127.277 86.5703H123.742L123.755 89.7344Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.23 89.7344H120.762L120.746 86.5703H117.211L117.23 89.7344Z"
        fill="#E6E6E6"
      />
      <path
        d="M110.699 89.7344H114.23L114.211 86.5703H110.676L110.699 89.7344Z"
        fill="#E6E6E6"
      />
      <path
        d="M104.174 89.7344H107.705L107.679 86.5703H104.145L104.174 89.7344Z"
        fill="#E6E6E6"
      />
      <path
        d="M123.845 94.9845H127.273L127.266 91.918H123.832L123.845 94.9845Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.328 94.9845H120.756L120.743 91.918H117.309L117.328 94.9845Z"
        fill="#E6E6E6"
      />
      <path
        d="M110.804 94.9845H114.235L114.215 91.918H110.781L110.804 94.9845Z"
        fill="#E6E6E6"
      />
      <path
        d="M104.284 94.9845H107.711L107.689 91.918H104.258L104.284 94.9845Z"
        fill="#E6E6E6"
      />
      <path
        d="M123.923 100.371H127.247L127.24 97.3984H123.91L123.923 100.371Z"
        fill="#E6E6E6"
      />
      <path
        d="M104.385 100.371H107.712L107.686 97.3984H104.359L104.385 100.371Z"
        fill="#E6E6E6"
      />
      <path
        d="M110.652 97.3984H120.935L120.938 98.296H120.281L120.307 103.58H119.355L119.329 98.296H112.272L112.308 103.58H111.358L111.319 98.296H110.659L110.652 97.3984Z"
        fill="#F5F5F5"
      />
      <path
        d="M115.857 103.581H119.379L119.353 98.2969H115.824L115.857 103.581Z"
        fill="#E6E6E6"
      />
      <path
        d="M112.286 103.581H115.808L115.778 98.2969H112.25L112.286 103.581Z"
        fill="#EBEBEB"
      />
      <path
        d="M50.1686 56.8431C50.1686 56.4463 50.1686 56.0529 50.1686 55.6561C50.1686 55.474 49.8857 55.474 49.8857 55.6561C49.8857 56.0886 49.8857 56.5244 49.8857 56.9569C49.8857 57.0382 49.9507 57.0902 50.0255 57.0967C50.471 57.1422 50.7995 57.6983 50.4287 58.0691C50.0743 58.4235 49.5052 58.1438 49.4597 57.6658C49.4402 57.487 49.1572 57.4837 49.1767 57.6658C49.2483 58.3682 50.071 58.8333 50.6304 58.2674C51.1474 57.7471 50.7897 57.0024 50.1686 56.8431Z"
        fill="#EBEBEB"
      />
      <path
        d="M29.3251 48.3325V50.9016L26.6227 48.1992L26.4894 48.3325H26.3008V51.3569V51.5455V54.3812V54.5698V57.4055V57.5941V60.4298V60.6184V63.4541V63.6427V66.4784V66.667V69.5027V69.6913V72.7156H29.7023V69.6913V69.5027V66.667V66.4784V63.6427V63.4541V60.6184V60.4298V57.5941V57.4055V54.5698V54.3812V51.5455V51.3569V48.3325H29.3251ZM26.678 66.9369L29.0584 69.3173H26.678V66.9369ZM26.678 60.8883L29.0584 63.2687H26.678V60.8883ZM26.678 54.8364L29.0584 57.2169H26.678V54.8364ZM26.678 48.7878L29.0584 51.1682H26.678V48.7878ZM29.0584 51.5455L26.678 53.9259V51.5455H29.0584ZM29.3251 51.8121V54.1926H26.9447L29.3251 51.8121ZM29.0584 57.5973L26.678 59.9778V57.5973H29.0584ZM29.3251 57.864V60.2444H26.9447L29.3251 57.864ZM29.0584 63.646L26.678 66.0264V63.646H29.0584ZM29.3251 63.9126V66.293H26.9447L29.3251 63.9126ZM29.0584 69.6946L26.678 72.075V69.6946H29.0584ZM26.9447 72.3449L29.3251 69.9645V72.3449H26.9447ZM29.3251 69.0507L26.9447 66.6703H29.3251V69.0507ZM29.3251 63.0021L26.9447 60.6216H29.3251V63.0021ZM29.3251 56.9535L26.9447 54.573H29.3251V56.9535Z"
        fill="#F0F0F0"
      />
      <path
        d="M26.6758 40.3001V42.57V42.7586V45.0252H27.053V43.2106L29.0009 45.1585L29.1343 45.0252H29.3229V42.7553V42.5667V40.2969H26.6758V40.3001ZM28.679 40.6774L27.053 42.3033V40.6774H28.679ZM28.9456 44.5699L27.3197 42.944H28.9456V44.5699ZM27.3197 42.5667L28.9456 40.9408V42.5667H27.3197Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.4661 48.332H15.9297V52.8685H20.4661V48.332Z"
        fill="#E6E6E6"
      />
      <path
        d="M50.0867 54.8778H49.8331V46.1529L28.7052 39.1482H27.3101L15.9966 46.1691L15.8633 45.9578L27.2353 38.8945H28.7475L28.767 38.901L50.0867 45.9708V54.8778Z"
        fill="#E6E6E6"
      />
      <path
        d="M47.502 48.5247H47.7621L50.4157 45.8711H47.6906H47.502H45.4207H45.2321H43.1509H42.9623H40.881H40.6924H38.6111H38.429H36.3478H36.1592H34.0779H33.8893H31.8081H31.6195H29.5382H29.3496H27.2684H27.0798H24.9985H24.8099H22.7287H22.54H20.4588H20.2702H18.1889H18.0003H15.7305V48.5182H18.0003H18.1889H20.2702H20.4588H22.54H22.7287H24.8099H24.9985H27.0798H27.2684H29.3496H29.5382H31.6195H31.8081H33.8893H34.0779H36.1592H36.3478H38.429H38.6177H40.6989H40.8875H42.9688H43.1574H45.2386H45.4272H47.5085V48.5247H47.502ZM47.8792 46.2483H49.5052L47.8792 47.8743V46.2483ZM16.1174 48.1409V46.515L17.7434 48.1409H16.1174ZM18.0101 47.8743L16.3841 46.2483H18.0101V47.8743ZM18.3873 46.2483H20.0133L18.3873 47.8743V46.2483ZM20.2799 48.1409H18.654L20.2799 46.515V48.1409ZM20.6572 48.1409V46.515L22.2831 48.1409H20.6572ZM22.5465 47.8743L20.9206 46.2483H22.5465V47.8743ZM22.9238 46.2483H24.5497L22.9238 47.8743V46.2483ZM24.8164 48.1409H23.1904L24.8164 46.515V48.1409ZM25.1936 48.1409V46.515L26.8196 48.1409H25.1936ZM27.0863 47.8743L25.4603 46.2483H27.0863V47.8743ZM27.4635 46.2483H29.0895L27.4635 47.8743V46.2483ZM29.3529 48.1409H27.7269L29.3529 46.515V48.1409ZM29.7301 48.1409V46.515L31.3561 48.1409H29.7301ZM31.6227 47.8743L29.9967 46.2483H31.6227V47.8743ZM31.9999 46.2483H33.6259L31.9999 47.8743V46.2483ZM33.8893 48.1409H32.2634L33.8893 46.515V48.1409ZM34.2666 48.1409V46.515L35.8925 48.1409H34.2666ZM36.1592 47.8743L34.5332 46.2483H36.1592V47.8743ZM36.5364 46.2483H38.1624L36.5364 47.8743V46.2483ZM38.429 48.1409H36.8031L38.429 46.515V48.1409ZM38.8063 48.1409V46.515L40.4322 48.1409H38.8063ZM40.6956 47.8743L39.0697 46.2483H40.6956V47.8743ZM41.0729 46.2483H42.6988L41.0729 47.8743V46.2483ZM42.9655 48.1409H41.3395L42.9655 46.515V48.1409ZM43.3427 46.2483H44.9687L43.3427 47.8743V46.2483ZM45.2354 48.1409H43.6094L45.2354 46.515V48.1409ZM45.6126 48.1409V46.515L47.2386 48.1409H45.6126ZM45.8792 46.2483H47.5052V47.8743L45.8792 46.2483Z"
        fill="#F0F0F0"
      />
      <path
        d="M31.3771 49.3615H24.3984L24.6553 46.425C24.7236 45.6316 25.387 45.0234 26.1838 45.0234H29.5072C30.2877 45.0234 30.9413 45.6088 31.0324 46.3795L31.3771 49.3615Z"
        fill="#F0F0F0"
      />
      <path
        d="M26.8632 46.2422H25.5234V47.582H26.8632V46.2422Z"
        fill="#FAFAFA"
      />
      <path
        d="M28.5586 46.2422H27.2188V47.582H28.5586V46.2422Z"
        fill="#FAFAFA"
      />
      <path
        d="M30.2539 46.2422H28.9141V47.582H30.2539V46.2422Z"
        fill="#FAFAFA"
      />
      <path
        d="M28.9135 39.574C28.9135 40.078 28.5038 40.4877 27.9997 40.4877C27.4957 40.4877 27.0859 40.078 27.0859 39.574C27.0859 39.0699 27.4957 38.6602 27.9997 38.6602C28.5038 38.6602 28.9135 39.0699 28.9135 39.574Z"
        fill="#E6E6E6"
      />
      <path
        d="M50.0266 56.4398C49.5941 56.4398 49.2461 56.0919 49.2461 55.6594V54.8789H50.807V55.6594C50.807 56.0886 50.4591 56.4398 50.0266 56.4398Z"
        fill="#E6E6E6"
      />
      <path
        d="M13.0781 66.6484L13.683 103.581H16.3658H31.9394H34.6223L34.2223 66.6484H13.0781Z"
        fill="#F5F5F5"
      />
      <path
        d="M14.3984 67.7148L14.47 72.209L14.4927 73.4448L14.5123 74.6773H17.2536L17.2341 73.4448L17.2179 72.209L17.1463 67.7148H14.3984Z"
        fill="#E6E6E6"
      />
      <path
        d="M19.6523 67.7148L19.7206 72.209L19.7369 73.4448L19.7564 74.6773H22.4978L22.4815 73.4448L22.4653 72.209L22.4002 67.7148H19.6523Z"
        fill="#E6E6E6"
      />
      <path
        d="M24.9102 67.7148L24.9719 72.209L24.9882 73.4448L25.0045 74.6773H27.7459L27.7328 73.4448L27.7166 72.209L27.6613 67.7148H24.9102Z"
        fill="#E6E6E6"
      />
      <path
        d="M30.168 67.7148L30.22 72.209L30.2428 74.1179L30.2493 74.6773H32.9939L32.9874 74.1179L32.9646 72.209L32.9159 67.7148H30.168Z"
        fill="#E6E6E6"
      />
      <path
        d="M14.5625 76.7148L14.6373 81.2025L14.6568 82.4383L14.6763 83.6675H17.421L17.4047 82.4383L17.3852 81.2025L17.3169 76.7148H14.5625Z"
        fill="#E6E6E6"
      />
      <path
        d="M19.8047 76.7148L19.8697 81.2025L19.8957 82.9423L19.9055 83.6675H22.6534L22.6436 82.9423L22.6209 81.2025L22.5558 76.7148H19.8047Z"
        fill="#E6E6E6"
      />
      <path
        d="M25.0469 76.7148L25.1054 81.2025L25.1217 82.4383L25.1379 83.6675H27.8858L27.8696 82.4383L27.8533 81.2025L27.798 76.7148H25.0469Z"
        fill="#E6E6E6"
      />
      <path
        d="M30.2891 76.7148L30.3443 81.2025L30.3574 82.4383L30.3736 83.6675H33.1183L33.1052 82.4383L33.0922 81.2025L33.0435 76.7148H30.2891Z"
        fill="#E6E6E6"
      />
      <path
        d="M14.7188 85.6094L14.7903 90.0808L14.8131 91.3068L14.8326 92.536H17.574L17.5544 91.3068L17.5349 90.0808L17.4666 85.6094H14.7188Z"
        fill="#E6E6E6"
      />
      <path
        d="M19.9492 85.6094L20.0143 90.0808L20.0305 91.3068L20.05 92.536H22.7914L22.7752 91.3068L22.7589 90.0808L22.6971 85.6094H19.9492Z"
        fill="#E6E6E6"
      />
      <path
        d="M25.1758 85.6094L25.2343 90.0808L25.2506 91.3068L25.2668 92.536H28.0115L27.9952 91.3068L27.979 90.0808L27.9237 85.6094H25.1758Z"
        fill="#E6E6E6"
      />
      <path
        d="M30.4062 85.6094L30.4583 90.0808L30.4778 91.7751L30.4875 92.536H33.2289L33.2224 91.7751L33.2029 90.0808L33.1541 85.6094H30.4062Z"
        fill="#E6E6E6"
      />
      <path
        d="M31.9268 94.375H30.5708H28.031H25.3189H22.7759H20.0637H14.8086L14.8476 96.7977H20.0995H22.8084H25.3481H28.0603H30.6H31.9561H33.3122L33.2861 94.375H31.9268Z"
        fill="#E6E6E6"
      />
      <path
        d="M28.0259 98.5977H20.1237L20.1172 99.1895H20.3546L20.4196 103.583H21.2098L21.1448 99.1895H27.0113L27.0666 103.583H27.8568L27.8015 99.1895H28.0194L28.0259 98.5977Z"
        fill="#FAFAFA"
      />
      <path
        d="M24.1754 103.581H21.2486L21.1836 99.1875H24.1168L24.1754 103.581Z"
        fill="#E6E6E6"
      />
      <path
        d="M27.0315 103.581H24.1015L24.043 99.1875H26.973L27.0315 103.581Z"
        fill="#E6E6E6"
      />
      <path
        d="M54.1629 68.7773L54.4003 103.58H51.8736H37.1976H34.6708L34.2383 68.7773H54.1629Z"
        fill="#E6E6E6"
      />
      <path
        d="M47.1268 94.9805H48.2032H53.0324L53.0389 96.0959L53.0486 97.2081L53.0779 101.25H50.5772L50.5446 97.0975H48.1057H47.1431H45.7383L45.7188 94.9805H47.1268Z"
        fill="#F0F0F0"
      />
      <path
        d="M37.5992 94.9805H38.5618H43.147L43.1698 97.0975H38.4252L38.474 101.25H35.9732L35.9277 97.2081L35.9082 95.5983L35.8984 94.9805H37.5992Z"
        fill="#F0F0F0"
      />
      <path
        d="M41.0885 98.8906H47.8883L47.8948 99.4825H47.9696L48.0021 103.58H47.2672L47.2314 99.4825H41.7616L41.8039 103.58H41.069L41.0234 99.4825H41.095L41.0885 98.8906Z"
        fill="#F5F5F5"
      />
      <path
        d="M44.5078 103.578H47.2394L47.2036 99.4805H44.4688L44.5078 103.578Z"
        fill="#EBEBEB"
      />
      <path
        d="M41.8352 103.578H44.5669L44.5279 99.4805H41.793L41.8352 103.578Z"
        fill="#E0E0E0"
      />
      <path
        d="M45.3594 70.1836H52.9397L52.9852 76.7168H45.4212L45.3594 70.1836Z"
        fill="#F0F0F0"
      />
      <path
        d="M35.5312 70.1836H43.1115L43.1766 76.7168H35.6093L35.5312 70.1836Z"
        fill="#F0F0F0"
      />
      <path
        d="M45.5703 78.3945H52.8254L52.8742 84.6383H45.6256L45.5703 78.3945Z"
        fill="#F0F0F0"
      />
      <path
        d="M35.7617 78.3945H43.0168L43.0818 84.6383H35.8333L35.7617 78.3945Z"
        fill="#F0F0F0"
      />
      <path
        d="M45.6445 86.8867H52.9419L52.9874 93.1467H45.6998L45.6445 86.8867Z"
        fill="#F0F0F0"
      />
      <path
        d="M35.8633 86.8867H43.1606L43.2224 93.1467H35.9348L35.8633 86.8867Z"
        fill="#F0F0F0"
      />
      <path
        d="M44.2844 32.2932C37.6309 35.7533 32.4571 41.558 28.3889 47.857C22.6037 56.8096 18.6981 67.541 20.3501 78.0708C22.0021 88.6006 30.3921 98.4768 41.0195 99.319C54.6321 100.399 65.7115 87.521 79.2916 86.0999C84.4654 85.5601 89.6458 86.734 94.8359 87.0657C110.188 88.0445 125.837 81.1212 135.436 69.102C141.722 61.229 145.478 50.8423 143.511 40.9596C141.466 30.69 133.352 22.2122 123.638 18.2936C113.928 14.3783 102.871 14.6547 92.8067 17.5391C83.4834 20.2122 76.0462 25.3341 67.6919 29.9323C64.3196 31.7892 60.4954 29.2949 56.7296 29.2494C52.4175 29.1909 48.0989 30.3095 44.2844 32.2932Z"
        fill="#4F55F0"
      />
      <path
        d="M44.2844 32.2932C37.6309 35.7533 32.4571 41.558 28.3889 47.857C22.6037 56.8096 18.6981 67.541 20.3501 78.0708C22.0021 88.6006 30.3921 98.4768 41.0195 99.319C54.6321 100.399 65.7115 87.521 79.2916 86.0999C84.4654 85.5601 89.6458 86.734 94.8359 87.0657C110.188 88.0445 125.837 81.1212 135.436 69.102C141.722 61.229 145.478 50.8423 143.511 40.9596C141.466 30.69 133.352 22.2122 123.638 18.2936C113.928 14.3783 102.871 14.6547 92.8067 17.5391C83.4834 20.2122 76.0462 25.3341 67.6919 29.9323C64.3196 31.7892 60.4954 29.2949 56.7296 29.2494C52.4175 29.1909 48.0989 30.3095 44.2844 32.2932Z"
        fill="url(#paint2_linear_11019_30227)"
      />
      <path
        d="M53.0039 10.4492L54.2169 12.0167H109.78L111.002 10.4492H53.0039Z"
        fill="#4F55F0"
      />
      <g opacity="0.4">
        <path
          d="M53.0039 10.4492L54.2169 12.0167H109.78L111.002 10.4492H53.0039Z"
          fill="#0C0F4A"
        />
      </g>
      <path
        d="M109.795 8.42188H54.1055V107.144H109.795V8.42188Z"
        fill="#4F55F0"
      />
      <g opacity="0.4">
        <path
          d="M109.795 8.42188H54.1055V107.144H109.795V8.42188Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        <path
          d="M56.362 10.8543C56.4271 11.4949 55.9783 12.012 55.3637 12.012C54.7491 12.012 54.193 11.4949 54.1247 10.8543C54.0531 10.2104 54.5019 9.68359 55.1198 9.68359C55.7442 9.68359 56.3002 10.2104 56.362 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M58.4155 10.8543C58.474 11.4949 58.022 12.012 57.4074 12.012C56.7928 12.012 56.2432 11.4949 56.1781 10.8543C56.1131 10.2104 56.5619 9.68359 57.183 9.68359C57.8041 9.68359 58.3537 10.2104 58.4155 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M60.4618 10.8543C60.5171 11.4949 60.0619 12.012 59.4472 12.012C58.8326 12.012 58.2863 11.4949 58.2278 10.8543C58.1692 10.2104 58.6212 9.68359 59.2424 9.68359C59.8602 9.68359 60.4066 10.2104 60.4618 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M62.5114 10.8543C62.5602 11.4949 62.1016 12.012 61.487 12.012C60.8724 12.012 60.3293 11.4949 60.274 10.8543C60.2188 10.2104 60.6773 9.68359 61.2984 9.68359C61.9195 9.68359 62.4626 10.2104 62.5114 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M64.5651 10.8543C64.6074 11.4949 64.1456 12.012 63.531 12.012C62.9164 12.012 62.3765 11.4949 62.3278 10.8543C62.279 10.2104 62.7407 9.68359 63.3619 9.68359C63.983 9.68359 64.5196 10.2104 64.5651 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M66.6115 10.8543C66.6505 11.4949 66.1822 12.012 65.5676 12.012C64.953 12.012 64.4197 11.4949 64.3742 10.8543C64.3286 10.2104 64.7969 9.68359 65.418 9.68359C66.0391 9.68359 66.5725 10.2104 66.6115 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M68.6654 10.8543C68.6979 11.4949 68.2264 12.012 67.6117 12.012C66.9971 12.012 66.4671 11.4949 66.428 10.8543C66.389 10.2104 66.8605 9.68359 67.4817 9.68359C68.0995 9.68359 68.6296 10.2104 68.6654 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M70.7116 10.8543C70.7408 11.4949 70.2628 12.012 69.6482 12.012C69.0336 12.012 68.5068 11.4949 68.4742 10.8543C68.4417 10.2104 68.9165 9.68359 69.5376 9.68359C70.1555 9.68359 70.6823 10.2104 70.7116 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M72.762 10.8543C72.7848 11.4949 72.3068 12.012 71.6889 12.012C71.0743 12.012 70.5507 11.4949 70.5247 10.8543C70.4954 10.2104 70.9767 9.68359 71.5946 9.68359C72.2125 9.68359 72.7393 10.2104 72.762 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M74.8123 10.8543C74.8318 11.4949 74.3473 12.012 73.7327 12.012C73.1181 12.012 72.601 11.4949 72.575 10.8543C72.5522 10.2104 73.0335 9.68359 73.6546 9.68359C74.2758 9.68359 74.7928 10.2104 74.8123 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M76.8629 10.8543C76.8759 11.4949 76.3881 12.012 75.7735 12.012C75.1589 12.012 74.6451 11.4949 74.6256 10.8543C74.6061 10.2104 75.0938 9.68359 75.715 9.68359C76.3361 9.68359 76.8499 10.2104 76.8629 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M78.9095 10.8543C78.916 11.4949 78.4249 12.012 77.8103 12.012C77.1957 12.012 76.6851 11.4949 76.6721 10.8543C76.6591 10.2104 77.1502 9.68359 77.7713 9.68359C78.3924 9.68359 78.903 10.2104 78.9095 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M80.964 10.8543C80.9673 11.4949 80.4697 12.012 79.8551 12.012C79.2405 12.012 78.7332 11.4949 78.7267 10.8543C78.7169 10.2104 79.2145 9.68359 79.8356 9.68359C80.4535 9.68359 80.9608 10.2104 80.964 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M83.0108 10.8543C83.0075 11.4949 82.5067 12.012 81.8921 12.012C81.2775 12.012 80.7767 11.4949 80.7735 10.8543C80.7702 10.2104 81.271 9.68359 81.8921 9.68359C82.5132 9.68359 83.014 10.2104 83.0108 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M85.0616 10.8543C85.0551 11.4949 84.551 12.012 83.9331 12.012C83.3185 12.012 82.821 11.4949 82.8242 10.8543C82.8275 10.2104 83.3315 9.68359 83.9527 9.68359C84.5738 9.68359 85.0713 10.2104 85.0616 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M87.1124 10.8543C87.0994 11.4949 86.5921 12.012 85.9775 12.012C85.3629 12.012 84.8686 11.4949 84.8751 10.8543C84.8816 10.2104 85.3921 9.68359 86.01 9.68359C86.6311 9.68359 87.1254 10.2104 87.1124 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M89.1633 10.8543C89.147 11.4949 88.6332 12.012 88.0186 12.012C87.404 12.012 86.9162 11.4949 86.9259 10.8543C86.9389 10.2104 87.4495 9.68359 88.0706 9.68359C88.6917 9.68359 89.1828 10.2104 89.1633 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M91.2143 10.8543C91.1915 11.4949 90.6745 12.012 90.0599 12.012C89.4452 12.012 88.9607 11.4949 88.977 10.8543C88.9932 10.2104 89.5103 9.68359 90.1314 9.68359C90.7525 9.68359 91.2371 10.2104 91.2143 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M93.2616 10.8543C93.2323 11.4949 92.712 12.012 92.0974 12.012C91.4827 12.012 91.0014 11.4949 91.0242 10.8543C91.047 10.2104 91.5673 9.68359 92.1884 9.68359C92.8095 9.68359 93.2908 10.2104 93.2616 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M95.3089 10.8543C95.2764 11.4949 94.7496 12.012 94.135 12.012C93.5204 12.012 93.0423 11.4949 93.0716 10.8543C93.0976 10.2104 93.6244 9.68359 94.2455 9.68359C94.8667 9.68359 95.3447 10.2104 95.3089 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M97.3639 10.8543C97.3249 11.4949 96.7981 12.012 96.1802 12.012C95.5656 12.012 95.0941 11.4949 95.1266 10.8543C95.1591 10.2104 95.6892 9.68359 96.3103 9.68359C96.9282 9.68359 97.3997 10.2104 97.3639 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M99.4121 10.8543C99.3699 11.4949 98.8365 12.012 98.2219 12.012C97.6073 12.012 97.139 11.4949 97.1781 10.8543C97.2171 10.2104 97.7504 9.68359 98.3715 9.68359C98.9894 9.68359 99.4544 10.2104 99.4121 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M101.459 10.8543C101.41 11.4949 100.873 12.012 100.259 12.012C99.6442 12.012 99.1791 11.4949 99.2214 10.8543C99.2637 10.2104 99.8035 9.68359 100.425 9.68359C101.046 9.68359 101.508 10.2104 101.459 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M103.51 10.8543C103.458 11.4949 102.915 12.012 102.301 12.012C101.686 12.012 101.224 11.4949 101.273 10.8543C101.322 10.2104 101.865 9.68359 102.483 9.68359C103.104 9.68359 103.562 10.2104 103.51 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M105.562 10.8543C105.503 11.4949 104.957 12.012 104.342 12.012C103.728 12.012 103.272 11.4949 103.324 10.8543C103.376 10.2104 103.923 9.68359 104.544 9.68359C105.165 9.68359 105.62 10.2104 105.562 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M107.614 10.8543C107.548 11.4949 106.999 12.012 106.384 12.012C105.77 12.012 105.318 11.4949 105.376 10.8543C105.435 10.2104 105.984 9.68359 106.605 9.68359C107.227 9.68359 107.675 10.2104 107.614 10.8543Z"
          fill="#F0F0F0"
        />
        <path
          d="M109.662 10.8543C109.593 11.4949 109.037 12.012 108.423 12.012C107.808 12.012 107.359 11.4949 107.424 10.8543C107.489 10.2104 108.042 9.68359 108.663 9.68359C109.284 9.68359 109.73 10.2104 109.662 10.8543Z"
          fill="#F0F0F0"
        />
      </g>
      <path
        d="M53.0039 8.85547H111.009L111.006 10.4424H53.0072L53.0039 8.85547Z"
        fill="#4F55F0"
      />
      <path
        d="M53 7.26953L54.2097 8.85648H109.789L111.011 7.26953H53Z"
        fill="#4F55F0"
      />
      <g opacity="0.4">
        <path
          d="M53 7.26953L54.2097 8.85648H109.789L111.011 7.26953H53Z"
          fill="#0C0F4A"
        />
      </g>
      <path
        d="M52.9922 5.67578H111.013L111.01 7.26923H52.9987L52.9922 5.67578Z"
        fill="#4F55F0"
      />
      <path
        d="M54.207 7.26953H109.793L109.789 8.85648H54.2103L54.207 7.26953Z"
        fill="#4F55F0"
      />
      <g opacity="0.8">
        <path
          d="M54.207 7.26953H109.793L109.789 8.85648H54.2103L54.207 7.26953Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        <path
          d="M109.807 40.0977H54.1211V40.7968H109.807V40.0977Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        <path
          d="M109.804 74.7969H54.2344V75.4928H109.804V74.7969Z"
          fill="white"
        />
      </g>
      <path
        d="M72.5445 15.0469H65.6602V22.4841H72.5445V15.0469Z"
        fill="#4F55F0"
      />
      <path
        d="M85.7672 15.0469H78.8828V22.4841H85.7672V15.0469Z"
        fill="#4F55F0"
      />
      <path
        d="M98.9898 15.0469H92.1055V22.4841H98.9898V15.0469Z"
        fill="#4F55F0"
      />
      <path
        d="M72.5445 26.1836H65.6602V33.6208H72.5445V26.1836Z"
        fill="#4F55F0"
      />
      <path
        d="M85.7672 26.1836H78.8828V33.6208H85.7672V26.1836Z"
        fill="#4F55F0"
      />
      <path
        d="M98.9898 26.1836H92.1055V33.6208H98.9898V26.1836Z"
        fill="#4F55F0"
      />
      <path
        d="M72.5445 80.1484H65.6602V87.5856H72.5445V80.1484Z"
        fill="#4F55F0"
      />
      <path
        d="M85.7672 80.1484H78.8828V87.5856H85.7672V80.1484Z"
        fill="#4F55F0"
      />
      <path
        d="M98.9898 80.1484H92.1055V87.5856H98.9898V80.1484Z"
        fill="#4F55F0"
      />
      <path
        d="M65.1695 95.8086H58.2852V103.246H65.1695V95.8086Z"
        fill="#4F55F0"
      />
      <path
        d="M105.619 95.8086H98.7344V103.246H105.619V95.8086Z"
        fill="#4F55F0"
      />
      <path
        d="M72.5445 46.6562H65.6602V54.0934H72.5445V46.6562Z"
        fill="#4F55F0"
      />
      <path
        d="M85.7672 46.6562H78.8828V54.0934H85.7672V46.6562Z"
        fill="#4F55F0"
      />
      <path
        d="M98.9898 46.6562H92.1055V54.0934H98.9898V46.6562Z"
        fill="#4F55F0"
      />
      <path
        d="M72.5445 57.7891H65.6602V65.2263H72.5445V57.7891Z"
        fill="#4F55F0"
      />
      <path
        d="M85.7672 57.7891H78.8828V65.2263H85.7672V57.7891Z"
        fill="#4F55F0"
      />
      <path
        d="M98.9898 57.7891H92.1055V65.2263H98.9898V57.7891Z"
        fill="#4F55F0"
      />
      <g opacity="0.8">
        <path
          d="M73.9863 96.6836H81.1113L81.082 104.079H73.9375L73.9863 96.6836Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        <path
          d="M82.4882 96.6836H89.9059L89.5547 104.079H82.4102L82.4882 96.6836Z"
          fill="white"
        />
      </g>
      <path
        d="M91.0416 105.575H72.2421L72.1641 104.082H91.1131L91.0416 105.575Z"
        fill="#4F55F0"
      />
      <g opacity="0.1">
        <path
          d="M91.0416 105.575H72.2421L72.1641 104.082H91.1131L91.0416 105.575Z"
          fill="white"
        />
      </g>
      <path
        d="M91.798 107.15H71.5774L71.4961 105.68H91.8728L91.798 107.15Z"
        fill="#4F55F0"
      />
      <g opacity="0.4">
        <path
          d="M92.4684 94.0647H70.856L70.7617 92.3867H92.553L92.4684 94.0647Z"
          fill="white"
        />
      </g>
      <path
        d="M70.8555 94.0625L73.9871 96.6836H89.9054L92.4679 94.0625H70.8555Z"
        fill="#0C0F4A"
      />
      <path
        d="M72.3203 95.2898L73.426 104.08H74.304L73.9495 95.2898C73.4455 95.0232 72.8049 95.0232 72.3203 95.2898Z"
        fill="#4F55F0"
      />
      <path
        d="M91.3125 95.2908L90.2296 104.081H89.3516L89.6833 95.2908C90.1645 94.9656 90.854 94.9656 91.3125 95.2908Z"
        fill="#4F55F0"
      />
      <path
        d="M63.6094 3.26381C63.6094 1.82645 64.7085 0.808594 66.2077 0.808594C67.0792 0.808594 67.7784 1.12728 68.2401 1.69963L67.3914 2.47034C67.0955 2.11262 66.7312 1.91751 66.2792 1.91751C65.502 1.91751 64.9622 2.45733 64.9622 3.26706C64.9622 4.0768 65.502 4.61662 66.2792 4.61662C66.7312 4.61662 67.0955 4.4215 67.3914 4.06379L68.2401 4.8345C67.7816 5.40684 67.0792 5.72553 66.2077 5.72553C64.7118 5.71903 63.6094 4.70117 63.6094 3.26381Z"
        fill="#0C0F4A"
      />
      <path
        d="M68.4883 3.26381C68.4883 1.84596 69.6004 0.808594 71.1126 0.808594C72.6248 0.808594 73.7369 1.84922 73.7369 3.26381C73.7369 4.68166 72.6248 5.71903 71.1126 5.71903C69.6037 5.71903 68.4883 4.68166 68.4883 3.26381ZM72.3906 3.26381C72.3906 2.44757 71.8313 1.91425 71.1159 1.91425C70.4004 1.91425 69.8411 2.44757 69.8411 3.26381C69.8411 4.08005 70.4004 4.61337 71.1159 4.61337C71.8313 4.61337 72.3906 4.08005 72.3906 3.26381Z"
        fill="#0C0F4A"
      />
      <path
        d="M78.704 5.62417L78.691 3.12017L77.4748 5.15914H76.8797L75.6732 3.18846V5.62417H74.4375V0.902344H75.5367L77.1951 3.62747L78.8146 0.902344H79.9138L79.9268 5.62742H78.704V5.62417Z"
        fill="#0C0F4A"
      />
      <path
        d="M85.1318 2.64864C85.1318 3.72178 84.3221 4.38843 83.0343 4.38843H82.2115V5.62417H80.875V0.902344H83.0343C84.3221 0.902344 85.1318 1.56899 85.1318 2.64864ZM83.7822 2.64864C83.7822 2.20963 83.5058 1.95272 82.953 1.95272H82.2115V3.3348H82.953C83.5058 3.33805 83.7822 3.08115 83.7822 2.64864Z"
        fill="#0C0F4A"
      />
      <path
        d="M88.7104 4.70646H86.7138L86.343 5.62351H84.9805L87.065 0.898438H88.382L90.473 5.62351H89.0844L88.7104 4.70646ZM88.317 3.72113L87.7088 2.20897L87.104 3.72113H88.317Z"
        fill="#0C0F4A"
      />
      <path
        d="M95.3418 0.902344V5.62742H94.2427L92.1582 3.11041V5.62742H90.8477V0.902344H91.9468L94.0313 3.41935V0.902344H95.3418Z"
        fill="#0C0F4A"
      />
      <path
        d="M98.8139 3.9325V5.62676H97.4774V3.90974L95.6758 0.898438H97.0871L98.209 2.77481L99.3277 0.898438H100.622L98.8139 3.9325Z"
        fill="#0C0F4A"
      />
      <path
        d="M41.3555 112.416L43.6741 112.289L43.879 117.778L41.5603 117.905L41.3555 112.416Z"
        fill="#F7978D"
      />
      <path
        d="M44.4484 117.283C45.1671 117.729 46.211 118.119 48.1166 118.311C48.5361 118.353 48.6759 119.319 47.6971 119.426C46.7183 119.533 45.011 119.686 44.3021 119.764C43.1476 119.888 42.7184 119.992 41.8078 120.106C41.5802 120.129 41.3786 119.963 41.3558 119.735L41.2582 117.647C41.2517 117.556 41.32 117.475 41.4143 117.468L44.0354 117.182C44.1785 117.169 44.3248 117.205 44.4484 117.283Z"
        fill="#0C0F4A"
      />
      <path
        opacity="0.2"
        d="M41.4595 115.246L43.7814 115.122L43.6741 112.293L41.3555 112.42L41.4595 115.246Z"
        fill="#0C0F4A"
      />
      <path
        d="M45.5783 117.479C45.6433 117.391 45.6563 117.277 45.614 117.18C45.5945 117.111 45.5392 117.059 45.4677 117.04C45.1718 116.959 44.5604 117.479 44.4921 117.544C44.4791 117.56 44.4726 117.58 44.4791 117.599C44.4856 117.619 44.5051 117.635 44.5246 117.638C44.7328 117.667 44.9441 117.671 45.1555 117.651C45.3149 117.648 45.4645 117.586 45.5783 117.479ZM44.6677 117.544C44.9149 117.359 45.2856 117.105 45.4449 117.15C45.4775 117.16 45.5035 117.186 45.5132 117.219C45.5457 117.284 45.5392 117.359 45.497 117.417C45.3994 117.541 45.097 117.586 44.6677 117.547V117.544Z"
        fill="#4F55F0"
      />
      <path
        d="M44.5653 117.627C44.7832 117.461 45.0759 116.938 44.9978 116.69C44.9686 116.599 44.8775 116.541 44.7832 116.547C44.6987 116.544 44.6141 116.58 44.5588 116.648C44.3475 116.902 44.4873 117.568 44.4808 117.594C44.484 117.611 44.497 117.627 44.5133 117.634L44.5393 117.64L44.5653 117.627ZM44.7702 116.658C44.8515 116.658 44.8873 116.694 44.8808 116.726C44.8743 117.009 44.7605 117.279 44.5588 117.477C44.5036 117.224 44.5263 116.957 44.6304 116.72C44.6596 116.684 44.6987 116.664 44.7442 116.661L44.7702 116.658Z"
        fill="#4F55F0"
      />
      <path
        d="M23.0309 109.98L25.33 110.465L23.6552 115.665L21.3594 115.18L23.0309 109.98Z"
        fill="#F7978D"
      />
      <path
        d="M24.2794 115.666C24.8192 116.31 25.9314 117.107 27.6809 117.878C28.0712 118.053 27.8988 119.009 26.933 118.808C25.9704 118.603 24.0615 118.122 23.3624 117.975C22.2274 117.735 21.7884 117.696 20.8876 117.527C20.6633 117.478 20.5234 117.257 20.5722 117.032L21.1283 115.016C21.151 114.928 21.2421 114.873 21.3332 114.896L23.9152 115.442C24.055 115.475 24.1851 115.556 24.2794 115.666Z"
        fill="#0C0F4A"
      />
      <path
        opacity="0.2"
        d="M22.168 112.66L24.4638 113.148L25.3289 110.468L23.0297 109.98L22.168 112.66Z"
        fill="#0C0F4A"
      />
      <path
        d="M37.6289 61.2969C37.6289 61.2969 40.0744 82.4605 40.263 90.3693C40.4353 97.6569 40.3118 113.783 40.3118 113.783L44.5295 113.585C44.5295 113.585 46.2888 98.5999 46.7929 91.257C47.2547 84.5353 45.814 63.0269 45.814 63.0269L37.6289 61.2969Z"
        fill="#4F55F0"
      />
      <path
        opacity="0.2"
        d="M38.6367 69.6497C39.0172 74.0268 39.5278 79.6624 39.996 84.3745C40.9619 81.6461 41.557 74.7878 40.3993 70.5798C39.7001 68.0237 39.066 68.5863 38.6367 69.6497Z"
        fill="#0C0F4A"
      />
      <path
        d="M39.6211 112.94L45.0746 112.527L44.8242 114.319L40.2357 114.7L39.6211 112.94Z"
        fill="#0C0F4A"
      />
      <path
        d="M32.842 62.1211C32.842 62.1211 28.8941 81.597 27.5283 89.3919C26.273 96.5722 21.3398 111.801 21.3398 111.801L25.5446 112.692C25.5446 112.692 32.5168 97.2876 34.4484 90.1756C36.2142 83.6782 40.6336 63.7731 40.6336 63.7731L32.842 62.1211Z"
        fill="#4F55F0"
      />
      <path
        d="M21.2402 110.367L26.892 111.304L26.3034 112.845L21.1719 111.759L21.2402 110.367Z"
        fill="#0C0F4A"
      />
      <path
        d="M25.079 115.997C25.17 115.936 25.2188 115.828 25.2058 115.718C25.2091 115.646 25.1733 115.578 25.1148 115.539C24.8579 115.37 24.1132 115.675 24.0286 115.711C24.0091 115.721 23.9993 115.741 23.9961 115.763C23.9961 115.786 24.0091 115.806 24.0286 115.812C24.2172 115.903 24.4188 115.975 24.6237 116.023C24.7733 116.069 24.9359 116.059 25.079 115.997ZM24.2107 115.76C24.5099 115.646 24.9359 115.532 25.0595 115.63C25.0887 115.649 25.105 115.685 25.1018 115.718C25.1115 115.789 25.079 115.858 25.0237 115.9C24.8936 115.981 24.5879 115.929 24.1945 115.763L24.2107 115.76Z"
        fill="#4F55F0"
      />
      <path
        d="M24.0802 115.812C24.3338 115.724 24.7794 115.317 24.7826 115.054C24.7826 114.956 24.7176 114.875 24.6233 114.852C24.542 114.823 24.4542 114.833 24.3794 114.882C24.0997 115.057 24.0151 115.727 24.0086 115.756C24.0054 115.773 24.0119 115.792 24.0249 115.802C24.0314 115.805 24.0412 115.808 24.0509 115.808C24.0639 115.812 24.0704 115.815 24.0802 115.812ZM24.5875 114.95C24.6655 114.979 24.6818 115.018 24.672 115.051C24.672 115.223 24.3664 115.539 24.1322 115.665C24.1582 115.405 24.2623 115.161 24.4379 114.969C24.4769 114.943 24.5224 114.937 24.5647 114.947L24.5875 114.95Z"
        fill="#4F55F0"
      />
      <path
        d="M25.1357 66.462C25.6853 65.9156 25.7633 65.3173 26.1048 64.6832C26.3357 64.2506 25.8024 64.4197 26.3357 64.475C26.5276 64.4945 26.7292 64.6084 26.9048 64.6734C27.3666 64.849 27.8153 65.0701 28.2803 65.2392C28.6803 65.3823 29.4023 65.3173 29.7405 65.5157C30.2933 65.8408 29.7632 67.2717 29.6494 67.7367C29.5096 68.2961 30.3648 68.5887 30.5047 68.0229C30.703 67.2164 31.2201 65.7758 30.5957 65.0181C30.2088 64.5498 29.6136 64.6441 29.0803 64.5368C28.0722 64.3385 27.0349 63.4181 25.9747 63.5287C25.6138 63.5645 25.4056 63.7238 25.256 64.0523C25.0869 64.423 25.1878 64.836 24.9666 65.2262C24.8528 65.4279 24.7065 65.6197 24.5406 65.7856C24.1309 66.1921 24.7227 66.8717 25.1357 66.462Z"
        fill="#4F55F0"
      />
      <g opacity="0.3">
        <path
          d="M25.1357 66.462C25.6853 65.9156 25.7633 65.3173 26.1048 64.6832C26.3357 64.2506 25.8024 64.4197 26.3357 64.475C26.5276 64.4945 26.7292 64.6084 26.9048 64.6734C27.3666 64.849 27.8153 65.0701 28.2803 65.2392C28.6803 65.3823 29.4023 65.3173 29.7405 65.5157C30.2933 65.8408 29.7632 67.2717 29.6494 67.7367C29.5096 68.2961 30.3648 68.5887 30.5047 68.0229C30.703 67.2164 31.2201 65.7758 30.5957 65.0181C30.2088 64.5498 29.6136 64.6441 29.0803 64.5368C28.0722 64.3385 27.0349 63.4181 25.9747 63.5287C25.6138 63.5645 25.4056 63.7238 25.256 64.0523C25.0869 64.423 25.1878 64.836 24.9666 65.2262C24.8528 65.4279 24.7065 65.6197 24.5406 65.7856C24.1309 66.1921 24.7227 66.8717 25.1357 66.462Z"
          fill="#0C0F4A"
        />
      </g>
      <path
        d="M30.7041 80.7957L15.8915 75.8333C14.942 75.5146 14.4281 74.4837 14.7468 73.5341L17.6638 64.8319C17.8492 64.2791 18.4475 63.9832 18.9971 64.1685L35.2536 69.6156C35.8064 69.8009 36.1024 70.3993 35.917 70.9489L33 79.6511C32.6846 80.6006 31.6537 81.1144 30.7041 80.7957Z"
        fill="#4F55F0"
      />
      <path
        opacity="0.3"
        d="M30.7041 80.7957L15.8915 75.8333C14.942 75.5146 14.4281 74.4837 14.7468 73.5341L17.6638 64.8319C17.8492 64.2791 18.4475 63.9832 18.9971 64.1685L35.2536 69.6156C35.8064 69.8009 36.1024 70.3993 35.917 70.9489L33 79.6511C32.6846 80.6006 31.6537 81.1144 30.7041 80.7957Z"
        fill="white"
      />
      <path
        d="M26.1992 70.8759L23.6406 78.1875L23.9476 78.2949L26.5061 70.9833L26.1992 70.8759Z"
        fill="#4F55F0"
      />
      <g opacity="0.3">
        <path
          d="M26.1992 70.8759L23.6406 78.1875L23.9476 78.2949L26.5061 70.9833L26.1992 70.8759Z"
          fill="#0C0F4A"
        />
      </g>
      <path
        opacity="0.2"
        d="M34.9688 70.6714L18.5595 65.1724C18.0489 65 17.4961 65.2764 17.327 65.787L17.2132 66.1252C16.7319 67.5626 17.5059 69.1202 18.9432 69.6015L31.9933 73.9754C33.4306 74.4567 34.9883 73.6827 35.4696 72.2454L35.5834 71.9072C35.7558 71.3966 35.4794 70.8438 34.9688 70.6714Z"
        fill="#0C0F4A"
      />
      <path
        d="M35.3282 69.6363L18.9189 64.1372C18.4083 63.9649 17.8555 64.2413 17.6864 64.7518L17.5726 65.09C17.0913 66.5274 17.8652 68.0851 19.3026 68.5664L32.3527 72.9402C33.79 73.4215 35.3477 72.6476 35.829 71.2102L35.9428 70.872C36.1152 70.3614 35.8387 69.8086 35.3282 69.6363Z"
        fill="#4F55F0"
      />
      <path
        d="M35.3282 69.6363L18.9189 64.1372C18.4083 63.9649 17.8555 64.2413 17.6864 64.7518L17.5726 65.09C17.0913 66.5274 17.8652 68.0851 19.3026 68.5664L32.3527 72.9402C33.79 73.4215 35.3477 72.6476 35.829 71.2102L35.9428 70.872C36.1152 70.3614 35.8387 69.8086 35.3282 69.6363Z"
        fill="#4F55F0"
      />
      <path
        opacity="0.5"
        d="M35.3282 69.6363L18.9189 64.1372C18.4083 63.9649 17.8555 64.2413 17.6864 64.7518L17.5726 65.09C17.0913 66.5274 17.8652 68.0851 19.3026 68.5664L32.3527 72.9402C33.79 73.4215 35.3477 72.6476 35.829 71.2102L35.9428 70.872C36.1152 70.3614 35.8387 69.8086 35.3282 69.6363Z"
        fill="white"
      />
      <path
        d="M20.9875 71.48L20.4444 71.2979C20.1127 71.1873 19.9339 70.8264 20.0444 70.4947L20.8704 68.0264C20.981 67.6947 21.3419 67.5159 21.6736 67.6265L22.2167 67.8086C22.5484 67.9191 22.7273 68.2801 22.6167 68.6118L21.7907 71.08C21.6801 71.4117 21.3192 71.5906 20.9875 71.48Z"
        fill="#4F55F0"
      />
      <path
        d="M29.8234 74.4409L29.2803 74.2588C28.9486 74.1483 28.7698 73.7873 28.8804 73.4556L29.7064 70.9874C29.8169 70.6557 30.1779 70.4768 30.5096 70.5874L31.0527 70.7695C31.3844 70.8801 31.5632 71.241 31.4526 71.5727L30.6267 74.041C30.5161 74.3759 30.1584 74.5548 29.8234 74.4409Z"
        fill="#4F55F0"
      />
      <path
        d="M20.9875 71.48L20.4444 71.2979C20.1127 71.1873 19.9339 70.8264 20.0444 70.4947L20.8704 68.0264C20.981 67.6947 21.3419 67.5159 21.6736 67.6265L22.2167 67.8086C22.5484 67.9191 22.7273 68.2801 22.6167 68.6118L21.7907 71.08C21.6801 71.4117 21.3192 71.5906 20.9875 71.48Z"
        fill="white"
      />
      <path
        d="M29.8234 74.4409L29.2803 74.2588C28.9486 74.1483 28.7698 73.7873 28.8804 73.4556L29.7064 70.9874C29.8169 70.6557 30.1779 70.4768 30.5096 70.5874L31.0527 70.7695C31.3844 70.8801 31.5632 71.241 31.4526 71.5727L30.6267 74.041C30.5161 74.3759 30.1584 74.5548 29.8234 74.4409Z"
        fill="white"
      />
      <path
        d="M48.7831 45.4626C48.9294 45.7521 49.1148 46.0968 49.2839 46.4155C49.453 46.7341 49.6416 47.0593 49.827 47.3748C50.2009 48.0089 50.5782 48.6333 50.9847 49.2284C51.3749 49.804 51.7976 50.3568 52.2464 50.8836C52.6106 51.3291 53.0399 51.7129 53.5212 52.0251L53.5862 52.0673C53.6057 52.0706 53.5862 52.0673 53.5992 52.0706L53.6122 52.0738C53.6122 52.0738 53.6447 52.0803 53.6935 52.0901C53.8756 52.0868 54.0545 52.0446 54.2171 51.9665C54.7764 51.6999 55.2935 51.3552 55.752 50.9389C56.2951 50.4771 56.8089 49.9796 57.2869 49.4463C57.5341 49.1731 57.7812 48.9032 58.0186 48.6203C58.256 48.3374 58.4999 48.0382 58.7113 47.778L58.7535 47.726C59.1438 47.1471 59.9275 46.9943 60.5096 47.3813C61.0884 47.7715 61.2413 48.552 60.8543 49.1308C60.0478 50.604 59.0657 51.9698 57.9275 53.2023C57.2837 53.8917 56.5422 54.4835 55.7227 54.9518C55.1992 55.2445 54.6236 55.4364 54.0285 55.5176C53.6675 55.5599 53.3033 55.5502 52.9488 55.4851C52.7602 55.4526 52.5781 55.4038 52.3992 55.3388C52.3114 55.3193 52.2106 55.2673 52.1423 55.238L51.9472 55.1502C51.0692 54.6949 50.2757 54.0965 49.5961 53.3811C48.988 52.7665 48.4254 52.1096 47.9148 51.4104C47.414 50.7373 46.949 50.0544 46.5165 49.3552C46.2888 49.0073 46.0872 48.6658 45.8856 48.2983C45.684 47.9309 45.4921 47.5959 45.2872 47.1894C44.7962 46.2236 45.1832 45.0431 46.149 44.5553C47.1148 44.0676 48.2953 44.4513 48.7831 45.4171V45.4626Z"
        fill="#0C0F4A"
      />
      <path
        d="M59.7984 48.7514L61.6422 49.4213L61.5121 47.2685C61.5121 47.2685 59.5967 46.5888 59.3398 47.61V48.0815C59.3398 48.3742 59.5219 48.6441 59.7984 48.7514Z"
        fill="#F7978D"
      />
      <path
        d="M63.5383 48.9077L63.9286 47.0703L61.5156 47.2654L61.6425 49.4182L63.5383 48.9077Z"
        fill="#F7978D"
      />
      <path
        opacity="0.3"
        d="M49.3308 53.0086C49.1129 52.7614 48.9081 52.5143 48.7064 52.2606C48.0788 51.4541 47.5097 50.6021 47.0089 49.7111C46.5146 48.8428 46.0724 47.9518 45.6789 47.0347C45.6236 46.9047 45.5716 46.7746 45.5195 46.6445C46.5081 46.0559 48.6024 46.3453 49.282 49.0802C49.5519 50.3777 49.5682 51.711 49.3308 53.0086Z"
        fill="#0C0F4A"
      />
      <path
        d="M45.1062 64.7128L32.8398 62.1178C32.9829 61.0024 33.9845 57.487 34.765 52.905C35.2528 49.7441 35.5325 46.554 35.6072 43.3573C35.6072 43.3476 35.6072 43.3378 35.6072 43.3313C35.6528 42.502 36.365 41.8679 37.1909 41.9167L37.217 41.9232C37.9812 41.972 38.934 42.0565 39.8543 42.2061C41.6136 42.476 43.3566 42.8435 45.0737 43.3118C45.6395 43.4971 46.1923 43.715 46.7322 43.9687C47.4996 44.3004 48.0264 45.0288 48.1012 45.8613C48.3191 48.5377 48.215 53.783 45.1062 64.7128Z"
        fill="white"
      />
      <path
        d="M43.5589 45.0748L43.2077 45.7772C43.1426 45.9073 43.0386 45.9821 42.9378 45.9723L42.3296 45.9203C42.2418 45.917 42.1703 45.8422 42.1378 45.7284L41.8906 44.8862L42.8012 44.3203L43.5589 45.0748Z"
        fill="#4F55F0"
      />
      <path
        d="M39.7233 41.9824C38.7543 41.8263 37.7494 41.7352 36.9429 41.6864L36.9169 41.6799C36.9007 41.6799 36.8844 41.6767 36.8681 41.6767C35.9966 41.6474 35.2682 42.3303 35.2389 43.2019C35.1641 45.514 34.982 49.5887 34.3544 53.2699C33.1642 61.8225 31.9805 63.52 31.9805 63.52L37.4535 64.5541C39.1055 56.7885 40.3835 46.1806 40.2502 42.0539L39.7233 41.9824Z"
        fill="#0C0F4A"
      />
      <path
        opacity="0.3"
        d="M34.543 52.0887C34.9397 49.3278 35.1121 46.4791 35.1999 44.4011C35.2259 44.3719 35.2519 44.3459 35.2844 44.3231C35.9738 43.7378 37.6843 45.1426 37.2193 47.7637C36.7771 50.2546 35.5641 51.7538 34.543 52.0887Z"
        fill="#0C0F4A"
      />
      <path
        d="M46.0305 66.4941L42.7266 65.6096C44.8013 51.0571 45.2858 47.1873 44.8208 43.1094C45.6403 43.324 46.4468 43.5842 47.237 43.8833C48.0793 44.2898 48.6451 45.1093 48.7264 46.0426C49.0028 48.3287 47.2728 54.153 46.0305 66.4941Z"
        fill="#0C0F4A"
      />
      <path
        d="M42.2219 60.4918C42.5666 60.3194 42.9861 60.4593 43.1584 60.804C43.1584 60.804 43.1584 60.804 43.1584 60.8072C43.3308 61.1519 43.1909 61.5747 42.8462 61.747C42.5015 61.9194 42.0788 61.7795 41.9064 61.4348C41.7341 61.0901 41.8706 60.6674 42.2186 60.495C42.2219 60.4918 42.2219 60.4918 42.2219 60.4918Z"
        fill="#0C0F4A"
      />
      <path
        d="M41.8088 61.4963C41.6039 61.0963 41.76 60.602 42.1632 60.3971C42.3551 60.2995 42.5827 60.28 42.7876 60.3516C43.2006 60.4752 43.4445 60.9012 43.3469 61.3207C43.2429 61.7597 42.8038 62.0296 42.3648 61.9255C42.1242 61.8702 41.9226 61.7141 41.8088 61.4963Z"
        fill="white"
      />
      <path
        d="M42.8815 44.3345C40.2637 43.928 39.6523 42.1784 39.6523 42.1784C41.0279 41.2841 41.2393 38.1297 41.21 36.3672L42.3612 37.8208L43.9286 39.8012C43.8181 40.0451 43.7238 40.2923 43.6457 40.5459C43.3238 41.554 43.3921 42.5491 44.8327 43.2288C44.8327 43.2288 45.1124 44.6727 42.8815 44.3345Z"
        fill="#F7978D"
      />
      <path
        d="M39.1662 41.8768L40.0248 41.75C40.7109 42.7971 41.628 43.6686 42.7109 44.2995C42.1385 44.5304 41.6507 44.9272 41.3028 45.4345C38.7467 43.919 39.1662 41.8768 39.1662 41.8768Z"
        fill="white"
      />
      <path
        d="M44.8527 43.0856L44.1405 42.7734C44.1405 42.7734 43.5129 44.2563 42.7031 44.3116C43.9291 45.1116 44.0104 45.8173 44.0104 45.8173C46.0624 45.6026 44.8527 43.0856 44.8527 43.0856Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M43.7297 40.3119L42.2273 38.4258C42.159 38.6632 42.12 38.9071 42.1037 39.1542C42.055 39.9022 42.8322 40.8485 43.5086 41.1477L43.7297 40.3119Z"
        fill="#0C0F4A"
      />
      <path
        d="M45.375 36.8653C45.375 36.8653 45.4563 38.036 46.6758 37.5905C46.7278 36.3418 47.1376 36.4393 47.5603 35.7076C48.1197 34.8849 48.1815 33.8215 47.7229 32.937L46.9457 32.5467C43.9149 31.6037 45.375 36.8653 45.375 36.8653Z"
        fill="#0C0F4A"
      />
      <path
        opacity="0.6"
        d="M45.375 36.8653C45.375 36.8653 45.4563 38.036 46.6758 37.5905C46.7278 36.3418 47.1376 36.4393 47.5603 35.7076C48.1197 34.8849 48.1815 33.8215 47.7229 32.937L46.9457 32.5467C43.9149 31.6037 45.375 36.8653 45.375 36.8653Z"
        fill="#0C0F4A"
      />
      <path
        d="M42.2098 32.134C43.3383 30.8658 45.2829 30.7552 46.5479 31.8869C48.4373 33.4901 47.7739 36.9079 46.7105 38.755C46.4146 39.2948 45.9593 39.7306 45.4098 40.007C43.9106 40.7549 42.0895 40.1468 41.3383 38.6477C40.5579 37.1323 40.9741 35.8282 41.4944 33.5519C41.605 33.0218 41.8521 32.534 42.2098 32.134Z"
        fill="#F7978D"
      />
      <path
        d="M41.1239 35.6172C41.1239 35.6172 41.4296 36.6285 42.5353 36.0887C42.3434 34.9961 42.7596 35.0253 43.0328 34.3327C43.423 33.5392 43.28 32.5994 42.6588 31.8807L41.8231 31.6368C37.8492 31.2563 41.1239 35.6172 41.1239 35.6172Z"
        fill="#0C0F4A"
      />
      <path
        opacity="0.6"
        d="M41.1239 35.6172C41.1239 35.6172 41.4296 36.6285 42.5353 36.0887C42.3434 34.9961 42.7596 35.0253 43.0328 34.3327C43.423 33.5392 43.28 32.5994 42.6588 31.8807L41.8231 31.6368C37.8492 31.2563 41.1239 35.6172 41.1239 35.6172Z"
        fill="#0C0F4A"
      />
      <path
        d="M45.0682 29.3719C44.6617 29.2191 44.1739 29.3036 43.8455 29.5898C43.6276 29.7784 43.4162 30.0678 43.1333 30.0126C42.9902 29.9833 42.8829 29.8695 42.7626 29.7849C42.304 29.4597 41.6439 29.5996 41.2016 29.941C40.7594 30.2857 40.4797 30.7898 40.187 31.2711C39.8943 31.7491 39.5594 32.2336 39.0586 32.4905C39.9561 32.9263 41.0423 32.9523 41.9593 32.5621C41.7024 32.8775 41.3382 33.1051 40.9447 33.1995C41.852 33.4629 42.8569 33.3816 43.7089 32.9751C44.1934 32.7442 44.6422 32.4092 45.1723 32.3182C45.7023 32.2271 46.356 32.5133 46.4047 33.0466C46.4795 32.7669 46.8633 32.6726 47.1234 32.7962C47.3836 32.9198 47.5494 33.1864 47.6893 33.4401C47.8291 33.6938 47.9754 33.9637 48.2258 34.1133C48.473 34.2628 48.86 34.2303 48.9868 33.9702C48.5998 33.9539 48.2583 33.6092 48.2421 33.2222C48.525 33.3816 48.8632 33.4368 49.1819 33.3751C48.7559 33.3458 47.9299 31.8369 47.7998 31.4304C47.673 31.0239 47.4779 30.6142 47.1267 30.3735C46.7332 30.1036 46.3332 30.5751 45.9592 30.3735C45.6015 30.1817 45.4909 29.5313 45.0682 29.3719Z"
        fill="#0C0F4A"
      />
      <path
        d="M45.5078 37.5491C45.7745 37.7182 46.1029 37.7637 46.4053 37.6694C46.1875 37.0775 46.0541 36.4597 46.0021 35.832L45.5078 37.5491Z"
        fill="#D9796F"
      />
      <path
        d="M41.2536 34.0431C41.8064 34.2317 42.0211 35.1552 41.9983 35.7373C41.969 36.3845 41.543 36.7877 41.065 36.378C40.6975 36.03 40.4601 35.5682 40.3951 35.0674C40.3073 34.4333 40.7008 33.8577 41.2536 34.0431Z"
        fill="#F7978D"
      />
      <path
        d="M44.9263 35.0708C45.0434 35.1099 45.0922 35.3082 45.0401 35.4741C44.9913 35.6399 44.8515 35.7537 44.7344 35.7147C44.6174 35.6757 44.5816 35.5001 44.6206 35.3147C44.6727 35.1326 44.806 35.0318 44.9263 35.0708Z"
        fill="#0C0F4A"
      />
      <path
        d="M46.9795 35.6325C47.0966 35.6715 47.1486 35.8504 47.0933 36.0325C47.0381 36.2146 46.9015 36.3317 46.7844 36.2927C46.6673 36.2536 46.6153 36.0715 46.6706 35.8894C46.7259 35.7106 46.8624 35.5935 46.9795 35.6325Z"
        fill="#0C0F4A"
      />
      <path
        d="M45.3021 38.5693C45.3313 38.5758 45.3606 38.553 45.3671 38.5238C45.3703 38.4945 45.3508 38.4652 45.3216 38.4587C44.8435 38.3937 44.4208 38.1108 44.1801 37.6913C44.1671 37.6653 44.1346 37.6523 44.1053 37.6653C44.0793 37.6783 44.0663 37.7108 44.0793 37.7401C44.2939 38.1303 44.6582 38.4132 45.0874 38.527C45.1557 38.54 45.2305 38.5498 45.3021 38.5693Z"
        fill="#0C0F4A"
      />
      <path
        d="M46.9077 34.8558C46.9175 34.8916 46.9435 34.9208 46.976 34.9371C47.2524 35.0217 47.4736 35.2298 47.5776 35.4997C47.5939 35.5582 47.6524 35.594 47.7077 35.5777C47.711 35.5777 47.711 35.5777 47.7142 35.5777C47.7728 35.5582 47.8053 35.4997 47.789 35.4412C47.6687 35.0997 47.3923 34.8395 47.0476 34.7322C46.989 34.7127 46.9272 34.7452 46.9045 34.8038C46.9012 34.8168 46.9012 34.8363 46.9077 34.8558Z"
        fill="#0C0F4A"
      />
      <path
        d="M44.5385 34.2732C44.5678 34.2959 44.6068 34.3024 44.6426 34.2927C44.9125 34.1919 45.2149 34.2179 45.4621 34.3642C45.5109 34.4 45.5759 34.3935 45.6149 34.3447L45.6182 34.3415C45.654 34.2927 45.641 34.2244 45.5954 34.1854C45.2897 33.9935 44.9125 33.9545 44.5743 34.0813C44.5158 34.1041 44.4865 34.1658 44.506 34.2244C44.5125 34.2439 44.5223 34.2602 44.5385 34.2732Z"
        fill="#0C0F4A"
      />
      <path
        d="M26.9613 62.5683L26.5385 62.5195C25.5435 62.9976 26.6231 64.8382 26.6231 64.8382L29.5498 64.4805L28.7011 63.4886C28.2588 62.9748 27.6377 62.6464 26.9613 62.5683Z"
        fill="#F7978D"
      />
      <path
        d="M29.6415 65.7455L29.5439 64.4805L26.6172 64.8382L26.7798 66.3373C26.783 66.3601 26.7863 66.3796 26.7928 66.4024C26.8546 66.6398 27.0985 66.7828 27.3359 66.7211L29.3065 66.204C29.5147 66.1487 29.6545 65.9569 29.6415 65.7455Z"
        fill="#F7978D"
      />
      <path
        d="M44.0114 57.2679C44.0114 57.2679 42.9935 59.7166 42.5447 59.3784C42.096 59.0402 41.0618 57.1671 41.0586 57.1281C41.0586 57.063 42.3301 45.9219 42.3301 45.9219L42.9382 45.9739C42.6878 47.2259 44.0114 57.2679 44.0114 57.2679Z"
        fill="#4F55F0"
      />
      <path
        d="M27.2523 51.2085C26.0361 52.9483 25.7467 61.1106 26.0654 63.2081C26.5564 62.6163 27.5255 62.4927 28.1499 62.948C28.1791 61.5106 29.3433 56.3758 29.8441 54.2328C29.984 53.6279 30.3384 53.0979 30.8425 52.7336C32.9888 51.176 36.8748 48.1029 37.3008 46.8899C37.9935 44.9322 37.3984 40.9388 35.6749 42.1681C34.0489 43.3323 28.2181 49.8231 27.2523 51.2085Z"
        fill="#0C0F4A"
      />
      <path
        d="M124.557 10.0688C124.557 10.0688 124.283 10.0135 123.877 9.84764C123.467 9.68829 122.94 9.41838 122.394 9.15498C121.848 8.89157 121.327 8.75824 120.944 8.71922C120.557 8.67694 120.297 8.70946 120.258 8.61841C120.222 8.54036 120.449 8.32898 120.921 8.24118C121.386 8.15012 122.059 8.23793 122.687 8.54361C123.314 8.84929 123.75 9.26879 124.062 9.56472C124.368 9.86715 124.557 10.0688 124.557 10.0688Z"
        fill="#4F55F0"
      />
      <path
        d="M126.943 8.1452C126.943 8.1452 126.751 8.22 126.452 8.33707C126.16 8.45739 125.789 8.62324 125.379 8.80535C124.556 9.16631 123.857 9.54354 123.743 9.38094C123.691 9.30615 123.799 9.12079 124.033 8.89315C124.267 8.66551 124.638 8.3891 125.112 8.18423C125.587 7.9761 126.082 7.95334 126.416 8.00212C126.586 8.02813 126.719 8.06065 126.81 8.09317C126.894 8.12569 126.943 8.1452 126.943 8.1452Z"
        fill="#4F55F0"
      />
      <path
        d="M120.124 11.4928C120.124 11.4928 120.075 11.4636 119.987 11.4115C119.903 11.3563 119.779 11.2847 119.626 11.2034C119.321 11.0376 118.898 10.8457 118.423 10.6799C117.467 10.3384 116.609 10.4132 116.583 10.2669C116.57 10.2018 116.778 10.0912 117.148 10.0392C117.516 9.98719 118.046 10.0132 118.586 10.1986C119.129 10.3872 119.542 10.7254 119.783 11.0018C119.903 11.1416 119.991 11.2619 120.043 11.353C120.095 11.4408 120.124 11.4928 120.124 11.4928Z"
        fill="#4F55F0"
      />
      <path
        d="M121.064 9.51377C121.064 9.51377 120.928 9.59507 120.723 9.71214C120.518 9.82596 120.251 9.97555 119.959 10.1414C119.383 10.4568 118.937 10.8276 118.827 10.7267C118.778 10.6812 118.827 10.5284 118.973 10.3268C119.12 10.1284 119.373 9.88124 119.715 9.69588C120.056 9.51052 120.411 9.46174 120.661 9.46499C120.911 9.4715 121.064 9.51377 121.064 9.51377Z"
        fill="#4F55F0"
      />
      <path
        d="M119.332 8.39297C119.332 8.39297 119.007 8.27915 118.513 8.07103C118.015 7.8629 117.349 7.56697 116.62 7.32633C115.892 7.08569 115.199 7.02715 114.701 7.05967C114.201 7.08894 113.882 7.18325 113.836 7.09544C113.794 7.0239 114.054 6.75724 114.617 6.59139C115.17 6.41904 116.002 6.40928 116.832 6.6857C117.661 6.96211 118.279 7.43364 118.692 7.78485C119.105 8.13932 119.332 8.39297 119.332 8.39297Z"
        fill="#4F55F0"
      />
      <path
        d="M121.529 5.59978C121.529 5.59978 121.464 5.61929 121.344 5.65181C121.227 5.68108 121.058 5.73311 120.859 5.7949C120.459 5.92172 119.939 6.12334 119.389 6.37374C118.277 6.87129 117.562 7.60948 117.406 7.48916C117.334 7.43388 117.428 7.16397 117.711 6.81276C117.991 6.46155 118.479 6.04855 119.107 5.75913C119.737 5.4697 120.381 5.41767 120.824 5.4567C121.048 5.47621 121.224 5.50873 121.344 5.5445C121.464 5.58027 121.529 5.59978 121.529 5.59978Z"
        fill="#4F55F0"
      />
      <path
        d="M46.2051 18.2758C46.2051 18.2758 45.9319 18.2205 45.5254 18.0547C45.1157 17.8953 44.5888 17.6254 44.0425 17.362C43.4962 17.0986 42.9759 16.9653 42.5922 16.9262C42.2052 16.884 41.945 16.9165 41.906 16.8254C41.8702 16.7474 42.0979 16.536 42.5694 16.4482C43.0344 16.3572 43.7076 16.445 44.3352 16.7506C44.9628 17.0563 45.3986 17.4758 45.7108 17.7718C46.0165 18.0742 46.2051 18.2758 46.2051 18.2758Z"
        fill="#4F55F0"
      />
      <path
        d="M48.5917 16.3561C48.5917 16.3561 48.3998 16.4309 48.1006 16.548C47.808 16.6683 47.4373 16.8342 47.0275 17.0163C46.2048 17.3773 45.5056 17.7545 45.3918 17.5919C45.3397 17.5171 45.4471 17.3317 45.6845 17.1041C45.9186 16.8765 46.2893 16.6 46.7641 16.3952C47.2389 16.187 47.7332 16.1643 48.0681 16.2131C48.2372 16.2391 48.3706 16.2716 48.4616 16.3041C48.5429 16.3366 48.5917 16.3561 48.5917 16.3561Z"
        fill="#4F55F0"
      />
      <path
        d="M41.7763 19.7116C41.7763 19.7116 41.7275 19.6823 41.6397 19.6303C41.5552 19.575 41.4316 19.5035 41.2788 19.4222C40.9731 19.2563 40.5503 19.0645 40.0756 18.8986C39.1195 18.5571 38.261 18.6319 38.235 18.4856C38.2219 18.4206 38.4301 18.31 38.8008 18.258C39.1683 18.2059 39.6983 18.232 40.2381 18.4173C40.7812 18.6059 41.1942 18.9441 41.4349 19.2238C41.5552 19.3636 41.643 19.484 41.695 19.575C41.747 19.6628 41.7763 19.7116 41.7763 19.7116Z"
        fill="#4F55F0"
      />
      <path
        d="M42.7127 17.7325C42.7127 17.7325 42.5761 17.8138 42.3712 17.9309C42.1663 18.0447 41.8997 18.1943 41.607 18.3601C41.0314 18.6756 40.5859 19.0463 40.4753 18.9455C40.4265 18.9 40.4753 18.7471 40.6217 18.5455C40.768 18.3471 41.0217 18.1 41.3631 17.9146C41.7046 17.7293 42.059 17.6805 42.3094 17.6837C42.5598 17.687 42.7127 17.7325 42.7127 17.7325Z"
        fill="#4F55F0"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11019_30227"
        x1="61.2252"
        y1="33.0219"
        x2="55.9885"
        y2="17.6655"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DAE3FE" />
        <stop offset="1" stop-color="#E9EFFD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11019_30227"
        x1="127.6"
        y1="19.6265"
        x2="121.853"
        y2="6.8829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DAE3FE" />
        <stop offset="1" stop-color="#E9EFFD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_11019_30227"
        x1="155.374"
        y1="113.05"
        x2="101.324"
        y2="28.4043"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DAE3FE" />
        <stop offset="1" stop-color="#E9EFFD" />
      </linearGradient>
      <clipPath id="clip0_11019_30227">
        <rect
          width="162.597"
          height="120"
          fill="white"
          transform="translate(0.703125 0.796875)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LimitedCompanyIcon",
};
</script>
