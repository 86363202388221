import { reactive } from "vue";

export default {
  userWorkstationMessages: reactive([]),
  chatMessagesRooms: reactive([]),
  filterMessagesRooms: reactive([]),
  NetworkDirectMessage: null,
  currentMessageType: "chat",
  projectChatRequests: null,
  krystalConversation: reactive([]),
  chatBoxRooms: reactive([]),
};
