<template>
  <GeneralDialog
    class="tw-max-w-[560px] tw-min-w-[300px] rmd:tw-min-w-[560px]"
    @on-close="onCloseModal"
  >
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <Text
            class="!tw-text-[24px] tw-w-[80%]"
            textAlign="center"
            lineHeight="28px"
          >
            Earn iknowa
            <span
              ><img
                :src="require('@/assets/images/iknowa-coin.png')"
                alt="site visit"
            /></span>
            credit’s for site visits.
          </Text>
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-h-auto"
          >
            <img
              :src="require('@/assets/images/earn-credit-thumbnail.svg')"
              alt="site visit"
            />
          </div>

          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textAlign="center"
            textColor="rgba(12, 15, 74, 0.5)"
            textWeight="500"
          >
            Once the site visit is accepted, you will be able to earn iknowa
            credits for your site visit, by correcting or confirming property
            data records.
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <!-- <Button variant="tertiary" label="close" @click="onCloseModal" /> -->
        <Button label="Done" @click="onCloseModal" />
        <Text variant="span" textColor="#FAA500" textAlign="center"
          >Terms & Conditions
          <Text
            variant="span"
            textWeight="400"
            textColor="rgba(12, 15, 74, 0.6)"
            >Apply</Text
          ></Text
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close", "on-continue"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const onCloseModal = () => {
  emits("on-close");
};

const onContinue = () => {
  emits("on-continue");
};
</script>
