<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <TrashIcon
            fillOpacity="1"
            fill="#F45454"
            class="tw-w-[27px] tw-h-[35px]"
          />
          <Text variant="h4" textAlign="center"
            >Are you sure you want to delete this card?</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          :disabled="state.loading"
          label="Cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          :disabled="state.loading"
          :isLoading="state.loading"
          label="Delete"
          :isActive="true"
          @click="onDelete"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import { USER_STORE } from "@/store/modules/user";

const props = defineProps(["loading", "selectedAtmCard"]);
const emits = defineEmits(["on-close", "on-success", "on-delete"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onCloseModal = () => {
  emits("on-close");
};

const onDelete = async () => {
  try {
    state.loading = true;
    const userId = user.value.id;
    const payload = {
      userId,
      id: props.selectedAtmCard.id,
    };
    const response = await store.dispatch(
      `${WORKSTATION_SETTING}/deleteAtmCard`,
      payload
    );
    console.log(response, "delete resonse");
  } catch (error) {
    console.log(error);
  } finally {
    state.loading = false;
    emits("on-success", true);
  }
};
</script>
