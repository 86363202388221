import moment from "moment";

import { DATE_FORMAT } from "@/core/constants";

export default {
  formatToHumanDate: (value: number) => {
    return moment.unix(value).format("DD/MM/YYYY");
  },
  /**
   * Formats standard date for the app
   * @param value
   * @returns
   */
  formatToDate: (value: string, customFormat = "") => {
    const currentDate = moment(value);
    let dateFormat = DATE_FORMAT;

    if (!currentDate.isValid) {
      throw new Error(`Invalid date: ${value}`);
    }

    if (customFormat) {
      dateFormat = customFormat;
    }

    return currentDate.format(dateFormat);
  },

  /**
   * Date time format
   * @param value
   * @param format
   */
  formatToDatetime: (value: string, format: string) => {
    return moment(value).format(format || "DD/MM/YYYY HH:mm");
  },

  formatToDatetimeWithMeridian: (value: string, format: string) => {
    return moment(value).format(format || "DD/MM/YYYY HH:mm A");
  },

  /**
   * Date time format with time ago
   * @param value
   */
  formatToDatetimeAgo: (value: string) => {
    return moment(value).fromNow();
  },

  rawCurrentDateTime() {
    return moment();
  },
};
