<template>
  <GeneralDialog
    headerTitle="Image Upload"
    @on-close="onCloseModal"
    width="400px"
  >
    <template #body>
      <!-- <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-w-full tw-max-w-full tw-h-[200px]"
      > -->
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-w-full tw-max-w-full"
      >
        <CommonLoader :loading="loading" class="tw-absolute" />
        <v-form
          :rules="validation"
          ref="folderNameFormRef"
          class="tw-w-full"
          v-if="!loading"
        >
          <div v-if="!isEdit" class="tw-h-full">
            <img
              v-if="form.attachments[0]?.publicUrl"
              :src="form.attachments[0]?.publicUrl"
              class="tw-h-full tw-w-full tw-object-cover"
            />
            <span v-else> No Image </span>
            <span
              @click="editPropertyImg"
              class="tw-cursor-pointer tw-px-[10px] tw-py-[4px] tw-rounded-md tw-bg-white tw-absolute tw-w-[120px] tw-flex tw-items-center tw-top-[80px] tw-right-[30px] tw-text-sm tw-font-normal tw-z-10 tw-text-[#0C0F4A80] tw-leading-[13.99px] tw-gap-1"
            >
              <div
                class="tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-[#4A92E5] tw-flex tw-items-center tw-justify-center"
              >
                <img
                  src="@/assets/icons/edit-pen-icon.svg"
                  class="edit_icon tw-w-[8px] tw-h-[8px]"
                  style="
                    filter: invert(93%) sepia(93%) saturate(0%)
                      hue-rotate(265deg) brightness(105%) contrast(108%);
                  "
                />
              </div>
              Edit Image
            </span>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full" v-if="isEdit">
            <UploadForm
              class="!tw-w-full"
              listType="picture"
              :record="form"
              :allowMultiple="false"
              :attachmentSources="[]"
              :useCustomUploadIcon="true"
              @on-progress-upload="onProgressUpload"
              @on-upload-success="updateFileOnSuccess"
              :isGallery="props.isGallery"
              @on-handle-error="onHandleError"
              :forDocuments="$props.forDocuments"
            />
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3"
        v-if="!loading && isEdit"
      >
        <Button
          :disabled="form.isUploading || !form.attachments.length"
          :isLoading="form.isLoading"
          label="Save"
          :isActive="hasAttachments"
          @click="onSave"
        />
        <Button
          label="Cancel"
          variant="tertiary"
          :disabled="form.isUploading"
          @click="onCloseModal"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, onMounted, ref, reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import UploadForm from "@/core/components/forms/Upload.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { USER_STORE } from "@/store/modules/user";
import { PROPERTY_STORE } from "@/store/modules/property";

import $axios from "@/core/utils/axios-api-config";
import { getImageApiUrl, getImageStringToImageURL } from "@/core/utils/common";
import propertyService from "@/core/services/property.service";
import CommonLoader from "@/core/components/CommonLoader.vue";
const internalInstance = getCurrentInstance();
const loading = ref(false);
const emits = defineEmits(["on-close", "on-success", "on-success-gallery"]);
const props = defineProps({
  forDocuments: {
    type: Boolean,
    default: false,
  },
  isGallery: Boolean,
  isPropertyDocument: Boolean,
});

const store = useStore();

const form = reactive({
  attachments: [],
  disableButton: false,
  isReadyToSubmit: false,
  formValue: {},
  isSubmitting: false,
  isUploading: false,
  isLoading: false,
});
const isEdit = ref(false);
const hasAttachments = computed(() => {
  return form.attachments?.length > 0;
});

const onCloseModal = () => {
  form.attachments = [];
  if (!form.isUploading) {
    emits("on-close");
  }
};
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const onProgressUpload = () => {
  form.isUploading = true;
  form.isLoading = true;
};

const updateFileOnSuccess = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const onHandleError = () => {
  form.isUploading = false;
  form.isLoading = false;
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const onSave = async () => {
  try {
    form.isLoading = true;
    const propertyImgAttachment = form.attachments[0];
    if (!propertyImgAttachment) {
      displayToast("property Image Not Found", "error");
      return;
    }

    const propertyId = propertyDetails.value?.id;
    const updatedData = await propertyService.updatePropertyCategory(
      {
        propertyImage: propertyImgAttachment?.attachment,
      },
      propertyId
    );

    const data = {
      ...updatedData,
      publicUrl:
        updatedData?.propertyImage &&
        (await getImageStringToImageURL(updatedData?.propertyImage)),
    };

    store.dispatch(`${PROPERTY_STORE}/setPropertyDetails`, {
      localData: data,
    });

    displayToast("Property Images updated.", "success");
  } catch (error) {
    console.log(error);
    displayToast("Something went wrong.", "error");
  } finally {
    form.isLoading = false;
    onCloseModal();
  }
};
const editPropertyImg = () => {
  form.attachments = [];
  isEdit.value = !isEdit.value;
};
onMounted(async () => {
  // form.attachments = [];

  const propertyImg = propertyDetails.value.propertyImage;
  if (propertyImg) {
    try {
      loading.value = true;
      const imageUrl = getImageApiUrl(propertyImg, true);
      const response = await $axios.get(imageUrl);
      if (response) {
        const blob = new Blob([new Uint8Array(response.Body.data)]);
        const mimeType = blob.type;
        const size = blob.size;

        form.attachments = [
          {
            size,
            mimeType,
            publicUrl: response.publicUrl,
          },
        ];
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  } else {
    isEdit.value = true;
  }
});
</script>
