<template>
  <div id="callFrameRef" class="call-frame call-frame-fullscreen">
    <Loader :show="isLoading" v-if="isLoading" />
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, onUnmounted } from "vue";
import DailyIframe from "@daily-co/daily-js";
import VideoService from "@/core/services/video.service";

import Loader from "@/core/components/common/Loader.vue";
export default {
  components: {
    Loader,
  },
  props: {
    roomDetails: {
      type: Object,
      default: null,
      required: true,
    },
    isDirectJoinVideoCall: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const callFrameRef = ref<HTMLElement | null>(null);
    let callFrame: any = null;
    const participantsCount = ref(0);
    const isLoading = ref(true);
    const onClickVideoCallButton = async () => {
      callFrameRef.value = document.getElementById("callFrameRef");

      if (!callFrame) {
        callFrame = DailyIframe.createFrame(callFrameRef.value as HTMLElement, {
          showLeaveButton: true,
          showParticipantsBar: false,
        });

        callFrame.on("left-meeting", leaveCall);
        callFrame.on("participant-joined", handleParticipantStateChange);
        callFrame.on("participant-left", handleParticipantStateChange);
        let response;
        if (!props.isDirectJoinVideoCall) {
          try {
            response = (await VideoService.createRoom({
              roomTitle: props.roomDetails.title,
              type: props.roomDetails.type,
            })) as any;
          } catch (error) {
            console.log("error", error);
          } finally {
            isLoading.value = false;
          }
        } else {
          isLoading.value = false;
        }

        if (response?.url || props.roomDetails?.url) {
          const url = response?.url || props.roomDetails?.url;
          const token = response?.token || props.roomDetails?.token;
          callFrame
            .join({
              url,
              token,
            })
            .catch((error: any) => {
              callFrame = null;
              console.error("Failed to join the call:", error);
            });
        }
      }
    };

    ctx.expose({ onClickVideoCallButton });

    const handleParticipantStateChange = () => {
      const participants = callFrame.participants();
      participantsCount.value = Object.keys(participants).length;
    };

    const leaveCall = async (event: any) => {
      if (callFrame) {
        callFrame.leave().catch((error: any) => {
          console.error("Failed to leave the call:", error);
        });
        callFrame.destroy();
        callFrame = null;
      }
      if (participantsCount.value === 1 || participantsCount.value === 0) {
        await VideoService.deleteRoom(props.roomDetails.title);
      }
      ctx.emit("leaveCall");
    };

    onUnmounted(() => {
      if (callFrame) {
        callFrame.off("participant-joined", handleParticipantStateChange);
        callFrame.off("participant-left", handleParticipantStateChange);
      }
    });

    return { onClickVideoCallButton, isLoading };
  },
};
</script>
