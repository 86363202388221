<template>
  <ChipState :label="props.label" textColor="#FFF" bgColor="#F45454">
    <template #prefix>
      <InfoIconWhite />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import InfoIconWhite from "@/core/components/icons/InfoIconWhite";

const props = defineProps({
  label: {
    type: String,
    default: "Emergency Job",
  },
});
</script>
<script>
export default {
  name: "EmergencyChip",
};
</script>
