<template>
  <GeneralDialog
    class="tw-max-w-[620px]"
    headerTitle="Out of Hours"
    @on-close="onCloseDetailsModal"
    ><template #body>
      <div class="emergency-outhr-details-modal" v-if="selectedEOHEventDetails">
        <div class="emergency-outhr-details-modal__item">
          <div class="emergency-outhr-details-modal__title">
            <p>Issue Name</p>
          </div>
          <v-text-field
            class="custom_input"
            variant="outlined"
            :value="selectedEOHEventDetails.title"
            :disabled="true"
          ></v-text-field>
        </div>

        <v-divider color="#C5D2F2" class="border-opacity-100"></v-divider>

        <div class="emergency-outhr-details-modal__item">
          <div class="emergency-outhr-details-modal__title">
            <p>Project notes</p>
            <v-icon
              color="#0C0F4A"
              icon="mdi-information-variant-circle-outline"
            />
          </div>
          <div class="emergency-outhr-details-modal__text">
            <p v-html="selectedEOHEventDetails.description"></p>
          </div>
        </div>

        <div class="emergency-outhr-details-modal__item">
          <div class="emergency-outhr-details-modal__title">
            <p>Select Specialisms</p>
          </div>
          <div class="emergency-outhr-details-modal__specialisms">
            <div class="emergency-outhr-details-modal__specialisms-inner">
              <template
                v-for="(
                  specialism, index
                ) in selectedEOHEventDetails.EOHSpecialism"
              >
                <div
                  class="emergency-outhr-details-modal__specialisms-item"
                  :key="specialism.id"
                  v-if="index < 3"
                >
                  <div class="emergency-outhr-details-modal__specialisms-icon">
                    <img
                      :src="
                        require('@/assets/category-icons/' +
                          specialism.subCategories.category.iconName +
                          '.svg')
                      "
                    />
                  </div>
                  <p>{{ specialism.subCategories.label }}</p>
                </div>
              </template>
            </div>
            <div
              class="more-items-tag"
              v-if="selectedEOHEventDetails.EOHSpecialism.length >= 3"
            >
              {{ selectedEOHEventDetails.EOHSpecialism.length - 3 }}+
            </div>
          </div>
        </div>

        <div class="emergency-outhr-details-modal__item">
          <div class="emergency-outhr-details-modal__title">
            <p>Date and time</p>
            <v-icon
              color="#0C0F4A"
              icon="mdi-information-variant-circle-outline"
            />
          </div>

          <div class="emergency-outhr-details-modal__date-time">
            <div class="emergency-outhr-details-modal__date-time-item">
              <div class="emergency-outhr-details-modal__date-time-item-left">
                Weekdays
              </div>
              <div class="emergency-outhr-details-modal__date-time-item-right">
                {{ showWeekDays() }}
              </div>
            </div>
            <div class="emergency-outhr-details-modal__date-time-item">
              <div class="emergency-outhr-details-modal__date-time-item-left">
                Time Range
              </div>
              <div
                class="emerg return daysency-outhr-details-modal__date-time-item-right"
              >
                {{ selectedEOHEventDetails.fromTimeAndDay.time }}
                {{ selectedEOHEventDetails.fromTimeAndDay.day }} -
                {{ selectedEOHEventDetails.toTimeAndDay.time }}
                {{ selectedEOHEventDetails.toTimeAndDay.day }}
              </div>
            </div>
          </div>
        </div>

        <div class="emergency-outhr-details-modal__item">
          <div class="emergency-outhr-details-modal__title">
            <p>SLA Time (Manual Intervention)</p>
            <v-icon
              color="#0C0F4A"
              icon="mdi-information-variant-circle-outline"
            />
          </div>
          <div class="emergency-outhr-details-modal__text">
            <p>{{ slaTime.hours }} HH : {{ slaTime.minutes }} MM</p>
          </div>
        </div>

        <div class="emergency-outhr-details-modal__item">
          <div class="emergency-outhr-details-modal__title">
            <p>Preferred Contractor</p>
            <v-icon
              color="#0C0F4A"
              icon="mdi-information-variant-circle-outline"
            />
          </div>
          <div class="emergency-outhr-details-modal__contractor">
            <div class="emergency-outhr-details-modal__contractor-inner">
              <template v-for="(contractor, index) in preferredContractor">
                <div
                  class="emergency-outhr-details-modal__contractor-item"
                  v-if="index < 3"
                  :key="contractor.id"
                >
                  <div class="emergency-outhr-details-modal__contractor-image">
                    <img :src="contractor.userWorkstation.publicURL" />
                  </div>
                  <p>{{ contractor.userWorkstation.name }}</p>
                </div>
              </template>
            </div>

            <div class="more-items-tag" v-if="preferredContractor.length >= 3">
              {{ preferredContractor.length - 3 }}+
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="emergency-outhr-details-modal__footer">
        <v-btn class="button button-purple-border" @click="onClickBackButton"
          >Back</v-btn
        ><v-btn class="button button-orange" @click="onClickNextButton"
          >Next</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { ref, onMounted, computed } from "vue";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
export default {
  props: {
    selectedEOHEventDetails: {
      type: Object,
      default: null,
    },
  },
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const preferredContractor = ref([]) as any;

    const onCloseDetailsModal = () => {
      ctx.emit("onCloseDetailsModal");
    };

    const onClickBackButton = () => {
      ctx.emit("onClickBackButton");
    };

    const onClickNextButton = () => {
      ctx.emit("onClickNextButton", props.selectedEOHEventDetails?.id);
    };

    const allDays: any = computed(() => {
      return {
        1: "Sun",
        2: "Mon",
        3: "Tue",
        4: "Wed",
        5: "Thu",
        6: "Fri",
        7: "Sat",
      };
    });

    const showWeekDays = () => {
      let days = "";
      props.selectedEOHEventDetails.days.forEach(
        (day: number, index: number) => {
          if (index === props.selectedEOHEventDetails.days.length - 2) {
            days += `${allDays.value[day]} and `;
          } else {
            days += `${
              index === props.selectedEOHEventDetails.days.length - 1
                ? allDays.value[day]
                : allDays.value[day] + ", "
            }`;
          }
        }
      );
      return days;
    };

    const slaTime = computed(() => {
      const hours = Math.floor(props.selectedEOHEventDetails?.SLATime / 60);
      const minutes = props.selectedEOHEventDetails?.SLATime - hours * 60;

      return { hours, minutes };
    });
    onMounted(async () => {
      if (props.selectedEOHEventDetails) {
        preferredContractor.value =
          props.selectedEOHEventDetails.EOHPreferredContractor;
        try {
          for (const contractor of preferredContractor.value) {
            if (contractor.userWorkstation.profileImage !== null) {
              const imageUrl = getImageApiUrl(
                contractor.userWorkstation.profileImage,
                true
              );
              const encoded: any = await $axios.get(imageUrl);
              contractor.userWorkstation.publicURL = encoded?.publicUrl;
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    });

    return {
      onCloseDetailsModal,
      onClickBackButton,
      onClickNextButton,
      preferredContractor,
      slaTime,
      showWeekDays,
    };
  },
};
</script>

<style lang="scss" scoped>
.emergency-outhr-details-modal {
  width: 522px;
  padding-top: 24px;
  border-top: 1px solid #c5d2f2;
  color: $blueDark;
  display: grid;
  grid-gap: 28px;
  @include fluidFont(16, 12, 24px);

  @include respond(s720) {
    width: 100%;
    line-height: 18px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @include fluidFont(16, 16, 20px);
    font-weight: 600;
    margin-bottom: 16px;

    .mdi {
      flex-shrink: 0;
      font-size: 20px;
    }
  }

  &__specialisms {
    display: flex;
    align-items: center;
    gap: 20px;

    @include respond(s720) {
      gap: 10px 8px;
    }

    &-inner {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 20px;

      @include respond(s720) {
        gap: 10px 8px;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 6px;
      align-items: center;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-icon {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: $blueDark;
      display: flex;
      align-self: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        object-fit: contain;
        max-width: 8px;
        filter: invert(100%) sepia(0%) saturate(3162%) hue-rotate(145deg)
          brightness(103%) contrast(93%);
      }
    }
  }

  &__date-time {
    background-color: #f1f6fb;
    border-radius: 8px;
    padding: 20px 16px;
    display: grid;
    grid-gap: 40px;

    @include respond(s720) {
      padding: 16px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      &-right {
        text-align: right;
        font-weight: 600;
        flex-shrink: 0;
      }
    }
  }

  &__contractor {
    display: flex;
    align-items: center;
    gap: 20px;

    @include respond(s720) {
      gap: 10px;
    }

    &-inner {
      display: flex;
      align-items: center;
      gap: 20px;

      @include respond(s720) {
        gap: 10px;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 6px;
      align-items: center;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-image {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 12px;
  }

  :deep(.custom_input) {
    .v-input__control {
      .v-field {
        border: 1px solid rgba($buttonText, 0.4);
        border-radius: 8px;
        .v-field__field {
          .v-field__input {
            padding: 8px 16px;
            min-height: 48px;
            @include fluidFont(16, 12, 1.2);
            font-weight: 400;
            letter-spacing: 0.15px;
            color: rgba($blueDark, 1);
            opacity: 1;
          }
          &::placeholder {
            @include fluidFont(16, 12, 1.2);
            font-weight: 400;
            letter-spacing: 0.15px;
            color: rgba($blueDark, 1);
            opacity: 1;
          }
        }
        .v-field__outline {
          display: none;
        }
      }
    }
  }

  .more-items-tag {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: #dde2e7;
    border-radius: 100%;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
