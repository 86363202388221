import $axios from "../utils/axios-api-config";

export default {
  addEmail(data: { email: string }) {
    return $axios.post("/user/manage-emails/create-alias", data);
  },
  confirmEmailRequest(data: { key: string }) {
    return $axios.put("/user/manage-emails/accept", data);
  },
  getEmailList(userId: number) {
    return $axios.get(`users/${userId}/alias-emails`);
  },
  removeEmail(emailRecordId: number) {
    return $axios.delete(`user/manage-emails/${emailRecordId}`);
  },
};
