<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="29" height="29" rx="4" fill="#7467B7" />
    <path
      d="M17.741 19.9083C18.066 20.2333 18.591 20.2333 18.916 19.9083L22.741 16.0833C23.066 15.7583 23.066 15.2333 22.741 14.9083L18.916 11.0833C18.591 10.7583 18.066 10.7583 17.741 11.0833C17.4243 11.4083 17.416 11.9417 17.741 12.2667L20.141 14.6667H10.8327C10.3743 14.6667 9.99935 15.0417 9.99935 15.5C9.99935 15.9583 10.3743 16.3333 10.8327 16.3333H20.141L17.741 18.7333C17.416 19.0583 17.416 19.5833 17.741 19.9083ZM7.49935 20.5C7.95768 20.5 8.33268 20.125 8.33268 19.6667V11.3333C8.33268 10.875 7.95768 10.5 7.49935 10.5C7.04102 10.5 6.66602 10.875 6.66602 11.3333V19.6667C6.66602 20.125 7.04102 20.5 7.49935 20.5Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "NotStartedIcon",
};
</script>
