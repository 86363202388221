<template>
  <GeneralDialog
    @on-close="onCloseModal"
    :headerTitle="props.isEdit ? 'Edit card' : 'Add new card'"
    width="561px"
  >
    <template #body>
      <section class="tw-h-full tw-flex tw-flex-col tw-gap-4">
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-4"
          v-if="isEdit && existingCard"
        >
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <Text variant="span">Card Number*</Text>
            <v-text-field
              density="compact"
              v-model="cardNumber"
              variant="solo-filled"
              :disabled="true"
            ></v-text-field>
          </div>
          <div class="tw-w-full tw-flex tw-items-start tw-gap-4">
            <v-form
              :rules="validation"
              ref="editFormRef"
              class="tw-w-full tw-flex tw-items-start tw-gap-4"
            >
              <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
                <Text variant="span">Expiry Month (1-12)*</Text>
                <v-text-field
                  density="compact"
                  v-model="expiryMonth"
                  variant="outlined"
                  @input="debouncedFormatExpiryMonth"
                  :rules="validation.month"
                  maxlength="2"
                  placeholder="MM"
                ></v-text-field>
              </div>
              <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
                <Text variant="span">Expiry Year*</Text>
                <v-text-field
                  density="compact"
                  v-model="expiryYear"
                  variant="outlined"
                  :rules="validation.year"
                  maxlength="4"
                  placeholder="YYYY"
                ></v-text-field>
              </div>
            </v-form>
          </div>
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4" v-else>
          <!-- <div id="card-element"></div> -->
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <Text variant="span">Card Number*</Text>
            <div id="card-number-element" class="stripe-element"></div>
          </div>
          <div class="tw-w-full tw-flex tw-items-center tw-gap-4">
            <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
              <Text variant="span">CVC*</Text>
              <div id="card-cvc-element" class="stripe-element"></div>
            </div>
            <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
              <Text variant="span">Expiry Date*</Text>
              <div id="card-expiry-element" class="stripe-element"></div>
            </div>
          </div>
        </div>

        <div class="tw-w-full">
          <v-checkbox v-model="state.isPrimary" color="#4B4BFF">
            <template v-slot:label>
              <div class="tw-flex tw-flex-col tw-flex-items-start">
                <Text variant="span" lineHeight="18px">Set As Primary</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  lineHeight="18px"
                >
                  Scheduled payment will be automatically deducted from this
                  card.
                </Text>
              </div>
            </template>
          </v-checkbox>
        </div>
      </section>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-4 tw-border-t-solid-custom tw-box-border tw-pt-4"
      >
        <Button
          variant="secondary"
          :disabled="state.loading"
          label="cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          :disabled="state.loading"
          :isLoading="state.loading"
          label="save changes"
          :isActive="true"
          @click="handleSubmit"
        />
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import { USER_STORE } from "@/store/modules/user";

const emits = defineEmits(["on-close", "on-success", "on-add"]);
const props = defineProps(["isEdit", "selectedAtmCard"]);

const store = useStore();

const stripePublicKey =
  "pk_test_51LlAQBHMbjzNI6kGe3jDbPcIPpzvflv08XtSsXxpRKxCRbdm9Yx3oJUrY6bxqVg1Uc0pXeIxE9PHul6PAXO8N5nu00JtAaSR2f";
const stripe = ref(null);
const elements = ref(null);

const cardElement = ref(null);

const cardNumberElement = ref(null);
const cardExpiryElement = ref(null);
const cardCvcElement = ref(null);

const cardBrand = ref("");
const existingCard = ref(null);

//if edit
const cardNumber = ref("************");
const expiryYear = ref(null);
const expiryMonth = ref(null);
const editFormRef = ref(null);
const validation = ref({
  month: [
    (v) => !!v || "Expiry Month is required",
    (v) => /^\d{1,2}$/.test(v) || "Invalid Expiry Month",
  ],
  year: [
    (v) => !!v || "Expiry Year is required",
    (v) => /^\d{4}$/.test(v) || "Invalid Expiry Year",
  ],
});

const state = reactive({
  loading: false,
  isPrimary: false,
  fetchingCardDetails: false,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onCloseModal = () => {
  emits("on-close");
};

const handleSubmit = async () => {
  try {
    state.loading = true;
    const userId = user.value.id;

    if (!props.isEdit) {
      const { token, error } = await stripe.value.createToken(
        cardExpiryElement.value
      );
      if (!error && token.id) {
        console.log("Source Token ID:", token.id);
        const formData = {
          token: token.id,
          isActive: state.isPrimary,
        };
        const payload = {
          userId,
          formData,
        };
        const response = await store.dispatch(
          `${WORKSTATION_SETTING}/addAtmCard`,
          payload
        );

        console.log(response, "add card response");
        if (response) {
          emits("on-success", true);
        }
      }
    } else {
      const isFormValid = await editFormRef.value.validate();
      if (!isFormValid.valid) return;
      // const expiryDate = cardExpiryElement.value._component._state.value; // Get the expiry date value
      // console.log("Updated Expiry Date:", expiryDate);
      const cardId = props.selectedAtmCard.id;
      const formData = {
        isActive: state.isPrimary,
        expMonth: expiryMonth.value,
        expYear: expiryYear.value,
      };
      const payload = {
        userId,
        cardId,
        formData,
      };
      const response = await store.dispatch(
        `${WORKSTATION_SETTING}/editAtmCard`,
        payload
      );

      console.log(response, "edit card response");
      if (response) {
        emits("on-success", true);
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    state.loading = false;
  }
};

const formatExpiryMonth = (event) => {
  const value = event.target.value;

  // Convert value to integer
  const intValue = parseInt(value, 10);

  // Ensure month is within 1-12
  if (!isNaN(intValue) && intValue >= 1 && intValue <= 12) {
    expiryMonth.value = intValue < 10 ? `0${intValue}` : `${intValue}`; // Add leading zero if less than 10
  } else {
    // Clear expiryMonth or handle invalid input
    expiryMonth.value = ""; // Clear the value if it's not within 1-12
  }
};

function debounce(fn, delay) {
  let timeoutID;
  return function (...args) {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

// Debounced version of formatExpiryMonth
const debouncedFormatExpiryMonth = debounce(formatExpiryMonth, 300);

const formatExpiryYear = (value) => {
  // Ensure year is in 'YY' format
  if (/^\d{1,2}$/.test(value)) {
    expiryYear.value = value;
  }
};

const fetchCardDetails = async (cardId) => {
  try {
    state.fetchingCardDetails = true;
    const userId = user.value.id;
    const id = cardId;
    const payload = {
      userId,
      id,
    };
    const response = await store.dispatch(
      `${WORKSTATION_SETTING}/getAtmCard`,
      payload
    );

    existingCard.value = response;
    cardNumber.value = `************${existingCard.value.last4}`;
    expiryMonth.value =
      existingCard.value.exp_month < 10
        ? `0${existingCard.value.exp_month}`
        : `${existingCard.value.exp_month}`;
    expiryYear.value = existingCard.value.exp_year.toString();
    state.isPrimary = existingCard.value.isActive;
    console.log(existingCard.value, "existingCard");
  } catch (error) {
    console.log(error);
  } finally {
    state.fetchingCardDetails = false;
  }
};

onMounted(async () => {
  stripe.value = await loadStripe(stripePublicKey);
  elements.value = stripe.value.elements();

  const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  if (!props.isEdit) {
    // cardElement.value = elements.value.create("card", {
    //   style,
    //   hidePostalCode: true,
    // });

    // cardElement.value.mount("#card-element");

    cardNumberElement.value = elements.value.create("cardNumber", {
      style,
      disabled: props.isEdit, // Allow cardNumber to be created as not disabled
    });
    cardNumberElement.value.mount("#card-number-element");

    cardExpiryElement.value = elements.value.create("cardExpiry", {
      style,
    });
    cardExpiryElement.value.mount("#card-expiry-element");

    cardCvcElement.value = elements.value.create("cardCvc", {
      style,
      disabled: props.isEdit, // Allow cardCvc to be created as not disabled
    });

    cardCvcElement.value.mount("#card-cvc-element");

    cardNumberElement.value.on("change", (event) => {
      if (event.brand) {
        cardBrand.value = event.brand;
      } else {
        cardBrand.value = "";
      }
    });
  }

  // Fetch existing card details
  if (props.isEdit) {
    await fetchCardDetails(props.selectedAtmCard.id);
  }
});
</script>

<style lang="scss" scoped>
#card-element,
.stripe-element {
  background: #fff;
  border: 1px solid rgba(12, 15, 74, 0.2);
  border-radius: 8px;
  padding: 12px;
}
:deep(.v-input--disabled.v-text-field) {
  .v-input__control {
    height: 45px;
    border-radius: 8px;
    overflow: hidden;
    min-height: 45px;
    background: #f1f6fb;
  }
}
</style>
