<template>
  <svg
    width="198"
    height="110"
    viewBox="0 0 198 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.0509 95.0117H35.3594V108.801H42.0509V95.0117Z"
      fill="#DCEAF9"
    />
    <path
      d="M133.953 56.3445V50.3176H132.123V39.9545H122.315V50.3176H113.144V36.1135L103.023 32.283V44.3932H100.715V28.2656L90.5946 31.6192V55.8432H77.7963V70.1515H73.0135V73.7562H62.0292V67.5494H55.7226V50.6833H47.9312V81.8178H40.1406V108.911H47.9312H54.6088H55.7226H62.0292H65.5939H71.3037H73.0135H77.7963H82.288H90.5946H93.1385H98.2001H100.715H103.023H108.321H111.926H113.144H122.315H131.96H132.123H133.953H141.769V56.3445H133.953Z"
      fill="#D5E7F7"
    />
    <path
      d="M98.9325 32.8477H92.1055V35.3481H98.9325V32.8477Z"
      fill="#C4D9ED"
    />
    <path d="M98.9325 37.543H92.1055V40.0434H98.9325V37.543Z" fill="#C4D9ED" />
    <path d="M98.9325 41.7656H92.1055V44.266H98.9325V41.7656Z" fill="#C4D9ED" />
    <path
      d="M55.8645 62.0469H47.9297V65.9061H55.8645V62.0469Z"
      fill="#C4D9ED"
    />
    <path
      d="M55.7889 73.8945H47.9297V77.7538H55.7889V73.8945Z"
      fill="#C4D9ED"
    />
    <path
      d="M55.7889 79.7227H47.9297V83.5819H55.7889V79.7227Z"
      fill="#C4D9ED"
    />
    <path d="M55.66 85.8203H47.8008V89.6795H55.66V85.8203Z" fill="#C4D9ED" />
    <path d="M55.66 91.793H47.8008V95.6522H55.66V91.793Z" fill="#C4D9ED" />
    <path d="M55.66 97.8906H47.8008V101.75H55.66V97.8906Z" fill="#C4D9ED" />
    <path
      d="M111.536 41.7695H104.355V44.2699H111.536V41.7695Z"
      fill="#C4D9ED"
    />
    <path d="M111.536 37.582H104.355V40.0824H111.536V37.582Z" fill="#C4D9ED" />
    <path
      d="M111.536 46.6289H91.9336V49.1293H111.536V46.6289Z"
      fill="#C4D9ED"
    />
    <path d="M111.536 50.3906H91.9336V52.891H111.536V50.3906Z" fill="#C4D9ED" />
    <path d="M111.536 54.125H91.9336V56.529H111.536V54.125Z" fill="#C4D9ED" />
    <path
      d="M55.7889 68.0625H47.9297V71.9217H55.7889V68.0625Z"
      fill="#C4D9ED"
    />
    <path
      d="M81.4698 58.1875H79.7539V60.6054H81.4698V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M84.6768 58.1875H82.9609V60.6054H84.6768V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M87.8135 58.1875H86.0977V60.6054H87.8135V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M90.7901 58.1875H89.0742V60.6054H90.7901V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M93.8174 58.1875H92.1016V60.6054H93.8174V58.1875Z"
      fill="#C4D9ED"
    />
    <path d="M96.8839 58.1875H95.168V60.6054H96.8839V58.1875Z" fill="#C4D9ED" />
    <path
      d="M100.095 58.1875H98.3789V60.6054H100.095V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M103.232 58.1875H101.516V60.6054H103.232V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M106.204 58.1875H104.488V60.6054H106.204V58.1875Z"
      fill="#C4D9ED"
    />
    <path d="M109.235 58.1875H107.52V60.6054H109.235V58.1875Z" fill="#C4D9ED" />
    <path
      d="M112.392 58.1875H110.676V60.6054H112.392V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M115.599 58.1875H113.883V60.6054H115.599V58.1875Z"
      fill="#C4D9ED"
    />
    <path d="M118.735 58.1875H117.02V60.6054H118.735V58.1875Z" fill="#C4D9ED" />
    <path
      d="M121.712 58.1875H119.996V60.6054H121.712V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M124.743 58.1875H123.027V60.6054H124.743V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M131.017 58.1875H129.301V60.6054H131.017V58.1875Z"
      fill="#C4D9ED"
    />
    <path
      d="M134.153 58.1875H132.438V60.6054H134.153V58.1875Z"
      fill="#C4D9ED"
    />
    <path d="M137.126 58.1875H135.41V60.6054H137.126V58.1875Z" fill="#C4D9ED" />
    <path
      d="M140.157 58.1875H138.441V60.6054H140.157V58.1875Z"
      fill="#C4D9ED"
    />
    <path d="M81.4698 62.457H79.7539V64.8749H81.4698V62.457Z" fill="#C4D9ED" />
    <path d="M84.6768 62.457H82.9609V64.8749H84.6768V62.457Z" fill="#C4D9ED" />
    <path d="M87.8135 62.457H86.0977V64.8749H87.8135V62.457Z" fill="#C4D9ED" />
    <path d="M90.7901 62.457H89.0742V64.8749H90.7901V62.457Z" fill="#C4D9ED" />
    <path d="M93.8174 62.457H92.1016V64.8749H93.8174V62.457Z" fill="#C4D9ED" />
    <path d="M96.8839 62.457H95.168V64.8749H96.8839V62.457Z" fill="#C4D9ED" />
    <path d="M100.095 62.457H98.3789V64.8749H100.095V62.457Z" fill="#C4D9ED" />
    <path d="M103.232 62.457H101.516V64.8749H103.232V62.457Z" fill="#C4D9ED" />
    <path d="M106.204 62.457H104.488V64.8749H106.204V62.457Z" fill="#C4D9ED" />
    <path d="M109.235 62.457H107.52V64.8749H109.235V62.457Z" fill="#C4D9ED" />
    <path d="M112.392 62.457H110.676V64.8749H112.392V62.457Z" fill="#C4D9ED" />
    <path d="M115.599 62.457H113.883V64.8749H115.599V62.457Z" fill="#C4D9ED" />
    <path d="M118.735 62.457H117.02V64.8749H118.735V62.457Z" fill="#C4D9ED" />
    <path d="M121.712 62.457H119.996V64.8749H121.712V62.457Z" fill="#C4D9ED" />
    <path d="M124.743 62.457H123.027V64.8749H124.743V62.457Z" fill="#C4D9ED" />
    <path d="M127.806 62.457H126.09V64.8749H127.806V62.457Z" fill="#C4D9ED" />
    <path d="M131.017 62.457H129.301V64.8749H131.017V62.457Z" fill="#C4D9ED" />
    <path d="M134.153 62.457H132.438V64.8749H134.153V62.457Z" fill="#C4D9ED" />
    <path d="M137.126 62.457H135.41V64.8749H137.126V62.457Z" fill="#C4D9ED" />
    <path d="M140.157 62.457H138.441V64.8749H140.157V62.457Z" fill="#C4D9ED" />
    <path
      d="M81.4698 66.6562H79.7539V69.0741H81.4698V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M84.6768 66.6562H82.9609V69.0741H84.6768V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M87.8135 66.6562H86.0977V69.0741H87.8135V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M90.7901 66.6562H89.0742V69.0741H90.7901V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M93.8174 66.6562H92.1016V69.0741H93.8174V66.6562Z"
      fill="#C4D9ED"
    />
    <path d="M96.8839 66.6562H95.168V69.0741H96.8839V66.6562Z" fill="#C4D9ED" />
    <path
      d="M100.095 66.6562H98.3789V69.0741H100.095V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M103.232 66.6562H101.516V69.0741H103.232V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M106.204 66.6562H104.488V69.0741H106.204V66.6562Z"
      fill="#C4D9ED"
    />
    <path d="M109.235 66.6562H107.52V69.0741H109.235V66.6562Z" fill="#C4D9ED" />
    <path
      d="M112.392 66.6562H110.676V69.0741H112.392V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M115.599 66.6562H113.883V69.0741H115.599V66.6562Z"
      fill="#C4D9ED"
    />
    <path d="M118.735 66.6562H117.02V69.0741H118.735V66.6562Z" fill="#C4D9ED" />
    <path
      d="M121.712 66.6562H119.996V69.0741H121.712V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M124.743 66.6562H123.027V69.0741H124.743V66.6562Z"
      fill="#C4D9ED"
    />
    <path d="M127.806 66.6562H126.09V69.0741H127.806V66.6562Z" fill="#C4D9ED" />
    <path
      d="M131.017 66.6562H129.301V69.0741H131.017V66.6562Z"
      fill="#C4D9ED"
    />
    <path
      d="M134.153 66.6562H132.438V69.0741H134.153V66.6562Z"
      fill="#C4D9ED"
    />
    <path d="M137.126 66.6562H135.41V69.0741H137.126V66.6562Z" fill="#C4D9ED" />
    <path
      d="M140.157 66.6562H138.441V69.0741H140.157V66.6562Z"
      fill="#C4D9ED"
    />
    <path d="M81.4698 71.1211H79.7539V73.539H81.4698V71.1211Z" fill="#C4D9ED" />
    <path d="M84.6768 71.1211H82.9609V73.539H84.6768V71.1211Z" fill="#C4D9ED" />
    <path d="M87.8135 71.1211H86.0977V73.539H87.8135V71.1211Z" fill="#C4D9ED" />
    <path d="M90.7901 71.1211H89.0742V73.539H90.7901V71.1211Z" fill="#C4D9ED" />
    <path d="M93.8174 71.1211H92.1016V73.539H93.8174V71.1211Z" fill="#C4D9ED" />
    <path d="M96.8839 71.1211H95.168V73.539H96.8839V71.1211Z" fill="#C4D9ED" />
    <path d="M100.095 71.1211H98.3789V73.539H100.095V71.1211Z" fill="#C4D9ED" />
    <path d="M103.232 71.1211H101.516V73.539H103.232V71.1211Z" fill="#C4D9ED" />
    <path d="M106.204 71.1211H104.488V73.539H106.204V71.1211Z" fill="#C4D9ED" />
    <path d="M109.235 71.1211H107.52V73.539H109.235V71.1211Z" fill="#C4D9ED" />
    <path d="M112.392 71.1211H110.676V73.539H112.392V71.1211Z" fill="#C4D9ED" />
    <path d="M115.599 71.1211H113.883V73.539H115.599V71.1211Z" fill="#C4D9ED" />
    <path d="M118.735 71.1211H117.02V73.539H118.735V71.1211Z" fill="#C4D9ED" />
    <path d="M121.712 71.1211H119.996V73.539H121.712V71.1211Z" fill="#C4D9ED" />
    <path d="M124.743 71.1211H123.027V73.539H124.743V71.1211Z" fill="#C4D9ED" />
    <path d="M127.806 71.1211H126.09V73.539H127.806V71.1211Z" fill="#C4D9ED" />
    <path d="M131.017 71.1211H129.301V73.539H131.017V71.1211Z" fill="#C4D9ED" />
    <path d="M134.153 71.1211H132.438V73.539H134.153V71.1211Z" fill="#C4D9ED" />
    <path d="M137.126 71.1211H135.41V73.539H137.126V71.1211Z" fill="#C4D9ED" />
    <path d="M140.157 71.1211H138.441V73.539H140.157V71.1211Z" fill="#C4D9ED" />
    <path
      d="M81.4698 75.3867H79.7539V77.8046H81.4698V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M84.6768 75.3867H82.9609V77.8046H84.6768V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M87.8135 75.3867H86.0977V77.8046H87.8135V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M90.7901 75.3867H89.0742V77.8046H90.7901V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M93.8174 75.3867H92.1016V77.8046H93.8174V75.3867Z"
      fill="#C4D9ED"
    />
    <path d="M96.8839 75.3867H95.168V77.8046H96.8839V75.3867Z" fill="#C4D9ED" />
    <path
      d="M100.095 75.3867H98.3789V77.8046H100.095V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M103.232 75.3867H101.516V77.8046H103.232V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M106.204 75.3867H104.488V77.8046H106.204V75.3867Z"
      fill="#C4D9ED"
    />
    <path d="M109.235 75.3867H107.52V77.8046H109.235V75.3867Z" fill="#C4D9ED" />
    <path
      d="M112.392 75.3867H110.676V77.8046H112.392V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M115.599 75.3867H113.883V77.8046H115.599V75.3867Z"
      fill="#C4D9ED"
    />
    <path d="M118.735 75.3867H117.02V77.8046H118.735V75.3867Z" fill="#C4D9ED" />
    <path
      d="M121.712 75.3867H119.996V77.8046H121.712V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M124.743 75.3867H123.027V77.8046H124.743V75.3867Z"
      fill="#C4D9ED"
    />
    <path d="M127.806 75.3867H126.09V77.8046H127.806V75.3867Z" fill="#C4D9ED" />
    <path
      d="M131.017 75.3867H129.301V77.8046H131.017V75.3867Z"
      fill="#C4D9ED"
    />
    <path d="M137.126 75.3867H135.41V77.8046H137.126V75.3867Z" fill="#C4D9ED" />
    <path
      d="M134.153 75.3867H132.438V77.8046H134.153V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M140.157 75.3867H138.441V77.8046H140.157V75.3867Z"
      fill="#C4D9ED"
    />
    <path
      d="M81.4698 79.5898H79.7539V82.0077H81.4698V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M84.6768 79.5898H82.9609V82.0077H84.6768V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M87.8135 79.5898H86.0977V82.0077H87.8135V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M90.7901 79.5898H89.0742V82.0077H90.7901V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M93.8174 79.5898H92.1016V82.0077H93.8174V79.5898Z"
      fill="#C4D9ED"
    />
    <path d="M96.8839 79.5898H95.168V82.0077H96.8839V79.5898Z" fill="#C4D9ED" />
    <path
      d="M100.095 79.5898H98.3789V82.0077H100.095V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M103.232 79.5898H101.516V82.0077H103.232V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M106.204 79.5898H104.488V82.0077H106.204V79.5898Z"
      fill="#C4D9ED"
    />
    <path d="M109.235 79.5898H107.52V82.0077H109.235V79.5898Z" fill="#C4D9ED" />
    <path
      d="M112.392 79.5898H110.676V82.0077H112.392V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M115.599 79.5898H113.883V82.0077H115.599V79.5898Z"
      fill="#C4D9ED"
    />
    <path d="M118.735 79.5898H117.02V82.0077H118.735V79.5898Z" fill="#C4D9ED" />
    <path
      d="M121.712 79.5898H119.996V82.0077H121.712V79.5898Z"
      fill="#C4D9ED"
    />
    <path
      d="M124.743 79.5898H123.027V82.0077H124.743V79.5898Z"
      fill="#C4D9ED"
    />
    <path d="M127.806 79.5898H126.09V82.0077H127.806V79.5898Z" fill="#C4D9ED" />
    <path
      d="M131.017 79.5898H129.301V82.0077H131.017V79.5898Z"
      fill="#C4D9ED"
    />
    <path d="M137.126 79.5898H135.41V82.0077H137.126V79.5898Z" fill="#C4D9ED" />
    <path
      d="M140.157 79.5898H138.441V82.0077H140.157V79.5898Z"
      fill="#C4D9ED"
    />
    <path d="M158.356 74.8086H154.539V100.63H158.356V74.8086Z" fill="#C4D9ED" />
    <path
      d="M154.539 90.5078H150.441V108.443H154.539V90.5078Z"
      fill="#ADC9E0"
    />
    <path
      d="M64.8188 67.5373V62.5078H65.0256V67.5373H67.8561V73.744H62.0273V67.5373H64.8188Z"
      fill="#C4D9ED"
    />
    <path
      d="M47.9297 54.5503V50.6911H49.57V49.0117H49.7768V50.6911H55.7559H55.8653H56.9956V49.0117H57.2024V50.6911H58.6533V67.5554H55.7559V60.2888H47.9297V56.4304H55.7559V54.5503H47.9297Z"
      fill="#C4D9ED"
    />
    <path
      d="M132.652 78.567V100.334H154.537V74.8086L132.652 78.567Z"
      fill="#D5E7F7"
    />
    <path
      d="M159.955 90.4414H132.652V108.803H159.955V90.4414Z"
      fill="#D5E7F7"
    />
    <path d="M159.955 79.5742H132.652V85.542H159.955V79.5742Z" fill="#C4D9ED" />
    <path
      d="M135.914 95.4219H134.512V97.3984H135.914V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M138.535 95.4219H137.133V97.3984H138.535V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M141.101 95.4219H139.699V97.3984H141.101V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M143.531 95.4219H142.129V97.3984H143.531V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M146.008 95.4219H144.605V97.3984H146.008V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M148.512 95.4219H147.109V97.3984H148.512V95.4219Z"
      fill="#C4D9ED"
    />
    <path d="M151.133 95.4219H149.73V97.3984H151.133V95.4219Z" fill="#C4D9ED" />
    <path
      d="M153.699 95.4219H152.297V97.3984H153.699V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M156.129 95.4219H154.727V97.3984H156.129V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M158.605 95.4219H157.203V97.3984H158.605V95.4219Z"
      fill="#C4D9ED"
    />
    <path
      d="M135.914 98.9102H134.512V100.887H135.914V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M138.535 98.9102H137.133V100.887H138.535V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M141.101 98.9102H139.699V100.887H141.101V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M143.531 98.9102H142.129V100.887H143.531V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M146.008 98.9102H144.605V100.887H146.008V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M148.512 98.9102H147.109V100.887H148.512V98.9102Z"
      fill="#C4D9ED"
    />
    <path d="M151.133 98.9102H149.73V100.887H151.133V98.9102Z" fill="#C4D9ED" />
    <path
      d="M153.699 98.9102H152.297V100.887H153.699V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M156.129 98.9102H154.727V100.887H156.129V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M158.605 98.9102H157.203V100.887H158.605V98.9102Z"
      fill="#C4D9ED"
    />
    <path
      d="M135.914 88.3945H134.512V90.3711H135.914V88.3945Z"
      fill="#C4D9ED"
    />
    <path
      d="M138.535 88.3945H137.133V90.3711H138.535V88.3945Z"
      fill="#C4D9ED"
    />
    <path
      d="M141.101 88.3945H139.699V90.3711H141.101V88.3945Z"
      fill="#C4D9ED"
    />
    <path
      d="M143.531 88.3945H142.129V90.3711H143.531V88.3945Z"
      fill="#C4D9ED"
    />
    <path
      d="M146.008 88.3945H144.605V90.3711H146.008V88.3945Z"
      fill="#C4D9ED"
    />
    <path
      d="M148.512 88.3945H147.109V90.3711H148.512V88.3945Z"
      fill="#C4D9ED"
    />
    <path d="M151.133 88.3945H149.73V90.3711H151.133V88.3945Z" fill="#C4D9ED" />
    <path
      d="M153.699 88.3945H152.297V90.3711H153.699V88.3945Z"
      fill="#C4D9ED"
    />
    <path
      d="M135.914 91.8828H134.512V93.8593H135.914V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M138.535 91.8828H137.133V93.8593H138.535V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M141.101 91.8828H139.699V93.8593H141.101V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M143.531 91.8828H142.129V93.8593H143.531V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M146.008 91.8828H144.605V93.8593H146.008V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M148.512 91.8828H147.109V93.8593H148.512V91.8828Z"
      fill="#C4D9ED"
    />
    <path d="M151.133 91.8828H149.73V93.8593H151.133V91.8828Z" fill="#C4D9ED" />
    <path
      d="M153.699 91.8828H152.297V93.8593H153.699V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M156.129 91.8828H154.727V93.8593H156.129V91.8828Z"
      fill="#C4D9ED"
    />
    <path
      d="M158.605 91.8828H157.203V93.8593H158.605V91.8828Z"
      fill="#C4D9ED"
    />
    <path d="M135.914 102.344H134.512V104.32H135.914V102.344Z" fill="#C4D9ED" />
    <path d="M138.535 102.344H137.133V104.32H138.535V102.344Z" fill="#C4D9ED" />
    <path d="M141.101 102.344H139.699V104.32H141.101V102.344Z" fill="#C4D9ED" />
    <path d="M143.531 102.344H142.129V104.32H143.531V102.344Z" fill="#C4D9ED" />
    <path d="M146.008 102.344H144.605V104.32H146.008V102.344Z" fill="#C4D9ED" />
    <path d="M148.512 102.344H147.109V104.32H148.512V102.344Z" fill="#C4D9ED" />
    <path d="M151.133 102.344H149.73V104.32H151.133V102.344Z" fill="#C4D9ED" />
    <path d="M153.699 102.344H152.297V104.32H153.699V102.344Z" fill="#C4D9ED" />
    <path d="M156.129 102.344H154.727V104.32H156.129V102.344Z" fill="#C4D9ED" />
    <path d="M158.605 102.344H157.203V104.32H158.605V102.344Z" fill="#C4D9ED" />
    <path
      d="M135.914 105.402H134.512V107.379H135.914V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M138.535 105.402H137.133V107.379H138.535V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M141.101 105.402H139.699V107.379H141.101V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M143.531 105.402H142.129V107.379H143.531V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M146.008 105.402H144.605V107.379H146.008V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M148.512 105.402H147.109V107.379H148.512V105.402Z"
      fill="#C4D9ED"
    />
    <path d="M151.133 105.402H149.73V107.379H151.133V105.402Z" fill="#C4D9ED" />
    <path
      d="M153.699 105.402H152.297V107.379H153.699V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M156.129 105.402H154.727V107.379H156.129V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M158.605 105.402H157.203V107.379H158.605V105.402Z"
      fill="#C4D9ED"
    />
    <path
      d="M153.069 70.7109H152.664V75.8864H153.069V70.7109Z"
      fill="#D5E7F7"
    />
    <path d="M135.912 81.25H134.734V83.2882H135.912V81.25Z" fill="#D5E7F7" />
    <path d="M147.612 81.25H146.434V83.2882H147.612V81.25Z" fill="#D5E7F7" />
    <path d="M156.916 81.25H155.738V83.2882H156.916V81.25Z" fill="#D5E7F7" />
    <path
      d="M60.7865 108.805H100.078V85.8214H60.7865V108.805Z"
      fill="#C4D9ED"
    />
    <path
      d="M74.32 86.872H66.1211V79.7227L74.32 82.8625V86.872Z"
      fill="#C4D9ED"
    />
    <path
      d="M114.73 108.805H133.494V88.3945L114.73 94.8897V108.805Z"
      fill="#C4D9ED"
    />
    <path d="M115.228 96.1953H133V95.5646H115.228V96.1953Z" fill="#D5E7F7" />
    <path d="M115.228 98.2227H133V97.5919H115.228V98.2227Z" fill="#D5E7F7" />
    <path d="M115.228 100.25H133V99.6192H115.228V100.25Z" fill="#D5E7F7" />
    <path d="M115.228 102.277H133V101.647H115.228V102.277Z" fill="#D5E7F7" />
    <path d="M115.228 104.305H133V103.674H115.228V104.305Z" fill="#D5E7F7" />
    <path d="M115.228 106.332H133V105.701H115.228V106.332Z" fill="#D5E7F7" />
    <path
      d="M64.5442 85.8203H66.1211V79.7213H64.5442V85.8203Z"
      fill="#C4D9ED"
    />
    <path
      d="M130.729 50.0273H131.816V40.8033H130.729V50.0273Z"
      fill="#C4D9ED"
    />
    <path
      d="M128.569 50.0273H129.656V40.8033H128.569V50.0273Z"
      fill="#C4D9ED"
    />
    <path
      d="M126.437 50.0273H127.523V40.8033H126.437V50.0273Z"
      fill="#C4D9ED"
    />
    <path
      d="M124.225 50.0273H125.312V40.8033H124.225V50.0273Z"
      fill="#C4D9ED"
    />
    <path
      d="M189.355 109.708C190.974 106.683 192.487 102.609 193.486 99.3285C197.795 85.1861 198.156 69.79 191.448 56.5269C186.665 47.063 177.195 37.6956 167.931 33.4011C151.25 25.6852 137.542 34.1595 129.562 33.5887C120.788 32.9606 117.027 16.2318 97.6957 6.70971C83.5142 -0.273729 61.59 -1.89405 46.3042 2.26318C30.466 6.57504 15.4331 17.4403 21.4148 38.5209C22.8587 43.6112 25.1159 48.6347 27.6198 53.2706C41.9411 79.7882 8.38099 72.0811 4.58867 98.1008C4.10995 101.387 4.16556 105.209 4.93097 109.709H189.355V109.708Z"
      fill="url(#paint0_linear_11019_29953)"
    />
    <path
      d="M135.624 32.918L94.3477 105.814H114.071L142.979 53.8249L142.557 31.3672L135.624 32.918Z"
      fill="#BDD0FB"
    />
    <path
      d="M49.8281 43.6502V69.3755L89.1988 3.37514C88.0433 3.03804 86.853 2.63318 85.6098 2.34734C82.5655 1.64796 79.3831 1.07281 76.0634 0.691406L49.8281 43.6502Z"
      fill="#BDD0FB"
    />
    <path
      d="M161.242 95.5465C161.233 95.5456 161.22 95.5447 161.203 95.543C161.216 95.5447 161.229 95.5456 161.242 95.5465Z"
      fill="#CFE1E5"
    />
    <path
      d="M161.166 95.5438C161.132 95.5404 161.1 95.5378 161.066 95.5352C161.106 95.5378 161.138 95.5412 161.166 95.5438Z"
      fill="#CFE1E5"
    />
    <path
      d="M93.9307 59.4083H72.2107L66.4766 45.8984H88.1966L93.9307 59.4083Z"
      fill="#0C0F4A"
    />
    <path
      d="M94.3057 59.4083H72.5857L66.8516 45.8984H88.5716L94.3057 59.4083Z"
      fill="#0C0F4A"
    />
    <path
      d="M93.5076 58.8862H73.3245L67.9961 46.332H88.1793L93.5076 58.8862Z"
      fill="#D0E0F7"
    />
    <path
      d="M101.272 59.8446H72.3847L72.2109 59.4102H101.272V59.8446Z"
      fill="#0C0F4A"
    />
    <path
      d="M158.395 59.8477H35.7327C34.6119 59.8477 33.6953 60.7642 33.6953 61.8841V107.496C33.6953 108.616 34.6119 109.533 35.7327 109.533H158.395C159.515 109.533 160.432 108.616 160.432 107.496V61.8841C160.431 60.7642 159.514 59.8477 158.395 59.8477Z"
      fill="#9FC1F4"
    />
    <path
      d="M150.936 64.8438H41.9612C40.9534 64.8438 40.1289 65.6682 40.1289 66.6752V107.7C40.1289 108.708 40.9534 109.531 41.9612 109.531H150.936C151.944 109.531 152.768 108.707 152.768 107.7V66.6752C152.768 65.6682 151.944 64.8438 150.936 64.8438Z"
      fill="#719DD3"
    />
    <path
      d="M111.195 32.5736C111.272 32.3434 111.319 32.0906 111.327 31.8117C111.427 28.5502 112.168 26.3851 108.831 26.0941C105.493 25.8039 104.806 27.028 104.508 28.1088C104.21 29.1896 104.636 34.6874 105.995 34.8342C105.995 34.8342 106.432 34.8542 107.054 34.8038C107.083 35.1618 107.134 35.7821 107.189 36.4658C107.298 37.7717 107.426 39.3025 107.463 39.6535L111.155 39.4719L113.105 37.0427C113.105 37.0436 111.745 35.3972 111.195 32.5736Z"
      fill="#FEBBBA"
    />
    <path
      d="M118.374 74.6252C117.649 75.8554 116.811 77.0083 115.945 78.1004C113.273 81.4739 110.364 84.255 109.858 86.884C109.757 87.4079 109.597 87.9726 109.387 88.5668C109.176 89.1689 108.915 89.8023 108.613 90.4574C107.479 92.9204 105.783 95.6806 104.09 98.1888C101.163 102.526 98.4102 106.144 98.4102 106.144L93.3633 105.93C93.5657 105.424 95.7664 99.4356 98.7116 92.1176C99.908 89.1472 101.179 85.9865 102.406 82.9396C102.569 82.5321 102.732 82.1273 102.895 81.7259C103.27 80.7928 103.639 79.8753 103.999 78.9813C106.765 72.11 108.967 66.6383 108.967 66.6383C108.967 66.6383 120.019 62.4515 121.167 65.1648C122.114 67.4011 121.343 69.5922 118.374 74.6252Z"
      fill="#0C0F4A"
    />
    <path
      d="M98.2545 105.375C98.2545 105.375 97.9964 109.54 97.1902 109.6C97.0833 109.608 93.1033 109.608 93.1033 109.608L88.0234 109.578C88.0234 109.578 88.0565 108.354 89.5421 108.095C91.0269 107.836 93.5125 106.793 94.4126 104.83C95.3587 102.765 98.2545 105.375 98.2545 105.375Z"
      fill="#0C0F4A"
    />
    <path
      d="M109.939 93.3281C109.939 93.3281 113.45 95.5844 113.109 96.3168C113.064 96.4141 111.124 99.8893 111.124 99.8893L108.622 104.311C108.622 104.311 107.57 103.685 108.068 102.262C108.566 100.839 108.866 98.1604 107.591 96.4185C106.248 94.5844 109.939 93.3281 109.939 93.3281Z"
      fill="#0C0F4A"
    />
    <path
      d="M106.626 36.1437C106.707 36.1142 111.617 34.9396 112.133 35.1402C112.649 35.3409 112.907 36.3731 112.907 36.3731L104.906 37.7493C104.906 37.7493 105.594 36.5164 106.626 36.1437Z"
      fill="#454BE6"
    />
    <path
      d="M105.006 27.8222C105.006 27.8222 106.299 31.4303 107.021 31.0698C107.743 30.7083 108.404 30.4077 108.585 31.5511C108.765 32.6936 108.043 34.2574 110.449 33.8369C112.855 33.4155 114.644 26.4564 113.697 25.4773C110.96 22.6502 101.247 24.4842 105.006 27.8222Z"
      fill="#0C0F4A"
    />
    <path
      d="M104.997 64.7734C104.997 64.7734 91.3181 73.2903 89.769 79.0974C88.2208 84.9045 108.222 97.2927 108.222 97.2927C108.222 97.2927 110.416 95.357 110.804 93.9374C110.804 93.9374 101.763 83.1052 99.8011 80.6777C98.597 79.1869 117.61 75.5153 119.933 71.902C122.255 68.2886 121.424 65.2487 121.424 65.2487L104.997 64.7734Z"
      fill="#0C0F4A"
    />
    <path
      d="M87.1808 58.1023C87.1808 58.1023 85.5188 56.5393 84.7273 56.7035C83.9358 56.8686 82.2912 57.9302 82.0297 58.2326C81.7682 58.5358 81.5327 59.0284 82.1965 59.1474C82.8594 59.2656 82.8941 59.283 82.8941 59.283C82.8941 59.283 84.2442 58.8355 84.4988 58.9137C84.7534 58.9919 85.6361 59.8703 86.3563 59.4741C87.0765 59.0779 87.1808 58.1023 87.1808 58.1023Z"
      fill="#FEBBBA"
    />
    <path
      d="M121.425 65.2473C120.104 66.8077 108.868 66.4011 105.576 65.084C105.328 64.985 105.132 64.8807 104.997 64.773C103.061 63.2239 107.429 61.8642 102.545 50.0607C102.542 50.0546 102.54 50.0494 102.538 50.0433C99.4675 41.3057 103.509 38.7697 104.928 37.4795C104.928 37.4795 111.788 35.3944 115.532 36.5542C120.393 38.0608 122.844 63.5697 121.425 65.2473Z"
      fill="#4F55F0"
    />
    <path
      d="M109.611 59.4566C109.39 61.0639 108.096 65.0282 105.577 65.0856C105.33 64.9865 105.133 64.8823 104.998 64.7745C103.062 63.2255 107.43 61.8658 102.546 50.0622C102.543 50.0562 102.541 50.0509 102.539 50.0449C103.356 49.4836 104.679 48.8485 105.538 49.8207C106.858 51.3116 109.84 57.7928 109.611 59.4566Z"
      fill="#4F55F0"
    />
    <path
      d="M104.907 37.7461C104.907 37.7461 102.866 38.8399 101.415 42.3916C99.9134 46.0666 97.9204 54.3029 97.3722 54.6617C96.824 55.0196 88.5851 56.3194 86.1316 57.2733C86.1316 57.2733 85.1681 58.9996 86.0795 59.7138C86.9908 60.4271 98.0246 60.0352 98.6927 59.8615C104.263 58.4193 107.19 47.4289 107.854 45.7399C108.518 44.051 104.907 37.7461 104.907 37.7461Z"
      fill="#4F55F0"
    />
    <path
      d="M118.894 105.483V99.6641H124.836C125.067 99.6641 125.257 99.4748 125.257 99.2428V97.6598C125.257 97.4279 125.067 97.2384 124.836 97.2384H118.894V78.4862C118.894 77.8711 118.39 77.3672 117.775 77.3672H116.574C115.959 77.3672 115.455 77.8702 115.455 78.4862V97.2393H109.512C109.281 97.2393 109.092 97.4287 109.092 97.6607V99.2437C109.092 99.4748 109.281 99.665 109.512 99.665H115.455V105.484C111.067 105.816 107.314 107.567 106.328 109.608H127.785C126.799 107.566 123.282 105.815 118.894 105.483Z"
      fill="#0C0F4A"
    />
    <path
      d="M131.369 75.7461H102.977C102.409 75.7461 101.945 76.2109 101.945 76.7782V82.4567C101.945 83.0241 102.409 83.4889 102.977 83.4889H131.369C131.937 83.4889 132.401 83.0241 132.401 82.4567V76.7782C132.402 76.2109 131.937 75.7461 131.369 75.7461Z"
      fill="#FFA500"
    />
    <path
      d="M58.004 47.4635C58.004 47.4635 55.8754 49.0465 55.4601 48.95C55.0448 48.8544 57.0161 47.2411 57.0161 47.2411C56.2742 46.8849 53.9692 47.1082 53.9692 47.1082C53.9692 47.1082 53.4419 49.0656 53.0631 48.871C52.6843 48.6755 53.6139 47.2315 53.6139 47.2315C52.7877 47.182 51.7746 47.7954 51.7746 47.7954C50.388 48.8909 51.8659 50.6146 51.8659 50.6146C51.8659 50.6146 51.5809 51.4669 50.7051 52.2428C50.7051 52.2428 49.4541 53.1681 49.3672 54.8205L49.5939 54.8405C49.5939 54.8405 49.9536 53.301 50.6078 52.5729C50.633 52.5469 50.6704 52.5086 50.7156 52.4626C50.8224 52.3635 50.8971 52.2897 50.9467 52.235C51.2725 51.9222 51.7216 51.5234 51.9388 51.4852C51.9388 51.4852 51.9275 51.4452 51.9293 51.3766C51.9319 51.4113 51.9354 51.447 51.9388 51.4852C52.0127 52.3566 53.7034 53.1177 54.7442 53.274C55.7842 53.4304 55.4627 53.003 55.1048 52.3688C54.7468 51.7345 53.6122 51.4513 53.3533 51.1594C53.0944 50.8675 53.6295 50.8666 54.2559 51.1533C54.8823 51.4391 54.9684 52.2028 55.9736 52.6841C56.9788 53.1655 58.4914 51.4973 58.4914 51.4973C57.6903 51.4565 56.5461 50.5868 56.5878 50.3201C56.6295 50.0543 58.5296 50.7797 58.8545 50.8145C59.1794 50.8492 59.9188 50.4452 60.0335 49.1359C60.1499 47.8284 58.004 47.4635 58.004 47.4635Z"
      fill="#82B378"
    />
    <path
      d="M46.6988 48.444C46.6988 48.444 49.0497 47.1278 47.9524 45.3407C47.9524 45.3407 47.0749 44.2434 46.134 43.961C46.134 43.961 46.6353 46.6256 46.134 46.6882C45.6327 46.7507 45.7891 43.6786 45.7891 43.6786C45.7891 43.6786 43.313 42.4875 42.3408 42.5813C42.3408 42.5813 43.8769 45.1834 43.3756 45.1209C42.8743 45.0583 41.1497 42.425 41.1497 42.425C41.1497 42.425 38.6102 41.9549 38.2027 43.4597C37.7953 44.9645 38.4538 45.7169 38.83 45.8107C39.2062 45.9045 41.6197 45.8732 41.5572 46.1869C41.4946 46.5005 39.8648 47.0018 38.9551 46.7195C38.9551 46.7195 39.9586 49.1955 41.2748 49.0704C42.5911 48.9453 42.9985 48.1304 43.8143 48.067C44.6293 48.0044 45.2253 48.2233 44.8178 48.4432C44.4103 48.663 43.0307 48.5153 42.373 49.0748C41.7144 49.6343 41.1819 49.9792 42.4043 50.2303C43.6267 50.4814 45.8213 50.3241 46.26 49.3841C46.6988 48.444 46.6988 48.444 46.6988 48.444Z"
      fill="#A8D29F"
    />
    <path
      d="M47.3259 50.7324C46.6673 49.51 46.6986 48.444 46.6986 48.444C45.9462 46.0617 39.3633 43.9297 39.3633 43.9297C47.7325 47.3154 46.2598 49.384 46.2598 49.384C46.4857 49.5152 46.8237 50.1434 47.0583 50.6247C47.0913 50.7064 47.1443 50.8202 47.2233 50.9739C47.2555 51.0434 47.2807 51.1008 47.2981 51.1399C47.729 52.2181 47.5005 54.0799 47.5005 54.0799L47.762 54.1503C48.3406 52.2737 47.3259 50.7324 47.3259 50.7324Z"
      fill="#82B378"
    />
    <path
      d="M50.4395 46.63C50.4395 46.63 47.3405 44.8941 48.787 42.5388C48.787 42.5388 49.9443 41.0922 51.1841 40.7204C51.1841 40.7204 50.5229 44.233 51.1841 44.3155C51.8452 44.398 51.6384 40.3485 51.6384 40.3485C51.6384 40.3485 54.9025 38.7786 56.184 38.902C56.184 38.902 54.1588 42.332 54.82 42.2486C55.4812 42.1661 57.754 38.6952 57.754 38.6952C57.754 38.6952 61.8408 37.4824 62.3777 39.4658C62.9146 41.4493 61.3074 43.034 60.8121 43.1582C60.3161 43.2825 57.1345 43.2408 57.217 43.6543C57.2996 44.0679 59.4446 44.3546 60.6436 43.9827C60.6436 43.9827 59.3247 47.6213 57.5889 47.4562C55.853 47.2911 55.3161 46.2164 54.2423 46.1339C53.1675 46.0514 52.383 46.3407 52.9199 46.63C53.4569 46.9193 55.2753 46.7247 56.1432 47.4623C57.0111 48.1999 57.7131 48.6543 56.1015 48.9853C54.4899 49.3155 51.5976 49.1095 51.019 47.8698C50.4395 46.63 50.4395 46.63 50.4395 46.63Z"
      fill="#A8D29F"
    />
    <path
      d="M60.1108 40.6797C60.1108 40.6797 51.4332 43.4894 50.4419 46.6301C50.4419 46.6301 50.4836 48.035 49.6156 49.6466C49.6156 49.6466 48.1265 51.7812 48.8893 54.2547L49.3845 54.0592C49.3845 54.0592 49.0839 51.6049 49.6513 50.1835C49.6747 50.1314 49.7077 50.0558 49.7503 49.9646C49.8537 49.7622 49.9241 49.6118 49.9675 49.5041C50.2768 48.8699 50.7225 48.0428 51.0196 47.869C51.0196 47.8699 49.0779 45.1427 60.1108 40.6797Z"
      fill="#82B378"
    />
    <path
      d="M50.336 59.5195C53.6887 59.5195 56.4063 56.8019 56.4063 53.4492H44.2656C44.2656 56.8011 46.9832 59.5195 50.336 59.5195Z"
      fill="#0C0F4A"
    />
    <path
      d="M51.8284 59.4726C51.5816 59.5039 51.3297 59.5195 51.0742 59.5195C47.7215 59.5195 45.0039 56.8011 45.0039 53.4492H46.5121C46.5113 56.5456 48.831 59.1017 51.8284 59.4726Z"
      fill="#0C0F4A"
    />
    <path
      d="M55.74 59.1016H44.9286C44.515 59.1016 44.1797 59.4369 44.1797 59.8505H56.4889C56.4889 59.4369 56.1535 59.1016 55.74 59.1016Z"
      fill="#0C0F4A"
    />
    <path
      d="M56.4521 53.4453H44.1594C44.1081 53.4453 44.0664 53.487 44.0664 53.5383C44.0664 53.5895 44.1081 53.6312 44.1594 53.6312H56.4521C56.5033 53.6312 56.545 53.5895 56.545 53.5383C56.545 53.487 56.5033 53.4453 56.4521 53.4453Z"
      fill="#FFA500"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11019_29953"
        x1="214.2"
        y1="127.554"
        x2="149.294"
        y2="6.86999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DAE3FE" />
        <stop offset="1" stop-color="#E9EFFD" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "SoloTraderIcon",
};
</script>
