<template>
  <svg
    width="220"
    height="41"
    viewBox="0 0 220 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M187.319 34.5446V27.7266H190.539C191.338 27.7266 191.953 27.8845 192.384 28.2004C192.815 28.5099 193.03 28.9419 193.03 29.4964C193.03 29.909 192.898 30.2572 192.634 30.5408C192.37 30.8245 192.005 31.0147 191.54 31.1114V30.9664C192.075 31.0373 192.488 31.2178 192.78 31.5079C193.079 31.7916 193.228 32.1688 193.228 32.6394C193.228 33.2455 193.003 33.7162 192.551 34.0514C192.099 34.3802 191.471 34.5446 190.665 34.5446H187.319ZM188.611 33.5969H190.508C190.96 33.5969 191.311 33.5195 191.561 33.3648C191.811 33.2036 191.936 32.9425 191.936 32.5814C191.936 32.2139 191.811 31.9528 191.561 31.7981C191.311 31.6369 190.96 31.5563 190.508 31.5563H188.611V33.5969ZM188.611 30.6182H190.31C190.79 30.6182 191.144 30.5377 191.373 30.3764C191.609 30.2088 191.728 29.9638 191.728 29.6414C191.728 29.3191 191.609 29.0773 191.373 28.9161C191.144 28.7485 190.79 28.6647 190.31 28.6647H188.611V30.6182ZM195.036 34.5446V27.7266H199.986V28.7033H196.328V30.5892H199.746V31.5756H196.328V33.5582H199.986V34.5446H195.036ZM203.434 34.5446V28.7517H201.006V27.7266H207.206V28.7517H204.778V34.5446H203.434ZM207.05 34.5446L210.375 27.7266H211.5L214.866 34.5446H213.501L212.605 32.6298L213.147 32.9683H208.738L209.301 32.6298L208.405 34.5446H207.05ZM210.927 29.0612L209.457 32.2913L209.187 31.9915H212.688L212.469 32.2913L210.969 29.0612H210.927Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
    <path
      d="M213.896 22.2729H186.916C184.097 22.2729 181.812 24.5878 181.812 27.4433V34.8296C181.812 37.6851 184.097 39.9999 186.916 39.9999H213.896C216.715 39.9999 219 37.6851 219 34.8296V27.4433C219 24.5878 216.715 22.2729 213.896 22.2729Z"
      stroke="#0C0F4A"
      stroke-opacity="0.5"
      stroke-width="0.5"
    />
    <path
      d="M34.898 39.9996H27.4686V36.448C27.4937 34.6282 26.838 32.87 25.6399 31.544C25.0986 30.8822 24.4202 30.3565 23.6555 30.0065C22.8909 29.6564 22.0599 29.4912 21.2251 29.5231H20.325V39.9996H12.6956V11.2304H16.5103H20.325V21.9148H21.2251C21.9913 21.943 22.7546 21.8013 23.464 21.4989C24.1735 21.1966 24.813 20.7406 25.3398 20.1613C26.5503 18.9781 27.3067 17.3782 27.4686 15.6587V11.2007H34.9124V14.9454C34.9297 16.9664 34.529 18.9677 33.7378 20.8137C32.9467 22.6597 31.7832 24.3072 30.3261 25.6446C31.7885 26.9982 32.9529 28.664 33.7395 30.5285C34.5261 32.393 34.9163 34.4119 34.8837 36.448C34.8837 36.9084 34.8837 37.5028 34.8837 38.2609L34.898 39.9996Z"
      fill="#0C0F4A"
    />
    <path
      d="M68.2352 39.9996H60.7522V25.9101C60.7672 24.5568 60.397 23.2284 59.6871 22.088C58.9772 20.9477 57.9585 20.0449 56.7558 19.4906C55.5532 18.9363 54.2191 18.7546 52.9173 18.9678C51.6155 19.181 50.4027 19.7799 49.4277 20.6908C48.1706 21.8079 47.3909 23.3875 47.2572 25.0891V39.9848H39.6744V25.7195C39.6652 23.7988 40.0409 21.8967 40.7779 20.1313C41.5149 18.3658 42.5975 16.7747 43.9583 15.4567C46.6333 12.7306 50.2498 11.2007 54.0189 11.2007C57.7882 11.2007 61.4044 12.7306 64.0794 15.4567C65.4369 16.8108 66.5065 18.4384 67.2217 20.2374C67.9368 22.0363 68.2818 23.9677 68.2352 25.9101V39.9996Z"
      fill="#0C0F4A"
    />
    <path
      d="M101.563 25.6063C101.594 27.4961 101.242 29.3724 100.527 31.1204C99.8118 32.8683 98.749 34.4511 97.4044 35.7714C96.0798 37.1113 94.505 38.1749 92.7702 38.9005C91.0358 39.6261 89.1754 39.9996 87.2966 39.9996C85.4177 39.9996 83.5577 39.6261 81.8229 38.9005C80.0882 38.1749 78.5134 37.1113 77.1888 35.7714C75.8375 34.4544 74.7689 32.8731 74.0487 31.1248C73.3282 29.3766 72.9718 27.4984 73.0015 25.6063C72.9738 23.7144 73.3309 21.8368 74.051 20.0887C74.7712 18.3407 75.8389 16.7591 77.1888 15.4412C78.5114 14.0977 80.0855 13.0312 81.8206 12.3033C83.5557 11.5755 85.4167 11.2007 87.2966 11.2007C89.1764 11.2007 91.0378 11.5755 92.7725 12.3033C94.5076 13.0312 96.0818 14.0977 97.4044 15.4412C98.7477 16.7627 99.8095 18.3455 100.524 20.0932C101.239 21.8409 101.593 23.7167 101.563 25.6063ZM94.1122 25.6063C94.1142 24.7082 93.9395 23.8187 93.5982 22.9888C93.2569 22.1589 92.7557 21.4051 92.1236 20.7708C91.4915 20.1364 90.7407 19.6341 89.915 19.2926C89.089 18.9511 88.204 18.7773 87.3111 18.7812C86.4247 18.7653 85.5447 18.9323 84.725 19.272C83.9056 19.6117 83.1636 20.1168 82.546 20.7561C81.9176 21.3922 81.4246 22.1502 81.0969 22.9841C80.7694 23.8179 80.6138 24.7101 80.6398 25.6063C80.6379 26.4961 80.8106 27.3777 81.148 28.2004C81.4853 29.0231 81.9805 29.7708 82.6057 30.4007C83.2306 31.0306 83.9732 31.5303 84.7906 31.8713C85.6077 32.2123 86.4841 32.3878 87.3688 32.3878C88.2601 32.3999 89.1448 32.2297 89.9688 31.8878C90.7928 31.5458 91.5393 31.0391 92.1628 30.3984C92.7966 29.7763 93.2965 29.03 93.6318 28.2058C93.9672 27.3815 94.1307 26.4967 94.1122 25.6063Z"
      fill="#0C0F4A"
    />
    <path
      d="M142.826 11.2007V28.7917C142.792 30.9348 142.164 33.0233 141.016 34.8128C139.869 36.6023 138.249 38.0184 136.347 38.8958C134.445 39.7729 132.339 40.0747 130.276 39.7661C128.213 39.4573 126.279 38.5506 124.699 37.1524C122.715 39.027 120.107 40.0457 117.413 39.998C115.967 40.0148 114.533 39.7352 113.193 39.1757C111.854 38.6162 110.636 37.7879 109.611 36.7392C108.56 35.7568 107.724 34.5561 107.158 33.2164C106.592 31.8769 106.309 30.4291 106.327 28.9687V11.2744H113.799V28.8507C113.799 29.8049 114.168 30.72 114.824 31.3947C115.48 32.0695 116.37 32.4485 117.298 32.4485C117.767 32.4577 118.233 32.3682 118.667 32.1857C119.101 32.0031 119.494 31.7313 119.822 31.3869C120.151 31.0571 120.41 30.6623 120.585 30.2263C120.76 29.7903 120.847 29.3223 120.841 28.8507V11.2597H128.313V29.2341C128.417 30.0791 128.827 30.8529 129.46 31.4016C130.107 31.9895 130.949 32.3008 131.812 32.2716C132.267 32.2802 132.718 32.1915 133.138 32.0113C133.558 31.8311 133.936 31.5632 134.25 31.2247C134.575 30.8788 134.83 30.4699 135 30.0219C135.17 29.5738 135.251 29.0955 135.24 28.6148V11.2007H142.826Z"
      fill="#0C0F4A"
    />
    <path
      d="M176.15 39.9956V26.2659C176.15 26.048 176.15 25.83 176.15 25.6121C176.18 23.7216 175.827 21.8448 175.112 20.0962C174.396 18.3476 173.335 16.764 171.992 15.4419C170.669 14.0977 169.095 13.0306 167.36 12.3024C165.625 11.5742 163.764 11.1992 161.884 11.1992C160.004 11.1992 158.143 11.5742 156.408 12.3024C154.674 13.0306 153.099 14.0977 151.777 15.4419C150.424 16.7584 149.355 18.3404 148.634 20.0898C147.914 21.8392 147.558 23.7188 147.589 25.6121C147.553 27.5061 147.906 29.3873 148.627 31.1374C149.348 32.8876 150.42 34.469 151.777 35.7824C153.082 37.1475 154.653 38.2266 156.393 38.9515C158.132 39.6768 160.002 40.032 161.884 39.9956C164.303 40.0247 166.938 37.6047 169.046 35.7824V39.9956H176.15ZM166.692 30.4066C166.067 31.0443 165.32 31.5481 164.496 31.8877C163.672 32.2273 162.789 32.3956 161.899 32.3825C161.014 32.3864 160.137 32.2139 159.319 31.875C158.501 31.5361 157.758 31.0376 157.132 30.4081C156.507 29.7786 156.011 29.0307 155.674 28.2076C155.338 27.3844 155.166 26.5023 155.17 25.6121C155.153 24.7102 155.32 23.8144 155.66 22.9799C156 22.1454 156.507 21.3897 157.148 20.7594C157.766 20.1198 158.507 19.6145 159.327 19.2746C160.147 18.9348 161.027 18.7676 161.913 18.7835C162.806 18.7797 163.69 18.9537 164.515 19.2955C165.34 19.6373 166.089 20.1401 166.721 20.775C167.351 21.4099 167.851 22.1642 168.191 22.9944C168.53 23.8246 168.704 24.7143 168.7 25.6121C168.711 26.5073 168.539 27.3953 168.194 28.2204C167.849 29.0455 167.337 29.7898 166.692 30.4066Z"
      fill="#0C0F4A"
    />
    <path
      d="M7.93477 39.9981H0V11.1992H7.87576L7.93477 39.9981Z"
      fill="#0C0F4A"
    />
    <path
      d="M7.64347 5.53491C7.84342 5.04863 7.94241 4.52619 7.93431 3.99983C7.94241 3.47351 7.84342 2.95106 7.64347 2.46477C7.44355 1.97849 7.1469 1.53866 6.77182 1.17244C6.40857 0.794304 5.97233 0.495223 5.49 0.293654C5.00764 0.0920844 4.48947 -0.00769886 3.96739 0.000463202C3.4453 -0.00769886 2.92711 0.0920844 2.44477 0.293654C1.96243 0.495223 1.52618 0.794304 1.16293 1.17244C0.78786 1.53866 0.491204 1.97849 0.291272 2.46477C0.0913376 2.95106 -0.0076364 3.47351 0.000459442 3.99983C-0.0076364 4.52619 0.0913376 5.04863 0.291272 5.53491C0.491204 6.02119 0.78786 6.46101 1.16293 6.82725C1.52618 7.20536 1.96243 7.50446 2.44477 7.70602C2.92711 7.90761 3.4453 8.00738 3.96739 7.99923C4.48947 8.00738 5.00764 7.90761 5.49 7.70602C5.97233 7.50446 6.40857 7.20536 6.77182 6.82725C7.1469 6.46101 7.44355 6.02119 7.64347 5.53491Z"
      fill="#0C0F4A"
    />
  </svg>
</template>
<script>
export default {
  name: "IknowaIconBlack",
};
</script>
