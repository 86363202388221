<template>
  <ChipState
    :hasCustomLabel="true"
    bgColor="#FFF"
    :hasShadow="true"
    :isDense="true"
  >
    <template #prefix>
      <div class="service-icon-wrapper-condensed">
        <img
          class="service-icon-wrapper-condensed__icon"
          :src="require(`@/assets/category-icons/${iconName}.svg`)"
          alt="icon"
        />
      </div>
    </template>
    <template #custom-label>
      <Text variant="span" textWeight="600">{{ label }}</Text>
    </template>
  </ChipState>
</template>
<script setup>
import { computed } from "vue";

import ChipState from "@/core/components/common/ChipState";
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  iconName: {
    type: String,
    default: "bathrooms",
    required: true,
  },
  label: {
    type: String,
    default: "Bathrooms",
    required: true,
  },
  bgColor: {
    type: String,
    default: "#0C0F4A",
  },
});
</script>
<script>
export default {
  name: "SpecialismChip",
};
</script>
<style lang="scss" scoped>
.service-icon-wrapper-condensed {
  @apply tw-flex tw-items-center tw-justify-center tw-h-[14px] tw-w-[14px] tw-min-h-[14px] tw-min-w-[14px] tw-rounded-full;
  background: v-bind("props.bgColor");

  &__icon {
    @apply tw-w-[8px] tw-h-[10px];
    // filter: #fff;
    filter: invert(100%) sepia(1%) saturate(1260%) hue-rotate(45deg)
      brightness(112%) contrast(100%);
  }
}
</style>
