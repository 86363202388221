import { Commit } from "vuex";
import connectionService from "@/core/services/connections.service";
import { getImageStringToImageURL } from "@/core/utils/common";

export default {
  async getUserConnectionList(
    { commit }: { commit: Commit },
    payload: { userId: number; params: any; isFiltering?: boolean }
  ) {
    try {
      const contractorsConnectionList: any =
        await connectionService.getUserConnectionList(
          payload.userId,
          payload.params || {}
        );
      for (const connection of contractorsConnectionList) {
        if (connection?.userWorkstation) {
          connection.profileImage = await getImageStringToImageURL(
            connection?.userWorkstation?.profileImage
          );
        }
      }

      if (!payload?.isFiltering) {
        commit("setUserConnections", contractorsConnectionList);
      }
      commit("setUserFilteredConnection", contractorsConnectionList);
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async inviteContractors(
    { state, commit }: any,
    payload: {
      members: {
        email: string;
        tags: Array<any>;
      };
      userId: number;
    }
  ) {
    return connectionService
      .inviteContractors(payload.members, payload.userId)
      .then((response) => {
        return response;
      });
  },

  async getPropertyUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number }
  ) {
    try {
      const contractorsConnectionList =
        await connectionService.getPropertyUserConnection(payload.userId);
      commit("setPropertyUserConnection", contractorsConnectionList);
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async acceptPropertyUserConnection(
    { commit }: { commit: Commit },
    payload: {
      userId: number;
      tradeUserConnectionId: number;
      data: {
        key: string;
        workstationIds: Array<number>;
      };
    }
  ) {
    try {
      const contractorsConnectionList =
        await connectionService.acceptPropertyUserConnection(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.data
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async rejectPropertyUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number; type: string }
  ) {
    try {
      const contractorsConnectionList =
        await connectionService.rejectPropertyUserConnection(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.type
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async resendPropertyUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number; key: any }
  ) {
    try {
      const contractorsConnectionList =
        await connectionService.resendPropertyUserConnection(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.key
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getPropertyConnectionRole(
    { commit }: { commit: Commit },
    payload: { userId: number; propertyId: number }
  ) {
    try {
      const connectionRes: any =
        await connectionService.getConnectionRoleByPropertyId(
          payload.userId,
          payload.propertyId
        );
      commit(
        "setPropertyConnectionRole",
        connectionRes?.connectionRole || null
      );
      return {
        propertyId: payload.propertyId,
        role: connectionRes?.connectionRole,
      };
    } catch (error) {
      commit("setPropertyConnectionRole", null);
      return null;
    }
  },

  async getConnectionsOfProperty(
    { commit }: { commit: Commit },
    payload: { userId: number; propertyId: number }
  ) {
    try {
      const connectionRes: any =
        await connectionService.getConnectionsOfProperty(
          payload.userId,
          payload.propertyId
        );

      const connectionModified = await Promise.all(
        connectionRes.map(async (connection: any) => {
          if (connection?.userWorkstation?.profileImage) {
            connection.userWorkstation.profileImageUrl =
              await getImageStringToImageURL(
                connection?.userWorkstation?.profileImage
              );
          }
          return connection;
        })
      );
      const occupiers = connectionModified.filter((connection) =>
        connection.tags.includes("Occupier")
      );

      commit("setPropertyConnections", connectionModified);
      commit("setPropertyOccupiers", occupiers);
      return {
        propertyId: payload.propertyId,
        connectionRes,
      };
    } catch (error) {
      commit("setPropertyConnections", []);
      return [];
    }
  },
};
