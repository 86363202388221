<template>
  <ChipState
    label="In-Progress"
    bgColor="#FFF"
    :hasShadow="true"
    textColor="#FAA500"
  >
    <template #prefix>
      <InfoIconWhite bgFill="#FAA500" fill="#fff" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import InfoIconWhite from "@/core/components/icons/InfoIconWhite.vue";
</script>
<script>
export default {
  name: "InProgressChip",
};
</script>
