<template>
  <InfoCardLayoutSlot
    size="lg"
    :hasFooter="true"
    title="Overview"
    :removeBorderBottom="true"
    :footerBorderTopDashed="true"
    bgColor="#F1F6FB"
  >
    <template #title-icon>
      <ToolTip
        description="This rating is based on combined data provided by the government, property owner, and trade contractor."
      />
    </template>
    <template #header-right-content>
      <div
        v-if="!hideAction"
        class="tw-flex tw-items-center tw-gap-1 tw-cursor-pointer"
        @click="onOpenModal"
      >
        <Text variant="span" textWeight="600" textColor="#FFA500"
          >EPC Rating</Text
        >
        <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
      </div>
    </template>
    <template #content>
      <section
        class="tw-flex tw-gap-4 tw-overflow-auto tw-flex-col tw-justify-center tw-items-center tw-h-[450px] tw-relative"
      >
        <div class="rating-circle">
          <div
            @click="onClickCategoryMap('all')"
            class="circle-inner sm:!tw-w-[90px] sm:!tw-h-[90px] tw-w-[152px] tw-h-[152px] tw-rounded-full tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center tw-cursor-pointer tw-z-[999]"
          >
            <div
              @click="onClickCategoryMap('all')"
              class="sm:!tw-text-[32px] !tw-leading-4 tw-text-[66px] tw-cursor-pointer tw-z-[999]"
              variant="span"
            >
              {{ allEPCCategoryDataList?.propetyScore || "0" }}
              <!-- <Text variant="span" lineHeight="18px"
              >Property<br />
              Performance</Text
            > -->
            </div>
          </div>
          <div
            class="outer-circle sm:!tw-w-[100px] sm:!tw-h-[100px] sm:!tw-top-[-23px] sm:!tw-left-[-13px]"
          >
            <div
              class="roof_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('roof')"
            >
              <div
                class="roof sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-bottom-[-38px] sm:!tw-left-[-50px]"
              >
                <img
                  src="../../../../assets/icons/roof.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-top-[97%] sm:!tw-left-[-72%]">Roof</p>
            </div>
            <div
              class="walls_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('walls')"
            >
              <div
                class="walls sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-top-[-2px] sm:!tw-left-[-50px]"
              >
                <img
                  src="../../../../assets/icons/walls.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-top-[21%] sm:!tw-left-[-77%]">Walls</p>
            </div>
            <div
              class="floors_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('floors')"
            >
              <div
                class="floors sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-top-[-40px] sm:!tw-left-[-12px]"
              >
                <img
                  src="../../../../assets/icons/floors.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-top-[-47%] sm:!tw-left-[-12%]">Floors</p>
            </div>
            <div
              class="energy_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('energy')"
            >
              <div
                class="energy sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-top-[-42px] sm:!tw-left-[62px]"
              >
                <img
                  src="../../../../assets/icons/energy.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-top-[-47%] sm:!tw-right-[-38%]">Energy</p>
            </div>
            <div
              class="heating_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('heating')"
            >
              <div
                class="heating sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-top-[-5px] sm:!tw-right-[-70px]"
              >
                <img
                  src="../../../../assets/icons/heating.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-top-[21%] sm:!tw-right-[-109%]">Heating</p>
            </div>
            <div
              class="lighting_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('lighting')"
            >
              <div
                class="lighting sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-bottom-[-36px] sm:!tw-right-[-70px]"
              >
                <img
                  src="../../../../assets/icons/lighting.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-top-[96%] sm:!tw-right-[-113%]">Lighting</p>
            </div>
            <div
              class="ventilation_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('ventilation')"
            >
              <div
                class="ventilation sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-bottom-[-75px] sm:!tw-right-[-30px]"
              >
                <img
                  src="../../../../assets/icons/ventilation.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-bottom-[-86%] sm:!tw-right-[-52%]">
                Ventilation
              </p>
            </div>
            <div
              class="windows_wrapper tw-cursor-pointer"
              @click="onClickCategoryMap('windows')"
            >
              <div
                class="windows sm:!tw-w-[70px] sm:!tw-h-[70px] sm:!tw-bottom-[-76px] sm:!tw-right-[43px]"
              >
                <img
                  src="../../../../assets/icons/windows.svg"
                  class="sm:!tw-w-full sm:!tw-h-full"
                />
              </div>
              <p class="sm:!tw-bottom-[-86%] sm:!tw-left-[-25%]">Windows</p>
            </div>
          </div>
        </div>
      </section>
    </template>

    <template #footer-content>
      <section class="tw-w-full tw-flex tw-items-center tw-justify-center">
        <Text variant="span" textWeight="400" textColor="rgba(12, 15, 74, 0.5)"
          >Powered by <Text variant="span" textWeight="400">Krystal</Text></Text
        >
      </section>
    </template>
  </InfoCardLayoutSlot>

  <!-- MODALS -->
  <EnergyEfficiencyModal
    v-if="showEnergyEfficiencyModal"
    @on-close="onCloseModal"
  />
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Text from "@/core/components/ui/general/Text.vue";

import ToolTip from "@/core/components/common/ToolTip.vue";
import InfoCardLayoutSlot from "@/core/components/slots/InfoCardLayoutSlot.vue";
import EnergyEfficiencyModal from "@/modules/properties/components/Modals/EnergyEfficiencyModal.vue";
import { PROPERTY_STORE } from "@/store/modules/property";

const store = useStore();
const emit = defineEmits(["onClickCategoryMap"]);
const props = defineProps({
  hideAction: Boolean,
});

const showEnergyEfficiencyModal = ref(false);
const allEPCCategoryDataList = computed(
  () => store.getters[`${PROPERTY_STORE}/epcCategoryDataList`]
);
console.log(
  allEPCCategoryDataList.value?.propetyScore,
  "allEPCCategoryDataList"
);

const onCloseModal = () => {
  showEnergyEfficiencyModal.value = false;
};

const onOpenModal = () => {
  showEnergyEfficiencyModal.value = true;
};

const onClickCategoryMap = (category) => {
  emit("onClickCategoryMap", category);
};
</script>
<style lang="scss" scoped>
.score {
  box-shadow: 0px 0px 8.61px 0px rgba(12, 15, 74, 0.2);
}
.rating-circle {
  position: relative;
  .circle-inner {
    position: relative;
    width: 150px;
    height: 150px;
    .meter_count {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .score {
        @include fluidFont(68, 68, 1);
        color: rgba($blueDark, 1);
        letter-spacing: 0.7px;
        font-weight: 700;
      }
      .label {
        @include fluidFont(18, 18, 1);
        color: rgba($blueDark, 1);
        letter-spacing: 0.12px;
        font-weight: 700;
      }
    }
  }
  .outer-circle {
    width: 180px;
    height: 180px;
    //border: 1px solid;
    border-radius: 100%;
    position: absolute;
    top: -15px;
    left: -15px;
    .roof_wrapper {
      .roof {
        position: absolute;
        bottom: -15px;
        left: -55px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        left: -50%;
        top: 80%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .walls_wrapper {
      .walls {
        position: absolute;
        top: -5px;
        left: -55px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        left: -50%;
        top: 20%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .floors_wrapper {
      .floors {
        position: absolute;
        top: -55px;
        left: -10px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        left: 0;
        top: -35%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .energy_wrapper {
      .energy {
        position: absolute;
        top: -55px;
        left: 90px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        right: 0;
        top: -35%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .heating_wrapper {
      .heating {
        position: absolute;
        top: -10px;
        right: -55px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        right: -50%;
        top: 0%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .lighting_wrapper {
      .lighting {
        position: absolute;
        bottom: -10px;
        right: -55px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        right: -50%;
        top: 80%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .ventilation_wrapper {
      .ventilation {
        position: absolute;
        bottom: -60px;
        right: -10px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        right: 0;
        bottom: -40%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .windows_wrapper {
      .windows {
        position: absolute;
        bottom: -60px;
        right: 90px;
        transition: 0.3s ease-in-out all;
        &:hover {
          transform: scale(1.5);
          z-index: 1;
        }
      }
      p {
        position: absolute;
        left: 0;
        bottom: -40%;
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
  }
}
</style>
