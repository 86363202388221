<template>
  <ChipState
    label="Completed"
    bgColor="#FFF"
    :hasShadow="true"
    textColor="#1FB27A"
  >
    <template #prefix>
      <CheckGreen class="!tw-max-w-4 !tw-max-h-4" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
</script>
<script>
export default {
  name: "CompletedChip",
};
</script>
