import { Loader, LoaderOptions, Library } from "@googlemaps/js-api-loader";

const createGoogleMapsLoader = (
  libraries: Library[] = ["places", "geometry", "drawing"]
): Loader => {
  const options: LoaderOptions = {
    apiKey: process.env.VUE_APP_GOOGLE_API_URL as string,
    version: "weekly",
    libraries: libraries,
  };

  return new Loader(options);
};

export default createGoogleMapsLoader;
