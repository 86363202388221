<template>
  <div
    class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer add_property_wrapper tw-flex-wrap tw-text-left"
  >
    <AddressChip
      v-if="addressValue"
      :value="addressValue"
      class="!tw-p-0 !tw-text-left"
    />
    <CountChip
      v-if="countValue"
      class="!tw-px-0 !tw-whitespace-nowrap"
      bgColor="transparent"
      textColor="rgba(12, 15, 74, 0.5)"
      :value="countValue"
    />
    <v-btn
      @click="openPropertyModal = true"
      v-if="showAddAction && propertyList.length > 1"
      class="tw-no-underline tw-flex tw-items-center tw-gap-1 tw-text-[#80829F] tw-text-xs tw-font-semibold tw-leading-4 tw-tracking-wide tw-bg-transparent"
    >
      <PlusFilledYellowIcon /> Add Property
    </v-btn>
  </div>
  <AddPropertyDialog
    v-if="openPropertyModal"
    @on-click-close-tab="openPropertyModal = false"
    @on-save-task-properties="saveProperties"
    :phaseIndex="phaseIndex"
    :taskIndex="taskIndex"
  />
</template>
<script setup>
import AddressChip from "@/core/uni-components/Chips/AddressChip.vue";
import AddPropertyDialog from "@/core/uni-components/AddPropertyDialog.vue";
import CountChip from "@/core/uni-components/Chips/CountChip.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";

import { computed, ref } from "vue";
import { useStore } from "vuex";
import { JOBS_STORE } from "@/store/modules/jobs";
import { onBeforeMount } from "vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";

const props = defineProps({
  showAddAction: {
    type: Boolean,
    default: true,
  },
  phaseIndex: {
    type: Number,
  },
  taskIndex: {
    type: Number,
  },
  properties: Array,
  addressValue: String,
  countValue: Number,
});

const store = useStore();
const openPropertyModal = ref(false);
const emits = defineEmits(["on-save-project-properties"]);
const countValue = ref(0);
const addressValue = ref(null);

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const propertyList = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]?.projectProperties
);

const saveProperties = (payload) => {
  openPropertyModal.value = false;
  if (payload && payload.length) {
    countValue.value = payload.length - 1;
    addressValue.value = payload[0].property.address;
  } else {
    countValue.value = 0;
    addressValue.value = null;
  }
  emits("on-save-project-properties", payload);
};

onBeforeMount(() => {
  if (!props.properties && !props.addressValue && !props.countValue) {
    const phase = estimateForm.value.projectStages[props.phaseIndex];
    const task = phase.projectStageTasks[props.taskIndex];

    if (propertyList.value.length === 1) {
      addressValue.value = propertyList.value[0]?.property?.address;
    }

    if (
      task &&
      task.estimationPhaseTasksProperties &&
      task.estimationPhaseTasksProperties.length &&
      phase.stageType !== "materials"
    ) {
      const estimatePropertyId =
        task.estimationPhaseTasksProperties[0].propertyId;

      if (estimatePropertyId) {
        const firstProperty = propertyList.value.find((data) => {
          return data.property.id === estimatePropertyId;
        });

        countValue.value = task.estimationPhaseTasksProperties.length - 1;
        addressValue.value = firstProperty.property.address;
      }
    }

    if (
      phase &&
      phase.stageType === "materials" &&
      phase.estimationPhaseProperties &&
      phase.estimationPhaseProperties.length
    ) {
      const estimatePropertyId = phase.estimationPhaseProperties[0].propertyId;

      if (estimatePropertyId) {
        const firstProperty = propertyList.value.find((data) => {
          return data.property.id === estimatePropertyId;
        });
        countValue.value = phase.estimationPhaseProperties.length - 1;
        addressValue.value = firstProperty.property.address;
      }
    }
  } else {
    countValue.value = props.countValue;
    addressValue.value = props.addressValue;
  }
});
</script>

<style lang="scss" scoped>
.add_property_wrapper {
  .v-btn {
    padding: 0;
    box-shadow: none;
    :deep(.v-btn__content) {
      @include fluidFont(12, 12, 1.3);
      color: rgba($blueDark, 1);
      text-transform: capitalize;
      gap: 6px;
    }
  }
}
</style>
