import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
export const USER = "user";

export const USER_STORE = "user";

export default {
  namespaced: true,

  state,

  actions,

  getters,

  mutations,
};
