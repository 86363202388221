<template>
  <ChipState
    label="Estimates Accepted"
    bgColor="#FFF"
    :hasShadow="true"
    textColor="#1FB27A"
  >
    <template #prefix>
      <InfoIconWhite bgFill="#1FB27A" fill="#fff" />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import InfoIconWhite from "@/core/components/icons/InfoIconWhite.vue";
</script>
<script>
export default {
  name: "EstimateAcceptedChip",
};
</script>
