import $axios from "../utils/axios-api-config";
import {
  AuthRegistration,
  VerifyCode,
  ResetPassWord,
  UpdateUserPasswordRequest,
} from "../models/authentication";

export default {
  login(username: string, password: string) {
    return $axios.post("/auth/login", { username, password });
  },
  verifyEmail(email: string) {
    return $axios.get(`/auth/verify/email/${email}`);
  },
  registration(userForm: AuthRegistration) {
    return $axios.post(`/auth/register/`, userForm);
  },
  newRegistration(userForm: AuthRegistration) {
    return $axios.post(`/auth/new-register/`, userForm);
  },
  verifyCode(data: VerifyCode) {
    return $axios.post(`/auth/verify`, data);
  },
  resendOTP(email: string) {
    return $axios.post(`/auth/verify/resend`, { email });
  },
  resetPassWord(data: ResetPassWord) {
    return $axios.post(`/forgot-password/reset/new-password`, data);
  },
  forgotPassword(data: object) {
    return $axios.post(`/forgot-password/reset`, data);
  },
  verifyResetPasswordToken(token: string) {
    return $axios.get(`/forgot-password/${encodeURIComponent(token)}/verify`);
  },
  changePassword(data: UpdateUserPasswordRequest) {
    return $axios.put("/forgot-password/change/new-password", data);
  },
  logOut(userId: number) {
    return $axios.post("/auth/logout", { userId });
  },
};
