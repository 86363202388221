<template>
  <WizardModal
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="modelDetails"
    @on-close="onClickToggleOccupierWelcome(false)"
    v-model="isShowManageSubscription"
  >
    <template #content>
      <div class="manage_subscription__card !tw-mt-0">
        <v-card
          class="manage_subscription__card-item"
          :class="currentPlan === PaymentPlanType.MONTHLY ? 'selected' : ''"
        >
          <div class="manage_subscription__card-header">
            <div class="manage_subscription__card-tag">MONTHLY</div>
            <span v-if="currentPlan === PaymentPlanType.MONTHLY"
              >Current Plan</span
            >
          </div>
          <div class="manage_subscription__card-details">
            <div class="manage_subscription__card-details__title">
              <span>£9.99</span>
              <p>per month</p>
            </div>
            <div class="manage_subscription__card-details__text">
              <ul class="check-list">
                <li class="check-list__item">iknowa Verified Trade Passport</li>
                <li class="check-list__item">Access to subsidised courses</li>
                <li class="check-list__item">
                  Store & manage documents (100 MB)
                </li>
                <li class="check-list__item">
                  Access to residential & commercial jobs
                </li>
                <li class="check-list__item">
                  Access to trade contractor network
                </li>
                <li class="check-list__item">Access tp collaboration tools</li>

                <li class="check-list__item">E-Wallet</li>
              </ul>
            </div>
            <div class="bottom-btn">
              <v-btn
                v-if="currentPlan !== PaymentPlanType.MONTHLY"
                class="button button-orange"
                :loading="
                  currentPlan === PaymentPlanType.MONTHLY ? false : isLoading
                "
                @click="onClickUpdatePlan(PaymentPlanType.MONTHLY)"
                >CHOOSE MONTHLY
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card
          class="manage_subscription__card-item"
          :class="currentPlan === PaymentPlanType.YEARLY ? 'selected' : ''"
        >
          <div class="manage_subscription__card-header">
            <div class="manage_subscription__card-tag">YEARLY</div>
            <span v-if="currentPlan === PaymentPlanType.YEARLY"
              >Current Plan</span
            >
          </div>
          <div class="manage_subscription__card-details">
            <div class="manage_subscription__card-details__title">
              <span>£7.99</span>
              <p>per month / £95.90 today</p>
            </div>
            <div class="manage_subscription__card-details__text">
              <ul class="check-list">
                <li class="check-list__item">iknowa Verified Trade Passport</li>
                <li class="check-list__item">Access to subsidised courses</li>
                <li class="check-list__item">
                  Store & manage documents (100 MB)
                </li>
                <li class="check-list__item">
                  Access to residential & commercial jobs
                </li>
                <li class="check-list__item">
                  Access to trade contractor network
                </li>
                <li class="check-list__item">Access tp collaboration tools</li>

                <li class="check-list__item">E-Wallet</li>
              </ul>
            </div>
            <div class="bottom-btn">
              <v-btn
                v-if="currentPlan !== PaymentPlanType.YEARLY"
                class="button button-orange"
                :loading="
                  currentPlan === PaymentPlanType.YEARLY ? false : isLoading
                "
                @click="onClickUpdatePlan(PaymentPlanType.YEARLY)"
                >CHOOSE YEARLY</v-btn
              >
            </div>
          </div>
        </v-card>
      </div>
      <p class="description explore__desc">
        *T&C Apply. All rights reserved. ©iknowa 2024
      </p>
    </template>
  </WizardModal>
</template>

<script lang="ts">
import { computed, ref, onBeforeMount, getCurrentInstance } from "vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import { PaymentPlanType } from "@/core/enums/RolesEnum";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import { WORKSTATION } from "@/store/modules/workstation";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";

export default {
  components: {
    WizardModal,
  },
  props: {
    currentPlan: {
      type: Number,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const internalInstance = getCurrentInstance();

    const isShowManageSubscription = ref(true);
    const selectedPlan = ref(0);
    const modelDetails = ref({
      header: "Subscription Plans",
      title: "Choose Your Iknowa Subscription Plan",
    });

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const selectPlan = (plan: number) => {
      if (selectedPlan.value === plan) {
        selectedPlan.value = 0; // Deselect if the same card is clicked again
      } else {
        selectedPlan.value = plan;
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickUpdatePlan = async (updatePlan: number) => {
      if (props?.currentPlan === updatePlan) {
        console.log("Add or remove update");
        await router.push({ name: TRADE_PASSPOSRT_ROUTE });
      } else {
        try {
          isLoading.value = true;
          await store.dispatch(
            `${WORKSTATION_SETTING}/updateSubscriptionsPlan`,
            {
              userId: user.value.id,
              userWorkstationId: activeUserWorkstation.value?.id,
              planType: updatePlan,
            }
          );
        } catch (err) {
          console.log();
        } finally {
          isLoading.value = false;
          onClickToggleOccupierWelcome(true);
          displayToast("Subscription Plan Updated Successfully.", "success");
        }
      }
    };

    const onClickToggleOccupierWelcome = (planUpdated: boolean) => {
      isShowManageSubscription.value = false;
      if (planUpdated) {
        ctx.emit("toggleActiveManageSubscription", planUpdated);
      } else {
        ctx.emit("toggleActiveManageSubscription");
      }
    };

    onBeforeMount(() => {
      console.log(":calling ", props?.currentPlan);
    });

    return {
      isShowManageSubscription,
      PaymentPlanType,
      selectPlan,
      selectedPlan,
      onClickToggleOccupierWelcome,
      onClickUpdatePlan,
      isLoading,
      modelDetails,
    };
  },
};
</script>
<style lang="scss" scoped>
.description {
  margin-top: 0px !important;
}
</style>
