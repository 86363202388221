<template>
  <CommonDialog
    v-if="isShowCourseAssignModal"
    :onClickBackButton="onClickCloseButton"
    :isShowHeader="true"
    :isShowBackButton="true"
    width="575px"
    max-width="100%"
    height="auto"
    :className="['assign_course__modal_box']"
  >
    <template v-slot:body>
      <div class="modal__content">
        <div class="block__content">
          <h5>
            Total: {{ userSelectedCourseForAssign?.selectedSpotCount }} Spots
          </h5>
          <p>
            Members can now be assigned to the
            <b>{{
              userSelectedCourseForAssign?.selectedSpotCount -
                userSelectedCourseForAssign?.allocatedSlots?.length || 0
            }}</b>
            available spots in the
            {{ userSelectedCourseForAssign?.course?.name }}.
          </p>
        </div>
        <div class="members__selector">
          <label>Assign Members</label>
          <v-autocomplete
            variant="outlined"
            label="Select team member"
            bg-color="#fff"
            chips
            class="input_field input_dropdown"
            color="blue-grey-lighten-2"
            closable-chips
            :items="teamMemberList"
            item-title="toUserId"
            item-value="toUserId"
            multiple
            :loading="isMemberLoading"
            v-model="assignedMembers"
            :menu-props="{ contentClass: 'members__selector__dropdown' }"
            @update:modelValue="onUpdateAssignMember"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :prepend-avatar="item.raw.avatar"
                :text="item.raw.email"
              ></v-chip>
            </template>

            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props">
                <div class="members_text">
                  <h6>
                    {{ item.raw?.toUser?.firstName }}
                    {{ item.raw?.toUser?.lastName }}
                  </h6>
                  <p>{{ item.raw.email }}</p>
                </div>
                <div class="members__img">
                  <img
                    v-if="item.raw.profileImage"
                    :src="item.raw.profileImage"
                  />
                  <UserProfileLogo v-else :userName="item.raw?.userName" />
                </div>
                <v-checkbox
                  disabled
                  v-model="assignedMembers"
                  :value="item.raw.toUserId"
                  color="indigo-darken-3"
                ></v-checkbox
              ></v-list-item>
            </template>
          </v-autocomplete>
          <div class="tw-flex tw-flex-col">
            <span class="tw-text-red-600">{{ errorAlert }}</span>
            <a
              @click.prevent="openAddMemberModal"
              class="tw-cursor-pointer tw-text-[#FFA500] tw-flex tw-items-center"
              >Invite Members
              <v-icon>mdi-chevron-right</v-icon>
            </a>
          </div>
          <v-btn
            @click="onClickAssignMember"
            :loading="isLoading"
            :disabled="isLoading || isMemberLoading"
            class="button button-orange"
            width="100%"
            >Assign Members</v-btn
          >
          <v-btn
            variant="plain"
            color="#4F55F0"
            class="blue-text"
            @click="onClickCloseButton"
            >close</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
  <CheckElegibilityForAssignCourseModal
    v-if="isShowElegibilityModal"
    :eligibility="eligibility"
    @on-close="onCloseElegibilityForAssignCourseModal"
  />
  <AddTeamMemberModal
    :userCourseId="userSelectedCourseForAssign?.id"
    v-if="isOpenedAddMemberModel"
    @onClickCloseTab="onClickCloseAddMemberModel"
  />
</template>

<script setup lang="ts">
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import {
  displayToastMessage,
  getImageStringToImageURL,
} from "@/core/utils/common";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { USER_STORE } from "@/store/modules/user";
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import { COURSES_STORE } from "@/store/modules/courses";
import { MemberInvitationStatusEnum } from "@/core/enums/RolesEnum";
import coursesService from "@/core/services/courses.service";
import CheckElegibilityForAssignCourseModal from "./CheckElegibilityForAssignCourseModal.vue";
import AddTeamMemberModal from "@/modules/trade-passport/components/AddTeamMemberModal.vue";

const store = useStore();
const emit = defineEmits(["onClickCloseButton", "memberInvited"]);
const teamMemberList = ref();
const props = defineProps({
  selectedUserCourseIdForAssign: {
    type: [Number, null],
    required: true,
    default: null,
  },
  isfromCourseModule: {
    type: Boolean,
    required: true,
  },
});
const eligibility = ref(null);
const isShowElegibilityModal = ref(false);
const isShowCourseAssignModal = ref(true);
const isOpenedAddMemberModel = ref(false);
const internalInstance = getCurrentInstance();
const errorAlert = ref("");
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const assignedMembers = ref([]);
const coursesWithRemainingSlots = computed(
  () => store.getters[`${COURSES_STORE}/getCoursesWithRemainingSlots`]
);
const listOfEnrollCourse = computed(
  () => store.getters[`${COURSES_STORE}/getEnrollCourses`]
);
const isLoading = ref(false);
const isMemberLoading = ref(true);
const userSelectedCourseForAssign = ref();
const setTeamMember = async () => {
  try {
    let response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });

    response = response.filter(
      (member: any) =>
        member?.invitationStatus === MemberInvitationStatusEnum.ACCEPTED
    );
    for (const member of response) {
      if ("toUser" in member) {
        member["profileImage"] = await getImageStringToImageURL(
          member?.toUser?.userPublicProfile?.profileImage
        );
      } else {
        member["profileImage"] = await getImageStringToImageURL(
          member?.userWorkstation?.profileImage
        );
      }
    }

    teamMemberList.value = response;
  } catch (err) {
    console.log();
  }
};

const setAlreadyAssignedMember = (userCourseLists: any) => {
  userSelectedCourseForAssign.value = userCourseLists.find(
    (course: any) => course?.id == props.selectedUserCourseIdForAssign
  );
  assignedMembers.value = userSelectedCourseForAssign.value?.allocatedSlots.map(
    (allocatedSlot: any) => allocatedSlot?.user?.id
  );
  isMemberLoading.value = false;
};

const onClickCloseButton = () => {
  emit("onClickCloseButton");
};

const onClickAssignMember = async () => {
  try {
    isLoading.value = true;
    const response = await store.dispatch(
      `${COURSES_STORE}/assignUserCourseToMember`,
      {
        userId: user.value?.id,
        userCourseId: userSelectedCourseForAssign.value?.id,
        memberIds: assignedMembers.value,
      }
    );
    if (!props.isfromCourseModule) {
      store.dispatch(`${COURSES_STORE}/getCoursesWithRemainingSlots`, {
        userId: user.value.id,
      });
    } else {
      store.dispatch(`${COURSES_STORE}/getEnrollCourses`, {
        userId: user.value.id,
      });
    }
    displayToastMessage(internalInstance, response?.message, "success");
    onClickCloseButton();
  } catch (error: any) {
    console.log();
    errorAlert.value = error?.response?.data?.message;
  } finally {
    isLoading.value = false;
  }
};

const onUpdateAssignMember = async (newVal: any) => {
  try {
    isMemberLoading.value = true;
    errorAlert.value = "";
    if (newVal[newVal?.length - 1]) {
      const response: any =
        await coursesService.checkElegibilityForAssignMember(
          userSelectedCourseForAssign.value?.course?.id,
          newVal[newVal?.length - 1]
        );
      if (!response?.isEligible) {
        eligibility.value = response;
        isShowElegibilityModal.value = true;
        assignedMembers.value.pop();
      }
    }
  } catch (error) {
    console.log(error);
    newVal.pop();
  } finally {
    isMemberLoading.value = false;
  }
};

const onCloseElegibilityForAssignCourseModal = () => {
  eligibility.value = null;
  isShowElegibilityModal.value = false;
};

const onClickCloseAddMemberModel = (isMemberAdded = false) => {
  if (isMemberAdded) {
    emit("memberInvited");
  }
  isOpenedAddMemberModel.value = false;
};
const openAddMemberModal = () => {
  isOpenedAddMemberModel.value = true;
};
onBeforeMount(async () => {
  await setTeamMember();
  if (!props.isfromCourseModule) {
    setAlreadyAssignedMember(coursesWithRemainingSlots.value);
  } else {
    setAlreadyAssignedMember(listOfEnrollCourse.value);
  }
});
</script>

<style lang="scss" scoped></style>
