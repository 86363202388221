<template>
  <div class="project_details_content">
    <v-tabs class="tab-list" v-model="selectedTab" align-tabs="center" stacked>
      <v-tab
        v-for="tabItem in projectMenu"
        :key="tabItem.name"
        :value="tabItem.name"
      >
        <v-icon :icon="tabItem.icon"></v-icon>
        <span>{{ tabItem.label }}</span>
      </v-tab>
    </v-tabs>

    <v-window v-model="selectedTab">
      <v-window-item
        v-for="tabValue in projectMenu"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <HistoricProjectReviewComponent
          v-if="tabValue.name === 'project-overview'"
          :projectRefId="String($route.params?.refId)"
          @view-estimate="selectedTab = 'trades-estimates'"
          @back="showHistoricProjectsRequestTab"
        />

        <HistoricProjectEstimateComponent
          v-if="tabValue.name === 'trades-estimates'"
          :estimateId="Number($route.params?.estimateId)"
          @view-details="selectedTab = 'project-overview'"
        />
      </v-window-item>
    </v-window>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import HistoricProjectReviewComponent from "@/modules/project/components/HistoricProjectReviewComponent.vue";
import HistoricProjectEstimateComponent from "@/modules/project/components/HistoricProjectEstimateComponent.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PROJECT_ROUTE } from "../routes";

const selectedTab = ref();
const projectMenu = [
  { name: "project-overview", icon: "mdi-home", label: "Project Details" },
  {
    name: "trades-estimates",
    icon: "mdi-speedometer-slow",
    label: "Trades Estimates",
  },
];
const store = useStore();
const router = useRouter();

const showHistoricProjectsRequestTab = async () => {
  await store.dispatch(
    `${PROJECT_STORE}/setProjectRedirectTabName`,
    "historic-projects-request"
  );
  router.push({ name: PROJECT_ROUTE });
};
</script>
