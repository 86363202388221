<template>
  <svg
    width="66"
    height="67"
    viewBox="0 0 66 67"
    :class="className"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.485 29.2782C46.485 28.7504 46.0546 28.3281 45.5349 28.3281H30.2687C29.7409 28.3281 29.3105 28.7585 29.3105 29.2782C29.3105 29.7979 29.7409 30.2283 30.2687 30.2283H45.5349C46.0627 30.2283 46.485 29.7979 46.485 29.2782Z"
      fill="#F45454"
    />
    <path
      d="M20.7266 30.2325C23.3576 30.2325 25.4932 28.0887 25.4932 25.4658C25.4932 22.843 23.3576 20.6992 20.7266 20.6992C18.0956 20.6992 15.96 22.843 15.96 25.4658C15.96 28.0887 18.0956 30.2325 20.7266 30.2325ZM20.7266 22.5994C22.3019 22.5994 23.5849 23.8824 23.5849 25.4577C23.5849 27.0331 22.3019 28.3161 20.7266 28.3161C19.1512 28.3161 17.8682 27.0331 17.8682 25.4577C17.8682 23.8824 19.1512 22.5994 20.7266 22.5994Z"
      fill="#F45454"
    />
    <path
      d="M46.4845 35.0087C46.4845 34.4808 46.0542 34.0586 45.5345 34.0586H34.0848C33.557 34.0586 33.1348 34.489 33.1348 35.0087C33.1348 35.5284 33.5651 35.9587 34.0848 35.9587H45.5345C46.0623 35.9587 46.4845 35.5284 46.4845 35.0087Z"
      fill="#F45454"
    />
    <path
      d="M27.2476 33.1526C25.721 32.2513 23.358 31.1875 20.727 31.1875C18.096 31.1875 15.7249 32.2594 14.1983 33.1526C12.9234 33.8997 12.1357 35.3613 12.1357 36.9529V37.5619C12.1357 38.78 13.0533 39.7707 14.1821 39.7707H27.272C28.4007 39.7707 29.3183 38.78 29.3183 37.5619V36.9529C29.3183 35.3613 28.5306 33.8997 27.2557 33.1526H27.2476ZM27.4019 37.5619C27.4019 37.7568 27.2882 37.8624 27.2639 37.8624H14.1821C14.1821 37.8624 14.044 37.7649 14.044 37.5619V36.9529C14.044 36.0434 14.4825 35.1989 15.1646 34.7929C16.4801 34.0134 18.5183 33.0877 20.7189 33.0877C22.9195 33.0877 24.9577 34.0134 26.2732 34.7929C26.9553 35.1908 27.3938 36.0434 27.3938 36.9529V37.5619H27.4019Z"
      fill="#F45454"
    />
    <path
      d="M56.9882 39.4395C56.639 39.1066 56.2614 38.798 55.8635 38.5219C54.8972 37.856 53.7929 37.3688 52.6154 37.1008V16.8975C52.6154 16.662 52.5829 16.4347 52.5504 16.2154C52.4124 15.4115 52.0389 14.6644 51.4461 14.0717C50.6909 13.3165 49.6921 12.9023 48.6202 12.9023H10.0082C8.93631 12.9023 7.92939 13.3165 7.18233 14.0717C6.42714 14.8269 6.00488 15.8338 6.00488 16.8975V44.5309C6.00488 45.5947 6.42714 46.6016 7.18233 47.3568C7.77511 47.9496 8.52218 48.3231 9.32609 48.4611C9.38293 48.4693 9.43977 48.4693 9.49661 48.4774C9.66714 48.5017 9.83766 48.5261 10.0082 48.5261H41.247C41.5231 49.7117 42.0184 50.8079 42.6924 51.7742C43.6262 53.1222 44.9092 54.2022 46.4034 54.9168C47.6458 55.5177 49.0262 55.8669 50.496 55.8669C55.7417 55.8669 60.0049 51.6037 60.0049 46.358C60.0049 43.6296 58.8315 41.1772 56.9882 39.4395ZM40.9871 46.358C40.9871 46.358 40.9871 46.3905 40.9871 46.4067H10.0082C9.74834 46.4067 9.48849 46.3499 9.253 46.2443C9.04188 46.155 8.84699 46.0251 8.67646 45.8545C8.31917 45.4972 8.12428 45.0263 8.12428 44.5309V16.8975C8.12428 16.3941 8.31917 15.9231 8.67646 15.5739C9.02563 15.2247 9.49661 15.0217 10.0001 15.0217H48.6202C49.1237 15.0217 49.5947 15.2166 49.9438 15.5739C50.1144 15.7444 50.2362 15.9393 50.3336 16.1505C50.4392 16.386 50.496 16.6377 50.496 16.8975V36.8491C45.2503 36.8491 40.9871 41.1123 40.9871 46.358ZM50.496 53.8368C48.5065 53.8368 46.6957 53.0491 45.3559 51.7742C44.5113 50.9703 43.8617 49.9715 43.4638 48.859C43.4232 48.7453 43.3745 48.6398 43.342 48.5261C43.139 47.8521 43.0253 47.1375 43.0172 46.3986C43.0172 46.3823 43.0172 46.3661 43.0172 46.3499C43.0172 42.2816 46.2897 38.9685 50.3336 38.8792C50.3904 38.8792 50.4392 38.8711 50.496 38.8711C51.235 38.8711 51.9495 38.9848 52.6235 39.1796C53.8741 39.5532 54.9865 40.2434 55.8717 41.161C57.1709 42.509 57.9748 44.336 57.9748 46.3499C57.9748 50.475 54.6211 53.8287 50.496 53.8287V53.8368Z"
      fill="#F45454"
    />
    <path
      d="M49.9487 42.1254C49.7583 42.3158 49.6514 42.574 49.6514 42.8432V46.3958C49.6514 46.665 49.7583 46.9232 49.9487 47.1135C50.139 47.3039 50.3972 47.4108 50.6664 47.4108C50.9356 47.4108 51.1938 47.3039 51.3841 47.1135C51.5745 46.9232 51.6814 46.665 51.6814 46.3958V42.8432C51.6814 42.574 51.5745 42.3158 51.3841 42.1254C51.1938 41.9351 50.9356 41.8281 50.6664 41.8281C50.3972 41.8281 50.139 41.9351 49.9487 42.1254Z"
      fill="#F45454"
    />
    <path
      d="M49.9487 48.9262C49.7583 49.1165 49.6514 49.3747 49.6514 49.6439C49.6514 49.9131 49.7583 50.1714 49.9487 50.3618C50.139 50.5521 50.3972 50.6591 50.6664 50.6591C50.9356 50.6591 51.1938 50.5521 51.3841 50.3618C51.5745 50.1714 51.6814 49.9131 51.6814 49.6439C51.6814 49.3747 51.5745 49.1165 51.3841 48.9262C51.1938 48.7358 50.9356 48.6289 50.6664 48.6289C50.3972 48.6289 50.139 48.7358 49.9487 48.9262Z"
      fill="#F45454"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
