<template>
  <section class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4">
    <div
      class="tw-w-full tw-h-full rmd:tw-min-w-[440px] rmd:tw-w-[440px] rmd:!tw-min-h-[198px] tw-flex tw-flex-col tw-gap-4 rmd:tw-px-2 tw-box-border tw-overflow-y-hidden"
    >
      <PropertyCard />
    </div>
    <div
      class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start tw-gap-4 tw-box-border"
    >
      <CollapsiblePropertyKypScore
        @route-to-project-logs="onRouteToProjectLogs"
      />
      <CollapsiblePropertyPerformance />
      <CollapsiblePropertyAssetRegister v-if="!isSiteVisitPropertyDetail" />
      <!-- <CollapsibleInsurance />
      <CollapsiblePropertyTitleDeeds />
<CollapsiblePropertyPlanningAndComparables /> 
      <CollapsiblePropertyPriceHistory />
      <CollapsiblePropertyRics /> -->
    </div>
  </section>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import PropertyCard from "@/modules/properties/components/Cards/PropertyCard.vue";
import CollapsiblePropertyKypScore from "../Collapsibles/CollapsiblePropertyKypScore.vue";
import CollapsiblePropertyPerformance from "../Collapsibles/CollapsiblePropertyPerformance.vue";
import CollapsiblePropertyAssetRegister from "../Collapsibles/CollapsiblePropertyAssetRegister.vue";
import CollapsibleInsurance from "../Collapsibles/CollapsibleInsurance.vue";
import CollapsiblePropertyTitleDeeds from "../Collapsibles/CollapsiblePropertyTitleDeeds.vue";
// import CollapsiblePropertyPlanningAndComparables from "../Collapsibles/CollapsiblePropertyPlanningAndComparables.vue";
import CollapsiblePropertyPriceHistory from "@/modules/properties/components/Collapsibles/CollapsiblePropertyPriceHistory.vue";
import CollapsiblePropertyRics from "@/modules/properties/components/Collapsibles/CollapsiblePropertyRics.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
const store = useStore();
const emits = defineEmits(["route-to-project-logs"]);
const isSiteVisitPropertyDetail = computed(
  () => store.getters[`${PROPERTY_STORE}/getIsSiteVisitPropertyDetail`]
);
const onRouteToProjectLogs = () => {
  emits("route-to-project-logs");
};
</script>
<style lang="scss" scoped></style>
