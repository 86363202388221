import {
  AuthenticationState,
  ForgetPasswordTempUser,
} from "@/core/models/authentication";
export default {
  setForgotPasswordTempUser(
    state: AuthenticationState,
    payload: ForgetPasswordTempUser
  ) {
    state.forgotPasswordTempUser = payload;
  },
};
