<template>
  <div class="verifed_jobs__wrapper">
    <div>
      <!-- Heading -->
      <div class="heading">
        <h3 class="tw-flex tw-items-center">Your Verified Jobs</h3>
      </div>
      <div class="tw-mb-8"></div>

      <!-- Total Results -->
      <div class="tw-flex tw-gap-3">
        <p>
          <span class="tw-font-bold tw-font-xs">{{ verifyJobs.length }}</span>
          Results
        </p>
      </div>
      <div class="tw-mb-8"></div>

      <!-- Cards -->
      <div class="tw-flex tw-gap-4">
        <div class="tw-basis-full">
          <div
            class="tw-grid tw-gap-7 sm:tw-grid-cols-1 md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-grid-cols-4"
          >
            <VerifiedJobCard
              v-for="job in verifyJobs"
              :key="job.id"
              :jobDetails="job.project"
              class="verified__jobs__card"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CommonLoader :loading="isLoading" />
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import VerifiedJobCard from "./VerifiedJobCard.vue";
import { useStore } from "vuex";
import { JOBS_STORE } from "@/store/modules/jobs";
import { HistoricProjectStatusEnum } from "@/core/enums/ProjectsEnum";
import CommonLoader from "@/core/components/CommonLoader.vue";

const store = useStore();

const isLoading = ref(false);
const verifyJobs = computed(() => {
  const jobs = store.getters[`${JOBS_STORE}/getHistoricalJobs`];
  return jobs.filter(
    (data) =>
      data.project?.isHistoricProject &&
      data.project?.projectHistoricalStatus ==
        HistoricProjectStatusEnum.VERIFIED
  );
});
onBeforeMount(async () => {
  isLoading.value = true;
  try {
    await store.dispatch(`${JOBS_STORE}/fetchHistoricalJobs`);
  } catch (error) {
    console.log("error", error);
  } finally {
    isLoading.value = false;
  }
});
</script>
<style lang="scss" scoped>
.verifed_jobs__wrapper {
  .verified__jobs__card {
  }
}
</style>
