<template>
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4635 3.92982H9.16402L7.71206 2.20413C7.66321 2.14541 7.59002 2.11249 7.5137 2.11487H1.62453C0.725386 2.12141 0 2.85215 0 3.7513V14.3653C0.00109095 15.2675 0.732229 15.9986 1.63445 15.9997H16.4635C17.3657 15.9986 18.0969 15.2675 18.0979 14.3653V5.56426C18.0969 4.66204 17.3657 3.93091 16.4635 3.92982Z"
      :fill="fill"
    />
    <path
      d="M18.6094 1.81507H11.3079L9.85595 0.0893804C9.80711 0.0306673 9.73391 -0.00225954 9.6576 0.000120717H3.77041C2.95457 0.00145961 2.26469 0.604161 2.15381 1.41241H7.52329C7.80486 1.41171 8.07219 1.53598 8.25324 1.75159L9.49296 3.22537H16.4652C17.7547 3.22755 18.7996 4.27244 18.8018 5.56199V13.8691C19.6194 13.7671 20.2333 13.0725 20.2339 12.2485V3.44753C20.2318 2.54992 19.5069 1.82161 18.6094 1.81507Z"
      :fill="fill"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#FFA500", // #2A52CB blue, #919191 light grey, #615B52 dark grey, #68C693 green
  },
});
</script>

<script>
export default {
  name: "FolderYellow",
};
</script>
