import { UserMessagesRoomStatusEnum } from "@/core/enums/chatsEnums";
import chatService from "@/core/services/chat.service";
import { Commit } from "vuex";

export default {
  async setNetworkDirectMessage(
    { commit }: { commit: Commit },
    payload: object
  ) {
    commit("setNetworkDirectMessage", payload);
  },
  async setCurrentMessageType({ commit }: { commit: Commit }, payload: string) {
    commit("setCurrentMessageType", payload);
  },
  async setProjectChatRequests({ commit }: any, payload: Array<any>) {
    commit("setProjectChatRequests", payload);
  },

  async acceptProjectChatStatus(
    { commit }: { commit: Commit },
    payload: { userMessageId: number; status: UserMessagesRoomStatusEnum }
  ) {
    try {
      await chatService.acceptProjectChatStatus(payload.userMessageId, {
        status: payload.status,
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async rejectProjectChatStatus(
    { commit }: { commit: Commit },
    payload: { userMessageId: number }
  ) {
    try {
      await chatService.rejectProjectChatStatus(payload.userMessageId);
    } catch (error) {
      console.log("error", error);
    }
  },
  async getKrystalConversation({ commit }: { commit: Commit }) {
    try {
      const response = await chatService.getKrystalConversation();
      if (response?.questions) {
        commit("setKrystalConversation", response?.questions);
      } else {
        commit("setKrystalConversation", []);
      }
    } catch (error) {
      console.log("error", error);
    }
  },
  async getKrystalChatResponse(
    { commit }: { commit: Commit },
    payload: {
      prompt: string;
    }
  ) {
    try {
      const response = await chatService.getKrystalChatResponse(payload);
      commit("popKrystalConversation");
      commit("pushKrystalConversation", response?.question);
    } catch (error) {
      console.log(error);
    }
  },

  // chatMessages Store
  async getUserRoomMessages(
    { commit }: { commit: Commit },
    workstationId: number
  ) {
    try {
      const response = (await chatService.getUserMessageRoomByProjectId(
        workstationId
      )) as any;
      commit("setUserRoomMessages", response);

      const messageRooms = response?.filter((room: any) => {
        return !room.isArchiveRoom;
      });

      commit("setFilterMessageRooms", messageRooms);

      return messageRooms;
    } catch (error) {
      console.log("error", error);
    }
  },

  async archiveMessageRoom({ commit }: { commit: Commit }, data: any) {
    try {
      const payload = {
        roomId: data.id,
      };
      await chatService.archiveMessageRoom(payload);

      commit("updateUserRoomMessage", data);
    } catch (error) {
      console.log("error", error);
    }
  },
  async unArchiveMessageRoom({ commit }: { commit: Commit }, data: any) {
    try {
      await chatService.unarchiveMessageRoom(data.id);
      commit("updateUserRoomMessage", data);
    } catch (error) {
      console.log("error", error);
    }
  },
  async modifyChatMessageRoomAfterNewMesage(
    { commit, state }: { commit: Commit; state: any },
    msgRoom: any
  ) {
    try {
      const msgStatuses = msgRoom?.userMessageStatus?.find((msgStatus: any) => {
        return msgStatus.id == msgRoom.userMessages.id;
      });
      if (msgStatuses) {
        await chatService.modifyChatMessageRoomAfterNewMesage(msgStatuses);
      }

      commit("modifyChatMessageRoomAfterNewMesage", msgRoom);
    } catch (error) {
      console.log("error", error);
    }
  },
};
