/**
 * IssueProperty route page
 */
import IssueProperty from "@/modules/issues/pages/IssuePropertyPage.vue";

export const ISSUE_ROUTE = "raise-issue";

export default {
  path: "/raise-issue",
  name: ISSUE_ROUTE,
  component: IssueProperty,
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Issue",
  },
};
