<template>
  <div class="map-container">
    <div v-if="IsLoading" class="loader-container">
      <CommonLoader :loading="IsLoading" class="loader-container" />
    </div>
    <div id="map" class="map" ref="mapElement"></div>

    <!-- :class="{
      thumbnail: latlng && property && !address && !isModalMap,
      'review-map': isModalMap && latlng && property && !address,
      'whole-map': !isModalMap && latlng && !property && !address,
    }" -->
  </div>
</template>
<script lang="ts">
import { ref, onMounted, watch, computed, reactive } from "vue";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { VUE_APP_LEAFLET_LAYER_URL } from "@/config";
export default {
  name: "AddressMapComponent",
  props: {
    address: {
      type: Object,
    },
    latlng: {
      type: Object,
    },
    isManual: {},
    property: {
      type: Object,
    },
    isModalMap: {},
    addresses: {},
  },
  setup(props: any, ctx: any) {
    const OS_MAP_API_KEY = "mO9BPArB5oA2Ai8YIPs4nZQza4GhGFHh";
    const map: any = ref(null);
    const marker: any = ref(null);
    const IsLoading = ref(false);
    const mapElement = ref(null);

    onMounted(() => {
      IsLoading.value = true;
      if (
        props.latlng &&
        props.property &&
        !props.address &&
        !props.isModalMap
      ) {
        // initThumbnailMap();
      } else if (
        props.latlng &&
        props.property &&
        !props.address &&
        props.isModalMap
      ) {
        // initReviewMap();
      } else if (props.addresses && props.addresses?.length) {
        setTimeout(() => {
          initViewWholeMap();
          IsLoading.value = false;
        }, 3000);
      } else {
        initMap();
        IsLoading.value = false;
      }
    });
    const popupTemplate = computed(() => {
      if (props.property) {
        return `<b>${props.property?.address}</b><br />UPRN: ${props.property?.uprn}`;
      }
      return `<b>${props.address?.ADDRESS}</b><br />UPRN: ${props.address?.UPRN}`;
    });
    const initMap = () => {
      map.value = L.map("map", { attributionControl: false }).setView(
        props?.address?.latlng,
        16
      );

      const icon = new L.Icon({
        iconUrl: require("../../assets/images/map-marker.svg"),
        iconAnchor: [14, 40],
        popupAnchor: [1, -34],
      });

      L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(
        map.value
      );
      marker.value = L.marker(props?.address?.latlng, { icon });
      map.value.on("movestart", () => {
        map.value.getCenter().toString();
      });
      watch(props.address, (newAddress, oldAddress) => {
        if (newAddress !== oldAddress) {
          map.value.setView(newAddress.latlng).zoomIn(30);
          marker.value
            .setLatLng(newAddress.latlng)
            .unbindPopup()
            .bindPopup(popupTemplate.value)
            .openPopup();
          marker.value
            .on("movestart", () => {
              marker.value.getCenter().toString();
            })
            .zoomIn(30);

          if (!newAddress.UPRN) {
            map.value.removeLayer(marker.value);
          } else {
            marker.value
              .addTo(map.value)
              .unbindPopup()
              .bindPopup(popupTemplate.value)
              .openPopup();
          }
        }
      });
    };
    const initViewWholeMap = () => {
      const baseLatLang: any = [51.509865, -0.118092];

      // console.log("mapElement==:", mapElement, mapElement.value);
      if (mapElement.value) {
        try {
          map.value = L.map("map", {
            attributionControl: false,
            zoomControl: false,
            minZoom: 7,
            zoomAnimation: false,
            fadeAnimation: true,
            layers: [
              L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                  maxZoom: 19,
                  attribution:
                    '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                  detectRetina: true,
                }
              ),
            ],
            markerZoomAnimation: true,
          }).setView(baseLatLang, 7);

          const icon = new L.Icon({
            iconUrl: require("../../assets/images/map-marker.svg"),
            iconAnchor: [1, 35],
            popupAnchor: [11, -34],
            iconSize: [25, 30],
          });

          // L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`, {
          //   maxZoom: 24,
          //   maxNativeZoom: 20,
          //   opacity: 0.5,
          // }).addTo(map.value);

          props.addresses.forEach((address: any) => {
            const latlang: any = new Array(...address?.latlang);
            /* eslint-disable global-require */
            const template = `
        <div class="marker-container flex-column jc-c ai-c">
          <div class="marker-container__row flex-row ai-fs">
            <div class="marker-container__thumbnail-container flex-row ai-c jc-c">
              <el-image fit="cover" :src="${require("@/assets/images/property-sample-image.jpg")}"></el-image>
            </div>
            <div class="marker-container__row flex-column ai-fs">
              <p class="marker-container__address-text default-text">${
                address.address
              }</p>
              <p class="marker-container__postcode-text default-text">${
                address.postcode
              }</p>
            </div>
          </div>
        </div>`;
            /* eslint-disable global-require */
            // <b>${address?.address}</b><br />UPRN: ${address?.uprn}

            marker.value = L.marker(latlang, { icon });

            marker.value
              .addTo(map.value)
              .unbindPopup()
              .bindPopup(template)
              .openPopup();

            marker.value.on("click", () => {
              ctx.emit("show-details", address);
            });
          });

          // L.tileLayer(
          //   `${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`
          // ).addTo(map.value);

          map.value.on("movestart", () => {
            map.value.getCenter().toString();
          });

          const container: any = L.DomUtil.get("map");
          if (container !== null) {
            container.id = null;
          }
        } catch (error) {
          console.log("error", error);
          IsLoading.value = false;
        }
      }
    };
    return { IsLoading, mapElement };
  },
  components: {
    CommonLoader,
  },
};
</script>
<style scoped>
.map-container {
  display: flex;
  flex: 1;
  /* margin: 125px 0 0 0; */
  height: 100%;
  position: relative;
}

.map {
  width: 100%;
  max-height: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(42, 42, 42, 0.02);
}

.map-container.thumbnail {
  margin: 0;
  width: 85px;
  max-width: 85px;
  height: 85px;
  min-width: 85px;
}

.map-container.review-map {
  min-width: 400px;
  min-height: 300px;
  margin: 0;
}

.map-container.whole-map {
  margin: 0;
  height: 100%;
  width: 100%;
}

:deep(.marker-container) {
  width: auto;
}

:deep(.marker-container__row) {
  width: 100%;
  gap: 0.5rem;
}

:deep(.marker-container__thumbnail-container) {
  width: 70px;
  height: 70px;
  min-width: 70px;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(12, 15, 74, 0.05);

  /* .el-image {
    width: 100%;
    height: 100%;
  } */
}

:deep(.marker-container__address-text) {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

:deep(.marker-container__postcode-text) {
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: rgba(12, 15, 74, 0.5);
}

:deep(.marker-container__cta-text) {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: #ffa500;
}

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 50px;
  height: 50px;
}
</style>
