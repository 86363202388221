<template>
  <GeneralDialog
    width="475px"
    class="contract_assign_modal"
    @on-close="onCloseModal"
    headerTitle="Select phase to assign"
    headerDescription="Select a phase to assign this item to that phase."
  >
    <template #body>
      <div class="selector">
        <p v-if="!formValid">Please Select One Phase</p>
        <div class="custom_selector_wrapper" @click="onClickSelectBox">
          <p v-if="!selectedPhase">Select phase</p>
          <p v-else>{{ selectedPhase?.name }}</p>

          <v-icon icon="mdi-chevron-down" v-if="!isShowDropDown"></v-icon>
          <v-icon icon="mdi-chevron-up" v-if="isShowDropDown"></v-icon>
        </div>
        <div class="custom_selector_options" v-if="isShowDropDown">
          <v-form
            ref="form"
            v-model="formValid"
            class="tw-max-w-full tw-flex tw-flex-col tw-gap-6"
          >
            <div class="tw-flex tw-gap-1">
              <v-radio-group
                v-model="selectedPhaseId"
                @update:model-value="onClickSelectPhase"
                placeholder="Select of Phase"
                :rules="[(v) => !!v || 'Phase is required']"
              >
                <div
                  class="tw-flex tw-items-center tw-gap-1.5 tw-cursor-pointer"
                  v-for="(phase, index) in assignPhases"
                  :key="phase?.id"
                >
                  <v-radio class="custom_radio" :value="phase?.id" />

                  <span
                    class="tw-text-sm tw-font-normal tw-leading-5 tw-text-[#0C0F4A]"
                  >
                    Phase {{ index + 1 }} - {{ phase?.name }}
                  </span>
                </div>
              </v-radio-group>
            </div>
          </v-form>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full">
        <Button label="Save" @click="onSavePhases" :disabled="!formValid" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { ref, onMounted, computed } from "vue";
const emits = defineEmits(["on-close", "on-save"]);
const props = defineProps({
  assignPhases: {
    type: Array,
  },
  selectedAssignPhase: {
    type: Object,
  },
});
const formValid = ref(false);
const isShowDropDown = ref(false);
const selectedPhaseId = ref(null) as any;
const selectedPhase = ref(null) as any;

const onCloseModal = () => {
  emits("on-close");
};
const onClickSelectBox = () => {
  isShowDropDown.value = !isShowDropDown.value;
};
const onSavePhases = () => {
  if (selectedPhaseId.value && formValid.value) {
    const payload = {
      assignPhaseId: selectedPhaseId.value,
    };
    emits("on-save", payload, selectedPhase.value);
  }
};

const onClickSelectPhase = () => {
  const checkedPhase = props.assignPhases?.find(
    (phase) => phase?.id === selectedPhaseId.value
  );
  selectedPhase.value = checkedPhase;
};

onMounted(() => {
  if (props.selectedAssignPhase) {
    selectedPhaseId.value = props.selectedAssignPhase.id;
    selectedPhase.value = props.selectedAssignPhase;
    formValid.value = true;
  } else {
    selectedPhase.value = null;
  }
});
</script>

<style lang="scss" scoped>
.c-select {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      padding: 0;
      border: 1px solid rgba($buttonText, 1);
      border-radius: 8px;
      gap: 8px;
      background-color: transparent;
      .v-field__field {
        .v-field__input {
          background-color: transparent;
          min-height: 42px;
          padding: 0;
          input {
            @include fluidFont(12, 12, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            opacity: 1;
          }
          &::placeholder {
            @include fluidFont(12, 12, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
          }
        }
        .v-text-field__suffix {
          padding: 0;
          min-height: 42px;
        }
      }
    }
  }
}
.selector {
  .custom_selector_wrapper {
    width: 100%;
    border: 1px solid rgba($blueDark, 0.5);
    border-radius: 8px;
    padding: 8px 12px;
    @include fluidFont(12, 12, 1.3);
    font-weight: 400;
    color: rgba($blueDark, 1);
    letter-spacing: 0.4px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
  .custom_selector_options {
    margin-top: 16px;
    padding: 16px;
    box-shadow: 0px 0px 20px 0px #2a2a2a1a;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .v-input {
      :deep(.v-input__control) {
        .v-selection-control-group {
          gap: 16px;
        }
      }
    }
  }
}
.custom_radio {
  width: auto;
  flex: unset;
  margin-right: 6px;
  :deep(.v-selection-control__wrapper) {
    width: auto;
    height: auto;
    .v-selection-control__input {
      width: auto;
      height: auto;
      .mdi-radiobox-marked {
        color: #4b4bff;
      }
    }
  }
}
</style>
