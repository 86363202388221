<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#0C0F4A" />
    <path
      d="M33.0169 19.9917C33.4148 20.5583 32.9405 21.25 32.2482 21.25H15C14.4477 21.25 14 20.8023 14 20.25V18.42C14 15.98 15.98 14 18.42 14H20.74C22.37 14 22.88 14.53 23.53 15.4L24.93 17.26C25.24 17.67 25.28 17.72 25.86 17.72H28.65C30.4546 17.72 32.0516 18.6171 33.0169 19.9917Z"
      fill="white"
    />
    <path
      d="M32.9834 22.75C33.5343 22.75 33.9815 23.1957 33.9834 23.7466L34 28.6503C34 31.6003 31.6 34.0003 28.65 34.0003H19.35C16.4 34.0003 14 31.6003 14 28.6503V23.7503C14 23.198 14.4477 22.7503 15 22.7503L32.9834 22.75Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "FolderCircleIcon",
};
</script>
