<template>
  <GeneralDialog
    ><template #body>
      <div class="confirm-assign-modal-body">
        <h1>Do you wants to assign your certificate & specialisms?</h1>
        <p>
          Once enabled, Contractors will be able to request site visits for
          properties listed in this project, if required.
        </p>
      </div></template
    ><template #footer>
      <div class="confirm-assign-modal-footer">
        <v-btn class="button button-purple-border" @click="onclickNo">NO</v-btn
        ><v-btn @click="onClickYes" class="button button-orange">YES</v-btn>
      </div>
    </template></GeneralDialog
  >
</template>

<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import UserService from "@/core/services/user.service";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const assignedWorkStationData = computed(
      () => store.getters[`${WORKSTATION}/getAssignedWorkStationData`]
    );

    const onclickNo = () => {
      ctx.emit("onclickNo");
    };

    const onClickYes = async () => {
      await UserService.assignWorkStationData(
        user.value.id,
        activeUserWorkstation.value.id,
        assignedWorkStationData.value
      );
      ctx.emit("onCloseConfirmModal");
    };
    return { onClickYes, onclickNo };
  },
};
</script>

<style lang="scss" scoped>
.confirm-assign-modal {
  &-body {
    color: rgba($blueDark, 0.5);
    width: 400px;
    padding: 24px 0 10px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.15px;

    @include respond(s720) {
      width: 100%;
    }

    h1 {
      @include fluidFont(24, 24, 30px);
      font-weight: 600;
      margin-bottom: 24px;
      color: $blueDark;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 6px;

    @include respond(s720) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .button {
      min-width: 78px;
    }
  }
}
</style>
