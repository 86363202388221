<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.750041 10.6667C0.750041 11.4 1.35004 12 2.08337 12H7.41671C8.15004 12 8.75004 11.4 8.75004 10.6667V4C8.75004 3.26667 8.15004 2.66667 7.41671 2.66667H2.08337C1.35004 2.66667 0.750041 3.26667 0.750041 4V10.6667ZM8.75004 0.666667H7.08337L6.61004 0.193333C6.49004 0.0733333 6.31671 0 6.14337 0H3.35671C3.18337 0 3.01004 0.0733333 2.89004 0.193333L2.41671 0.666667H0.750041C0.383374 0.666667 0.083374 0.966667 0.083374 1.33333C0.083374 1.7 0.383374 2 0.750041 2H8.75004C9.11671 2 9.41671 1.7 9.41671 1.33333C9.41671 0.966667 9.11671 0.666667 8.75004 0.666667Z"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#0C0F4A",
  },
  fillOpacity: {
    type: String,
    default: "0.5",
  },
});
</script>
<script>
export default {
  name: "LoadIcon",
};
</script>
