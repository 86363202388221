export default {
  setEntity(state: { [x: string]: any }, payload: { name: any; entity: any }) {
    const { name, entity } = payload;

    state[name] = entity;
  },

  resetEntityState(state: { [x: string]: never[] }, name: string | number) {
    if (state[name]) {
      state[name] = [];
    }
  },

  appendEntity(
    state: { [x: string]: any },
    payload: { name: any; entity: any }
  ) {
    const { name, entity } = payload;
    let current = state[name];

    if (!Array.isArray(current)) {
      current = [];
    }
    current.push(entity);

    state[name] = current;
  },

  mergeEntity(
    state: { [x: string]: unknown[] },
    payload: { name: any; entity: any }
  ) {
    const { name, entity } = payload;
    let current = state[name];

    if (!Array.isArray(current)) {
      current = [];
    }

    current.push(...entity);

    const newList = [
      ...new Map(
        current.filter(Boolean).map((item: any) => {
          return [item.attachment, item];
        })
      ).values(),
    ];

    state[name] = newList;
  },
};
