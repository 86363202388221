<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M1.82226 0.998798C1.91544 0.874513 1.89021 0.698226 1.76592 0.605062C1.64164 0.511883 1.46537 0.537111 1.37219 0.661396L0.423392 1.92702C0.348453 2.02699 0.348453 2.16444 0.423392 2.26442L1.37219 3.53005C1.46537 3.65433 1.64164 3.67955 1.76592 3.58638C1.89021 3.4932 1.91544 3.31693 1.82226 3.19265L1.21078 2.37696H9.9086L9.26409 3.18612C9.16733 3.30761 9.18737 3.48456 9.30887 3.58133C9.43035 3.67811 9.6073 3.65807 9.70408 3.53657L10.7122 2.27095C10.7939 2.1684 10.7939 2.02303 10.7122 1.9205L9.70408 0.654871C9.6073 0.533371 9.43035 0.513318 9.30887 0.610096C9.18737 0.706874 9.16733 0.883823 9.26409 1.00532L9.9086 1.81446H1.21078L1.82226 0.998798Z"
        :fill="fill"
        :fill-opacity="fillOpacity"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.367188 4.97852C0.367188 4.66786 0.619033 4.41602 0.929688 4.41602H10.2812C10.5919 4.41602 10.8438 4.66786 10.8438 4.97852V14.4707C10.8438 14.7813 10.5919 15.0332 10.2812 15.0332H0.929688C0.619033 15.0332 0.367188 14.7813 0.367188 14.4707V4.97852Z"
        :fill="fill"
        :fill-opacity="fillOpacity"
      />
      <path
        d="M11.814 13.6344C11.9072 13.5102 12.0835 13.4849 12.2078 13.5781L13.0235 14.1896V5.4918L12.2143 6.1363C12.0928 6.23307 11.9159 6.21303 11.8191 6.09153C11.7223 5.97003 11.7423 5.79309 11.8638 5.69631L13.1295 4.68821C13.232 4.60653 13.3774 4.60653 13.4799 4.68821L14.7456 5.69631C14.8671 5.79309 14.8872 5.97003 14.7903 6.09153C14.6935 6.21303 14.5166 6.23307 14.3951 6.1363L13.586 5.4918V14.1896L14.4016 13.5781C14.5259 13.4849 14.7022 13.5102 14.7953 13.6344C14.8886 13.7587 14.8633 13.935 14.7391 14.0281L13.4735 14.9769C13.3735 15.0519 13.2359 15.0519 13.136 14.9769L11.8704 14.0281C11.7461 13.935 11.7209 13.7587 11.814 13.6344Z"
        :fill="fill"
        :fill-opacity="fillOpacity"
      />
    </g>
  </svg>
</template>
<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#0C0F4A",
  },
  fillOpacity: {
    type: String,
    default: "0.972549",
  },
});
</script>
<script>
export default {
  name: "UpwardGreenIcon",
};
</script>
