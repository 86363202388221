import { createApp } from "vue";
import VueDragResize from "vue-drag-resize";

// Add MaterialDesign Icon
import "@mdi/font/css/materialdesignicons.css";

// ADD ELEMENT PLUS
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { setupCalendar } from "v-calendar";
import { dragscroll } from "vue-dragscroll";
import ClickOutside from "@/core/directives/ClickOutsideDirective";

import VueFlagIcon from "vue-flag-icon";

import App from "./App.vue";

// ADD Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import VueClipboard from "vue-clipboard2";
// ADD Tailwind
import "./index.css";

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi", // This is already the default value - only for display purposes
  },
  components,
  directives,
});

import router from "./router";
import store from "./store";

const app = createApp(App);

app.component("vue-drag-resize", VueDragResize);

app.use(vuetify);
app.use(ElementPlus);
app.use(store);
app.use(router);

app.use(VueFlagIcon);
app.use(VueClipboard);
app.use(setupCalendar, {});
app.directive("dragscroll", dragscroll);
app.directive("click-outside", ClickOutside);
app.mount("#app");

(window as any).intercomSettings = {
  app_id: process.env.VUE_APP_INTERCOM_APP_ID, // Use VUE_APP_ prefix for Vue CLI to expose the variable
};

(function () {
  const w: any = window;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    const i: any = function () {
      // eslint-disable-next-line prefer-rest-params
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src =
        "https://widget.intercom.io/widget/" +
        process.env.VUE_APP_INTERCOM_APP_ID;
      const x: any = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();

export default app;
