import $axios from "../utils/axios-api-config";

export default {
  getUserDocuments(userId: number, options?: any) {
    const params = new URLSearchParams(options);
    return $axios.get(`/users/${userId}/documents?${params.toString()}`);
  },
  getContractorDocuments(userId: number, workStationId: number, options?: any) {
    const params = new URLSearchParams(options);
    return $axios.get(
      `/users/${userId}/documents/trade-passport/${workStationId}?${params.toString()}`
    );
  },

  getTradePassportGallery(userId: number) {
    return $axios.get(`/users/${userId}/documents/gallery`);
  },
  getContractorTradePassportGallery(userId: number, workStationId: number) {
    return $axios.get(
      `/users/${userId}/documents/gallery/trade-passport/${workStationId}`
    );
  },

  // getUserDocumentCalculatedSize(userId: number, userDocumentId: number) {
  //   return $axios.get(`/users/${userId}/documents/${userDocumentId}/calc-size`);
  // },

  getUserDocumentCalculatedSize(
    userId: number,
    userDocumentId: number,
    workStationId: any
  ) {
    const documentId = userDocumentId;
    const params = new URLSearchParams({ workStationId });
    return $axios.get(
      `/users/${userId}/documents/${documentId}/totalize?${params.toString()}`
    );
  },

  getUserDocumentTotalSize(userId: number) {
    return $axios.get(`/users/${userId}/documents/totalize`);
  },

  createUserDocument(userId: number, formData: any): Promise<any> {
    return $axios.post(`/users/${userId}/documents?`, formData);
  },

  updateUserDocument(userId: number, id: number, formData: any): Promise<any> {
    return $axios.put(`/users/${userId}/documents/${id}`, formData);
  },

  moveUserDocuments(userId: number, formData: any): Promise<any> {
    return $axios.put(`/users/${userId}/documents/move`, formData);
  },

  deleteUserDocuments(userId: number, formData: any): Promise<any> {
    return $axios.delete(`/users/${userId}/documents`, { data: formData });
  },
  getWorkStationList(userId: number, name: any): Promise<any> {
    return $axios.get(`/users/${userId}/documents/workstation?search=${name}`);
  },
  shareDocument(payload: any): Promise<any> {
    return $axios.post(`workstation-share-file`, payload);
  },
};
