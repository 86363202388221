import { Analytics } from "./../../../core/models/analytics";

export default {
  setAnalyticsData(state: Analytics, payload: any) {
    state.estimatesSent = payload;
    state.jobsCompleted = payload;
    state.customerRating = 5;
  },

  setTradeAnalytics(state: Analytics, payload: any) {
    state.estimatesSent = payload?.estimateSent;
    state.jobsCompleted = payload?.jobCompleted;
    state.revenueData = payload?.revenueData;
  },

  setRevenueService(state: Analytics, payload: any) {
    state.revenueAndJobService = payload;
  },

  setRevenuePerformance(state: Analytics, payload: any) {
    state.revenuePerformanceData = payload;
    console.log(
      state.revenuePerformanceData,
      "state.revenuePerformanceData in mutations"
    );
  },

  setTeamPerformance(state: Analytics, payload: any) {
    state.teamPerformance = payload;
  },
};
