import { PropertyTypeEnum } from "@/core/enums/PropertiesEnum";

export default {
  propertyForm: {
    properties: [
      {
        address: "",
        postcode: "",
        epcData: null,
        propertyCategory: PropertyTypeEnum.RESIDENTIAL,
        nickname: "",
        propertyRole: "",
        propertyType: "",
        uprn: "",
      },
    ],
  },
  properties: [],
  totalpages: 0,
  totalcount: 0,
  propertyDetails: {},
  selectedProperties: [],
  epcCategoryDataList: [],
  epcDataListByCategoryId: {},

  assetRegisterForm: {
    description: "", // form
    idNumber: "", // form
    location: "", // form  // ask if location can be anywhere or location is just the same as the property?
    size: "", // form // format W*L*H
    accessInformation: "", // form
    assetPropertyId: "", // Property id from the system?

    supplier: "",
    installer: "",
    purchasePrice: 0,
    dateOfAcquisition: "",
    purchaseOrderNumber: "",
    dateOfDelivery: "",
    ownershipRecords: "",
    currentValue: 0, // Assuming 'currentValue' should be a number
    conditionsAndDefects: "",
    maintenanceRequirements: "",
    informationAboutSpares: "",
    runningCost: 0, // Assuming 'runningCost' should be a number
    energyPerformance: "",
    certification: "",
    attachments: [], // and how to upload?
  },
  assetRegisters: [],
  assetRegisterDetails: {},
  selectedAsset: null,

  propertyProjects: [],

  propertyMapLayers: [],
  propertySafetyCheckList: [],

  newPropertyProjectData: {
    propertyId: null,
  },
  propertyLeasesData: null,
  propertyProjectSummaryList: [],
  isSiteVisitPropertyDetail: false,
  siteVisitProjectRefId: "",
};
