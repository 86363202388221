/**
 * Owner Onboarding route page
 */
import OwnerOnboardingRoute from "@/modules/owner-onboarding/components/OwnerOnboardingPage.vue";

export const OWNER_ONBOARDING_ROUTE = "owner-onboarding";

export default {
  path: "/owner-onboarding",
  name: OWNER_ONBOARDING_ROUTE,
  component: OwnerOnboardingRoute,
  meta: {
    requiresAuth: true,
    layout: "Authlayout",
  },
};
