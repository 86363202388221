<template>
  <commonDialog
    HeaderTittle="Create a Trade Workstation"
    :onClickBackButton="onClickBackButton"
    class="create__trade__modal__box"
    max-width="100%"
    width="570px"
  >
    <template v-slot:body>
      <div class="create__trade__modal__setting">
        <div class="create__trade__modal__setting__row">
          <h4>
            You're almost finished! Just set up your workstation to upload the
            Trade Passport details.
          </h4>
          <div class="create__trade__modal__setting__left">
            <div class="bottom-btn">
              <v-btn class="button button-orange" @click="onClickCreateWS">
                Create a Trade Workstation
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { WORKSTATION } from "@/store/modules/workstation";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();

    const onClickCreateWS = async () => {
      await router.push({ name: DASHBOARD_ROUTE });
      await store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: true,
      });
    };
    const onClickBackButton = () => {
      ctx.emit("onClickCloseTab");
    };

    return { onClickCreateWS, onClickBackButton };
  },
};
</script>
