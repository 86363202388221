<template>
  <main>
    <div class="tw-max-w-[1320px] tw-w-full tw-h-full tw-mx-auto tw-text-left">
      <div class="tw-mb-4">
        <Text
          variant="h4"
          class="tw-text-center !tw-leading-normal rlg:!tw-text-[28px]"
          >Hello, {{ details.userName }}</Text
        >
      </div>

      <div
        class="tw-grid rlg:tw-grid-cols-2 tw-gap-4 rmd:tw-gap-x-5 rmd:tw-gap-y-6"
      >
        <div
          class="tw-rounded-[16px] tw-bg-[#F8FBFD] tw-p-4 rmd:tw-py-4 rmd:tw-px-6 rlg:tw-col-span-2 tw-border tw-border-solid tw-border-[#DFDFDF]"
        >
          <Text variant="h3" class="!tw-text-[16px] rmd:!tw-text-[20px]"
            >Getting started with iknowa</Text
          >

          <div
            class="tw-flex llg:tw-flex-col tw-items-center tw-gap-9 tw-mt-2 llg:tw-items-start"
          >
            <div
              class="tw-bg-[#C5D2F23D] tw-border tw-border-[#DFDFDF] tw-py-4 tw-px-8 tw-rounded-[8px] rlg:tw-w-[292px] tw-shrink-0 tw-opacity-30"
            >
              <div
                class="tw-flex tw-items-center tw-gap-1 tw-flex-wrap tw-text-[#264FD5] tw-mb-2"
              >
                <svg
                  width="17"
                  height="17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.113" cy="8.5" r="8" fill="currentColor" />
                  <path
                    d="m7.048 10.157-1.53-1.485a.439.439 0 0 0-.61 0 .409.409 0 0 0 0 .594l1.83 1.777c.17.166.445.166.616 0l4.63-4.491a.409.409 0 0 0 0-.594.439.439 0 0 0-.61 0l-4.326 4.199Z"
                    fill="#fff"
                  />
                </svg>
                <Text variant="span" text-color="currentColor">Completed</Text>
              </div>
              <Text
                variant="h5"
                class="!tw-text-[16px] tw-block tw-mb-2"
                whiteSpace="nowrap"
                >Sign up to iknowa</Text
              >

              <Text class="!text-[14px]" textWeight="400"
                >You’ve just created your account to iknowa.</Text
              >
            </div>

            <div class="rlg:tw-w-[100px] tw-flex-shrink-0">
              <img
                class="tw-hidden rlg:!tw-flex tw-w-full tw-object-contain"
                src="@/assets/images/up-next-desktop.svg"
                alt=""
              />
              <img
                class="tw-flex rlg:!tw-hidden tw-object-contain"
                src="@/assets/images/up-next-mobile.svg"
                alt=""
              />
            </div>

            <div
              class="tw-bg-white tw-border tw-grid tw-grid-cols-[200px_1fr] tw-gap-8 tw-border-solid tw-border-[#DFDFDF] tw-p-8 tw-px-4 rlg:tw-px-8 tw-rounded-[16px] tw-w-full rlg:tw-flex-auto tw-h-full xxl:tw-grid-cols-[1fr]"
            >
              <div class="tw-w-[200px] tw-max-w-full">
                <img
                  src="@/assets/images/tp-banner.svg"
                  alt="iknowa banner"
                  class="tw-w-full tw-object-contain tw-h-auto"
                />
              </div>
              <div class="tw-flex tw-flex-col tw-items-start">
                <Text variant="h5" class="!tw-text-[18px] tw-block tw-mb-2"
                  >Create your
                  {{
                    details.role === RolesEnum.TRADESPERSON
                      ? "trade"
                      : "property"
                  }}
                  workstation</Text
                >

                <Text
                  class="!text-[14px]"
                  textWeight="400"
                  v-if="details.role === RolesEnum.TRADESPERSON"
                  >Unlock access to subsidised upskilling courses, estimate new
                  job opportunities, and seamlessly collaborate.</Text
                >

                <Text class="!text-[14px]" textWeight="400" v-else
                  >List properties, raise property service projects, and
                  seamlessly collaborate.</Text
                >

                <div
                  class="tw-mt-6 rlg:tw-w-auto"
                  v-if="details.role === RolesEnum.TRADESPERSON"
                >
                  <v-btn
                    class="button button-purple"
                    label="CREATE Trade WORKSTATION"
                    @click="onNextClick('createWs')"
                    >CREATE Trade WORKSTATION</v-btn
                  >
                  <!-- <Button
                    class="small-button"
                    label="CREATE Trade WORKSTATION"
                    @click="onNextClick('createWs')"
                  ></Button> -->
                </div>

                <div class="tw-mt-6 rlg:tw-w-auto" v-else>
                  <v-btn
                    class="button button-purple"
                    label="CREATE Property WORKSTATION"
                    @click="onNextClick('createWs')"
                    >CREATE Property WORKSTATION</v-btn
                  >
                  <!-- <Button
                    class="small-button"
                    label="CREATE Property WORKSTATION"
                    @click="onNextClick('createWs')"
                  ></Button> -->
                </div>

                <!-- <div class="tw-text-center tw-mt-3 rmd:tw-hidden">
                  <Text
                    variant="p"
                    textAlign="left"
                    textWeight="500"
                    textColor="#0C0F4A80"
                    >Been invited to workstation?
                    <a
                      href="#"
                      class="tw-font-semibold tw-text-[#FFA500] tw-underline tw-hover:no-underline"
                      >Click here
                    </a>
                  </Text>
                </div> -->
              </div>
            </div>
          </div>

          <!-- <div class="tw-text-center tw-mt-2 tw-hidden rmd:tw-block">
            <Text
              variant="p"
              textAlign="left"
              textWeight="500"
              textColor="#0C0F4A80"
              >Been invited to workstation?
              <a
                href="#"
                class="tw-font-semibold tw-text-[#FFA500] tw-underline hover:tw-no-underline"
                >Click here
              </a>
            </Text>
          </div> -->
        </div>

        <!-- Trade Site Card Start -->
        <div
          v-if="details.role === RolesEnum.TRADESPERSON"
          class="tw-border tw-border-[#DFDFDF] tw-py-8 tw-px-4 rmd:tw-p-8 tw-border-solid tw-rounded-2xl tw-grid tw-items-center tw-gap-8 tw-row-span-2 tw-self-start"
        >
          <img
            class="tw-w-[200px] tw-h-auto tw-object-contain"
            src="@/assets/images/courses-welcome.png"
          />
          <div>
            <Text class="!tw-text-[18px] tw-block tw-mb-3"
              >Access Government subsidised Up-skilling courses</Text
            >
            <Text class="!text-[14px]" textWeight="400"
              >We’re serious about increasing the number of qualified
              installers.</Text
            >
            <!-- <div
              class="tw-mt-6 tw-pt-6 tw-border-t tw-border-dashed tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-[#8687A5]"
            >
              <ArrowLink label="Explore up-skilling courses" />
            </div> -->
          </div>
        </div>
        <!-- Trade Site Card End -->

        <div
          class="tw-border tw-border-[#DFDFDF] tw-border-solid tw-py-8 tw-px-4 rmd:tw-p-8 tw-rounded-2xl tw-grid rmd:tw-grid-cols-[124px_1fr] tw-items-center tw-gap-8"
        >
          <img
            class="tw-w-[124px] rlg:w-full tw-h-auto tw-object-contain"
            src="@/assets/images/upcoming-events.svg"
          />
          <div>
            <Text class="!tw-text-[18px] tw-block tw-mb-3"
              >Stay tuned for more events in 2025</Text
            >
            <Text class="!text-[14px]" textWeight="400"
              >Join us and connect with peers, gain insights from experts, and
              take steps towards advancing your career in the trade
              industry.</Text
            >
            <!-- <div
              class="tw-mt-6 tw-pt-6 tw-border-t tw-border-dashed tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-[#8687A5]"
            >
              <ArrowLink
                @click="onClickViewGoogleEvent"
                label="Explore our events"
              />
            </div> -->
          </div>
        </div>

        <div
          class="tw-border tw-border-[#DFDFDF] tw-border-solid tw-py-8 tw-px-4 rmd:tw-p-8 tw-rounded-2xl tw-grid rmd:tw-grid-cols-[124px_1fr] tw-items-center tw-gap-8"
        >
          <img
            class="tw-w-[124px] rlg:w-full tw-h-auto tw-object-contain"
            src="@/assets/images/iknowa-benefits.png"
          />
          <div>
            <Text class="!tw-text-[18px] tw-block tw-mb-3"
              >Learn more about iknowa</Text
            >
            <Text class="!text-[14px]" textWeight="400"
              >Explore everything that the iknowa can do.</Text
            >
            <div
              class="tw-mt-6 tw-pt-6 tw-border-t tw-border-dashed tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-[#8687A5]"
            >
              <ArrowLink
                @click="toggleBenefitModal"
                label="Explore iknowa benefits"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <TradePersonBenefitsModal
    v-if="isShowTradePersonBenefitsModal"
    @onClickClosePreviewModalButton="toggleBenefitModal"
  />
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ArrowLink from "@/core/components/ui/general/ArrowLink.vue";
import TradePersonBenefitsModal from "@/modules/dashboard/components/TradePersonBenefitsModal.vue";
import { ref } from "vue";
import { RolesEnum } from "@/core/enums/RolesEnum";

const emits = defineEmits("onNext");
const props = defineProps(["details"]);
const isShowTradePersonBenefitsModal = ref(false);
const toggleBenefitModal = () => {
  isShowTradePersonBenefitsModal.value = !isShowTradePersonBenefitsModal.value;
};
const onNextClick = (type) => {
  emits("onNext", type);
};
const onClickViewGoogleEvent = () => {
  const link = "https://qc6kd2k4jjk.typeform.com/to/Z37e81Id";
  window.open(link, "_blank");
};
</script>

<style lang="scss" scoped>
.small-button {
  padding: 5px 10px;
  min-height: 1px;
  height: auto;
  min-width: 1px;
  font-size: 12px !important;
}
</style>
