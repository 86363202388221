<template>
  <v-expansion-panels v-model="tradePassportPanel">
    <v-expansion-panel
      :expand="isExpand"
      class="collapsible-component !tw-rounded-[10px]"
      v-model="tradePassportPanel"
    >
      <v-expansion-panel-title
        ><Text variant="p">{{ title }}</Text>
        <div
          v-if="isSiteVisitPropertyDetail && title == 'Recommendation'"
          class="form__button"
          @click="onClickShowRecommendedProjectModal"
        >
          ADD RECOMMENDED PROJECT
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="tw-border-t-2">
        <slot name="content"></slot>
        <v-divider class="tw-mt-8 tw-mb-2" v-if="hasActions"></v-divider>
        <v-card-actions class="tw-border-t-2" v-if="hasActions">
          <slot name="actions"></slot>
        </v-card-actions>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <AddRecommendedProjectModal
    v-if="isShowAddRecommendedProjectModal"
    :propertyId="propertyId"
    @on-close="onCloseModal"
  />
</template>
<script setup>
import { PROPERTY_STORE } from "@/store/modules/property";
import AddRecommendedProjectModal from "../modals/AddRecommendedProjectModal.vue";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Button from "../ui/general/Button.vue";
const store = useStore();
const props = defineProps({
  hasActions: Boolean,
  title: String,
  isExpand: {
    type: Boolean,
    default: false,
  },
});
const isSiteVisitPropertyDetail = computed(
  () => store.getters[`${PROPERTY_STORE}/getIsSiteVisitPropertyDetail`]
);
const propertyId = computed(() => {
  const data = store.getters[`${PROPERTY_STORE}/propertyDetails`];
  return data?.localData?.id;
});
const tradePassportPanel = ref(props.isExpand ? [0] : [1]);
const isShowAddRecommendedProjectModal = ref(false);
const onClickShowRecommendedProjectModal = () => {
  isShowAddRecommendedProjectModal.value = true;
};
const onCloseModal = () => {
  isShowAddRecommendedProjectModal.value = false;
};
</script>
<style lang="scss" scoped>
.collapsible-component {
  :deep(.v-expansion-panel-title) {
    min-height: 62px;
    font-weight: 700;
  }
  :deep(.v-expansion-panel__shadow) {
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }
}

.form__button {
  padding: 10px 17px;
  background-color: rgba($PrimaryPurple, 1);
  border-radius: 6px;
  color: rgba($white, 1);
  @include fluidFont(10, 10, 1.2);
  font-weight: 700;
  text-transform: uppercase;
  display: inline-flex;
  margin-left: auto;
  position: relative;
  z-index: 2;
}
</style>
