<template>
  <WizardModal
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="modelDetails"
    @on-close="onClickBackButton"
  >
    <template #content>
      <p class="note__wrapper">
        *Please note. we will require proof before engaging in any projects on
        iknowa.
      </p>
      <div class="sole-trader-data-wrapper rmd:!tw-w-[50%] md:!tw-h-full">
        <div
          v-if="isShowQualificationForm"
          class="form__wrapper md:!tw-h-full md:!tw-m-0"
        >
          <v-form
            @submit.prevent
            :rules="validation"
            ref="certificationFormRef"
            class="md:!tw-h-full"
          >
            <div class="modal_input_wrapper tw-flex tw-flex-col tw-gap-4">
              <div class="modal_input !tw-m-0">
                <h5 class="input-lbl">Title</h5>
                <v-select
                  :items="certificateList"
                  item-title="name"
                  item-value="id"
                  placeholder="e.g Plumbing & Heating"
                  :rules="validation.certificateId"
                  v-model="qualificationData.certificateId"
                  variant="outlined"
                  class="c-select mb-2"
                  :menu-props="{ contentClass: 'tags_dropdown' }"
                ></v-select>

                <v-text-field
                  v-if="qualificationData.certificateId === getOthersId"
                  density="compact"
                  :rules="validation.typeText"
                  placeholder="E.g. Public liability"
                  variant="outlined"
                  v-model="customType"
                  class="c-input rounded-full tw-flex-row-reverse mt-4 mb-2"
                >
                </v-text-field>
              </div>
              <div class="modal_input !tw-m-0">
                <h5 class="input-lbl">Membership number (optional)</h5>
                <v-text-field
                  density="compact"
                  placeholder="eg. 12345678"
                  variant="outlined"
                  v-model="qualificationData.registrationNumber"
                  class="c-input rounded-full !tw-m-0"
                ></v-text-field>
              </div>
              <div class="modal_input !tw-m-0">
                <h5 class="input-lbl">URL (optional)</h5>
                <v-text-field
                  density="compact"
                  placeholder="Add Credential URL"
                  variant="outlined"
                  v-model="qualificationData.url"
                  class="c-input rounded-full !tw-m-0"
                ></v-text-field>
              </div>
              <div class="modal_input !tw-m-0">
                <h5 class="input-lbl">Evidence</h5>
                <v-file-input
                  class="c-file !tw-m-0"
                  :label="
                    qualificationData.attachments[0]?.originalName
                      ? qualificationData.attachments[0]?.originalName
                      : `Upload`
                  "
                  prepend-icon="mdi-upload"
                  variant="solo"
                  flat
                  @click:clear="clearAttachments"
                  @change="onFileChange"
                  :error-messages="attachmentError"
                ></v-file-input>
              </div>
              <div v-if="isFileUploading">
                <v-progress-linear
                  :model-value="uploadProgress"
                  :height="10"
                  :width="5"
                  striped
                  rounded
                  color="#0C0F4A"
                >
                </v-progress-linear>
                {{ uploadProgress }} %<span>&nbsp; Uploading file...</span>
              </div>
            </div>
            <v-btn
              class="button disable"
              :class="isSaveButtonDisable ? 'disable' : 'button-orange'"
              :loading="isQualificationSubmitting"
              @click="onClickAddQualification"
              :disabled="isSaveButtonDisable"
              >{{
                isEditCertificate ? "update qualification" : "add qualification"
              }}</v-btn
            >
          </v-form>
          <div class="add-more-data" v-if="userCertificates.length">
            <p class="body-text">
              Nothing more to add?
              <span class="yellow-text" @click="onClickCancelButton"
                >Cancel<v-icon icon="mdi-chevron-right"></v-icon>
              </span>
            </p>
          </div>
        </div>
        <div v-else>
          <div class="insurances-list">
            <div class="insurances-list__innner !tw-m-0">
              <ItemListCard
                v-for="certificate in userCertificates"
                :key="certificate.id"
                :onClickDelete="() => deleteCertificate(certificate.id)"
                :isShowLogo="true"
                :onClickEdit="() => editCertificate(certificate)"
              >
                <template v-slot:listItemLogo>
                  <ServiceIconWrapper iconName="tools" />
                </template>
                <template v-slot:listItemCardTitle>{{
                  certificate.metadata.name
                    ? certificate.metadata.name
                    : certificate.certificate.name
                }}</template>
                <template v-slot:listItemCardLabel>{{
                  certificate?.metadata?.registrationNumber
                }}</template>
                <template v-slot:listItemCardText
                  >{{ certificate?.metadata?.url }}
                  <div v-if="certificate?.attachment.length">
                    {{ certificate?.attachment[0]?.originalName }}
                  </div></template
                >
              </ItemListCard>
            </div>
            <v-btn
              class="button button-purple-border w-100 !tw-m-0"
              @click="onClickAddAnotherQualification"
              >add another qualification</v-btn
            >
          </div>
          <v-btn
            class="button button-orange w-100"
            @click="onClickQualificationContinue"
            >Continue</v-btn
          >
        </div>
      </div>
    </template>
  </WizardModal>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import ItemListCard from "@/core/components/ItemListCard.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import ServiceIconWrapper from "@/core/components/common/ServiceIconWrapper.vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { CERTIFICATE_STORE } from "@/store/modules/certificates";
import { onBeforeMount } from "vue";
import { watch } from "vue";
import { onMounted, onBeforeUnmount } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: { WizardModal, ItemListCard, ServiceIconWrapper },
  props: { userCertificateData: { type: Object, default: null } },
  setup(props: any, ctx: any) {
    const isShowQualificationForm = ref(true);
    const isQualificationSubmitting = ref(false);
    const store = useStore();
    const uploadProgress = ref(0);
    const modelDetails = ref({
      id: 2,
      tab: "Certifications",
      name: "certifications",
      header: "Certifications",
      title: "Do you have any certifications?",
      description: `Please enter your qualification details.`,
    });
    const isFileUploading = ref(false);
    const attachmentError = ref("");
    const imageData = ref("");
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const qualificationData = ref({
      certificateId: null,
      registrationNumber: null,
      url: null,
      attachments: [],
    }) as any;
    const certificationFormRef = ref(null) as any;
    const validation = ref({
      certificateId: [(v: any) => !!v || "Select a any one certification"],
      typeText: [
        (v: string) => !!v || "Provide an insurance type",
        (v: string) => isNaN(Number(v)) || "Must be a character",
      ],
    });
    const customType = ref("");

    const onClickBackButton = () => {
      return ctx.emit("onClickCloseTab");
    };
    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const isSaveButtonDisable = computed(
      () =>
        !(
          qualificationData.value.attachments.length &&
          qualificationData.value.certificateId
        ) && qualificationData.value.certificateId !== 10
    );

    const onClickAddQualification = async () => {
      try {
        const isFormValid = await certificationFormRef.value.validate();
        isQualificationSubmitting.value = true;
        if (
          !qualificationData.value.url &&
          !qualificationData.value.attachments.length &&
          qualificationData.value.certificateId !== 10
        ) {
          attachmentError.value =
            "Add credential URL or upload evidence to continue";
          return;
        }
        if (!isFormValid.valid) return;
        let certificateData;
        interface Metadata {
          url: string;
          registrationNumber: string;
          name?: string; // Optional property
        }

        if (isEditCertificate.value) {
          certificateData = {
            certificateId: qualificationData.value.certificateId,
            metadata: {
              url: qualificationData.value.url,
              registrationNumber: qualificationData.value.registrationNumber,
            } as Metadata,
            attachment: qualificationData.value.attachments[0],
          };

          if (qualificationData.value.certificateId === getOthersId.value) {
            certificateData.metadata.name = customType.value || "";
          }
          await store.dispatch(`${USER_STORE}/updateUserCertificate`, {
            userId: user.value?.id,
            id: userCertificateModalData.value?.id,
            certificate: certificateData,
          });
        } else {
          certificateData = {
            userId: user.value.id,
            certificateId: qualificationData.value.certificateId,
            metadata: {
              url: qualificationData.value.url,
              registrationNumber: qualificationData.value.registrationNumber,
            } as Metadata,
            attachments: qualificationData.value.attachments,
          };

          if (qualificationData.value.certificateId === getOthersId.value) {
            certificateData.metadata.name = customType.value || "";
          }
          await store.dispatch(
            `${USER_STORE}/addNewCertificate`,
            certificateData
          );
        }
        isShowQualificationForm.value = false;
      } catch (error) {
        console.log();
      } finally {
        isQualificationSubmitting.value = false;
        await store.dispatch(
          `${USER_STORE}/setUserCertificates`,
          user.value?.id
        );
        await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
      }
    };

    const deleteCertificate = async (certificateId: number) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificateId,
        });
        if (!userCertificates.value.length) {
          isShowQualificationForm.value = true;
        }
      } catch (error) {
        console.log();
      }
    };
    const onClickCancelButton = () => {
      isShowQualificationForm.value = false;
    };

    const isEditCertificate = computed(() => {
      return userCertificateModalData.value ? true : false;
    });
    const editCertificate = (certificate: any) => {
      qualificationData.value.certificateId = certificate?.certificate?.id;
      qualificationData.value.registrationNumber =
        certificate?.metadata?.registrationNumber;
      qualificationData.value.url = certificate?.metadata?.url;
      qualificationData.value.attachments = certificate?.attachment;
      isShowQualificationForm.value = true;
      userCertificateModalData.value = certificate;
    };

    const onClickAddAnotherQualification = () => {
      qualificationData.value = {
        certificateId: null,
        registrationNumber: null,
        url: null,
        attachments: [],
      };
      userCertificateModalData.value = false;
      isShowQualificationForm.value = true;
    };
    const onClickQualificationContinue = () => {
      return ctx.emit("onClickCloseTab");
    };

    const certificateList = computed(
      () => store.getters[`${CERTIFICATE_STORE}/certificates`]
    );

    const getOthersId = computed(() => {
      if (!certificateList.value) {
        return null;
      }
      const foundItem = certificateList.value.find(
        (item: any) => item.template === "Other"
      );
      return foundItem ? foundItem.id : null;
    });

    const onFileChange = async (event: any) => {
      try {
        isFileUploading.value = true;
        attachmentError.value = "";
        uploadProgress.value = 0;
        const fileInput = event.target;
        if (fileInput.files && fileInput.files.length > 0) {
          const file = fileInput.files[0];

          imageData.value = file;
          const formData = new FormData();
          formData.append("file", imageData.value);

          const progressInterval = setInterval(() => {
            if (uploadProgress.value < 100) {
              uploadProgress.value += 10;
            }
          }, 120);

          const uploadImg = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
          if (uploadImg) {
            clearInterval(progressInterval);
            uploadProgress.value = 100;
          }

          qualificationData.value.attachments = [uploadImg];
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        attachmentError.value = "Error uploading file.";
      } finally {
        isFileUploading.value = false;
      }
    };

    watch(
      () => qualificationData.value.url,
      () => {
        attachmentError.value = "";
      }
    );

    onBeforeMount(async () => {
      await store.dispatch(`${CERTIFICATE_STORE}/getAllCertificates`);
      if (userCertificateModalData.value) {
        const userCertifiCateId = certificateList.value?.find(
          (certificate: any) =>
            certificate.name ===
            userCertificateModalData.value?.certificate?.name
        );
        qualificationData.value.certificateId = userCertifiCateId?.id;
        qualificationData.value.url =
          userCertificateModalData.value?.metadata?.url;
        qualificationData.value.registrationNumber =
          userCertificateModalData.value?.metadata?.registrationNumber;
        qualificationData.value.attachments =
          userCertificateModalData.value?.attachment;
      }
    });
    const userCertificateModalData = ref(null) as any;
    onMounted(() => {
      userCertificateModalData.value = props.userCertificateData;
      if (userCertificates.value && userCertificates.value.length) {
        isShowQualificationForm.value = false;
        if (
          props.userCertificateData === undefined ||
          props.userCertificateData === null
        ) {
          isShowQualificationForm.value = false;
        } else {
          isShowQualificationForm.value = true;
        }
      } else {
        isShowQualificationForm.value = true;
      }
    });

    const clearAttachments = () => {
      qualificationData.value.attachments = [];
    };
    onBeforeUnmount(() => {
      ctx.emit("clearUserCertificateData");
    });
    return {
      modelDetails,
      onClickBackButton,
      qualificationData,
      validation,
      certificationFormRef,
      isShowQualificationForm,
      onClickAddQualification,
      onClickAddAnotherQualification,
      onClickQualificationContinue,
      userCertificates,
      deleteCertificate,
      onClickCancelButton,
      isQualificationSubmitting,
      certificateList,
      isFileUploading,
      attachmentError,
      onFileChange,
      isEditCertificate,
      editCertificate,
      isSaveButtonDisable,
      uploadProgress,
      getOthersId,
      customType,
      clearAttachments,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/certification/styles/CertificationModel.scss";
</style>
