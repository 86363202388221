<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#FFA500" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.92188 9.61538V20.3846C6.92188 20.8132 7.09203 21.2241 7.39518 21.5267C7.6978 21.8298 8.10864 22 8.53726 22C11.3125 22 18.6851 22 21.4603 22C21.889 22 22.2998 21.8298 22.6024 21.5267C22.9056 21.2241 23.0757 20.8132 23.0757 20.3846V11.7692C23.0757 11.3406 22.9056 10.9298 22.6024 10.6272C22.2998 10.324 21.889 10.1538 21.4603 10.1538H19.845V9.61538C19.845 9.18677 19.6748 8.77592 19.3716 8.47331C19.069 8.17015 18.6582 8 18.2296 8C15.9766 8 10.7902 8 8.53726 8C8.10864 8 7.6978 8.17015 7.39518 8.47331C7.09203 8.77592 6.92188 9.18677 6.92188 9.61538ZM7.9988 11.1382V20.3846C7.9988 20.5273 8.05534 20.6646 8.15657 20.7653C8.25726 20.8665 8.39457 20.9231 8.53726 20.9231H21.4603C21.603 20.9231 21.7403 20.8665 21.841 20.7653C21.9423 20.6646 21.9988 20.5273 21.9988 20.3846V18.2308H19.3065C18.4143 18.2308 17.6911 17.5076 17.6911 16.6154V15.5385C17.6911 14.6462 18.4143 13.9231 19.3065 13.9231H21.9988V11.7692C21.9988 11.6265 21.9423 11.4892 21.841 11.3885C21.7403 11.2873 21.603 11.2308 21.4603 11.2308H8.53726C8.35203 11.2308 8.17003 11.199 7.9988 11.1382ZM7.9988 9.61538C7.9988 9.47269 8.05534 9.33538 8.15657 9.23469C8.25726 9.13346 8.39457 9.07692 8.53726 9.07692H18.2296C18.3723 9.07692 18.5096 9.13346 18.6103 9.23469C18.7115 9.33538 18.768 9.47269 18.768 9.61538V10.1538H8.53726C8.39457 10.1538 8.25726 10.0973 8.15657 9.99608C8.05534 9.89538 7.9988 9.75808 7.9988 9.61538Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8434 12.3076C10.7634 12.3076 9.07422 13.9968 9.07422 16.0768C9.07422 18.1569 10.7634 19.8461 12.8434 19.8461C14.9235 19.8461 16.6127 18.1569 16.6127 16.0768C16.6127 13.9968 14.9235 12.3076 12.8434 12.3076ZM12.305 14.9999V16.0768C12.305 16.2195 12.3615 16.3568 12.4628 16.4575L13.3975 17.3923C13.6075 17.6023 13.9489 17.6023 14.1589 17.3923C14.3689 17.1823 14.3689 16.8409 14.1589 16.6309L13.3819 15.8539V14.9999C13.3819 14.7027 13.1407 14.4615 12.8434 14.4615C12.5462 14.4615 12.305 14.7027 12.305 14.9999Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "WalletCircleYellowIcon",
};
</script>
