<template>
  <div id="map" class="tw-w-full tw-h-full tw-max-h-full" />
</template>
<script lang="ts" setup>
import L from "leaflet";
import { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  computed,
  defineProps,
  onMounted,
  watch,
  nextTick,
  onBeforeUnmount,
} from "vue";
import mapMarkerImage from "@/core/uni-assets/images/map-marker.png";
import { VUE_APP_LEAFLET_LAYER_URL } from "@/config";

// tmp only
const OS_MAP_API_KEY = "mO9BPArB5oA2Ai8YIPs4nZQza4GhGFHh";

const props = defineProps({
  data: {
    // type: Object as () => JobsState["jobs"],
    type: Object as () => any,
    required: true,
  },
  isDraggable: {
    type: Boolean,
    default: true,
  },
  defaultViewZoom: {
    type: Number,
    default: 12,
  },
  showControls: {
    type: Boolean,
    default: true,
  },
  latlang: {
    type: Array as unknown as () => LatLngExpression,
    default: () => [51.3593966, -0.2863208] as LatLngExpression,
  },
});

const mapData = computed(() => props.data);

let map: L.Map | null = null;

let markersLayer = new L.LayerGroup();

// const updateMap = (data: JobsState["jobs"]) => {
const updateMap = (data: any) => {
  // NOTE: The first thing we do here is clear the markers from the layer.
  markersLayer.clearLayers();

  const locationCoor = [] as any;
  let marker: any;

  const customIcon = {
    iconUrl: mapMarkerImage,
    iconSize: [20, 40],
  } as any;

  const mapIcon = L.icon(customIcon);

  for (let i = 0; i < data.length; i++) {
    const iconProps = {
      title: data[i].name,
      clickable: true,
      icon: mapIcon,
    };

    const lat = parseFloat(data[i].latitude);
    const lon = parseFloat(data[i].longitude);
    locationCoor[i] = [lat, lon];

    const popup = L.popup().setLatLng([lat, lon]).setContent(`
        <h1>${data[i].name}</h1>
        <p>${data[i].postcode}</p>
        <p>${data[i].description}</p>
      `);

    marker = L.marker([lat, lon], iconProps).bindPopup(popup);

    markersLayer.addLayer(marker);
  }

  if (locationCoor.length > 0) {
    const bounds = new L.latLngBounds(locationCoor);
    if (map) {
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }
};

watch(
  mapData,
  (state) => {
    updateMap(state);
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  await nextTick();

  // Define base layers
  const streetLayer = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }
  );

  const satelliteLayer = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      detectRetina: true,
    }
  );

  const terrainLayer = L.tileLayer(
    "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 17,
      attribution:
        'Map data: &copy; <a href="https://www.opentopomap.org">OpenTopoMap</a> contributors',
    }
  );

  var Esri_WorldImagery = L.tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    {
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    }
  );

  map = L.map("map", {
    zoomControl: false,
    // layers: [Esri_WorldImagery, streetLayer, satelliteLayer, terrainLayer],
    layers: [satelliteLayer],
    dragging: props.isDraggable,
    attributionControl: false,
  }).setView(props.latlang, props.defaultViewZoom);
  // L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //   maxZoom: 19,
  //   attribution:
  //     '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  // }).addTo(map);
  markersLayer.addTo(map);

  L.tileLayer(`${VUE_APP_LEAFLET_LAYER_URL}?key=${OS_MAP_API_KEY}`).addTo(map);

  map.on("movestart", () => {
    if (map) {
      map.getCenter().toString();
    }
  });

  // Add layer control
  if (props.showControls) {
    const baseLayers = {
      "Street Map": streetLayer,
      Satellite: satelliteLayer,
      Terrain: terrainLayer,
      Esri: Esri_WorldImagery,
    };
    L.control.layers(baseLayers).addTo(map);
  }

  map.invalidateSize();
  // Initialize mapMarkers

  // Add custom control for 2D view reset
  if (props.showControls) {
    const customControl = L.Control.extend({
      options: {
        position: "topright",
      },
      onAdd: function () {
        const container = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );
        container.innerHTML = "2D";
        container.style.backgroundColor = "white";
        container.style.width = "34px";
        container.style.height = "34px";
        container.style.cursor = "pointer";
        container.style.textAlign = "center";
        container.style.lineHeight = "30px";

        container.onclick = function () {
          if (map) {
            map.setView([51.3593966, -0.2863208], 12); // Reset to default view
          }
        };

        return container;
      },
    });

    map.addControl(new customControl());
  }

  // Add zoom controls
  if (props.showControls) {
    L.control
      .zoom({
        position: "topright",
      })
      .addTo(map);
  }

  updateMap(mapData.value);

  const container = L.DomUtil.get("map") as any;
  if (container !== null) {
    container.id = null;
  }
});

onBeforeUnmount(() => {
  if (map) {
    map.remove(); // Removes the map and clears all related event listeners
  }
  markersLayer.clearLayers(); // Clears all layers in the marker layer
  map = null;
});
</script>
<style lang="scss" scoped></style>
