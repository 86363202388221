<template>
  <svg
    width="61"
    height="38"
    viewBox="0 0 61 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.4089 30.5332V29.8013H60.2183L59.9978 30.3035L59.7785 29.8013H59.5873V30.5332H59.7227V29.9818L59.9289 30.4576H60.0691L60.2753 29.9806V30.5332H60.4089ZM59.1997 30.5332V29.9266H59.4437V29.8031H58.8203V29.9266H59.0643V30.5332H59.1997Z"
      fill="#F79410"
    />
    <path
      d="M38.7482 33.6727H22.2539V4.03027H38.7485L38.7482 33.6727Z"
      fill="#FF5F00"
    />
    <path
      d="M23.2992 18.852C23.2992 12.839 26.1146 7.48261 30.4988 4.03072C27.1802 1.4141 23.0756 -0.00608159 18.8495 1.95763e-05C8.43896 1.95763e-05 0 8.44019 0 18.852C0 29.2637 8.43896 37.7039 18.8495 37.7039C23.0758 37.7103 27.1805 36.2901 30.4991 33.6732C26.1153 30.2219 23.2992 24.8652 23.2992 18.852Z"
      fill="#EB001B"
    />
    <path
      d="M61.0006 18.852C61.0006 29.2637 52.5617 37.7039 42.1511 37.7039C37.9244 37.7101 33.8193 36.29 30.5 33.6732C34.8854 30.2213 37.7008 24.8652 37.7008 18.852C37.7008 12.8387 34.8854 7.48261 30.5 4.03072C33.8192 1.41412 37.9243 -0.00604332 42.1508 1.93307e-05C52.5614 1.93307e-05 61.0003 8.44019 61.0003 18.852"
      fill="#F79E1B"
    />
  </svg>
</template>
<script>
export default {
  name: "MasterIcon",
};
</script>
