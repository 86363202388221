<template>
  <commonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    persistent
    :className="['solo-trader__last__step__box', 'workstation_create_modal']"
  >
    <template v-slot:body>
      <div
        class="custom__modal__heading tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <div>
          <div class="header-title">
            Nice job,
            <span class="tw-ml-1"> {{ user?.firstName }}.</span>
          </div>
          <div class="header-sub-title">
            You have successfully created your Property Workstation.
          </div>
        </div>

        <div class="custom__modal__img">
          <img
            src="@/assets/images/ws-creation-success-image.png"
            alt="Create Workstation"
          />
        </div>
        <p class="description explore__desc">
          Time to unlock your property superpower!
        </p>
      </div>
      <v-btn
        class="button button-orange !tw-mt-[16px] rmd:!tw-mt-[24px]"
        size="large"
        block
        @click="onExplore"
      >
        Explore 🚀
      </v-btn>
    </template>
  </commonDialog>
</template>

<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const emits = defineEmits(["close"]);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const onExplore = () => {
  emits("close");
};
</script>

<style></style>
