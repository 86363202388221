<template>
  <main
    class="tw-w-full tw-h-auto rsm:tw-h-full tw-bg-white tw-relative tw-flex tw-flex-col"
  >
    <OnboardingWave
      class="wave-svg tw-fixed tw-top-0 tw-left-0 tw-object-cover tw-h-auto tw-w-full"
    />
    <div
      class="tw-relative tw-z-10 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-8 rllg:tw-gap-16 tw-py-10 rllg:tw-py-20 tw-box-border tw-px-6"
    >
      <div
        class="tw-w-full tw-items-start tw-flex tw-flex-col rllg:tw-items-center tw-justify-center tw-gap-6 rllg:tw-gap-10"
      >
        <IknowaLogoBlack class="llg:!tw-w-[133px] llg:!tw-h-[24px]" />
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-start rllg:tw-items-center tw-justify-center tw-gap-2 rllg:tw-gap-4"
        >
          <Text
            variant="h4"
            class="tw-text-start rllg:!tw-text-center rllg:!tw-text-[32px]"
            >Welcome, {{ userName }} 👋</Text
          >
          <Text variant="h6" textWeight="500" class="tw-hidden rllg:!tw-flex"
            >Let’s get you started on iknowa. Select the
            <Text variant="h6">&nbsp;account type,</Text>&nbsp;that best
            describes you.</Text
          >
          <Text
            variant="p"
            textWeight="500"
            class="tw-mt-[-.3rem] rllg:tw-hidden"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="start"
            >Let’s get you started on Iknowa.
          </Text>
          <Text
            class="rllg:tw-hidden"
            variant="p"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="start"
            textWeight="500"
            >Please select the <Text variant="p">account type</Text> that best
            describes you.
          </Text>
        </div>
      </div>

      <div
        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 rsm:!tw-flex-row rllg:tw-gap-10"
      >
        <div
          @click="selectRole(RolesEnum.PROJECT_OWNER)"
          :class="{
            'tw-border-[#FFA500] tw-border-solid tw-border-[1px]':
              selectedRole === RolesEnum.PROJECT_OWNER,
          }"
          class="onboarding-card tw-w-full tw-min-w-[unset] tw-max-h-[236px] tw-min-h-[236px] rllg:!tw-w-[396px] rllg:!tw-min-w-[396px] rllg:!tw-max-h-[400px] rllg:!tw-min-h-[400px] tw-rounded-[18px] tw-bg-transparent tw-py-8 tw-px-6 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6 tw-cursor-pointer"
        >
          <OnboardingPropertyIcon />
          <div class="tw-flex tw-flex-col tw-gap-[.4rem]">
            <Text variant="h6" class="rllg:!tw-text-[20px]" textAlign="center"
              >Join To Manage Your Property
            </Text>
            <Text
              variant="span"
              textWeight="500"
              class="rllg:!tw-text-[14px]"
              textColor="rgba(12, 15, 74, 0.5)"
              lineHeight="18px"
              >Get Your Free ‘Know Your Property’ card for insights and start
              retrofit projects with ease.
            </Text>
          </div>
        </div>
        <div
          @click="selectRole(RolesEnum.TRADESPERSON)"
          :class="{
            'tw-border-[#FFA500] tw-border-solid tw-border-[1px]':
              selectedRole === RolesEnum.TRADESPERSON,
          }"
          class="onboarding-card tw-w-full tw-min-w-[unset] tw-max-h-[236px] tw-min-h-[236px] rllg:!tw-w-[396px] rllg:!tw-min-w-[396px] rllg:!tw-max-h-[400px] rllg:!tw-min-h-[400px] tw-rounded-[18px] tw-bg-transparent tw-py-8 tw-px-6 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6 tw-cursor-pointer"
        >
          <OnboardingTradesIcon />
          <div class="tw-flex tw-flex-col tw-gap-[.4rem]">
            <Text variant="h6" class="rllg:!tw-text-[20px]" textAlign="center"
              >Join As A Trade Contractor
            </Text>
            <Text
              variant="span"
              textWeight="500"
              class="rllg:!tw-text-[14px]"
              textColor="rgba(12, 15, 74, 0.5)"
              lineHeight="18px"
              >Create a FREE Personal iknowa HQ & Set-up a Workstation for your
              Trade Business.
            </Text>
          </div>
        </div>
      </div>

      <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
        <Button
          class="rllg:!tw-max-w-[400px] rllg:!tw-w-[400px]"
          label="continue"
          :disabled="!selectedRole"
          :loading="loading"
          @click.enter="returnToSpecialisms"
        ></Button>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { RolesEnum } from "@/core/enums/RolesEnum";
import { useStore } from "vuex";
const store = useStore();
import { USER_STORE } from "@/store/modules/user";
import { computed } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import OnboardingWave from "@/core/components/icons/OnboardingWave.vue";
import IknowaLogoBlack from "@/core/components/icons/IknowaLogoBlack.vue";
import OnboardingTradesIcon from "@/core/components/icons/OnboardingTradesIcon.vue";
import OnboardingPropertyIcon from "@/core/components/icons/OnboardingPropertyIcon.vue";

const userName = store.getters[`${USER_STORE}/user`]?.firstName;

const selectedRole = ref(0);
const loading = ref(false);
// const isAppIknowa = computed(() => {
//   return window.location.hostname.startsWith("app.iknowa");
// });
const selectRole = (role: number) => {
  if (selectedRole.value === role) {
    selectedRole.value = 0; // Deselect if the same card is clicked again
  } else {
    selectedRole.value = role;
  }
};

const returnToSpecialisms = async () => {
  try {
    loading.value = true;
    await store.dispatch(`${USER_STORE}/selectRole`, {
      userId: store.getters[`${USER_STORE}/user`]?.id,
      roleId: selectedRole.value,
      email: store.getters[`${USER_STORE}/user`]?.email,
    });
    if (selectedRole.value == 2) {
      router.push("/owner-onboarding");
    } else {
      router.push("/specilalisms");
    }
  } catch (error) {
    console.log("error:", error);
  } finally {
    loading.value = false;
  }
};
</script>
<style lang="scss" scoped>
.wave-svg {
  @media screen and (max-width: 767px) {
    width: 161%;
    left: -57%;
  }
  @media screen and (max-width: 567px) {
    width: 225%;
    left: -110%;
  }

  @media screen and (max-width: 450px) {
    width: 293%;
    left: -169%;
  }
}

.onboarding-card {
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.08);
}
</style>
