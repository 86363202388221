<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.452 9.41148H7.25504V8.14965H9.6385C9.7787 8.14965 9.89086 8.03749 9.89086 7.89729V6.39711C9.89086 6.25691 9.7787 6.14475 9.6385 6.14475H7.25504V4.88292H11.5593C11.6714 4.88292 11.7696 4.77075 11.7696 4.63055V2.87801C11.7696 2.73781 11.6714 2.62564 11.5593 2.62564H7.25504V0.494553C7.25504 0.35435 7.14288 0.242188 7.00268 0.242188C6.86247 0.242188 6.75031 0.35435 6.75031 0.494553V2.62564H4.32479C4.21263 2.62564 4.11449 2.73781 4.11449 2.87801V4.63055C4.11449 4.77075 4.21263 4.88292 4.32479 4.88292H6.75031V6.14475H0.595384C0.45518 6.14475 0.343018 6.25691 0.343018 6.39711V7.91131C0.343018 8.05151 0.45518 8.16367 0.595384 8.16367H6.75031V9.4255H4.32479C4.21263 9.4255 4.11449 9.53767 4.11449 9.67787V11.1921C4.11449 11.3323 4.21263 11.4444 4.32479 11.4444H6.75031V13.3232C6.75031 13.4634 6.86247 13.5755 7.00268 13.5755C7.14288 13.5755 7.25504 13.4634 7.25504 13.3232V11.4304H13.452C13.5642 11.4304 13.6623 11.3182 13.6623 11.178V9.66385C13.6623 9.52365 13.5642 9.41148 13.452 9.41148Z"
      fill="#FFA500"
    />
  </svg>
</template>
<script>
export default {
  name: "GanttIcon",
};
</script>
