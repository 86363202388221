export default {
  listingAmount: null,
  hasPropertyListing: false,

  // default workstation plan.
  storagePlan: {
    planType: 0,
    capacity: "100MB",
    monthly: 0.0,
    isMostPopular: false,
  },
  addedMember: 1,
  totalMember: 1,
  perMemberPrice: 2.49,
  listingPrice: 4.99,
  paymentMethod: "google-pay",

  accumulatedSize: "",

  defaultTrialDays: "14",
  defaultPropertyBillingAmount: "4.99",
};
