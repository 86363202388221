<template>
  <div class="add_comment_wrapper" :style="getPosition">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="end"
      offset="15"
      origin="left"
    >
      <template v-slot:activator="{ props }">
        <div
          class=""
          style="
            height: 32px;
            width: 32px;
            box-shadow: var(
              --elevation-200-canvas,
              0px 1px 2px rgba(0, 0, 0, 0.1),
              0px 5px 8px rgba(0, 0, 0, 0.3)
            );
            border-radius: 16px 16px 16px 0;
            background-color: #0d99ff;
          "
          v-bind="props"
        ></div>
      </template>

      <v-card min-width="300" flat class="custom__input">
        <v-textarea
          :hide-details="true"
          bg-color="white"
          base-color="white"
          density="compact"
          variant="solo"
          flat
          placeholder="Add a comment..."
          no-resize
          rows="1"
          auto-grow
          v-model="commentText"
          max-rows="4"
          @keydown.shift.enter.stop=""
          @keydown.enter="onComment"
        ></v-textarea>
        <v-icon
          icon="mdi-arrow-up-circle"
          color="#ffaa00"
          @click="onComment"
          :disabled="commentText.trim() === ''"
        ></v-icon>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { ref, computed } from "vue";

export default {
  name: "AddCommentComponent",
  props: {
    commentData: {
      type: Object,
    },
  },
  setup(props: any, ctx: any) {
    const menu = ref(true);
    const commentText = ref("");
    const getPosition = computed(() => {
      return {
        top: props.commentData?.styles?.y + "px",
        left: props.commentData?.styles?.x + "px",
      };
    });

    const onComment = (e: any) => {
      if (commentText.value.trim() !== "") {
        ctx.emit("onComment", commentText.value.trim());
      }
      e.stopPropagation();
    };

    return { menu, getPosition, onComment, commentText };
  },
};
</script>

<style lang="scss" scoped>
.add_comment_wrapper {
  position: absolute;
  z-index: 1;
  background-color: rgba($white, 1);
  border-radius: 100% 100% 100% 10%;
  width: 250px;
  width: 34px;
  height: 34px;
  overflow: hidden;
}

.custom__input {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.18), 0px 3px 8px rgba(0, 0, 0, 0.1),
    0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
  display: flex;
  gap: 10px;
}
</style>
