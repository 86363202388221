<template>
  <el-input class="no-bg-input" :placeholder="placeholder"> </el-input>
</template>

<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
});
</script>

<script>
export default {
  name: "NoBgInput",
};
</script>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  @apply tw-bg-transparent tw-border-0 tw-shadow-none tw-rounded-none tw-p-0
      tw-min-h-[2.625rem] tw-max-h-[2.625rem] tw-h-full w-full tw-border-b-[1px] tw-border-[rgba(12,15,74,0.2)];
}
.el-input.is-disabled .el-input__wrapper {
  background-color: transparent;
  box-shadow: none;
}
</style>
