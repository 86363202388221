<template>
  <InfoCardLayoutSlot
    size="lg"
    :removeBorderBottom="true"
    :hasFooter="true"
    title="Property Score"
    bgColor="#F1F6FB"
  >
    <template #title-icon>
      <!-- <ToolTip description="Property Score" /> -->
    </template>
    <template #header-right-content>
      <!-- <Text variant="span" textWeight="600" textColor="rgba(12, 15, 74, 0.5)"
        >Last Update 07:59</Text
      > -->
    </template>
    <template #content>
      <section
        class="tw-h-full tw-flex tw-gap-4 tw-overflow-auto tw-flex-col tw-justify-between"
      >
        <div
          class="tw-flex tw-flex-col tw-h-[250px] tw-items-center tw-justify-center tw-box-border tw-max-h-[300px]"
        >
          <!-- TEMPORARY RANKING UI -->
          <!-- <div
            class="score tw-w-[152px] tw-h-[152px] tw-rounded-full tw-bg-white tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center"
          >
            <Text class="!tw-text-[67px] !tw-leading-8 tw-mt-4">{{
              score
            }}</Text>
            <Text variant="h5">{{ getRanking(score) }}</Text>
          </div> -->

          <div class="progress-circle">
            <div class="circle-inner">
              <div :class="`meter_circle score_${getRanking(score)}`">
                <img
                  src="../../../../assets/icons/meter.svg
              "
                  alt=""
                />
              </div>
              <div class="meter_count">
                <div class="score">
                  {{ score }}
                </div>
                <div class="label">
                  {{ getRanking(score) }}
                </div>
              </div>
            </div>
            <div class="outer-circle">
              <div class="property_red">
                <img src="../../../../assets/icons/property-red.svg" />
              </div>
              <div class="property_orange">
                <img src="../../../../assets/icons/property-orange.svg" />
              </div>
              <div class="property_white_green">
                <img src="../../../../assets/icons/property-white-green.svg" />
              </div>
              <div class="property_light_green">
                <img src="../../../../assets/icons/property-lightgreen.svg" />
              </div>
              <div class="property_green">
                <img src="../../../../assets/icons/property-green.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <Card
            class="!tw-w-full"
            :class="{
              'tw-very-poor-ranking-border-b-solid-custom':
                getRanking(score) === item.rank && item.rank === VERY_POOR,
              'tw-poor-ranking-border-b-solid-custom':
                getRanking(score) === item.rank && item.rank === POOR,
              'tw-fair-ranking-border-b-solid-custom':
                getRanking(score) === item.rank && item.rank === FAIR,
              'tw-good-ranking-border-b-solid-custom':
                getRanking(score) === item.rank && item.rank === GOOD,
              'tw-excellent-ranking-border-b-solid-custom':
                getRanking(score) === item.rank && item.rank === EXCELLENT,
            }"
            v-for="(item, index) in rankingData"
            :key="index"
          >
            <template #content>
              <div
                class="tw-w-full tw-h-full tw-px-4 tw-py-3 tw-box-border tw-flex tw-items-center tw-justify-between"
              >
                <div class="tw-flex tw-items-center tw-gap-2">
                  <div
                    class="tw-w-[12px] tw-h-[12px] tw-min-w-[12px] tw-min-h-[12px]"
                    :class="{
                      'tw-bg-[#FF0007]': item.rank === VERY_POOR,
                      'tw-bg-[#F98228]': item.rank === POOR,
                      'tw-bg-[#BCD7B0]': item.rank === FAIR,
                      'tw-bg-[#C1D129]': item.rank === GOOD,
                      'tw-bg-[#66BB6A]': item.rank === EXCELLENT,
                    }"
                  ></div>
                  <Text variant="span" textWeight="400" lineHeight="16px">{{
                    item.rank
                  }}</Text>
                </div>
                <Text variant="span" textWeight="400" lineHeight="16px">{{
                  item.range
                }}</Text>
              </div>
            </template>
          </Card>
        </div>
      </section>
    </template>
    <template #footer-content>
      <section class="tw-w-full tw-flex tw-items-center tw-justify-start">
        <Button
          class="!tw-w-auto !tw-text-xs !tw-max-h-[30px] !tw-capitalize !tw-mt-1"
          label="Property Score Analysis"
          fontSize="12px"
          @click="onOpenAnalysysModal"
        />
      </section>
    </template>
  </InfoCardLayoutSlot>

  <PropertyScoreAnalysisModal
    v-if="analysisModal"
    @on-close="onCloseAnalysisModal"
  />
</template>
<script setup>
import { computed, ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InfoCardLayoutSlot from "@/core/components/slots/InfoCardLayoutSlot.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import ToolTip from "@/core/components/common/ToolTip.vue";
import PropertyScoreAnalysisModal from "../Modals/PropertyScoreAnalysisModal.vue";

const store = useStore();
const route = useRoute();

const VERY_POOR = "Very poor";
const POOR = "Poor";
const FAIR = "Fair";
const GOOD = "Good";
const EXCELLENT = "Excellent";

const score = ref(0);
const epcCategoryDataList = ref([]);
const loading = ref(false);
const analysisModal = ref(false);

// const rankingData = ref([
//   {
//     rank: VERY_POOR,
//     range: "0-39",
//     startRange: 0,
//     endRange: 39,
//   },
//   {
//     rank: POOR,
//     range: "40-49",
//     startRange: 40,
//     endRange: 49,
//   },
//   {
//     rank: FAIR,
//     range: "50-69",
//     startRange: 50,
//     endRange: 69,
//   },
//   {
//     rank: GOOD,
//     range: "70-89",
//     startRange: 70,
//     endRange: 89,
//   },
//   {
//     rank: EXCELLENT,
//     range: "90-100",
//     startRange: 90,
//     endRange: 100,
//   },
// ]);

const rankingData = ref([
  {
    rank: EXCELLENT,
    range: "90-100",
    startRange: 90,
    endRange: 100,
  },
  {
    rank: GOOD,
    range: "70-89",
    startRange: 70,
    endRange: 89,
  },
  {
    rank: FAIR,
    range: "50-69",
    startRange: 50,
    endRange: 69,
  },
  {
    rank: POOR,
    range: "40-49",
    startRange: 40,
    endRange: 49,
  },
  {
    rank: VERY_POOR,
    range: "0-39",
    startRange: 0,
    endRange: 39,
  },
]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const allEPCCategoryDataList = computed(
  () => store.getters[`${PROPERTY_STORE}/epcCategoryDataList`]
);

const propertyId = computed(() => route?.params?.propertyId);

const getRanking = (score) => {
  if (score >= 0 && score < 40) {
    return VERY_POOR;
  }
  if (score >= 40 && score < 50) {
    return POOR;
  }
  if (score >= 50 && score < 70) {
    return FAIR;
  }
  if (score >= 70 && score < 90) {
    return GOOD;
  }
  if (score >= 90 && score <= 100) {
    return EXCELLENT;
  }
};

const setScore = async () => {
  const details = propertyDetails.value;

  const data = details?.localData;
  if (!data) return;

  score.value = allEPCCategoryDataList.value?.propetyScore || 0;

  // if (data.epcDomestic) {
  //   score.value = data.epcDomestic?.energy?.current_energy_efficiency;
  // } else if (data.epcDisplay) {
  //   score.value = data.epcDisplay?.energy?.current_energy_efficiency;
  // } else if (data.epcNonDomestic) {
  //   score.value = data.epcNonDomestic?.energy?.current_energy_efficiency;
  // }
  // if (score.value == null) {
  //   score.value = 56;
  // }
};

const onOpenAnalysysModal = () => {
  analysisModal.value = true;
};

const onCloseAnalysisModal = () => {
  analysisModal.value = false;
};

onBeforeMount(async () => {
  try {
    loading.value = true;
    let params = {};

    params = Object.fromEntries(
      Object.entries(params).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );
    epcCategoryDataList.value = await store.dispatch(
      `${PROPERTY_STORE}/fetchEPCDataList`,
      {
        propertyId: propertyId.value,
        params: params,
      }
    );
  } catch (error) {
    console.log(error, "error in fetching property details");
  } finally {
    loading.value = false;
    await setScore();
  }
});
</script>
<style lang="scss" scoped>
.border-bottom {
  border-bottom: 3px solid;
}
.progress-circle {
  position: relative;
  .circle-inner {
    position: relative;
    width: 150px;
    height: 150px;
    .meter_count {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .score {
        @include fluidFont(68, 68, 1);
        color: rgba($blueDark, 1);
        letter-spacing: 0.7px;
        font-weight: 700;
      }
      .label {
        @include fluidFont(18, 18, 1);
        color: rgba($blueDark, 1);
        letter-spacing: 0.12px;
        font-weight: 700;
      }
    }
    .meter_circle {
      // transform: rotate(5deg);
      img {
        width: 100%;
      }
    }
    .score_Very .poor {
      transform: rotate(185deg);
    }
    .score_Poor {
      transform: rotate(-111deg);
    }
    .score_Fair {
      transform: rotate(-54deg);
    }
    .score_Good {
      transform: rotate(5deg);
    }
    .score_Excellent {
      transform: rotate(65deg);
    }
  }
  .outer-circle {
    width: 180px;
    height: 180px;
    //border: 1px solid;
    border-radius: 100%;
    position: absolute;
    top: -15px;
    left: -15px;

    .property_red {
      position: absolute;
      bottom: -5px;
      left: -10px;
      transform: rotate(-10deg);
    }
    .property_orange {
      position: absolute;
      top: 5px;
      left: -20px;
      transform: rotate(-5deg);
    }
    .property_white_green {
      position: absolute;
      top: -17px;
      left: 0;
      transform: rotate(0deg);
      right: 0;
      margin: 0 auto;
    }
    .property_light_green {
      position: absolute;
      top: 5px;
      right: -20px;
      transform: rotate(5deg);
    }
    .property_green {
      position: absolute;
      bottom: -5px;
      right: -10px;
      transform: rotate(10deg);
    }
  }
}
</style>
