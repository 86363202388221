<template>
  <!-- LOADER -->
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-[70vh] tw-w-full"
  >
    <Loader :show="isLoading" />
  </div>

  <div v-if="!isLoading" class="manage_member__wrapper">
    <div class="title">
      <h3 class="manage_member__title">Manage Members</h3>
      <v-btn class="button button-orange" @click="onClickAddTeamMember"
        >ADD NEW MEMber</v-btn
      >
    </div>
    <v-table
      class="tw-pt-8 tw-pb-4 tw-box-border tw-bg-transparent desktop__wrapper"
    >
      <thead>
        <tr>
          <th class="text-left">
            <Text variant="p">Member Name</Text>
          </th>
          <th class="text-left">
            <Text variant="p" whiteSpace="nowrap">Email ID</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Phone Number</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Role</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Joined Date</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Request Status</Text>
          </th>
          <th class="text-center">
            <Text variant="p" whiteSpace="nowrap">Action</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(member, index) in modifiedMembers"
          :key="index"
          class="tw-cursor-pointer"
        >
          <td class="text-left">
            <div class="tw-flex tw-items-center tw-gap-2" v-if="index === 0">
              <img
                class="tw-rounded-full tw-overflow-hidden !tw-h-[30px] !tw-w-[30px] tw-relative tw-max-w-full tw-max-h-full tw-object-cover"
                size="sm"
                v-if="member?.attachmentSource"
                :src="member?.attachmentSource"
              />
              <div
                v-else
                class="tw-rounded-full tw-overflow-hidden tw-h-[30px] tw-w-[30px] tw-relative tw-max-w-full tw-max-h-full"
              >
                <UserProfileLogo :userName="member?.userName" />
              </div>
              <Text class="tw-whitespace-nowrap" variant="p">
                {{
                  member?.userName
                    ? member?.userName
                    : member?.email?.split("@")[0]
                }}</Text
              >
            </div>
            <div class="tw-flex tw-items-center tw-gap-2" v-else>
              <div
                class="tw-rounded-full tw-overflow-hidden tw-h-[30px] tw-w-[30px] tw-relative tw-max-w-full tw-max-h-full"
              >
                <UserProfileLogo :userName="member?.email" />
              </div>
              <Text class="tw-whitespace-nowrap" variant="p">
                {{
                  member?.userName
                    ? member?.userName
                    : member?.email?.split("@")[0]
                }}</Text
              >
            </div>
          </td>
          <td class="text-left">
            <Text class="tw-whitespace-nowrap" variant="p">
              {{ member?.email }}</Text
            >
          </td>
          <td class="text-center">
            <Text v-if="index === 0" class="tw-whitespace-nowrap" variant="p">{{
              member?.userWorkstation?.user?.contactNo
            }}</Text>
            <Text v-else class="tw-whitespace-nowrap" variant="p">{{
              member?.toUser?.contactNo ? member?.toUser?.contactNo : "--"
            }}</Text>
          </td>
          <td class="text-center">
            <Text class="tw-whitespace-nowrap tw-capitalize" variant="p">{{
              member?.role
            }}</Text>
          </td>
          <td class="text-center">
            <Text class="tw-whitespace-nowrap" variant="p">{{
              member?.dateCreated ? formatDate(member?.dateCreated) : "--"
            }}</Text>
          </td>
          <td class="text-center">
            <p v-if="index === 0">--</p>
            <AcceptChip
              v-if="index > 0 && member.invitationStatus !== 'pending'"
              label="Accepted"
              :isDense="true"
            />
            <PendingChip
              v-if="index > 0 && member.invitationStatus === 'pending'"
              :isDense="true"
            />
          </td>
          <td class="text-center">
            <div v-if="index !== 0">
              <v-icon
                icon="mdi-pencil"
                color="#80829F"
                class="card__edit"
                @click="onClickEditTeamMember(member)"
              ></v-icon>
              <v-icon
                icon="mdi-delete"
                color="#80829F"
                class="card__delete"
                @click="onClickRemoveTeamMember(member?.id, member)"
              ></v-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="manage_member__content mobile__wrapper">
      <v-card
        class="manage_member__card"
        v-for="(member, index) in modifiedMembers"
        :key="index"
      >
        <div class="member_name">
          <div v-if="member?.attachmentSource" class="user_image">
            <img :src="member?.attachmentSource" />
          </div>
          <div v-else class="user_image">
            <UserProfileLogo :userName="member?.userName" />
          </div>
          <p>
            {{
              member?.userName ? member?.userName : member?.email?.split("@")[0]
            }}
          </p>
        </div>
        <div class="member_card__content">
          <div class="content_list_item">
            <h5>EMAIL ID</h5>
            <p>{{ member?.email }}</p>
          </div>
          <div class="content_list_item">
            <h5>Phone Number</h5>
            <p>
              {{ member?.toUser?.contactNo ? member?.toUser?.contactNo : "--" }}
            </p>
          </div>
          <div class="content_list_item">
            <h5>Role</h5>
            <p style="text-transform: capitalize">{{ member?.role }}</p>
          </div>
          <div class="content_list_item">
            <div class="content_list_date_status">
              <div class="left_block">
                <h5>Joined Date</h5>
                <p>
                  {{
                    member?.dateCreated ? formatDate(member?.dateCreated) : "--"
                  }}
                </p>
              </div>
              <div
                :class="`right_block
                status_wrapper_${member?.userWorkstation?.isAvailable}`"
              >
                <h5>Request Status</h5>
                <p v-if="index === 0">--</p>
                <AcceptChip
                  v-if="index > 0 && member.invitationStatus !== 'pending'"
                  label="Accepted"
                  :isDense="true"
                />
                <PendingChip
                  v-if="index > 0 && member.invitationStatus === 'pending'"
                  :isDense="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="manage_card_footer" v-if="index !== 0">
          <v-btn
            @click="onClickRemoveTeamMember(member?.id, member)"
            class="button button-purple-border"
            max-width="100%"
            >delete</v-btn
          >
          <v-btn
            @click="onClickEditTeamMember(member)"
            class="button button-orange"
            max-width="100%"
            >edit</v-btn
          >
        </div>
      </v-card>
    </div>
    <CommonDialog
      v-if="isShowRemoveTeamMemberModal"
      :isShowHeader="false"
      :onClickBackButton="onClickBackButton"
      :className="['remove_team_member_dialog']"
      width="350px"
      ><template v-slot:body>
        <div class="modal__content">
          <div class="modal__content_img">
            <img src="../../../../assets/icons/trash-icon.svg" />
          </div>
          <div class="modal__content_header">
            <h2>Deactivate Team Member?</h2>
            <p>
              Are you sure you want to deactivate
              <span>{{ memberName }}</span> Account?
            </p>
          </div>
        </div>
        <div class="modal__footer">
          <v-btn
            class="button button-orange w-full"
            @click="onClickConfirmRemoveTeamMember(userMemberId)"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </CommonDialog>
    <AddTeamMemberModal
      v-if="openedModel === modelName.ADDTEAMMEMBER"
      @onClickCloseTab="onClickCloseModel"
    />
    <EditTeamMemberModal
      v-if="openedModel === modelName.EDITTEAMMEMBER"
      :memberData="memberData"
      @onClickCloseTab="onClickCloseModel"
    />
    <PropertyOwnerSubscriptionModal
      v-if="isShowUpgradePremiumModal"
      @closeSubscriptionModal="closeUpgradePremiumModal"
      @closeSelectWorkstationTypeModal="closeUpgradePremiumModal"
    />
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import Loader from "@/core/components/common/Loader.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import AddTeamMemberModal from "@/modules/trade-passport/components/AddTeamMemberModal.vue";
import EditTeamMemberModal from "@/modules/trade-passport/components/EditTeamMemberModal.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import { UserWorkstationSubStatusEnum } from "@/core/enums";
import PropertyOwnerSubscriptionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerSubscriptionModal.vue";

export default {
  components: {
    Loader,
    UserProfileLogo,
    AddTeamMemberModal,
    EditTeamMemberModal,
    CommonDialog,
    PendingChip,
    AcceptChip,
    PropertyOwnerSubscriptionModal,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isLoading = ref(false);
    const teamMemberList = ref();
    const modifiedMembers = ref([]) as any;
    const openedModel = ref("");
    const modelName = {
      ADDTEAMMEMBER: "Add Member",
      EDITTEAMMEMBER: "Edit Member",
    };
    const memberData = ref();
    const memberName = ref("");
    const userMemberId = ref();
    const isShowRemoveTeamMemberModal = ref(false);
    const isShowUpgradePremiumModal = ref(false);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const getTeamMember = async () => {
      try {
        const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
          userId: user.value?.id,
        });

        teamMemberList.value = response;
      } catch (err) {
        console.log();
      }
    };

    const getWorkstationProfile = async (profileImage: string) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      }
    };

    const modifyMembersList = async () => {
      isLoading.value = true;
      if (!teamMemberList.value || !teamMemberList.value.length) {
        isLoading.value = false;
        return;
      }
      try {
        // Filter members with invitationStatus 'accepted'
        const acceptedMembers = teamMemberList.value.filter(
          (member: { invitationStatus: string }) =>
            member.invitationStatus === "accepted"
        );

        // Check if there are any accepted members
        if (!acceptedMembers.length) {
          isLoading.value = false;
          return;
        }

        const defaultMember = acceptedMembers[0];
        const { profileImage } = defaultMember?.userWorkstation;

        const source = await getWorkstationProfile(profileImage);

        acceptedMembers.forEach((member: any, index: number) => {
          if (index === 0) {
            modifiedMembers.value.push({
              ...member,
              attachmentSource: source,
            });
          } else {
            modifiedMembers.value.push({
              ...member,
            });
          }
        });
      } catch (error) {
        isLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const onClickConfirmRemoveTeamMember = async (userMemberId: number) => {
      try {
        const response = await store.dispatch(
          `${MEMBER_STORE}/deleteMemberDetails`,
          {
            userId: user.value?.id,
            userMemberId: userMemberId,
          }
        );
      } catch (error) {
        console.error("Error updating member details:", error);
      } finally {
        isShowRemoveTeamMemberModal.value = false;
        getTeamMember();
      }
    };

    const onClickBackButton = () => {
      isShowRemoveTeamMemberModal.value = false;
    };

    const onClickAddTeamMember = () => {
      if (
        !activeUserWorkstation.value?.subscription ||
        activeUserWorkstation.value?.subStatus !==
          UserWorkstationSubStatusEnum.SUCCEEDED
      ) {
        isShowUpgradePremiumModal.value = true;
      } else {
        openedModel.value = modelName.ADDTEAMMEMBER;
      }
    };
    const closeUpgradePremiumModal = () => {
      isShowUpgradePremiumModal.value = false;
    };
    const onClickEditTeamMember = (member: any) => {
      memberData.value = member;
      openedModel.value = modelName.EDITTEAMMEMBER;
    };

    const onClickRemoveTeamMember = (id: number, name: any) => {
      memberName.value = name?.userName
        ? name?.userName
        : name?.email?.split("@")[0];
      userMemberId.value = id;
      isShowRemoveTeamMemberModal.value = true;
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      getTeamMember();
    };

    onBeforeMount(async () => {
      await getTeamMember();
      await modifyMembersList();
    });
    return {
      user,
      activeUserWorkstation,
      formatDate,
      isLoading,
      modifiedMembers,
      teamMemberList,
      onClickAddTeamMember,
      onClickCloseModel,
      openedModel,
      modelName,
      memberData,
      onClickEditTeamMember,
      userMemberId,
      memberName,
      onClickRemoveTeamMember,
      isShowRemoveTeamMemberModal,
      onClickConfirmRemoveTeamMember,
      onClickBackButton,
      closeUpgradePremiumModal,
      isShowUpgradePremiumModal,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/workstation-setting/styles/ManageMemberSetting.scss";
</style>
