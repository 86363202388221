<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="570px"
    max-width="100%"
    persistent
    :className="['specialisms_multiselect_modal ']"
  >
    <template v-slot:body>
      <div class="header">
        <h2>Add Services</h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onClickCancel"
        ></v-icon>
      </div>
      <div class="specialisms_multiselect_modal_body">
        <v-btn
          class="button button-purple-border"
          block
          @click="onClickAddNewTradeSpecialism"
        >
          <Text variant="span"> add new trade Services </Text>
        </v-btn>
        <div class="specialisms_multiselect_modal_search">
          <v-text-field
            variant="outlined"
            placeholder="Search Services"
            prepend-inner-icon="mdi-magnify"
            @input="debouncedCategoryFilter"
          >
          </v-text-field>
        </div>

        <div
          class="specialisms_multiselect_modal_inner no-scrollbar"
          v-if="filterCategory.length && !servicesLoading"
        >
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="(specialism, index) in filterCategory"
              :key="index"
            >
              <v-expansion-panel-title>
                <div class="specialisms_multiselect_modal_item_heading">
                  <v-checkbox
                    color="#4B4BFF"
                    v-model="mainSelectedCategory"
                    :value="specialism.id"
                    @click="onClickMainCategory(specialism.id)"
                  />
                  <div class="specialisms_multiselect_modal_item_title">
                    <v-icon
                      v-if="specialism.iconName"
                      class="specialisms_multiselect_modal_item_title_icon"
                    >
                      <img
                        style="width: 14px"
                        v-if="specialism.iconName"
                        :src="
                          require('@/assets/category-icons/' +
                            specialism.iconName +
                            '.svg')
                        "
                        alt="icon"
                      />
                    </v-icon>
                    <div class="specialisms_multiselect_modal_item_title_text">
                      <h2>{{ specialism?.poLabel }}</h2>
                      <p>
                        {{
                          specialism.subCategories
                            .slice(0, 2)
                            .map((sub: any) => sub.label)
                            .join(", ")
                        }}
                        <span v-if="specialism.subCategories?.length > 2">
                          etc..</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="specialisms_multiselect_modal_item_content">
                  <div class="specialisms_multiselect_modal_item_input_wrapper">
                    <v-text-field
                      class="specialisms_multiselect_modal_item_input"
                      variant="outlined"
                      placeholder="Enter sub-category"
                      :id="specialism.id"
                      v-model="newSubCategory[specialism.id]"
                      @input="subCategoryValidationError = ''"
                    >
                    </v-text-field>
                    <span
                      v-if="subCategoryValidationErrorId === specialism.id"
                      class="tw-text-red-600"
                      >{{ subCategoryValidationError }}</span
                    >
                    <v-btn
                      class="button button-purple-border"
                      density="compact"
                      @click="onClickAddSubCategory(specialism.id)"
                      :disabled="
                        !newSubCategory[specialism.id] ||
                        newSubCategory[specialism.id].length > 25
                      "
                      icon="mdi-plus"
                    ></v-btn>
                  </div>
                  <v-checkbox
                    color="#4B4BFF"
                    v-model="selectedSubCategory"
                    :label="subCategory.label"
                    :key="subCategory.id"
                    :value="subCategory.id"
                    v-for="subCategory in specialism.subCategories"
                  >
                  </v-checkbox>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div
          v-if="!filterCategory.length && !servicesLoading"
          class="tw-mx-auto tw-flex tw-justify-center"
        >
          <p>Not any Services Found</p>
        </div>
        <div
          v-if="servicesLoading"
          class="tw-mx-auto tw-flex tw-justify-center"
        >
          <CommonLoader :loading="true"></CommonLoader>
        </div>
        <div class="specialisms_multiselect_modal_footer">
          <v-btn
            class="button button-purple-border"
            @click="onClickCancel"
            block
          >
            <Text variant="span"> Cancel </Text>
          </v-btn>
          <v-btn
            class="button button-orange"
            block
            @click="onClickContinue"
            :loading="isLoading"
          >
            <Text variant="span"> CONTINUE </Text>
          </v-btn>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import { computed, ref, onBeforeMount, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import UserService from "@/core/services/user.service";
import { WORKSTATION } from "@/store/modules/workstation";
import CommonLoader from "@/core/components/CommonLoader.vue";

export default {
  components: {
    CommonDialog,
    CommonLoader,
  },
  props: {
    phaseDetails: { type: Object, default: null },
  },

  setup(props: any, ctx: any) {
    const mainSelectedCategory = ref([]) as any;

    const selectedSubCategory = ref([]) as any;

    const panel = ref([]) as any;
    const isLoading = ref(false);
    const subCategoryValidationError = ref("");
    const subCategoryValidationErrorId = ref<any>(null);
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const servicesLoading = ref(true);
    const search = ref("");

    const newSubCategory = ref({}) as any;

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );

    const onCloseModal = () => {
      ctx.emit("onClickCloseTab");
    };

    const listOfSpecialismsCategory = ref([]) as any;

    const onClickMainCategory = (mainCategoryId: number) => {
      if (mainSelectedCategory.value.includes(mainCategoryId)) {
        const mainCategory = listOfSpecialismsCategory.value.find(
          (category: any) => category?.id === mainCategoryId
        );
        mainCategory.subCategories.forEach((element: any) => {
          const elementIndex = selectedSubCategory.value.findIndex(
            (subCategory: any) => subCategory === element.id
          );
          if (elementIndex > -1) {
            selectedSubCategory.value.splice(elementIndex, 1);
          }
        });
      } else {
        const mainCategory = listOfSpecialismsCategory.value.find(
          (category: any) => category?.id === mainCategoryId
        );

        mainCategory.subCategories.forEach((element: any) => {
          if (!selectedSubCategory.value.includes(element?.id)) {
            selectedSubCategory.value.push(element?.id);
          }
        });
      }
    };

    const onClickAddNewTradeSpecialism = () => {
      ctx.emit("onClickAddNewTradeSpecialism");
    };

    const onClickCancel = () => {
      ctx.emit("onClickCancel");
    };

    const onClickContinue = async () => {
      try {
        isLoading.value = true;
        const map = new Map();
        const payload: any = [];

        selectedSubCategory.value.forEach((subId: any) => {
          const categoryId = listOfSpecialismsCategory.value.find(
            (mainCategory: any) =>
              mainCategory.subCategories.some(
                (subObj: any) => subObj.id === subId
              )
          ).id;
          map.set(categoryId, [
            ...(map.has(categoryId) ? map.get(categoryId) : []),
            subId,
          ]);
        });

        map.forEach((subCategoryIds, categoryId) => {
          payload.push({ categoryId, subCategoryIds });
        });

        await store.dispatch(`${USER_STORE}/saveUserSkills`, {
          userId: user.value.id,
          payload,
        });
        await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        ctx.emit("onClickCancel", true);
      } catch (e) {
        console.log("Error", e);
      } finally {
        isLoading.value = false;
      }
    };

    const filterCategory = ref([]);
    const debouncedCategoryFilter = (event: any) => {
      let debounceTimeOut;
      const search = event?.target?.value || "";
      clearTimeout(debounceTimeOut);
      debounceTimeOut = setTimeout(() => {
        if (search) {
          const searchTerm = search.toLowerCase();
          filterCategory.value = listOfSpecialismsCategory.value.filter(
            (category: any) => {
              const mainCategoryMatch = category.poLabel
                .toLowerCase()
                .includes(searchTerm);

              if (mainCategoryMatch) {
                return true;
              }
              const subCategoryMatch = category.subCategories?.some(
                (subCategory: any) =>
                  subCategory.label.toLowerCase().includes(searchTerm)
              );

              return subCategoryMatch;
            }
          );
        } else {
          filterCategory.value = listOfSpecialismsCategory.value;
        }
      }, 300);
    };
    const onClickAddSubCategory = async (specialismId: number) => {
      try {
        subCategoryValidationErrorId.value = specialismId;
        if (newSubCategory.value[specialismId] > 25) {
          subCategoryValidationError.value = "Sub category is too long";
          return;
        }
        await UserService.createSubCategory(activeUserWorkstation.value?.id, {
          categoryId: specialismId.toString(),
          label: newSubCategory.value[specialismId],
        });
        listOfSpecialismsCategory.value = await UserService.getCustomCategories(
          activeUserWorkstation.value?.id
        );
        newSubCategory.value[specialismId] = "";
        subCategoryValidationErrorId.value = null;
      } catch (error: any) {
        console.log("error", error);
        subCategoryValidationError.value = error?.response?.data?.message;
      }
    };
    const getAllSpecialism = computed(
      () => store.getters[`${USER_STORE}/getAllSpecialism`]
    );

    // watch(
    //   () => selectedSubCategory.value,
    //   (value) => {
    //     if (value.length) {
    //       console.log(selectedSubCategory.value, "selectedSubCategory");
    //     }
    //   },
    //   { immediate: true, deep: true }
    // );
    // watch(
    //   () => mainSelectedCategory.value,
    //   (value) => {
    //     if (value.length) {
    //       console.log(mainSelectedCategory.value, "mainSelectedCategory");
    //     }
    //   },
    //   { immediate: true, deep: true }
    // );

    onMounted(async () => {
      if (!getAllSpecialism.value.length) {
        servicesLoading.value = true;
        await store.dispatch(
          `${USER_STORE}/setAllSpecialismsCategory`,
          activeUserWorkstation.value.id
        );
      }
      listOfSpecialismsCategory.value = JSON.parse(
        JSON.stringify(getAllSpecialism.value)
      );

      userSpecialisms.value.forEach((sp: any) => {
        const mainCategoryIndex = listOfSpecialismsCategory.value.findIndex(
          (l: { id: number }) => l.id === sp.category.id
        );

        if (mainCategoryIndex > -1) {
          if (
            sp.subCategoryIds.length ===
            listOfSpecialismsCategory.value[mainCategoryIndex].subCategories
              .length
          ) {
            listOfSpecialismsCategory.value.splice(mainCategoryIndex, 1);
          } else {
            listOfSpecialismsCategory.value[mainCategoryIndex].subCategories =
              listOfSpecialismsCategory.value[
                mainCategoryIndex
              ].subCategories.filter(
                (sub: { id: number }) => !sp.subCategoryIds.includes(sub.id)
              );
          }
        }
      });
      filterCategory.value = listOfSpecialismsCategory.value;
      servicesLoading.value = false;
    });
    return {
      onCloseModal,
      listOfSpecialismsCategory,
      mainSelectedCategory,
      selectedSubCategory,
      onClickMainCategory,
      onClickAddNewTradeSpecialism,
      onClickCancel,
      onClickContinue,
      panel,
      filterCategory,
      search,
      newSubCategory,
      onClickAddSubCategory,
      isLoading,
      subCategoryValidationError,
      subCategoryValidationErrorId,
      servicesLoading,
      debouncedCategoryFilter,
    };
  },
};
</script>
