<template>
  <GeneralDialog
    headerTitle="Create new folder"
    width="400px"
    maxWidth="100%"
    borderRadius="30px"
    @on-close="onCloseModal"
    class="dox__create__folder"
    :paddingLarge="true"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <v-form
          @submit.prevent="onCreate"
          :rules="validation"
          ref="folderNameFormRef"
          class="tw-w-full"
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <Text variant="span" textWeight="400">Folder name</Text>
            <v-text-field
              placeholder="Untitled"
              density="compact"
              :rules="validation.folderName"
              v-model="form.folderName"
              variant="outlined"
              class="input_field"
            ></v-text-field>
            <Text
              variant="span"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.5)"
              >You have {{ getRemainingCharacters }} characters remaining</Text
            >
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          label="Cancel"
          variant="tertiary"
          :disabled="state.loading"
          @click="onCloseModal"
        />
        <Button
          :disabled="state.loading"
          :isLoading="state.loading"
          label="Create"
          :isActive="isFormReady"
          @click="onCreate"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import documentConstants from "@/modules/documents/contants";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import { PROPERTY_STORE } from "@/store/modules/property";

const emits = defineEmits(["on-close", "on-success"]);
const props = defineProps(["isGallery", "isPropertyDocument"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const form = reactive({
  folderName: "",
});

const folderNameFormRef = ref(null);
const validation = ref({
  folderName: [
    (v) => !!v || "Folder name is required",
    (v) =>
      (!!v && v.length <= 50) || "Folder name must be less than 50 characters",
  ],
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const openedFolder = computed(
  () => store.getters[`${DOCUMENTS_STORE}/openedFolder`]
);
const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);

const getRemainingCharacters = computed(() => {
  const limit = 50;
  const remaining = limit - form.folderName.length;
  return remaining >= 0 ? remaining : 0;
});

const isFormReady = computed(() => {
  return form.folderName.length;
});

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};
const setDocumentList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setDocumentList`, params);
};

const getUserDocumentCalculatedSize = async (params) => {
  if (props.isPropertyDocument) {
    params.workStationId = propertyDetails.value?.userWorkstation?.id;
  }
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

const getFolderList = async (options = {}) => {
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;
  let list = null;
  const documentPromises = [];

  if (Object.keys(options).length) {
    params.options = options;
  }

  if (props.isPropertyDocument) {
    params.options.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );
    list = userDocuments.filter(
      (userDocument) =>
        userDocument.isDir === true && userDocument.parentId === hasParentParam
    );
    list.forEach((folderItem) => {
      documentPromises.push(
        getUserDocumentCalculatedSize({
          userId,
          userDocumentId: folderItem.id,
        })
      );
    });

    // Wait for all promises to resolve
    await Promise.all(documentPromises);

    if (documentPromises.length) {
      const documentFolderSizes = await Promise.all(documentPromises);
      list = list.map((item) => {
        const findFolder = documentFolderSizes.find((x) => x.id === item.id);

        return { ...item, ...findFolder };
      });
    }
  } catch (error) {
    const formError = "Error getting folder list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    setFolderList(list);
    setDocumentList([...list, ...fileList.value]);
  }
};

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const onCreate = async () => {
  const isFormValid = await folderNameFormRef.value.validate();
  if (!isFormValid.valid) return;
  state.loading = true;
  const parentId = openedFolder.value?.id || null;
  const { id: userId } = user.value;
  const { params: routeParams, name } = route;
  const propertyEntity =
    name.includes("property-details") && routeParams.id
      ? { propertyId: routeParams.id }
      : "";

  const params = {
    userId,
    formData: {
      name: form.folderName,
      parentId,
      entity: propertyEntity ? JSON.stringify(propertyEntity) : "",
      docType: props.isGallery ? "gallery" : "document",
    },
  };
  if (props.isPropertyDocument) {
    params.formData.workStationId = propertyDetails.value?.userWorkstation?.id;
    params.userId = propertyDetails.value?.propertyCreator?.id;
  }
  let docParams = {};
  if ("id" in openedFolder.value) {
    docParams = { parentId: openedFolder.value?.id, isDir: 1 };
  }

  try {
    await store.dispatch(`${DOCUMENTS_STORE}/createUserDocument`, params);
    await getFolderList(docParams);
  } catch (error) {
    const formError = "Error creating folder. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    state.loading = false;
    form.folderName = "";
    emits("on-success", documentConstants.NEW_FOLDER);
    emits("on-close");
  }
};

const onCloseModal = () => {
  emits("on-close");
};
</script>

<style lang="scss" scoped>
.dox__create__folder {
  .input_field {
    margin: 0;
    --v-input-control-height: auto;
    :deep(.v-input__control) {
      .v-field {
        border: 1px solid rgba($blueDark, 0.2);
        border-radius: 8px;
        .v-field__field {
          .v-field__input {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            min-height: auto;
            padding: 13px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
        }
        .v-field__outline {
          display: none;
        }
      }
    }
    :deep(.v-input__details) {
      padding: 0;
      .v-messages {
        .v-messages__message {
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          letter-spacing: 0.4px;
        }
      }
    }
  }
}
.reusable-button.v-btn--disabled,
.reusable-button.is-disabled {
  background-color: rgba($blueDark, 0.04);
}
.reusable-button.is-disabled:hover {
  color: rgba($blueDark, 0.5);
}
</style>
