<template>
  <div class="widget__card">
    <AnalyticsWidgetLayout
      class="estimate__card"
      title="Estimates Sent"
      :hideToolTip="true"
      headerIcon="analyticsEstimateSent"
    >
      <template #content>
        <div class="widget__content">
          <div class="widget__left custom__widget">
            <div class="widget_inner__content">
              <div class="widget__count">
                <h3>{{ estimatesSent?.iknowaCount }}</h3>
                <p>iknowa</p>
              </div>
              <div class="ratio">
                <v-icon
                  icon="mdi-arrow-top-right"
                  class="up-arrow"
                  v-if="estimatesSent?.iknowaPercentage"
                ></v-icon>
                <v-icon
                  icon="mdi-arrow-bottom-right"
                  class="down-arrow"
                  v-if="estimatesSent?.iknowaPercentage < 0"
                ></v-icon>
                <span
                  >{{ estimatesSent?.iknowaPercentage ? "+" : ""
                  }}{{ estimatesSent?.iknowaPercentage }}%</span
                >
              </div>
            </div>
          </div>
          <div class="widget__right custom__widget">
            <div class="widget_inner__content">
              <div class="widget__count">
                <h3>{{ estimatesSent?.directCount }}</h3>
                <p>Direct</p>
              </div>
              <div class="ratio">
                <v-icon
                  icon="mdi-arrow-top-right"
                  class="up-arrow"
                  v-if="estimatesSent?.directPercentage"
                ></v-icon>
                <v-icon
                  icon="mdi-arrow-bottom-right"
                  class="down-arrow"
                  v-if="estimatesSent?.directPercentage < 0"
                ></v-icon>
                <span
                  >{{ estimatesSent?.directPercentage ? "+" : ""
                  }}{{ estimatesSent?.directPercentage }}%</span
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </AnalyticsWidgetLayout>
    <AnalyticsWidgetLayout
      class="jobs__card"
      title="Jobs Completed"
      :hideToolTip="true"
      headerIcon="analyticsJobsCompleted"
    >
      <template #content>
        <div class="widget__content">
          <div class="widget__left custom__widget">
            <div class="widget_inner__content">
              <div class="widget__count">
                <h3>{{ jobsCompleted?.iknowaCount }}</h3>
                <p>iknowa</p>
              </div>
              <div class="ratio">
                <v-icon
                  icon="mdi-arrow-top-right"
                  class="up-arrow"
                  v-if="jobsCompleted?.iknowaPercentage"
                ></v-icon>
                <v-icon
                  icon="mdi-arrow-bottom-right"
                  class="down-arrow"
                  v-if="jobsCompleted?.iknowaPercentage < 0"
                ></v-icon>
                <span
                  >{{ jobsCompleted?.iknowaPercentage ? "+" : ""
                  }}{{ jobsCompleted?.iknowaPercentage }}%</span
                >
              </div>
            </div>
          </div>
          <div class="widget__right custom__widget">
            <div class="widget_inner__content">
              <div class="widget__count">
                <h3>{{ jobsCompleted?.directCount }}</h3>
                <p>Direct</p>
              </div>
              <div class="ratio">
                <v-icon
                  icon="mdi-arrow-top-right"
                  class="up-arrow"
                  v-if="jobsCompleted?.directPercentage"
                ></v-icon>
                <v-icon
                  icon="mdi-arrow-bottom-right"
                  class="down-arrow"
                  v-if="jobsCompleted?.directPercentage < 0"
                ></v-icon>
                <span
                  >{{ jobsCompleted?.directPercentage ? "+" : ""
                  }}{{ jobsCompleted?.directPercentage }}%</span
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </AnalyticsWidgetLayout>
    <AnalyticsWidgetLayout
      class="rating__card"
      title="Customer Rating"
      :hideToolTip="true"
      headerIcon="analyticsCustomerRating"
    >
      <template #content>
        <div class="widget__content">
          <div class="rating__content">
            <div class="customer__info">
              <div class="customer__icon">
                <!-- <img :src="userWorkStationImage" alt="" /> -->
                <img
                  :src="userWorkStationImage"
                  alt=""
                  v-if="userWorkStationImage"
                />
                <UserProfileLogo
                  :isRounded="false"
                  v-else
                  :userName="activeUserWorkstation?.name"
                  class="!tw-rounded-full"
                />
              </div>
              <p>{{ activeUserWorkstation?.name }}</p>
            </div>
            <div class="view-rating-box">
              <v-rating
                :model-value="getAverageOfAllProjectReview"
                active-color="orange"
                color="rgba(12, 15, 74, 0.26)"
                empty-icon="mdi-star"
                readonly
              ></v-rating>
              <p>{{ getAverageOfAllProjectReview }} out of 5</p>
            </div>
          </div>
        </div>
      </template>
    </AnalyticsWidgetLayout>
  </div>
</template>

<script lang="ts" setup>
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import AnalyticsWidgetLayout from "@/core/components/slots/AnalyticsWidgetLayout.vue";
import { ANALYTICS_STORE } from "@/store/modules/analytics";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { useStore } from "vuex";
import { reviewRatingState } from "@/core/models/project-rating";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { getCombineSameProjectReview } from "@/core/utils/common";

const store = useStore();

const estimatesSent = computed(
  () => store.getters[`${ANALYTICS_STORE}/getEstimatesSent`]
);

const jobsCompleted = computed(
  () => store.getters[`${ANALYTICS_STORE}/getJobsCompleted`]
);
const customerRating = computed(
  () => store.getters[`${ANALYTICS_STORE}/getCustomerRating`]
);
const userWorkStationImage = computed(() => {
  return store.getters[`${USER_STORE}/userWorkStationImage`];
});

const activeUserWorkstation = computed(() => {
  return store.getters[`${WORKSTATION}/activeUserWorkstation`];
});

const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

const combineSameProjectReview = computed(() =>
  getCombineSameProjectReview(getAllProjectReviewList.value)
);
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});
</script>

<style lang="scss" scoped>
.widget__card {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}
.widget__content {
  padding: 24px 20px;
  background-image: linear-gradient(
    180deg,
    #f3f3f6 0%,
    rgba(243, 243, 246, 0) 100%
  );
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  .custom__widget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .widget_inner__content {
      width: 145px;
      max-width: 100%;
      @include respond(xxl) {
        width: 105px;
      }
      .widget__count {
        text-align: left;
        h3 {
          @include fluidFont(30, 30, 1.4);
          font-size: 600;
          color: rgba(7, 9, 44, 1);
        }
        p {
          @include fluidFont(16, 16, 1.2);
          font-size: 400;
          color: rgba(7, 9, 44, 1);
        }
      }
      .ratio {
        display: flex;
        align-items: center;
        gap: 4px;
        border: 1px solid rgba($buttonText, 0.4);
        border-radius: 4px;
        padding: 6px;
        width: 100%;
        max-width: 100%;
        @include fluidFont(14, 14, 1.2);
        font-size: 400;
        color: rgba(7, 9, 44, 1);
        margin-top: 12px;
        .down-arrow {
          color: rgba($errorRed, 1);
          transform: rotate(0deg);
        }
        .up-arrow {
          color: rgba(52, 199, 89, 1);
        }
      }
    }
  }
  .widget__left {
    padding-right: calc(50% - 145px);
    border-right: 1px solid rgba(7, 9, 44, 0.2);
    @include respond(xxl) {
      padding-right: calc(50% - 105px);
    }
  }
  .widget__right {
    padding-left: calc(50% - 145px);
    @include respond(xxl) {
      padding-left: calc(50% - 105px);
    }
  }
  .rating__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 100%;
    .customer__info {
      display: flex;
      gap: 8px;
      align-items: center;
      .customer__icon {
        width: 44px;
        height: 44px;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        @include fluidFont(16, 16, 1.2);
        font-weight: 400;
        color: rgba(7, 9, 44, 1);
        width: calc(100% - 50px);
        text-align: left;
        max-width: 100%;
      }
    }
    .view-rating-box {
      display: flex;
      align-items: center;
      gap: 8px;
      :deep(.v-rating) {
        .v-rating__wrapper {
          .v-rating__item {
            label {
              .v-btn {
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
    }
    p {
      @include fluidFont(16, 16, 1.2);
      font-weight: 700;
      color: rgba(7, 9, 44, 1);
      letter-spacing: 0.12px;
    }
  }
}
</style>
