<template>
  <div
    class="counter tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-full"
    :class="{
      'rsm:tw-max-h-[16px] rsm:tw-max-w-[16px] rsm:tw-min-h-[16px] rsm:tw-min-w-[16px]':
        size === 'xsm',
      'rsm:tw-max-h-[22px] rsm:tw-max-w-[22px] rsm:tw-min-h-[22px] rsm:tw-min-w-[22px]':
        size === 'sm',
      'rsm:tw-max-h-[32px] rsm:tw-max-w-[32px] rsm:tw-min-h-[32px] rsm:tw-min-w-[32px]':
        size === 'mmd',
      'tw-max-h-[28px] tw-max-w-[28px] tw-min-h-[28px] tw-min-w-[28px] rsm:tw-max-h-[43px] rsm:tw-max-w-[43px] rsm:tw-min-h-[43px] rsm:tw-min-w-[43px]':
        size === 'md',
      'rsm:tw-max-h-[68px] rsm:tw-max-w-[68px] rsm:tw-min-h-[68px] rsm:tw-min-w-[68px]':
        size === 'lg',
    }"
  >
    <Text variant="p" textColor="#FFF">{{ value }}</Text>
  </div>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  size: {
    type: String,
    default: "md", // xsm, sm, md, lg
  },
  bgColor: {
    type: String,
    default: "#FAA500",
  },
  value: String,
});
</script>
<style lang="scss" scoped>
.counter {
  background: v-bind("props.bgColor");
}
</style>
