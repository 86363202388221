import { DEFAULT_CURRENCY } from "@/core/constants";

export default {
  /**
   * Format to currency
   * @param value
   * @returns
   */
  formatToCurrency(value: string) {
    let parsedValue = parseFloat(value);

    if (Number.isNaN(parsedValue)) {
      parsedValue = 0;
    }

    return (
      DEFAULT_CURRENCY +
      parsedValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
    );
  },

  formatCurrencyWithoutSymbol(number: any) {
    // Check if the value is 0, null, or undefined
    if (
      number === "0" ||
      number === 0 ||
      number === null ||
      number === undefined ||
      number === "undefined" ||
      number === ""
    ) {
      return "0";
    }

    // Remove existing commas
    const cleanedNumber = number.toString().replace(/,/g, "");

    // Check if the number has decimals
    if (cleanedNumber.includes(".")) {
      const [integerPart, decimalPart] = cleanedNumber.split(".");

      // Add commas to the integer part
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // Concatenate the integer and decimal parts with a dot
      return `${formattedIntegerPart}.${decimalPart.padEnd(2, "0")}`;
    }
    // Add commas to the integer part
    const formattedNumber = cleanedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Add .00 at the end
    return `${formattedNumber}.00`;
  },

  toNumber(value: string): number {
    if (typeof value === "string") {
      const cleanedValue = value.replace(/,/g, "");
      const parsedNumber = parseFloat(cleanedValue);

      return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
    }
    return value; // Or any other appropriate value
  },

  removeCurrency(value: string) {
    return value.replace("£", "");
  },
};
