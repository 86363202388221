<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.356445"
      y="1"
      width="14.079"
      height="14.079"
      rx="7.03951"
      fill="#C5D2F2"
    />
    <rect
      x="3.87695"
      y="4.52051"
      width="7.03951"
      height="7.03951"
      rx="3.51976"
      fill="#0C0F4A"
    />
  </svg>
</template>
<script>
export default {
  name: "ListIcon",
};
</script>
