export const CLIENTS_ROUTE = "clients";

export default {
  path: "/clients",
  name: CLIENTS_ROUTE,
  component: () => import("@/modules/clients/pages/ClientsPage.vue"),
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Clients",
  },
};
