<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="912px"
    max-width="100%"
    height="auto"
    :className="[
      'trade_passport_preview_modal',
      'md:!tw-h-auto md:!tw-overflow-[unset]',
    ]"
  >
    <template v-slot:body>
      <div class="header md:!tw-right-[-13px]">
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <v-carousel
        height="auto"
        class="md:!tw-h-auto"
        style="overflow: initial"
        hide-delimiter-background
        :show-arrows="false"
        :continuous="false"
        v-model="model"
      >
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-sheet>
            <div class="md:!tw-flex md:!tw-flex-col md:!tw-gap-[.5rem]">
              <h4 class="md:!tw-mb-0 md:!tw-text-[24px]">iknowa Courses</h4>
              <!-- <div
                class="carousel__img !tw-mb-0 tw-relative !tw-w-full tw-overflow-hidden rmd:!tw-h-[400px] rmd:!tw-m-0 rmd:!tw-pt-8 rmd:! rmd:!tw-mb-4"
              >
                <img
                  width="90"
                  class="tw-relative tw-z-10 !tw-object-contain"
                  :src="item.src"
                />
                <img
                  class="tw-w-full !tw-h-auto tw-absolute tw-z-0 tw-top-[-16px] tw-right-0 tw-object-contain rmd:!tw-top-[-27px]"
                  :src="require('@/assets/images/benefit-blured-bg-1.png')"
                />
              </div> -->
              <div
                class="trade_passport_preview_modal_title md:!tw-mb-0 md:!tw-text-[18px]"
              >
                {{ item?.title }}
              </div>
              <div
                class="trade_passport_preview_modal_text md:!tw-mb-0 md:!tw-text-[12px] md:!tw-leading-[18px] md:!tw-min-h-[unset]"
              >
                {{ item?.description }}
              </div>
              <div
                class="tw-w-full tw-min-h-[160px] tw-max-h-[160px] tw-overflow-hidden tw-rounded-[10px] rmd:!tw-min-h-[220px] rmd:!tw-max-h-[220px]"
              >
                <img
                  class="tw-w-full tw-object-cover tw-h-[160px] rmd:!tw-h-[220px]"
                  :src="item.src"
                />
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
        <div
          class="carousel-controls !tw-w-full tw-flex tw-flex-col !tw-gap-3 rmd:!tw-grid rmd:!tw-grid-cols-2"
        >
          <Button
            variant="secondary"
            label="Previous"
            @click="prevSlide"
            :isActive="model !== 0"
          />
          <Button
            variant="secondary"
            :label="model === items.length - 1 ? 'Close' : 'Next'"
            @click="handleNextClick"
          />
          <!-- <v-btn
          variant="elevated"
          class="button button-orange"
          @click="prevSlide"
          :disabled="model === 0"
        >
          Previous
        </v-btn>
        <v-btn
          variant="elevated"
          class="button button-orange"
          @click="handleNextClick"
        >
          {{ model === items.length - 1 ? "Go to Dashboard" : "Close" }}
        </v-btn> -->
        </div>
        <Button
          label="View Course Details"
          @click="viewCourseDetails(currentCourseSlideId)"
        ></Button>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import Button from "@/core/components/ui/general/Button.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import {
  airSourceHeatPumpData,
  greenSkillsData,
} from "@/modules/courses/data/courseDetails";
import { useRouter } from "vue-router";
import { mode } from "crypto-js";

export default {
  components: {
    CommonDialog,
    Button,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );
    const model = ref(0);

    const coursesWithExtraInfo = ref([]) as any;
    const listOfCourse = ref([]) as any;
    const getCourseloading = ref(false);
    const currentCourseSlideId = ref(1);

    const items = ref([
      {
        id: 1,
        src: require("@/assets/images/course/air-source.jpg"),
        title: "Air Source Heat Pump (L3)",
        description:
          "This Bootcamp Package is designed for existing heating engineers to be able to install air source heat pump technologies. This course also includes a minor electrical...",
      },
      {
        id: 2,
        src: require("@/assets/images/course/green-skills.jpg"),
        title: "Solar PV, Battery Storage & EV Charging Level 3",
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even...",
      },
    ]);

    const handleNextClick = () => {
      if (model.value === items.value.length - 1) {
        currentCourseSlideId.value = 1;
        ctx.emit("onClickClosePreviewModalButton");
      } else {
        model.value++;
        if (model.value === 1) {
          currentCourseSlideId.value = 2;
        }
      }
    };
    const prevSlide = () => {
      if (model.value > 0) {
        model.value--;
        if (model.value === 0) {
          currentCourseSlideId.value = 1;
        }
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickClosePreviewModalButton");
    };

    const getCourseData = async () => {
      try {
        getCourseloading.value = true;
        let response = await store.dispatch(`${COURSES_STORE}/getCourses`);
        listOfCourse.value = response.data;
        coursesWithExtraInfo.value = response.data.map((course: any) => {
          if (course.id === 1) {
            return {
              ...course,
              ...airSourceHeatPumpData,
            };
          }
          if (course.id === 2) {
            return {
              ...course,
              ...greenSkillsData,
            };
          }
        });
      } catch (error) {
        console.log("error:", error);
      } finally {
        getCourseloading.value = false;
      }
    };

    const viewCourseDetails = async (courseID: number) => {
      const course = coursesWithExtraInfo.value.find(
        (item: any) => item.id === courseID
      );
      if (course) {
        await store.dispatch(`${COURSES_STORE}/saveCourseDetails`, course);
        router.push({
          name: "courseDetails",
          params: { courseId: courseID, variableName: "course" },
        });
      } else {
        console.error(`Course with ID ${courseID} not found.`);
      }
    };

    onMounted(async () => {
      await getCourseData();
    });

    return {
      userName,
      onClickCloseButton,
      items,
      model,
      handleNextClick,
      prevSlide,
      viewCourseDetails,
      currentCourseSlideId,
    };
  },
};
</script>
