import axios from "axios";
import { API_URL } from "../../config";
import { AUTH_TOKEN, AUTH_USER } from "../constants";
import { deleteAllCookies, getLocalStorageWithExpiry } from "./common";

const axiosRequest = axios.create({
  baseURL: API_URL,
  timeout: 60 * 1000,
});

axiosRequest.interceptors.request.use(
  function (config) {
    const configureConfig = { ...config };
    const token = getLocalStorageWithExpiry(AUTH_TOKEN);
    if (configureConfig && configureConfig.baseURL === API_URL && token) {
      configureConfig.headers.Authorization = `Bearer ${token}`;
    }
    if (token) {
      // alias account
      const authUser = getLocalStorageWithExpiry(AUTH_USER);

      if (authUser && authUser.aliased) {
        configureConfig.headers.Aliased = true;
      }
    }
    return configureConfig;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosRequest.interceptors.response.use(
  function (res) {
    // Do something before request is sent
    return res.data;
  },
  function (error) {
    if (
      [401].includes(error?.response?.status) &&
      error.config.url !== "/auth/login"
    ) {
      deleteAllCookies();
      localStorage.clear();
      window.location.href = "/";
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosRequest;
