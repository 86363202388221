<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="832px"
    max-width="100%"
    persistent
    :className="['phaseproof__upload__modal ']"
  >
    <template v-slot:body>
      <v-form ref="attachFormRef" :rules="validation" @submit.prevent>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          :disabled="isLoadingUploadAttachments"
          @click="onClickToggleAttachmentModal"
        ></v-icon>
        <div class="modal__content">
          <div class="left_upload_img">
            <h4>Upload images and files.</h4>
            <div class="upload__area">
              <div class="uploade-wrapper">
                <v-file-input
                  class="upload_file"
                  label="Upload"
                  v-model="selectedFile"
                  :clearable="imageUrl ? true : false"
                  append-inner-icon="mdi-image-plus"
                  variant="solo-filled"
                  :class="{ 'added-img': imageUrl }"
                  @change="onFileChange"
                  @click:clear="clearImageUrl"
                  multiple
                >
                </v-file-input>
                <v-img
                  class="uploaded-img"
                  :width="200"
                  aspect-ratio="16/9"
                  cover
                  :src="imageUrl"
                ></v-img>
                <p class="error_msg" v-if="errorMessage">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="upload__text">
                <p>
                  <strong>Drag and drop documents, or</strong>
                  <span>Browse</span>
                </p>
                <p class="">
                  Attachments such as images, design ideas and documents help
                  better understand the project and provide increasingly
                  accurate estimates.
                </p>
              </div>
            </div>
          </div>

          <!-- ProjectAttachmentListComponent -->
          <div class="attachment-list !tw-p-4 sm:!tw-p-0">
            <div class="attachment-list__header">
              <!-- <p>Uploaded Files</p> -->
              <div class="tw-flex tw-items-center tw-gap-2">
                <Text variant="h6" textWeight="600">Uploaded Files</Text>
                <div
                  v-if="attachmentDataList?.length"
                  class="tw-w-[20px] tw-h-[20px] tw-rounded-full tw-bg-[#1FB27A] tw-flex tw-flex-col tw-items-center tw-justify-center"
                >
                  <Text variant="xsmall" textColor="#fff" textWeight="400">{{
                    attachmentDataList.length
                  }}</Text>
                </div>
              </div>
            </div>

            <div
              v-if="!isLoading && attachmentDataList.length"
              class="attachment-list__list"
            >
              <div
                v-for="(attachment, index) of attachmentDataList"
                :key="index"
                class="attachment-list__item"
              >
                <div class="attachment-list__image">
                  <div class="attachment-list__image-init">
                    <img
                      v-if="isImageType(attachment?.uploadedImages)"
                      class="attachment-list__image-covered"
                      :src="attachment?.uploadedImages?.url"
                      alt="Image"
                    />
                    <img
                      v-else
                      class="tw-h-[85px]"
                      :src="
                        require('@/assets/icons/sidebar-icons/documents.svg')
                      "
                      alt="icon"
                    />
                    <div
                      v-if="!isLoadingUploadAttachments"
                      @click="
                        removeImageFromAttachments(
                          index,
                          attachment?.uploadedImages?.id
                        )
                      "
                      class="attachment-list__delete"
                    >
                      <img
                        src="@/assets/icons/delete-icon-white.svg"
                        alt="Image"
                      />
                    </div>
                  </div>
                  <div class="attachment-list__name">
                    <p>{{ attachment?.uploadedImages?.name }}</p>
                  </div>
                </div>
                <div class="attachment-list__content">
                  <v-select
                    v-model="attachment.propertyId"
                    label="Select property"
                    :items="projectProperties"
                    :rules="validation.property"
                    item-title="address"
                    item-value="id"
                    multiple
                    variant="outlined"
                    density="compact"
                    class="selectprop_dropdown"
                    :menu-props="{
                      contentClass: 'members__selector__dropdown',
                    }"
                  >
                  </v-select>
                  <v-select
                    v-model="attachment.taskId"
                    label="Select Task"
                    :items="phaseDetails.projectStageTasks"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="compact"
                    class="selectprop_dropdown"
                    :menu-props="{
                      contentClass: 'members__selector__dropdown',
                    }"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="no-attachment" v-else>
              Your added project attachments will show up here
            </div>
            <CommonLoader
              :loading="true"
              v-if="isLoading"
              class="loader-container"
            />
          </div>
        </div>

        <div class="footer-buttons">
          <v-btn
            class="button button-purple-border"
            @click="onClickToggleAttachmentModal"
            variant="outlined"
            :disabled="isLoadingUploadAttachments"
          >
            cancel
          </v-btn>
          <v-btn
            class="button button-orange"
            :loading="isLoadingUploadAttachments"
            :disabled="isLoading"
            @click="onClickSaveButtonAttachmentsModal"
          >
            save
          </v-btn>
        </div>
      </v-form>
    </template>
  </CommonDialog>
</template>
<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { onMounted, ref } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { useStore } from "vuex";
import { computed } from "vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { USER_STORE } from "@/store/modules/user";
import { getImageApiUrl, imageExtensions } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { ElMessage } from "element-plus";
export default {
  components: {
    CommonDialog,
    CommonLoader,
  },
  props: {
    phaseDetails: { type: Object, default: null },
    estimatePhaseId: {
      type: Number,
    },
    projectEstimateId: {
      type: Number,
    },
    phaseIndex: {
      type: Number,
    },
  },

  setup(props: any, ctx: any) {
    const store = useStore();
    const isNewPropertyAdded = ref(false);
    const imageUrl = ref("");
    const selectedFile = ref([]);
    const isClearImage = ref("true");
    const attachFormRef = ref(null) as any;
    const validation = ref({
      property: [
        (v: string | Array<number>) => {
          return !!v || "please selected property";
        },
        (v: string | Array<number>) => {
          return !!v.length || "please selected property";
        },
      ],
    }) as any;

    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const cloneAttachmentDataList = ref([]) as any;
    const isLoadingUploadAttachments = ref(false);
    const isLoading = ref(false);
    const projectDetails = computed(
      () => store.getters[`${JOBS_STORE}/getJobDetails`]
    );

    const projectProperties = computed(() =>
      projectDetails.value?.projectProperties.map((data: any) => data.property)
    );
    const onClickToggleAttachmentModal = () => {
      if (isLoadingUploadAttachments.value) return;
      ctx.emit("onClose");
      isNewPropertyAdded.value = false;
    };
    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
                type: "image",
              };
              addPropertyImage();
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              addPropertyImage();
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };

    const removeImageFromAttachments = (index: number, attachmentId: any) => {
      attachmentDataList.value.splice(index, 1);
      cloneAttachmentDataList.value[index].isDeleted = true;
    };
    const addPropertyImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        propertyId: null,
        taskId: null,
      });
      uploadedImages.value = null;
      isNewPropertyAdded.value = true;
      selectedFile.value = [];
      imageUrl.value = "";
    };
    const onClickSaveButtonAttachmentsModal = async () => {
      try {
        if (!props.projectEstimateId) {
          ElMessage.error("project estimate Id not Found");
          onClickToggleAttachmentModal();
          return;
        }
        if (attachmentDataList.value.length) {
          const isFormValid = await attachFormRef.value?.validate();
          if (!isFormValid.valid) return;
        }

        isLoadingUploadAttachments.value = true;

        //new attachment add functionality
        const newAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => attachment?.uploadedImages.imageData
        );
        const filterAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => !attachment?.uploadedImages.imageData
        );
        let newCreatedAttachment = [];
        if (newAttachmentDataList.length) {
          const formData = new FormData();
          newAttachmentDataList.forEach((attachment: any) => {
            if (attachment.uploadedImages.imageData) {
              formData.append(`files`, attachment.uploadedImages.imageData);
            }
          });

          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          newCreatedAttachment = uploadMultipleImage?.map(
            (item: any, index: number) => ({
              attachment: item.attachment,
              originalName: item.originalName,
              properties: newAttachmentDataList[index]?.propertyId?.map(
                (id: any) => ({ propertyId: id, isDeleted: false })
              ),
              taskId: newAttachmentDataList[index]?.taskId,
              isDeleted: false,
            })
          );
        }

        const finalDataAttachment: any = [];
        const projectDefaultPropertyIds = projectProperties.value.map(
          (property: any) => property?.id
        );

        cloneAttachmentDataList.value.forEach((attachment: any, index: any) => {
          let attachmentData;
          if (attachment.isDeleted) {
            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              taskId: attachment.taskId,
              properties: projectAttachmentData.value[
                index
              ].estimatePhaseAttachmentsProperties.map((property: any) => ({
                id: property?.id,
                propertyId: property?.property?.id,
                isDeleted: true,
              })),
              isDeleted: attachment.isDeleted,
            };
          } else {
            const propertyIds: any = [];

            const changeAttachmentData = filterAttachmentDataList.find(
              (data: any) =>
                data.uploadedImages.attachmentId ===
                attachment?.uploadedImages?.attachmentId
            );
            projectDefaultPropertyIds.forEach((propertyId: number) => {
              if (
                attachment.propertyId.includes(propertyId) &&
                changeAttachmentData?.propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  id: projectAttachmentData.value[
                    index
                  ].estimatePhaseAttachmentsProperties.find(
                    (property: any) => property.property.id === propertyId
                  )?.id,
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                !attachment.propertyId.includes(propertyId) &&
                changeAttachmentData?.propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                attachment.propertyId.includes(propertyId) &&
                !changeAttachmentData?.propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  id: projectAttachmentData.value[
                    index
                  ].estimatePhaseAttachmentsProperties.find(
                    (property: any) => property.property.id === propertyId
                  )?.id,
                  propertyId: propertyId,
                  isDeleted: true,
                });
              }
            });

            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              properties: propertyIds || [],
              taskId: changeAttachmentData?.taskId,
            };
          }
          finalDataAttachment.push(attachmentData);
        });

        if (newCreatedAttachment.length) {
          const createResponse =
            await tradeEstimateService.createEstimateAttachments({
              projectEstimationId: props.projectEstimateId,
              estimatePhaseId: props.phaseDetails.id,
              attachments: newCreatedAttachment,
            });
        }
        if (finalDataAttachment.length) {
          const UpdateResponse =
            await tradeEstimateService.updateEstimateAttachments({
              projectEstimationId: props.projectEstimateId,
              estimatePhaseId: props.phaseDetails.id,
              attachments: finalDataAttachment,
            });
        }
        isLoadingUploadAttachments.value = false;
        onClickToggleAttachmentModal();
      } catch (error) {
        console.log(error);
      }
    };
    const projectAttachmentData = ref();
    const setProjectData = async () => {
      try {
        isLoading.value = true;
        const projectAttachment: any =
          await tradeEstimateService.getEstimateAttachments(
            props.phaseDetails.id
          );
        projectAttachmentData.value = projectAttachment;
        attachmentDataList.value = await Promise.all(
          projectAttachment.map(async (propertyItems: any) => {
            return {
              uploadedImages: {
                url: await getImageUrl(propertyItems?.attachment),
                name: propertyItems?.originalName,
                attachment: propertyItems?.attachment,
                attachmentId: propertyItems?.id,
              },
              propertyId: propertyItems.estimatePhaseAttachmentsProperties.map(
                (property: any) => property.property.id
              ),

              taskId: propertyItems?.phaseTask?.id,
            };
          })
        );
        cloneAttachmentDataList.value = JSON.parse(
          JSON.stringify(attachmentDataList.value)
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const getImageUrl = async (profileImage: string) => {
      try {
        const imageUrl = getImageApiUrl(profileImage, true);
        const encoded: any = await $axios.get(imageUrl);
        return encoded.publicUrl;
      } catch (error) {
        return null;
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
    };
    onMounted(() => {
      setProjectData();
    });
    const isImageType = (attachments: any) => {
      if (attachments?.type === "image") {
        return true;
      }
      return imageExtensions.some((ext) =>
        attachments?.url?.toLowerCase().endsWith(ext)
      );
    };
    return {
      onClickToggleAttachmentModal,
      onFileChange,
      uploadedImages,
      errorMessage,
      imageUrl,
      addPropertyImage,
      attachmentDataList,
      projectProperties,
      isLoadingUploadAttachments,
      onClickSaveButtonAttachmentsModal,
      removeImageFromAttachments,
      validation,
      attachFormRef,
      isLoading,
      isClearImage,
      isImageType,
      clearImageUrl,
      selectedFile,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectReview.scss";

.loader-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  top: 50%;
}

.attachment-list {
  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  color: #0c0f4a;
  width: calc(100% - 400px);
  max-width: 100%;
  padding: 0 !important;
  position: relative;
  @include respond(s720) {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
  // @include respond(xxl) {
  //   margin: 32px 0 0;
  //   width: 100%;
  // }

  &__header {
    @include fluidFont(14, 14, 25px);
    font-weight: 700;
    letter-spacing: 0.25px;
    padding-bottom: 16px;
    border-bottom: 0.66px dashed #8687a5;
  }

  &__item {
    display: grid;
    grid-template-columns: 156px 1fr;
    align-items: center;
    grid-gap: 10px;
    padding: 16px 0;
    @include respond(md) {
      grid-template-columns: 135px 1fr;
    }

    @media (max-width: 360px) {
      grid-template-columns: 1fr;
    }

    &:not(:last-child) {
      border-bottom: 0.66px dashed #8687a5;
    }
  }

  &__list {
    max-height: 465px;
    overflow: auto;
  }

  &__image {
    &-init {
      position: relative;
      height: 88px;
      overflow: hidden;
      border-radius: 8px;
    }

    &-covered {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    // height: 88px;
    //display: grid;
    align-content: center;
    //  align-items: center;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 360px) {
      height: auto;
    }
  }

  &__name {
    margin-top: 10px;
    @include fluidFont(12, 12, 22px);
    font-weight: 500;
    letter-spacing: 0.25px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__delete {
    cursor: pointer;
    background-color: #fa4b00b2;
    border: 1px solid #fa4b00;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    img {
      max-width: 12px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &:hover {
      background-color: #fa4b00;
    }
  }

  &__menu-toggle {
    padding: 0;
    text-transform: none;
    color: #4f55f0;
    @include fluidFont(14, 14, 22px);
    font-weight: 600;
    text-decoration: underline;
    width: auto;
    justify-self: flex-start;

    :deep(.v-btn__overlay),
    :deep(.v-btn__underlay) {
      display: none;
    }
  }

  .select_prop_dropdown {
    margin-top: 10px;
    width: 100%;

    :deep(.v-select__selection-text) {
      font-size: 12px;
    }

    :deep(.v-input__control) {
      position: relative;
      .v-input__slot {
        min-height: 30px;
        &:empty {
          &::before {
            content: attr(data-placeholder);
            color: rgba($blueDark, 1);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
      }
      &.focus {
        .v-input__slot {
          &:empty {
            &::before {
              display: none;
            }
          }
        }
      }
      .v-field {
        padding: 7px 12px 6px;
        border: 1px solid rgba($buttonText, 0.4);
        background-color: rgba($white, 1);
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        letter-spacing: 0.4px;
        color: #80829f;
        align-items: center;
        gap: 4px;
        border-radius: 5px;

        .v-field__field {
          .v-label {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            letter-spacing: 0.4px;
            color: rgba($blueDark, 1);
            opacity: 1;
            display: none;
          }
          .v-field__input {
            padding: 0;
            min-height: auto;
            .v-select__selection {
              .v-select__selection-text {
                color: rgba($blueDark, 1);
                line-height: 1;
              }
            }
            input {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.4px;
              color: rgba($blueDark, 1);
              top: -7px;
              opacity: 1;
              &::placeholder {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                letter-spacing: 0.4px;
                color: rgba($blueDark, 1);
                opacity: 1;
              }
            }
          }
        }
        .v-field__outline {
          display: none;
          .v-field__outline__notch {
            .v-label {
              display: none;
            }
          }
        }
        .v-field__prepend-inner {
          .v-icon {
            opacity: 1;
            color: rgba($blueDark, 1);
          }
        }
        .v-field__append-inner {
          .v-icon {
            opacity: 1;
            color: rgba($blueDark, 1);
          }
        }
      }
    }
  }

  :deep(.v-select--selected .v-field .v-field__input > input) {
    opacity: 0 !important;
    padding: 0 !important;
  }
}

.attachment-list__menu {
  box-shadow: 0px 0px 10px 0px #0c0f4a29;
  width: 500px;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;

  @media (max-width: 767px) {
    width: 342px;
  }

  @media (max-width: 360px) {
    width: 290px;
  }

  &-header {
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.12px;
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 0.66px dashed #8687a5;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &-close {
    cursor: pointer;
    flex-shrink: 0;
    margin-left: auto;
  }

  &-group-input {
    padding: 10px;
    display: grid;
    grid-gap: 8px;
    font-weight: 600;
    @include fluidFont(12, 12, 12px);
    letter-spacing: 0.31px;
    background-color: #f5f5f8;
    border-radius: 4px;
  }

  :deep(.attachment-list__menu-input) {
    .v-input__control {
      .v-field {
        border-radius: 6px;
        border: 0.77px solid #80829f66;

        .v-field__input {
          padding: 8px;
          min-height: 30px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.31px;
        }

        .v-field__overlay {
          background-color: #fff;
          opacity: 1;
        }
      }
    }
  }

  &-group-list {
    margin-top: 10px;
    border: 1px solid #80829f66;
    border-radius: 4px;
    display: grid;
    grid-gap: 4px;
    max-height: 165px;
    overflow: auto;

    &-item {
      padding: 6px 12px;
      @include fluidFont(12, 12, 16px);
      font-weight: 500;
      letter-spacing: 0.4;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover,
      &.is-active {
        background-color: #0c0f4a0a;
      }
    }
  }

  &-footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    flex-wrap: wrap;

    .button {
      height: auto;
      min-height: 26px;
      border-radius: 5px;
      padding: 5px 12px;

      :deep(.v-btn__content) {
        @include fluidFont(10, 10, 12px);
        letter-spacing: 0.09px;
      }

      &-orange {
        min-width: 112px;
      }
    }
  }
}
.no-attachment {
  text-align: center;
  @include fluidFont(14, 14, 1.3);
  color: rgba($blueDark, 0.5);
  letter-spacing: 0.15px;
  margin-top: 16px;
}

.attachment-list__content {
  .v-form {
    width: 100%;
    max-width: 100%;
  }
  p {
    background-color: rgba($lightPurple, 0.5);
    color: rgba($blueDark, 1);
    padding: 10px;
    width: max-content;
    // margin-top: 20px;
  }
}
.selectprop_dropdown {
  width: 100%;
  max-width: 100%;
  margin-bottom: 8px;
  :deep(.v-field) {
    padding: 7px 12px 6px;
    @include fluidFont(12, 12, 1.2);
    font-weight: 400;
    letter-spacing: 0.4px;
    color: #80829f;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    background-color: rgba($white, 1);
    border: 1px solid rgba($buttonText, 0.4);
    .v-field__field {
      .v-field__input {
        min-height: auto;
        height: auto;
        padding: 0;
        // flex-direction: row-reverse;
        input {
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgb($blueDark, 1);
          letter-spacing: 0.4px;
          padding: 0;
          margin-right: auto;
        }
        .v-select__selection {
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          padding: 0;
        }
      }
    }
    .v-field__outline {
      display: none;
    }
  }
}

.error-border {
  border: 2px solid red;
}
</style>
