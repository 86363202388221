<template>
  <GeneralDialog :headerTitle="modalHeader.title" @on-close="onCloseModal">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-2 tw-min-w-[330px] tw-max-w-[330px]"
      >
        <v-form
          :rules="validation"
          ref="emailFormRef"
          @submit.prevent="onAddClick"
          class="tw-w-full tw-max-w-full"
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <Text variant="span" textWeight="400">Email Address</Text>
            <v-text-field
              type="email"
              density="compact"
              :rules="validation.email"
              v-model="form.email"
              variant="outlined"
              class="tw-rounded-[8px] !tw-max-w-full"
              label="Email address"
            ></v-text-field>
            <p v-if="isShowError" class="tw-text-[#f45454]">
              {{ isShowError }}
            </p>
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3"
      >
        <Button
          variant="tertiary"
          :disabled="loading"
          label="cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          :disabled="loading"
          :isLoading="loading"
          label="add"
          :isActive="true"
          @click="onAddClick"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref, reactive } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import manageEmailService from "@/core/services/manage-email.service";
import { watch } from "vue";

const emits = defineEmits(["on-close", "onClickAdd"]);

const modalHeader = {
  title: "Link Email Address",
};
const loading = ref(false);
const form = reactive({
  email: "",
});

const emailFormRef = ref(null);

const isShowError = ref("");
const validation = ref({
  email: [
    // (v) => !!v || "Email is required",
    (v) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Your email address is incorrect. Please provide a valid email address.",
  ],
});

const onCloseModal = () => {
  emits("on-close", false);
};

watch(
  () => form.value,
  (newValue) => {
    if (isShowError.value) {
      isShowError.value = "";
    }
  },
  { deep: true }
);

const onAddClick = async () => {
  try {
    loading.value = true;
    isShowError.value = null;
    const isFormValid = await emailFormRef.value.validate();

    if (!isFormValid.valid) return;
    const response = await manageEmailService.addEmail(form);
    emits("onClickAdd", response);
  } catch (error) {
    console.error("email add error", error);
    isShowError.value = error.response.data.message;
    // emits("on-close", false);
  } finally {
    loading.value = false;
  }
};
</script>
<script>
export default {
  name: "AddEmailModal",
};
</script>
<style lang="scss" scoped></style>
