<template>
  <div class="desktop__wrapper">
    <v-table
      height="450px"
      fixed-header
      class="tw-w-full tw-pb-4 tw-box-border tw-relative"
    >
      <thead>
        <tr>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >ID Number</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Location</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Size</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Access Information</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Supplier</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Installer</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Purchase Price</Text
            >
          </th>
          <th class="text-left">
            <Text
              variant="p"
              whiteSpace="nowrap"
              textColor="rgba(12, 15, 74, 0.5)"
              >Date Of Delivery</Text
            >
          </th>
          <th class="text-left">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="assets.length">
          <tr
            v-for="(item, index) in assets"
            :key="index"
            class="tw-cursor-pointer"
            :class="{
              'tw-bg-blue-100':
                state.selectedRow === item.id && state.selectedRow !== null,
            }"
            @click="onHandleClickRow($event, item)"
          >
            <td class="text-left">
              <div class="tw-flex tw-items-center tw-gap-2">
                <Text variant="p" whiteSpace="nowrap">{{
                  item?.idNumber
                }}</Text>
              </div>
            </td>
            <td class="text-left">
              <Text variant="p" whiteSpace="nowrap">{{ item?.location }}</Text>
            </td>
            <td class="text-left">
              <Text variant="span" whiteSpace="nowrap">{{ item?.size }}</Text>
            </td>
            <td class="text-left">
              <Text variant="span" whiteSpace="nowrap">{{
                item?.accessInformation
              }}</Text>
            </td>
            <td class="text-left">
              <Text variant="span" whiteSpace="nowrap">{{
                item?.supplier
              }}</Text>
            </td>
            <td class="text-left">
              <Text variant="span" whiteSpace="nowrap">{{
                item?.installer
              }}</Text>
            </td>
            <td class="text-left">
              <Text variant="span" whiteSpace="nowrap">{{
                formattedCurrency(item?.purchasePrice)
              }}</Text>
            </td>
            <td class="text-left">
              <Text variant="span" whiteSpace="nowrap">{{
                formattedDate(item?.dateOfDelivery)
              }}</Text>
            </td>
            <td class="text-right">
              <div
                class="tw-w-full tw-flex tw-items-center tw-gap-3 tw-justify-end"
              >
                <PencilCircularBlueIcon
                  v-if="isUserHasEditRight"
                  @click="onEditRegister(item)"
                />
                <EyeCircularGrayIcon @click="onViewRegister(item)" />
              </div>
            </td>
          </tr>
        </template>
        <div
          v-else
          class="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-absolute tw-top-0 tw-right-0"
        >
          <Text>No Data</Text>
        </div>
      </tbody>
    </v-table>
  </div>
  <!-- Mobile Version -->

  <div class="mobile__wrapper">
    <div class="property_card__list">
      <div
        v-for="(item, index) in assets"
        :key="index"
        class="tw-cursor-pointe property_card__list__item"
        :class="{
          'tw-bg-blue-100':
            state.selectedRow === item.id && state.selectedRow !== null,
        }"
        @click="onHandleClickRow($event, item)"
      >
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >ID Number</Text
          >
          <Text variant="p" whiteSpace="nowrap">{{ item?.idNumber }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Location</Text
          >
          <Text variant="p" whiteSpace="nowrap">{{ item?.location }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Size</Text
          >
          <Text variant="span" whiteSpace="nowrap">{{ item?.size }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Access Information</Text
          >
          <Text variant="span" whiteSpace="nowrap">{{
            item?.accessInformation
          }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Supplier</Text
          >
          <Text variant="span" whiteSpace="nowrap">{{ item?.supplier }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Installer</Text
          >
          <Text variant="span" whiteSpace="nowrap">{{ item?.installer }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Purchase Price</Text
          >
          <Text variant="span" whiteSpace="nowrap">{{
            formattedCurrency(item?.purchasePrice)
          }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Date Of Delivery</Text
          >
          <Text variant="span" whiteSpace="nowrap">{{
            formattedDate(item?.dateOfDelivery)
          }}</Text>
        </div>
        <div class="property_card__list__item__content">
          <Text
            variant="p"
            whiteSpace="nowrap"
            textColor="rgba(12, 15, 74, 0.5)"
            >Action</Text
          >
          <div
            class="tw-w-full tw-flex tw-items-center tw-gap-3 tw-justify-end"
          >
            <PencilCircularBlueIcon
              v-if="isUserHasEditRight"
              @click="onEditRegister(item)"
            />
            <EyeCircularGrayIcon @click="onViewRegister(item)" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modals -->
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PropertyListActionMenu from "@/modules/properties/components/PropertyListActionMenu.vue";
import EyeCircularGrayIcon from "@/core/components/icons/EyeCircularGrayIcon.vue";
import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";
import CurrencyFilter from "@/filters/currency.filter";
import appFilters from "@/filters";
import { PROPERTY_STORE } from "@/store/modules/property";

const router = useRouter();
const store = useStore();
const props = defineProps({ isUserHasEditRight: Boolean });
const emits = defineEmits(["on-view-register", "on-edit-register"]);

const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedProperty: null,
});

const assets = computed(() => {
  return store.getters[`${PROPERTY_STORE}/assetRegisters`];
});

const onViewRegister = (item) => {
  emits("on-view-register", item);
};

const onEditRegister = async (item) => {
  emits("on-edit-register", item);
};

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const formattedDate = (date) => {
  return appFilters.formatToDate(date, "DD MMM YYYY");
};

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      state.selectedRow = state.selectedRow === item.id ? null : item.id;
      state.result.push(event.type);
      state.clicks = 0;
    }, state.delay);
  } else {
    clearTimeout(state.timer);
    state.selectedProperty = item;
    state.selectedRow = item.id;
    state.clicks = 0;
    emits("on-view-register", item);
  }
};

onMounted(() => {
  // console.log(assets.value, "asset registersss");
});
</script>
<style lang="scss" scoped>
:deep(.v-table__wrapper) {
  &::-webkit-scrollbar {
    display: none;
  }
}

.desktop__wrapper {
  @include respond(s1024) {
    display: none;
  }
}

.mobile__wrapper {
  display: none;
  @include respond(s1024) {
    display: block;

    .property_card__list {
      padding: 16px;
      background-color: rgba($cardBorder, 1);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .property_card__list__item {
        background-color: rgba($white, 1);
        padding: 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .property_card__list__item__content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .action {
          align-items: flex-end;
        }
      }
    }
  }
}
</style>
