<template>
  <div
    class="estimates-received-main tw-h-full tw-w-full tw-pb-2 tw-box-border"
  >
    <CommonLoader v-if="isLoading" :loading="isLoading" />
    <!-- <EstimatesReceivedList /> -->
    <EstimateReceivedPreview
      v-if="estimateAllDetails && !isLoading"
      :details="estimateAllDetails"
    />
  </div>
</template>
<script lang="ts" setup>
// import EstimatesReceivedList from "@/modules/project/components/screens/EstimatesReceivedList.vue";
import EstimateReceivedPreview from "@/modules/project/components/screens/EstimateReceivedPreview.vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const route = useRoute();
const isLoading = ref(true);
const estimateAllDetails = ref(null) as any;
onMounted(async () => {
  let estimateId;
  if (route.params && route.params.estimateId) {
    estimateId = route.params.estimateId;
  }
  if (estimateId) {
    try {
      isLoading.value = true;
      const response = await store.dispatch(
        `${TRADE_ESTIMATE_STORE}/setTradeEstimateDetails`,
        {
          projectEstimateId: estimateId,
        }
      );

      estimateAllDetails.value = response;
    } catch (error) {
      console.log();
    } finally {
      isLoading.value = false;
    }
  }
});
</script>
<script lang="ts">
export default {
  name: "EstimatesReceivedMainComponent",
};
</script>
<style lang="scss" scoped></style>
