<template>
  <InvitationSuccessComponent
    v-if="isShowInvitationsSentModal"
    @onClose="onClickCloseButton"
    :members="form.members"
  />
  <CommonDialog
    v-else
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['add_contractor_dialog']"
    width="968px"
  >
    <template v-slot:body>
      <div class="header">
        <h4>Invite a connection to iknowa platform</h4>
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <div class="modal__content">
        <!-- <div class="modal__content_header_note">
          Each added member costs £9.99/month. <span> Learn More. </span>
        </div> -->
        <div class="modal__inner__content">
          <v-form ref="emailFormRef" @submit.prevent class="tw-w-full">
            <div
              v-for="(member, index) in form.members"
              :key="index"
              class="tw-w-full invite_members_form"
            >
              <div class="input_field email_address">
                <label>Email Addresses</label>
                <v-text-field
                  type="email"
                  density="compact"
                  v-model="member.email"
                  :rules="validation.email"
                  variant="outlined"
                  class="c-input"
                  placeholder="Email address"
                ></v-text-field>
              </div>
              <div class="input_field email_address">
                <label>Invite As</label>
                <v-select
                  class="c-select"
                  v-model="member.inviteAs"
                  :items="inviteAsType"
                  :rules="validation.inviteAs"
                  placeholder="Select Role"
                  variant="outlined"
                />
              </div>
              <div class="input_field tags_wrapper">
                <label>Tags</label>
                <v-select
                  class="c-select"
                  v-model="member.tags"
                  :items="Tags"
                  :rules="validation.tags"
                  :error-messages="IsValidTag(member)"
                  placeholder="Select Tags"
                  multiple
                  variant="outlined"
                  :menu-props="{ contentClass: 'tags_dropdown' }"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      @click="toggle(member)"
                      class="list__item"
                    >
                      <template v-slot:prepend>
                        <v-checkbox-btn
                          :color="
                            likesSomeTag(member) ? 'indigo-darken-4' : undefined
                          "
                          :indeterminate="
                            likesSomeTag(member) && !likesAllTags(member)
                          "
                          :model-value="likesAllTags(member)"
                        ></v-checkbox-btn>
                      </template>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item>
                    <v-list-item>
                      <v-text-field
                        v-model="customTag"
                        label="Add custom tag"
                        variant="outlined"
                        class="inner_input"
                      ></v-text-field>
                      <v-btn
                        @click="addCustomTag(member)"
                        class="button button-orange"
                        >Add</v-btn
                      >
                    </v-list-item>
                  </template>
                </v-select>
              </div>
              <div class="input_field email_address">
                <label>Property</label>
                <v-select
                  class="c-select"
                  v-model="member.propertyIds"
                  :items="propertyList"
                  item-title="address"
                  item-value="id"
                  :rules="validation.propertyIds"
                  placeholder="Select Property"
                  variant="outlined"
                  multiple
                />
              </div>

              <v-btn
                v-if="form.members.length > 1"
                icon="mdi-close"
                @click="onRemoveEmail(index)"
                class="remove_btn"
                variant="plain"
              ></v-btn>
            </div>
            <v-btn
              variant="text"
              class="button button-orange add_btn"
              @click="onAddEmail"
            >
              +ADD
            </v-btn>
            <div class="input_field message_wrapper">
              <label>Message (optional):</label>
              <v-textarea
                v-model="memberMessage"
                variant="outlined"
              ></v-textarea>
            </div>
          </v-form>
        </div>
      </div>
      <div class="modal__footer">
        <v-btn class="button button-purple-border" @click="onClickCloseButton"
          >cancel</v-btn
        >
        <v-btn
          class="button button-orange"
          @click="onSendInvitation"
          :loading="isShowLoadingSendInvitation"
          :disabled="!isFormValid || !hasAtLeastOneMember"
          >send invitation</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed, ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { CONNECTIONS } from "@/store/modules/connections";
import { PROPERTY_STORE } from "@/store/modules/property";
import { onMounted } from "vue";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import InvitationSuccessComponent from "@/core/components/InvitationSuccessComponent.vue";

export default {
  props: {
    defaultPropertyId: {
      type: Number,
      default: null,
    },
  },
  components: {
    CommonDialog,
    InvitationSuccessComponent,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const memberMessage = ref("");
    const isShowLoadingSendInvitation = ref(false);
    const isShowInvitationsSentModal = ref(false);
    const selectedTags = ref([]) as any;
    const Tags = ref(["Occupier", "Trade Contactor", "Manager", "Assistant"]);

    const inviteAsType = ref(["admin", "editor", "viewer"]);
    const customTag = ref("");
    const form = reactive({
      members: [
        { email: "", tags: [], inviteAs: null, propertyIds: [] as any },
      ],
    });
    const emailFormRef = ref(null) as any;
    const validation = ref({
      email: [
        (v: any) => !!v || "Email is required",
        (v: any) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email. Try again.",
        (v: any) => !isEmailDuplicate(v) || "Email is already used",
      ],
      tags: [
        (v: any) => (!!v && v.length > 0) || "At least one tag is required",
      ],
      propertyIds: [
        (v: any) =>
          (!!v && v.length > 0) || "At least one property is required",
      ],
      inviteAs: [(v: any) => (!!v && v.length > 0) || "Select invite role."],
    });
    const propertyList = computed(() =>
      store.getters[`${PROPERTY_STORE}/properties`]?.filter(
        (property: any) =>
          property.connectionRole !== PropertyConnectionRoleEnum.VIEWER
      )
    );
    const isEmailDuplicate = (email: string) => {
      const emailValues = form.members.map((emailObj) => emailObj.email);
      const emailCount = emailValues.filter((e) => e === email).length;
      return emailCount > 1;
    };

    const onAddEmail = (e: any) => {
      e.preventDefault();
      form.members.push({
        email: "",
        tags: [],
        inviteAs: null,
        propertyIds: [],
      });
    };

    const onRemoveEmail = (index: number) => {
      form.members.splice(index, 1);
    };

    const likesAllTags = (member: any) => {
      return member.tags.length === Tags.value.length;
    };

    const likesSomeTag = (member: any) => {
      return member.tags.length > 0;
    };

    const toggle = (member: any) => {
      if (likesAllTags(member)) {
        member.tags = [];
      } else {
        member.tags = Tags.value.slice();
      }
    };

    const addCustomTag = (member: any) => {
      if (customTag.value && !member.tags.includes(customTag.value)) {
        member.tags.push(customTag.value);
        customTag.value = "";
      }
    };
    const IsValidTag = (member: any) => {
      if (!member?.tags?.length || !member?.inviteAs) {
        return "";
      }
      return member?.tags.includes("Occupier") && member?.inviteAs !== "viewer"
        ? "Occupier tag assign to viewer user only"
        : "";
    };
    const onSendInvitation = async () => {
      try {
        const allOccupierIsViewerUser = form.members.every((member: any) =>
          member.tags.includes("Occupier") ? member.inviteAs === "viewer" : true
        );
        const isFormValid = await emailFormRef.value.validate();
        if (!isFormValid.valid || !allOccupierIsViewerUser) return;
        isShowLoadingSendInvitation.value = true;
        const processedForm = form.members.map((member: any) => {
          const processedMember = {
            ...member,
            tags: member.tags,
          };

          if (memberMessage.value) {
            processedMember.message = memberMessage.value;
          }

          return processedMember;
        });
        const response = await store.dispatch(
          `${CONNECTIONS}/inviteContractors`,
          {
            members: { members: processedForm },
            userId: user.value?.id,
          }
        );
        await store.dispatch(`${CONNECTIONS}/getUserConnectionList`, {
          userId: user.value?.id,
        });
        if (response) {
          isShowInvitationsSentModal.value = true;
        }
        ctx.emit("redirectToRequest");
      } catch (err) {
        console.log();
      } finally {
        isShowLoadingSendInvitation.value = false;
      }
    };

    const onClickCloseButton = () => {
      if (props.defaultPropertyId && isShowInvitationsSentModal.value) {
        localStorage.setItem("redirectToRequest", "true");
        ctx.emit("onRouteToConnections");
      } else {
        ctx.emit("onClickCloseTab");
      }
    };

    const isFormValid = computed(() => {
      return form.members.every(
        (member) =>
          validation.value.email.every((rule) => rule(member.email) === true) &&
          validation.value.tags.every((rule) => rule(member.tags) === true) &&
          validation.value.propertyIds.every(
            (rule) => rule(member.propertyIds) === true
          ) &&
          ((member.tags as any).includes("Occupier")
            ? member.inviteAs === "viewer"
            : true)
      );
    });

    const hasAtLeastOneMember = computed(() => {
      return form.members.some((member) => member.email.trim() !== "");
    });
    onMounted(async () => {
      if (props.defaultPropertyId) {
        form.members[0].propertyIds.push(props.defaultPropertyId);
      }
      // if (props.defaultPropertyId) {
      //   form.members[0].propertyIds = [props.defaultPropertyId];
      // }
      if (!propertyList.value?.length)
        await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});
    });
    return {
      onClickCloseButton,
      validation,
      emailFormRef,
      form,
      onAddEmail,
      onSendInvitation,
      onRemoveEmail,
      selectedTags,
      Tags,
      toggle,
      likesAllTags,
      likesSomeTag,
      customTag,
      addCustomTag,
      isShowInvitationsSentModal,
      user,
      memberMessage,
      isShowLoadingSendInvitation,
      isFormValid,
      hasAtLeastOneMember,
      inviteAsType,
      propertyList,
      IsValidTag,
    };
  },
};
</script>
