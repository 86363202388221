export enum RolesEnum {
  ADMIN = 1,
  PROJECT_OWNER = 2,
  TRADESPERSON = 3,
}

export enum BusinessDetailTypeEnum {
  LIMITED_COMPANY = "limited_company",
  SOLETRADER = "soletrader",
  PROPERTY_OWNER = "property_owner",
}

export enum UserWorkstationConstant {
  ACTIVE = "active",
  PENDING = "pending",
  INACTIVE = "inactive",
}

export enum MemberInvitationStatusEnum {
  PENDING = "pending",
  ACCEPTED_POKED = "accepted_poked", // accepted but not registered or logged in
  ACCEPTED = "accepted",
  FAILED = "failed",
}

export enum UserVerificationStatusEnum {
  NOT_ADDED = "0",
  PENDING_VERIFICATION = "1",
  VERIFIED = "2",
  REJECTED = "-1",
}

export enum UserRolesEnum {
  TRADESPERSON = "Tradesperson",
  PROPERTY_OWNER = "Project Owner",
}

export enum UserWorkstationVerificationType {
  KYC = "KYC",
  KYB = "KYB",
}

export enum PaymentPlanType {
  MONTHLY = 1,
  YEARLY = 2,
}

export enum WorkstationAccountTypeEnum {
  SOLETRADER = "soletrader",
  LIMITED_COMPANY = "limited_company",
  LANDLORD = "landlord",
  RENTER = "renter",
  HOMEOWNER = "homeowner",
}

export enum PropertyOwnerWorkstationType {
  COMPANY = "company",
  INDIVIDUAL = "individual",
}

export enum UserWorkstationMemberRoleEnum {
  ADMIN = "admin",
  VIEWER = "viewer",
  EDITOR = "editor",
}

export enum connectionMemberInvitationStatusEnum {
  PENDING = "pending",
  ACCEPTED_POKED = "accepted_poked", // accepted but not registered or logged in
  ACCEPTED = "accepted",
  FAILED = "failed",
  REQUESTED = "requested",
}
