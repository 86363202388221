export const TRADE_NETWORK_ROUTE = "trade-network";

export default {
  path: "/trade-network",
  name: TRADE_NETWORK_ROUTE,
  component: () => import("@/modules/trade-network/pages/TradeNetwork.vue"),
  meta: {
    validateAuth: true,
    requiresAuth: true,
    title: "Trade Network",
  },
};
