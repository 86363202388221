import $axios from "../utils/axios-api-config";

export default {
  getWalletBalance(userId: number) {
    return $axios.get(`/users/${userId}/accounts/tp-balance`);
  },
  getTransactionList(userId: number) {
    return $axios.get(`/users/${userId}/accounts/e-wallet/transaction-list`);
  },
  getInvoiceTransactionDetails(
    userId: number,
    startTransactionDate: string,
    endTransactionDate: string
  ) {
    return $axios.get(
      `/users/${userId}/accounts/transaction-list/download?startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`,
      {
        responseType: "arraybuffer",
      }
    );
  },
  moneyOutInformation(userForm: any) {
    return $axios.post(`/payment/scan`, userForm);
  },
};
