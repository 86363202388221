<template>
  <CommonDialog
    v-model="openReviewModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['service_plan_modal']"
    width="640px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="modal__header">
          <v-btn
            class="modal__close"
            variant="plain"
            icon="mdi-close"
            @click="onCloseModal"
          ></v-btn>
        </div>
        <div class="modal__body">
          <div class="empty__content">
            <div class="empty__banner">
              <div class="empty__banner__img">
                <img src="@/assets/images/service_plan_modal.svg" alt="" />
              </div>
              <div class="empty__banner__content">
                <h4>Have you got Air Source Heat Pump?</h4>
                <p>
                  iknowa heat pump servicing plan provides you with peace of
                  mind, knowing your heat pump is annually serviced & any
                  potential issues are detected early.
                </p>
                <ul class="empty__banner__content__list">
                  <li>
                    <div class="list__item">
                      <v-icon icon="mdi-check-circle" />
                      <p><strong> Annual</strong> heat pump servicing</p>
                    </div>
                  </li>
                  <li>
                    <div class="list__item">
                      <v-icon icon="mdi-check-circle" />
                      <p>One annual <strong>payment</strong></p>
                    </div>
                  </li>
                  <li>
                    <div class="list__item">
                      <v-icon icon="mdi-check-circle" />
                      <p>
                        Verified trade contractors
                        <strong> in your local area</strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="empty__banner__footer">
                <v-btn
                  class="button button-purple"
                  width="100%"
                  @click="onTriggerAction('workstation')"
                  >Create a workstation</v-btn
                >

                <v-btn
                  class="button button-normal"
                  @click="onTriggerAction('servicePlan')"
                  variant="text"
                  width="100%"
                >
                  VIEW EXAMPLE SERVICEING PLAN
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { defineEmits } from "vue";
import moment from "moment";

const emits = defineEmits(["on-close", "on-action"]);
const props = defineProps(["reviewData"]);
const openReviewModal = true;
const onCloseModal = () => {
  emits("on-close");
};
const onTriggerAction = (actionType = "") => {
  emits("on-action", actionType);
};
</script>
<script>
export default {
  name: "ProjectReviewCommentModal",
};
</script>
<style scoped lang="scss"></style>
