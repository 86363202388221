import { reviewRatingState } from "@/core/models/project-rating";
export default {
  setProjectReviewList(state: reviewRatingState, payload: []) {
    state.allProjectReviewList = payload;
  },
  setTradePassportRedirect(state: reviewRatingState, payload: any) {
    state.showTradePassportRedirect = payload;
  },
  resetTradePassportRedirect(state: reviewRatingState) {
    state.showTradePassportRedirect = null;
  },
};
