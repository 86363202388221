<template>
  <GeneralDialog @on-close="onCloseImportWorkstationModal"
    ><template #body>
      <div class="workstation-modal-body">
        <div class="workstation-modal-body__inner">
          <div class="workstation-modal-body__image">
            <img
              src="@/assets/images/copydata-folder.svg"
              alt="Image"
              width="87"
              height="87"
            />
          </div>
          <h2>Copy Data to Another Workstation</h2>
          <p>Ready to copy data?</p>
        </div>

        <div class="custom-dropdown" :class="{ 'is-open': isOpen }">
          <div class="custom-dropdown__label">
            <p>Workstation</p>
          </div>
          <div class="custom-dropdown__inner">
            <button
              type="button"
              class="custom-dropdown__trigger"
              @click="isOpen = !isOpen"
            >
              <p>
                {{
                  selectedWorkStationName
                    ? selectedWorkStationName?.name
                    : "Select Your workstation "
                }}
              </p>
              <v-icon icon="mdi-chevron-down" color="#0C0F4A"></v-icon>
            </button>
            <div class="custom-dropdown__menu">
              <div class="custom-dropdown__menu-inner">
                <v-radio-group v-model="selectedWorkStation">
                  <v-radio
                    color="#4F55F0"
                    :label="workstation.name"
                    :value="workstation.id"
                    v-for="workstation in userWorkstations"
                    :key="workstation.id"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div> </template
    ><template #footer>
      <div class="workstation-modal-footer">
        <v-btn
          @click="onCloseImportWorkstationModal"
          class="button button-purple-border"
          >CANCEl</v-btn
        ><v-btn
          class="button button-orange"
          :disabled="!selectedWorkStation"
          @click="onClickContinue"
          >CONTINUE</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { filter } from "lodash";
import { ref } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import UserService from "@/core/services/user.service";
import { USER_STORE } from "@/store/modules/user";
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    GeneralDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const selectedWorkStation = ref(null);

    const isLoading = ref(false);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userWorkstations = computed(() => {
      const allWorkstation =
        store.getters[`${WORKSTATION}/getUserWorkstations`];

      const filterWorkstation = allWorkstation
        .filter((workstation: any) => workstation.name !== "Default")
        .filter(
          (workstation: any) =>
            workstation.name !== activeUserWorkstation.value?.name
        );
      return filterWorkstation;
    });

    const selectedWorkStationName = computed(() => {
      return userWorkstations.value.find((workstation: any) => {
        return workstation.id === selectedWorkStation.value;
      });
    });

    const onCloseImportWorkstationModal = () => {
      ctx.emit("onCloseImportWorkstationModal");
    };

    const onClickContinue = async () => {
      try {
        isLoading.value = true;
        await UserService.copyDataFromOtherWorkStation(user.value?.id, {
          toWorkstationId: selectedWorkStation.value,
        });
        ctx.emit("onCloseImportWorkstationModal");
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading.value = false;
      }
    };
    return {
      onCloseImportWorkstationModal,
      userWorkstations,
      selectedWorkStation,
      selectedWorkStationName,
      onClickContinue,
    };
  },
};
</script>

<style lang="scss" scoped>
.workstation-modal-body {
  color: $blueDark;
  width: 330px;

  @include respond(s720) {
    width: 100%;
  }

  &__image {
    width: 88px;
    height: 88px;
    margin: 0 auto 32px;
  }

  &__inner {
    max-width: 270px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    @include fluidFont(24, 24, 28px);
    font-weight: 600;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
  }
}

.workstation-modal-footer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  margin-top: 5px;
}

.custom-dropdown {
  margin-top: 26px;
  @include fluidFont(12, 12, 1);

  &__label {
    margin-bottom: 12px;
    letter-spacing: 0.15px;
  }

  &__trigger {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(#80829f, 0.4);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 16px;
    letter-spacing: 0.4px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .mdi {
      font-size: 16px;
      flex-shrink: 0;
      transition: transform 0.3s ease;
    }
  }

  &__menu {
    border: 1px solid rgba(#80829f, 0.4);
    border-radius: 0 0 8px 8px;
    padding: 5px;
    margin-top: -1px;
    display: none;

    :deep(.v-label) {
      width: 100%;
      @include fluidFont(13, 13, 1);
      opacity: 1;
      padding-left: 10px;
    }

    &-inner {
      max-height: 130px;
      overflow: auto;
      padding: 5px;
    }
  }

  :deep(.v-selection-control-group) {
    gap: 10px;
  }

  :deep(.v-selection-control__wrapper),
  :deep(.v-selection-control__input) {
    width: 16px;
    height: 16px;

    .mdi {
      font-size: 20px;
    }
  }

  &.is-open {
    .custom-dropdown__trigger {
      border-radius: 8px 8px 0 0;

      .mdi {
        transform: scaleY(-1);
      }
    }

    .custom-dropdown__menu {
      display: block;
    }
  }
}
</style>
