import geminiFlashService from "@/core/services/gemini-flash.service";
import { Commit } from "vuex";

export default {
  async getUsersTextPromptsResponse(
    { commit }: { commit: Commit },
    payload: {
      conversationId?: string;
      prompt: string;
    }
  ) {
    try {
      const response = await geminiFlashService.getUsersTextPromptsResponse(
        payload
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getUsersAudioPromptsResponse(
    { commit }: { commit: Commit },
    formData: any
  ) {
    try {
      const response = await geminiFlashService.getUsersAudioPromptsResponse(
        formData
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getSpeechToSpeech({ commit }: { commit: Commit }, formData: any) {
    try {
      const response = await geminiFlashService.getSpeechToSpeech(formData);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getAudioFromText({ commit }: { commit: Commit }, payload: any) {
    try {
      const response = await geminiFlashService.getAudioFromText(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getConversationsList({ commit }: { commit: Commit }) {
    try {
      const response = await geminiFlashService.getConversationsList();
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async getConversationData({ commit }: { commit: Commit }, payload: any) {
    try {
      const { conversationId } = payload;
      const response = await geminiFlashService.getConversationData(
        conversationId
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async reGenerateResponse({ commit }: { commit: Commit }, payload: any) {
    try {
      const response = await geminiFlashService.reGenerateResponse(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async editConversationQuestion({ commit }: { commit: Commit }, payload: any) {
    try {
      const { questionId, prompt } = payload;
      const response = await geminiFlashService.editConversationQuestion(
        questionId,
        { prompt }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
