<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0037 13.0264C9.85036 13.0407 9.69641 13.0061 9.56393 12.9275C9.46713 12.8282 9.42072 12.6902 9.4378 12.5525C9.44138 12.4379 9.45506 12.3238 9.4787 12.2116C9.50158 12.0829 9.53114 11.9555 9.56732 11.8298L9.9696 10.4457C10.0111 10.3091 10.0385 10.1686 10.0514 10.0264C10.0514 9.87298 10.0719 9.76727 10.0719 9.70593C10.0804 9.4325 9.96368 9.17011 9.75482 8.99342C9.49792 8.7962 9.178 8.69926 8.85482 8.7207C8.62323 8.72419 8.39343 8.7621 8.173 8.8332C7.93209 8.9082 7.67867 8.99797 7.41277 9.10253L7.29688 9.55253C7.37527 9.52525 7.47075 9.49458 7.57982 9.46048C7.68389 9.42966 7.79176 9.41357 7.90027 9.41275C8.0525 9.39626 8.20572 9.43364 8.33322 9.51843C8.41982 9.62176 8.46046 9.75599 8.44572 9.89003C8.44534 10.0047 8.43277 10.119 8.40822 10.2309C8.38435 10.3503 8.35367 10.4764 8.31617 10.6093L7.9105 12.0002C7.8778 12.1295 7.85164 12.2603 7.8321 12.3923C7.81616 12.5052 7.80819 12.6191 7.80822 12.7332C7.80655 13.0085 7.93244 13.269 8.14912 13.4388C8.40998 13.6391 8.73447 13.7384 9.06274 13.7184C9.2939 13.7231 9.52426 13.6897 9.74457 13.6195C9.93775 13.5536 10.1957 13.4593 10.5184 13.3366L10.6275 12.907C10.5401 12.9433 10.4501 12.9729 10.3582 12.9956C10.242 13.0222 10.1227 13.0325 10.0037 13.0264Z"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
    <path
      d="M10.4276 6.46595C10.2421 6.29556 9.99762 6.20389 9.74579 6.21027C9.4941 6.20459 9.24988 6.29617 9.06396 6.46595C8.72318 6.7598 8.68512 7.27428 8.979 7.61509C9.00519 7.64547 9.03358 7.67386 9.06396 7.70005C9.4522 8.0473 10.0394 8.0473 10.4276 7.70005C10.7684 7.4033 10.8041 6.8865 10.5073 6.54572C10.4827 6.51733 10.456 6.49067 10.4276 6.46595Z"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
    <path
      d="M9 2.45996C4.85786 2.45996 1.5 5.81782 1.5 9.95996C1.5 14.1021 4.85786 17.46 9 17.46C13.1421 17.46 16.5 14.1021 16.5 9.95996C16.5 5.81782 13.1421 2.45996 9 2.45996ZM9 16.7781C5.23441 16.7781 2.18183 13.7255 2.18183 9.95996C2.18183 6.19438 5.23441 3.14179 9 3.14179C12.7656 3.14179 15.8182 6.19438 15.8182 9.95996C15.8182 13.7255 12.7656 16.7781 9 16.7781Z"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>
<script>
export default {
  name: "InfoIconOutline",
};
</script>
<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#0C0F4A",
  },
  fillOpacity: {
    type: String,
    default: "0.6",
  },
});
</script>
