<template>
  <svg
    :class="className"
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 4.0625C35.1549 4.0625 37.2215 4.91852 38.7452 6.44226C40.269 7.96599 41.125 10.0326 41.125 12.1875V28.4375H24.875V12.1875C24.875 10.0326 25.731 7.96599 27.2548 6.44226C28.7785 4.91852 30.8451 4.0625 33 4.0625ZM45.1875 28.4375V12.1875C45.1875 8.95517 43.9035 5.85524 41.6179 3.56964C39.3323 1.28404 36.2323 0 33 0C29.7677 0 26.6677 1.28404 24.3821 3.56964C22.0965 5.85524 20.8125 8.95517 20.8125 12.1875V28.4375C18.6576 28.4375 16.591 29.2935 15.0673 30.8173C13.5435 32.341 12.6875 34.4076 12.6875 36.5625V56.875C12.6875 59.0299 13.5435 61.0965 15.0673 62.6202C16.591 64.144 18.6576 65 20.8125 65H45.1875C47.3424 65 49.409 64.144 50.9327 62.6202C52.4565 61.0965 53.3125 59.0299 53.3125 56.875V36.5625C53.3125 34.4076 52.4565 32.341 50.9327 30.8173C49.409 29.2935 47.3424 28.4375 45.1875 28.4375Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    className: String,
  },
};
</script>
