<template>
  <ChipState
    :label="label"
    :isDense="isDense"
    textColor="#FFF"
    bgColor="#1FB27A"
  >
    <template #prefix>
      <CheckIconWhite />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import CheckIconWhite from "@/core/components/icons/CheckIconWhite.vue";

const props = defineProps({
  label: {
    type: String,
    default: "Accept",
  },
  isDense: Boolean,
});
</script>
<script>
export default {
  name: "AcceptChip",
};
</script>
