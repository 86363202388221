<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill="white" />
    <path
      d="M5.99307 2.96151C6.76789 2.96151 7.51097 3.27425 8.05885 3.83093C8.60673 4.38761 8.91453 5.14263 8.91453 5.92989H8.49717L9.33188 6.77799L10.1666 5.92989H9.74923C9.74969 5.06145 9.45864 4.21881 8.92413 3.54113C8.38963 2.86346 7.64363 2.39127 6.80934 2.20254C5.97505 2.01381 5.10236 2.11984 4.33538 2.5031C3.5684 2.88637 2.95301 3.52395 2.59082 4.31057L3.23076 4.96078C3.42943 4.37707 3.80226 3.87081 4.29736 3.51248C4.79246 3.15414 5.3852 2.96155 5.99307 2.96151Z"
      fill="#4A92E5"
    />
    <path
      d="M6.00701 9.03914C5.23219 9.03914 4.4891 8.7264 3.94122 8.16972C3.39334 7.61304 3.08555 6.85802 3.08555 6.07076H3.5029L2.6682 5.22266L1.8335 6.07076H2.25085C2.25038 6.93921 2.54144 7.78184 3.07594 8.45952C3.61045 9.13719 4.35644 9.60938 5.19073 9.79811C6.02502 9.98684 6.89771 9.88081 7.66469 9.49755C8.43167 9.11428 9.04706 8.4767 9.40925 7.69008L8.76931 7.03987C8.57065 7.62358 8.19781 8.12984 7.70271 8.48818C7.20761 8.84651 6.61488 9.0391 6.00701 9.03914Z"
      fill="#4A92E5"
    />
    <path
      d="M5.9999 4.05664C4.95558 4.05664 4.10596 4.92892 4.10596 6.00109C4.10596 7.07325 4.95558 7.94553 5.9999 7.94553C7.04421 7.94553 7.89384 7.07325 7.89384 6.00109C7.89384 4.92892 7.04421 4.05664 5.9999 4.05664ZM6.92704 7.39046L6.80381 7.17125L6.58512 7.30088L6.70798 7.51931C6.52932 7.60746 6.33348 7.66359 6.12641 7.67979V7.29738H5.87389V7.67966C5.66682 7.66333 5.47086 7.60733 5.29232 7.51918L5.41517 7.30075L5.19649 7.17112L5.07325 7.39033C4.90634 7.27262 4.76177 7.1242 4.64712 6.95283L4.86063 6.82631L4.73437 6.60179L4.52161 6.72792C4.43575 6.54449 4.38108 6.34344 4.3653 6.13084H4.73727V5.87159H4.36492C4.38083 5.65899 4.43538 5.45781 4.52123 5.27451L4.73399 5.40064L4.86025 5.17612L4.64674 5.0496C4.76139 4.87823 4.90596 4.72981 5.07288 4.6121L5.19611 4.83131L5.4148 4.70168L5.29194 4.48325C5.4706 4.3951 5.66644 4.33897 5.87351 4.32277V4.70479H6.12603V4.32251C6.3331 4.33884 6.52906 4.39484 6.7076 4.48299L6.58474 4.70142L6.80343 4.83105L6.92666 4.61184C7.09358 4.72955 7.23815 4.87797 7.3528 5.04934L7.13929 5.17586L7.26555 5.40038L7.47831 5.27425C7.56416 5.45768 7.61884 5.65873 7.63462 5.87133H7.26252V6.13059H7.63487C7.61896 6.34318 7.56442 6.54436 7.47856 6.72766L7.26581 6.60153L7.13954 6.82605L7.35305 6.95257C7.23841 7.1242 7.09384 7.27262 6.92704 7.39046Z"
      fill="#4A92E5"
    />
    <path
      d="M5.96999 4.83398H5.62109V6.14591C5.62109 6.20348 5.65319 6.25694 5.7057 6.28645L6.57795 6.77843L6.75746 6.49718L5.96999 6.0531V4.83398Z"
      fill="#4A92E5"
    />
  </svg>
</template>
<script>
export default {
  name: "RoutineCircleWhiteIcon",
};
</script>
