<template>
  <CommonDialog
    v-model="onCloseModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="905px"
    max-width="100%"
    persistent
    :className="['contract_assign_modal proof_work_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <!-- group name -->
        <h2>
          {{
            phaseDetails?.phaseType === EstimatePhaseType.LABOUR
              ? `Phase -${phaseDetails?.name}`
              : "Materials"
          }}
        </h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onCloseModal"
        ></v-icon>
      </div>

      <div class="modal__content" v-if="!loading">
        <div class="proof_work_modal_inner">
          <div
            class="proof_work_modal_doc_list"
            v-for="(task, index) in phaseProofOfWorksDetails"
            :key="task?.id"
          >
            <div class="proof_work_modal_doc_list_header">
              <h2 v-if="task?.details?.assignTo === 'phase'">
                Phase - {{ task?.details?.name }}
              </h2>
              <h2 v-else>Task {{ index + 1 }} - {{ task?.details?.name }}</h2>
              <v-btn
                variant="text"
                aria-label="Download"
                class="proof_work_modal_download"
                @click="downloadAllAttachments(task.attachments)"
              >
                <img
                  src="@/assets/icons/download-icon.svg"
                  alt="Download Icon"
                  width="16"
                  height="16"
                />
                Download</v-btn
              >
            </div>
            <div class="proof_work_modal_doc_list_content">
              <div class="proof_work_modal_doc_list_inner">
                <a
                  class="proof_work_modal_doc tw-cursor-pointer"
                  v-for="attachment in task.attachments"
                  :key="attachment?.id"
                  @click="downloadAttachment(attachment)"
                >
                  <img
                    src="@/assets/images/pdf-icon.svg"
                    alt="PDF Icon"
                    width="16"
                    height="16"
                  />
                  <p>{{ attachment?.originalName }}</p>
                </a>
              </div>
            </div>
          </div>

          <div class="proof_work_modal_doc_image_list">
            <div
              class="proof_work_modal_doc_image_list_item"
              v-for="img in allAttachment"
              :key="img?.id"
            >
              <img
                v-if="isImageType(img)"
                :src="img?.publicURL"
                alt="Image"
                width="156"
                height="156"
              />

              <img
                v-else
                class="tw-h-[85px]"
                :src="require('@/assets/icons/sidebar-icons/documents.svg')"
                alt="icon"
              />
            </div>

            <div
              class="proof_work_modal_doc_image_list_add_more"
              v-if="imgCount"
            >
              <p>{{ imgCount }}+</p>
            </div>
          </div>
        </div>
      </div>
      <div class="contract_assign_modal_footer" v-if="!loading">
        <Button
          label="Cancel"
          variant="elevated"
          class="button button-purple-border"
          @click="onCloseModal"
          :disabled="loading"
        />
      </div>
      <div class="tw-flex tw-justify-center">
        <CommonLoader :loading="loading" />
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { ref, onMounted, computed } from "vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import $axios from "@/core/utils/axios-api-config";
import { getImageApiUrl, imageExtensions } from "@/core/utils/common";
import { EstimatePhaseType } from "@/core/enums/estimateEnum";
const phaseProofOfWorksDetails = ref(null);
import CommonLoader from "@/core/components/CommonLoader.vue";
const loading = ref(null);
const props = defineProps({
  phaseDetails: { type: Object, default: null },
  estimateId: {
    type: Number,
  },
  isEstimateAttachments: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["on-close"]);
const onCloseModal = () => {
  emits("on-close");
};

const updateAttachmentUrls = async (groupedData) => {
  const updatedGroupedDataPromises = groupedData.map(async (task) => {
    const attachmentsPromises = task.attachments.map(async (attachment) => {
      const response = await getPublicUrlForAttachments(attachment.attachment);

      return {
        ...attachment,
        publicURL: response?.publicUrl,
        imageData: response,
      };
    });

    const updatedAttachments = await Promise.all(attachmentsPromises);
    return {
      ...task,
      attachments: updatedAttachments,
    };
  });

  return Promise.all(updatedGroupedDataPromises);
};

const groupByTaskId = (data) => {
  const result = {};

  data.forEach((item) => {
    const task = !props.isEstimateAttachments ? item.task : item.phaseTask;
    const taskId = task?.id;

    if (taskId) {
      if (!result[taskId]) {
        result[taskId] = {
          details: task,
          attachments: [],
        };
      }

      result[taskId].attachments.push({
        attachment: item.attachment,
        originalName: item.originalName,
        propertyAddress: !props.isEstimateAttachments
          ? item.proofOfWorkProperties[0]?.property?.address
          : item.estimatePhaseAttachmentsProperties[0]?.property?.address,
        taskName: task.name,
        taskStartDate: task.phaseTaskStartDate,
        taskEndDate: task.phaseTaskEndDate,
        taskCompletionStatus: task.taskCompletionStatus,
      });
    } else {
      if (!result[props.phaseDetails?.id]) {
        result[props.phaseDetails?.id] = {
          details: {
            name: props.phaseDetails?.name,
            assignTo: "phase",
          },
          attachments: [],
        };
      }
      result[props.phaseDetails?.id].attachments.push({
        attachment: item.attachment,
        originalName: item.originalName,
        propertyAddress: !props.isEstimateAttachments
          ? item.proofOfWorkProperties[0]?.property?.address
          : item.estimatePhaseAttachmentsProperties[0]?.property?.address,
      });
    }
  });

  return Object.values(result);
};
const getPublicUrlForAttachments = async (attachment) => {
  const imageUrl = getImageApiUrl(attachment, true);

  try {
    const response = await $axios.get(imageUrl);
    return response;
  } catch (error) {
    console.error(
      `Error fetching image URL for attachment ${attachment}:`,
      error
    );
    return null;
  }
};

const downloadAttachment = async (attachment) => {
  try {
    const blob = new Blob([new Uint8Array(attachment.imageData.Body.data)]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = attachment?.originalName || "attachment";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.log("image download error", error);
  }
};

const downloadAllAttachments = async (attachments) => {
  for (const attachment of attachments) {
    await downloadAttachment(attachment);
  }
};
const imgCount = ref(0);
const allAttachment = computed(() => {
  const images = phaseProofOfWorksDetails.value?.reduce(
    (accumulator, currentValue) => {
      return accumulator.concat(currentValue.attachments);
    },
    []
  );
  if (!images) return [];
  if (images.length <= 3) {
    return images;
  } else {
    imgCount.value = images.length - 3;
    return images.slice(0, 3);
  }
});
const isImageType = (attachments) => {
  return imageExtensions.some((ext) =>
    attachments?.publicURL?.toLowerCase().endsWith(ext)
  );
};
onMounted(async () => {
  try {
    loading.value = true;
    let response = null;
    if (props.isEstimateAttachments) {
      response = await tradeEstimateService.getEstimateAttachments(
        props.phaseDetails?.id
      );
    } else {
      response = await tradeEstimateService.getEstimatePhaseTaskProofOfWork(
        props.phaseDetails?.id
      );
    }
    const allTasks = [];
    if (response) {
      const groupData = groupByTaskId(response);
      phaseProofOfWorksDetails.value = await updateAttachmentUrls(groupData);
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    loading.value = false;
  }
});
</script>
