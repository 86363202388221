<template>
  <ChipState
    :label="`${phaseType} ${phaseNumber}`"
    :textColor="textColor"
    :bgColor="bgColor"
  />
</template>
<script setup>
import { computed } from "vue";
import ChipState from "@/core/components/common/ChipState";

const props = defineProps({
  phaseNumber: Number,
  textColor: {
    type: String,
    default: "#4A92E5",
  },
  bgColor: {
    type: String,
    default: "rgba(74, 146, 229, 0.1)",
  },
  phaseType: String,
});
</script>
<script>
export default {
  name: "PhaseChip",
};
</script>
