import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TermsAndCondition = _resolveComponent("TermsAndCondition")!
  const _component_CommonDialog = _resolveComponent("CommonDialog")!

  return (_openBlock(), _createBlock(_component_CommonDialog, {
    className: ['property_owner_terms_modal'],
    onClickBackButton: $setup.onClickCloseButton,
    HeaderTittle: "Terms & Conditions",
    title: "Terms & Conditions",
    description: "Please read through and agree to the terms & conditions to access your iknowa platform.",
    width: "968px",
    height: "auto"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TermsAndCondition)
      ])
    ]),
    _: 1
  }, 8, ["onClickBackButton"]))
}