<template>
  <GeneralDialog
    headerTitle="Select the Certificate and Specialisms to carry forward."
    @on-close="onCloseModal"
    ><template #body>
      <div class="assign-modal">
        <div class="custom-dropdown" :class="{ 'is-open': isOpenCategory }">
          <div class="custom-dropdown__inner">
            <button
              type="button"
              class="custom-dropdown__trigger"
              @click="isOpenCategory = !isOpenCategory"
            >
              <p>
                {{
                  selectedSubCategory.length
                    ? selectedSubCategoryName
                    : "Please select a category"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" color="#0C0F4A"></v-icon>
            </button>
            <div class="custom-dropdown__menu">
              <div class="custom-dropdown__search">
                <v-text-field
                  variant="outlined"
                  placeholder="Search Specialisms"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchCategory"
                >
                </v-text-field>
              </div>

              <div class="custom-dropdown__menu-inner">
                <div class="custom-dropdown__specialisms">
                  <v-checkbox
                    color="#4F55F0"
                    v-for="(specialism, index) in filterCategory"
                    :key="index"
                    v-model="selectedSubCategory"
                    :value="specialism.subCategory.id"
                    @click="onClickMainCategory(specialism.subCategory.id)"
                  >
                    <template v-slot:label>
                      <div class="custom-dropdown__specialisms-item">
                        <v-icon
                          class="custom-dropdown__specialisms-icon"
                          v-if="specialism.subCategory.category.iconName"
                        >
                          <img
                            style="width: 14px"
                            v-if="specialism.subCategory.category.iconName"
                            :src="
                              require('@/assets/category-icons/' +
                                specialism.subCategory.category.iconName +
                                '.svg')
                            "
                            alt="icon"
                          />
                        </v-icon>
                        <h2>{{ specialism?.subCategory?.label }}</h2>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
                <!-- <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel
                    v-for="(specialism, index) in filterCategory"
                    :key="index"
                  >
                    <v-expansion-panel-title>
                      <div class="custom-dropdown__heading">
                        <v-checkbox
                          color="#4B4BFF"
                          v-model="mainSelectedCategory"
                          :value="specialism.id"
                          @click="onClickMainCategory(specialism.id)"
                        />
                        <div class="custom-dropdown__title">
                          <v-icon
                            class="custom-dropdown__title_icon"
                            v-if="specialism.iconName"
                          >
                            <img
                              style="width: 14px"
                              v-if="specialism.iconName"
                              :src="
                                require('@/assets/category-icons/' +
                                  specialism.iconName +
                                  '.svg')
                              "
                              alt="icon"
                            />
                          </v-icon>
                          <div class="custom-dropdown__title_text">
                            <h2>{{ specialism?.poLabel }}</h2>
                            <p>
                              {{
                                specialism.subCategories
                                  .slice(0, 2)
                                  .map((sub: any) => sub.label)
                                  .join(", ")
                              }}
                              <span v-if="specialism.subCategories?.length > 2">
                                etc..</span
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div class="custom-dropdown__content">
                        <v-checkbox
                          color="#4B4BFF"
                          v-model="selectedSubCategory"
                          :label="subCategory.label"
                          :key="subCategory.id"
                          :value="subCategory.id"
                          v-for="subCategory in specialism.subCategories"
                        >
                        </v-checkbox>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels> -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="custom-dropdown custom-dropdown-certificate"
          :class="{ 'is-open': isOpenCertificates }"
        >
          <div class="custom-dropdown__label">
            <p>Select Certificate</p>
          </div>
          <div class="custom-dropdown__inner">
            <button
              type="button"
              class="custom-dropdown__trigger"
              @click="isOpenCertificates = !isOpenCertificates"
            >
              <p>
                {{
                  selectedCertificate.length
                    ? selectedCertificatesName
                    : "Please Select Certificate"
                }}
              </p>
              <v-icon icon="mdi-chevron-down" color="#0C0F4A"></v-icon>
            </button>
            <div class="custom-dropdown__menu">
              <div class="custom-dropdown__search">
                <v-text-field
                  variant="outlined"
                  placeholder="Search Specialisms"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchCertificate"
                >
                </v-text-field>
              </div>

              <div class="custom-dropdown__menu-inner">
                <v-checkbox
                  color="#4B4BFF"
                  v-for="(certificate, index) in filterCertificate"
                  :label="certificate.certificate.name"
                  :key="index"
                  v-model="selectedCertificate"
                  :value="certificate.certificate.id"
                >
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="assign-modal-footer">
        <v-btn class="button button-purple-border" @click="onCloseModal"
          >Cancel</v-btn
        ><v-btn class="button button-orange" @click="onContinueButtonClick"
          >Continue</v-btn
        >
      </div>
    </template></GeneralDialog
  >
</template>
<script lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import UserService from "@/core/services/user.service";
import { CERTIFICATE_STORE } from "@/store/modules/certificates";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { watch } from "vue";
import { onBeforeMount } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    GeneralDialog,
  },

  setup(props: any, ctx: any) {
    const store = useStore();

    const mainSelectedCategory = ref([]) as any;

    const panel = ref([]) as any;

    const selectedCertificate = ref([]) as any;

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );

    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const certificateList = computed(
      () => store.getters[`${CERTIFICATE_STORE}/certificates`]
    );

    const assignedWorkStationData = computed(
      () => store.getters[`${WORKSTATION}/getAssignedWorkStationData`]
    );

    const isOpenCategory = ref(false);

    const isOpenCertificates = ref(false);

    const selectedSubCategory = ref([]) as any;

    const listOfSpecialismsCategory = ref([]) as any;

    const searchCategory = ref("");
    const searchCertificate = ref("");

    const filterCategory = computed(() => {
      if (searchCategory.value) {
        return userSpecialisms.value.filter((category: any) =>
          category.subCategory.label.includes(searchCategory.value)
        );
      } else {
        return userSpecialisms.value;
      }
    });

    const filterCertificate = computed(() => {
      if (searchCertificate.value) {
        return userCertificates.value.filter((certificate: any) =>
          certificate.certificate.name.includes(searchCertificate.value)
        );
      } else {
        return userCertificates.value;
      }
    });

    const onCloseModal = () => {
      ctx.emit("onCloseModal");
    };

    const selectedSubCategoryName = computed(() => {
      const subCategoryName: any = [];

      listOfSpecialismsCategory.value.forEach((category: any) => {
        category.subCategories.forEach((subCategory: any) => {
          if (selectedSubCategory.value.includes(subCategory.id)) {
            subCategoryName.push(subCategory.label);
          }
        });
      });

      return subCategoryName.toString();
    });

    const selectedCertificatesName = computed(() =>
      certificateList.value
        .filter((certificate: any) =>
          selectedCertificate.value.includes(certificate.id)
        )
        .map((certificateList: any) => certificateList.name)
        .toString()
    );

    const onClickMainCategory = (mainCategoryId: number) => {
      if (mainSelectedCategory.value.includes(mainCategoryId)) {
        const mainCategory = listOfSpecialismsCategory.value.find(
          (category: any) => category?.id === mainCategoryId
        );
        mainCategory.subCategories.forEach((element: any) => {
          const elementIndex = selectedSubCategory.value.findIndex(
            (subCategory: any) => subCategory === element.id
          );
          if (elementIndex > -1) {
            selectedSubCategory.value.splice(elementIndex, 1);
          }
        });
      } else {
        const mainCategory = listOfSpecialismsCategory.value.find(
          (category: any) => category?.id === mainCategoryId
        );

        mainCategory.subCategories.forEach((element: any) => {
          if (!selectedSubCategory.value.includes(element?.id)) {
            selectedSubCategory.value.push(element?.id);
          }
        });
      }
    };

    const onContinueButtonClick = () => {
      store.commit(`${WORKSTATION}/setAssignWorkStationData`, {
        teamMemberIds: assignedWorkStationData.value.teamMemberIds,
        subCategoryId: selectedSubCategory.value,
        certificateIds: selectedCertificate.value,
      });
      ctx.emit("onContinueButtonClick");
    };

    watch(
      () => mainSelectedCategory.value,
      (value) => {
        if (value.length) {
          isOpenCategory.value = true;
        }
      },
      { deep: true }
    );

    watch(
      () => selectedCertificate.value,
      (value) => {
        if (value.length) {
          isOpenCertificates.value = true;
        }
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      listOfSpecialismsCategory.value = await UserService.getCustomCategories(
        activeUserWorkstation.value?.id
      );
      userSpecialisms.value.forEach((specialism: any) => {
        selectedSubCategory.value.push(specialism.subCategory?.id);
      });
      listOfSpecialismsCategory.value.forEach(
        (specialism: any, index: number) => {
          const isSelectedAllSubCategory = specialism.subCategories.every(
            (subCategory: any) =>
              selectedSubCategory.value.includes(subCategory.id)
          );
          if (isSelectedAllSubCategory) {
            mainSelectedCategory.value.push(specialism.id);
            panel.value.push(index);
          }
        }
      );
      await store.dispatch(`${CERTIFICATE_STORE}/getAllCertificates`);
      userCertificates.value.forEach((certificate: any) => {
        selectedCertificate.value.push(certificate.certificate.id);
      });
    });
    return {
      isOpenCategory,
      filterCategory,
      panel,
      selectedSubCategory,
      onCloseModal,
      mainSelectedCategory,
      onClickMainCategory,
      isOpenCertificates,
      userCertificates,
      certificateList,
      selectedCertificate,
      onContinueButtonClick,
      selectedSubCategoryName,
      searchCategory,
      selectedCertificatesName,
      filterCertificate,
      searchCertificate,
      userSpecialisms,
    };
  },
};
</script>

<style lang="scss" scoped>
.assign-modal {
  color: $blueDark;
  width: 522px;
  padding: 24px 0;
  border-top: 1px solid #c5d2f2;
  border-bottom: 1px solid #c5d2f2;

  @include respond(s720) {
    width: 100%;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    @include respond(s720) {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .button-orange {
      min-width: 255px;

      @include respond(s720) {
        min-width: 100%;
      }
    }
  }
}

.custom-dropdown {
  @include fluidFont(12, 12, 1);
  padding: 0 10px;

  &__label {
    @include fluidFont(16, 16, 1);
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__trigger {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(#80829f, 0.4);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-weight: 600;
    @include fluidFont(14, 14, 1);
    padding: 8px 8px 8px 16px;
    letter-spacing: 0.15px;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: normal;
    }

    .mdi {
      font-size: 26px;
      flex-shrink: 0;
      transition: transform 0.3s ease;
    }
  }

  &__menu {
    border-radius: 8px;
    padding: 16px 10px 30px;
    margin-top: 8px;
    display: none;
    box-shadow: 0px 0px 20px 0px #2a2a2a1a;

    :deep(.v-label) {
      width: 100%;
      @include fluidFont(12, 12, 1);
      font-weight: 500;
      opacity: 1;
      padding-left: 10px;
    }

    &-inner {
      max-height: 464px;
      overflow: auto;
      padding: 16px 0 0;
    }
  }

  &__checkbox {
    &-label {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 6px;
      align-items: center;
    }

    &-group {
      display: grid;
      grid-gap: 16px;
    }

    &-image {
      position: relative;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      border-radius: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  :deep(.v-selection-control__wrapper),
  :deep(.v-selection-control__input) {
    width: 16px;
    height: 16px;

    .mdi {
      font-size: 20px;
    }
  }

  :deep(.v-checkbox .v-selection-control) {
    min-height: 1px;
  }

  &__search {
    :deep(.v-field) {
      border: 0;
      border-radius: 30px;
      background-color: rgba($blueDark, 0.04);

      .v-field__field {
        .v-field__input {
          padding: 8px 16px 8px 5px;
          min-height: 38px;
          height: 38px;
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: -0.41px;
          color: rgba($blueDark, 1);
          opacity: 1;
        }

        &::placeholder {
          @include fluidFont(12, 12, 1);
          font-weight: 400;
          letter-spacing: -0.41px;
          color: #8e8e93;
          opacity: 1;
        }
      }

      .mdi-magnify {
        font-size: 18px;
      }

      .v-field__outline {
        display: none;
      }
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 88%;
  }

  &.is-open {
    .custom-dropdown__trigger {
      .mdi {
        transform: scaleY(-1);
      }
    }

    .custom-dropdown__menu {
      display: block;
    }
  }

  &__specialisms {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-item {
      display: grid;
      grid-template-columns: 32px 1fr;
      align-items: center;
      grid-gap: 12px;
      width: 100%;

      h2 {
        @include fluidFont(16, 16, 24px);
        font-weight: 700;
        letter-spacing: 0.15px;
        color: $blueDark;
      }
    }

    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: $blueDark;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        object-fit: contain;
        max-width: 10px;
        filter: invert(100%) sepia(2%) saturate(7425%) hue-rotate(152deg)
          brightness(109%) contrast(116%);
      }
    }
  }

  &__title {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: flex-start;
    grid-gap: 12px;
    width: 100%;

    &_text {
      @include fluidFont(12, 12, 16px);
      letter-spacing: 0.4px;
      color: rgba($blueDark, 0.4);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      h2,
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h2 {
        @include fluidFont(16, 16, 24px);
        font-weight: 700;
        letter-spacing: 0.15px;
        color: $blueDark;
      }
    }

    &_icon {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: $blueDark;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        object-fit: contain;
        max-width: 10px;
        filter: invert(100%) sepia(2%) saturate(7425%) hue-rotate(152deg)
          brightness(109%) contrast(116%);
      }
    }
  }

  &__content {
    padding: 0 5px;
    display: grid;
    grid-gap: 12px;
    max-height: 140px;
    overflow: auto;
  }

  &-certificate {
    margin-top: 24px;

    .custom-dropdown__menu {
      :deep(.v-label) {
        @include fluidFont(16, 16, 24px);
        font-weight: 700;
        letter-spacing: 0.15px;

        @include respond(s720) {
          @include fluidFont(14, 14, 1);
        }
      }

      &-inner {
        padding: 26px 0 0 15px;
        display: grid;
        grid-gap: 40px;
        max-height: 700px;

        @include respond(s720) {
          grid-gap: 20px;
        }
      }
    }
  }
}

.v-checkbox {
  flex-shrink: 0;

  .v-selection-control__input {
    width: 16px;
    height: 16px;

    .mdi-checkbox-blank-outline {
      opacity: 1;
      color: rgba($blueDark, 0.5);
    }
  }

  .v-selection-control__wrapper {
    width: 16px;
    height: 16px;
  }

  .v-selection-control {
    min-height: 16px;
  }
}

:deep(.v-expansion-panels) {
  padding: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .v-expansion-panel {
    border-radius: 0 !important;
    overflow: hidden;
    box-shadow: none !important;
    margin: 0 !important;

    &::after {
      display: none !important;
    }
  }

  .v-expansion-panel-title__overlay {
    display: none;
  }

  .v-expansion-panel__shadow {
    box-shadow: none;
  }

  .v-expansion-panel__shadow {
    box-shadow: none;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0 0 0 45px;
  }

  .v-expansion-panel-title {
    padding: 12px 16px;
    min-height: 64px;

    @include respond(s720) {
      padding: 12px;
    }
  }

  .v-expansion-panel-title__icon .mdi {
    font-size: 42px;

    @include respond(s720) {
      font-size: 32px;
    }
  }
}
</style>
