<template>
  <commonDialog
    HeaderTittle="Know Your Property (KYP) Preview"
    :isShowBackButton="false"
    class="carousel__dialog custom__modal__box"
    max-width="100%"
  >
    <template v-slot:body>
      <div class="dashboard-setting-up">
        <div class="dashboard-setting-up__row">
          <div class="dashboard-setting-up__left">
            <div class="custom-card">
              <div class="dashboard-setting__logo">
                <img
                  :width="160"
                  aspect-ratio="16/9"
                  cover
                  src="../../../assets/images/logo/iknowa-logo-v4.svg"
                />
              </div>
              <div class="progress-wrap">
                <div class="progress-title">
                  <h4 class="common-title">
                    Your iknowa Passport & Know Your Property (KYP) Is Complete
                    🎉
                  </h4>
                  <p class="body-text">Here’s what it gives you;</p>
                </div>
              </div>

              <div class="modal_card_list">
                <ul>
                  <li>
                    <v-icon icon="mdi-check-circle" /><span>Value One </span>
                  </li>
                  <li>
                    <v-icon icon="mdi-check-circle" /><span>Value Two </span>
                  </li>
                  <li>
                    <v-icon icon="mdi-check-circle" /><span>Value Three </span>
                  </li>
                </ul>
              </div>
              <div class="bottom-btn desktop__wrapper">
                <v-btn
                  class="button button-orange"
                  @click="onClickComplete"
                  :loading="loadingContinue"
                >
                  Explore 🚀
                </v-btn>
              </div>
            </div>
          </div>
          <div class="dashboard-setting-up__right">
            <v-card class="custom-card">
              <div class="card-logo-header">
                <div class="card-logo-header__wrap">
                  <img
                    class="site-logo-img"
                    src="../../../assets/images/iknowa_logo.svg"
                    width="106"
                    height="24"
                  />
                  <span class="under-line" />
                </div>
                <div class="custom__tags">
                  <div class="card-logo-header__tags">
                    <label class="tag-item">
                      <span> KNOW YOUR PROPERTY</span> Preview</label
                    >
                  </div>
                  <div class="onbording-wrap__card-tag">
                    <label class="tag-item__points"
                      ><span>1,414</span>&nbsp;Verified Data Points
                    </label>
                  </div>
                </div>
              </div>
              <div class="profile-wrap common-profile-view">
                <div class="content">
                  <h3 class="dashboard-verify-profile__name">
                    9 Cornwallis Avenue
                  </h3>
                  <div class="dashboard-verify-profile__address">
                    <p>Folkestone, Kent, UK</p>
                    <div class="location__wrapper">
                      <v-icon icon="mdi-map-marker" />
                      <span>{{ propertyListData?.postcode }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!loading" class="card-part">
                <div class="speedmeter__img">
                  <img src="../../../assets/images/speedometer.png" alt="" />
                </div>
                <div class="speedmeter__text">
                  <v-chip variant="flat">
                    <strong> {{ propertyListData?.days_on_market }} </strong>
                    Poor
                  </v-chip>
                  <p>
                    Here we can have a brief explainer as to the score and the
                    major factors that contribute to it shown below.
                  </p>
                </div>
              </div>
              <CommonLoader v-else :loading="loading" />
              <div class="dashboard-property__list">
                <div class="dashboard-property__list_items">
                  <p class="left_list__item">Energy Efficiency Rating</p>
                  <p class="right_list__item">75</p>
                </div>
                <div class="dashboard-property__list_items">
                  <p class="left_list__item">KYP Highlight</p>
                  <p class="right_list__item">Answer</p>
                </div>
                <div class="dashboard-property__list_items">
                  <p class="left_list__item">Estimated Property Value</p>
                  <p class="right_list__item">£320,000</p>
                </div>
              </div>
            </v-card>
            <div class="bottom-btn mobile__wrapper">
              <v-btn
                class="button button-orange"
                @click="onClickComplete"
                :loading="loadingContinue"
              >
                Explore 🚀
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from "@/config";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";

export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();
    const store = useStore();
    const loadingContinue = ref(false);
    const loading = ref(false);
    const propertyListData = ref([]) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const onClickComplete = async () => {
      try {
        loadingContinue.value = true;
        ctx.emit("onClickCloseWelcomeModal", true);
      } catch (error) {
        console.log(error);
      } finally {
        loadingContinue.value = false;
      }
    };

    onBeforeMount(async () => {
      try {
        loading.value = true;
        // const response = await axios.get(
        //   `https://api.propertydata.co.uk/sourced-properties?key=${VUE_APP_PROPERTY_DATA_API}&list=repossessed-properties&postcode=${user.value?.postcode}`
        // );
        // if (response.status === 200) {
        // propertyListData.value = response.data.properties[0];
        propertyListData.value = {
          id: "R142978070",
          address: "Chester Row, London, SW1W",
          postcode: "SW1W 9JE",
          type: "Terraced house",
          bedrooms: 3,
          price: 2250000,
          sqf: 1895,
          days_on_market: 47,
          sstc: 0,
          lat: "51.49305000",
          lng: "-0.15286000",
          distance_to: "0.73",
          highest_offer: null,
        };
        // }
      } catch (error) {
        console.log("error", error);
      } finally {
        loading.value = false;
      }
    });

    return {
      VUE_APP_OS_API,
      VUE_APP_OS_POSTCODE_URL,
      loadingContinue,
      loading,
      propertyListData,
      onClickComplete,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/KnowYourPropertyModal.scss";
</style>
