<template>
  <div class="calendar_event_modal shadow">
    <div class="todo_list">
      <div class="modup__heading">
        <p>Course Details</p>
        <div class="content__icons">
          <v-icon @click.stop="onToggleEventEditModal" class="edit_modal">
            <img src="@/assets/icons/calendar-modal-edit.svg" alt="" />
          </v-icon>
          <v-icon @click.stop="removeMemberFromEvent" class="delete_modal">
            <img src="@/assets/icons/calendar-delete-modal.svg" alt="" />
          </v-icon>
        </div>
      </div>
      <div class="header">
        <h6>{{ event?.eventLabel }}</h6>
      </div>
      <div class="sub_header">
        <!-- <p>Location:{{ event?.location }}</p> -->
        <div class="calendar_event_modal-time">
          <img src="../../../assets/icons/popup-calender-clock.svg" />
          <p>{{ getEventStartDate }}</p>
        </div>
        <p class="time__wrapper">
          ({{ event?.startTime }}-{{ event?.endTime }})
        </p>
        <p>{{ event?.description }}</p>
      </div>
      <div class="footer">
        <h6>Invites</h6>
        <div v-if="event?.invitePeople?.length" class="invite__tags">
          <h5
            v-for="(people, index) in event?.invitePeople"
            :key="`invite-${index}`"
          >
            {{ people }}
          </h5>
        </div>
      </div>
      <div class="footer">
        <h6>Location</h6>
        <div class="invite__tags">
          <h5>{{ event?.location }}</h5>
        </div>
      </div>
    </div>
  </div>
  <EventModal
    v-if="isShowEventEditModal"
    :event="eventData"
    @closeModal="onToggleEventEditModal"
    modalType="edit"
    @action="editEvent"
  />
</template>
<script setup>
import moment from "moment";
import { defineProps, computed, ref, onMounted } from "vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import EventModal from "@/modules/calendar/components/EventModal.vue";
import { CALENDAR_STORE } from "@/store/modules/calendar";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
const props = defineProps({ event: Object });
const emits = defineEmits(["removeEvent", "editEvent"]);
const store = useStore();
const isShowEventEditModal = ref(false);
const eventData = ref(null);
const getEventStartDate = computed(() =>
  moment(props.event?.suggestedStartDate).format("D MMM YYYY, dddd")
);
const onToggleEventEditModal = () => {
  isShowEventEditModal.value = !isShowEventEditModal.value;
};
const removeMemberFromEvent = async () => {
  try {
    eventData.value.invitePeople = props.event.invitePeople.filter(
      (invite) => invite !== props.event?.user?.email
    );
    await store.dispatch(`${CALENDAR_STORE}/updateCalendarEvent`, {
      TraderCalenderId: props.event.eventId,
      updateData: eventData.value,
    });
    emits("removeEvent");
  } catch (error) {
    console.log("error", error);
  }
};
const editEvent = async (updatedEventData) => {
  try {
    const updatedEvent = await store.dispatch(
      `${CALENDAR_STORE}/updateCalendarEvent`,
      {
        TraderCalenderId: props.event.eventId,
        updateData: updatedEventData,
      }
    );
    onToggleEventEditModal();
    emits("editEvent", updatedEvent);
  } catch (error) {
    console.log("error", error);
  }
};

onMounted(() => {
  const {
    id,
    startTime,
    endTime,
    description,
    eventLabel,
    location,
    projectEstimateEndDate,
    suggestedStartDate,
    invitePeople,
  } = props.event;
  eventData.value = {
    name: eventLabel,
    startDate: suggestedStartDate,
    endDate: projectEstimateEndDate,
    location: location,
    startTime: startTime,
    endTime: endTime,
    description: description,
    invitePeople: invitePeople,
  };
});
</script>

<style lang="scss" scoped>
.calendar_event_modal.shadow {
  text-align: left;
  position: relative;
  min-width: 240px;
  max-width: 100%;
  box-shadow: 0px 4px 20px 0px #2a2a2a1a;
  background-color: rgba($white, 1);
  border-radius: 8px;

  .todo_list {
    padding: 15px;
    // border-bottom: 1px solid rgba($blueDark, 0.2);
    .header {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      h6 {
        @include fluidFont(14, 14, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
    }
    .sub_header {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .calendar_event_modal-time {
        display: flex;
        align-items: center;
        margin-top: 8px;
        gap: 6px;
      }
      p {
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
        // margin-top: 8px;
      }
      .time__wrapper {
        // color: rgba($PrimaryPurple, 1);
        color: rgba($blueDark, 0.5);
      }
    }
    .footer {
      margin-top: 16px;
      h6 {
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
      }
      .invite__tags {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 4px;
        h5 {
          @include fluidFont(14, 12, 1.2);
          font-weight: 600;
          color: rgba($blueDark, 1);
          // padding: 3px 5px;
          // border-radius: 30px;
          // background-color: rgba(248, 248, 248, 1);
        }
      }
    }

    .progress_bar {
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 6px;
      @include fluidFont(10, 10, 1.2);
      font-weight: 500;
      color: rgba($blueDark, 1);
    }
  }
  .member__card__users {
    padding: 15px;
    .member__card__users__img {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      li {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        overflow: hidden;
        margin-left: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e6e6e6;
        &:first-child {
          margin: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span {
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
      }
    }
  }
  .calendar_time {
    @include fluidFont(12, 12, 1.2);
    font-weight: 400;
    color: rgba($PrimaryPurple, 1);
    margin-top: 15px;
    display: inline-block;
    margin-right: 20px;
  }
  .calendar_event_modal_footer {
    display: inline-flex;

    .calendar_edit_wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      .edit_modal {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($blueDark, 0.1);
      }
      .delete_modal {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($PrimaryRed, 0.1);
      }
    }
  }
}
.modup__heading {
}

.modup__heading {
  @include fluidFont(14, 14, 18px);
  font-weight: 600;
  color: #0c0f4a;
  padding-bottom: 14px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dotted rgba($blueDark, 0.2);
  align-items: center;
  p {
    flex: 1;
    padding-right: 10px;
  }
  .content__icons {
    display: flex;
    gap: 8px;
    align-items: center;
    .v-icon {
      width: 24px;
      height: 24px;
      background-color: rgba($blueDark, 0.1);
      border-radius: 100%;
    }
  }
}
</style>
