<template>
  <InfoCardLayoutSlot
    size="lg"
    :removeBorderBottom="true"
    :hasFooter="true"
    title="Property Logs"
    bgColor="#F1F6FB"
  >
    <template #title-icon>
      <!-- <ToolTip description="Property Logs" /> -->
    </template>
    <template #content>
      <section
        class="tw-h-full tw-gap-4 tw-overflow-auto tw-flex-col tw-justify-between tw-flex"
      >
        <div class="card-container tw-grid tw-gap-2">
          <Card
            class="!tw-min-h-[90px] !tw-max-h-[90px] tw-w-full"
            v-for="(item, index) in cardData"
            :key="index"
          >
            <template #content>
              <div
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-gap-1"
              >
                <div
                  class="tw-w-full tw-h-full tw-px-3 tw-pt-3 tw-box-border tw-flex tw-items-center tw-gap-1"
                >
                  <ChipCounter :bgColor="item.color" :value="item.value" />
                  <Text
                    class="tw-w-[50%]"
                    variant="p"
                    whiteSpace="wrap"
                    textAlign="left"
                    lineHeight="18px"
                    >{{ item.label }}</Text
                  >
                </div>
                <div class="tw-w-full tw-h-full">
                  <!-- <div
                    v-if="item.hasFooter"
                    class="tw-w-full tw-h-full tw-border-t-dashed-custom tw-flex tw-items-center tw-justify-between tw-px-3 tw-box-border"
                  >
                    <Text
                      variant="span"
                      textWeight="400"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >This month</Text
                    >
                    <Text variant="span" textWeight="400" textColor="#FFA500"
                      >+5%</Text
                    >
                  </div> -->
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start">
          <Text variant="p">Timeline</Text>
          <div
            v-if="!timeLineData.length"
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="span">Timeline empty</Text>
          </div>
          <v-timeline
            v-if="timeLineData && timeLineData.length"
            side="end"
            density="compact"
            truncate-line="start"
            align="start"
          >
            <template v-for="(timeline, index) in timeLineData">
              <v-timeline-item
                dot-color="#FFF"
                fill-dot
                v-if="index < 4"
                :key="index"
              >
                <template v-slot:icon>
                  <img
                    :src="require(`@/assets/icons/kyp-icons/${timeline?.icon}`)"
                  />
                </template>
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="20px" textAlign="left"
                    >{{ timeline.title }}
                    <Text
                      variant="p"
                      textColor="rgba(12, 15, 74, 0.54)"
                      textWeight="400"
                      whiteSpace="wrap"
                      lineHeight="20px"
                      >({{ timeline.status }})</Text
                    ></Text
                  >
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.54)"
                    lineHeight="20px"
                    >{{ timeline.date }}</Text
                  >
                </div>
              </v-timeline-item>
            </template>
          </v-timeline>
          <Text
            class="tw-pl-6"
            variant="span"
            textColor="#FFA500"
            v-if="timeLineData.length > 4"
            >{{ `+${timeLineData.length - 4} More` }}</Text
          >
        </div>
      </section>
    </template>
    <template #footer-content>
      <section class="tw-w-full tw-flex tw-items-center tw-justify-start">
        <Button
          class="!tw-w-auto !tw-capitalize"
          label="View Project"
          fontSize="12px"
          @click="routeToProjectLogs"
        />
      </section>
    </template>
  </InfoCardLayoutSlot>
</template>
<script setup>
import moment from "moment";
import { computed, ref, onBeforeMount, onMounted } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ChipCounter from "@/core/components/common/ChipCounter.vue";
import InfoCardLayoutSlot from "@/core/components/slots/InfoCardLayoutSlot.vue";
import ToolTip from "@/core/components/common/ToolTip.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import { AUDIT_LOG_STORE } from "@/store/modules/audit-logs";
import { PROPERTY_STORE } from "@/store/modules/property";
import {
  HistoricProjectStatusEnum,
  HistoricalProjectTypeEnum,
  ProjectJobStatusEnum,
} from "@/core/enums/ProjectsEnum";
import {
  ALL,
  DRAFT_PROGRESS,
  IN_PROGRESS,
  COMPLETED,
  NOT_STARTED,
  NEW_HISTORIC_PROJECT,
  NEW_PROJECT,
  NEW_HISTORIC_PROJECT_SCREEN,
  PROJECT_LIST_SCREEN,
} from "../../constants";

const emits = defineEmits(["route-to-project-logs"]);

// const timeLineData = ref([
//   {
//     title: "Solar PV installation",
//     status: "iknowa verified",
//     date: "28/08/2024",
//     icon: "solar-pv-icon.svg",
//     time: "8:47 PM",
//   },
// ]);

const timeLineData = ref([]);

const store = useStore();
const route = useRoute();

const cardData = ref([]);

const ProjectLogStatus = ref([
  {
    key: ALL,
    label: "All Projects",
    statusId: ["all"],
    count: 0,
  },
  {
    key: IN_PROGRESS,
    label: "In Progress",
    statusId: [
      ProjectJobStatusEnum.IN_PROGRESS,
      ProjectJobStatusEnum.QUOTE_ACCEPTED,
    ],
    count: 0,
  },
  {
    key: DRAFT_PROGRESS,
    label: "Draft Projects",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
    count: 0,
  },
  {
    key: NOT_STARTED,
    label: "Not Started",
    statusId: [
      ProjectJobStatusEnum.AWAITING_QUOTES,
      ProjectJobStatusEnum.QUOTES_RECEIVED,
      ProjectJobStatusEnum.CANCELLED,
    ],
    count: 0,
  },
  {
    key: COMPLETED,
    label: "Completed",
    statusId: [ProjectJobStatusEnum.COMPLETED],
    count: 0,
  },
]);

const selectedFilter = ref(ProjectLogStatus.value[0]);
const diyProjects = ref([]);
const tradeVerifiedProjects = ref([]);
const iknowaVerifiedProjects = ref([]);
const inProgressProjects = ref([]);
const loading = ref(false);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const propertyId = computed(() => route?.params?.propertyId);

const propertyProjects = computed(() => {
  const projectList = store.getters[`${PROPERTY_STORE}/propertyProjects`];

  return (
    projectList?.filter(
      (project) =>
        project.projectHistoricalStatus !== HistoricProjectStatusEnum.REJECTED
    ) || []
  );
});

const filterPropertyProject = computed(() =>
  selectedFilter.value.statusId.includes("all")
    ? propertyProjects.value
    : propertyProjects.value.filter((project) =>
        selectedFilter.value.statusId.includes(project?.projectJobStatus?.id)
      )
);

const getProjectState = async () => {
  if (filterPropertyProject.value && filterPropertyProject.value.length) {
    diyProjects.value = await filterPropertyProject.value.filter((project) => {
      return (
        project.historicalProjectType === HistoricalProjectTypeEnum.DIY_PROJECT
      );
    });

    tradeVerifiedProjects.value = await filterPropertyProject.value.filter(
      (project) => {
        return (
          project.historicalProjectType ===
          HistoricalProjectTypeEnum.TRADE_VERIFY
        );
      }
    );

    iknowaVerifiedProjects.value = await filterPropertyProject.value.filter(
      (project) => {
        return (
          project.historicalProjectType !==
            HistoricalProjectTypeEnum.DIY_PROJECT &&
          project.historicalProjectType !==
            HistoricalProjectTypeEnum.TRADE_VERIFY
        );
      }
    );

    inProgressProjects.value = await filterPropertyProject.value.filter(
      (project) => {
        return project.projectJobStatus.id === ProjectJobStatusEnum.IN_PROGRESS;
      }
    );
  }

  cardData.value = [
    {
      value: iknowaVerifiedProjects.value.length || 0,
      label: "iknowa Verified",
      color: "#1FB27A",
      hasFooter: false,
    },
    {
      value: tradeVerifiedProjects.value.length || 0,
      label: "Trade Verified",
      color: "#7467B7",
      hasFooter: false,
    },
    {
      value: diyProjects.value.length || 0,
      label: "DIY Projects",
      color: "#FAA500",
      hasFooter: true,
    },
    {
      value: inProgressProjects.value.length || 0,
      label: "In Progress",
      color: "#4A92E5",
      hasFooter: false,
    },
  ];
};

const initialize = async () => {
  loading.value = true;
  const params = {
    status: -1,
  };
  const payload = {
    propertyId: propertyId.value,
    params,
  };
  try {
    await store.dispatch(`${PROPERTY_STORE}/fetchPropertyProjects`, payload);
    await store.dispatch(`${PROPERTY_STORE}/fetchPropertyLeasesData`, {
      propertyId: propertyId.value,
    });
  } catch (error) {
    loading.value = false;
  } finally {
    loading.value = false;
  }
};

const routeToProjectLogs = () => {
  emits("route-to-project-logs");
};

const getAuditLogsData = async (page) => {
  let params = {
    category: "project",
    // page: page,
    // pageSize: 30,
  };

  params = Object.fromEntries(
    Object.entries(params).filter((value) => (value[1] === 0 ? true : value[1]))
  );
  const response = await store.dispatch(`${AUDIT_LOG_STORE}/getAuditLogs`, {
    userId: user.value?.id,
    params: params,
  });
  if (response) {
    timeLineData.value = response.data
      .map((audit) => {
        const formattedDate = moment(audit?.dateCreated).format("DD/MM/YYYY");
        return {
          title: audit?.content,
          status: audit?.title,
          date: formattedDate,
          icon: "solar-pv-icon.svg",
          propertiesData: audit?.meta?.propertiesData,
        };
      })
      .filter((audit) =>
        audit?.propertiesData?.find(
          (property) => property?.propertyId == propertyId.value
        )
      );
  }
  if (response?.data?.length) {
    return response.data;
  } else {
    return [];
  }
};

onBeforeMount(async () => {
  await getAuditLogsData(1);
});

onMounted(async () => {
  await initialize();
  getProjectState();
});
</script>
<style lang="scss" scoped>
.card-container {
  grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));

  @media screen and (min-width: 1880px) {
    grid-template-columns: repeat(auto-fit, minmax(208px, 1fr));
  }

  @media screen and (min-width: 2528px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
</style>
