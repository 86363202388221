import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "add_comment_wrapper",
    style: _normalizeStyle($setup.getPosition)
  }, [
    _createVNode(_component_v_menu, {
      modelValue: $setup.menu,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.menu) = $event)),
      "close-on-content-click": false,
      location: "end",
      offset: "15",
      origin: "left"
    }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("div", _mergeProps({
          class: "",
          style: {"height":"32px","width":"32px","box-shadow":"var(\n              --elevation-200-canvas,\n              0px 1px 2px rgba(0, 0, 0, 0.1),\n              0px 5px 8px rgba(0, 0, 0, 0.3)\n            )","border-radius":"16px 16px 16px 0","background-color":"#0d99ff"}
        }, props), null, 16)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          "min-width": "300",
          flat: "",
          class: "custom__input"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_textarea, {
              "hide-details": true,
              "bg-color": "white",
              "base-color": "white",
              density: "compact",
              variant: "solo",
              flat: "",
              placeholder: "Add a comment...",
              "no-resize": "",
              rows: "1",
              "auto-grow": "",
              modelValue: $setup.commentText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.commentText) = $event)),
              "max-rows": "4",
              onKeydown: [
                _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["shift","stop"]), ["enter"])),
                _withKeys($setup.onComment, ["enter"])
              ]
            }, null, 8, ["modelValue", "onKeydown"]),
            _createVNode(_component_v_icon, {
              icon: "mdi-arrow-up-circle",
              color: "#ffaa00",
              onClick: $setup.onComment,
              disabled: $setup.commentText.trim() === ''
            }, null, 8, ["onClick", "disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 4))
}