<template>
  <v-dialog width="auto" v-model="state.modal" persistent>
    <main
      :="$attrs"
      class="general-dialog tw-w-full tw-flex tw-flex-col tw-gap-5 tw-justify-between tw-bg-white tw-p-5 tw-box-border tw-rounded-[8px]"
    >
      <section
        class="tw-absolute tw-top-[-2rem] tw-left-0"
        v-if="showBackButton"
      >
        <ChipState
          class="tw-justify-start !tw-p-0 tw-cursor-pointer"
          label="Back"
          textColor="rgba(255, 255, 255, 1)"
          @click="onBack"
        >
          <template #prefix>
            <v-icon
              icon="mdi-chevron-left"
              color="rgba(255, 255, 255, 1)"
            ></v-icon>
          </template>
        </ChipState>
      </section>
      <section
        class="general-dialog__header tw-flex tw-gap-2 tw-items-start tw-justify-between tw-w-full"
      >
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2">
          <Text v-if="isTitleStrong" class="tw-max-w-[80%]" variant="h4">{{
            headerTitle
          }}</Text>
          <Text
            v-else
            class="tw-max-w-[80%]"
            variant="h5"
            textWeight="600"
            lineHeight="16px"
            >{{ headerTitle }}</Text
          >
          <Text
            variant="span"
            textWeight="400"
            :textColor="descriptionColor"
            lineHeight="16px"
            >{{ headerDescription }}</Text
          >
        </div>
        <v-icon
          class="cursor-pointer"
          icon="mdi-close"
          :size="15"
          @click="onCloseModal"
        ></v-icon>
      </section>
      <section class="tw-w-full tw-h-full">
        <slot name="body"></slot>
      </section>
      <v-divider></v-divider>
      <slot name="footer"></slot>
    </main>
  </v-dialog>
</template>

<script setup>
import { reactive } from "vue";
import Text from "@/core/uni-components/Text.vue";
import ChipState from "@/core/uni-components/ChipState.vue";

const emits = defineEmits(["on-close", "on-back"]);

const props = defineProps({
  headerTitle: String,
  headerDescription: String,
  descriptionColor: {
    type: String,
    default: "rgba(12, 15, 74, 0.5)",
  },
  isTitleStrong: Boolean,
  showBackButton: Boolean,
  maxWidth: {
    type: String,
    default: "unset",
  },
});

const state = reactive({
  modal: true,
});

const onCloseModal = () => {
  emits("on-close");
};

const onBack = () => {
  emits("on-back");
};
</script>

<script>
export default {
  name: "GeneralDialog",
};
</script>

<style lang="scss" scoped>
.general-dialog {
  max-width: v-bind("props.maxWidth");
}
</style>
