<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" :fill="props.bgFill" />
    <path
      d="M5.25375 2.32491C5.24328 2.22062 5.25479 2.1153 5.28753 2.01574C5.32026 1.91617 5.3735 1.82457 5.44382 1.74685C5.51413 1.66913 5.59995 1.607 5.69575 1.56448C5.79155 1.52197 5.89519 1.5 6 1.5C6.10481 1.5 6.20845 1.52197 6.30425 1.56448C6.40005 1.607 6.48587 1.66913 6.55618 1.74685C6.6265 1.82457 6.67974 1.91617 6.71247 2.01574C6.74521 2.1153 6.75672 2.22062 6.74625 2.32491L6.45525 7.08741C6.44401 7.20034 6.39119 7.30506 6.30706 7.38123C6.22293 7.45741 6.11349 7.49959 6 7.49959C5.88651 7.49959 5.77707 7.45741 5.69294 7.38123C5.60881 7.30506 5.55599 7.20034 5.54475 7.08741L5.25375 2.32491ZM5.25 8.99991C5.25 8.80099 5.32902 8.61023 5.46967 8.46958C5.61032 8.32892 5.80109 8.24991 6 8.24991C6.19891 8.24991 6.38968 8.32892 6.53033 8.46958C6.67098 8.61023 6.75 8.80099 6.75 8.99991C6.75 9.19882 6.67098 9.38958 6.53033 9.53024C6.38968 9.67089 6.19891 9.74991 6 9.74991C5.80109 9.74991 5.61032 9.67089 5.46967 9.53024C5.32902 9.38958 5.25 9.19882 5.25 8.99991Z"
      :fill="fill"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  bgFill: {
    type: String,
    default: "#fff",
  },
  fill: {
    type: String,
    default: "#F45454",
  },
});
</script>
<script>
export default {
  name: "InfoIconWhite",
};
</script>
