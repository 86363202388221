<template>
  <CollapsibleWidgetSlot title="RICS Property Survey Reports">
    <template #content>
      <section
        class="tw-flex tw-flex-col rmd:tw-flex-row tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4"
      >
        <div
          class="tw-w-full tw-max-w-[404px] rmd:tw-min-w-[404px] tw-h-full tw-bg-[#F1F6FB] tw-rounded-lg tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-4"
        >
          <div
            class="tw-flex tw-flex-col-reverse rmd:tw-flex-row tw-items-center tw-gap-3 tw-w-full"
          >
            <InputField
              prepend-inner-icon="mdi-magnify"
              class="tw-w-full"
              placeholder="Search"
              v-model="filters.search"
              @input="debouncedFilterReports"
            />
            <Button
              v-if="isUserHasEditRight"
              class="rmd:!tw-w-auto"
              label="New report"
              @click="onOpenModal"
            >
              <template #prefix>
                <v-icon icon="mdi-plus-circle"></v-icon>
              </template>
            </Button>
          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
            <CollapsibleReports
              :reports="reports"
              @on-select-report="onSelectReport"
            />
          </div>
        </div>
        <div
          class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-rounded-lg tw-p-4 tw-box-border tw-max-h-[650px] tw-min-h-[650px]"
        >
          <div v-if="fetchingReports" class="loader-container">
            <CommonLoader :loading="fetchingReports" class="loader-container" />
          </div>
          <embed
            v-if="
              selectedReport &&
              selectedReport?.propertyReportAttachments.length &&
              selectedReport?.propertyReportAttachments[0]?.isPdf
            "
            :src="selectedReport?.propertyReportAttachments[0].attachmentSource"
            type="application/pdf"
            width="100%"
            height="615px"
          />
          <v-img
            v-if="
              selectedReport &&
              selectedReport?.propertyReportAttachments.length &&
              !selectedReport?.propertyReportAttachments[0]?.isPdf
            "
            class="tw-w-full tw-h-full tw-cursor-pointer"
            aspect-ratio="16/9"
            cover
            :src="
              selectedReport?.propertyReportAttachments[0]?.attachmentSource
            "
          ></v-img>
          <div
            v-if="
              selectedReport &&
              !selectedReport?.propertyReportAttachments.length
            "
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h6">No Attachment</Text>
          </div>
          <div
            v-if="!selectedReport"
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="h6">Please select a report</Text>
          </div>
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>

  <!-- MODALS -->
  <AddRicsReportModal
    v-if="addRicsReportModal"
    @on-close="onCloseModal"
    @on-success="onAddRicsSuccess"
  />
</template>
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import InputField from "@/core/components/common/InputField.vue";
import CollapsibleReports from "@/modules/properties/components/Collapsibles/sub-modules/CollapsibleReports.vue";
import AddRicsReportModal from "@/modules/properties/components/Modals/AddRicsReportModal.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { CONNECTIONS } from "@/store/modules/connections";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import { PROPERTY_STORE } from "@/store/modules/property";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import CommonLoader from "@/core/components/CommonLoader.vue";
import debounce from "lodash/debounce";

const store = useStore();
const route = useRoute();

const filters = reactive({
  search: "",
});
const addRicsReportModal = ref(false);
const reports = ref([]);
const fetchingReports = ref(false);
const selectedReport = ref(null);
const loadingAttachment = ref(false);

const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);

const propertyId = computed(() => route?.params?.propertyId);

const onOpenModal = () => {
  addRicsReportModal.value = true;
};
const onCloseModal = () => {
  addRicsReportModal.value = false;
};

const onAddRicsSuccess = async () => {
  await initialize();
  onCloseModal();
};

const initialize = async () => {
  if (!propertyId.value) return;
  const parsedPropertyId = parseInt(propertyId.value, 10);
  try {
    fetchingReports.value = true;
    const payload = {
      propertyId: parsedPropertyId,
      params: {
        search: filters.search,
      },
    };
    const response = await store.dispatch(
      `${PROPERTY_STORE}/fetchRicsReports`,
      payload
    );
    console.log(response, "fetch reports response");
    // if (response.length) {
    reports.value = response;
    // }
  } catch (error) {
    console.log(error);
  } finally {
    fetchingReports.value = false;
  }
};

const isPdf = (filename) => {
  let extension = filename.split(".").pop().toLowerCase();

  return extension === "pdf";
};

const onSelectReport = async (report) => {
  if (!report?.propertyReportAttachments.length) {
    selectedReport.value = report;
    return;
  }

  try {
    loadingAttachment.value = true;
    const data = report;
    const attachmentPromises = report.propertyReportAttachments.map(
      async (currentFile) => {
        const { attachment } = currentFile;
        const imageUrl = getImageApiUrl(attachment, true);
        const encoded = await $axios.get(imageUrl);
        const publicURL = encoded?.publicUrl;
        return {
          ...currentFile,
          attachmentSource: publicURL,
          isPdf: isPdf(currentFile.originalName),
        };
      }
    );
    data.propertyReportAttachments = await Promise.all(attachmentPromises);
    selectedReport.value = data;
    console.log(selectedReport.value, "selectedReport.value");
  } catch (error) {
    console.log(error);
  } finally {
    loadingAttachment.value = false;
  }
};

const debouncedFilterReports = debounce(initialize, 300);

onMounted(() => {
  initialize();
});
</script>
<style lang="scss" scoped>
.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 50px;
  height: 50px;
}
</style>
