<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2507 8.52865C15.2507 8.33698 15.2424 8.15365 15.2257 7.96198L16.7757 6.78698C17.109 6.53698 17.2007 6.07031 16.9924 5.70365L15.434 3.01198C15.2257 2.64531 14.7757 2.49531 14.3924 2.66198L12.6007 3.42031C12.2924 3.20365 11.9674 3.01198 11.6257 2.85365L11.384 0.928646C11.334 0.511979 10.9757 0.195312 10.559 0.195312H7.4507C7.0257 0.195312 6.66736 0.511979 6.61736 0.928646L6.3757 2.85365C6.03403 3.01198 5.70903 3.20365 5.4007 3.42031L3.60903 2.66198C3.2257 2.49531 2.7757 2.64531 2.56736 3.01198L1.00903 5.71198C0.800698 6.07865 0.892364 6.53698 1.2257 6.79531L2.7757 7.97031C2.75903 8.15365 2.7507 8.33698 2.7507 8.52865C2.7507 8.72031 2.75903 8.90365 2.7757 9.09531L1.2257 10.2703C0.892364 10.5203 0.800698 10.987 1.00903 11.3536L2.56736 14.0453C2.7757 14.412 3.2257 14.562 3.60903 14.3953L5.4007 13.637C5.70903 13.8536 6.03403 14.0453 6.3757 14.2036L6.61736 16.1286C6.66736 16.5453 7.0257 16.862 7.44236 16.862H10.5507C10.9674 16.862 11.3257 16.5453 11.3757 16.1286L11.6174 14.2036C11.959 14.0453 12.284 13.8536 12.5924 13.637L14.384 14.3953C14.7674 14.562 15.2174 14.412 15.4257 14.0453L16.984 11.3536C17.1924 10.987 17.1007 10.5286 16.7674 10.2703L15.2174 9.09531C15.2424 8.90365 15.2507 8.72031 15.2507 8.52865ZM9.03403 11.4453C7.4257 11.4453 6.11736 10.137 6.11736 8.52865C6.11736 6.92031 7.4257 5.61198 9.03403 5.61198C10.6424 5.61198 11.9507 6.92031 11.9507 8.52865C11.9507 10.137 10.6424 11.4453 9.03403 11.4453Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "SettingsIconWhite",
};
</script>
