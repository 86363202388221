<template>
  <button
    class="tw-arrow-link tw-inline-flex tw-items-center tw-gap-0.5 tw-group"
    :class="{
      'tw-opacity-50 tw-pointer-events-none tw-cursor-not-allowed':
        props.isDisabled,
    }"
  >
    <Text
      class="!tw-text-[14px] tw-font-bold tw-transition-all tw-duration-300 tw-ease-in-out"
      variant="span"
      :textColor="textColor"
      whiteSpace="nowrap"
    >
      {{ label }}
    </Text>
    <div
      class="tw-arrow-link-arrow tw-transition-all tw-duration-300 tw-ease-in-out tw-text-[#FFA500] tw-group-hover:tw-translate-x-1 tw-group-hover:tw-text-secondary tw-flex tw-mt-0.5"
    >
      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
    </div>
    <slot />
  </button>
</template>

<script setup>
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  label: {
    type: String,
    default: "Get started - it’s free 🚀",
  },
  textColor: {
    type: String,
    default: "#FFA500",
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped>
.tw-arrow-link:hover .tw-arrow-link-arrow {
  transform: translateX(4px);
}
</style>
