<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <h1>iknowa Terms of Service</h1>
    <p>
      The following Terms of Service (“Terms”) set out the legally binding
      agreement under which both Users are permitted to use our website at
      <a target="_blank" href="https://www.iknowa.com/">
        https://www.iknowa.com/</a
      >
      and the services provided on it (collectively the "Platform"). You will be
      deemed to have accepted these Terms on your first use of our Platform. We
      draw your attention in particular to the “Limitation of Liability”
      section. If you do not agree to these Terms, you MUST NOT use the
      Platform.
    </p>
    <p>
      For information on how we collect, store and process your personal data,
      and our use of cookies, please refer to our Privacy & Cookie Policy.
    </p>

    <h2>1. ABOUT THE PLATFORM</h2>
    <p>
      <strong>1.1.</strong> iknowa is a property improvement marketplace. Our
      Platform connects and matches property owners ("Property Owners”) with
      individuals or companies who provide property services (“Service
      Providers”). (Service Providers and Property Owners are collectively
      referred to as “Users”.)
    </p>

    <h2>2. ABOUT US</h2>
    <p>
      <strong>2.1.</strong> The Platform is owned and operated by iknowa Ltd,
      registered in England and Wales under company number 12249380 (“iknowa”,
      “we”, “us”, “our”), whose registered office is at 30-31 North Road,
      Wolverhampton, West Midlands, WV1 1QL.
    </p>

    <h2>3. OUR ROLE</h2>
    <p>
      <strong>3.1.</strong> The Platform connects Service Providers and Property
      Owners to facilitate transactions for property services. Such Services
      include: 1. a subscriptions for (a) Premium Property Services (Property
      Owners), (b) Sole Trader Subscription (Service Provider), (c) Limitied
      Company Subscriptions (Service Provider), (d) Document Storage (Based on
      Usage) and 2. property services carried out on a project by project basis
      ( “Service(s)”). iknowa’s role is as an intermediary role only. iknowa is
      not involved in the organisation or provision of any Services.
    </p>
    <p>
      <strong>3.2.</strong> Although we take measures to verify the Service
      Providers on our Platform, such as identification, qualification,
      insurance checks, we do not endorse any Service Providers on the Platform.
      We make no representations, warranties or guarantees, whether express or
      implied,that the information on our Platform is accurate, complete or
      up-to-date. Property Owners are responsible for ensuring that a Service or
      Service Provider meets their specific requirements.
    </p>
    <p>
      <strong>3.3.</strong> iknowa is not a party to any agreement entered into
      between a Service Provider and a Property Owner. By completing or
      accepting a Service booking, you agree to comply with the terms applicable
      to that Service, which will be made available to you during the proposal
      process. If you do not agree to the terms applicable to a Service, you
      must not complete or accept a booking for that Service.
    </p>
    <p>
      <strong>3.4.</strong> Users are independent from iknowa and they do not
      act on our behalf. We will not be held liable for any loss or liability
      suffered by you due to the acts or omissions of another User. For more
      information, please refer to the Limitation of Liability section.
    </p>

    <h2>4. REGISTRATION AND ACCOUNTS</h2>
    <p>
      <strong>4.1.</strong> In order to access and make use of the Platform, you
      must register for an account. You must be at least 18 years old to
      register for an account. Any registration by, use of, or access of our
      Platform by persons under the age of 18 is unauthorised and is in breach
      of these Terms. In using our Platform, you represent and warrant that you
      are at least 18 years old.
    </p>
    <p>
      <strong>4.2.</strong> When you submit your registration, you will receive
      a notification that we have received your submission. However, this does
      not mean that your registration has been accepted. We are under no
      obligation to accept any individual or company on the Platform, and may
      accept or reject any application at our sole discretion. Once your
      registration has been accepted, you will receive confirmation via email.
    </p>
    <p>
      <strong>4.3.</strong> When you choose, or you are provided with, a
      username, password or any other piece of information as part of our
      security procedures, you must treat such information as confidential and
      you must not disclose it to any third party. If you know or suspect that
      anyone other than you knows your username or password, you must promptly
      notify us using the contact details below. We will not be liable for any
      unauthorised use of your account.
    </p>

    <h2>5. USER CONTENT</h2>
    <p>
      <strong>5.1.</strong> You retain ownership of the content you submit to
      the Platform, including your username, photos, descriptions, data about
      your property / its stateof repair, reviews and comments (“User Content”).
      We do not make any claim to User Content.
    </p>
    <p>
      <strong>5.2.</strong> You agree that you will not submit any content that
      is abusive, threatening, defamatory, obscene, vulgar or otherwise
      offensive or in violation of our Terms. You also agree not to submit any
      content that is false and misleading or uses the Platform in a manner that
      is fraudulent or deceptive. We will not be responsible or liable to any
      third party for User Content or the accuracy of any materials submitted by
      you or any other User.
    </p>
    <p>
      <strong>5.3.</strong> Subject to statutory requirements, including data
      protection laws, by submitting User Content on or via our Platform, you
      grant us a non-exclusive, worldwide, royalty-free, irrevocable, fully
      transferable, sub-licensable, perpetual licence to use, display, edit,
      modify, reproduce, distribute, store, and prepare derivative works of your
      User Content to operate and promote the Platform, in any formats and
      through any channels, including across any third-party website or
      advertising medium. Your User Content will be considered non-confidential
      and non-proprietary and we have the right to use, copy, distribute and
      disclose it to third parties.
    </p>
    <p>
      <strong>5.4.</strong> You understand that you are solely responsible for
      your User Content. You warrant that you have all necessary rights to User
      Content and that you are not infringing or violating any third party’s
      rights by submitting it to the Platform. You agree to indemnify us for any
      loss or damage suffered by us as a result of your breach of that warranty.
      If we receive notice that your User Content infringes a third party’s
      intellectual property rights or privacy rights, we will remove it
      immediately and we reserve the right to disclose your identity to that
      third party.
    </p>
    <p>
      <strong>5.5.</strong> We are not responsible for User Content or for any
      opinions, views, or values expressed in User Content. Any such opinions,
      views, or values are those of the relevant User and do not reflect our
      opinions, views, or values in any way.
    </p>

    <h2>6. PRICING AND PAYMENT</h2>
    <p>
      <strong>6.1.</strong> We charge a subscription fee for any subscription
      products that you take out with us, as set out in our subscription terms;
      and/ or we take a fee on any booking for Services made via the Platform,
      as set out in the contract between the relevant Property Owner and Service
      Provider.
    </p>
    <p>
      <strong>6.2.</strong> To manage our secure payment processes, we use
      Stripe for subscriptions, and Modulr FS Limited for bookings for Services.
      For bookings for Services, any fees received by the Property Owner will be
      held in escrow by Modulr FS Limited until completion of the project, after
      which such fees will be released to the Service Provider. By booking a
      Service through the Platform, you authorise us to share your information
      with Stripe / Modulr FS Limited and you agree to comply with Stripe’s/
      Modulr FS Limited terms and conditions as updated from time to time. You
      also agree to provide complete and accurate payment information and you
      warrant that the details you are providing are not fraudulent.
    </p>

    <h2>7. PROHIBITED USES</h2>
    <p>
      <strong>7.1.</strong> You agree that you will not use the Platform in any
      way that is unlawful or fraudulent, or has any unlawful or fraudulent
      purpose or effect. This includes submitting, posting, sharing or
      communicating any false, misleading or deceptive information of any kind,
      impersonating another person or entity or misrepresenting your affiliation
      with another person or entity.
    </p>

    <h2>8. SECURITY</h2>
    <p>
      <strong>8.1.</strong> You must not misuse our Platform by knowingly
      introducing viruses, trojans, worms, logic bombs or other material which
      is malicious or technologically harmful. You must not attempt to gain
      unauthorised access to our Platform, the server on which our Platform is
      stored or any server, computer or database connected to our Platform.
    </p>
    <p>
      <strong>8.2.</strong> We do not guarantee that our Platform will be secure
      or free from bugs or viruses. You are responsible for configuring your
      information technology, computer programmes and platforms in order to
      access our Platform. You should use your own virus protection software.
    </p>

    <h2>9. PLATFORM ACCESS AND AVAILABILITY</h2>
    <p>
      <strong>9.1.</strong> We will use all reasonable endeavours to ensure that
      our Platform is fully operational at all times. However, we do not
      guarantee that access to our Platform will always be available or be
      uninterrupted. We will not be liable to you if for any reason our Platform
      is unavailable at any time or for any period.
    </p>
    <p>
      <strong>9.2.</strong> You are responsible for making all arrangements
      necessary for you to have access to our Platform. You are also responsible
      for ensuring that allpersons who access our Platform through your internet
      connection are aware of these Terms and other applicable terms and
      conditions, and that they comply with them.
    </p>

    <h2>10. INTELLECTUAL PROPERTY</h2>
    <p>
      <strong>10.1.</strong> We are the owner or the licensee of all
      intellectual property rights in our Platform and in the material published
      on it. Those works are protected by intellectual property laws and
      treaties around the world. All such rights are reserved and you may not
      reproduce, copy, distribute, sell, sub-licence, store, or in any other
      manner re-use content from our Platform without our express written
      permission.
    </p>
    <p>
      <strong>10.2.</strong> Subject to your agreement and compliance with these
      Terms, by accepting your registration we grant you a non-transferable,
      non-exclusive, non-sublicensable license to access and use the Platform
      until your account is deactivated (whether by you or us).
    </p>

    <h2>11. SUSPENSION AND TERMINATION</h2>
    <p>
      <strong>11.1</strong> Immediate, temporary or permanent withdrawal of your
      right to use our Platform (including suspension or termination of your
      account).
    </p>
    <p>
      <strong>11.2</strong> Immediate, temporary or permanent removal of any of
      your User Content.
    </p>
    <p><strong>11.3</strong>Legal proceedings against you.</p>
    <p>
      <strong>11.4</strong> Disclosure of such information to law enforcement
      authorities as we reasonably feel is necessary.
    </p>

    <h2>12. LIMITATION OF LIABILITY</h2>
    <p>
      <strong>12.1.</strong>We do not exclude or limit in any way our liability
      to you where it would be unlawful to do so. This includes liability for
      death or personal injury caused by our negligence or the negligence of our
      employees, agents or subcontractors and for fraud or fraudulent
      misrepresentation.
    </p>
    <p>
      <strong>12.2</strong> Insofar as is permitted by law, we make no
      representation, warranty, or guarantee that the Services we facilitate via
      the Platform will meet your requirements or expectations, that they will
      be fit for a particular purpose, that success or any other specific
      results may be achieved, or that they will be error-free, timely or
      reliable. The Platform is provided solely in order to facilitate a
      subscription arrangement or the booking of Services. We are not a party to
      any transactions or other relationships between Users or to any disputes
      between Users. We do not accept any liability for the acts or omissions of
      other Users. Any claims must be made directly against the User concerned.
      You use the Platform solely at your own risk.
    </p>
    <p>
      <strong>12.3</strong> If you are a Service Provider (or a Property Owner
      using the Platform for business purposes):
    </p>
    <div class="sub-section">
      <p>
        <strong>12.3.1.</strong> We exclude all implied conditions, warranties,
        representations or other terms that may apply to our Platform or any
        content on it.
      </p>
      <p>
        <strong>12.3.2.</strong> We will not be liable to you for any loss or
        damage, whether in contract, tort (including negligence), breach of
        statutory duty, or otherwise, even if foreseeable, arising under or in
        connection with:
      </p>
      <div class="sub-section">
        <p>
          <strong>12.3.2.1.</strong> use of, or inability to use, our Platform;
          or
        </p>
        <p>
          <strong>12.3.2.2.</strong> use of or reliance on our Platform or any
          content on it.
        </p>
      </div>
      <p><strong>12.3.3.</strong> In particular, we will not be liable for:</p>
      <div class="sub-section">
        <p>
          <strong>12.3.3.1.</strong> loss of profits, sales, business, or
          revenue;
        </p>
        <p><strong>12.3.3.2.</strong> business interruption;</p>
        <p><strong>12.3.3.3.</strong> loss of anticipated savings;</p>
        <p>
          <strong>12.3.3.4.</strong> loss of business opportunity, goodwill or
          reputation; or
        </p>
        <p>
          <strong>12.3.3.5.</strong> any indirect or consequential loss or
          damage.
        </p>
      </div>
    </div>
    <p>
      <strong>12.4</strong> If you are a Property Owner using the Platform for
      personal purposes:
    </p>

    <p>
      <strong>12.4.1.</strong> Please note that we only provide our Platform to
      you for domestic and private use. You agree not to use our Platform for
      any commercial or business purposes, and we have no liability to youfor
      any loss of profit, loss of business, business interruption, or loss of
      business opportunity.
    </p>
    <p>
      <strong>12.5</strong>Our total liability to you shall not exceed the
      amount you have paid or received for Services through the Platform.
    </p>
    <h2>13. INDEMNITY</h2>
    <p>
      <strong>13.1.</strong> You agree to indemnify us against any actual or
      threatened legal proceedings or claims issued against us by a third party
      on the basis of your breach of the Terms.
    </p>
    <h2>14. DISPUTES</h2>
    <p>
      <strong>14.1.</strong>In the event that you have a dispute with us, please
      contact us directly so a <strong>14.1.</strong>In the event that you have
      a dispute with us, please contact us directly so a resolution can be
      found. We will take into account reasonable requests to work through the
      dispute through alternative dispute resolution methods such as mediation.
    </p>
    <h2>15. GENERAL</h2>
    <p>
      <strong>15.1.</strong>Clause headings are inserted for convenience only
      and shall not affect the interpretation of these Terms.
    </p>
    <p>
      <strong>15.2.</strong>We will not lose our rights under these Terms by
      reason of any delay by us in enforcing those rights.
    </p>
    <p>
      <strong>15.3.</strong>If any provision of these Terms is deemed unlawful,
      void, or for any reason unenforceable, then that provision shall be
      severable and deemed to be removed from these Terms and shall not affect
      the validity and enforceability of any remaining terms.
    </p>
    <p>
      <strong>15.4.</strong>These Terms will be interpreted, construed and
      enforced in all respects in accordance with the laws of England and Wales,
      and you irrevocably submit to the jurisdiction of the Courts of England
      and Wales and you irrevocably submit to the exclusive jurisdiction of the
      Courts of England and Wales.
    </p>
    <h2>16. CHANGES TO THESE TERMS</h2>
    <p>
      <strong>16.1.</strong>We reserve the right to make changes to these Terms
      from time to time at our sole discretion. Your use of the Platform will be
      subject to the mostrecent version of the Terms posted on the Platform at
      the time of use. Please check back frequently for the latest version of
      our Terms.
    </p>
    <h2>17. CONTACT</h2>
    <p>
      <strong>17.1.</strong>Questions, comments and concerns are welcome. To
      contact us, please email us at
      <a target="_blank" href="support@iknowa.com">support@iknowa.com </a>
    </p>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
