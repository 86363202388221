<template>
  <GeneralDialog
    :isShowCloseButton="false"
    class="tw-w-[450px] tw-h-[430px] tw-max-w-full"
  >
    <template #body>
      <main
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <Text variat="h5" textColor="#264FD5">{{ switchingText }}</Text>
        <div class="tw-w-[100px] tw-h-[100px] tw-overflow-hidden">
          <img
            class="tw-w-full tw-h-full"
            cover
            :src="require('@/assets/icons/' + switchingImage)"
          />
        </div>
        <v-stepper
          class="onboard-stepper"
          v-model="currentSelectedTabDIY.name"
          v-if="isDiy"
        >
          <v-stepper-header class="onboard-stepper__header">
            <v-stepper-item
              class="onboard-stepper__item"
              v-for="(step, index) in tabListItemsDiy"
              :key="index"
              :value="step.name"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
        <v-stepper
          class="onboard-stepper"
          v-model="currentSelectedTabTradeVerified.name"
          v-if="!isDiy"
        >
          <v-stepper-header class="onboard-stepper__header">
            <v-stepper-item
              class="onboard-stepper__item"
              v-for="(step, index) in tabListItemsTradeVerified"
              :key="index"
              :value="step.name"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </main>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3">
        <Button
          v-if="
            currentSelectedTabTradeVerified.tab === 'step3' ||
            currentSelectedTabDIY.tab === 'step2'
          "
          :disabled="loading"
          :isLoading="loading"
          label="Back to Project Logs"
          :isActive="true"
          @click="onSuccess"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { PROJECT_STORE } from "@/store/modules/project";

const props = defineProps(["loading"]);
const emits = defineEmits(["on-close", "on-success", "on-confirm"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const switchingImage = ref("home-verify.png");
const switchingText = ref("");
const tabListItemsTradeVerified = [
  {
    tab: "step1",
    name: "Sending Contractors your invitation...",
  },
  {
    tab: "step2",
    name: "Saving your Historical Project...",
  },
  {
    tab: "step3",
    name: "Your Historical Project has successfully been saved!",
  },
];
const tabListItemsDiy = [
  {
    tab: "step1",
    name: "Saving your Historical Project...",
  },
  {
    tab: "step2",
    name: "Your Historical Project has successfully been saved!",
  },
];
const currentSelectedTabTradeVerified = ref(tabListItemsTradeVerified[0]);
const currentSelectedTabDIY = ref(tabListItemsDiy[0]);

const projectDetailsForm = computed(
  () => store.getters[`${PROJECT_STORE}/projectDetails`]
);

const isDiy = computed(
  () => projectDetailsForm.value.projectType === "DIY Project"
);

const onCloseModal = () => {
  emits("on-close");
};

const onSuccess = () => {
  emits("on-success");
};

const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

onMounted(async () => {
  if (!isDiy.value) {
    switchingText.value = "Sending Contractors your invitation...";
    switchingImage.value = "user-blue-cap.png";
    currentSelectedTabTradeVerified.value = tabListItemsTradeVerified[0];

    // Second step: Wait for 2 seconds
    await delay(2000);
    switchingText.value = "Saving your Historical Project...";
    switchingImage.value = "home-verify.png";
    currentSelectedTabTradeVerified.value = tabListItemsTradeVerified[1];

    // Third step: Wait for 5 seconds
    await delay(5000);
    switchingText.value =
      "Your Historical Project has successfully been saved!";
    switchingImage.value = "check-blue.svg";
    currentSelectedTabTradeVerified.value = tabListItemsTradeVerified[2];
  }

  if (isDiy.value) {
    switchingText.value = "Saving your Historical Project...";
    switchingImage.value = "home-verify.png";
    currentSelectedTabDIY.value = tabListItemsDiy[0];

    // second step: Wait for 5 seconds
    await delay(5000);
    switchingText.value =
      "Your Historical Project has successfully been saved!";
    switchingImage.value = "check-blue.svg";
    currentSelectedTabDIY.value = tabListItemsDiy[1];
  }
});
</script>
<style lang="scss" scoped>
:deep(.v-stepper-item) {
  width: 100%;
  max-width: 25px;
}

:deep(.v-stepper-item__content) {
  display: none;
}

:deep(.v-stepper-item--selected) {
  max-width: unset;
  .v-stepper-item__content {
    display: inline-block;
  }
}

@media screen and (min-width: 568px) {
  :deep(.v-stepper-item) {
    max-width: unset;
  }
  :deep(.v-stepper-item__content) {
    display: inline-block;
  }
}
</style>
