const FIXED = "fixed";
const VARIABLE = "variable";
const INITIAL = "initial";

const LABOUR = "labour";
const MATERIAL = "materials";

const DAYS = 1;
const WEEKS = 2;
const MONTHS = 3;

const GRANT = "grant";
const PERSONAL_DISCOUNT = "personal-discount";

export default {
  FIXED,
  VARIABLE,
  INITIAL,
  LABOUR,
  MATERIAL,
  DAYS,
  WEEKS,
  MONTHS,
  GRANT,
  PERSONAL_DISCOUNT,
};
