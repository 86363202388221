<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12L8 16H16C17.1 16 18 15.1 18 14C18 12.9 17.1 12 16 12H12Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
    <path
      d="M9.06 3.18994L0.29 11.9599C0.11 12.1399 0 12.3999 0 12.6599V14.9999C0 15.5499 0.45 15.9999 1 15.9999H3.34C3.61 15.9999 3.86 15.8899 4.05 15.7099L12.82 6.93994L9.06 3.18994Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
    <path
      d="M15.7099 4.04006C16.0999 3.65006 16.0999 3.02006 15.7099 2.63006L13.3699 0.290059C12.9799 -0.0999414 12.3499 -0.0999414 11.9599 0.290059L10.1299 2.12006L13.8799 5.87006L15.7099 4.04006Z"
      fill="#0C0F4A"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "EditPenIcon",
};
</script>
