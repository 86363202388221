<template>
  <section class="tw-flex tw-flex-col tw-gap-6">
    <div class="tw-flex tw-flex-col tw-gap-2" v-if="isProperty">
      <LabelWithValue
        v-if="isGovernmentEstimate"
        :noBg="true"
        :allFontBold="true"
        :label="formData.grantName ? formData.grantName : 'Personal'"
        :data="formatNumber(formData.grantAmount, true)"
      />
      <v-divider></v-divider>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        label="Total Cost"
        :data="formatNumber(totalGovernment)"
      />
    </div>
    <div v-else class="tw-flex tw-flex-col tw-gap-2">
      <LabelWithValue
        :noBg="true"
        label="Your total earnings"
        :data="formatNumber(totalSubTotal)"
      />
      <LabelWithValue :noBg="true" label="VAT" :data="formatNumber(totalVat)" />
      <LabelWithValue
        :noBg="true"
        label="Service fee"
        :data="formatNumber(serviceCharges)"
      >
        <template #postfix>
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="top"
            class="estimate_tooltip tw-cursor-pointer"
          >
            Each phase has a 3% fee on the total estimate. The first phase
            includes a base fee of €5, plus 3% of the estimate.
          </v-tooltip>

          <v-icon
            icon="mdi-information-slab-circle-outline mdi  !tw-text-[16px]"
            color="#80829F"
          ></v-icon>
        </template>
      </LabelWithValue>

      <v-divider></v-divider>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        :label="isGovernmentEstimate ? totalLabel : customerPaysLabel"
        :data="formatNumber(customerTotal)"
      />
      <LabelWithValue
        v-if="isGovernmentEstimate"
        :noBg="true"
        :allFontBold="true"
        label="Grants & Discounts"
        :data="formatNumber(formData.grantAmount, true)"
      >
        <template #postfix>
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="top"
            class="estimate_tooltip tw-cursor-pointer"
          >
            Grants & Discounts
          </v-tooltip>

          <v-icon
            icon="mdi-information-slab-circle-outline mdi  !tw-text-[16px]"
            color="#80829F"
          ></v-icon>
        </template>
      </LabelWithValue>

      <LabelWithValue
        v-if="isGovernmentEstimate"
        :noBg="true"
        :allFontBold="true"
        :label="customerPaysLabel"
        :data="'£' + totalGovernment"
      />
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4">
      <Button
        class="tw-col-span-3"
        :label="submitLabel"
        @click="onRouteToSchedule"
        :disabled="
          isLockTemplateModifiedChecker ||
          isEstimateSubmit ||
          currentJobDetails?.isHistoricProject ||
          !isUserHasEstimateEditAccess
        "
      >
        <template
          v-if="
            (isLockTemplateModifiedChecker || isEstimateSubmit) && !isProperty
          "
          v-slot:postfix
        >
          <img src="@/assets/icons/lock.svg" alt="" />
        </template>
      </Button>
      <!-- <Button
        class="tw-col-span-3"
        label="Clear Estimate"
        variant="secondary"
        @click="onClearEstimate"
        :disabled="
          isLockTemplateModifiedChecker ||
          isEstimateSubmit ||
          currentJobDetails?.isHistoricProject ||
          !isUserHasEstimateEditAccess
        "
      /> -->
    </div>
  </section>
</template>
<script setup>
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { computed } from "vue";
import { GrantTypeEnum } from "@/core/enums/estimateEnum";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import { ref } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import CurrencyFilter from "@/filters/currency.filter";
const emits = defineEmits(["on-submit", "on-route-to-schedule"]);
const store = useStore();
const props = defineProps({
  totalSubTotal: {
    type: Number,
  },
  totalVat: {
    type: Number,
  },
  serviceCharges: {
    type: Number,
  },
  isProperty: Boolean,
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
});
const customerTotal = computed(() => {
  const total = props.totalSubTotal + props.totalVat + props.serviceCharges;

  return props.isDIYProjectType
    ? formData.value?.totalCost || 0
    : Math.round(parseFloat(total) * 100) / 100;
});
const workFlowType = computed(
  () => store.getters[`${JOBS_STORE}/getWorkFlowType`]
);
const submitLabel = computed(() => {
  if (
    workFlowType.value &&
    workFlowType.value === PropertyWorkflowTypeEnum.RAISE_CLIENT
  ) {
    return "submit project";
  }
  if (isLockTemplateModifiedChecker.value || isEstimateSubmit.value) {
    return "Submit Estimate";
  } else {
    return "Take me to schedule";
  }
});
const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const onSubmitEstimate = async () => {
  if (totalGovernment.value <= 0) {
    ElMessage.error("total estimation value not be negative.");
  } else {
    emits("on-submit");
  }
};

const onRouteToSchedule = async () => {
  emits("on-route-to-schedule");
};

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);
const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);
const isGovernmentEstimate = computed(() => {
  return formData.value.grantType;
});

const totalGovernment = computed(() => {
  const value = customerTotal.value - parseFloat(formData.value.grantAmount);
  return value.toFixed(2);
});
const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);

const formatNumber = (value, flag = false) => {
  if (!value) return "£0";

  if (flag) return "-" + CurrencyFilter.formatToCurrency(value);
  return CurrencyFilter.formatToCurrency(value);
};
const customerPaysLabel = ref("Customer pays");
const totalLabel = ref("SubTotal");

const onClearEstimate = () => {
  store.commit(`${JOB_TEMPLATE_STORE}/clearAllEstimateData`);
};
</script>
<script>
export default {
  name: "EstimateTotal",
};
</script>
<style lang="scss" scoped></style>
