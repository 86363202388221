<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <TrashIcon
            fillOpacity="1"
            fill="#F45454"
            class="tw-w-[27px] tw-h-[35px]"
          />
          <Text variant="h4" textAlign="center">{{ label }}</Text>
          <Text variant="p" textWeight="400" textAlign="center"
            >Are you sure you want to delete {{ type }}
            <strong>from your profile?</strong> Once removed, You’ll not be able
            to restore it back. and will need to re-upload your documents.</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-items-center tw-gap-3">
        <Button
          :disabled="loading"
          :isLoading="loading"
          label="Delete"
          :isActive="true"
          @click="onDelete"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

const emits = defineEmits(["on-close", "on-success", "on-delete"]);
// const props = defineProps(["type", "label"]);
const props = defineProps({
  type: {
    type: String,
  },
  label: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const onCloseModal = () => {
  emits("on-close");
};

const onDelete = () => {
  emits("on-delete", props.type);
};
</script>
