/**
 * Courses route page
 */
import CoursesRoute from "@/modules/courses/pages/CoursesListPage.vue";
import courseDetailsRoute from "@/modules/courses/pages/CoursesDetailsPage.vue";

export const COURSE_ROUTE = "courses";
export const COURSE_DETAILS_ROUTE = "courseDetails";

export default [
  {
    path: "/courses",
    name: COURSE_ROUTE,
    component: CoursesRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Courses",
    },
  },
  {
    path: "/course-details/:courseId/:variableName/:selectedEnrollCourse?",
    name: COURSE_DETAILS_ROUTE,
    component: courseDetailsRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Courses",
    },
  },
];
