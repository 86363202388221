<template>
  <v-form @submit.prevent class="flex gap-4 flex-col" ref="ruleFormRef">
    <div class="form_input">
      <label for="newPassword" class="">New password</label>
      <v-text-field
        bg-color="rgba(12, 15, 74, 0.0509803922)"
        variant="solo"
        class="input_field password__input"
        rounded
        outlined="false"
        v-model="password"
        :rules="validation.password"
        placeholder="Enter password"
        :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append-inner="() => (isShowPassword = !isShowPassword)"
        :type="isShowPassword ? 'text' : 'password'"
      ></v-text-field>
    </div>
    <div class="password-list">
      <p>
        <img src="../../../assets/icons/exclaimation.svg" />
        <span> Must be at least 8 characters long</span>
      </p>
      <p>
        <img src="../../../assets/icons/exclaimation.svg" />
        <span> Must contain an uppercase and lowercase (A, a)</span>
      </p>
      <p>
        <img src="../../../assets/icons/exclaimation.svg" />
        <span> Must contain an number</span>
      </p>
      <p>
        <img src="../../../assets/icons/exclaimation.svg" />
        <span> Must contain a special character <br />(!, %, @, #)</span>
      </p>
    </div>
    <div class="form_input">
      <label for="reEnterPass" class="">Re-enter new password</label>
      <v-text-field
        class="input_field password__input"
        bg-color="rgba(12, 15, 74, 0.0509803922)"
        variant="solo"
        rounded
        outlined="false"
        v-model="confirmPassword"
        :rules="validation.confirmPassword"
        placeholder="Re-enter password"
        :append-inner-icon="isShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append-inner="
          () => (isShowConfirmPassword = !isShowConfirmPassword)
        "
        :type="isShowConfirmPassword ? 'text' : 'password'"
      ></v-text-field>
    </div>
    <v-btn
      class="button button-orange"
      type="submit"
      block
      @click.enter="onClickSubmitButton"
      >Submit</v-btn
    >
  </v-form>
  <div class="card-part">
    <p class="common-title">Dont’ have an account?</p>
    <label @click="onClickRegisterButton" class="yellow-text">
      Register<v-icon icon="mdi-chevron-right"
    /></label>
  </div>
</template>

<script lang="ts">
import { REGISTRATION_ROUTE } from "@/modules/registration/routes";
import { ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { CRYPTO_SECRET_KEY } from "@/core/constants";
import CryptoJS from "crypto-js";
import { useStore } from "vuex";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { LOGIN_ROUTE } from "@/modules/login/routes";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const password = ref("");
    const confirmPassword = ref("");
    const ruleFormRef = ref(null) as any;

    const isShowPassword = ref(false);
    const isShowConfirmPassword = ref(false);

    const validation = ref({
      password: [
        (v: string) => !!v || "Password is required",
        (v: string) =>
          // eslint-disable-next-line
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(
            v
          ) ||
          "Password must contain at least 8 characters including at least 1 letter, 1 number and 1 special character",
      ],
      confirmPassword: [
        (v: string) => !!v || "Confirm Password is required",
        (v: string) =>
          v === password.value || "Confirm Password does not match",
      ],
    });

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSubmitButton = async () => {
      try {
        const isFormValid = await ruleFormRef.value.validate();
        if (!isFormValid.valid) return;
        const encryptPassword = CryptoJS.AES.encrypt(
          password.value ?? "",
          CRYPTO_SECRET_KEY ?? ""
        ).toString();

        const response = await store.dispatch(
          `${AUTHENTICATION_STORE}/changePassword`,
          {
            password: encryptPassword,
          }
        );
        if (response) {
          displayToast("Password Updated.", "success");
        }
        setTimeout(() => {
          router.push({ name: LOGIN_ROUTE });
        }, 2000);
      } catch (error) {
        console.log("Failed to change password", error);
      }
    };

    const onClickRegisterButton = () => {
      router.push({ name: REGISTRATION_ROUTE });
    };

    return {
      password,
      confirmPassword,
      ruleFormRef,
      onClickSubmitButton,
      validation,
      onClickRegisterButton,
      isShowConfirmPassword,
      isShowPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/change-password/styles/changesPassword.scss";
</style>
